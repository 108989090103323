import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, TextField, Tooltip, FormControl } from "@mui/material";
import { KeyboardArrowRight } from '@mui/icons-material';
import { useSnackbar } from "../../../Contexts/SnackbarContext";

// Import custom components for this page
import { NewLogo } from '../../../Components/Images/Images'
import LinearProgressCompo from "./Components/LinearProgressCompo";
import "./Components/Questionnaire.css"
import { postApi } from "../../../Api/Api";

export default function AddAssetTitle(props) {
  // Destructuring props to extract necessary functions and data
  const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

  // Get current proposal and progress data from the parent component
  var propData = getProposalData();
  let currentProgress = getProgress();
  const { showSnackbar } = useSnackbar()

  // State to track whether the asset title length exceeds the character limit
  const [checkChar, checkCharLength] = useState(false);
  const [selected, changeSelection] = useState(propData?.asset_sub_category ? propData?.asset_sub_category : "");
  const [subCategoryData, setSubCategoryData] = useState("");

  // Character limit for asset title
  const CHARACTER_LIMIT = 250;

  // State to manage the asset title, initialized with existing title if available
  const [asset_title, setAsset_title] = useState(propData?.asset_title ? propData?.asset_title : '');

  /**
   * Function to handle changes in the asset title input.
   * @param {String} name - The name of the input field.
   */
  const handleChange = name => event => {
    // Update character length status
    checkCharLength(true);

    // Set the asset title based on user input
    setAsset_title(event.target.value);
  };

  /** 
* Function to set sub category id and url on asset sub category selected
*/
  function setSelected(data) {
    // update proposal data
    propData.asset_sub_category = data?.id;
    propData.asset_sub_category_url = data?.url;
    propData.blockchain_asset_category_id = data?.blockchain_id;
    if (data?.url !== "other") {
      propData.other_category = ""
    }
    // update it to main page
    updateProposalData(propData)

    // update it to local
    changeSelection(data?.id);
  }


  // Fetching sub categories data for asset type selected from server
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await postApi("/proposer/getSubcategory/", { "category_id": propData?.category_id });
        if (res?.data?.data && res?.data?.data?.length) {
          setSubCategoryData(res?.data?.data)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchData();
  }, [propData?.category_id])

  /**
   * Function to proceed to the next step.
   */
  const handleClick = () => {
    // Update proposal data with the new asset title
    propData.asset_title = asset_title;
    if (propData?.selectedCategory === 'funds' && selected === '') {
      showSnackbar('Please select fund type', 'error')
      return
    }
    // Update proposal data in the main page state
    updateProposalData(propData);

    // Move to the next step
    handleNext();
  };

  /**
   * Function to save the current proposal and exit the current step/page.
   */
  const SaveAndExit = () => {
    // Update proposal data with the new asset title
    propData.asset_title = asset_title;
    if (propData?.selectedCategory === 'funds' && selected === '') {
      showSnackbar('Please select fund type', 'error')
      return
    }
    // Update proposal data in the main page state
    updateProposalData(propData);

    // Save the proposal and exit
    saveProposal();
  };

  // handle fund type change
  const handleFundTypeChange = (event) => {
    let filterCategory = subCategoryData?.find((item) => parseInt(item.id) === parseInt(event.target.value))
    setSelected(filterCategory);
    // Additional logic you may want to add when the select value changes
  };

  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left" >
              {/* left-title */}
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  {propData?.selectedCategory === "funds" ? "Let’s give your asset a name and Which of the following best describes your asset?" : "Let’s give your asset a name"}
                </Typography>
              </Box>
              {/* Logo */}
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right quests-right-contents-box">
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  <Button onClick={SaveAndExit} disabled={(asset_title?.length === 0 || (propData?.selectedCategory === "funds" && selected === '')) ? true : false} className="btn-rounded btn-text-white btn-blue-600">
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box className="qsn-middle-content" sx={{ pt: 4, mb: 5 }}>
                <Box className="quests-right-contents-type">
                  <Box className="heading-wrap">
                    <Typography component="h1" className="title">
                      Create Your Title
                    </Typography>
                  </Box>
                </Box>
                <TextField
                  id={`${asset_title.length === 0 && checkChar ? "" : "assetTitle"}`}
                  label="Title"
                  inputProps={{
                    maxLength: CHARACTER_LIMIT
                  }}
                  value={asset_title}
                  error={(asset_title.length === 0 && checkChar === true)}
                  helperText={(asset_title.length === 0 && checkChar === true) ?
                    "Asset title is required." : `${asset_title.length}/${CHARACTER_LIMIT}`}
                  onChange={handleChange()}
                  margin="normal"
                  variant="outlined"
                  multiline
                />
                {propData?.selectedCategory === "funds" ?
                  <>
                    <Box mb={'30px'} mt={'24px'}>
                      <Typography className="text-black font-32" mb={1}>
                        Select Your Fund Type
                      </Typography>
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          variant="outlined"
                          // label="Fund Subtype"
                          id="outlined-select-fund-subtype-native"
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{
                            native: true
                          }}
                          name='fund_subtype'
                          value={selected}
                          onChange={handleFundTypeChange}
                          className='stIf-input'
                          sx={{ input: { color: '#64748B !important' } }}
                        >
                          <option value="" disabled>Select your Fund Type</option>
                          {subCategoryData?.length && subCategoryData?.map(option => (
                            <option key={option.id} value={option.id}>{option.title}</option>
                          ))}
                        </TextField>
                      </FormControl>
                    </Box>
                  </>
                  : null}
              </Box>
              <Box>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={3}>
                  <Box textAlign={"left"} className='quests6-btn-box'>
                    <Button className='btn-rounded back-btn' onClick={handleback}>
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"} className="quests-btn-box">
                    <Tooltip title={propData?.selectedCategory === "funds" && (asset_title?.length === 0 || selected === '') ? 'Please provide a title and select a fund type to proceed.' : (asset_title?.length !== 0) ? "" : "Please provide a title to proceed."}>
                      <span>
                        <Button
                          onClick={() => handleClick()}
                          endIcon={<KeyboardArrowRight />}
                          className='btn-rounded btn-text-white btn-blue-600'
                          disabled={(asset_title?.length === 0 || (propData?.selectedCategory === "funds" && selected === '')) ? true : false}
                        >
                          Next
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
