import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, styled } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import React, { useEffect, useState } from 'react'
import "../Investor.css"
import { GetApiParam } from '../../../Api/Api';
import { useSnackbar } from '../../../Contexts/SnackbarContext';

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    }
}));

export default function FaqsAccordions(props) {

    // Extract showSnackbar function from the useSnackbar custom hook
    const { showSnackbar } = useSnackbar();

    const [faqs, setFaqs] = useState(
        //     [
        //     {
        //         id: 1,
        //         question: 'When will i be charged?',
        //         answer: 'Your selected payment method will be charged as soon as you click invest. However, in the case that you make an offer lower than the full sale amount, you will only be charged when your offer has been selected.'
        //     },
        //     {
        //         id: 2,
        //         question: 'What if I want to edit my investment amount?',
        //         answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi pulvinar, dui vel lobortis consectetur, tellus nulla vehicula ex, ac rutrum libero dolor ac erat. Nam ex magna, euismod nec ullamcorper quis, interdum quis lorem. Curabitur rutrum quam et nulla tincidunt, ut lobortis turpis viverra. Fusce viverra quis libero quis ullamcorper.'
        //     },
        //     {
        //         id: 3,
        //         question: 'Can I cancel my investment if I change my mind?',
        //         answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi pulvinar, dui vel lobortis consectetur, tellus nulla vehicula ex, ac rutrum libero dolor ac erat. Nam ex magna, euismod nec ullamcorper quis, interdum quis lorem. Curabitur rutrum quam et nulla tincidunt, ut lobortis turpis viverra. Fusce viverra quis libero quis ullamcorper.'
        //     },
        //     {
        //         id: 4,
        //         question: 'What information will RACE know about me?',
        //         answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi pulvinar, dui vel lobortis consectetur, tellus nulla vehicula ex, ac rutrum libero dolor ac erat. Nam ex magna, euismod nec ullamcorper quis, interdum quis lorem. Curabitur rutrum quam et nulla tincidunt, ut lobortis turpis viverra. Fusce viverra quis libero quis ullamcorper.'
        //     },
        //     {
        //         id: 5,
        //         question: 'Can I invest from my country?',
        //         answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi pulvinar, dui vel lobortis consectetur, tellus nulla vehicula ex, ac rutrum libero dolor ac erat. Nam ex magna, euismod nec ullamcorper quis, interdum quis lorem. Curabitur rutrum quam et nulla tincidunt, ut lobortis turpis viverra. Fusce viverra quis libero quis ullamcorper.'
        //     },
        //     {
        //         id: 6,
        //         question: 'How do I earn a return on my investment?',
        //         answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi pulvinar, dui vel lobortis consectetur, tellus nulla vehicula ex, ac rutrum libero dolor ac erat. Nam ex magna, euismod nec ullamcorper quis, interdum quis lorem. Curabitur rutrum quam et nulla tincidunt, ut lobortis turpis viverra. Fusce viverra quis libero quis ullamcorper.'
        //     },
        //     {
        //         id: 7,
        //         question: 'How do offers work?',
        //         answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi pulvinar, dui vel lobortis consectetur, tellus nulla vehicula ex, ac rutrum libero dolor ac erat. Nam ex magna, euismod nec ullamcorper quis, interdum quis lorem. Curabitur rutrum quam et nulla tincidunt, ut lobortis turpis viverra. Fusce viverra quis libero quis ullamcorper.'
        //     },
        //     {
        //         id: 8,
        //         question: 'Can I pay a deposit?',
        //         answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi pulvinar, dui vel lobortis consectetur, tellus nulla vehicula ex, ac rutrum libero dolor ac erat. Nam ex magna, euismod nec ullamcorper quis, interdum quis lorem. Curabitur rutrum quam et nulla tincidunt, ut lobortis turpis viverra. Fusce viverra quis libero quis ullamcorper.'
        //     }
        // ]
        []
    )

    const [expanded, setExpanded] = React.useState('panel1');


    useEffect(() => {
        // Function to fetch category data from the API
        let assetData = props?.assetData;
        async function getHowItWorkFaqs() {
            try {
                if (assetData) {
                    const res = await GetApiParam("/proposer/getWorkFAQs/", {
                        investment_type_id: assetData?.investment_type_id, category_id: assetData?.category_id
                    });
                    if (res?.data?.data && res?.data?.data?.length) {
                        let id = 0;
                        const flattenedData = res.data.data.flatMap((item) => {
                            let ans = item?.work_faqs?.map((faq) => ({
                                main_id: item.id,  // Main id
                                id: id++,        // Term id
                                question: faq.question,
                                answer: faq.answer
                            }))
                            return ans;
                        }
                        );
                        setFaqs(flattenedData);
                    }
                    else {
                        setFaqs([])
                    }
                }
            } catch (error) {
                showSnackbar(error, 'error')
            }
        }
        getHowItWorkFaqs();
    }, [props?.assetData]);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Box>
            <Typography className='rc-detail-subtitle' variant='subtitle2' gutterBottom >How it works</Typography>
            {
                faqs?.map((item, index) => {
                    return (
                        <Accordion key={index} disableGutters elevation={0} className='rc-faq-accordion' expanded={expanded === `faq${item.id}`} onChange={handleChange('faq' + item.id)}>
                            <CustomAccordionSummary className='rc-faq-summary'
                                expandIcon={<ArrowRightIcon />}
                                aria-controls="panel1bh-content"
                                id={`faq${item.id}bh-header`}
                            >
                                <Typography className='rc-xsmall-secondary-text' sx={{ flexShrink: 0 }}>{item.question}</Typography>
                            </CustomAccordionSummary>
                            <AccordionDetails>
                                <Typography variant='caption'>
                                    {item.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }

        </Box>
    )
}
