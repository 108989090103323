import { createSlice } from '@reduxjs/toolkit'

export const tooltipSlice = createSlice({
    name: 'tooltip',
    initialState: {
        "loan_step_number": 0,
        "sale_step_number": 0,
        "parcel_step_number": 0,
        "re_step_number": 0,
        "funds_step_number": 0,
        'active_how_it_work': false
    },
    reducers: {
        updateStep: (state, action) => {
            const { key, value } = action.payload;
            if (key in state) {
                state[key] = value;
            }
        }
    }
});

export const { updateStep } = tooltipSlice.actions;
export default tooltipSlice.reducer;
