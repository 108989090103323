import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, TextField, Button, Typography, InputAdornment, AvatarGroup, Avatar } from "@mui/material";
import { Cancel, Close, Done, PermIdentity } from "@mui/icons-material";
import AssetReviewDetails from "./AssetReviewDetails";
import { ASSIGNED_PROJECT_STATUS } from "../../../constants";

const Voting = ({ assetData, voteSubmit, voteApproveSubmit, voteSubmitChange, voteStatus, setInputChange, handleInputChange, inputValue, updateReviewData }) => {
    let votedData;
    const MaxLength = 500;
    const navigate = useNavigate();
    if (assetData?.dicMemberData) {
        const flatData = assetData?.dicMemberData?.flat();
        const totalLength = flatData?.length;

        const statusOneCount = flatData.filter((item) => item.status === ASSIGNED_PROJECT_STATUS.APPROVED || item.status === ASSIGNED_PROJECT_STATUS.REJECTED).length;
        votedData = `${statusOneCount}/${totalLength}`;
    }
    const inputStyle = {
        fontSize: "13px",
        lineHeight: "1.5",
        color: "#fff",
        // height: "31px !important",
    };

    const helperTextStyle = {
        fontSize: "12px",
        color: "#94A3B8",
        display: "flex",
        justifyContent: "space-between",
    };

    return (
        <Box className="modalContentDone votemodal-content"
            id="scroller-wrapper"
        >
            <Box className="main-content loan ">
                {
                    // checking project vote by dic 
                    !voteSubmit ? (
                        <Box className="votingContent">
                            <Box className="">
                                <Box className="voted-img-box">
                                    <AvatarGroup>
                                        {assetData?.dicMemberData?.map((userData, ind) => {
                                            return (
                                                <Avatar
                                                    className={
                                                        userData.status === ASSIGNED_PROJECT_STATUS.APPROVED || userData.status === ASSIGNED_PROJECT_STATUS.REJECTED
                                                            ? "avatar-dic-voted "
                                                            : "avatar-dic"
                                                    }
                                                    alt="Remy Sharp"
                                                    key={ind}
                                                >
                                                    <PermIdentity
                                                        className={
                                                            userData.status === ASSIGNED_PROJECT_STATUS.APPROVED || userData.status === ASSIGNED_PROJECT_STATUS.REJECTED ? "vote" : "noVote"
                                                        }
                                                    />
                                                </Avatar>
                                            );
                                        })}
                                    </AvatarGroup>
                                </Box>
                            </Box>
                            <Box className="voteCount" >{`${votedData} Voted`}</Box>
                            <Typography component="h1" className="headText">
                                {" "}
                                Please submit your vote for the approval of{" "}
                                <span > {assetData?.asset_title
                                    ? assetData?.asset_title
                                    : '"Project Name"'}
                                </span>
                            </Typography>
                            {/* <Box className="vote-subText">
                                <Typography component="p" className="sub-headText">
                                    Please ensure your wallet has sufficient RACE Tokens for
                                    voting. Your vote will remain confidential.
                                </Typography>
                            </Box> */}

                            {/* Code for Review the Project Details edited by lead Member */}

                            <AssetReviewDetails assetData={assetData} updateNewReviewData={updateReviewData} fromVoting={true} />
                            <Box className="vote-btn-box">
                                <Button
                                    className="wallet-button appr-btn"
                                    onClick={voteApproveSubmit}
                                >
                                    {" "}
                                    <Box className="btn-icon">
                                        <Done />
                                        Approve Project
                                    </Box>
                                </Button>
                                <Button
                                    className="wallet-button rjct-btn"
                                    onClick={voteSubmitChange}
                                >
                                    <Box className="btn-icon">
                                        <Close />
                                        Reject Project
                                    </Box>
                                </Button>
                            </Box>
                            {voteStatus ? (
                                <Box paddingBottom={2.5}>
                                    <Typography component="h1" className="headText" mb={2.5} mt={3}>
                                        Please include the reason for rejection
                                    </Typography>
                                    <Box component="form" noValidate autoComplete="off">
                                        <TextField
                                            multiline
                                            minRows={3} // Added to ensure a minimum of 3 rows
                                            maxRows={6}
                                            className="reject-text"
                                            value={inputValue}
                                            onChange={handleInputChange}
                                            inputProps={{ style: inputStyle }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Cancel
                                                            className="close-icon-voting"
                                                            onClick={setInputChange}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            helperText={
                                                <>
                                                    <Box>Please provide as much detail as possible</Box>
                                                    <Box>
                                                        {inputValue.length}/{MaxLength}
                                                    </Box>
                                                </>
                                            }
                                            FormHelperTextProps={{ style: helperTextStyle }}
                                        />
                                    </Box>
                                </Box>
                            ) : null}
                        </Box>
                    ) : (
                        <>
                            <Box className="DoneIcon">
                                <PermIdentity className="doneIconSvg" />
                            </Box>
                            <Typography component="h1" className="headText">
                                Vote Submitted
                            </Typography>
                            <Typography component="p" className="sub-headText">
                                Thanks for voting on this project. The project will be approved
                                or rejected once all Assigned Committee Members have taken their
                                vote. You’ll be able to view the outcome in your{" "}
                                <Link to="#" className="link-review">
                                    {" "}
                                    Completed Projects{" "}
                                </Link>
                                page.
                            </Typography>
                            <Button
                                className="wallet-button loan"
                                onClick={() => navigate("/dic/overview")}
                            >
                                Return to Dashboard
                            </Button>
                        </>
                    )
                }
            </Box>
        </Box>
    )
}

export default Voting