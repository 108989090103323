import React, { useRef, useEffect, useState } from 'react';
import * as htmlToImage from 'html-to-image'; // Import  library
import { Box, Typography } from "@mui/material";
import { formatNumber } from './USFormat';
import './CreateTokenImage.css';
import convertFromDecimal from './convertFromDecimal';

const CreateTokenImage = ({ getImage, assetData, offerData, emiData }) => {
    const containerRef = useRef(null);
    const [coverImage, setCoverImage] = useState('');

    // Fetching image from dynamic URL and setting it as coverImage
    useEffect(() => {
        if (assetData?.asset_gallery[0]?.images?.path) {
            let dynamicImageUrl = `${process.env.REACT_APP_IMAGE_URL}${assetData.asset_gallery[0].images.path}`;
            async function fetchData() {
                try {
                    const response = await fetch(dynamicImageUrl, { cache: 'no-store' });
                    if (response.ok) {
                        const blob = await response.blob(); // Convert to blob
                        const blobUrl = URL.createObjectURL(blob); // Convert to object URL
                        setCoverImage(blobUrl); // Set the blob URL
                    }
                } catch (error) {
                    console.log('Error fetching image:', error);
                }
            }
            fetchData();
        }
    }, [assetData]);

    // Convert HTML to image when coverImage changes
    useEffect(() => {
        if (coverImage && containerRef.current) {
            htmlToImage.toPng(containerRef.current)
                .then(function (dataUrl) {
                    getImage(dataUrl, offerData, emiData); // Pass generated image data URL to callback
                })
                .catch(function (error) {
                    console.error('Image conversion error:', error);
                });
        }
    }, [coverImage, getImage, offerData, emiData]);



    return (
        <Box className="container dilProduct-page" ref={containerRef} style={{ width: '100%', height: '100%', zIndex: '-2', position: 'relative' }}>
            <Box className="dilProduct-wrap">
                <Box className="dilProduct-card">
                    <Box className="dilpro-body">
                        <Box className="pro-header">
                            <Box className="pro-img">
                                <img src={coverImage} alt="" />
                            </Box>
                        </Box>
                        <Box className="pro-body">
                            <Typography className="title">
                                Principal :{" "}
                                <Box className="pro-value" component="span">
                                    ${formatNumber(offerData?.offer_amount ? offerData?.offer_amount : 0)}
                                </Box>{" "}
                            </Typography>

                            <Typography className="title">
                                Interest :{" "}
                                <Box className="pro-value" component="span">
                                    {formatNumber(offerData?.loan_roi)}%
                                </Box>{" "}
                            </Typography>

                            <Typography className="title">
                                Months :{" "}
                                <Box className="pro-value" component="span">
                                    {offerData?.loan_duration}
                                </Box>{" "}
                            </Typography>

                            <Typography className="title">
                                EMI/months :{" "}
                                <Box className="pro-value" component="span">
                                    ${formatNumber(Number(emiData[0]) ? convertFromDecimal(Number(emiData[0])) : 0)}{" "}
                                </Box>{" "}
                            </Typography>
                            <Typography className="title">
                                Total Interest Amount :{" "}
                                <Box className="pro-value" component="span">
                                    ${formatNumber(Number(emiData[1]) ? convertFromDecimal(Number(emiData[1])) : 0)}{" "}
                                </Box>{" "}
                            </Typography>
                            <Typography className="title">
                                Total Principal + Interest Amount :{" "}
                                <Box className="pro-value" component="span">
                                    ${formatNumber(Number(emiData[2]) ? convertFromDecimal(Number(emiData[2])) : 0)}{" "}
                                </Box>{" "}
                            </Typography>
                        </Box>
                        <Box className="lender-details">
                            <Typography className="lender">
                                Lender Address: {offerData?.investor_details?.wallet_address}
                            </Typography>
                            <Typography className="broker">
                                Borrower Address: {offerData?.proposer_details?.wallet_address}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default CreateTokenImage;
