import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactImageGallery from 'react-image-gallery';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VideoThumbnail from 'react-video-thumbnail';

export default function GallerySlider({ images, coverPhoto }) {
    const [data, setData] = useState([]);
    const isXs = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        // Combine images and cover photo
        let combinedData = [];
        images?.forEach((val) => {
            combinedData.push({
                original: `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`,
                thumbnail: val?.images?.type === 'mp4' ? `${process.env.REACT_APP_IMAGE_URL}${images[0]?.images.path}` : `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`,
                isVideo: val?.images?.type === 'mp4' ? true : false,
            });
        });

        if (coverPhoto) {
            combinedData.unshift({
                original: `${process.env.REACT_APP_IMAGE_URL}${coverPhoto.path}`,
                thumbnail: `${process.env.REACT_APP_IMAGE_URL}${coverPhoto.path}`,
                isVideo: false,
            });
        }

        setData(combinedData);
    }, [images, coverPhoto]);

    const renderThumbnail = (item) => {
        if (item.isVideo) {
            return (
                <div className="video-thumbnail">
                    <VideoThumbnail
                        videoUrl={item.original}
                        style={{ width: '100%', height: '100%' }}
                        snapshotAtTime={2} // Adjust the time as needed
                    />
                    <div className="play-icon-overlay">
                        <PlayCircleOutlineIcon fontSize="large" />
                    </div>
                </div>
            );
        }

        return <img src={item.thumbnail} alt="" height='auto' width="100%" />;
    };

    const renderItem = (item) => {
        if (item.isVideo) {
            return (
                <div className="video-wrapper">
                    <video controls width="100%" height="auto" style={{ marginBottom: '40px' }} className='image-gallery-image'>
                        <source src={item.original} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            );
        }

        return (
            <img src={item.original} alt="" className='image-gallery-image' />
        );
    };

    return (
        <Box className="details-thumbnails-slider">
            {data && data.length > 0 ? (
                <ReactImageGallery
                    items={data}
                    autoPlay={false}
                    showThumbnails={true}
                    thumbnailPosition={isXs ? 'bottom' : 'left'} // Set the position based on screen size
                    infinite={false}
                    showPlayButton={false}
                    lazyLoad={true}
                    useBrowserFullscreen={true}
                    originalHeight="426px"
                    originalWidth="200px"
                    showIndex={true}
                    disableThumbnailScroll={false}
                    showNav={false}
                    showBullets={true}
                    showFullscreenButton={true}
                    slideOnThumbnailOver={false}
                    disableThumbnailSwipe={false}
                    renderItem={renderItem}
                    renderThumbInner={renderThumbnail} // Use custom render for thumbnails
                />
            ) : (
                <Box>
                    <Typography variant="h6" sx={{ textAlign: 'center' }}>
                        No Images available
                    </Typography>
                </Box>
            )}
        </Box>
    );
}
