import "react-responsive-carousel/lib/styles/carousel.min.css";
import React, { useState, useEffect, useRef } from "react";
import { useLoaderData, useLocation } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { GetApi, GetApiParam } from "../../Api/Api";
import Cards from "../../Components/Cards/Cards";
import Filter from "../Home/Filter";
import "../Home/Home.css";

const SeeAllPage = () => {
  const location = useLocation();

  const data = useLoaderData(); //getting data first hand when page loads
  const [assetData, setAssetData] = useState(data?.data);
  const [assetDataFull, setAssetDataFull] = useState(data)

  // State variable for the dynamic inputes
  const [defaults, setDefaults] = useState({});
  const [bookmark, setBookmark] = useState() // for Bookmark
  const [serverTime, setServerTime] = useState();
  const [serverDate, setServerDate] = useState();
  const [serverClockTime, setServerClock] = useState(null);
  const serverTimeInterval = useRef(null)

  // fetching data from local
  const localData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : ""

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    setTimer(assetDataFull)
    setServerTime(assetDataFull.server_time ? assetDataFull.server_time : null);
    setServerDate(assetDataFull.server_date ? assetDataFull.server_date : null);
  }, [assetDataFull])

  // for Bookmark functionality
  useEffect(() => {
    if (localData?.id) {
      // Api to get getBookmarkData
      async function getBookmarkData() {
        try {
          const payloadData = {
            "user_id": localData.id
          }
          const res = await GetApiParam("/proposer/getBookmark/", payloadData);
          setBookmark(res?.data?.data ? res?.data?.data : [])

          // setInvestmenTypeData(res?.data?.data ? res?.data?.data : []);

        } catch (error) {
          console.log(error);
        }
      }
      const timer = setTimeout(() => {
        getBookmarkData()
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [])

  useEffect(() => {
    // Update default values only if location.state is available
    if (location.state && location.state !== Object.entries({})) {
      setDefaults({
        defaultFilter: location.state.defaultFilter || "",
        defaultSearchString: location.state.searchString || "",
        defaultSortBy: location.state.sortBy || "",
      });
    }
  }, [location.state]);





  // functionality for Timer
  function setTimer(res) {
    // initialize server clock
    if (res.data?.length > 0) {
      const serverClockTime = new Date(res?.server_date);
      var timestamp = res?.server_time.split(":");
      serverClockTime.setHours(timestamp[0]);
      serverClockTime.setMinutes(timestamp[1]);
      serverClockTime.setSeconds(timestamp[2]);
      setServerClock(serverClockTime);
    }
  }

  useEffect(() => {
    serverTimeInterval.current = setInterval(() => {
      if (serverClockTime) {
        serverClockTime.setSeconds(serverClockTime.getSeconds() + 1);
        setServerClock(serverClockTime);
        const event = new CustomEvent("clocktime", {
          detail: serverClockTime,
          bubbles: true,
        });
        document.dispatchEvent(event);
      }
    }, 1000);

  }, [serverClockTime])


  // useEffect for calling api's
  async function getAssetData() {
    try {
      const res = await GetApi("/proposer/getAssetsList");
      setAssetData(res.data?.data);
      setServerTime(res.data?.server_time);
      setServerDate(res.data?.server_date);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };


  /**
   * set the data from the filter component
   */
  const FilteredData = (data) => {
    setAssetData(data);
  };

  const handleSortChange = (sortBy) => {

    if (assetData || assetData.length !== 0) {
      if (sortBy === 'Name') {
        assetData?.sort((a, b) => a?.asset_title?.localeCompare(b?.asset_title));
        setAssetData([...assetData]);
      }
      else if (sortBy === 'Amount') {
      } else if (sortBy === 'Asset Type') {
        assetData?.sort((a, b) => a?.assets_category?.title?.localeCompare(b?.assets_category?.title));
        setAssetData([...assetData]);
      }
      else if (sortBy === 'Default') {
      }
    }

  };


  // functionality for Bookmark
  const bookmarkVal = (asset_id) => {
    return bookmark?.some((item) => item.asset_id === asset_id && item.status === 1);

  }

  return (
    <Box>
      {/*All projects Explore start */}
      <Box className="HomeExplore-section" sx={{ mb: 6, mt: 3 }}>
        <Box className="container">
          <Box className="filter-panel">
            <Box className="fp-left">
              <Box className="heading-left">
                <Typography>All Projects</Typography>
                <Typography component={"h6"}>
                  Explore{" "}
                  <Box component={"span"} className="title-icon">
                    🔎
                  </Box>{" "}
                </Typography>
              </Box>
            </Box>

            <Box className="fp-right">
              <Box className="filters-group-wrap">
                <Filter
                  FilteredData={FilteredData}
                  defaultFilter={defaults.defaultFilter}
                  defaultSearchString={defaults.defaultSearchString}
                  defaultSortBy={defaults.defaultSortBy}
                  onSortChange={handleSortChange}
                  sortByOptions={["Default", "Name", "Asset Type"]} // "default
                />
              </Box>
            </Box>

          </Box>
          <Box className="HomeExplore-wrap">
            <Grid spacing={{ xs: 1, sm: 1, md: 2 }} container>
              {(() => {
                const topAssets = [];
                for (
                  let i = 0;
                  i < (assetData?.length >= 1 ? assetData.length : "");
                  i++
                ) {
                  const galleryimg = [];
                  // Adding Cover Photo into slider
                  if (assetData[i]?.asset_coverphoto) {
                    galleryimg.push(
                      `${process.env.REACT_APP_IMAGE_URL}${assetData[i].asset_coverphoto?.path}`
                    );
                  }
                  // Inserting gellery Images Into Slider
                  assetData[i]?.asset_gallery.map((val, ind) => {
                    if (val.title === "gellary") {
                      galleryimg.push(
                        `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`
                      );
                    }
                  });
                  topAssets.push(
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Cards
                        id={assetData[i]?.id}
                        title={assetData[i]?.asset_title}
                        subTitle={assetData[i]?.asset_address}
                        address={assetData[i]?.owner_information?.owner_address}
                        investType={
                          assetData[i]?.investment_type?.investment_name
                        }
                        isLoan={assetData[i]?.assets_category?.title}
                        isDraft={false}
                        isClock={true}
                        investment_expiry_time={assetData[i]?.investment_expiry_time}
                        isPending={
                          assetData[i]?.status === "Pending" ||
                            assetData[i]?.status === "inreview"
                            ? true
                            : false
                        }
                        isDelete={
                          assetData[i]?.status === "Rejected" ? true : false
                        }
                        isSaved={false}
                        imgSrc={galleryimg}
                        isSaveDeal={true}
                        server_date={serverDate}
                        server_time={serverTime}
                        link={`/project-details/${assetData[i]?.id}`}
                        ownerName={assetData[i]?.user_details?.first_name}
                        ownerImage={assetData[i]?.user_details?.persona_picture}
                        bookMarkStatus={bookmarkVal(assetData[i]?.id)}
                      />
                    </Grid>
                  );
                }
                return topAssets;
              })()}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default SeeAllPage;
