import React, { useEffect, useState } from 'react';
import { styled } from "@mui/material/styles"
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Chip, Box, Paper, InputBase, TableRow, TableHead, TableContainer, Table, TableBody, Typography, MenuItem, ListItemText, ImageListItem, FormControl, InputLabel, Select, Stack, Grid, Modal, IconButton } from '@mui/material';
import { ImageOutlined, RemoveRedEye, Edit, Download, SwapVert, Search, ViewComfy } from '@mui/icons-material';
import FileViewer from "react-file-viewer";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import { GetApiParam } from '../../Api/Api';
import '../Transactions/Transactions.css'

// sort options
const sortByOptionsOffers = ['Project Name', 'Document Name', 'Status'];

// Styles for table columns
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#05131B',
        color: '#94A3B8',
        borderBottom: '0px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: '0px'
    },
}));

// Styles for table rows
const StyledTableRow = styled(TableRow)(({ theme }) => ({
}));

const Reports = () => {
    /**
     * Initialize data
     */
    const [documentsData, SetDocumentsData] = useState(
        [

        ]
    );
    const [searchString, setSearchString] = useState('');
    const [sortBy, setSortBy] = useState('Default');
    const [view, setView] = useState(false);
    const [file, setFile] = useState("");
    const [type, setType] = useState("");
    const [openModal, setOpenModal] = useState(false);

    /**
     * here we get documents list
     */
    useEffect(() => {
        let u = localStorage.getItem('user_data');
        let User = JSON.parse(u);
        const getOffers = async () => {
            let res = await GetApiParam('/transaction/getTransactions', { investor_id: User.id });
        }
        getOffers();
    }, [])

    /**
     * searches in the data [title]
     * @param {string} toSearch 
     * @returns {void}
     */
    const searchInData = (toSearch) => {
        setSearchString(toSearch)
        if (toSearch.length > 2) {
            SetDocumentsData(documentsData.filter(o => o.p_name.toLowerCase().includes(toSearch.toLowerCase())))
        }
        if (toSearch.length < 1) {
            SetDocumentsData(documentsData)
        }
    }

    /**
     * sort data by document name, status ans project name
     * @param {event} event 
     * @returns {void}
     */
    const handleSortByChange = (event) => {
        setSortBy(event.target.value);
        let value = event.target.value;
        if (value === 'Status') {
            SetDocumentsData([...documentsData].sort((a, b) => a.status.localeCompare(b.status)))
        }
        else if (value === 'Document Name') {
            SetDocumentsData([...documentsData].sort((a, b) => a.d_name.localeCompare(b.d_name)))
        }
        else if (value === 'Project Name') {
            SetDocumentsData([...documentsData].sort((a, b) => a.p_name.localeCompare(b.p_name)))
        }
        else {
            SetDocumentsData(documentsData)
        }
    }

    // Merge the two arrays
    let mergedArray = [{
        images: {
            "path": "ajay.doc.sample.pdf",
        }
    }];

    /**
     * open modal to display doc content
     * @param {Array} documentdata 
     * @returns {void}
     */
    const viewDoc = (documentdata) => {
        setOpenModal(true);
        setView(true);
        if (documentdata?.images.path) {
            const fileName = `${process.env.REACT_APP_IMAGE_URL}${documentdata.images.path ? documentdata.images.path : ""}`;
            setFile(fileName);
        }
        if (documentdata?.images.type === 'document' || documentdata?.images.type === 'doc') {
            setType('pdf');
        } else {
            setType(documentdata?.images.type);
        }
    };

    /**
     * handle modal close
     */
    const handleClose = () => { setOpenModal(false); setView(false) };

    // modal styles
    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    };

    return (
        <>
            {/* <Box className="filter-panel" mt={4}>
                <Box className="fp-left">
                    <Box className="heading-left">
                        <Typography component={"h6"}>
                            My Documents
                        </Typography>
                    </Box>
                </Box>
                <Box className="fp-right">
                    <Box className="filters-group-wrap">
                        <Box className="filters-group-wrap">
                            {/* Search bar filter */}
            {/*  <Box className="filterSearch-form">
                                <Box component={"span"} className="search-icon">
                                    <Search />
                                </Box>
                                <InputBase
                                    // ref={searchBarRef}
                                    className="filterSearch"
                                    placeholder={"Search documents"}
                                    value={searchString}
                                    onChange={(e) => searchInData(e.target.value)}
                                    inputProps={{ "aria-label": "search in transactions" }}
                                />
                            </Box>
                        </Box>

                        {/* Category filter */}
            {/* <Box className="select-group" ml={1.5}>
                            <FormControl className="select-rounded" sx={{ minWidth: '150px' }} size="small">
                                <InputLabel id="demo-controlled-open-select-label">
                                    Category
                                    <ViewComfy />
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    label="Category"
                                    variant='outlined'
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    {
                                        []?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value.id}>
                                                    <ListItemText>{value.title}</ListItemText>
                                                    <Typography className="menu-icon">
                                                        <ImageListItem>
                                                            <img
                                                                src={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                                                srcSet={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                                                alt={value.category_icon?.original_name}
                                                                loading="lazy"
                                                            />
                                                        </ImageListItem>
                                                    </Typography>
                                                </MenuItem>
                                            );
                                        })

                                    }
                                </Select>
                            </FormControl>
                        </Box>*/}

            {/* Sorting  */}
            {/* <Box className="select-group" ml={1.5}>
                            <FormControl className="select-rounded" sx={{ minWidth: '150px' }} size="small">
                                <InputLabel id="demo-controlled-open-select-label">
                                    Sort By
                                    <SwapVert />
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    value={sortBy}
                                    onChange={handleSortByChange}
                                    label="Sort By"
                                    variant='outlined'
                                >
                                    <MenuItem value={'Default'}>Default</MenuItem>
                                    {
                                        sortByOptionsOffers?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value}>
                                                    <ListItemText>{value}</ListItemText>
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>

                        </Box>
                    </Box>
                </Box>
            </Box> */}
            <TableContainer component={Paper} className='TableContainer'>
                <Table aria-label="customized table">
                    <TableHead className='TableHeader'>
                        <TableRow>
                            <StyledTableCell>Document Name</StyledTableCell>
                            <StyledTableCell align="left">Project Name</StyledTableCell>
                            <StyledTableCell align="left">Category</StyledTableCell>
                            <StyledTableCell align="center">Date</StyledTableCell>
                            <StyledTableCell align="center">Status</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documentsData?.map((row, i) => (
                            <StyledTableRow className='TableRow' key={`${row.asset_data?.asset_title}${i}`}>
                                <StyledTableCell component="th" scope="row">{row.d_name}</StyledTableCell>
                                <StyledTableCell align="left">  {row.p_name}</StyledTableCell>
                                <StyledTableCell align="left">
                                    <Chip
                                        sx={{
                                            marginTop: 2,
                                            marginBottom: 2,
                                            color: "#FFFFFF",
                                            bgcolor: "#13202D",
                                            fontSize: "12px",
                                            borderRadius: "8px",
                                        }}
                                        label={row.category === "Asset" ? "Luxury Cars" : ""}
                                        icon={
                                            <ImageOutlined
                                                sx={{ color: "#FFFFFF !important" }}
                                            />
                                        }
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="left">   {row.date}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <Chip
                                        sx={{
                                            marginTop: 2,
                                            marginBottom: 2,
                                            color: "#13202D",
                                            bgcolor:
                                                row.status === "Completed"
                                                    ? "#34D399"
                                                    : row.status === "Pending Signature"
                                                        ? "#E1D3A1"
                                                        : "",
                                            borderRadius: "8px",
                                        }}
                                        label={row.status}
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="baseline"
                                        spacing={3}
                                    >
                                        <IconButton>
                                            <RemoveRedEye onClick={() => viewDoc(mergedArray[0])} style={{ color: "#FFFFFF" }} />
                                        </IconButton>
                                        <IconButton disabled={row.status === "Completed" ? true : false}>
                                            <Edit style={{ color: row.status === "Completed" ? "#053863" : "#FFFFFF" }} />
                                        </IconButton>
                                        <IconButton>
                                            <Download style={{ color: "#FFFFFF" }} />
                                        </IconButton>
                                    </Stack>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* modal code start from here */}
            <Grid>
                {view &&
                    <Modal
                        className="projectDocumentsModal"
                        open={openModal}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        fullWidth={true}
                    >
                        <Box sx={styleModal}>
                            <OverlayScrollbarsComponent defer
                                style={{ width: '100%', height: '100%' }}
                            >
                                <Box className="raj">
                                    {<FileViewer fileType={type} filePath={file} />}
                                </Box>
                            </OverlayScrollbarsComponent>
                        </Box>
                    </Modal>
                }
            </Grid>
            {/* modal code end from here */}
        </>
    )
}

export default Reports