import React, { useState, useEffect, useCallback } from "react";
import { Box, Accordion, AccordionDetails, AccordionSummary, Typography, styled } from "@mui/material";
import { GetApi } from "../../Api/Api";
import "./Faq.css";
import { ArrowRightIcon } from "@mui/x-date-pickers";

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    }
}));
const Faq = ({ type }) => {
    const [faqData, setFaqData] = useState([]);
    const [expanded, setExpanded] = React.useState('panel1');

    const getFaqData = useCallback(async(Type) => {
        try {
            const res = await GetApi(`/services/getFAQ?type=${Type}`);
            if (res?.data?.data && res?.data?.data?.length){
                setFaqData(res?.data?.data);
            }else{
                setFaqData([])
            }
          } catch (error) {
            setFaqData([])
          }
    }, []);
    useEffect(() => {
        getFaqData(type);
    }, [type]);
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <>
            <Box className="main-container service-proposer-myproject">
                <Box sx={{ margin: "-10px 0px 30px 0px" }}>
                    <Typography component="h4" className="title font-28">
                       FAQs
                    </Typography>
                </Box>

                        <Box className="post-projct-main" mt={2} ml={0} mr={0}>
                            <Box sx={{ cursor: "pointer" }} >
                                {faqData && faqData.length > 0 ? faqData.map((item, index) => 
                                // <Box className="project-title">
                                //     <Typography component="h4" className="title font-16">
                                //         {item?.question}
                                //     </Typography>
                                //     <Typography
                                //         component="h5"
                                //         className="sub-title font-12"
                                //     >
                                //         {item?.answer}
                                //     </Typography>
                                // </Box>
                                <Accordion key={index} disableGutters elevation={0} className='rc-faq-accordion' expanded={expanded === `faq${item.id}`} onChange={handleChange('faq' + item.id)}>
                                    <CustomAccordionSummary className='rc-faq-summary'
                                        expandIcon={<ArrowRightIcon/>}
                                        aria-controls="panel1bh-content"
                                        id={`faq${item.id}bh-header`}
                                    >
                                        <Typography className='rc-xsmall-secondary-text' sx={{ flexShrink: 0 }}>{item.question}</Typography>
                                    </CustomAccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant='caption'>
                                            {item.answer}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                ):(null)}
                            </Box>
                        </Box>
            </Box>
        </>
    );
};

export default Faq;
