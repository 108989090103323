import { GetApiParam } from "../../Api/Api";
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID } from "../../constants";

export const GetContractAddress = async (assetData, version, contract_name) => {
    try {
        const { category_id, investment_type_id, asset_sub_category } = assetData;

        // if (!version) {
        //     throw new Error('Please send contract version.');
        // }

        const payload = {
            version,
            contract_name,
            ...(((investment_type_id !== INVESTMENT_TYPE_ID.LOAN && assetData?.investment_type?.id !== INVESTMENT_TYPE_ID.LOAN) && category_id) && { category_id }),
            ...(investment_type_id && { investment_type_id }),
        };
        if (category_id === CATEGORY_TYPE_ID.FUNDS && asset_sub_category) {
            payload.asset_sub_category = asset_sub_category
        }

        const res = await GetApiParam(`/user/getContractAbi`, payload);

        if (res?.data?.code === 200 && res?.data?.data?.length) {
            const contract = res.data.data;
            return contract;
        }

        throw new Error('Address and ABI not available.');
    } catch (error) {
        throw error; // propagate the error
    }
};
