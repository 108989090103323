import React from 'react'
import { Typography, Avatar, Box } from '@mui/material';
import './ReachInvestors.css';

const MoreBenefits = (props) => {
    return (
        <Box className='lg-icon-thumbnail moreBenefit bg-blue-900'>
            <Box className="lg-icon-wrap">
                <Avatar className='bg-blue-600' alt="Icon" variant='square' src={props.image}></Avatar>
            </Box>
            <Box className='lg-content' pt={4.1}>
                <Typography component={'h5'}>
                    {props.title}
                </Typography>
                <Typography mt={1.5}>
                    {props.subtitle}
                </Typography>
            </Box>
        </Box>
    )
}

export default MoreBenefits