import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { GetApiParam } from '../../../Api/Api'

export default function SaleInformation({ sale_price, deposite_amount, assetId }) {

    // Constants for the title, prices, and listing type
    const [prices, setPrice] = useState([
        {
            subtitle: 'Sale Price',
            value: sale_price ? sale_price : 0,
        },
        {
            subtitle: 'Deposite Amount',
            value: deposite_amount ? deposite_amount : 0,
        },
    ])


    return (
        <>
            <Box sx={{ px: '10px', mt: '8px' }} className="PO-price-set-h5">
                <Typography className='font-22' style={{ margin: '10px 0px', fontWeight: '500' }}>Sale Information</Typography>
                <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
                    {prices.map((price, index) => (
                        <Grid key={index} item sx={{ flexBasis: '50%', pb: '10px' }} xs={12} sm={6}>
                            <Typography className='rc-small-secondary-text' variant='body2' >{price.subtitle}</Typography>
                            <Typography className='font-22' >${price.value}</Typography>
                        </Grid >
                    ))}
                </Grid>
            </Box>
        </>
    )
}



