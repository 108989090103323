import React, { useEffect, useState } from 'react'
import "./OnboardingProfileLeft.css";
import { Avatar, Box, Typography } from '@mui/material';
import { newUserImage, Star } from '../../../Components/Images/Images';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import { getUserDataFromLocalStorage } from '../../../Services/localstorage.service';


const OnboardingProfileRight = ({ personalInfoData, profileImage, previousData }) => {
    const localData = getUserDataFromLocalStorage();
    const personalinfoData = personalInfoData;
    const [personalInfoDataVal, setPersonalInfoDataVal] = useState("")

    useEffect(() => {
        if (personalInfoData) {
            setPersonalInfoDataVal(personalInfoData)
        }
    }, [personalInfoData])


    /**
     * Setting data of Dispaly name 
     */
    const displayName = previousData?.firstName ? `${previousData?.firstName} ${previousData?.lastName}` : personalinfoData?.firstName ? `${personalinfoData?.firstName} ${personalinfoData?.lastName}` : null

    return (
        <Box className="onboarding-profile-right-container">
            <Box justifyContent="center" display="flex">
                {profileImage || previousData?.profileImage ? (
                    <img className='profile-img' src={URL.createObjectURL(profileImage ? profileImage : previousData?.profileImage)} alt="Profile" />
                )
                    : (
                        // <img src={newUserImage} alt="Profile" />
                        <Avatar
                            className='profile-img'
                            src={profileImage ? URL.createObjectURL(profileImage) : '/static/images/avatar/1.jpg'}
                            alt={localData?.first_name}
                        />
                    )
                }
            </Box>
            <Typography component="h5" className="username font-28">
                {/* {previousData?.displayName ? previousData?.displayName : personalinfoData?.displayName} */}
                {displayName}
            </Typography>
            <Typography component="h5" className="profession font-16" >
                {
                    previousData?.title
                        ? previousData?.title
                        : personalinfoData?.title
                            ? personalinfoData?.title
                            : null
                }

            </Typography>
            <Typography component="h5" className="location font-14" mt={1}>
                {
                    previousData?.location
                        ? previousData?.location
                        : personalinfoData?.location
                            ? personalinfoData?.location
                            : null
                }

            </Typography>
            <Box className="top-box" mt={2}>
                <Box className="left">
                    {/* <StarBorderOutlinedIcon /> */}
                    <img src={Star} alt='no-image' />
                    <Typography className='text'> 0.0</Typography>
                    {/* <Typography className='text'>{previousData?.hourlyRate
                        ? `$${previousData?.hourlyRate}/hr`
                        : personalinfoData?.hourlyRate
                            ? `$${personalinfoData?.hourlyRate}<span>/hr</span>`
                            : "$00.00/hr"} </Typography> */}
                    <Typography className='text hourly-rate-amount ' dangerouslySetInnerHTML={{
                        __html:
                            previousData?.hourlyRate
                                ? `$${previousData.hourlyRate}/hr`
                                : personalinfoData?.hourlyRate
                                    ? `$${personalinfoData.hourlyRate}<span>/hr</span>`
                                    : "$00.00/hr"
                    }} />
                </Box>
                <Box className="right">
                    <WorkOutlineOutlinedIcon />
                    <Typography className='text'> 00 jobs</Typography>
                </Box>
            </Box>
            <Box className="" mt={2} textOverflow={"revert-layer"}>
                <Typography component="p" className='disc-text font-16' style={{ whiteSpace: 'pre-line' }}>
                    {previousData?.description || personalinfoData?.description || ''}
                </Typography>
            </Box>
            <Box className='list-data'>
                {/* <ul >
                    <li>Knows HTML and CSS3,PHP,JQuery,Wordpress,and SEO</li>
                    <li>Full project management from start to finish</li>
                    <li>Regular communication is important to me, so let's keep in touch</li>
                </ul> */}
            </Box>
        </Box>
    )
}


export default OnboardingProfileRight