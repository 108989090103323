import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Chip, Button, FormControl, Pagination, RadioGroup } from "@mui/material";
import ServiceProviderSearch from "../Components/ServiceProviderSearch/ServiceProviderSearch";
import { GetApiParam, postApi } from "../../Api/Api";
import ProjectFilter from "../ServiceProviderDashBoard/ProjectFilters/ProjectFilter"
import AddIcon from "@mui/icons-material/Add";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import Bookmarks from "@mui/icons-material/Bookmarks";
import "./BrowserRequest.css";
import { Link, useLocation } from "react-router-dom";
import CommonRadio from "../../Components/CommonRadio/CommonRadio";
import { calculateNewSinceCreation, calculateDaysSinceCreation } from "../../Utils/utils";
import { getUserDataFromLocalStorage } from "../../Services/localstorage.service";
import CommonBackdropLoader from "../../Components/Common/CommonBackdropLoader";

const BrowserRequest = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [selectedCategoryData, setSelectedCategoryData] = useState(location.state && location.state?.category ? location.state?.category : { title: 'Any Type' });
  const [searchString, setSearchString] = useState(location.state?.search ? location.state?.search : '');
  const [categoryData, setCategoryData] = useState(null);
  const [projectServiceDetails, setProjectServiceDetails] = useState([]);
  const [expandedStates, setExpandedStates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [paginationData, setPaginationData] = useState({
    "page": 1,
    "pageSize": 5,
    "totalCount": 0,
    "totalPages": 0
  });
  const [filter, setFilter] = useState({
    "priceRangeStart": null,
    "priceRangeEnd": null,
    "location": null,
    "rating": null,
    "sort": null,
    "savedRequest": null,
  });

  const localData = getUserDataFromLocalStorage();

  useEffect(() => {
    const scrollTopFunction = () => {
      document.documentElement.scrollTop = 0;
    }
    scrollTopFunction()
  }, []);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    document.documentElement.scrollTop = 0;
  };

  const handleChange = (event, newValue) => {
    const category = categoryData && categoryData.length > 0 ? categoryData.filter((item) => item.title === newValue) : null;
    if (category && category.length > 0) {
      setSelectedCategoryData(category[0]);
    } else {
      setSelectedCategoryData({ title: 'Any Type' })
    }
  };

  const serviceProviderData = async () => {
    try {
      setLoading(true);
      let payload = {
        status: 1,
        user_id: localData.id,
        login_user_id: localData.id,
      };
      if (selectedCategoryData && selectedCategoryData?.title !== 'Any Type') {
        payload = {
          ...payload,
          sp_category_id: selectedCategoryData?.id
        }
      }
      if (filter?.savedRequest === "savedRequest") {
        payload = {
          ...payload,
          savedRequest: true
        }
      }
      payload = {
        ...payload,
        searchString: searchString
      }
      if (filter?.sort && filter?.sort !== 'all') {
        payload = {
          ...payload,
          sort: filter?.sort
        }
      }
      if ((filter?.priceRangeStart || filter?.priceRangeStart === 0) && (filter?.priceRangeEnd || filter?.priceRangeEnd === 0)) {
        payload = {
          ...payload,
          priceRangeStart: filter?.priceRangeStart,
          priceRangeEnd: filter?.priceRangeEnd
        }
      }
      const projectRes = await GetApiParam(
        `/services/sp_projectRequirement?page=${currentPage}&rowperpage=${itemsPerPage}`,
        payload
      );
      if (projectRes?.data && projectRes?.data?.code === 200) {
        setProjectServiceDetails(projectRes?.data?.data || []);
        setPaginationData(projectRes?.data?.pagination)
        // Initialize expanded state for each card
      } else {
        // setProjectServiceDetails(projectRes?.data?.data || []);
        // setPaginationData(projectRes?.data?.pagination)
      }
      setExpandedStates(new Array(projectRes?.data?.data.length).fill(false));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const fetchProjectAccordingToCategory = (selectedCategory) => {
    setSelectedCategoryData(selectedCategory);
  }

  useEffect(() => {
    if (location.state && location.state?.category && location.state?.category?.id) {
      setSelectedCategoryData(location.state?.category);
    }
    setSearchString(location.state?.search ? location.state?.search : '');
  }, [location.state])

  useEffect(() => {
    serviceProviderData();
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    serviceProviderData();
  }, [selectedCategoryData, filter, searchString]);

  useEffect(() => {
    async function fetchData() {
      try {
        const payload = {
          user_id: localData.id
        }
        const categoryRes = await GetApiParam("/services/spCategory", payload);
        setCategoryData(categoryRes?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, []);

  const handleViewMore = (ind) => {
    setExpandedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[ind] = true;
      return newStates;
    });
  };

  const handleViewLess = (ind) => {
    setExpandedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[ind] = false;
      return newStates;
    });
  };

  /**
   *
   * @param {*} item
   */
  const handleClickBookmark = async (item) => {
    const payloadData = {
      project_id: item.id,
      user_id: localData.id,
      is_bookmark: item.is_bookmark ? 0 : 1,
    };

    if (localData.id) {
      const bookmarkResponce = await postApi(
        "/services/isBookmark",
        payloadData
      );
      if (bookmarkResponce?.data?.code) {
        serviceProviderData();
      }
    }
  };

  return (
    <Box className="container">
      <CommonBackdropLoader loading={loading} />
      <Box className="brower-request-container">
        <Box className="find-profile-page">
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={4}
            maxWidth={"660px"}
          >
            <Typography className="title"
            >
              Find the best job for your expertise
            </Typography>
            <Typography className="font-18">
              Browse jobs posted on RACE, or jump right in and create a free
              profile to find the work that you love to do.
            </Typography>
          </Box>
          <Box>
            <Box mt={3}>
              <ServiceProviderSearch
                categoryData={categoryData}
                showSearchBar={false}
                page={'findWork'}
                fetchProjectAccordingToCategory={fetchProjectAccordingToCategory}
              />
            </Box>
          </Box>
        </Box>
        <Box className="brower-request-main">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Box className="brower-request-left">
                  <Typography className="title">Types of skillsets</Typography>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <FormControl>
                      <RadioGroup value={selectedCategoryData?.title} onChange={handleChange}>
                        <CommonRadio value="Any Type" label="Any type" size="sm" color="#945b3b" />
                        {categoryData &&
                          categoryData.map((categoryVal, index) => (
                            <CommonRadio
                              key={index}
                              value={categoryVal.title}
                              label={
                                categoryVal.title ===
                                  "Technology Developers & Software Services"
                                  ? "Development & IT"
                                  : categoryVal.title
                              } size="sm" color="#945b3b" />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={9}>
                <Box className="brower-request-right">
                  <Box mb={2} className="filter-container">
                    <ProjectFilter
                      title={"Results"}
                      totalServices={paginationData?.totalCount}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  </Box>
                  {projectServiceDetails?.length ? (
                    <Grid container spacing={2}>
                      {projectServiceDetails.map((serviceVal, ind) => (
                        <Grid item xs={12} md={6} key={ind}>
                          <Box className="card-box">
                            <Box className="top-header">
                              <Box className="top-left">
                                <Box className="pc-chip-wrep-browse">
                                  {calculateNewSinceCreation(serviceVal?.createdAt) === 'new' ?
                                    <Chip className="pc-chip" label={"New"} /> : ""}
                                </Box>
                                <Box className="top-sub-left">
                                  <Typography className="font-16">
                                    {serviceVal?.project_title}
                                  </Typography>
                                  <Typography className="font-12">
                                    {serviceVal?.budget === "Project Budget"
                                      ? "Fixed-price"
                                      : serviceVal?.budget} | Posted {calculateDaysSinceCreation(serviceVal?.createdAt)} ago
                                  </Typography>
                                </Box>
                              </Box>
                              {serviceVal && serviceVal?.is_bookmark ? (
                                <Box className="top-right">
                                  <Bookmarks
                                    className="bookmark-icon cursor-pointer"
                                    onClick={() =>
                                      handleClickBookmark(serviceVal)
                                    }
                                  />
                                </Box>
                              ) : (
                                <Box className="top-right">
                                  <BookmarkBorderIcon
                                    className="bookmark-icon cursor-pointer"
                                    onClick={() =>
                                      handleClickBookmark(serviceVal)
                                    }
                                  />
                                </Box>
                              )}
                            </Box>
                            <Box className="card-body">
                              <Box className="card-body-top">
                                <Box className="left-section">
                                  <Typography className="title font-12">
                                    {serviceVal?.budget === "Project Budget"
                                      ? "Fixed-price"
                                      : serviceVal?.budget}
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="sub-title font-16"
                                  >
                                    ${serviceVal?.budget_from}
                                  </Typography>
                                </Box>
                                <Box className="right-section">
                                  <Typography
                                    component="h4"
                                    className="title font-12"
                                  >
                                    Experience Level
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="sub-title font-16"
                                  >
                                    {serviceVal?.level_of_experience}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box className="card-body-discription">
                                {expandedStates[ind] ? (
                                  <>
                                    <Typography
                                      component="p"
                                      className="title font-14"
                                    >
                                      {serviceVal?.describe_services}
                                    </Typography>
                                    <Link onClick={() => handleViewLess(ind)}>
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          color: "#398CD1",
                                          fontSize: "14px",
                                          fontWeight: 700,
                                          textDecoration: "underline",
                                          textDecorationColor: "#398CD1",
                                        }}
                                        mt={1}
                                      >
                                        View Less
                                      </Typography>
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    <Typography
                                      component="p"
                                      className="title font-14"
                                      sx={{
                                        mt: "12px",
                                        color: "#FFFFFF",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        WebkitLineClamp: "2",
                                        WebkitBoxOrient: "vertical",
                                      }}
                                    >
                                      {serviceVal?.describe_services}
                                    </Typography>
                                    <Link onClick={() => handleViewMore(ind)}>
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          color: "#398CD1",
                                          fontSize: "14px",
                                          fontWeight: 700,
                                          textDecoration: "underline",
                                          textDecorationColor: "#398CD1",
                                        }}
                                        mt={1}
                                      >
                                        View More
                                      </Typography>
                                    </Link>
                                  </>
                                )}
                              </Box>

                              <Box className="button-list">
                                {serviceVal?.skills ?
                                  serviceVal?.skills?.map(
                                    (skillVal, index) => (
                                      <Button key={index}>
                                        {skillVal.skill_name}
                                      </Button>
                                    )
                                  )
                                  : serviceVal?.skillData?.map(
                                    (skillVal, index) => (
                                      <Button key={index}>
                                        {skillVal.skill_name}
                                      </Button>
                                    )
                                  )}
                              </Box>
                              <Box className="see-more-btn">
                                <Link to={`/service-provider/view-single-request/${serviceVal?.id}`}>
                                  <Button
                                    variant="contained"
                                    startIcon={<AddIcon />}
                                  >
                                    See more
                                  </Button>
                                </Link>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    null
                  )}
                </Box>
              </Grid>
              <Box className="pagination-container" ml={42} px={{ md: '0', xs: '30px' }}>
                <Pagination
                  count={paginationData?.pageSize === 'all' ? 1 : Math.ceil(paginationData?.totalCount / Number(paginationData?.pageSize))}
                  page={currentPage}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BrowserRequest;
