// Import Bubik fonts
import "@fontsource/rubik/300.css";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/600.css";
import "@fontsource/rubik/700.css";

// Import CSS file
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { RouterProvider } from "react-router-dom";
import 'overlayscrollbars/overlayscrollbars.css'; //imported as per overlayscrollbars-react library
import "./Assets/Css/Base.css";
import "./Assets/Css/Custom.css";
import "./Assets/Css/Responsive.css";
import "./App.css";
import router from "./Routes/NewRouter";
import WalletProvider from "./Web3/WalletProvider";
import { SnackbarProvider } from "./Contexts/SnackbarContext";
import AppGlobalState from "./AppGlobalState";
import { SocketProvider } from "./Contexts/SocketContext";
import ChatBotPage from "./Components/ChatBot/ChatBotPage";
import { AuthProvider } from "./Contexts/AuthContext";
import {ToastContainer } from 'react-toastify';

// Default Theme
const theme = createTheme({
  typography: {
    fontFamily: [
      'Rubik', 'sans-serif'
    ].join(','),
  }
});

function App() {

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SocketProvider>
          <SnackbarProvider>
            <WalletProvider>
              <AppGlobalState />
              <div className="wrapper">
                <RouterProvider router={router} />
              </div>
              <ToastContainer/>
              {/* <ChatBotPage /> */}
            </WalletProvider>
          </SnackbarProvider>
        </SocketProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
