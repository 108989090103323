import React, { useEffect, useState } from 'react';
import { Button, Modal, Box, Typography, IconButton, Stack } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import './CustomModal.css';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "var(--blue-900)",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderWidth: "0px",
    "@media (max-width: 600px)": {
        width: "350px", // Set a different height for small screens
    },
    // Additional media queries for other screen sizes if needed
    "@media (min-width: 601px) and (max-width: 900px)": {
        // Custom styles for devices between 601px and 900px width
        width: "580px",
    },
    "@media (min-width: 901px) and (max-width: 1200px)": {
        // Custom styles for devices between 901px and 1200px width
        width: "750px",
        maxHeight: "300px",
        overflow: "auto",
    },
    // Specify the maximum width for large screens
    "@media (min-width: 1201px)": {
        maxWidth: "1043px",
        maxHeight: "525px",
        overflow: "auto",
    },
};

const CustomModal = ({ title, component, isOpen, setIsOpenModal }) => {
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setOpenModal(true);
            setIsOpenModal(true);
        } else {
            setOpenModal(false);
            setIsOpenModal(false);
        }
    }, [isOpen]);

    return (
        <div>
            <Modal
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                    setIsOpenModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="send-custom-offer-container alert-modal"
            >
                <Box sx={style}>
                    <Box className="header">
                        <Typography id="modal-modal-title font-28">
                            {title}
                        </Typography>
                        <IconButton className="close-btn cross-icon-modal" onClick={() => {setOpenModal(false); setIsOpenModal(false);}}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box className="body">
                        {component}
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default CustomModal;