/** get/set/remove User Data */
export const setUserDataInLocalStorage = (data) => {
    localStorage.setItem("user_data", JSON.stringify(data));
}

export const isUserLoggedIn = () => {
    const data = localStorage.getItem("login");
    if(data && data === 'true'){
        return true;
    }
    if(data && data === true){
        return true;
    }
    return false;
}

export const getUserDataFromLocalStorage = () => {
    return localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : {};
}

export const removeUserDataInLocalStorage = () => {
    localStorage.removeItem("user_data");
}

/** get/set/remove Token*/
export const setTokenInLocalStorage = (token) => {
    localStorage.setItem("token", token);
}

export const getTokenInLocalStorage = () => {
    return localStorage.getItem("token");
}

export const removeTokenInLocalStorage = () => {
    localStorage.removeItem("token");
}

/** get/set/remove Refresh Token*/
export const setRefreshTokenInLocalStorage = (refreshToken) => {
    localStorage.setItem("refreshToken", refreshToken);
}

export const getRefreshTokenInLocalStorage = () => {
    return localStorage.getItem("refreshToken");
}

export const removeRefreshTokenInLocalStorage = () => {
    localStorage.removeItem("refreshToken");
}

export const getLoggedInUserType = () => {
    const client = localStorage.getItem("service-proposer");
    if(client && client === 'true'){
        return 'proposer';
    }

    const spv = localStorage.getItem("service-provider");
    if(spv && spv === 'true'){
        return 'spv';
    }
    return '';
}

export const getServiceProviderFromLocalStorage = () => {
    const spv = localStorage.getItem("service-provider");
    if(spv && spv === 'true'){
        return true;
    }
    return false;
}

export const getServiceProposerFromLocalStorage = () => {
    const client = localStorage.getItem("service-proposer");
    if(client && client === 'true'){
        return true;
    }
    return false;
}

export const setServiceProviderInLocalStorage = () => {
    localStorage.setItem("service-provider", true);
}

export const setServiceProposerInLocalStorage = () => {
    localStorage.setItem("service-proposer" , true);
}

export const removeServiceProviderFromLocalStorage = () => {
    localStorage.removeItem("service-provider");
}

export const removeServiceProposerFromLocalStorage = () => {
    localStorage.removeItem("service-proposer");
}
