import { Box, Skeleton, Stack } from '@mui/material'
import React from 'react'

const animation = 'pulse'

function CardSkeleton() {
    return (
        <Box className='product-commonCard'>
            <Box sx={{ height: '236px' }}>
                <Skeleton animation={animation} variant="rounded" height={'100%'}  />
            </Box>
            <Box pt={'16px'}>
                <Skeleton animation={animation} variant="text" height={'36px'} width={'100%'} />
                <Skeleton animation={animation} variant="text" height={'20px'} width={'100%'} />
            </Box>
            <Stack direction="row" spacing={1} mt={2}>
                <Skeleton animation={animation} variant="rounded" height={'20px'} width={'64px'} />
                <Skeleton animation={animation} variant="rounded" height={'20px'} width={'64px'} />
            </Stack>
            <Box className='pc-user-wrap' display={'flex'} alignItems={'center'} mt={3}>
                <Box className="user-thumbnail" display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <Skeleton animation={animation} variant="circular" width={40} height={40} />
                </Box>
                <Box className='user-details' ml={1.8}>
                    <Skeleton animation={animation} height={'24px'} width="124px" />
                    <Skeleton animation={animation} height={'12px'} width="64px" />
                </Box>
            </Box>
        </Box >
    )
}

export default CardSkeleton