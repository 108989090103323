import React from "react";
import { Box } from "@mui/material";
import ExploreProjects from "../Components/Common/ExploreProjects";

const CategoryHeader = ({ data, categoryId }) => {
  return (
    <Box key={data.title}>
      <Box
        className="home-exploreBanner-section realEstate-banner"
        pb={10}
        pt={6}
      >
        <Box className="container">
          <ExploreProjects
            title={
              data.title
                ? data.title
                : "Invest in Real Estate Through the Power of the"
            }
            highlightText={data.highlight}
            subTitle={data.subtitle}
            image={data.image}
            categoryId={categoryId}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default CategoryHeader;
