import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Modal, TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { Upload } from "@mui/icons-material";
import "./AddPortfolioPopup.css"
import { updateApi } from "../../../Api/Api";
import { useSnackbar } from '../../../Contexts/SnackbarContext';
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";

const AddPortfolioPopup = ({ open, handleClose, onSave, editPortfolio, portfolioDetails, getProfileData }) => {
    const { showSnackbar } = useSnackbar();
    const portfolioImages = portfolioDetails?.portfolioImages || "";
    const { path } = portfolioImages;
    const avatarSrc = path ? `${process.env.REACT_APP_IMAGE_URL}${path}` : '';
    const [formValues, setFormValues] = useState({
        title: "" || portfolioDetails?.portfolio_title,
        image: "" || avatarSrc,
    });
    const [errors, setErrors] = useState({
        title: "",
        image: "",
    });
    const [AdditonalDetailsLocal, setAdditionalDetailsLocal] = useState([]); // state to store
    const localData = getUserDataFromLocalStorage();

    useEffect(() => {
        if (portfolioDetails?.image) {
            fetch(portfolioDetails.image)
                .then(response => response.blob())
                .then(blob => {
                    const imageObjectURL = URL.createObjectURL(blob);
                    setFormValues(prevValues => ({
                        ...prevValues,
                        image: imageObjectURL,
                    }));
                })
                .catch(error => console.error('Error fetching image:', error));
        }
    }, [portfolioDetails]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "image") {
            setFormValues({
                ...formValues,
                [name]: files[0],
            });
        } else {
            setFormValues({
                ...formValues,
                [name]: value,
            });
        }
        setErrors({
            ...errors,
            [name]: "",
        });
    };

    const validateForm = () => {
        let isValid = true;
        // Your validation logic goes here
        // For example:
        if (formValues?.title && formValues?.title?.trim() === "") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                title: "Title is required",
            }));
            isValid = false;
        }
        if (!formValues?.title) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                title: "Title is required",
            }));
            isValid = false;
        }
        if (!formValues.image) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                image: "Image is required",
            }));
            isValid = false;
        }
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validateForm();

        if (isValid) {
            // Update the educationDetailsLocal state with the new form values directly
            setAdditionalDetailsLocal([...AdditonalDetailsLocal, formValues]);
            {
                if (!editPortfolio) {
                    onSave({ portfolio_title: formValues.title, portfolio_image: formValues.image, user_id: localData && localData.id, });
                } else {
                    let updateData = new FormData();
                    updateData.append("portfolioImagesId", portfolioImages?.id);
                    updateData.append("portfolioImages", formValues.image);
                    updateData.append("portfolio_title", formValues.title);
                    updateData.append("user_id", localData?.id);
                    updateApi(`/services/portfolio/${portfolioDetails?.id}`, updateData).then((res) => {
                        if (res?.status === 200) {
                            if(res?.data?.code === 200){
                                showSnackbar("Updated successfully!!", 'success');
                                getProfileData();
                            }else{
                                showSnackbar(res?.data?.message, 'success');
                            }
                            handleClose(); // Close the modal
                        } else { // something went wrong user or backend side
                            showSnackbar(res?.data?.message, 'error');
                            handleClose(); // Close the modal
                        }
                    })
                }
            }
            setFormValues({
                title: "",
                image: null,
            });
            handleClose();
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-main"
        >
            <Box className="popup-main">
                <form onSubmit={handleSubmit}>
                    <Box>
                        <Box className="close-icon-main cursor-pointer">
                            <IconButton onClick={handleClose}>
                                <CloseIcon className="cross-icon-modal"/>
                            </IconButton>
                        </Box>
                        {editPortfolio === "edit-protfolio" ?
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                mt={2}
                                className="heading font-32"
                                sx={{ textAlign: "center" }}
                            >
                                Edit Portfolio
                            </Typography> :
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                mt={2}
                                className="heading font-32"
                                sx={{ textAlign: "center" }}
                            >
                                Add Portfolio
                            </Typography>}
                        <Box mt={3}>
                            <TextField
                                name="title"
                                value={formValues.title}
                                onChange={handleChange}
                                className="first-name"
                                label="Portfolio Title"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                placeholder="E.g. Copywriter"
                                error={!!errors.title}
                                helperText={errors.title}
                            />
                        </Box>
                        <Box mt={3}>
                            <Box className="upload-section">
                                <input
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    id="upload-button"
                                    name="image"
                                    type="file"
                                    onChange={handleChange}
                                />

                                <Box mt={2}>
                                    {formValues.image ? (
                                        <img
                                            src={typeof formValues.image === "string" ? formValues.image : URL.createObjectURL(formValues.image)}
                                            alt="Uploaded"
                                            className="uploaded-image"
                                        />
                                    ) : (
                                        null // You can replace this with a placeholder image if necessary.
                                    )
                                    }
                                </Box>
                                <label htmlFor="upload-button" className="upload-img-btn">
                                    <Button
                                        variant="contained"
                                        component="span"
                                        startIcon={<Upload />}
                                        className="upload-img"
                                    >
                                        {editPortfolio === "edit-protfolio" ? <span>Change Image</span> : <span>Upload Image</span>}
                                    </Button>
                                </label>
                                <br />
                                {errors.image && (
                                    <Typography variant="caption" className="font-12" color="error" style={{marginLeft: "15px"}}>
                                        {errors.image}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ textAlign: "center" }} mt={3}>
                            <Button
                                type="submit"
                                variant="contained"
                                startIcon={<SaveIcon />}
                                className="btn-large"
                                sx={{
                                    background: "var(--golden-gradient)",
                                    color: "var(--blueGray-900)",
                                    borderRadius:"16px"
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </form>

            </Box>
        </Modal>
    );
};

export default AddPortfolioPopup;
