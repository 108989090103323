import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  Modal,
  TextField,
  IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./ContactSupportModal.css";
import { useSnackbar } from "../../../../Contexts/SnackbarContext";
import { getUserDataFromLocalStorage } from "../../../../Services/localstorage.service";
import { postApi } from "../../../../Api/Api";

const ContactSupportModal = ({ onOpen, handleClose, contractId }) => {
  const localData = getUserDataFromLocalStorage();
  const [textCount, setTextCount] = useState(["5000"]);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const { showSnackbar } = useSnackbar();

  const handleChange = (event) => {
    const { name, value } = event.target;
    let updatedValue = value;
    if (name === "description") {
      // Check if description length is within the limit
      if (value.length > 5000) {
        updatedValue = value.substring(0, 5000); // Trim the description to 5000 characters
      }
      setTextCount(5000 - updatedValue.length); // Update character count
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if(!Object.keys(errors).every((key) => errors[key] === "")){
      validate();
    }
  }, [formData]);

  const validate = () => {

    const errors = {};
    // Validate Title field
    if (!formData.title.trim()) {
      errors.title = "Title is required";
    }

    // Validate description field (if required)
    // For example, if description is required:
    if (formData.description !== undefined) {
      if (!formData.description.trim()) {
        errors.description = "Description is required";
      }

      if (formData?.description?.length < 500) {
        errors.description =
          "Please enter a description of at least 500 characters.";
      }
    }
    setErrors(errors);
    // If there are no errors, proceed with onSave and handleClose
    return Object.keys(errors).every((key) => errors[key] === "");
  };

  const submitSupport = async (event) => {
    event.preventDefault();
    const isValid = validate();
    if (isValid) {
      try {
        const payload = {
          user_id: localData?.id,
          title: formData?.title,
          description: formData?.description
        }
        const res = await postApi(`/services/createSupportContact/${contractId}`, payload);
        if (res.data && res.data.code === 200) {
          showSnackbar(res?.data?.message, 'success');
          handleClose();
          setFormData({
            title: "",
            description: "",
          });
        } else {
          showSnackbar(res?.data?.message, 'error');
        }
      } catch (error) {
        console.log("Something went wrong", error);
      }
    }
  };

  return (
    <Box className="add-education-popup-main">
      <Button onClick={handleClose}>Open modal</Button>
      <Modal
        open={onOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-main"
      >
        <Box
          id="education-scroller-wrapper"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            borderRadius: "24px",
            width: "900px",
            padding: "52px",

            background: "var(--blueGray-900)",
            boxShadow:
              "0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.15)",
            // Media query for small devices
            "@media (max-width: 600px)": {
              width: "350px", // Set a different height for small screens
            },
            // Additional media queries for other screen sizes if needed
            "@media (min-width: 601px) and (max-width: 900px)": {
              // Custom styles for devices between 601px and 900px width
              width: "580px",
            },
            "@media (min-width: 901px) and (max-width: 1200px)": {
              // Custom styles for devices between 901px and 1200px width
              width: "750px",
              maxHeight: "300px",
              overflow: "auto",
            },
            // Specify the maximum width for large screens
            "@media (min-width: 1201px)": {
              maxWidth: "1043px",
              maxHeight: "525px",
              overflow: "auto",
            },
          }}
        >
          <Box className="close-icon-main">
            <IconButton className='closeButton'>
                <CloseIcon onClick={handleClose}/>
            </IconButton>
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            mt={2}
            className="heading font-36"
            sx={{ textAlign: "center" }}
          >
            Contact Support
          </Typography>
          <form onSubmit={submitSupport}>
            <Box mt={3}>
              <TextField
                name="title"
                value={formData.title}
                onChange={handleChange}
                label="Title"
                variant="outlined"
                fullWidth
                error={!!errors.title}
                helperText={errors.title ? errors.title : ""}
              />
            </Box>

            <Box mt={2}>
              <TextField
                id="description"
                name="description"
                label="Message"
                variant="outlined"
                placeholder="Write your mesage."
                onChange={handleChange}
                value={formData.description}
                fullWidth
                multiline
                rows={4}
                style={{ minHeight: "80px" }}
                error={!!errors.description}
                helperText={errors.description ? errors.description : ""}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>

              <Typography
                paddingLeft={"32px"}
                paddingTop={"3px"}
                className="font-12"
              >
                {" "}
                Enter at least 500 characters
              </Typography>

              <Typography
                paddingRight={"24px"}
                paddingTop={"3px"}
                className="font-12"
              >
                {" "}
                {textCount} characters left
              </Typography>
            </Box>
            <Box mt={3} className="btn-container">
              <Stack spacing={2} direction="row" justifyContent="center">
                {/* <Button variant="outlined" className='cancel-btn' onClick={handleClose}>Cancel</Button> */}
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    background: "var(--golden-gradient)",
                    color: "var(--blueGray-900)",
                  }}
                >
                  Submit
                </Button>
              </Stack>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};


export default ContactSupportModal;
