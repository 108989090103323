//  getFundEscrowWithdrawCount.js
import { readContract } from "@wagmi/core";

/**
 * Fetch user wallet allowance for given spender
 * @param {String} address user wallet address
 * @param {String} contract_address asset data
 * @returns user wallet 
 */
async function getFundEscrowWithdrawCount(address, contract_address, contract_abi) {
    try {
        // Now check for
        const withdrawalCount = await readContract({
            address: contract_address,
            abi: contract_abi,
            account: address,
            functionName: "withdrawalCount",
            chainId: 6805,
            args: [
                address
            ]
        })
        return Number(withdrawalCount);
    } catch (error) {
        return 0
        console.error('Error fetching allowance:', error);
        throw error; // propagate the error
    }
}

export { getFundEscrowWithdrawCount };