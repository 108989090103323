//  spaceXFundTokenBalanceOf.js

import { readContract } from "@wagmi/core";
import convertFromDecimal from "../Common/convertFromDecimal";

/**
 * Fetch user wallet allowance for given spender
 * @param {String} address user wallet address
 * @param {String} contract_address asset data
 * @returns user wallet 
 */
async function spaceXFundTokenBalanceOf(address, contract_address, contract_abi) {
    try {
        // Now check for
        const balanceOf = await readContract({
            address: contract_address,
            abi: contract_abi,
            account: address,
            functionName: "balanceOf",
            chainId: 6805,
            args: [
                address
            ]
        })
        return convertFromDecimal(Number(balanceOf));
    } catch (error) {
        console.error('Error fetching allowance:', error);
        throw error; // propagate the error
    }
}

export { spaceXFundTokenBalanceOf };
