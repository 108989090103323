import React,{useState,useEffect} from 'react'
import './CompanyProfile.css'
import ServicesSearch from '../Components/ServicesSearch/ServicesSearch'
import { Box, Grid } from '@mui/material'
import LeftCompanyProfileSection from './LeftCompanyProfileSection'
import RightCompanyProfileSection from './RightCompanyProfileSection'
import { GetApi } from '../../Api/Api'

function CompanyProfilePage() {

    const [categoryData, setCategoryData] = useState(null);

    useEffect(() => {
      async function getCategoryData() {
        try {
          const res = await GetApi("/services/spCategory");
          setCategoryData(res?.data?.data);
        } catch (error) {
          console.log(error);
        }
      }
  
      getCategoryData()
    }, [])

    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }, [])

    return (
        <Box className="container">
            <ServicesSearch showSearchBar={true} pageTitle={'Service Catalog'} categoryData={categoryData} />
            <Grid container spacing={2} mt={2} >
                <Grid item xs={12} md={8.5} >
                    <LeftCompanyProfileSection />
                </Grid>
                <Grid item xs={12} md={3.5} >
                    <RightCompanyProfileSection />
                </Grid>
            </Grid>
        </Box>
    )
}

export default CompanyProfilePage