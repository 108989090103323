import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, TextField, Button, Typography, IconButton, Avatar, Modal, FormControlLabel, Radio, RadioGroup, FormControl, } from "@mui/material";
import { Remove, Close, Add, DownloadDone, AttachMoney, Done } from "@mui/icons-material";
import NumericFormatCustomDollar from "../../../Components/Common/NumericFormatCustomDollar";
import { NewLogo } from "../../../Components/Images/Images";
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID } from "../../../constants";
import './Components/ProposerStakeRaceTokens.css'
import TransactionProgressModal from "../../../Components/SmartContract/TransactionProgressModal";
import { postApi } from "../../../Api/Api";

// style object for modal
const style = {
    position: "relative",
    boxShadow: 24,
    borderRadius: "24px",
    width: "900px",
    height: '550px',
    // Media query for small devices
    '@media (max-width: 600px)': {
        width: '350px', // Set a different height for small screens
    },
    // Additional media queries for other screen sizes if needed
    '@media (min-width: 601px) and (max-width: 900px)': {
        // Custom styles for devices between 601px and 900px width
        width: '580px'
    },

    '@media (min-width: 901px) and (max-width: 1200px)': {
        // Custom styles for devices between 901px and 1200px width
        width: '750px'
    },

    // Specify the maximum width for large screens
    '@media (min-width: 1201px)': {
        maxWidth: "1043px",
        maxHeight: "687px",
    },
};


export default function ProposerStakeRaceTokens(props) {
    // Initialize state for the total price of the asset
    const [openTransactionModal, setOpenTransactionModal] = useState(false);

    // state to hold payment currency type
    const [selectedOption, setSelectedOption] = useState('USDT');

    /**
  * Handle change in payment type
  * @param {Event} event 
  */
    const handleOptionChange = (event) => { setSelectedOption(event.target.value); };

    const findAmountByInvestment = (investment_id) => {
        let amount = 0;
        if (investment_id === INVESTMENT_TYPE_ID.LOAN) {
            amount = props?.propData?.loan_amount;
        }
        else if (investment_id === INVESTMENT_TYPE_ID.FRACTION) {
            amount = props?.propData?.fractionalize_total_price;
        }
        else if (investment_id === INVESTMENT_TYPE_ID.PARCEL) {
            amount = props?.propData?.parcel_total_price;
        }
        else if (investment_id === INVESTMENT_TYPE_ID.SALE) {
            amount = props?.propData?.sale_price;
        }
        return amount;
    }
    // calculating state amount for proposer
    let stakeAmt = 0;
    let stackAmtData = props?.propData?.stackAmtData;
    let projectAmt = findAmountByInvestment(props?.propData?.investment_type_id)
    if (stackAmtData?.length) {
        let newStake = stackAmtData

        if (props?.propData?.asset_sub_category === 54 || props?.propData?.asset_sub_category === 55) {
            newStake = stackAmtData?.filter((item) => item?.asset_sub_category == props?.propData?.asset_sub_category)
        } else if (props.propData.category_id === CATEGORY_TYPE_ID.ART) {
            newStake = stackAmtData?.filter((item) => item?.investment_type_id === props.propData.investment_type_id)
        } else {
            newStake = stackAmtData
        }

        newStake?.forEach((dataItem) => {
            if (dataItem?.min_amount <= projectAmt && projectAmt <= dataItem?.max_amount) {
                if (dataItem?.staking_type === 'Fixed') {
                    stakeAmt = dataItem?.staking_amount
                }
                else {
                    if (projectAmt) {
                        stakeAmt = Math.ceil(projectAmt * (dataItem?.staking_amount) / 100)
                    }
                }
            }
        })
    }
    const [raceTokens, setRaceTokens] = useState(stakeAmt ? stakeAmt : 0);

    // Initialize state for error messages related to the real-estate asset
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        if (stakeAmt) {
            setRaceTokens(stakeAmt);
        }
    }, [stakeAmt]);

    /**
     * Function to change the 'RtTotalPrice' based on the provided 'type' (either 'inc' or 'dec').
     * @param {string} type - Type of change, either 'inc' or 'dec'.
     */
    const handleRaceTokens = (type) => {
        if (type === "inc") {
            // Increase 'RtTotalPrice' by 10
            setRaceTokens((prevCount) => prevCount ? parseInt(prevCount) + 10 : 10);
        } else {
            // Decrease 'RtTotalPrice' by 10, but ensure it doesn't go below 0
            setRaceTokens((prevCount) =>
                parseInt(prevCount) >= 10 ? parseInt(prevCount) - 10 : prevCount
            );
        }
    };

    /**
     * Function to handle the confirmation of the total price.
     * Checks if 'raceTokens' is greater than or equal to 10, then calls 'userResponse' from props and clears any error message.
     */
    const handleConfirm = async () => {
        if (raceTokens == "" && typeof raceTokens == 'string') {
            setErrorMsg("Please enter tokens.");
            return
        } else if (stakeAmt > raceTokens) {
            setErrorMsg(`Please enter minimum ${stakeAmt} Tokens`, 'error');
            return
        } else {
            setOpenTransactionModal(true);
        }
    };

    /**
     * Function to handle approve race token method
     */
    const handleBuyRaceToken = () => {
    }

    // useEffect to clear the error message when 'raceTokens' is greater than or equal to 10
    useEffect(() => {
        if (raceTokens >= 0 && errorMsg === 'Please enter tokens.') {
            setErrorMsg('');
        } else if (raceTokens >= stakeAmt && errorMsg?.includes('Please enter minimum')) {
            setErrorMsg('');
        }
    }, [raceTokens]);

    /**
     * Function call when transaction successful for confirm stake
     */
    const confirmStake = async (data, amount, asset_id, user_id) => {
        try {
            props.userResponse(raceTokens);
            let body = {
                amount: amount,
                sender_address: data.from,
                contract_address: data.to,
                transaction_hash: data.transactionHash,
                method_name: "proposerStake",
                proposer_id: user_id,
                asset_id: asset_id
            }

            const transactionCreate = await postApi("/user/createBlockchainTransaction", body);
        } catch (error) {
            console.log('error', error);
        }
    }

    const handleModalClose = () => {
        setOpenTransactionModal(false)
    }
    return (
        <>
            <Modal
                open={props.open}
                onClose={props.handleClose}
                className="kyc-modal"
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <Box className="set-stack-rts" sx={style}>
                    <Box className="p-headContent">
                        <Box className="logo">
                            <Avatar
                                alt="Logo"
                                src={NewLogo}
                                variant="square"
                                sx={{ width: 39, height: 90, objectFit: "contain" }}
                            />
                        </Box>
                        <Close className="close-btn" onClick={props.handleClose} />
                    </Box>
                    <Box className="p-headContent" mt={4}>
                        <Box className="modalContentDone" py={{ md: '2px', sm: '0px' }} px={{ md: 18, sm: 2 }}>
                            <Typography component="h1" className="headText">Stake RACE Tokens</Typography>
                            <Box>
                                <Box className="overview-text-box">
                                    <Typography component="p" className="sub-headText overviewText">You need to stake a minimum of {stakeAmt || stakeAmt == 0 ? stakeAmt : 0} RACE tokens to propose a project. Please ensure your wallet has sufficient RACE Tokens for Staking.<br /> Read more about staking <Link to='/dummy' target="_blank" className="link-review overview">here.</Link></Typography >
                                </Box>
                            </Box>
                            <Box className="dic-NS-sale-details overview" mb={1} mt={-1}>
                                <Box className="p-inc-dec-icon">
                                    <IconButton
                                        onClick={() => {
                                            handleRaceTokens("dec");
                                        }}
                                        className="p-inc-dec-icon-btn"
                                    >
                                        <Remove />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <TextField
                                        id="outlined-number"
                                        type="text"
                                        className="loan-text"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            inputComponent: NumericFormatCustomDollar,
                                        }}
                                        FormHelperTextProps={{ style: { color: 'black' } }}
                                        value={raceTokens}
                                        size="small"
                                        helperText={raceTokens < 2 ? "RACE Token" : "RACE Tokens"}
                                        onChange={(e) =>
                                            setRaceTokens(e.target.value)
                                        }
                                        onKeyDown={(event) => {
                                            if (event?.key === "-" || event?.key === "+") {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </Box>
                                <Box className="p-inc-dec-icon">
                                    <IconButton
                                        onClick={() => {
                                            handleRaceTokens("inc");
                                        }}
                                        className="p-inc-dec-icon-btn"
                                    >
                                        <Add />
                                    </IconButton>
                                </Box>
                            </Box>
                            {errorMsg && <Typography sx={{ color: '#FF6865', fontSize: '11px', textAlign: 'center', mb: 2 }}>{errorMsg}</Typography>}

                            <Box className='investor-select-currency-type'>
                                <Typography className='pay-using font-14'>
                                    Pay using
                                </Typography>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="payment-method"
                                        name="payment-method"
                                        defaultValue="USDT"
                                        row
                                        className='currency-options'
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                    >
                                        {/* <FormControlLabel
                                            value="RACE"
                                            control={<Radio />}
                                            label="RACE Token"
                                        /> */}
                                        <FormControlLabel
                                            value="USDT"
                                            control={<Radio />}
                                            label="USDT"
                                        />
                                        <FormControlLabel
                                            value="USDC"
                                            control={<Radio />}
                                            label="USDC"
                                        />
                                        <FormControlLabel
                                            value="DAI"
                                            control={<Radio />}
                                            label="DAI"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>

                            <Box className='overview-btn-box' mt={1}>
                                <Button
                                    onClick={handleBuyRaceToken}
                                    className="wallet-button"
                                    sx={{ background: "#E2E8F0 !important" }}>
                                    <Box className="btn-icon" sx={{ color: "#053863 !important" }}>
                                        <AttachMoney sx={{ color: "#000 !important" }} />
                                        {'Buy RACE Tokens'}
                                    </Box>
                                </Button>
                                <Button className="wallet-button" sx={{ background: "#1771BD !important" }} onClick={handleConfirm}><Box className="btn-icon" ><DownloadDone />{'Confirm Stake'}</Box></Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal >

            {/* stake race token progress modal */}
            {openTransactionModal ? <TransactionProgressModal identity={'proposer-stake'} confirmStake={confirmStake} stakeAmt={raceTokens} projectAmt={projectAmt} propData={{ ...props?.propData, feeTokenType: selectedOption }} handleModalClose={handleModalClose} openTransactionModal={openTransactionModal} /> : null}
        </>
    );
}
