import { GetApiParam } from "../../Api/Api";

export const GetTokenAddress = async (version, contract_name) => {
    try {

        const payload = {
            version
        };
        if (contract_name) {
            payload.contract_name = contract_name
        }

        const res = await GetApiParam(`/user/getContractAbi`, payload);

        if (res?.data?.code === 200 && res?.data?.data?.length) {
            const contract = res.data.data;
            return contract;
        }

        throw new Error('Address and ABI not available.');
    } catch (error) {
        throw error; // propagate the error
    }
};
