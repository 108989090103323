import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NewLogo } from '../../Components/Images/Images'
import { useForm } from "react-hook-form";
import { Box, Grid, Typography, TextField, FormControlLabel, Checkbox, Stack } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LoadingButton } from "@mui/lab";
import { postApi } from "../../Api/Api";
import SnackbarAlert from "../../Components/Common/SnackbarAlert";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useSocket } from "../../Contexts/SocketContext";
import { decryptStringData, encryptData } from "../../Components/Common/Utils";
import useAuth from "../../Contexts/useAuth";
import Cookies from "js-cookie";
import { useSnackbar } from "../../Contexts/SnackbarContext";

export default function LoginDIC() {

    const { login } = useAuth();
    const { showSnackbar } = useSnackbar();
    const socket = useSocket();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false); // loading state for login button
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const { register, handleSubmit, setValue, formState: { errors }, } = useForm();

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    // snackbar
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };


    useEffect(() => {
        const rememberedEmail = Cookies.get("rememberedDicEmail");
        const rememberedPassword = Cookies.get("rememberedDicPassword");
        if (rememberedEmail && rememberedPassword) {
            const rememberEmail = decryptStringData(rememberedEmail).replace(/"/g, '')
            const rememberPass = decryptStringData(rememberedPassword).replace(/"/g, '')
            setValue("email", rememberEmail);
            setValue("password", rememberPass);
            setRememberMe(true);
        } else {
            setRememberMe(false);
        }
    }, []);

    /**
     * login with email and password
     * @param {object} data {
     * email: string,
     * password: string}
     */
    const onSubmit = async (data) => {
        const body = {
            credentials: createEncryptedCredentials(data?.email, data?.password)
        };
        setLoading(true)
        const response = await postApi(`/committee/login`, body);
        if (response) {
            setLoading(false)
            if (response?.data?.code === 200) {
                socket.emit("setup", response?.data?.data.id);
                socket.emit("refresh-unread", response?.data?.data.id);
                await login(response?.data?.data);
                localStorage.setItem("Diclogin", true);
                localStorage.setItem("user_data_dic", JSON.stringify(response?.data?.data));
                localStorage.setItem("user_data", JSON.stringify(response?.data?.data));
                navigate('/dic/overview', { state: { from: '/login' }, replace: true });

                if (rememberMe) {
                    Cookies.set("rememberedDicEmail", encryptData(data.email), { expires: 30 });
                    Cookies.set("rememberedDicPassword", encryptData(data.password), { expires: 30 });
                } else {
                    Cookies.remove("rememberedDicEmail");
                    Cookies.remove("rememberedDicPassword");
                }
            } else { // something went wrong user or backend side
                setOpenSnackbar(true)
                setSnackbarMessage(response?.data?.message)
                showSnackbar(response?.data?.message, "error");
            }
        }
    }

    /**
     * Function for remember Me
     * @param {*} event 
     */
    const handleRememberMeChange = (event) => {
        setRememberMe(event.target.checked);
    };



    /**
    * 
    * @param {string} email 
    * @param {string} pass 
    * @returns {string} encrypted + concatenated credentials
    */
    const createEncryptedCredentials = (email, pass) => {
        const emailEncrypted = encryptData(email);
        const passEncrypted = encryptData(pass);
        // concatenated credentials separated by " ; " 
        const concatenated = `${emailEncrypted};${passEncrypted}`
        return concatenated;
    }

    return (
        <>
            <Box className="login-page auth-page">
                <Box className="auth-section">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                            <Box className="auth-left">
                                <Box className="content">
                                    <Box className="heading-wrap">
                                        <Typography component="h1" className="title">
                                            Join RACE’s Decentralized Investment Committee
                                        </Typography>
                                        <Typography component="p" className="sub-title">
                                            HELP DRIVE THE RACE PLATFORM • GET REWARDED FOR WORK DONE
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className="auth-logo">
                                    <Link to="/">
                                        <Box component="img" src={NewLogo} alt="Race logo" />
                                    </Link>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Box className="auth-right">
                                <Box textAlign={"right"} className="alreadyAc-des">
                                    Don’t have an account? Apply{" "}
                                    <Link component="a" to="/dic/register">
                                        here
                                    </Link>{" "}
                                    <Box component="span" className="icon" ml={1}>
                                        <ArrowForwardIcon />
                                    </Box>
                                </Box>
                                <Box className="auth-form-wrap">
                                    <Box className="heading-wrap">
                                        <Typography component="h1" className="title">
                                            Welcome back
                                        </Typography>
                                        <Typography component="p" className="sub-title">
                                            Sign in to your Decentralized Investment Committee account
                                        </Typography>
                                    </Box>

                                    {/*Uth Form */}
                                    <Box
                                        className="auth-form"
                                        mt={4}
                                        component="form"
                                        onSubmit={handleSubmit(onSubmit)}
                                    >
                                        <Box className="form-group label-field">
                                            <TextField
                                                type="email"
                                                id="outlined-required"
                                                label="Email Address"
                                                name="email"
                                                {...register("email", {
                                                    required: "E-mail address is required.",
                                                    pattern: {
                                                        value:
                                                            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                        message: "Please enter valid email address.",
                                                    },
                                                })}
                                                autoComplete="on"  // this line for auto-fill
                                                error={Boolean(errors.email)}
                                                helperText={errors.email?.message}
                                            />
                                        </Box>
                                        <Box className="form-group label-field" mb={1}>
                                            <TextField
                                                id="outlined-required"
                                                label="Password"
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                {...register("password", {
                                                    required: "Password is required.",
                                                })}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="password"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                autoComplete="on"  // this line for auto-fill
                                                error={Boolean(errors.password)}
                                                helperText={errors.password?.message}
                                            />
                                        </Box>
                                        <Stack
                                            direction={{ xs: "column", sm: "row" }}
                                            useFlexGap
                                            flexWrap="wrap"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            mb={3}
                                        >
                                            <Box>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={rememberMe}
                                                        onChange={handleRememberMeChange}
                                                    />}
                                                    label="Remember Me"
                                                    sx={{ mr: 0 }}
                                                    className="checkbox-dark"
                                                />
                                            </Box>
                                            <Box className="auth-forgotP-link">
                                                <Link component="a" to="/dic/forgot-password">
                                                    Forgot Password?
                                                </Link>
                                            </Box>
                                        </Stack>
                                        <Box className="btn-wrap">
                                            {/* <Link to="/portfolio"> */}
                                            <LoadingButton
                                                variant="contained"
                                                type="submit"
                                                loading={loading}
                                                fullWidth
                                                className="btn-rounded btn-blue-600"
                                            >
                                                Sign In
                                            </LoadingButton>
                                            {/* </Link> */}
                                        </Box>
                                    </Box>
                                </Box>
                                {/* End Form */}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <SnackbarAlert open={openSnackbar} message={snackbarMessage} severity='error' onClose={handleClose} />
        </>
    );
}
