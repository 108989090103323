// calculateRemainingAmount.js

import { readContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID } from "../../constants";

/**
 * Fetch user wallet balance
 * @param {Object} payEMIData asset details 
 * @returns emi amount for current date
 */
async function calculateRemainingAmount(contract_address, contract_abi, payEMIData) {
    try {
        if (contract_address && contract_abi) {
            const getPrepayment = await readContract({
                address: contract_address,
                abi: contract_abi,
                functionName: "calculateRemainingAmt",
                chainId: 6805,
                watch: true,
                args: [
                    payEMIData.listing_id
                ]
            });
            return getPrepayment
        }
    } catch (error) {
        console.error('Error fetching prepayment amount:', error);
        throw error; // propagate the error
    }
}

export { calculateRemainingAmount };
