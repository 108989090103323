//  nftBalanceOf.js
import { readContract } from "@wagmi/core";

/**
 * Fetch user wallet allowance for given spender
 * @param {String} address user wallet address
 * @param {String} contract_address asset data
 * @returns user wallet 
 */
async function tokenOfOwnerByIndex(address, contract_address, contract_abi, index) {
    try {
        // Now check for
        const balanceOf = await readContract({
            address: contract_address,
            abi: contract_abi,
            account: address,
            functionName: "tokenOfOwnerByIndex",
            chainId: 6805,
            args: [
                address,
                index
            ]
        })
        return Number(balanceOf);
    } catch (error) {
        console.error('Error fetching tokens by index:', error);
        throw error; // propagate the error
    }
}

export { tokenOfOwnerByIndex };