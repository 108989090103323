import { createSlice } from '@reduxjs/toolkit'

export const spNotificationSlice = createSlice({
    name: 'spNotification',
    initialState: {
        updateNotificationCount: null,
    },
    reducers: {
        updateNotification: (state, action) => {
            state.updateNotificationCount = action.payload?.updateNotificationCount;
        },
    }
});

export const { updateNotification } = spNotificationSlice.actions;


export default spNotificationSlice.reducer;