import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { NewLogo, } from "../../Components/Images/Images"
import { Grid, Box, Button, Stack, Avatar, Menu, MenuItem } from "@mui/material";
import "./Header.css";
import { GetApi } from "../../Api/Api";

const HeaderSignIn = () => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [categoryData, setCategoryData] = useState();
  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const location = useLocation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = (id) => {
    setAnchorEl1(null);
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    // Api to get categoryData
    async function headergetCategoryData() {
      try {
        const res = await GetApi("/proposer/getCategory/");
        setCategoryData(res?.data?.data);
      } catch (error) {
        console.log(error);
        return []
      }
    }

    headergetCategoryData();
  }, []);

  // code for restricting Routes for the Categories
  const renderPath = (val) => {

    switch (val.id) {
      case 2:
        return `/asset-category/${val.url}`;
      case 9:
        return `/asset-category/${val.url}`;
      case 8:
        return `/asset-category/${val.url}`;
      case 7:
        return `/asset-category/${val.url}`;
      default:
        return "#";
    }
  }

  function scrollTop() {
    document.documentElement.scrollTop = 0;
  }


  return (
    <>
      <Box className="main-header-wrap">
        <Box className="container">
          <Grid container spacing={2} className="main-header">
            <Grid item xs={5} className="left">
              <Box display={"flex"} alignItems={"center"}>
                <Box className="logo">
                  <Link to="/" onClick={scrollTop} >
                    <Avatar
                      variant="square"
                      src={NewLogo}
                      sx={{ width: "26px", height: "40px" }}
                      alt="Logo"
                    />
                  </Link>
                </Box>
                <Stack direction="row" spacing={4} className="headerLeft-menu" >
                  <Box className="menu-item">
                    <Link
                      id="fade-button"
                      aria-controls={open ? "fade-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      {location.pathname === "/service-provider"
                        ? "Provide Services"
                        : location.pathname === "/dummy"
                          ? "Decentralized Investment Committee"
                          : location.pathname === "/project-proposer" ? "Raise Capital" : "Get Started"}
                    </Link>
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      className="dropdown-menu InvesterHeader-dropdown"
                      mt={8}
                    >
                      <Link to="/project-proposer">
                        <MenuItem
                          onClick={handleClose}
                          className={
                            location.pathname === "/project-proposer"
                              ? "active"
                              : ""
                          }
                        >
                          {/* <Box component={'span'} className=''>Raise Capital</Box> */}
                          Raise Capital
                        </MenuItem>
                      </Link>
                      <Link
                        to={`/`}
                        state={{ from: "HeaderProvideService" }}
                      >
                        <MenuItem
                          onClick={handleClose}
                          className={
                            location.pathname === "/service-provider"
                              ? "active"
                              : ""
                          }
                        >
                          Provide Services
                        </MenuItem>
                      </Link>
                      <Link to="/dic/login">
                        <MenuItem
                          onClick={handleClose}
                          className={
                            location.pathname === "/dummy" ? "active" : ""
                          }
                        >

                          Decentralized Investment Committee
                        </MenuItem>
                      </Link>
                    </Menu>
                  </Box>
                  <Box className="menu-item" sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <Link to="/dummy">Learn</Link>
                  </Box>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={7} className="right">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <Box className="menu-item">
                  <Button
                    className="btn-rounded btn-golden"
                    id="fade-button"
                    aria-controls={open1 ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? "true" : undefined}
                    onClick={handleClick1}
                  >
                    Start Investing{" "}
                  </Button>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    anchorEl={anchorEl1}
                    open={open1}
                    onClose={handleClose1}
                    className="mega-menu InvesterHeader-dropdown"
                  >
                    <Box
                      className="mega-menu-inner start-investing"
                      sx={{ width: "772px" }}
                    >
                      <Grid container spacing={0}>
                        {categoryData &&
                          categoryData?.map((val, ind) => {
                            return (
                              <Grid
                                key={ind}
                                item
                                md={4}
                                className="mega-menu-item"
                              >
                                <Link
                                  to={renderPath(val)}
                                  onClick={handleClose1}
                                  className={val.url === location.pathname.split('/')[2] ? 'active' : ''}
                                >
                                  <Box
                                    component={"span"}
                                    className="mega-menu-box"
                                  >
                                    <Box component={"span"} className="icon">
                                      <Avatar
                                        src={`${process.env.REACT_APP_IMAGE_URL}${val.category_image.path}`}
                                        alt=""
                                      />
                                    </Box>
                                    <Box
                                      className="title-wrap"
                                      component={"span"}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Box className="title">{val.title}</Box>
                                      <Box className="subtitle">
                                        Invest in {val.title} Through the Power
                                        of the Blockchain
                                      </Box>
                                    </Box>
                                  </Box>
                                </Link>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </Box>
                  </Menu>
                </Box>
                <Stack direction="row" spacing={{ md: 4, xs: 2 }} className="headerRight-menu" sx={{ ml: { xs: 2 } }}>
                  <Box className="menu-item">
                    <Link to="/login">Sign In</Link>
                  </Box>
                  <Box className="menu-item" sx={{ display: { xs: 'none', md: 'flex' } }}>
                    {/* <Link to="/login">Get Started</Link> */}
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default HeaderSignIn;