import { Box, Chip, Stack, Typography } from "@mui/material";
import React from "react";
import "./ProfileCard.css";
import StarsIcon from '@mui/icons-material/Stars';
import config from "../../../Config/Config";

const ProfileCard = (props) => {
  return (
    <Box style={{ backgroundColor: "var(--blue-900)", borderRadius: "24px" ,padding:"20px"}}>
    
     <Box className="user-img-wrap">
        <Box className="user-img-div">
            <img src={`${process.env.REACT_APP_IMAGE_URL}${props?.profilePicture?.path}`} alt="" />
        </Box>
     </Box>
      <Box className="profile-card-content">
        <Typography className="profile-name">
          {props?.profile_name
            ? props?.profile_name
            : `${props.first_name}  ${props.last_name}`}
        </Typography>
        <Typography className="profile-profession">
          {props?.profile_profession
            ? props?.profile_profession
            : props.title}
        </Typography>
        <Typography className="united-text">{props.address ? props.address :props.address }</Typography> 
      </Box>
      <Box direction="row" display={"flex"} justifyContent={"center"} alignItems="center">
        <Box direction="row" display={"flex"} alignItems="center">
        <StarsIcon/>
        <Typography ml={1}>{props?.rating ? props?.rating : 'NA'}</Typography>
        </Box>
        <Box direction="row" ml={4}>
          <Typography>${props?.hourly_rate}/hr</Typography>
        </Box>
      </Box>
      <Box className="pc-chip-wrap" pb={3} mt={3}>
        <Stack
          direction="row"
          flexWrap={"wrap"}
          display={"flex"}
          spacing={1}
          justifyContent={"center"}
        >
          {props?.rating >= config?.TOP_RATAED?.ratingGrater && props?.review >= config?.TOP_RATAED?.reviewGrater && 
            <Chip
              className="pc-chip"
              label={"Top-Rated"}
            />
          }
          <Chip
            className="pc-chip"
            label={props?.successRate ? props?.successRate+"% Success" : 'Not Worked'}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default ProfileCard;
