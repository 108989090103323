import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import SliderCard from "../Components/Common/SliderCard";
import { postApi } from "../Api/Api";

function TypeSlider({ sliderTitle, categoryId , from }) {
  const [sliderData, setSliderData] = useState([]);
  useEffect(() => {
    async function getSubcategoryData() {
      try {
        // Create a payload for the API request 
        const subCategoryPayload = {
          category_id: categoryId,
        };
        // Send a POST request to the "/proposer/getSubcategory/" endpoint and store the response data in res
        const res = await postApi(
          "/proposer/getSubcategory/",
          subCategoryPayload
        );
        // Update the sliderData state with the data received from the API response  
        setSliderData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
    getSubcategoryData();
  }, [categoryId]);

  return (
    <Box className="artCollectionSlider-section" pb={8}>
      <Box className="container">
        <Box className="section-heading" mb={4} textAlign={"center"}>
          <Typography className="title" variant="h3">
            {sliderTitle}
          </Typography>
        </Box>
        <Box className="realEstateTypes-wrap hc-thumbnail-without-hover">
          <Grid container spacing={0.3} justifyContent={'center'}>
            {/* Category start grid 1 */}
            {sliderData &&
              sliderData?.map((el, index) => {
                return (
                  <Grid item key={index} xs={12} sm={6} md={2.4}>
                    <Box className="hc-thumbnail-wrap subcat-images">
                      <SliderCard
                        image={`${process.env.REACT_APP_IMAGE_URL}${el?.category_image?.path}`}
                        title={el?.title}
                        from = {"SubCategory"}
                      />
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default TypeSlider;



