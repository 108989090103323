import React, { useCallback, useEffect, useState } from 'react'
import { GetApi, postApi } from '../../Api/Api';
import AddIcon from '@mui/icons-material/Add';
import { Box, Grid, Typography, Chip, Button } from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import Bookmarks from '@mui/icons-material/Bookmarks';
import { Link, useNavigate } from 'react-router-dom';
import SavedRequestDrawer from './SavedRequestDrawer';
import { getUserDataFromLocalStorage } from '../../Services/localstorage.service';
import { calculateDaysSinceCreation, calculateNewSinceCreation } from '../../Utils/utils';

const SavedRequests = () => {
  const [projectServiceDetails, setProjectServiceDetails] = useState([]);
  const [expandedStates, setExpandedStates] = useState([]);
  const [drawerState, setDrawerState] = useState(false)
  const [drawerData, setDrawerData] = useState()

  const localData = getUserDataFromLocalStorage();

  const getBookmarkData = useCallback(async () => {
    try {
      const res = await GetApi(`/services/getBookmarkProjects?user_id=${localData.id}`);  
      setProjectServiceDetails(res?.data?.data ? res?.data?.data : [])
    } catch (error) {
      console.log(error);
    }
  }, [localData.id]);

  useEffect(() => {
    if (localData?.id) {
      // Api to get bookmark projects
      getBookmarkData();
    }
  }, [localData?.id]);

  const handleViewMore = (ind) => {
    setExpandedStates(prevStates => {
      const newStates = [...prevStates];
      newStates[ind] = true;
      return newStates;
    });
  };

  const handleViewLess = (ind) => {
    setExpandedStates(prevStates => {
      const newStates = [...prevStates];
      newStates[ind] = false;
      return newStates;
    });
  };

  /**
   * Onclick function  to change the bookmark condition
   * @param {*} item 
   */
  const handleClickBookmark = async (item, ind) => {
    const payloadData = {
      project_id: item.id,
      user_id: localData.id,
      is_bookmark: item.is_bookmark ? 0 : 1,
    };

    if (localData.id) {
      const bookmarkResponce = await postApi("/services/isBookmark", payloadData)
      if(bookmarkResponce?.data?.code === 200){
        getBookmarkData();
        const val = item.is_bookmark ? 0 : 1;
        const tempArr = projectServiceDetails.map((i, idx) => idx === ind ? {...i, projectDetails: { ...i.projectDetails, is_bookmark: val }} : i);
        setProjectServiceDetails(tempArr);
      }
    }

  }

  const handleDrawer = (savedData) => {
    setDrawerState(true);
    setDrawerData(savedData)
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };


  return (
    <Box>
      <Grid container spacing={2}>
        <Box>
          <Typography className='font-28' ml={2}></Typography>
        </Box>
        <Grid item xs={12} md={12}>
          <Box className="brower-request-right">
            <Grid container spacing={2}>
              {projectServiceDetails.length > 0 ? projectServiceDetails?.map((serviceVal, ind) => (
                <Grid item xs={12} md={6} key={ind}>
                  <Box className="card-box">
                    <Box className="top-header">
                      <Box className="top-left">
                        <Box className="pc-chip-wrep-browse">
                          {calculateNewSinceCreation(serviceVal?.createdAt) === 'new' ?
                                  <Chip className="pc-chip" label={"New"} /> : ""}
                        </Box>
                        <Box className="top-sub-left">
                          <Typography className='font-16' >{serviceVal?.projectDetails?.project_title}</Typography>
                          <Typography className='font-12'>{serviceVal?.projectDetails?.budget === "Project Budget"
                                    ? "Fixed-price"
                                    : serviceVal?.projectDetails?.budget} | Posted {calculateDaysSinceCreation(serviceVal?.projectDetails?.createdAt)} ago</Typography>
                        </Box>
                      </Box>
                      {serviceVal && serviceVal?.projectDetails?.is_bookmark ?
                        <Box className="top-right cursor-pointer">
                          <Bookmarks className='bookmark-icon' onClick={() => handleClickBookmark(serviceVal?.projectDetails, ind)} />
                        </Box>
                        : <Box className="top-right cursor-pointer">
                          <BookmarkBorderIcon className='bookmark-icon' onClick={() => handleClickBookmark(serviceVal?.projectDetails, ind)} />
                        </Box>
                      }
                    </Box>
                    <Box className="card-body">
                      <Box className="card-body-top">
                        <Box className="left-section">
                          <Typography className='title font-12'>{serviceVal?.projectDetails?.budget === "Project Budget" ? "Fixed-price" : serviceVal?.projectDetails?.budget}</Typography>
                          <Typography component="p" className='sub-title font-16'>${serviceVal?.projectDetails?.budget_from} - ${serviceVal?.projectDetails?.budget_to}</Typography>
                        </Box>
                        <Box className="right-section">
                          <Typography component="h4" className='title font-12'>Experience Level</Typography>
                          <Typography component="p" className='sub-title font-16'>{serviceVal?.projectDetails?.level_of_experience}</Typography>
                        </Box>
                      </Box>

                      <Box className="card-body-discription">
                        {expandedStates[ind] ? (
                          <>
                            <Typography component="p" className='title font-14'>{serviceVal?.projectDetails?.describe_services}</Typography>
                            <Link onClick={() => handleViewLess(ind)}>
                              <Typography variant="h6" sx={{
                                color: '#398CD1', fontSize: '14px', fontWeight: 700, textDecoration: "underline",
                                textDecorationColor: "#398CD1"
                              }} mt={1}>
                                View Less
                              </Typography>
                            </Link>
                          </>
                        ) : (
                          <>
                            <Typography component="p" className='title font-14' sx={{
                              mt: '12px', color: '#FFFFFF', overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: "2",
                              WebkitBoxOrient: "vertical",
                            }}>{serviceVal?.projectDetails?.describe_services}</Typography>
                            <Link onClick={() => handleViewMore(ind)}>
                              <Typography variant="h6" sx={{
                                color: '#398CD1', fontSize: '14px', fontWeight: 700, textDecoration: "underline",
                                textDecorationColor: "#398CD1"
                              }} mt={1}>
                                View More
                              </Typography>
                            </Link>
                          </>
                        )}
                      </Box>


                      <Box className="button-list">
                        {serviceVal?.projectDetails?.skills ? 
                        serviceVal?.projectDetails?.skills?.map((skillVal, index) => (
                          <Button key={index}>
                            {skillVal.skill_name}
                          </Button>
                        ))
                        :serviceVal?.projectDetails?.skillData?.map((skillVal, index) => (
                          <Button key={index}>
                            {skillVal.skill_name}
                          </Button>
                        ))}
                      </Box>
                      <Box className="see-more-btn">
                        <Button variant="contained" startIcon={<AddIcon />} onClick={() => handleDrawer(serviceVal?.projectDetails)} >
                          See more
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              )) : (
                <Grid item xs={12} md={12} style={{textAlign: 'center'}}>
                  <h1>Saved Request Not Available.</h1>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {drawerState ? <Box>
        <SavedRequestDrawer open={drawerState} drawerData={drawerData} onClose={handleCloseDrawer} />
      </Box> : null}
    </Box>
  )
}

export default SavedRequests