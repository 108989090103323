import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Box, Typography, Grid, Badge, tableCellClasses, styled, TableContainer, Table, TableHead, Paper, TableCell, TableRow, TableBody, Chip, IconButton, Menu, MenuItem } from "@mui/material";
import { Insights, LockOutlined, Payment, PendingActions, SwapHorizontalCircle, AccountBalanceWallet, Download, Upload, MoreHoriz, Pending, HowToVote, Done, AssignmentInd, AttachMoney, PaymentsOutlined } from "@mui/icons-material";
import ConnectWalletInvestor from "../../Web3/ConnectWalletInvestor";
import { GetApiParam, postApi } from "../../Api/Api";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { update } from '../../features/auth/authSlice'
import { useAccount } from "wagmi";
import { checkBalance } from "../../Components/SmartContract/checkBalance";
import { formatNumber } from "../../Components/Common/USFormat";
import { INVESTMENT_TYPE_ID } from "../../constants";
import moment from "moment/moment";
import TransactionProgressModal from "../../Components/SmartContract/TransactionProgressModal";
import CommonBackdropLoader from "../../Components/Common/CommonBackdropLoader";
import { GetTokenAddress } from "../../Components/Common/GetTokenAddress";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-multi-carousel/lib/styles.css";
import "../Overview/Overview.css";
import './WalletDic.css'

// Styles for table columns
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#05131B',
        color: '#94A3B8',
        borderBottom: '0px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: '0px'
    },
}));

// Styles for table rows
const StyledTableRow = styled(TableRow)(() => ({
}));

const WalletDic = () => {

    const { address, isConnected } = useAccount()
    const { showSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const location = useLocation()
    const navigate = useNavigate();

    // states
    const [assetData, setAssetData] = useState("");
    const [serverClockTime, setServerClock] = useState();
    const [committeeHeaderInfo, setCommitteeHeaderInfo] = useState("");
    const [walletBalance, setWalletBalance] = useState(0);
    const [totalStake, setTotalStake] = useState(0);
    const [totalEarnings, setTotalEarnings] = useState(0);
    const [stakeLast30Days, setStakeLast30Days] = useState(0);
    const [withdrawalAmount, setWithdrawalAmount] = useState(0);
    const [withdrawalAmountLast30Days, setWithdrawalAmountLast30Days] = useState(0);
    const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
    const [isTransactionModalCommissionOpen, setIsTransactionModalCommissionOpen] = useState(false);
    const [assetDetailsPayload, setAssetDetailsPayload] = useState('');
    const [loading, setLoading] = useState(false);
    const [isTransactionHappened, setIsTransactionHappened] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // getting user local storage data
    const localData = JSON.parse(localStorage.getItem("user_data_dic"));
    const localDataUser = JSON.parse(localStorage.getItem("user_data"));

    let userName = localData?.first_name;
    let memberId = localData?.member_id;

    useEffect(() => {
        if (localDataUser?.wallet_address) {
            dispatch(update({ wallet_address: localDataUser?.wallet_address?.toLowerCase() }));
        }
    }, [localDataUser?.wallet_address, dispatch])

    const selected = '';

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Find amount by investment type
    const findAmountByInvestment = (investment_id, assetData) => {
        let amount = 0;
        if (investment_id === INVESTMENT_TYPE_ID.LOAN) {
            amount = assetData?.loan_amount;
        }
        else if (investment_id === INVESTMENT_TYPE_ID.FRACTION) {
            amount = assetData?.fractionalize_total_price;
        }
        else if (investment_id === INVESTMENT_TYPE_ID.PARCEL) {
            amount = assetData?.parcel_total_price;
        }
        else if (investment_id === INVESTMENT_TYPE_ID.SALE) {
            amount = assetData?.sale_price;
        }
        return amount;
    }

    useEffect(() => {
        const localData = JSON.parse(localStorage.getItem("user_data_dic"));

        // If localData is not found, navigate to the home page
        if (!localData) {
            navigate("/"); // This won't cause a re-render of the current component
        }
    }, [navigate]); // Dependency on `navigate`, not `localData`, as localData is computed inside useEffect

    // set modal open true , only when it is coming from dic login page 
    useEffect(() => {
        // Check if location.state is available and if it's coming from the /login page
        if (location.state && location.state.from === '/login') {
            // setOpenModal(true);
            // replacing state so, modal not open on page reload
            navigate(location.pathname, { replace: true });
        }
    }, [location, navigate]);

    // getting assign project and member review project project data
    async function getProjectData() {
        try {
            const localData = JSON.parse(localStorage.getItem("user_data_dic"));
            if (localData?.id) {
                const payloadData = {
                    committee_member_id: localData?.id,
                    dic_wallet_list: true
                };
                setLoading(true)
                const res = await GetApiParam("/committee/getAssignedAssets", payloadData);
                if (res?.data?.data) {
                    let assignedData = res.data?.data;
                    let newData = [];
                    if (assignedData?.length) {
                        let totalAmount = 0;
                        let totalEarnsAmount = 0;
                        let total30DaysEarnsAmount = 0;
                        let total30DaysAmount = 0;
                        let withdrawnAmt = 0;
                        let withdrawn30DaysAmt = 0;

                        assignedData?.forEach((data) => {

                            const projectAmt = findAmountByInvestment(data?.assetDetails?.investment_type_id, data?.assetDetails)
                            // Filter the data based on the date
                            const isWithinLast30Days = (dateString) => {
                                const parsedDate = moment(dateString, 'YYYY-MM-DDTHH:mm:ss.S');
                                const currentDate = moment();
                                const date30DaysAgo = moment().subtract(30, 'days');

                                return parsedDate.isBetween(date30DaysAgo, currentDate, null, '[]'); // inclusive of both ends
                            };

                            if (parseFloat(data?.dicStakePercent) > 0) {
                                totalAmount += parseFloat(Math.ceil(projectAmt * (data?.dicStakePercent) / 100))
                                if (data?.createdAt && isWithinLast30Days(data?.createdAt)) {
                                    console.log('%')
                                    total30DaysAmount += parseFloat(Math.ceil(projectAmt * (data?.dicStakePercent) / 100))
                                }
                                if (data?.withdraw_time && isWithinLast30Days(data?.withdraw_time)) {
                                    withdrawn30DaysAmt += parseFloat(Math.ceil(projectAmt * (data?.dicStakePercent) / 100))
                                }
                                if (data?.dic_stake_withdraw) {
                                    withdrawnAmt += parseFloat(Math.ceil(projectAmt * (data?.dicStakePercent) / 100))
                                }
                            } else {
                                totalAmount += parseFloat(data?.dicStakeAmt)
                                if (data?.createdAt && isWithinLast30Days(data?.createdAt)) {
                                    total30DaysAmount += parseFloat(data?.dicStakeAmt)
                                }
                                if (data?.withdraw_time && isWithinLast30Days(data?.withdraw_time)) {
                                    withdrawn30DaysAmt += parseFloat(data?.dicStakeAmt)
                                }
                                if (data?.dic_stake_withdraw) {
                                    withdrawnAmt += parseFloat(data?.dicStakeAmt)
                                }
                            }

                            if (data?.dic_commission_withdraw && isWithinLast30Days(data?.commission_withdraw_time)) {
                                total30DaysEarnsAmount += parseFloat(data?.assetDetails?.dic_commission_fees ?? 0)
                            }
                            if (data?.dic_commission_withdraw) {
                                totalEarnsAmount += parseFloat(data?.assetDetails?.dic_commission_fees ?? 0)
                            }

                        });

                        setTotalStake(formatNumber(totalAmount ? totalAmount : 0));
                        setTotalEarnings(formatNumber(totalEarnsAmount ? totalEarnsAmount : 0));
                        setWithdrawalAmount(formatNumber(withdrawnAmt + totalEarnsAmount ? parseFloat(withdrawnAmt + totalEarnsAmount) : 0));
                        setStakeLast30Days(formatNumber(total30DaysAmount ? total30DaysAmount : 0));
                        setWithdrawalAmountLast30Days(formatNumber(withdrawn30DaysAmt + total30DaysEarnsAmount ? parseFloat(withdrawn30DaysAmt + total30DaysEarnsAmount) : 0));

                        assignedData?.forEach((assetItem) => {
                            let asset_title = assetItem.assetDetails.asset_title;
                            let investment_type = assetItem.assetDetails.investment_type.investment_name;
                            newData.push({
                                project_name: asset_title,
                                type: investment_type,
                                amount: parseFloat(assetItem.dicStakeAmt),
                                payment: "",
                                date: moment(assetItem?.createdAt).format("MMMM D, YY"),
                                ...assetItem
                            })


                        })
                    }
                    setAssetData(newData);
                }
                setLoading(false)
            } else {
                setLoading(false)
                showSnackbar("Member id is missing.", "error");
            }
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    useEffect(() => {

        async function getCommitteeHeaderInfo() {
            try {
                const localData = JSON.parse(localStorage.getItem("user_data_dic"));
                if (localData?.id) {
                    const payloadData = {
                        committee_member_id: localData.id ? localData.id : 5,
                    };
                    const res = await GetApiParam("/committee/headerInfo", payloadData);
                    if (res.data.code === 200) {
                        setCommitteeHeaderInfo(res.data.data);
                    }
                } else {
                    // showSnackbar("Member id is missing.", "error");
                }
            } catch (error) {
                console.log(error);
            }
        }

        const timeOut = setTimeout(() => {
            getCommitteeHeaderInfo();
            getProjectData();
        }, 100);
        return () => clearTimeout(timeOut)
    }, [isTransactionHappened, localData?.id]);

    // getting user wallet balance
    useEffect(() => {
        if (address && isConnected) {
            async function getBalance() {
                try {

                    const responseToken = await GetTokenAddress('current');
                    const contracts = responseToken.reduce((acc, item) => {
                        if (item.contract_name === "USDT") {
                            acc.usdt = item;
                        } else if (item.contract_name === "USDC") {
                            acc.usdc = item;
                        } else if (item.contract_name === "DAI") {
                            acc.dai = item;
                        }
                        return acc;
                    }, {});
                    let balanceOf1 = 0, balanceOf2 = 0, balanceOf3 = 0;
                    if (contracts.usdt) {
                        balanceOf1 = await checkBalance(address, contracts.usdt.address, contracts.usdt.abi);
                    }
                    if (contracts.usdc) {
                        balanceOf2 = await checkBalance(address, contracts.usdc.address, contracts.usdc.abi);
                    }
                    if (contracts.dai) {
                        balanceOf3 = await checkBalance(address, contracts.dai.address, contracts.dai.abi);
                    }
                    console.log('balanceOf1,balanceOf2,balanceOf3', balanceOf1, balanceOf2, balanceOf3);
                    setWalletBalance(formatNumber((Number(balanceOf1) / 1e6) + (Number(balanceOf2) / 1e6) + (Number(balanceOf3) / 1e18)))
                } catch (error) {
                    setWalletBalance(0)
                }
            }
            const timeOut = setTimeout(() => {
                getBalance();
            }, 100);
            return () => clearTimeout(timeOut)
        } else {
            setWalletBalance(0)
        }
    }, [address, localData?.wallet_address])

    // start clock interval
    setInterval(() => {
        if (serverClockTime) {
            serverClockTime.setSeconds(serverClockTime.getSeconds() + 1);
            setServerClock(serverClockTime);
            const event = new CustomEvent("clocktime", {
                detail: serverClockTime,
                bubbles: true,
            });
            document.dispatchEvent(event);
        }
    }, 1000);

    //Wallet connection code start
    const [walletDialogOpen, setWalletDialogOpen] = useState(false);

    const handleClickOpen = () => {
        setWalletDialogOpen(true);
    };

    const handleCloseDialog = (value) => {
        setWalletDialogOpen(false);
    };

    /**
     * gets called when a wallet is connect successfully 
     * @param {string} acc 
     */
    const walletLogin = async (acc) => {
        if (acc) {
            // dispatch(update({ wallet_address: acc?.toLowerCase() }));
            showSnackbar('Wallet connected successfully', 'success');
        }
    }
    //Wallet connection code end

    /**
     * Function call a method of blockchain for withdraw stake amount for a project for dic
     * @param {Object} assetData asset details
     */
    const handleWithdrawStake = (assetData) => {
        setAssetDetailsPayload(assetData);
        setIsTransactionModalOpen(true);
    }

    /**
     * Function call a method of blockchain for withdraw stake amount for a project for dic
     * @param {Object} assetData asset details
     */
    // const handleWithdrawCommission = (assetData) => {
    //     setAssetDetailsPayload(assetData);
    //     setIsTransactionModalCommissionOpen(true);
    // }

    /**
    * Function to handle modal close
   */
    const handleModalClose = () => {
        setIsTransactionModalOpen(false);
    }

    /**
    * Function to handle modal close
    */
    const handleModalCommissionClose = () => {
        setIsTransactionModalCommissionOpen(false);
    }

    /**
     * Function call after transaction confirm 
     */
    const confirmStake = async (data, amount, asset_id, user_id) => {
        // window.location.reload(true);
        setIsTransactionHappened(!isTransactionHappened)
        getProjectData()
        try {
            let userData = localStorage.getItem('user_data')
            userData = JSON.parse(userData);
            const gasFees = Number(data.gasUsed)
            let body = {
                amount: amount,
                sender_address: data.from,
                contract_address: data.to,
                transaction_hash: data.transactionHash,
                method_name: "WithdrawDICStake",
                proposer_id: userData?.id,
                asset_id: asset_id,
                gas_fees: gasFees

            }
            await postApi("/user/createBlockchainTransaction", body);
        } catch (error) {
            console.log('error', error);
        }
    }
    /**
    * Function call after transaction confirm 
    */
    const confirmCommissionStake = async () => {
        // window.location.reload(true);
        setIsTransactionHappened(!isTransactionHappened)
    }

    return (
        <>
            <Box className="myPorject-page overview-page" pt={4}>
                <Box className="myPorject-drafts-section" pb={4}>
                    <Box>
                        <Box className="memberId-text">
                            Member ID: {memberId ? memberId : ""}
                        </Box>
                        <Box className="name-text">Hi {userName ? userName : ""} 👋🏼</Box>
                    </Box>

                    <Box className="top-bar">
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <Box className="headIconBox">
                                    <Box className="icon-box">
                                        <PendingActions className="head-icon" />
                                    </Box>
                                    <Box className="right-box">
                                        <Box className="head-text">Not Reviewed</Box>
                                        <Box className="head-number">
                                            {committeeHeaderInfo?.notReviewed ?? ""}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <Box className="headIconBox">
                                    <Box className="icon-box">
                                        <Pending className="head-icon" />
                                    </Box>
                                    <Box className="right-box">
                                        <Box className="head-text">Reviewed</Box>
                                        <Box className="head-number">
                                            {committeeHeaderInfo?.Reviewed ?? ""}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <Box className="headIconBox">
                                    <Box className="icon-box">
                                        <HowToVote className="head-icon" />
                                    </Box>
                                    <Box className="right-box">
                                        <Box className="head-text">Pending Vote</Box>
                                        <Box className="head-number">
                                            {committeeHeaderInfo?.pendingVote ?? ""}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <Box className="headIconBox">
                                    <Box className="icon-box">
                                        <Done className="head-icon" />
                                    </Box>
                                    <Box className="right-box">
                                        <Box className="head-text">Completed</Box>
                                        <Box className="head-number">
                                            {committeeHeaderInfo?.Completed ?? ""}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <Box className="headIconBox">
                                    <Box className="icon-box">
                                        <AssignmentInd className="head-icon" />
                                    </Box>
                                    <Box className="right-box">
                                        <Box className="head-text">Assigned</Box>
                                        <Box className="head-number">
                                            {committeeHeaderInfo?.assigned ?? ""}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <Box className="headIconBox">
                                    <Box className="icon-box">
                                        <AttachMoney className="head-icon" />
                                    </Box>
                                    <Box className="right-box">
                                        <Box className="head-text">Funded</Box>
                                        <Box className="head-number">
                                            {committeeHeaderInfo?.funded ?? ""}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className='pf-page dic-wallet-page' mb={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8} sm={12}>
                                <Box>
                                    <Box className='dic-wallet'>
                                        <Box display={'flex'} alignItems={'center'} gap={'20px'}>
                                            <AccountBalanceWallet />
                                            <Typography className="wallet-address">{address ? address : ''}</Typography>
                                        </Box>
                                        {(address) ?
                                            <Badge className="wallet-status"><Typography className="text">Connected</Typography></Badge> :
                                            <Button className="dic-wallet-connect-btn btn-rounded" onClick={handleClickOpen}>
                                                Connect Wallet
                                            </Button>
                                        }
                                    </Box>
                                    <Box className='dic-wallet-balance'>
                                        <Typography className="text"> Balance: {walletBalance} RT</Typography>
                                    </Box>
                                    <Box className='pfInfo'>
                                        <Box className='pfInfo-flex' alignItems={'center'}>
                                            <Box className='pfIf'>
                                                Your Rewards
                                            </Box>
                                            <Box className=''>
                                                <IconButton onClick={handleClick}>
                                                    <MoreHoriz className="text-white" />
                                                </IconButton>
                                            </Box>
                                            <Menu
                                                id="fade-menu"
                                                MenuListProps={{
                                                    "aria-labelledby": "fade-button",
                                                }}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "right",
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right",
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                className="dropdown-dic-menu dic-wallet-dropdown"
                                            >
                                                <MenuItem
                                                    onClick={handleClose}
                                                    className={
                                                        selected === "withdraw"
                                                            ? "active"
                                                            : ""
                                                    }
                                                >
                                                    <Upload />
                                                    <Typography ml={'12px'} fontWeight={'500'}> Withdraw</Typography>
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={handleClose}
                                                    className={
                                                        selected === "stack"
                                                            ? "active"
                                                            : ""
                                                    }
                                                >
                                                    <LockOutlined />
                                                    <Typography ml={'12px'} fontWeight={'500'}> Stack</Typography>
                                                </MenuItem>
                                            </Menu>
                                        </Box>
                                        <Grid container spacing={1}>
                                            <Grid item md={3} sm={6} xs={12}>
                                                <Box className="pfIf-card">
                                                    <Box className="pfIf-card-icon-box">
                                                        <Box className="pfIf-card-icon">
                                                            <Payment />
                                                        </Box>
                                                    </Box>
                                                    <Box className="pfIf-card-title">{walletBalance} RT</Box>
                                                    <Box className="pfIf-card-subtitle">
                                                        Wallet Balance
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={3} sm={6} xs={12}>
                                                <Box className='pfIf-card'>
                                                    <Box className="pfIf-card-icon-box">
                                                        <Box className='pfIf-card-icon'><LockOutlined /></Box>
                                                    </Box>
                                                    <Box className='pfIf-card-title'>{formatNumber(parseFloat(totalStake > 0 ? totalStake : 0))} RT</Box>
                                                    <Box className='pfIf-card-subtitle'>Total Staked</Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={3} sm={6} xs={12}>
                                                <Box className='pfIf-card'>
                                                    <Box className="pfIf-card-icon-box">
                                                        <Box className='pfIf-card-icon'><Insights /></Box>
                                                    </Box>
                                                    <Box className='pfIf-card-title'>{formatNumber(totalEarnings)} RT</Box>
                                                    <Box className='pfIf-card-subtitle'>Total Earnings</Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={3} sm={6} xs={12}>
                                                <Box className='pfIf-card'>
                                                    <Box className="pfIf-card-icon-box">
                                                        <Box className='pfIf-card-icon'><SwapHorizontalCircle /></Box>
                                                    </Box>
                                                    <Box className='pfIf-card-title'>{formatNumber(parseFloat(withdrawalAmount > 0 ? withdrawalAmount : 0))} RT</Box>
                                                    <Box className='pfIf-card-subtitle'>Withdrawal</Box>
                                                </Box>
                                            </Grid>
                                        </Grid >
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Box className='wallet-statistics'>
                                    <Typography className="wallet-statistics-title">30-Day Wallet Statistics</Typography>
                                    <Box className='list-data'>
                                        <Box className='icons'>
                                            <Download />
                                        </Box>
                                        <Typography ml={'12px'} fontWeight={'500'}>In</Typography>
                                    </Box>
                                    <Box>
                                        <Box className='progress-data'>
                                            <Box className='progress' style={{ width: `${(parseFloat(withdrawalAmountLast30Days) / ((parseFloat(withdrawalAmountLast30Days) + parseFloat(stakeLast30Days)) ? (parseFloat(withdrawalAmountLast30Days) + parseFloat(stakeLast30Days)) : 1)) * 100}%` }} />
                                            <Box className='progress-empty' style={{ width: `${100 - (parseFloat(withdrawalAmountLast30Days) / ((parseFloat(withdrawalAmountLast30Days) + parseFloat(stakeLast30Days)) ? (parseFloat(withdrawalAmountLast30Days) + parseFloat(stakeLast30Days)) : 1)) * 100}%` }} />
                                        </Box>
                                        <Box className='amount'>
                                            <Typography>{withdrawalAmountLast30Days} RT</Typography>
                                            <Typography>{parseFloat((parseFloat(withdrawalAmountLast30Days) / ((parseFloat(withdrawalAmountLast30Days) + parseFloat(stakeLast30Days)) ? (parseFloat(withdrawalAmountLast30Days) + parseFloat(stakeLast30Days)) : 1)) * 100).toFixed(2)}%</Typography>
                                        </Box>
                                    </Box>
                                    <Box className='list-data'>
                                        <Box className='icons'>
                                            <Upload />
                                        </Box>
                                        <Typography ml={'12px'} fontWeight={'500'}>Out</Typography>
                                    </Box>
                                    <Box>
                                        <Box className='progress-data'>
                                            <Box className='progress' style={{ width: `${(parseFloat(stakeLast30Days) / ((parseFloat(withdrawalAmountLast30Days) + parseFloat(stakeLast30Days)) ? (parseFloat(withdrawalAmountLast30Days) + parseFloat(stakeLast30Days)) : 1)) * 100}%` }} />
                                            <Box className='progress-empty' style={{ width: `${100 - (parseFloat(stakeLast30Days) / ((parseFloat(withdrawalAmountLast30Days) + parseFloat(stakeLast30Days)) ? (parseFloat(withdrawalAmountLast30Days) + parseFloat(stakeLast30Days)) : 1)) * 100}%` }} />
                                        </Box>
                                        <Box className='amount'>
                                            <Typography>{stakeLast30Days} RT</Typography>
                                            <Typography>{parseFloat((parseFloat(stakeLast30Days) / ((parseFloat(withdrawalAmountLast30Days) + parseFloat(stakeLast30Days)) ? (parseFloat(withdrawalAmountLast30Days) + parseFloat(stakeLast30Days)) : 1)) * 100).toFixed(2)}%</Typography>
                                        </Box>
                                    </Box>
                                    {/* <Button className='btn-rounded' color="warning" sx={{ backgroundColor: '#FFF8F3', maxWidth: '123px' }}>
                                        {false ? 'Show Less' : 'Show More'}
                                    </Button> */}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <TableContainer component={Paper} className='TableContainer'>
                        <Box sx={{ padding: "4px 12px 4px 16px", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} mb={2}>
                            <Typography className="font-22 text-white">
                                Assigned Project
                            </Typography>
                            {/* <IconButton >
                                <OpenInFullSharp className="text-white" />
                            </IconButton> */}
                        </Box>
                        <Table aria-label="customized table">
                            <TableHead className='TableHeader'>
                                <TableRow>
                                    <StyledTableCell style={{ maxWidth: '200px' }}>Project Name</StyledTableCell>
                                    <StyledTableCell align="center">Type</StyledTableCell>
                                    {/* <StyledTableCell align="left">Interest Rate</StyledTableCell>
                                    <StyledTableCell align="left">Bonus Rate</StyledTableCell> */}
                                    <StyledTableCell align="left">Stake Amount</StyledTableCell>
                                    {/* <StyledTableCell align="left">Commission Amount</StyledTableCell> */}
                                    <StyledTableCell align="center">Date</StyledTableCell>
                                    <StyledTableCell align="center">Action</StyledTableCell>
                                    {/* <StyledTableCell align="center"></StyledTableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {assetData?.length ? assetData?.filter((item) => item)?.map((row, i) => (
                                    <StyledTableRow className='TableRow' key={`${row.project_name}${i}`}>
                                        <StyledTableCell component="th" scope="row" style={{ maxWidth: '200px' }}>{row.project_name}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Chip
                                                sx={{ marginTop: 2, marginBottom: 2, color: "#FFFFFF", bgcolor: "#398CD1", fontSize: "12px", borderRadius: "8px", }}
                                                label={row.type}
                                            />
                                        </StyledTableCell>
                                        {/* <StyledTableCell align="left">{row.interest_rate}</StyledTableCell>
                                        <StyledTableCell align="left">{row.bonus_rate}</StyledTableCell> */}
                                        <StyledTableCell align="left">${formatNumber(parseFloat(row.amount))}</StyledTableCell>
                                        {/* <StyledTableCell align="left">${formatNumber(parseFloat((row?.assetDetails?.dic_commission_fees ?? 0) / (row?.dicMemberData?.length ?? 1)))}</StyledTableCell> */}
                                        {/* <StyledTableCell align="center">
                                            <Box className='wallet-table-payment'>
                                                <img style={{ height: '24px' }} src={Ethereum} />
                                                <Typography> {row.payment}</Typography>
                                            </Box>
                                        </StyledTableCell> */}
                                        <StyledTableCell align="center">{row.date}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            {
                                                <Button
                                                    className="status-button"
                                                    onClick={() => handleWithdrawStake(row)}
                                                    startIcon={<PaymentsOutlined />}
                                                    sx={{
                                                        color: 'black', bgcolor: row?.dic_stake_withdraw ? '#34D399 !important' : ([3, 5, 6].includes(row?.status) && !row?.dic_stake_withdraw) ? '#F6CFB7' : '#343c42 !important'
                                                        , borderRadius: '8px', width: '180px'
                                                    }}
                                                    disabled={([3, 5, 6].includes(row?.status) && !row?.dic_stake_withdraw) ? false : true}
                                                >
                                                    {row?.dic_stake_withdraw ? 'Withdrawn Stake' : 'Withdraw Stake'}
                                                </Button>
                                                // :
                                                // <Chip label={"Withdrawn"} sx={{ color: '#13202D', bgcolor: '#34D399', borderRadius: '8px' }} />
                                            }
                                            {/* {
                                                <Button
                                                    className="status-button"
                                                    onClick={() => handleWithdrawCommission(row)}
                                                    startIcon={<PaymentsOutlined />}
                                                    sx={{
                                                        color: 'black', bgcolor: row?.dic_commission_withdraw ? '#34D399 !important' : (!row?.dic_commission_withdraw) ? '#F6CFB7' : '#343c42 !important'
                                                        , borderRadius: '8px', width: '200px !important', marginLeft: '8px'
                                                    }}
                                                    disabled={(!row?.dic_commission_withdraw) ? false : true}
                                                >
                                                    <span>
                                                        {row?.dic_commission_withdraw ? "Withdrawn Commission" : "Withdraw Commission"}
                                                    </span>
                                                </Button>
                                            } */}
                                        </StyledTableCell>
                                        {/* <StyledTableCell align="center"><Typography style={{ cursor: 'pointer' }}>View Tx</Typography></StyledTableCell> */}
                                    </StyledTableRow>
                                )) :
                                    <Typography className="dic-funded-no-project">No project found</Typography>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                {
                    walletDialogOpen ?
                        <ConnectWalletInvestor open={walletDialogOpen} handleCloseDialog={handleCloseDialog} handleConnectedSuccess={walletLogin} askConfirmation={false} />
                        : null
                }

                {
                    isTransactionModalOpen ?
                        <TransactionProgressModal identity={'withdraw-stake-dic'} confirmStake={confirmStake} propData={assetDetailsPayload} handleModalClose={handleModalClose} openTransactionModal={isTransactionModalOpen} /> :
                        null
                }
                {
                    isTransactionModalCommissionOpen ?
                        <TransactionProgressModal identity={'withdraw-commission-dic'} confirmStake={confirmCommissionStake} propData={assetDetailsPayload} handleModalClose={handleModalCommissionClose} openTransactionModal={isTransactionModalCommissionOpen} /> :
                        null
                }
            </Box >

            <CommonBackdropLoader loading={loading} />

        </>
    );
};
export default WalletDic;
