import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, TextField, Button, Typography, InputAdornment, IconButton, MenuItem } from "@mui/material";
import { Edit, DownloadDone, ArrowBack, Add, Remove } from "@mui/icons-material";
import { Dollar, trending_up } from "../../../Components/Images/Images";
import { GetApi } from "../../../Api/Api";
import { CAPITAL_TYPE_ID, CATEGORY_TYPE, INVESTMENT_TYPE, INVESTMENT_TYPE_ID, SUB_CATEGORY_TYPE_ID } from "../../../constants";
import NumericFormatCustom from "../../../Components/Common/NumericFormatCustom";
import NumericFormatCustomDollar from "../../../Components/Common/NumericFormatCustomDollar";
import NumericFormatCustomPercent from "../../../Components/Common/NumericFormatCustomPercent";

const AssetReviewDetails = ({ assetData, handleSaveClick, handleConfirmProject, handleSuggestChanges, setForSuggestProjectDetail, forSuggestProjectDetail, forReviewProjectDetails, updateNewReviewData, fromVoting, justShowSuggestions }) => {
  const modalRef = useRef();

  // state to hold asset category name
  const [categoryName, setCategoryName] = useState("");
  // state to hold asset listing type
  const [listingName, setListingName] = useState("");
  // hold capital type data in real-estate
  const [capitalTypeDetails, setCapitalTypeDetails] = useState(assetData?.capital_info);
  const [sharedData, setSharedData] = useState(assetData?.capital_info);
  // for total raise with some of raise for every equity type condition
  const [errorTotalRaise, setErrorTotalRaise] = useState(false);
  // for total raise and project size condition
  const [errorProjectSize, setErrorProjectSize] = useState(false);
  // for minimum investment condition
  const [errorMinimumInvestment, setErrorMinimumInvestment] = useState(false);
  const [errorMinimumInvestmentL, setErrorMinimumInvestmentL] = useState(false);
  // for rate of return or equity condition
  const [errorRate, setErrorRate] = useState(false);
  const [errorLpRate, setErrorLpRate] = useState(false);
  const [iopError, setIopError] = useState(false);
  const [repayDayError, setRepayDayError] = useState(false);
  const [errorEstimatedValue, setErrorEstimatedValue] = useState(false);
  const [errorEstimatedValueWS, setErrorEstimatedValueWS] = useState(false);
  const [errorCommon, setErrorCommon] = useState(false);
  const [defaultData, setDefaultData] = useState(true);
  const [iopDurationErr, setIopDurationErr] = useState(false);
  const [debtDurationErr, setDebtDurationErr] = useState(false);
  const [isPaymentDate, setIsPaymentData] = useState(false);
  const [claimPercentError, setClaimPercentError] = useState(false);
  const [spaceXDurationCheck, setSpaceXDurationCheck] = useState(false);
  const [fundTokenLockPeriod, setFundTokenLockPeriod] = useState(false);
  // for fund close
  const [manageFeesError, setManageFeesError] = useState(false);
  const [spaceXMinError, setSpaceXMinError] = useState(false);
  const [carriedIntError, setCarriedIntError] = useState(false);

  const [minimumInvestZero, setMinimumInvestZero] = useState(false);
  const [watchData, setWatchData] = useState([]);
  const [carData, setCarData] = useState([]);
  const goldData = [
    { value: "", label: "Select Condition" },
    { value: "Mint", label: "Mint" },
    { value: "Pure Gold (24 karats)", label: "Pure Gold (24 karats)" },
    { value: "Hallmarked", label: "Hallmarked" },
  ];

  const [isBack, setIsBack] = useState(false);
  const [errorFromTo, setErrorFromTo] = useState({
    status1: false, Message1: "", key1: "",
    status2: false, Message2: "", key2: "",
    status3: false, Message3: "", key3: "",
  });

  // form to handle member inputs
  const [formData, setFormData] = useState({
    loan_amount_from: 0,
    loan_amount_to: 0,
    loan_duration_from: 0,
    loan_duration_to: 0,
    loan_roi_from: 0,
    loan_roi_to: 0,
    loan_iop: 0,
    fractionalize_duration_of_project: 0,
    fractionalize_project_size: 0,
    fractionalize_total_price: 0,
    fractionalize_minimum_investment: 0,
    parcel_total_price: 0,
    number_of_parcel: 0,
    minimun_investment_per_parcel: 0,
    asset_field1: "",
    asset_field2: "",
    asset_field3: "",
    asset_field4: "",
    capital_info: capitalTypeDetails,
    shared_data: sharedData,
    management_fees: 0
  });

  // function handle increment, decrement
  const handleIncrementDecrement = (name, changeBy, type) => {
    if (type === "inc") {
      let newValue = 0;
      if (changeBy === 1) {
        newValue = formData[name] ? parseInt(formData[name]) + 1 : changeBy;
      } else if (changeBy === 0.25) {
        newValue =
          formData[name] && formData[name] < 100
            ? parseFloat(parseFloat(formData[name]) + changeBy).toFixed(2)
            : formData[name]
              ? formData[name]
              : changeBy;
      } else {
        newValue = formData[name]
          ? parseFloat(parseFloat(formData[name]) + changeBy)
          : changeBy;
      }
      // Check if the number has more than two decimal places
      if (
        !Number.isInteger(newValue) &&
        newValue.toString().split(".")[1].length > 2
      ) {
        // If the number has more than two decimal places, apply toFixed(2)
        newValue = newValue.toFixed(2);
      }
      setFormData((prevData) => ({
        ...prevData,
        [name]: newValue,
      }));
    } else {
      let newValue = 0;
      if (changeBy === 1) {
        newValue = formData[name] > changeBy ? parseInt(formData[name]) - 1 : 0;
      } else {
        newValue =
          formData[name] >= changeBy
            ? parseFloat(formData[name]) - changeBy
            : 0;
      }
      // Check if the number has more than two decimal places
      if (
        !Number.isInteger(newValue) &&
        newValue.toString().split(".")[1].length > 2
      ) {
        // If the number has more than two decimal places, apply toFixed(2)
        newValue = newValue.toFixed(2);
      }
      setFormData((prevData) => ({
        ...prevData,
        [name]: newValue,
      }));
    }
  };

  const [editStates, setEditStates] = useState({
    isEditing: false,
    isEditingModal: false,
    isEditingEstimatedValue: false,
    isEditingCondition: false,
  });
  const handleEditClick = (field) => {
    setEditStates({ ...editStates, [field]: !editStates[field] });
  };

  useEffect(() => {
    async function getWatchInventory() {
      try {
        // getting response from api
        const res = await GetApi(`/user/watchInventoryData`);
        // setting data
        if (res?.data?.data?.length) {
          const watchCondition = res.data.data[0].watch_condition;
          const formattedOptions = watchCondition.map((option) => ({
            value: option,
            label: option,
          }));
          setWatchData([
            { value: "", label: "Select Condition" }, // Default option
            ...formattedOptions, // Spread the options from API
          ]);
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function getCarInventory() {
      try {
        // getting response from api
        const res = await GetApi(`/user/carInventoryData`);
        // setting data
        if (res?.data?.data?.length) {

          // const typeOfCar = res?.data?.data[0]?.type_of_car;
          const carCondition = res?.data?.data[0]?.car_condition;

          // const formattedOptionsType = typeOfCar.map(option => ({
          //   value: option,
          //   label: option
          // }));

          const formattedOptionsWatchCondition = carCondition.map(option => ({
            value: option,
            label: option
          }));

          // setCarType([
          //     { value: '', label: 'Select Type Of Car' }, // Default option
          //     ...formattedOptionsType // Spread the options from API
          // ]);

          setCarData([
            { value: '', label: 'Select Condition' }, // Default option
            ...formattedOptionsWatchCondition // Spread the options from API
          ]);
        }
      } catch (error) {
        console.log(error)
      }
    }

    getWatchInventory();
    getCarInventory();
  }, []);

  // // Access the first object in the sharedData array and get the 'hurdle_rate' property
  // const hurdleRate = sharedData[0]?.hurdle_rate;
  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.scrollTop = 0;
    }
    if (
      assetData &&
      (!updateNewReviewData || updateNewReviewData?.length === 0)
    ) {
      setFormData((prevData) => ({
        ...prevData,
        parcel_total_price: assetData?.parcel_total_price
          ? assetData?.parcel_total_price
          : assetData?.sale_price
            ? assetData?.sale_price
            : 0,
        number_of_parcel: assetData?.number_of_parcel
          ? assetData?.number_of_parcel
          : 0,
        minimun_investment_per_parcel: assetData?.minimun_investment_per_parcel
          ? assetData?.minimun_investment_per_parcel
          : assetData?.sale_deposit_amount
            ? assetData?.sale_deposit_amount
            : 0,
        fractionalize_project_size: assetData?.fractionalize_project_size
          ? assetData?.fractionalize_project_size
          : 0,
        fractionalize_total_price: assetData?.fractionalize_total_price
          ? assetData?.fractionalize_total_price
          : 0,
        fractionalize_duration_of_project: assetData?.fractionalize_duration_of_project
          ? assetData?.fractionalize_duration_of_project
          : 0,
        fractionalize_minimum_investment: assetData?.fractionalize_minimum_investment
          ? assetData?.fractionalize_minimum_investment
          : 0,
        asset_field1: assetData?.watch_brand
          ? assetData?.watch_brand
          : assetData?.assetInformation?.car_brand
            ? assetData?.assetInformation?.car_brand
            : assetData?.gold_weight,
        asset_field2: assetData?.watch_model
          ? assetData?.watch_model
          : assetData?.assetInformation?.car_model
            ? assetData?.assetInformation?.car_model
            : assetData?.gold_quality,
        asset_field3: assetData?.watch_estimated
          ? assetData?.watch_estimated
          : assetData?.assetInformation?.car_estimated
            ? assetData?.assetInformation?.car_estimated.toString()
            : assetData?.gold_estimated,
        asset_field4: assetData?.watch_condition
          ? assetData?.watch_condition
          : assetData?.assetInformation?.car_condition
            ? assetData?.assetInformation?.car_condition
            : assetData?.gold_condition,
        loan_amount_from: assetData?.loan_amount &&
          assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN
          ? assetData?.loan_minimum_investment
          : assetData?.parcel_total_price
            ? assetData?.parcel_total_price
            : assetData?.sale_price
              ? assetData?.sale_price
              : 0,
        loan_amount_to: assetData?.loan_amount
          ? assetData?.loan_amount
          : assetData?.minimun_investment_per_parcel
            ? assetData?.minimun_investment_per_parcel
            : assetData?.sale_deposit_amount
              ? assetData?.sale_deposit_amount
              : 0,
        loan_roi_to: assetData?.loan_roi ? assetData?.loan_roi : 0,
        loan_roi_from: assetData?.loan_roi ? 1 : 0,
        loan_duration_to: assetData?.loan_duration_month
          ? assetData?.loan_duration_month
          : 0,
        loan_duration_from: assetData?.loan_iop
          ? parseInt(assetData?.loan_iop) + 1
          : 1,
        loan_iop: assetData?.loan_iop ? assetData?.loan_iop : 0,
        loan_repay_day: assetData?.loan_repay_day ? assetData?.loan_repay_day : 0,
        loan_minimum_investment: assetData?.loan_minimum_investment
          ? parseFloat(assetData?.loan_minimum_investment)
          : 0,
        claim_percentage: assetData?.claim_percentage
          ? parseFloat(assetData?.claim_percentage)
          : 0,
        investment_duration: assetData?.investment_duration
          ? parseFloat(assetData?.investment_duration)
          : 0,
        management_fees: assetData?.management_fees
          ? parseFloat(assetData?.management_fees)
          : 0,
        carried_interest: assetData?.carried_interest
          ? parseFloat(assetData?.carried_interest)
          : 0,
      }));

      setCategoryName(assetData?.assets_category?.title);
      setListingName(assetData?.investment_type?.investment_name);
      setCapitalTypeDetails(assetData?.capital_info);
    } else if (
      (assetData && updateNewReviewData) ||
      updateNewReviewData?.length > 0
    ) {
      setFormData((prevData) => ({
        ...prevData,
        parcel_total_price: updateNewReviewData?.amount
          ? updateNewReviewData?.amount
          : 0,
        number_of_parcel: updateNewReviewData?.number_of_parcel
          ? updateNewReviewData?.number_of_parcel
          : 0,
        minimun_investment_per_parcel: updateNewReviewData?.minimum_investment
          ? updateNewReviewData?.minimum_investment
          : updateNewReviewData?.deposit_amount
            ? updateNewReviewData?.deposit_amount
            : 0,
        fractionalize_project_size: updateNewReviewData?.fractionalize_project_size
          ? updateNewReviewData?.fractionalize_project_size
          : 0,
        fractionalize_total_price: updateNewReviewData?.fractionalize_total_price
          ? updateNewReviewData?.fractionalize_total_price
          : 0,
        fractionalize_duration_of_project: updateNewReviewData?.fractionalize_duration_of_project
          ? updateNewReviewData?.fractionalize_duration_of_project
          : 0,
        fractionalize_minimum_investment: updateNewReviewData?.fractionalize_minimum_investment
          ? parseFloat(updateNewReviewData?.fractionalize_minimum_investment)
          : 0,
        asset_field1: updateNewReviewData?.watch_brand
          ? updateNewReviewData?.watch_brand
          : updateNewReviewData?.assetInformation?.car_brand
            ? updateNewReviewData?.assetInformation?.car_brand
            : updateNewReviewData?.gold_weight,
        asset_field2: updateNewReviewData?.watch_model
          ? updateNewReviewData?.watch_model
          : updateNewReviewData?.assetInformation?.car_model
            ? updateNewReviewData?.assetInformation?.car_model
            : updateNewReviewData?.gold_quality,
        asset_field3: updateNewReviewData?.watch_estimated
          ? updateNewReviewData?.watch_estimated
          : updateNewReviewData?.assetInformation?.car_estimated
            ? (updateNewReviewData?.assetInformation?.car_estimated).toString()
            : updateNewReviewData?.gold_estimated,
        asset_field4: updateNewReviewData?.watch_condition
          ? updateNewReviewData?.watch_condition
          : updateNewReviewData?.assetInformation?.car_condition
            ? updateNewReviewData?.assetInformation?.car_condition
            : updateNewReviewData?.gold_condition,
        loan_amount_from: updateNewReviewData?.loan_amount_from
          ? parseFloat(updateNewReviewData?.loan_amount_from)
          : updateNewReviewData?.amount
            ? parseFloat(updateNewReviewData?.amount)
            : 0,
        loan_amount_to: updateNewReviewData?.loan_amount_to
          ? parseFloat(updateNewReviewData?.loan_amount_to)
          : updateNewReviewData?.minimum_investment
            ? parseFloat(updateNewReviewData?.minimum_investment)
            : updateNewReviewData?.deposit_amount
              ? parseFloat(updateNewReviewData?.deposit_amount)
              : 0,
        loan_roi_to: updateNewReviewData?.loan_roi_to
          ? updateNewReviewData?.loan_roi_to
          : 0,
        loan_roi_from: updateNewReviewData?.loan_roi_from
          ? updateNewReviewData?.loan_roi_from
          : 0,
        loan_duration_to: updateNewReviewData?.loan_duration_to
          ? parseInt(updateNewReviewData?.loan_duration_to)
          : 0,
        loan_duration_from: updateNewReviewData?.loan_duration_from
          ? parseInt(updateNewReviewData?.loan_duration_from)
          : 0,
        loan_minimum_investment: updateNewReviewData?.loan_amount_from
          ? parseFloat(updateNewReviewData?.loan_amount_from)
          : 0,
        loan_repay_day: updateNewReviewData?.loan_repay_day
          ? updateNewReviewData?.loan_repay_day
          : 0,
        loan_iop: updateNewReviewData?.loan_iop
          ? updateNewReviewData?.loan_iop
          : 0,
        claim_percentage: updateNewReviewData?.claim_percentage
          ? parseFloat(updateNewReviewData?.claim_percentage)
          : 0,
        investment_duration: updateNewReviewData?.investment_duration
          ? parseFloat(updateNewReviewData?.investment_duration)
          : 0,
        management_fees: updateNewReviewData?.management_fees
          ? parseFloat(updateNewReviewData?.management_fees)
          : 0,
        carried_interest: updateNewReviewData?.carried_interest
          ? parseFloat(updateNewReviewData?.carried_interest)
          : 0,
        capital_info: updateNewReviewData?.capital_info,
        shared_data: assetData?.capital_info?.map((item) => ({
          ...item,
          ...updateNewReviewData?.capital_info?.find(
            (i) => parseInt(i.class, 10) === parseInt(item.class, 10)
          ),
        })),
      }));
      setCategoryName(assetData?.assets_category?.title);
      setListingName(assetData?.investment_type?.investment_name);
      setCapitalTypeDetails(updateNewReviewData?.capital_info);
      setSharedData(updateNewReviewData?.capital_info);
    }
  }, [assetData, updateNewReviewData, isBack]); // Reset scroll position when the modal opens or closes

  useEffect(() => {
    if (sharedData?.length) {
      setFormData((prevData) => ({
        ...prevData, shared_data: sharedData
      }))
    }
  }, [sharedData]);

  /**
   * Onclick function for Back button (user will go to the back step)
   */
  const handleBack = () => {
    setForSuggestProjectDetail(false);
    setDefaultData(true);
    setIsBack((pre) => !pre);
    setDebtDurationErr(false);
    setIopDurationErr(false);
  };

  /**
   * Handle on change value in Real-estate capital type data, changed by dic
   */
  const handleChangeCapitalTypeDat = (e, capital_name, name, current_index) => {
    // Function to update an object in the array
    // Create a new array with the updated object
    const updatedArray = capitalTypeDetails.map((item, index) => {
      if (
        item.capital_type &&
        item.capital_type.capital_name === capital_name &&
        index === current_index
      ) {
        return { ...item, [name]: e.target.value };
      }
      return item;
    });
    let sumOfCapitalInfo = 0;
    updatedArray.forEach((item) => {
      sumOfCapitalInfo += parseFloat(item?.total_raise ? item?.total_raise : 0);
    });

    let checkError = false;
    // common field validation real-estate
    updatedArray.forEach((item) => {
      if (
        item?.capital_type.id === CAPITAL_TYPE_ID.EQUITY &&
        (item.equity === "" ||
          item.total_raise === "" ||
          item.minimum_investment === "" ||
          item.rate_of_return === "")
      ) {
        checkError = true;
      } else if (
        item?.capital_type.id === CAPITAL_TYPE_ID.SENIOR_DEBT &&
        (item.total_raise === "" ||
          item.minimum_investment === "" ||
          item.iop_senior === "" ||
          item.senior_ror_to === "" ||
          item.senior_duration_to === "")
      ) {
        checkError = true;
      } else if (
        item?.capital_type.id === CAPITAL_TYPE_ID.JUNIOR_DEBT &&
        (item.total_raise === "" ||
          item.minimum_investment === "" ||
          item.junior_ror_to === "" ||
          item.iop_junior === "" ||
          item.junior_duration_to === "")
      ) {
        checkError = true;
      }
    });
    if (checkError === true) {
      setErrorCommon(true);
    } else {
      setErrorCommon(false);
    }

    // checking for rate of return
    const checkRateError = updatedArray.every((item) => {
      if (item?.capital_type.id === CAPITAL_TYPE_ID.EQUITY) {
        if (
          parseFloat(item.equity ? item.equity : 0) <= 100 &&
          parseFloat(item.rate_of_return ? item.rate_of_return : 0) <= 100
        )
          return true;
      } else if (
        parseFloat(item.rate_of_return ? item.rate_of_return : 0) <= 100
      ) {
        return true;
      }
      return false;
    });
    if (checkRateError === true) {
      setErrorRate(false);
    } else {
      setErrorRate(true);
    }

    if (
      parseFloat(sumOfCapitalInfo) !==
      parseFloat(formData.fractionalize_total_price)
    ) {
      setErrorTotalRaise(true);
    } else if (
      parseFloat(
        updatedArray[current_index].total_raise
          ? updatedArray[current_index].total_raise
          : 0
      ) <
      parseFloat(
        updatedArray[current_index].minimum_investment
          ? updatedArray[current_index].minimum_investment
          : 0
      )
    ) {
      setErrorMinimumInvestment(true);
    } else {
      setErrorMinimumInvestment(false);
      setErrorTotalRaise(false);
    }
    // checking for interest only period with debt duration and debt duration with project duration
    let durationErr = false;
    let debtDuration = false;
    updatedArray?.forEach((items) => {
      if (items?.capital_type.id === CAPITAL_TYPE_ID.SENIOR_DEBT) {
        if (
          parseInt(items?.iop_senior) >
          parseInt(items?.senior_duration_to) * 12
        ) {
          durationErr = true;
        }
        if (
          parseInt(formData?.fractionalize_duration_of_project) <
          parseInt(items?.senior_duration_to)
        ) {
          debtDuration = true;
        }
      } else if (items?.capital_type.id === CAPITAL_TYPE_ID.JUNIOR_DEBT) {
        if (
          parseInt(items?.iop_junior) >
          parseInt(items?.junior_duration_to) * 12
        ) {
          durationErr = true;
        }
        if (
          parseInt(formData?.fractionalize_duration_of_project) <
          parseInt(items?.junior_duration_to)
        ) {
          debtDuration = true;
        }
      }
    });
    if (durationErr) {
      setIopDurationErr(true);
    } else {
      setIopDurationErr(false);
    }
    if (debtDuration) {
      setDebtDurationErr(true);
    } else {
      setDebtDurationErr(false);
    }
    // Set the new state with the updated array
    setCapitalTypeDetails(updatedArray);
  };
  /**
   * Handle on change value in funds classes shared data
   */
  const handleChangeSharedData = (e, name, current_index) => {
    const updatedArray = sharedData.map((item, index) => {
      if (index === current_index) {
        return { ...item, [name]: e.target.value };
      }
      return item;
    });
    let checkError = false;
    // common field validation fund
    updatedArray.forEach((item) => {
      if (item.minimum_investment === "" || item.rate_of_return === "" || item.total_raise === "" || item?.lp_percentage === '') {
        checkError = true;
      }
    });
    if (checkError === true) {
      setErrorCommon(true);
    } else {
      setErrorCommon(false);
    }

    // checking for rate of return
    const checkRateError = updatedArray.every((item) => {
      if (parseFloat(item.rate_of_return ? item.rate_of_return : 0) <= 100) {
        return true;
      }
      return false;
    });
    if (checkRateError === true) {
      setErrorRate(false);
    } else {
      setErrorRate(true);
    }
    const checkLpError = updatedArray.every((item) => {
      if (parseFloat(item.lp_percentage ? item.lp_percentage : 0) <= 100) {
        return true;
      }
      return false;
    });
    if (checkLpError === true) {
      setErrorLpRate(false);
    } else {
      setErrorLpRate(true);
    }

    let sumOfCapitalInfo = 0;
    updatedArray.forEach((item) => {
      sumOfCapitalInfo += parseFloat(
        item?.total_raise ? item?.total_raise : 0
      );
    });
    if (updatedArray?.some((item) => parseFloat(item?.minimum_investment) === 0)) {
      setMinimumInvestZero(true);
    } else {
      setMinimumInvestZero(false);
    }
    if (
      parseFloat(sumOfCapitalInfo) !==
      parseFloat(formData.fractionalize_total_price)
    ) {
      setErrorTotalRaise(true);
    } else {
      setErrorTotalRaise(false);
    }
    if (updatedArray?.some((item) => parseFloat(item?.minimum_investment) > parseFloat(item?.total_raise))) {
      setErrorMinimumInvestment(true);
    } else {
      setErrorMinimumInvestment(false);
    }
    // Set the new state with the updated array
    setSharedData(updatedArray);
  };

  useEffect(() => {
    if (assetData?.capital_info) {
      setIsPaymentData(
        assetData?.capital_info?.some(
          (item) =>
            item?.capital_type?.id === CAPITAL_TYPE_ID.SENIOR_DEBT ||
            item?.capital_type?.id === CAPITAL_TYPE_ID.JUNIOR_DEBT
        )
      );
    }
  }, [assetData?.capital_info]);

  /**
   * Applying total raise conditions
   */
  useEffect(() => {
    if (capitalTypeDetails?.length) {
      let sumOfCapitalInfo = 0;
      capitalTypeDetails.forEach((item) => {
        sumOfCapitalInfo += parseFloat(
          item?.total_raise ? item?.total_raise : 0
        );
      });
      if (
        parseFloat(sumOfCapitalInfo) >
        parseFloat(formData.fractionalize_total_price)
      ) {
        setErrorTotalRaise(true);
      } else {
        setErrorTotalRaise(false);
      }
    }
  }, [
    formData.fractionalize_total_price,
    formData.fractionalize_project_size,
    capitalTypeDetails,
  ]);

  /**
   * Applying Error msg conditions
   */
  useEffect(() => {
    if (!fromVoting) {
      if (categoryName === CATEGORY_TYPE.GOLD || categoryName === CATEGORY_TYPE.WATCH || categoryName === CATEGORY_TYPE.ASSET || (assetData?.investment_type?.investment_name === INVESTMENT_TYPE.SALE &&
        categoryName === CATEGORY_TYPE.ART)) {
        // for sale type, sale amount
        if ((assetData?.investment_type?.investment_name === INVESTMENT_TYPE.SALE &&
          categoryName === CATEGORY_TYPE.ART)) {
          if (
            formData.loan_amount_from === "" ||
            formData.loan_amount_to === ""
            // || formData.investment_duration === "" // only from v2
          ) {
            setErrorCommon(true);
          } else {
            setErrorCommon(false);
          }

        }
        if (
          parseFloat(formData?.loan_amount_from) <=
          parseFloat(formData?.loan_amount_to) &&
          assetData?.investment_type?.investment_name === INVESTMENT_TYPE.SALE
        ) {
          setErrorFromTo({
            ...errorFromTo,
            Message1: "Sale amount can not be lesser than or equal to deposit amount.",
            key1: "amount",
            status1: true,
          });
        } else if (
          parseFloat(formData?.loan_amount_from) >
          parseFloat(formData?.loan_amount_to) &&
          (categoryName === CATEGORY_TYPE.GOLD ||
            categoryName === CATEGORY_TYPE.ASSET ||
            (categoryName === CATEGORY_TYPE.WATCH &&
              assetData?.investment_type?.investment_name ===
              INVESTMENT_TYPE.LOAN))
        ) {
          setErrorFromTo({
            ...errorFromTo,
            Message1: "The 'To' loan amount cannot be lower than the 'From' loan amount.",
            key1: "amount",
            status1: true,
          });
        }

        // estimated value and 'to' loan amount
        const stringWithoutCurrency = formData?.asset_field3?.replace(/[^0-9.]/g, ""); // Removes non-numeric characters
        const integerValue = parseFloat(stringWithoutCurrency, 10); // Parses the string as an float
        if (
          categoryName === CATEGORY_TYPE.WATCH &&
          assetData?.investment_type?.investment_name ===
          INVESTMENT_TYPE.SALE &&
          parseFloat(formData?.loan_amount_from) > integerValue
        ) {
          setErrorEstimatedValueWS(true);
        } else {
          setErrorEstimatedValueWS(false);
        }
        if (parseFloat(formData?.loan_amount_to) > integerValue) {
          setErrorEstimatedValue(true);
        } else {
          setErrorEstimatedValue(false);
        }
        if (
          parseFloat(formData?.loan_duration_from) >
          parseFloat(formData?.loan_duration_to) &&
          assetData?.investment_type?.investment_name === INVESTMENT_TYPE.LOAN
        ) {
          setErrorFromTo({
            ...errorFromTo,
            Message2:
              "The 'To' loan duration cannot be lower than the 'From' loan duration.",
            key2: "duration",
            status2: true,
          });
        }
        if (
          parseFloat(formData?.loan_roi_from) >
          parseFloat(formData?.loan_roi_to) &&
          assetData?.investment_type?.investment_name === INVESTMENT_TYPE.LOAN
        ) {
          setErrorFromTo({
            ...errorFromTo,
            Message3:
              "The 'To' loan interest rate cannot be lower than the 'From' loan interest rate.",
            key3: "interest",
            status3: true,
          });
        }

        if (
          (parseFloat(formData?.loan_amount_from) <=
            parseFloat(formData?.loan_amount_to) &&
            errorFromTo.status1 &&
            (categoryName === CATEGORY_TYPE.GOLD ||
              categoryName === CATEGORY_TYPE.ASSET ||
              (categoryName === CATEGORY_TYPE.WATCH &&
                assetData?.investment_type?.investment_name ===
                INVESTMENT_TYPE.LOAN))) ||
          (errorFromTo.status1 &&
            parseFloat(formData?.loan_amount_from) >
            parseFloat(formData?.loan_amount_to) &&
            assetData?.investment_type?.investment_name ===
            INVESTMENT_TYPE.SALE)
        ) {
          setErrorFromTo({ ...errorFromTo, status1: false });
        }
        if (
          parseFloat(formData?.loan_duration_from) <=
          parseFloat(formData?.loan_duration_to) &&
          errorFromTo.status2
        ) {
          setErrorFromTo({ ...errorFromTo, status2: false });
        }
        if (
          parseFloat(formData?.loan_roi_from) <=
          parseFloat(formData?.loan_roi_to) &&
          errorFromTo.status3
        ) {
          setErrorFromTo({ ...errorFromTo, status3: false });
        }

        if (
          categoryName === CATEGORY_TYPE.GOLD ||
          categoryName === CATEGORY_TYPE.ASSET ||
          categoryName === CATEGORY_TYPE.WATCH
        ) {
          if (
            formData.asset_field1 === "" ||
            formData.asset_field2 === "" ||
            formData.asset_field3 === "" ||
            formData.asset_field4 === "" ||
            formData.loan_amount_from === "" ||
            formData.loan_amount_to === "" ||
            formData.loan_duration_from === "" ||
            formData.loan_duration_to === "" ||
            formData.loan_roi_from === "" ||
            formData.loan_roi_to === "" ||
            formData.loan_iop === "" ||
            formData.claim_percentage === "" ||
            formData.loan_repay_day === "" ||
            formData.loan_minimum_investment === ""
          ) {
            setErrorCommon(true);
          } else {
            setErrorCommon(false);
          }

          if (formData.claim_percentage > 100) {
            setClaimPercentError(true);
          } else {
            setClaimPercentError(false);
          }
          if (
            parseFloat(formData?.loan_amount_to) <
            parseFloat(formData?.loan_minimum_investment)
          ) {
            setErrorMinimumInvestmentL(true);
          } else {
            setErrorMinimumInvestmentL(false);
          }
          if (
            parseFloat(formData.loan_repay_day) < 1 ||
            parseFloat(formData.loan_repay_day) > 28
          ) {
            setRepayDayError(true);
          } else {
            setRepayDayError(false);
          }
          if (
            parseFloat(formData.loan_iop) >=
            parseFloat(formData.loan_duration_from)
          ) {
            setIopError(true);
          } else {
            setIopError(false);
          }
        }
      } else if (categoryName === CATEGORY_TYPE.REAL_ESTATE) {
        if (
          parseFloat(formData?.fractionalize_project_size) <
          parseFloat(formData?.fractionalize_total_price)
        ) {
          setErrorProjectSize(true);
        } else {
          setErrorProjectSize(false);
        }
        if (formData.claim_percentage > 100) {
          setClaimPercentError(true);
        } else {
          setClaimPercentError(false);
        }
        if (
          formData.fractionalize_duration_of_project === "" ||
          formData.fractionalize_project_size === "" ||
          formData.fractionalize_total_price === ""
        ) {
          setErrorCommon(true);
        } else {
          setErrorCommon(false);
        }
        if (
          parseFloat(formData.loan_repay_day) < 1 ||
          parseFloat(formData.loan_repay_day) > 28
        ) {
          setRepayDayError(true);
        } else {
          setRepayDayError(false);
        }
        let sumOfCapitalInfo = 0;
        formData?.capital_info?.forEach((item) => {
          sumOfCapitalInfo += parseFloat(
            item?.total_raise ? item?.total_raise : 0
          );
        });
        if (
          parseFloat(sumOfCapitalInfo) !==
          parseFloat(formData.fractionalize_total_price)
        ) {
          setErrorTotalRaise(true);
        } else {
          setErrorTotalRaise(false);
        }
      } else if (
        assetData?.investment_type?.investment_name ===
        INVESTMENT_TYPE.PARCEL &&
        categoryName === CATEGORY_TYPE.ART
      ) {
        if (formData.claim_percentage > 100) {
          setClaimPercentError(true);
        } else {
          setClaimPercentError(false);
        }

        if (formData.loan_amount_from === "" || formData.claim_percentage === "") {
          setErrorCommon(true);
        } else {
          setErrorCommon(false);
        }
        if (parseFloat(formData?.loan_amount_from) === 0) {
          setErrorFromTo({
            ...errorFromTo,
            Message1: "Sale Amount can not be equal to 0.",
            key1: "amount",
            status1: true,
          });
        } else {
          setErrorFromTo({
            ...errorFromTo,
            Message1: "",
            key1: "amount",
            status1: false,
          });
        }
      } else if (categoryName === CATEGORY_TYPE.FUNDS) {
        if (assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
          if (formData.fractionalize_duration_of_project === "" || formData.fractionalize_total_price === "" || formData.management_fees === '' || formData.carried_interest === '' || formData.claim_percentage === '' || parseFloat(formData?.investment_duration) === '' || parseFloat(formData?.investment_duration) === 0 || formData?.fractionalize_minimum_investment === '' || formData?.token_lock_period === '') {
            setErrorCommon(true);
          } else {
            setErrorCommon(false);
          }
        } else {
          if (formData.fractionalize_duration_of_project === "" || formData.fractionalize_total_price === "" || formData?.token_lock_period === '' || sharedData?.some((item) => (item.minimum_investment === "" || item.rate_of_return === "" || item.total_raise === "" || item?.lp_percentage === ''))) {
            setErrorCommon(true);
          } else {
            setErrorCommon(false);
          }
        }
        if (formData.management_fees > 100) { setManageFeesError(true); } else { setManageFeesError(false); }
        if (formData.carried_interest > 100) { setCarriedIntError(true); } else { setCarriedIntError(false); }
        if (formData.claim_percentage > 100) { setClaimPercentError(true); } else { setClaimPercentError(false); }

        if (parseFloat(formData?.fractionalize_total_price) < (parseFloat(formData?.fractionalize_minimum_investment) + parseFloat(formData?.fractionalize_total_price) * parseFloat(formData?.management_fees / 100))) {
          setSpaceXMinError(true);
        } else {
          setSpaceXMinError(false);

        }
        if (
          parseFloat(formData?.fractionalize_total_price) <
          parseFloat(formData?.fractionalize_minimum_investment)
        ) {
          setErrorMinimumInvestmentL(true);
        } else {
          setErrorMinimumInvestmentL(false);
        }

        if (categoryName === CATEGORY_TYPE.FUNDS) {
          let sumOfCapitalInfo = 0;
          sharedData?.forEach((item) => {
            sumOfCapitalInfo += parseFloat(
              item?.total_raise ? item?.total_raise : 0
            );
          });
          if (parseFloat(sumOfCapitalInfo) !== parseFloat(formData.fractionalize_total_price)) {
            setErrorTotalRaise(true);
          } else {
            setErrorTotalRaise(false);
          }
        }
        if (parseFloat(formData.fractionalize_duration_of_project * 365) < parseFloat(formData.investment_duration)) {
          setSpaceXDurationCheck(true);
        } else {
          setSpaceXDurationCheck(false);
        }
        if (parseFloat(formData.fractionalize_duration_of_project * 365) < parseFloat(formData.token_lock_period)) {
          setFundTokenLockPeriod(true);
        } else {
          setFundTokenLockPeriod(false);
        }
      }
    }
  }, [formData, assetData, fromVoting]);

  useEffect(() => {
    if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN && formData?.loan_minimum_investment) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        loan_amount_from: parseFloat(prevFormData.loan_minimum_investment),
      }));
    }
  }, [formData?.loan_minimum_investment, assetData?.investment_type_id]);

  const handleSubmitDetails = () => {
    formData["capital_info"] = capitalTypeDetails;
    formData["shared_data"] = sharedData;
    handleSaveClick(formData);
  };

  useEffect(() => {
    if (capitalTypeDetails?.length && defaultData && assetData?.capital_info?.length) {
      let defaultCapital = assetData?.capital_info;
      for (let i = 0; i < capitalTypeDetails?.length; i++) {
        capitalTypeDetails[i] = defaultCapital[i];
        setDefaultData(false);
        if (capitalTypeDetails[i]?.capital_type?.capital_name === "Senior Debt") {
          capitalTypeDetails[i].senior_duration_to = capitalTypeDetails[i].senior_duration;
          capitalTypeDetails[i].senior_ror_to = capitalTypeDetails[i].rate_of_return;
          // capitalTypeDetails[i].iop_senior = capitalTypeDetails[i].iop_senior;
        } else if (capitalTypeDetails[i]?.capital_type?.capital_name === "Junior Debt") {
          capitalTypeDetails[i].junior_duration_to = capitalTypeDetails[i].junior_duration;
          capitalTypeDetails[i].junior_ror_to = capitalTypeDetails[i].rate_of_return;
          // capitalTypeDetails[i].iop_junior = capitalTypeDetails[i].iop_junior;
        }
      }
    }
  }, [defaultData, capitalTypeDetails]);

  return (
    <Box
      className="modalContentDone scrollDesign"
      style={{
        maxHeight: fromVoting ? "" : "400px",
        overflow:
          fromVoting || "auto",
        flexDirection: fromVoting ? "" : "column",
      }}
      ref={modalRef}
    >
      <Box className="main-content loan ">
        <Box
          className="votingContent"
          sx={{ marginTop: !fromVoting ? "-15px" : "0px" }}
        >
          {/* Common code for the Watch, Gold, Art */}
          <Box className="submitDetails">
            {justShowSuggestions ?
              <>
                <Typography className="font-28" sx={{ marginTop: "8px" }}>
                  {assetData && (!updateNewReviewData || updateNewReviewData?.length === 0) ? "Project Details" : "Suggested Details"}
                </Typography>
                <Typography className="font-16" marginBottom={"29px"}>
                  {" "}
                </Typography>
              </> :
              !fromVoting && updateNewReviewData?.length === 0 ? (
                <>
                  <Typography className="font-28" sx={{ marginTop: "8px" }}>
                    Review Project{" "}
                    {assetData?.investment_type?.investment_name === "Parcelling"
                      ? "Parcel"
                      : assetData?.investment_type?.investment_name}{" "}
                    Details
                  </Typography>
                  <Typography className="font-16" marginBottom={"29px"}>
                    Please confirm the listing details for the project.{" "}
                  </Typography>
                </>
              ) : (
                !fromVoting && (
                  <>
                    <Typography className="font-28" sx={{ marginTop: "8px" }}>
                      Review Project Details
                    </Typography>
                    <Typography className="font-14" marginBottom={"29px"}>
                      The RACE’s Investment Committee has reviewed your project
                      proposal and proposed the following details for your
                      Project, {assetData?.asset_title}. You can accept or reject
                      the terms laid out by the Investment Committee below. Please
                      note that by rejecting the terms, your project will be
                      rejected.{" "}
                    </Typography>
                  </>
                )
              )}
            <Grid container spacing={2}>
              <Grid
                item
                md={categoryName === CATEGORY_TYPE.ART ? 4 : 6}
                xs={12}
                sm={categoryName === CATEGORY_TYPE.ART ? 4 : 6}
              >
                <Box className="submitDetail-form">
                  <Box className="detail-box">
                    <img src={Dollar} alt="" className="dIcon" />
                    <Box className="lD-txt">
                      <Typography className="lDetail-txt1">
                        Investment Type
                      </Typography>
                      <Typography
                        className="lDetail-txt2"
                        textAlign={"left"}
                        marginLeft={"0px !important"}
                      >
                        {assetData?.investment_type?.investment_name ===
                          "Parcelling"
                          ? "Parcel"
                          : assetData?.investment_type?.investment_name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                md={categoryName === CATEGORY_TYPE.ART ? 4 : 6}
                xs={12}
                sm={categoryName === CATEGORY_TYPE.ART ? 4 : 6}
              >
                <Box className="submitDetail-form">
                  <Box className="detail-box">
                    <img src={Dollar} alt="" className="dIcon" />
                    <Box className="lD-txt">
                      <Typography className="lDetail-txt1">
                        Asset Type
                      </Typography>
                      <Typography
                        className="lDetail-txt2"
                        textAlign={"left"}
                        marginLeft={"0px !important"}
                      >
                        {categoryName}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              {categoryName === CATEGORY_TYPE.ART && (
                <Grid item md={4} xs={12} sm={4}>
                  <Box className="submitDetail-form">
                    <Box className="detail-box">
                      <img src={trending_up} alt="" className="dIcon" />
                      <Box className="lD-txt">
                        <Typography className="lDetail-txt1" textAlign={"left"}>
                          Asset Sub Type
                        </Typography>
                        <Typography
                          className="lDetail-txt2"
                          textAlign={"left"}
                          marginLeft={"0px !important"}
                        >
                          {assetData?.asset_sub_category_info?.title}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
          {/* only for watch / gold */}
          {(categoryName === CATEGORY_TYPE.WATCH ||
            categoryName === CATEGORY_TYPE.ASSET ||
            categoryName === CATEGORY_TYPE.GOLD) && (
              <Box marginTop={"20px"} className="autofill-text-white">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="outlined-number"
                      type="text"
                      // label="Brand"
                      label={
                        categoryName === CATEGORY_TYPE.WATCH ||
                          categoryName === CATEGORY_TYPE.ASSET
                          ? "Brand"
                          : "Weight in Grams"
                      }
                      className="loan-text"
                      InputLabelProps={{
                        shrink: true,
                        style: { color: "white" },
                      }}
                      inputProps={{
                        style: {
                          color: "white",
                        },
                      }}
                      value={formData.asset_field1}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!forSuggestProjectDetail ? null : (
                              <IconButton
                                disabled={
                                  editStates.isEditing === true ||
                                    !forSuggestProjectDetail
                                    ? true
                                    : false
                                }
                              >
                                <Edit
                                  style={{ color: "#1771BD" }}
                                  onClick={() => handleEditClick("isEditing")}
                                />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      disabled={!editStates.isEditing} // Disable the input field when not editing
                      onChange={(e) => {
                        // handleChange('brand', e);
                        setFormData({
                          ...formData,
                          asset_field1: e.target.value,
                        });
                      }}
                    />
                    {formData.asset_field1 === "" && (
                      <Typography style={{ color: "red", fontSize: "12px" }}>
                        Please provide{" "}
                        {categoryName === CATEGORY_TYPE.WATCH ||
                          categoryName === CATEGORY_TYPE.ASSET
                          ? "brand name"
                          : "weight in grams value"}{" "}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="outlined-number"
                      type="text"
                      // label={assetData?.watch_model ? "Model" : "Quality in Carat"}
                      // label={"Model"}
                      label={
                        categoryName === CATEGORY_TYPE.WATCH ||
                          categoryName === CATEGORY_TYPE.ASSET
                          ? "Model"
                          : "Quality in Carat"
                      }
                      // defaultValue={assetData?.watch_model ? assetData?.watch_model : assetData?.gold_quality}
                      value={formData.asset_field2}
                      className="loan-text"
                      InputLabelProps={{
                        shrink: true,
                        style: { color: "white" },
                      }}
                      inputProps={{
                        style: {
                          color: "white",
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!forSuggestProjectDetail ? null : (
                              <IconButton
                                disabled={
                                  editStates.isEditingModal === true ||
                                    !forSuggestProjectDetail
                                    ? true
                                    : false
                                }
                              >
                                <Edit
                                  style={{ color: "#1771BD" }}
                                  onClick={() =>
                                    handleEditClick("isEditingModal")
                                  }
                                />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      disabled={!editStates.isEditingModal} // Disable the input field when not editing
                      onChange={(e) => {
                        // handleChange('model', e);
                        setFormData({
                          ...formData,
                          asset_field2: e.target.value,
                        });
                      }}
                    />
                    {formData.asset_field2 === "" ? (
                      <Typography style={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        Please provide{" "}
                        {categoryName === CATEGORY_TYPE.WATCH ||
                          categoryName === CATEGORY_TYPE.ASSET
                          ? "model name"
                          : "quality in Carat value"}{" "}
                      </Typography>
                    ) : (formData.asset_field2 < 18 ||
                      formData.asset_field2 > 24) &&
                      categoryName === CATEGORY_TYPE.GOLD ? (
                      <Typography style={{ color: "red", fontSize: "12px" }}>
                        The gold purity must be between 18K and 24K.
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="outlined-number"
                      type="text"
                      label="Estimated value at purchase"
                      // defaultValue={assetData?.watch_estimated ? assetData?.watch_estimated : assetData?.gold_estimated}
                      value={formData.asset_field3}
                      className="loan-text"
                      InputLabelProps={{
                        shrink: true,
                        style: { color: "white" },
                      }}
                      inputProps={{
                        style: {
                          color: "white",
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!forSuggestProjectDetail ? null : (
                              <IconButton
                                disabled={
                                  editStates.isEditingEstimatedValue === true ||
                                    !forSuggestProjectDetail
                                    ? true
                                    : false
                                }
                              >
                                <Edit
                                  style={{ color: "#1771BD" }}
                                  onClick={() =>
                                    handleEditClick("isEditingEstimatedValue")
                                  }
                                />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                        inputComponent: NumericFormatCustomDollar,
                      }}
                      disabled={!editStates.isEditingEstimatedValue} // Disable the input field when not editing
                      onChange={(e) => {
                        // handleChange('estimatedValue', e);
                        setFormData({
                          ...formData,
                          asset_field3: e.target.value,
                        });
                      }}
                    />
                    {formData.asset_field3 === "" && (
                      <Typography style={{ color: "red", fontSize: "12px" }}>
                        Please provide estimated value{" "}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {editStates?.isEditingCondition ? (
                      <TextField
                        select
                        label="Condition"
                        value={formData.asset_field4}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            asset_field4: e.target.value,
                          })
                        }
                        className="loan-text"
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "white" },
                        }}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                backgroundColor: "#00294b", // Match dropdown background to the overall theme
                                color: "white", // Match dropdown text color to the overall theme
                              },
                            },
                          },
                          style: { color: "white" }, // Ensure the text color is white
                        }}
                        inputProps={{
                          style: { color: "white" },
                        }}
                        variant="outlined"
                        fullWidth
                      >
                        {
                          categoryName === CATEGORY_TYPE.WATCH ? (
                            watchData?.map((option) => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                                style={{ color: "white" }}
                              >
                                {option.label}
                              </MenuItem>
                            ))
                          ) : categoryName === CATEGORY_TYPE.ASSET ? (
                            carData?.map((option) => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                                style={{ color: "white" }}
                              >
                                {option.label}
                              </MenuItem>
                            ))
                          ) : (
                            goldData?.map((option) => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                                style={{ color: "white" }}
                              >
                                {option.label}
                              </MenuItem>
                            ))
                          )
                        }
                      </TextField >
                    ) : (
                      <TextField
                        label="Condition"
                        value={formData.asset_field4}
                        disabled
                        className="loan-text"
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "white" },
                        }}
                        inputProps={{
                          style: { color: "white" },
                          readOnly: true,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {!forSuggestProjectDetail ? null : (
                                <IconButton
                                  onClick={() =>
                                    handleEditClick("isEditingCondition")
                                  }
                                >
                                  <Edit style={{ color: "#1771BD" }} />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                          style: { borderColor: "black" },
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    {
                      formData.asset_field4 === "" && (
                        <Typography style={{ color: "red", fontSize: "12px" }}>
                          Please provide condition
                        </Typography>
                      )
                    }
                  </Grid >
                </Grid >
              </Box >
            )}

          {/* Common code for the Watch, Gold, Art, Real estate */}
          <Box className="submitDetail-field">
            {/* showing this text for watch and gold */}
            {((categoryName === CATEGORY_TYPE.WATCH &&
              assetData?.investment_type?.investment_name ===
              INVESTMENT_TYPE.LOAN) ||
              categoryName === CATEGORY_TYPE.GOLD ||
              categoryName === CATEGORY_TYPE.ASSET) && (
                <Typography className="font-16">Range of Loan Amount</Typography>
              )}

            {/* code for watch,art and gold only */}
            {categoryName !== CATEGORY_TYPE.REAL_ESTATE &&
              categoryName !== CATEGORY_TYPE.FUNDS && (
                <Box className="loan-content">
                  <Grid container spacing={2}>
                    {/* common for watch(from) / gold(from) and art (sale/ parcel) sale amount */}
                    <Grid
                      item
                      md={categoryName === CATEGORY_TYPE.ART && listingName === INVESTMENT_TYPE.PARCEL ? 4 : 6}
                      xs={12}
                      sm={categoryName === CATEGORY_TYPE.ART && listingName === INVESTMENT_TYPE.PARCEL ? 4 : 6}
                    >
                      <Typography
                        className={
                          categoryName === CATEGORY_TYPE.ART ||
                            (categoryName === CATEGORY_TYPE.WATCH &&
                              assetData?.investment_type?.investment_name ===
                              INVESTMENT_TYPE.SALE)
                            ? "assetReviewModal-field-title"
                            : "font-12"
                        }
                        mt={1}
                        mb={-1}
                      >
                        {categoryName === CATEGORY_TYPE.ART ||
                          (categoryName === CATEGORY_TYPE.WATCH &&
                            assetData?.investment_type?.investment_name ===
                            INVESTMENT_TYPE.SALE)
                          ? "Sale Amount"
                          : ""}
                      </Typography>
                      <Box className="dic-NS-sale-details dic-fitSize" my={1}>
                        <Box className="dic-NS-sale-details-icon">
                          {!forSuggestProjectDetail ? null : (
                            <IconButton
                              disabled={!forSuggestProjectDetail || assetData.investment_type_id ===
                                INVESTMENT_TYPE_ID.LOAN}
                              onClick={() => {
                                handleIncrementDecrement(
                                  "loan_amount_from",
                                  500,
                                  "dec"
                                );
                              }}
                              className="dic-NS-sale-details-icon-btn"
                            >
                              <Remove />
                            </IconButton>
                          )}
                        </Box>
                        <Box>
                          <TextField
                            id="outlined-number"
                            type="text"
                            // defaultValue={500000}
                            className="loan-text"
                            InputLabelProps={{
                              shrink: true,
                              style: { marginLeft: "90px" },
                            }}
                            label={
                              categoryName === CATEGORY_TYPE.ART ||
                                (categoryName === CATEGORY_TYPE.WATCH &&
                                  assetData?.investment_type?.investment_name ===
                                  INVESTMENT_TYPE.SALE)
                                ? ""
                                : "From"
                            }
                            InputProps={{
                              inputComponent: NumericFormatCustomDollar,
                              readOnly:
                                !forSuggestProjectDetail ||
                                assetData.investment_type_id ===
                                INVESTMENT_TYPE_ID.LOAN, // conditionally displaying the values and edit functionality
                              style: { marginTop: "4px" },
                            }}
                            value={formData.loan_amount_from}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                loan_amount_from: e.target.value,
                              });
                              // if (CATEGORY_TYPE.ART && listingName === INVESTMENT_TYPE.PARCEL) {
                              //   setFormData({ ...formData, loan_amount_from: parseFloat((formData?.loan_amount_from / assetData?.parcel_total_price) * (assetData?.minimun_investment_per_parcel)).toFixed(2) })
                              // }
                            }}
                            onKeyDown={(event) => {
                              if (event?.key === "-" || event?.key === "+") {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Box>
                        <Box className="dic-NS-sale-details-icon">
                          {!forSuggestProjectDetail ? null : (
                            <IconButton
                              disabled={!forSuggestProjectDetail || assetData.investment_type_id ===
                                INVESTMENT_TYPE_ID.LOAN}
                              onClick={() => {
                                handleIncrementDecrement(
                                  "loan_amount_from",
                                  500,
                                  "inc"
                                );
                              }}
                              className="dic-NS-sale-details-icon-btn"
                            >
                              <Add />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                      {formData.loan_amount_from === "" ? (
                        <Typography
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "-14px",
                          }}
                        >
                          Please provide a value
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                    {/* only for art (parcel) number of parcel */}
                    {categoryName === CATEGORY_TYPE.ART &&
                      listingName === INVESTMENT_TYPE.PARCEL && (
                        <Grid item md={4} xs={12} sm={4}>
                          <Typography
                            sx={{ color: "#94A3B8", fontSize: "14px" }}
                            mt={1}
                            mb={-1}
                          >
                            Number Of Parcel
                          </Typography>
                          <Box
                            className="dic-NS-sale-details dic-fitSize"
                            my={1}
                          >
                            <Box className="dic-NS-sale-details-icon">
                              {CATEGORY_TYPE.ART &&
                                listingName === INVESTMENT_TYPE.PARCEL ? (
                                true
                              ) : !updateNewReviewData || fromVoting ? null : (
                                <IconButton
                                  disabled={true}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "number_of_parcel",
                                      1,
                                      "dec"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Remove />
                                </IconButton>
                              )}
                            </Box>
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                defaultValue={5000}
                                className="loan-text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                  readOnly: true, // conditionally displaying the values and edit functionality
                                }}
                                value={formData.number_of_parcel}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    number_of_parcel: e.target.value,
                                  })
                                }
                                onKeyDown={(event) => {
                                  if (
                                    event?.key === "-" ||
                                    event?.key === "+"
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              {formData.number_of_parcel === "" && (
                                <Box style={{ color: "red", fontSize: "10px" }}>
                                  Please provide number of parcel
                                </Box>
                              )}
                            </Box>
                            <Box className="dic-NS-sale-details-icon">
                              {CATEGORY_TYPE.ART &&
                                listingName === INVESTMENT_TYPE.PARCEL ? (
                                true
                              ) : !updateNewReviewData || fromVoting ? null : (
                                <IconButton
                                  disabled={true}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "number_of_parcel",
                                      1,
                                      "inc"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Add />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                        </Grid>
                      )}
                    {/* // only from v2 */}
                    {/* {categoryName === CATEGORY_TYPE.ART &&
                      listingName === INVESTMENT_TYPE.SALE && (
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            sx={{ color: "#94A3B8", fontSize: "14px" }}
                          >
                            Project Expiry Duration (Months)
                          </Typography>
                          <Box className="dic-NS-sale-details" my={1}>
                            <Box className="dic-NS-sale-details-icon">
                              {!updateNewReviewData ||
                                fromVoting ||
                                !forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "investment_duration",
                                      1,
                                      "dec"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Remove />
                                </IconButton>
                              )}
                            </Box>
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                defaultValue={0}
                                className="loan-text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                  readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                                }}
                                name="investment_duration"
                                value={
                                  formData.investment_duration
                                }
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    investment_duration:
                                      e.target.value,
                                  })
                                }
                                onKeyDown={(event) => {
                                  if (
                                    event?.key === "-" ||
                                    event?.key === "+" ||
                                    event?.key === "."
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                            <Box className="dic-NS-sale-details-icon">
                              {!updateNewReviewData ||
                                fromVoting ||
                                !forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "investment_duration",
                                      1,
                                      "inc"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Add />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                          {formData.investment_duration === "" ||
                            parseFloat(formData.investment_duration) === 0 ? (
                            <Typography
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "-14px",
                              }}
                            >
                              {formData.investment_duration === ""
                                ? "Please provide a value."
                                : "Project expiry duration can not be zero."}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Grid>)} */}

                    {/* common for watch / gold and art */}
                    <Grid
                      item
                      md={categoryName === CATEGORY_TYPE.ART && listingName === INVESTMENT_TYPE.PARCEL ? 4 : 6}
                      xs={12}
                      sm={categoryName === CATEGORY_TYPE.ART && listingName === INVESTMENT_TYPE.PARCEL ? 4 : 6}
                    >
                      <Typography
                        className={
                          categoryName === CATEGORY_TYPE.ART ||
                            (categoryName === CATEGORY_TYPE.WATCH &&
                              assetData?.investment_type?.investment_name ===
                              INVESTMENT_TYPE.SALE)
                            ? "assetReviewModal-field-title"
                            : "font-12"
                        }
                        mt={1}
                        mb={-1}
                      >
                        {categoryName === CATEGORY_TYPE.ART &&
                          listingName === INVESTMENT_TYPE.PARCEL
                          ? "Minimum Investment"
                          : categoryName === CATEGORY_TYPE.ART ||
                            (categoryName === CATEGORY_TYPE.WATCH &&
                              assetData?.investment_type?.investment_name ===
                              INVESTMENT_TYPE.SALE)
                            ? "Deposit Amount"
                            : ""}
                      </Typography>
                      <Box className="dic-NS-sale-details dic-fitSize" my={1}>
                        <Box className="dic-NS-sale-details-icon">
                          {CATEGORY_TYPE.ART &&
                            listingName === INVESTMENT_TYPE.PARCEL ? (
                            true
                          ) : !forSuggestProjectDetail ? null : (
                            <IconButton
                              disabled={
                                CATEGORY_TYPE.ART &&
                                  listingName === INVESTMENT_TYPE.PARCEL
                                  ? true
                                  : !forSuggestProjectDetail
                              }
                              onClick={() => {
                                handleIncrementDecrement(
                                  "loan_amount_to",
                                  500,
                                  "dec"
                                );
                              }}
                              className="dic-NS-sale-details-icon-btn"
                            >
                              <Remove />
                            </IconButton>
                          )}
                        </Box>
                        <Box>
                          <TextField
                            id="outlined-number"
                            type="text"
                            defaultValue={50000}
                            className="loan-text"
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                // Add inline style here
                                marginLeft: "96px", // Change color to your preferred color
                                // Add more styles if needed
                              },
                            }}
                            label={
                              categoryName === CATEGORY_TYPE.ART ||
                                (categoryName === CATEGORY_TYPE.WATCH &&
                                  assetData?.investment_type?.investment_name ===
                                  INVESTMENT_TYPE.SALE)
                                ? ""
                                : "To"
                            }
                            InputProps={{
                              inputComponent: NumericFormatCustomDollar,
                              readOnly:
                                CATEGORY_TYPE.ART &&
                                  listingName === INVESTMENT_TYPE.PARCEL
                                  ? true
                                  : !forSuggestProjectDetail,
                              style: {
                                // Add inline style here
                                marginTop: "4px", // Change color to your preferred color
                                // Add more styles if needed
                              },
                            }}
                            value={
                              CATEGORY_TYPE.ART &&
                                listingName === INVESTMENT_TYPE.PARCEL
                                ? parseFloat(
                                  (formData?.loan_amount_from /
                                    assetData?.parcel_total_price) *
                                  assetData?.minimun_investment_per_parcel
                                ).toFixed(2)
                                : formData.loan_amount_to
                            }
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                loan_amount_to:
                                  CATEGORY_TYPE.ART &&
                                    listingName === INVESTMENT_TYPE.PARCEL
                                    ? parseFloat(
                                      (formData?.loan_amount_from /
                                        assetData?.parcel_total_price) *
                                      assetData?.minimun_investment_per_parcel
                                    ).toFixed(2)
                                    : e.target.value,
                              })
                            }
                            onKeyDown={(event) => {
                              if (event?.key === "-" || event?.key === "+") {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Box>
                        <Box className="dic-NS-sale-details-icon">
                          {CATEGORY_TYPE.ART &&
                            listingName === INVESTMENT_TYPE.PARCEL ? (
                            true
                          ) : !forSuggestProjectDetail ? null : (
                            <IconButton
                              disabled={
                                CATEGORY_TYPE.ART &&
                                  listingName === INVESTMENT_TYPE.PARCEL
                                  ? true
                                  : !forSuggestProjectDetail
                              }
                              onClick={() => {
                                handleIncrementDecrement(
                                  "loan_amount_to",
                                  500,
                                  "inc"
                                );
                              }}
                              className="dic-NS-sale-details-icon-btn"
                            >
                              <Add />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                      {formData.loan_amount_to === "" ? (
                        <Typography
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "-14px",
                          }}
                        >
                          Please provide a value
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Box>
              )}
            {errorEstimatedValueWS ? (
              <Typography
                sx={{
                  color: "red",
                  fontSize: "11px",
                  textAlign: "center",
                  marginTop: -2,
                  marginBottom: 1,
                }}
              >
                The sale price can not be greater than estimated price.
              </Typography>
            ) : (
              <Typography
                sx={{
                  color: "red",
                  fontSize: "11px",
                  textAlign: "center",
                  marginTop: -2,
                  marginBottom: 1,
                }}
              >
                {errorFromTo.status1 && errorFromTo.key1 === "amount"
                  ? errorFromTo.Message1
                  : errorEstimatedValue
                    ? "The 'To' loan amount can not be greater than estimated value."
                    : ""}
              </Typography>
            )}

            {/* code for watch and gold only */}
            {((categoryName === CATEGORY_TYPE.WATCH &&
              assetData?.investment_type?.investment_name ===
              INVESTMENT_TYPE.LOAN) ||
              categoryName === CATEGORY_TYPE.GOLD ||
              categoryName === CATEGORY_TYPE.ASSET) && (
                <Box className="loan-content">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography className="font-16">
                        Range of Loan Duration (Months)
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Box className="dic-NS-sale-details dic-fitSize" my={1}>
                            <Box className="dic-NS-sale-details-icon">
                              {!forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "loan_duration_from",
                                      1,
                                      "dec"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Remove />
                                </IconButton>
                              )}
                            </Box>
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                defaultValue={1}
                                className="loan-text"
                                label={
                                  categoryName === CATEGORY_TYPE.ART ? "" : "From"
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  style: {
                                    marginLeft: !forSuggestProjectDetail
                                      ? "35%"
                                      : "10px",
                                  },
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                  readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                                  style: {
                                    marginTop: "4px",
                                  },
                                }}
                                value={formData.loan_duration_from}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    loan_duration_from: e.target.value,
                                  })
                                }
                                onKeyDown={(event) => {
                                  if (
                                    event?.key === "-" ||
                                    event?.key === "+" ||
                                    event?.key === "."
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                            <Box className="dic-NS-sale-details-icon">
                              {!forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "loan_duration_from",
                                      1,
                                      "inc"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Add />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                          {formData.loan_duration_from === "" && (
                            <Typography
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "-14px",
                              }}
                            >
                              Please provide a value
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Box className="dic-NS-sale-details dic-fitSize" my={1}>
                            <Box className="dic-NS-sale-details-icon">
                              {!forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "loan_duration_to",
                                      1,
                                      "dec"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Remove />
                                </IconButton>
                              )}
                            </Box>
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                defaultValue={30}
                                className="loan-text"
                                label={
                                  categoryName === CATEGORY_TYPE.ART ? "" : "To"
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  style: {
                                    marginLeft: !forSuggestProjectDetail
                                      ? "35%"
                                      : "12px",
                                  },
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                  readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                                  style: {
                                    marginTop: "4px",
                                  },
                                }}
                                value={formData.loan_duration_to}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    loan_duration_to: e.target.value,
                                  })
                                }
                                onKeyDown={(event) => {
                                  if (
                                    event?.key === "-" ||
                                    event?.key === "+" ||
                                    event?.key === "."
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                            <Box className="dic-NS-sale-details-icon">
                              {!forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "loan_duration_to",
                                      1,
                                      "inc"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Add />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                          {formData.loan_duration_to === "" && (
                            <Typography
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "-14px",
                              }}
                            >
                              Please provide a value
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      {
                        <Typography
                          style={{
                            color: "red",
                            fontSize: "12px",
                            textAlign: "center",
                            marginTop: -2,
                            marginBottom: 1,
                          }}
                        >
                          {errorFromTo.status2 && errorFromTo.key2 === "duration"
                            ? errorFromTo.Message2
                            : ""}
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography className="font-16">
                        Range of Interest Rate
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Box className="dic-NS-sale-details dic-fitSize" my={1}>
                            <Box
                              className="dic-NS-sale-details-icon"
                            // width={"30px"}
                            // height={"30px"}
                            >
                              {!forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "loan_roi_from",
                                      0.25,
                                      "dec"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Remove />
                                </IconButton>
                              )}
                            </Box>
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                defaultValue={1}
                                className="loan-text"
                                label={
                                  categoryName === CATEGORY_TYPE.ART ? "" : "From"
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  style: {
                                    marginLeft: !forSuggestProjectDetail
                                      ? "35%"
                                      : "11px",
                                  },
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustomPercent,
                                  readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                                  style: {
                                    marginTop: "4px",
                                  },
                                }}
                                value={formData.loan_roi_from}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    loan_roi_from: e.target.value,
                                  })
                                }
                                onKeyDown={(event) => {
                                  if (event?.key === "-" || event?.key === "+") {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                            <Box
                              className="dic-NS-sale-details-icon"
                            // width={"30px"}
                            // height={"30px"}
                            >
                              {!forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "loan_roi_from",
                                      0.25,
                                      "inc"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Add />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                          {formData.loan_roi_from === "" && (
                            <Typography
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "-14px",
                              }}
                            >
                              Please provide a value
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Box className="dic-NS-sale-details dic-fitSize" my={1}>
                            <Box className="dic-NS-sale-details-icon">
                              {!forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "loan_roi_to",
                                      0.25,
                                      "dec"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Remove />
                                </IconButton>
                              )}
                            </Box>
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                defaultValue={20}
                                className="loan-text"
                                label={
                                  categoryName === CATEGORY_TYPE.ART ? "" : "To"
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  style: {
                                    marginLeft: !forSuggestProjectDetail
                                      ? "35%"
                                      : "16px",
                                  },
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustomPercent,
                                  readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                                  style: {
                                    marginTop: "4px",
                                  },
                                }}
                                value={formData.loan_roi_to}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    loan_roi_to: e.target.value,
                                  })
                                }
                                onKeyDown={(event) => {
                                  if (event?.key === "-" || event?.key === "+") {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                            <Box className="dic-NS-sale-details-icon">
                              {!forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "loan_roi_to",
                                      0.25,
                                      "inc"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Add />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                          {formData.loan_roi_to === "" && (
                            <Typography
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "-14px",
                              }}
                            >
                              Please provide a value
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      {(categoryName !== CATEGORY_TYPE.WATCH ||
                        categoryName !== CATEGORY_TYPE.GOLD ||
                        categoryName === CATEGORY_TYPE.ASSET) && (
                          <Typography
                            style={{
                              color: "red",
                              fontSize: "12px",
                              textAlign: "center",
                              marginTop: -2,
                              marginBottom: 1,
                            }}
                          >
                            {errorFromTo.status3 && errorFromTo.key3 === "interest"
                              ? errorFromTo.Message3
                              : ""}
                          </Typography>
                        )}
                    </Grid>
                  </Grid>
                </Box>
              )}

            {/* Adding New Field For the Watch , Gold And Asset  */}
            {((categoryName === CATEGORY_TYPE.WATCH &&
              assetData?.investment_type?.investment_name ===
              INVESTMENT_TYPE.LOAN) ||
              categoryName === CATEGORY_TYPE.GOLD ||
              categoryName === CATEGORY_TYPE.ASSET) && (
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography className="font-16">
                        Interest Only Period
                      </Typography>

                      <Box className="dic-NS-sale-details dic-fitSize" my={1}>
                        <Box className="dic-NS-sale-details-icon">
                          {!forSuggestProjectDetail ? null : (
                            <IconButton
                              disabled={!forSuggestProjectDetail}
                              onClick={() => {
                                handleIncrementDecrement(
                                  "loan_iop",
                                  1,
                                  "dec"
                                );
                              }}
                              className="dic-NS-sale-details-icon-btn"
                            >
                              <Remove />
                            </IconButton>
                          )}
                        </Box>
                        <Box>
                          <TextField
                            id="outlined-number"
                            type="text"
                            defaultValue={1}
                            className="loan-text"
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                marginLeft: "10px",
                              },
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                              readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                              style: {
                                marginTop: "4px",
                              },
                            }}
                            value={formData.loan_iop}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                loan_iop: e.target.value,
                              })
                            }
                            onKeyDown={(event) => {
                              if (
                                event?.key === "-" ||
                                event?.key === "+" ||
                                event?.key === "."
                              ) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Box>
                        <Box className="dic-NS-sale-details-icon">
                          {!forSuggestProjectDetail ? null : (
                            <IconButton
                              disabled={!forSuggestProjectDetail}
                              onClick={() => {
                                handleIncrementDecrement(
                                  "loan_iop",
                                  1,
                                  "inc"
                                );
                              }}
                              className="dic-NS-sale-details-icon-btn"
                            >
                              <Add />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                      {formData.loan_iop === "" && (
                        <Typography
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "-14px",
                          }}
                        >
                          Please provide a value
                        </Typography>
                      )}

                      {
                        <Typography
                          style={{
                            color: "red",
                            fontSize: "12px",
                            textAlign: "center",
                            marginTop: -2,
                            marginBottom: 1,
                          }}
                        >
                          {iopError
                            ? "Interest only period must be lesser than 'From' loan duration"
                            : ""}
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography className="font-16">
                        Minimum Investment
                      </Typography>

                      <Box className="dic-NS-sale-details dic-fitSize" my={1}>
                        <Box className="dic-NS-sale-details-icon">
                          {!forSuggestProjectDetail ? null : (
                            <IconButton
                              disabled={!forSuggestProjectDetail}
                              onClick={() => {
                                handleIncrementDecrement(
                                  "loan_minimum_investment",
                                  500,
                                  "dec"
                                );
                              }}
                              className="dic-NS-sale-details-icon-btn"
                            >
                              <Remove />
                            </IconButton>
                          )}
                        </Box>
                        <Box>
                          <TextField
                            id="outlined-number"
                            type="text"
                            defaultValue={1}
                            className="loan-text"
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                marginLeft: "10px",
                              },
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustomDollar,
                              readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                              style: {
                                marginTop: "4px",
                              },
                            }}
                            value={formData.loan_minimum_investment}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                loan_minimum_investment: e.target.value,
                              })
                            }
                            onKeyDown={(event) => {
                              if (
                                event?.key === "-" ||
                                event?.key === "+"
                                // event?.key === "."
                              ) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Box>
                        <Box className="dic-NS-sale-details-icon">
                          {!forSuggestProjectDetail ? null : (
                            <IconButton
                              disabled={!forSuggestProjectDetail}
                              onClick={() => {
                                handleIncrementDecrement(
                                  "loan_minimum_investment",
                                  500,
                                  "inc"
                                );
                              }}
                              className="dic-NS-sale-details-icon-btn"
                            >
                              <Add />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                      {formData.loan_minimum_investment === "" && (
                        <Typography
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "-14px",
                          }}
                        >
                          Please provide a value
                        </Typography>
                      )}

                      {
                        <Typography
                          style={{
                            color: "red",
                            fontSize: "12px",
                            textAlign: "center",
                            marginTop: -2,
                            marginBottom: 1,
                          }}
                        >
                          {errorMinimumInvestmentL
                            ? "Minimum investment can not be greater than the loan amount"
                            : null}
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography className="font-16">Payment Date</Typography>

                      <Box className="dic-NS-sale-details dic-fitSize" my={1}>
                        <Box className="dic-NS-sale-details-icon">
                          {!forSuggestProjectDetail ? null : (
                            <IconButton
                              disabled={!forSuggestProjectDetail}
                              onClick={() => {
                                handleIncrementDecrement(
                                  "loan_repay_day",
                                  1,
                                  "dec"
                                );
                              }}
                              className="dic-NS-sale-details-icon-btn"
                            >
                              <Remove />
                            </IconButton>
                          )}
                        </Box>
                        <Box>
                          <TextField
                            id="outlined-number"
                            type="text"
                            defaultValue={1}
                            className="loan-text"
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                marginLeft: "11px",
                              },
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                              readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                              style: {
                                marginTop: "4px",
                              },
                            }}
                            value={formData.loan_repay_day}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                loan_repay_day: e.target.value,
                              })
                            }
                            onKeyDown={(event) => {
                              if (
                                event?.key === "-" ||
                                event?.key === "+" ||
                                event?.key === "."
                              ) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Box>
                        <Box
                          className="dic-NS-sale-details-icon"
                        // width={"30px"}
                        // height={"30px"}
                        >
                          {!forSuggestProjectDetail ? null : (
                            <IconButton
                              disabled={!forSuggestProjectDetail}
                              onClick={() => {
                                handleIncrementDecrement(
                                  "loan_repay_day",
                                  1,
                                  "inc"
                                );
                              }}
                              className="dic-NS-sale-details-icon-btn"
                            >
                              <Add />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                      {formData.loan_repay_day === "" && (
                        <Typography
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "-14px",
                          }}
                        >
                          Please provide a value
                        </Typography>
                      )}

                      {(categoryName !== CATEGORY_TYPE.WATCH ||
                        categoryName === CATEGORY_TYPE.ASSET ||
                        categoryName !== CATEGORY_TYPE.GOLD) && (
                          <Typography
                            style={{
                              color: "red",
                              fontSize: "12px",
                              textAlign: "center",
                              marginTop: -2,
                              marginBottom: 1,
                            }}
                          >
                            {repayDayError
                              ? "Payment dates should be between the 1st and the 28th."
                              : ""}
                          </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography className="font-16">
                        Withdrawal Threshold
                      </Typography>

                      <Box className="dic-NS-sale-details dic-fitSize" my={1}>
                        <Box className="dic-NS-sale-details-icon">
                          {!forSuggestProjectDetail ? null : (
                            <IconButton
                              disabled={!forSuggestProjectDetail}
                              onClick={() => {
                                handleIncrementDecrement(
                                  "claim_percentage",
                                  1,
                                  "dec"
                                );
                              }}
                              className="dic-NS-sale-details-icon-btn"
                            >
                              <Remove />
                            </IconButton>
                          )}
                        </Box>
                        <Box>
                          <TextField
                            id="outlined-number"
                            type="text"
                            defaultValue={1}
                            className="loan-text"
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                marginLeft: "11px",
                              },
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustomPercent,
                              readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                              style: {
                                marginTop: "4px",
                              },
                            }}
                            value={formData.claim_percentage}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                claim_percentage: e.target.value,
                              })
                            }
                            onKeyDown={(event) => {
                              if (event?.key === "-" || event?.key === "+") {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Box>
                        <Box
                          className="dic-NS-sale-details-icon"
                        // width={"30px"}
                        // height={"30px"}
                        >
                          {!forSuggestProjectDetail ? null : (
                            <IconButton
                              disabled={!forSuggestProjectDetail}
                              onClick={() => {
                                handleIncrementDecrement(
                                  "claim_percentage",
                                  1,
                                  "inc"
                                );
                              }}
                              className="dic-NS-sale-details-icon-btn"
                            >
                              <Add />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                      {formData.claim_percentage === "" ? (
                        <Typography
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "-14px",
                          }}
                        >
                          Please provide a value
                        </Typography>
                      ) : (
                        claimPercentError && (
                          <Typography
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: "-14px",
                            }}
                          >
                            Withdrawal Threshold can not be greater than 100
                          </Typography>
                        )
                      )}

                    </Grid>
                  </Grid>
                </Box>
              )}

            {assetData?.investment_type?.investment_name ===
              INVESTMENT_TYPE.PARCEL ?
              <>
                <Grid container spacing={1}>
                  <Grid item md={4}>
                    <Typography
                      className={"assetReviewModal-field-title"}
                      mt={1}
                      mb={-1}
                    >
                      Withdrawal Threshold
                    </Typography>
                    <Box
                      className="dic-NS-sale-details dic-fitSize"
                      mb={1}
                    >
                      <Box className="dic-NS-sale-details-icon">
                        {!forSuggestProjectDetail ? null : (
                          <IconButton
                            disabled={!forSuggestProjectDetail}
                            onClick={() => {
                              handleIncrementDecrement(
                                "claim_percentage",
                                1,
                                "dec"
                              );
                            }}
                            className="dic-NS-sale-details-icon-btn"
                          >
                            <Remove />
                          </IconButton>
                        )}
                      </Box>
                      <Box>
                        <TextField
                          id="outlined-number"
                          type="text"
                          defaultValue={1}
                          className="loan-text"
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              marginLeft: "11px",
                            },
                          }}
                          InputProps={{
                            inputComponent:
                              NumericFormatCustomPercent,
                            readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                            style: {
                              marginTop: "4px",
                            },
                          }}
                          value={formData.claim_percentage}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              claim_percentage: e.target.value,
                            })
                          }
                          onKeyDown={(event) => {
                            if (
                              event?.key === "-" ||
                              event?.key === "+"
                            ) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Box>
                      <Box
                        className="dic-NS-sale-details-icon"
                      // width={"30px"}
                      // height={"30px"}
                      >
                        {!forSuggestProjectDetail ? null : (
                          <IconButton
                            disabled={!forSuggestProjectDetail}
                            onClick={() => {
                              handleIncrementDecrement(
                                "claim_percentage",
                                1,
                                "inc"
                              );
                            }}
                            className="dic-NS-sale-details-icon-btn"
                          >
                            <Add />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                    {formData.claim_percentage === "" ? (
                      <Typography
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "-14px",
                        }}
                      >
                        Please provide a value
                      </Typography>
                    ) : (
                      claimPercentError && (
                        <Typography
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "-14px",
                          }}
                        >
                          Withdrawal Threshold can not be greater than 100
                        </Typography>
                      )
                    )}
                  </Grid>
                </Grid>
              </>
              : null}

            {/* Code for funds fraction */}
            <Box>
              {categoryName === CATEGORY_TYPE.FUNDS && (
                <>
                  <Box>
                    <Box className="loan-content">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography sx={{ color: "#94A3B8", fontSize: "14px" }}>
                            Total Raise
                          </Typography>
                          <Box className="dic-NS-sale-details" my={1}>
                            <Box className="dic-NS-sale-details-icon">
                              {!updateNewReviewData ||
                                fromVoting ||
                                !forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "fractionalize_total_price",
                                      500,
                                      "dec"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Remove />
                                </IconButton>
                              )}
                            </Box>
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                defaultValue={0}
                                className="loan-text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustomDollar,
                                  readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                                }}
                                name="fractionalize_total_price"
                                value={formData.fractionalize_total_price}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    fractionalize_total_price: e.target.value,
                                  })
                                }
                                onKeyDown={(event) => {
                                  if (
                                    event?.key === "-" ||
                                    event?.key === "+"
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                            <Box className="dic-NS-sale-details-icon">
                              {!updateNewReviewData ||
                                fromVoting ||
                                !forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "fractionalize_total_price",
                                      500,
                                      "inc"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Add />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                          {formData.fractionalize_total_price === "" ||
                            parseFloat(formData.fractionalize_total_price) === 0 ? (
                            <Typography
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "-14px",
                              }}
                            >
                              {formData.fractionalize_total_price === ""
                                ? "Please provide a value"
                                : "Total Raise can not be zero"}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            sx={{ color: "#94A3B8", fontSize: "14px" }}
                          >
                            {assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? "Initial Project Term" : "Project Duration"}({
                              "Years"})
                          </Typography>
                          <Box className="dic-NS-sale-details" my={1}>
                            <Box className="dic-NS-sale-details-icon">
                              {!updateNewReviewData ||
                                fromVoting ||
                                !forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "fractionalize_duration_of_project",
                                      1,
                                      "dec"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Remove />
                                </IconButton>
                              )}
                            </Box>
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                defaultValue={0}
                                className="loan-text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                  readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                                }}
                                name="fractionalize_duration_of_project"
                                value={
                                  formData.fractionalize_duration_of_project
                                }
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    fractionalize_duration_of_project:
                                      e.target.value,
                                  })
                                }
                                onKeyDown={(event) => {
                                  if (
                                    event?.key === "-" ||
                                    event?.key === "+" ||
                                    event?.key === "."
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                            <Box className="dic-NS-sale-details-icon">
                              {!updateNewReviewData ||
                                fromVoting ||
                                !forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "fractionalize_duration_of_project",
                                      1,
                                      "inc"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Add />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                          {formData.fractionalize_duration_of_project === "" ||
                            parseFloat(formData.fractionalize_duration_of_project) === 0 ? (
                            <Typography
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "-14px",
                              }}
                            >
                              {formData.fractionalize_duration_of_project === ""
                                ? "Please provide a value"
                                : "Initial Project Term can not be zero"}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            sx={{ color: "#94A3B8", fontSize: "14px" }}
                          >
                            Token Lock Period (Days)
                          </Typography>
                          <Box className="dic-NS-sale-details" my={1}>
                            <Box className="dic-NS-sale-details-icon">
                              {!updateNewReviewData ||
                                fromVoting ||
                                !forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "token_lock_period",
                                      1,
                                      "dec"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Remove />
                                </IconButton>
                              )}
                            </Box>
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                defaultValue={0}
                                className="loan-text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                  readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                                }}
                                name="token_lock_period"
                                value={
                                  formData.token_lock_period
                                }
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    token_lock_period:
                                      e.target.value,
                                  })
                                }
                                onKeyDown={(event) => {
                                  if (
                                    event?.key === "-" ||
                                    event?.key === "+" ||
                                    event?.key === "."
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                            <Box className="dic-NS-sale-details-icon">
                              {!updateNewReviewData ||
                                fromVoting ||
                                !forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "token_lock_period",
                                      1,
                                      "inc"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Add />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                          {formData.token_lock_period === ""
                            // ||formData.token_lock_period === 0 
                            ? (
                              <Typography
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  marginTop: "-14px",
                                }}
                              >
                                {formData.token_lock_period === ""
                                  ? "Please provide a value"
                                  // : "Token Lock Period can not be zero"
                                  : ''
                                }
                              </Typography>
                            ) : (
                              ""
                            )}
                        </Grid> */}
                        {assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ?
                          <>
                            <Grid item xs={12} sm={6} md={6}>
                              <Typography
                                sx={{ color: "#94A3B8", fontSize: "14px" }}
                              >
                                Fund Raising Period (Days)
                              </Typography>
                              <Box className="dic-NS-sale-details" my={1}>
                                <Box className="dic-NS-sale-details-icon">
                                  {!updateNewReviewData ||
                                    fromVoting ||
                                    !forSuggestProjectDetail ? null : (
                                    <IconButton
                                      disabled={!forSuggestProjectDetail}
                                      onClick={() => {
                                        handleIncrementDecrement(
                                          "investment_duration",
                                          1,
                                          "dec"
                                        );
                                      }}
                                      className="dic-NS-sale-details-icon-btn"
                                    >
                                      <Remove />
                                    </IconButton>
                                  )}
                                </Box>
                                <Box>
                                  <TextField
                                    id="outlined-number"
                                    type="text"
                                    defaultValue={0}
                                    className="loan-text"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    InputProps={{
                                      inputComponent: NumericFormatCustom,
                                      readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                                    }}
                                    name="investment_duration"
                                    value={
                                      formData.investment_duration
                                    }
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        investment_duration:
                                          e.target.value,
                                      })
                                    }
                                    onKeyDown={(event) => {
                                      if (
                                        event?.key === "-" ||
                                        event?.key === "+" ||
                                        event?.key === "."
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Box>
                                <Box className="dic-NS-sale-details-icon">
                                  {!updateNewReviewData ||
                                    fromVoting ||
                                    !forSuggestProjectDetail ? null : (
                                    <IconButton
                                      disabled={!forSuggestProjectDetail}
                                      onClick={() => {
                                        handleIncrementDecrement(
                                          "investment_duration",
                                          1,
                                          "inc"
                                        );
                                      }}
                                      className="dic-NS-sale-details-icon-btn"
                                    >
                                      <Add />
                                    </IconButton>
                                  )}
                                </Box>
                              </Box>
                              {formData.investment_duration === "" ||
                                parseFloat(formData.investment_duration) === 0 ? (
                                <Typography
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marginTop: "-14px",
                                  }}
                                >
                                  {formData.investment_duration === ""
                                    ? "Please provide a value"
                                    : "Fund Raising Period can not be zero"}
                                </Typography>
                              ) : (
                                ""
                              )}
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                              <Typography sx={{ color: "#94A3B8", fontSize: "14px" }}>
                                Minimum Investment
                              </Typography>
                              <Box className="dic-NS-sale-details" my={1}>
                                <Box className="dic-NS-sale-details-icon">
                                  {!updateNewReviewData || fromVoting || !forSuggestProjectDetail ? null : (
                                    <IconButton
                                      disabled={!forSuggestProjectDetail}
                                      onClick={() => {
                                        handleIncrementDecrement("fractionalize_minimum_investment", 500, "dec");
                                      }}
                                      className="dic-NS-sale-details-icon-btn"
                                    >
                                      <Remove />
                                    </IconButton>
                                  )}
                                </Box>
                                <Box>
                                  <TextField
                                    id="outlined-number"
                                    type="text"
                                    defaultValue={0}
                                    className="loan-text"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    InputProps={{
                                      inputComponent: NumericFormatCustomDollar,
                                      readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                                    }}
                                    name="fractionalize_minimum_investment"
                                    value={formData.fractionalize_minimum_investment}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        fractionalize_minimum_investment: e.target.value,
                                      })
                                    }
                                    onKeyDown={(event) => {
                                      if (
                                        event?.key === "-" ||
                                        event?.key === "+"
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Box>
                                <Box className="dic-NS-sale-details-icon">
                                  {!updateNewReviewData ||
                                    fromVoting ||
                                    !forSuggestProjectDetail ? null : (
                                    <IconButton
                                      disabled={!forSuggestProjectDetail}
                                      onClick={() => {
                                        handleIncrementDecrement("fractionalize_minimum_investment", 500, "inc");
                                      }}
                                      className="dic-NS-sale-details-icon-btn"
                                    >
                                      <Add />
                                    </IconButton>
                                  )}
                                </Box>
                              </Box>
                              {formData.fractionalize_minimum_investment === "" ||
                                parseFloat(formData.fractionalize_minimum_investment) === 0 || parseFloat(formData?.fractionalize_total_price) < (parseFloat(formData?.fractionalize_minimum_investment) + parseFloat(formData?.fractionalize_total_price) * parseFloat(formData?.management_fees / 100)) || parseFloat(formData?.fractionalize_total_price) <
                                parseFloat(formData?.fractionalize_minimum_investment) ? (
                                <Typography style={{ color: "red", fontSize: "12px", marginTop: "-14px", }}>
                                  {formData.fractionalize_minimum_investment === ""
                                    ? "Please provide a value"
                                    : spaceXMinError ? "Minimum investment + Management fees must not exceed total raise." :
                                      (parseFloat(formData?.fractionalize_total_price) <
                                        parseFloat(formData?.fractionalize_minimum_investment) ? "Minimum Investment can not be greater than total raise amount"
                                        : "Minimum investment can not be zero")}
                                </Typography>) : ("")}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Typography sx={{ color: "#94A3B8", fontSize: "14px" }}>
                                Management Fees
                              </Typography>
                              <Grid container spacing={1}>
                                <Grid item md={12}>
                                  <Box
                                    className="dic-NS-sale-details dic-fitSize"
                                    my={1}
                                  >
                                    <Box className="dic-NS-sale-details-icon">
                                      {!forSuggestProjectDetail ? null : (
                                        <IconButton
                                          disabled={!forSuggestProjectDetail}
                                          onClick={() => { handleIncrementDecrement("management_fees", 1, "dec"); }}
                                          className="dic-NS-sale-details-icon-btn"
                                        >
                                          <Remove />
                                        </IconButton>
                                      )}
                                    </Box>
                                    <Box>
                                      <TextField
                                        id="outlined-number"
                                        type="text"
                                        defaultValue={1}
                                        className="loan-text"
                                        InputLabelProps={{
                                          shrink: true,
                                          style: {
                                            marginLeft: "11px",
                                          },
                                        }}
                                        InputProps={{
                                          inputComponent:
                                            NumericFormatCustomPercent,
                                          readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                                          style: {
                                            marginTop: "4px",
                                          },
                                        }}
                                        value={formData.management_fees}
                                        onChange={(e) => setFormData({ ...formData, management_fees: e.target.value, })}
                                        onKeyDown={(event) => {
                                          if (event?.key === "-" || event?.key === "+") {
                                            event.preventDefault();
                                          }
                                        }}
                                      />
                                    </Box>
                                    <Box
                                      className="dic-NS-sale-details-icon"
                                    // width={"30px"}
                                    // height={"30px"}
                                    >
                                      {!forSuggestProjectDetail ? null : (
                                        <IconButton
                                          disabled={!forSuggestProjectDetail}
                                          onClick={() => {
                                            handleIncrementDecrement(
                                              "management_fees",
                                              1,
                                              "inc"
                                            );
                                          }}
                                          className="dic-NS-sale-details-icon-btn"
                                        >
                                          <Add />
                                        </IconButton>
                                      )}
                                    </Box>
                                  </Box>
                                  {formData.management_fees === "" ? (
                                    <Typography
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                        marginTop: "-14px",
                                      }}
                                    >
                                      Please provide a value
                                    </Typography>
                                  ) : (
                                    manageFeesError && (
                                      <Typography
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                          marginTop: "-14px",
                                        }}
                                      >
                                        Management Fees can not be greater than 100
                                      </Typography>
                                    )
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Typography sx={{ color: "#94A3B8", fontSize: "14px" }}>
                                Carried Interest
                              </Typography>
                              <Grid container spacing={1}>
                                <Grid item md={12}>
                                  <Box
                                    className="dic-NS-sale-details dic-fitSize"
                                    my={1}
                                  >
                                    <Box className="dic-NS-sale-details-icon">
                                      {!forSuggestProjectDetail ? null : (
                                        <IconButton
                                          disabled={!forSuggestProjectDetail}
                                          onClick={() => { handleIncrementDecrement("carried_interest", 1, "dec"); }}
                                          className="dic-NS-sale-details-icon-btn"
                                        >
                                          <Remove />
                                        </IconButton>
                                      )}
                                    </Box>
                                    <Box>
                                      <TextField
                                        id="outlined-number"
                                        type="text"
                                        defaultValue={1}
                                        className="loan-text"
                                        InputLabelProps={{
                                          shrink: true,
                                          style: {
                                            marginLeft: "11px",
                                          },
                                        }}
                                        InputProps={{
                                          inputComponent:
                                            NumericFormatCustomPercent,
                                          readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                                          style: {
                                            marginTop: "4px",
                                          },
                                        }}
                                        value={formData.carried_interest}
                                        onChange={(e) => setFormData({ ...formData, carried_interest: e.target.value, })}
                                        onKeyDown={(event) => {
                                          if (event?.key === "-" || event?.key === "+") {
                                            event.preventDefault();
                                          }
                                        }}
                                      />
                                    </Box>
                                    <Box
                                      className="dic-NS-sale-details-icon"
                                    // width={"30px"}
                                    // height={"30px"}
                                    >
                                      {!forSuggestProjectDetail ? null : (
                                        <IconButton
                                          disabled={!forSuggestProjectDetail}
                                          onClick={() => {
                                            handleIncrementDecrement(
                                              "carried_interest",
                                              1,
                                              "inc"
                                            );
                                          }}
                                          className="dic-NS-sale-details-icon-btn"
                                        >
                                          <Add />
                                        </IconButton>
                                      )}
                                    </Box>
                                  </Box>
                                  {formData.carried_interest === "" ? (
                                    <Typography
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                        marginTop: "-14px",
                                      }}
                                    >
                                      Please provide a value
                                    </Typography>
                                  ) : (
                                    carriedIntError && (
                                      <Typography
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                          marginTop: "-14px",
                                        }}
                                      >
                                        Carried Interest can not be greater than 100
                                      </Typography>
                                    )
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                          : null}

                        <Grid item xs={12} sm={6} md={6}>
                          <Typography sx={{ color: "#94A3B8", fontSize: "14px" }}>
                            {"Withdrawal Threshold"}
                          </Typography>
                          <Grid container spacing={1}>
                            <Grid item md={12}>
                              <Box
                                className="dic-NS-sale-details dic-fitSize"
                                my={1}
                              >
                                <Box className="dic-NS-sale-details-icon">
                                  {!forSuggestProjectDetail ? null : (
                                    <IconButton
                                      disabled={!forSuggestProjectDetail}
                                      onClick={() => { handleIncrementDecrement("claim_percentage", 1, "dec"); }}
                                      className="dic-NS-sale-details-icon-btn"
                                    >
                                      <Remove />
                                    </IconButton>
                                  )}
                                </Box>
                                <Box>
                                  <TextField
                                    id="outlined-number"
                                    type="text"
                                    defaultValue={1}
                                    className="loan-text"
                                    InputLabelProps={{
                                      shrink: true,
                                      style: {
                                        marginLeft: "11px",
                                      },
                                    }}
                                    InputProps={{
                                      inputComponent:
                                        NumericFormatCustomPercent,
                                      readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                                      style: {
                                        marginTop: "4px",
                                      },
                                    }}
                                    value={formData.claim_percentage}
                                    onChange={(e) => setFormData({ ...formData, claim_percentage: e.target.value, })}
                                    onKeyDown={(event) => {
                                      if (event?.key === "-" || event?.key === "+") {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Box>
                                <Box
                                  className="dic-NS-sale-details-icon"
                                // width={"30px"}
                                // height={"30px"}
                                >
                                  {!forSuggestProjectDetail ? null : (
                                    <IconButton
                                      disabled={!forSuggestProjectDetail}
                                      onClick={() => {
                                        handleIncrementDecrement(
                                          "claim_percentage",
                                          1,
                                          "inc"
                                        );
                                      }}
                                      className="dic-NS-sale-details-icon-btn"
                                    >
                                      <Add />
                                    </IconButton>
                                  )}
                                </Box>
                              </Box>
                              {formData.claim_percentage === "" ? (
                                <Typography
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marginTop: "-14px",
                                  }}
                                >
                                  Please provide a value
                                </Typography>
                              ) : (
                                claimPercentError && (
                                  <Typography
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                      marginTop: "-14px",
                                    }}
                                  >
                                    Withdrawal Threshold can not be greater than 100
                                  </Typography>
                                )
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    {assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? null :
                      <Box className="loan-content">
                        <Grid container spacing={2}>
                          {assetData?.number_of_shared > 0 &&
                            (() => {
                              const deals = [];
                              let ArrLength = parseInt(
                                assetData?.number_of_shared
                              );
                              for (
                                let i = 0;
                                i < (ArrLength > 100 ? 100 : ArrLength);
                                i++
                              ) {
                                deals.push(
                                  <Grid
                                    item
                                    md={parseInt(assetData?.number_of_shared) === 1 ? 12 : 6}
                                  >
                                    <Typography
                                      className="font-16"
                                      sx={{ marginBottom: "24px" }}
                                    >
                                      Shared class {i + 1}
                                    </Typography>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} md={6}>
                                        <TextField
                                          disabled={!forSuggestProjectDetail}
                                          id="outlined-number"
                                          type="text"
                                          label="Total Raise"
                                          className="loan-text"
                                          InputProps={{
                                            inputComponent:
                                              NumericFormatCustomDollar,
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                            style: { color: "white" },
                                          }}
                                          inputProps={{
                                            style: {
                                              color: "white",
                                            },
                                          }}
                                          value={
                                            formData?.shared_data[i]
                                              ?.total_raise
                                          }
                                          onChange={(e) =>
                                            handleChangeSharedData(
                                              e,
                                              "total_raise",
                                              i
                                            )
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={6}>
                                        <TextField
                                          disabled={!forSuggestProjectDetail}
                                          id="outlined-number"
                                          type="text"
                                          label="Minimum Investment"
                                          className="loan-text"
                                          InputProps={{
                                            inputComponent:
                                              NumericFormatCustomDollar,
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                            style: { color: "white" },
                                          }}
                                          inputProps={{
                                            style: {
                                              color: "white",
                                            },
                                          }}
                                          value={
                                            formData?.shared_data[i]
                                              ?.minimum_investment
                                          }
                                          onChange={(e) =>
                                            handleChangeSharedData(
                                              e,
                                              "minimum_investment",
                                              i
                                            )
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={6}>
                                        <TextField
                                          disabled={!forSuggestProjectDetail}
                                          // disabled={true}
                                          id="outlined-number"
                                          type="text"
                                          label="Hurdle Rate"
                                          className="loan-text"
                                          InputProps={{
                                            inputComponent:
                                              NumericFormatCustomPercent,
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                            style: { color: "white" },
                                          }}
                                          inputProps={{
                                            style: {
                                              color: "white",
                                            },
                                          }}
                                          value={
                                            formData?.shared_data[i]
                                              ?.rate_of_return
                                          }
                                          onChange={(e) =>
                                            handleChangeSharedData(
                                              e,
                                              "rate_of_return",
                                              i
                                            )
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={6}>
                                        <TextField
                                          disabled={!forSuggestProjectDetail}
                                          // disabled={true}
                                          id="outlined-number"
                                          type="text"
                                          label="LP Percentage"
                                          className="loan-text"
                                          InputProps={{
                                            inputComponent:
                                              NumericFormatCustomPercent,
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                            style: { color: "white" },
                                          }}
                                          inputProps={{
                                            style: {
                                              color: "white",
                                            },
                                          }}
                                          value={
                                            parseFloat(formData?.shared_data[i]
                                              ?.lp_percentage)
                                          }
                                          onChange={(e) =>
                                            handleChangeSharedData(
                                              e,
                                              "lp_percentage",
                                              i
                                            )
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                );
                              }
                              return deals;
                            })()}
                        </Grid>
                      </Box>
                    }
                  </Box>
                  {assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ?
                    <Typography
                      sx={{
                        color: "red",
                        fontSize: "11px",
                        textAlign: "center",
                        marginTop: 1,
                        marginBottom: 1,
                      }}
                    >
                      {errorCommon === true
                        ? "All fields are required." :
                        fundTokenLockPeriod ? "Token lock period can not be greater than project duration"
                          : spaceXDurationCheck ? 'Investment duration can not be greater than project duration.'
                            : Boolean(parseFloat(formData?.investment_duration * 365) < parseFloat(formData?.fractionalize_duration_of_project)) ? "Investment duration can not be greater than project duration." : ""}
                    </Typography>
                    :
                    <Typography
                      sx={{
                        color: "red",
                        fontSize: "11px",
                        textAlign: "center",
                        marginTop: 1,
                        marginBottom: 1,
                      }}
                    >
                      {errorCommon === true
                        ? "All fields are required." :
                        fundTokenLockPeriod ? "Token lock period can not be greater than project duration"
                          : errorTotalRaise === true
                            ? "The sum of all class raise amounts must be equal to total raise value."
                            : minimumInvestZero === true
                              ? "Minimum investment can not be zero"
                              : errorMinimumInvestment ?
                                'The minimum investment cannot exceed the total raise for each class.'
                                : errorRate
                                  ? "Hurdle rate cannot be greater than 100%"
                                  : errorLpRate
                                    ? "LP Percentage cannot be greater than 100%"
                                    : ""}
                    </Typography>
                  }
                </>
              )}
            </Box>

            {/* code for Real estate fraction */}
            <Box>
              {categoryName === CATEGORY_TYPE.REAL_ESTATE && (
                <>
                  <Box>
                    <Box className="loan-content">
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography
                            sx={{ color: "#94A3B8", fontSize: "14px" }}
                          >
                            Total Project Value
                          </Typography>
                          <Box className="dic-NS-sale-details" my={1}>
                            <Box className="dic-NS-sale-details-icon">
                              {!updateNewReviewData ||
                                fromVoting ||
                                !forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "fractionalize_project_size",
                                      500,
                                      "dec"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Remove />
                                </IconButton>
                              )}
                            </Box>
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                defaultValue={0}
                                className="loan-text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustomDollar,
                                  readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                                }}
                                name="fractionalize_project_size"
                                value={formData.fractionalize_project_size}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    fractionalize_project_size: e.target.value,
                                  })
                                }
                                onKeyDown={(event) => {
                                  if (
                                    event?.key === "-" ||
                                    event?.key === "+"
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                            <Box className="dic-NS-sale-details-icon">
                              {!updateNewReviewData ||
                                fromVoting ||
                                !forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "fractionalize_project_size",
                                      500,
                                      "inc"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Add />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                          {formData.fractionalize_project_size === "" ? (
                            <Typography
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "-14px",
                              }}
                            >
                              Please provide a value
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography
                            sx={{ color: "#94A3B8", fontSize: "14px" }}
                          >
                            Total Raise
                          </Typography>
                          <Box className="dic-NS-sale-details" my={1}>
                            <Box className="dic-NS-sale-details-icon">
                              {!updateNewReviewData ||
                                fromVoting ||
                                !forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "fractionalize_total_price",
                                      500,
                                      "dec"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Remove />
                                </IconButton>
                              )}
                            </Box>
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                defaultValue={0}
                                className="loan-text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustomDollar,
                                  readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                                }}
                                name="fractionalize_total_price"
                                value={formData.fractionalize_total_price}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    fractionalize_total_price: e.target.value,
                                  })
                                }
                                onKeyDown={(event) => {
                                  if (
                                    event?.key === "-" ||
                                    event?.key === "+"
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                            <Box className="dic-NS-sale-details-icon">
                              {!updateNewReviewData ||
                                fromVoting ||
                                !forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "fractionalize_total_price",
                                      500,
                                      "inc"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Add />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                          {(formData.fractionalize_total_price === "" ||
                            parseFloat(formData.fractionalize_total_price) === 0) ? (
                            <Typography
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "-14px",
                              }}
                            >
                              {formData.fractionalize_total_price === ""
                                ? "Please provide a value"
                                : "Total Raise can not be zero"}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography
                            sx={{ color: "#94A3B8", fontSize: "14px" }}
                          >
                            Project Duration (Years)
                          </Typography>
                          <Box className="dic-NS-sale-details" my={1}>
                            <Box className="dic-NS-sale-details-icon">
                              {!updateNewReviewData ||
                                fromVoting ||
                                !forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "fractionalize_duration_of_project",
                                      1,
                                      "dec"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Remove />
                                </IconButton>
                              )}
                            </Box>
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                defaultValue={0}
                                className="loan-text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                  readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                                }}
                                name="fractionalize_duration_of_project"
                                value={
                                  formData.fractionalize_duration_of_project
                                }
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    fractionalize_duration_of_project:
                                      e.target.value,
                                  })
                                }
                                onKeyDown={(event) => {
                                  if (
                                    event?.key === "-" ||
                                    event?.key === "+" ||
                                    event?.key === "."
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                            <Box className="dic-NS-sale-details-icon">
                              {!updateNewReviewData ||
                                fromVoting ||
                                !forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "fractionalize_duration_of_project",
                                      1,
                                      "inc"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Add />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                          {(formData.fractionalize_duration_of_project === "" ||
                            parseFloat(formData.fractionalize_duration_of_project) === 0) ? (
                            <Typography
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "-14px",
                              }}
                            >
                              {formData.fractionalize_duration_of_project === ""
                                ? "Please provide a value"
                                : "Project Duration can not be zero"}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Grid>
                        {isPaymentDate ? (
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography className="font-16">
                              Payment Date
                            </Typography>

                            <Box
                              className="dic-NS-sale-details dic-fitSize"
                              my={1}
                            >
                              <Box className="dic-NS-sale-details-icon">
                                {!updateNewReviewData ||
                                  fromVoting ||
                                  !forSuggestProjectDetail ? null : (
                                  <IconButton
                                    disabled={!forSuggestProjectDetail}
                                    onClick={() => {
                                      handleIncrementDecrement(
                                        "loan_repay_day",
                                        1,
                                        "dec"
                                      );
                                    }}
                                    className="dic-NS-sale-details-icon-btn"
                                  >
                                    <Remove />
                                  </IconButton>
                                )}
                              </Box>
                              <Box>
                                <TextField
                                  id="outlined-number"
                                  type="text"
                                  defaultValue={1}
                                  className="loan-text"
                                  InputLabelProps={{
                                    shrink: true,
                                    style: {
                                      marginLeft: "11px",
                                    },
                                  }}
                                  InputProps={{
                                    inputComponent: NumericFormatCustom,
                                    readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                                    style: {
                                      marginTop: "4px",
                                    },
                                  }}
                                  value={formData.loan_repay_day}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      loan_repay_day: e.target.value,
                                    })
                                  }
                                  onKeyDown={(event) => {
                                    if (
                                      event?.key === "-" ||
                                      event?.key === "+" ||
                                      event?.key === "."
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </Box>
                              <Box
                                className="dic-NS-sale-details-icon"
                              // width={"30px"}
                              // height={"30px"}
                              >
                                {!updateNewReviewData ||
                                  fromVoting ||
                                  !forSuggestProjectDetail ? null : (
                                  <IconButton
                                    disabled={!forSuggestProjectDetail}
                                    onClick={() => {
                                      handleIncrementDecrement(
                                        "loan_repay_day",
                                        1,
                                        "inc"
                                      );
                                    }}
                                    className="dic-NS-sale-details-icon-btn"
                                  >
                                    <Add />
                                  </IconButton>
                                )}
                              </Box>
                            </Box>
                            {formData.loan_repay_day === "" && (
                              <Typography
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  marginTop: "-14px",
                                }}
                              >
                                Please provide a value
                              </Typography>
                            )}

                            {(categoryName !== CATEGORY_TYPE.WATCH ||
                              categoryName === CATEGORY_TYPE.ASSET ||
                              categoryName !== CATEGORY_TYPE.GOLD) && (
                                <Typography
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    textAlign: "center",
                                    marginTop: -2,
                                    marginBottom: 1,
                                  }}
                                >
                                  {repayDayError
                                    ? "Payment dates should be between the 1st and the 28th."
                                    : ""}
                                </Typography>
                              )}
                          </Grid>
                        ) : null}
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography className="font-16">
                            Withdrawal Threshold
                          </Typography>

                          <Box
                            className="dic-NS-sale-details dic-fitSize"
                            my={1}

                          >
                            <Box className="dic-NS-sale-details-icon" width={'100%'}>
                              {!forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "claim_percentage",
                                      1,
                                      "dec"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Remove />
                                </IconButton>
                              )}
                            </Box>
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                defaultValue={1}
                                className="loan-text"
                                InputLabelProps={{
                                  shrink: true,
                                  style: {
                                    marginLeft: "11px",
                                  },
                                }}
                                InputProps={{
                                  inputComponent:
                                    NumericFormatCustomPercent,
                                  readOnly: !forSuggestProjectDetail, // conditionally displaying the values and edit functionality
                                  style: {
                                    marginTop: "4px",
                                  },
                                }}
                                value={formData.claim_percentage}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    claim_percentage: e.target.value,
                                  })
                                }
                                onKeyDown={(event) => {
                                  if (
                                    event?.key === "-" ||
                                    event?.key === "+"
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                            <Box
                              className="dic-NS-sale-details-icon"
                            // width={"30px"}
                            // height={"30px"}
                            >
                              {!forSuggestProjectDetail ? null : (
                                <IconButton
                                  disabled={!forSuggestProjectDetail}
                                  onClick={() => {
                                    handleIncrementDecrement(
                                      "claim_percentage",
                                      1,
                                      "inc"
                                    );
                                  }}
                                  className="dic-NS-sale-details-icon-btn"
                                >
                                  <Add />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                          {formData.claim_percentage === "" ? (
                            <Typography
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "-14px",
                              }}
                            >
                              Please provide a value
                            </Typography>
                          ) : (
                            claimPercentError && (
                              <Typography
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  marginTop: "-14px",
                                }}
                              >
                                Withdrawal Threshold can not be greater than 100
                              </Typography>
                            )
                          )}

                        </Grid>
                      </Grid>
                    </Box>
                    {
                      <Typography
                        sx={{
                          color: "red",
                          fontSize: "11px",
                          textAlign: "center",
                          marginTop: 0,
                          marginBottom: 1,
                        }}
                      >
                        {errorProjectSize === true
                          ? "Total raise can not be greater than project size."
                          : ""}
                      </Typography>
                    }
                    <Box>
                      <Grid container spacing={1}>
                        {assetData?.capital_type &&
                          JSON.parse(assetData?.capital_type) &&
                          assetData?.capital_info?.length > 0 &&
                          (() => {
                            const deals = [];
                            // getting title of all capital in array
                            let capitalData = JSON.parse(
                              assetData?.capital_type
                            );
                            // getting all data of capital type
                            let ArrLength = capitalData?.length
                              ? capitalData?.length
                              : 0;
                            let capitalTitle = capitalData;
                            for (let i = 0; i < ArrLength; i++) {
                              deals.push(
                                <Grid item xs={6} sm={6} md={6}>
                                  <Typography
                                    className="inputFieldTitle"
                                    sx={{
                                      marginBottom: "16px",
                                      color: "#94A3B8",
                                    }}
                                  >
                                    {capitalTitle[i]}
                                  </Typography>
                                  <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12}>
                                      <Box className="form-group label-field">
                                        <TextField
                                          disabled={!forSuggestProjectDetail}
                                          id="outlined-number"
                                          type="text"
                                          defaultValue={
                                            capitalTypeDetails[i]?.total_raise
                                          }
                                          label={
                                            capitalTitle[i] === "Equity"
                                              ? "Total Raise"
                                              : `Total Raise & ${capitalTitle[i]}`
                                          }
                                          className="loan-text"
                                          InputProps={{
                                            inputComponent:
                                              NumericFormatCustomDollar,
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                            style: { color: "#94A3B8" },
                                          }}
                                          inputProps={{
                                            style: {
                                              color: "white",
                                            },
                                          }}
                                          value={
                                            capitalTypeDetails[i]?.total_raise
                                          }
                                          onChange={(e) =>
                                            handleChangeCapitalTypeDat(
                                              e,
                                              capitalTitle[i],
                                              "total_raise",
                                              i
                                            )
                                          }
                                        />
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                      <Box className="form-group label-field">
                                        <TextField
                                          disabled={!forSuggestProjectDetail}
                                          id="outlined-number"
                                          type="text"
                                          defaultValue={
                                            capitalTypeDetails[i]
                                              .minimum_investment
                                          }
                                          label="Minimum Investment"
                                          className="loan-text"
                                          InputProps={{
                                            inputComponent:
                                              NumericFormatCustomDollar,
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                            style: { color: "#94A3B8" },
                                          }}
                                          inputProps={{
                                            style: {
                                              color: "white",
                                            },
                                          }}
                                          value={
                                            capitalTypeDetails[i]
                                              .minimum_investment
                                          }
                                          onChange={(e) =>
                                            handleChangeCapitalTypeDat(
                                              e,
                                              capitalTitle[i],
                                              "minimum_investment",
                                              i
                                            )
                                          }
                                        />
                                      </Box>
                                    </Grid>
                                    {capitalTitle[i] === "Equity" ? (
                                      <>
                                        <Grid item xs={12} sm={12} md={6}>
                                          <Box className="form-group label-field">
                                            <TextField
                                              disabled={
                                                !forSuggestProjectDetail
                                              }
                                              id="outlined-number"
                                              type="text"
                                              defaultValue={
                                                capitalTypeDetails[i].equity
                                              }
                                              label={`${capitalTitle[i]}`}
                                              className="loan-text"
                                              InputProps={{
                                                inputComponent:
                                                  NumericFormatCustomPercent,
                                              }}
                                              InputLabelProps={{
                                                shrink: true,
                                                style: { color: "#94A3B8" },
                                              }}
                                              inputProps={{
                                                style: {
                                                  color: "white",
                                                },
                                              }}
                                              value={
                                                capitalTypeDetails[i].equity
                                              }
                                              onChange={(e) =>
                                                handleChangeCapitalTypeDat(
                                                  e,
                                                  capitalTitle[i],
                                                  "equity",
                                                  i
                                                )
                                              }
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                          <Box className="form-group label-field">
                                            <TextField
                                              disabled={
                                                !forSuggestProjectDetail
                                              }
                                              id="outlined-number"
                                              type="text"
                                              defaultValue={
                                                capitalTypeDetails[i]
                                                  .rate_of_return
                                              }
                                              label="Interest Rate"
                                              className="loan-text"
                                              InputProps={{
                                                inputComponent:
                                                  NumericFormatCustomPercent,
                                              }}
                                              InputLabelProps={{
                                                shrink: true,
                                                style: { color: "#94A3B8" },
                                              }}
                                              inputProps={{
                                                style: {
                                                  color: "white",
                                                },
                                              }}
                                              value={
                                                capitalTypeDetails[i]
                                                  .rate_of_return
                                              }
                                              onChange={(e) =>
                                                handleChangeCapitalTypeDat(
                                                  e,
                                                  capitalTitle[i],
                                                  "rate_of_return",
                                                  i
                                                )
                                              }
                                            />
                                          </Box>
                                        </Grid>
                                      </>
                                    ) : (
                                      <>
                                        <Grid item xs={12} sm={12} md={6}>
                                          <Box className="form-group label-field">
                                            <TextField
                                              disabled={
                                                !forSuggestProjectDetail
                                              }
                                              id="outlined-number"
                                              type="text"
                                              defaultValue={
                                                capitalTitle[i] ===
                                                  "Senior Debt"
                                                  ? capitalTypeDetails[i]
                                                    .senior_ror_to
                                                  : capitalTypeDetails[i]
                                                    .junior_ror_to
                                              }
                                              label="Interest Rate"
                                              className="loan-text"
                                              InputProps={{
                                                inputComponent:
                                                  NumericFormatCustomPercent,
                                              }}
                                              InputLabelProps={{
                                                shrink: true,
                                                style: { color: "#94A3B8" },
                                              }}
                                              inputProps={{
                                                style: {
                                                  color: "white",
                                                },
                                              }}
                                              value={
                                                capitalTitle[i] ===
                                                  "Senior Debt"
                                                  ? capitalTypeDetails[i]
                                                    .senior_ror_to
                                                  : capitalTypeDetails[i]
                                                    .junior_ror_to
                                              }
                                              onChange={(e) =>
                                                handleChangeCapitalTypeDat(
                                                  e,
                                                  capitalTitle[i],
                                                  capitalTitle[i] ===
                                                    "Senior Debt"
                                                    ? "senior_ror_to"
                                                    : "junior_ror_to",
                                                  i
                                                )
                                              }
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                          <Box className="form-group label-field">
                                            <TextField
                                              disabled={
                                                !forSuggestProjectDetail
                                              }
                                              id="outlined-number"
                                              type="text"
                                              defaultValue={
                                                capitalTitle[i] ===
                                                  "Senior Debt"
                                                  ? capitalTypeDetails[i]
                                                    .iop_senior
                                                  : capitalTypeDetails[i]
                                                    .iop_junior
                                              }
                                              label="Interest-only Period (in months)"
                                              className="loan-text"
                                              InputProps={{
                                                inputComponent:
                                                  NumericFormatCustom,
                                                endAdornment: (
                                                  <InputAdornment position="start">
                                                    <Typography
                                                      color={"#94A3B8"}
                                                    >
                                                      months
                                                    </Typography>
                                                  </InputAdornment>
                                                ),
                                              }}
                                              InputLabelProps={{
                                                shrink: true,
                                                style: { color: "#94A3B8" },
                                              }}
                                              inputProps={{
                                                style: {
                                                  color: "white",
                                                },
                                              }}
                                              onKeyDown={(event) => {
                                                if (event?.key === ".") {
                                                  event.preventDefault();
                                                }
                                              }}
                                              value={
                                                capitalTitle[i] ===
                                                  "Senior Debt"
                                                  ? capitalTypeDetails[i]
                                                    .iop_senior
                                                  : capitalTypeDetails[i]
                                                    .iop_junior
                                              }
                                              onChange={(e) =>
                                                handleChangeCapitalTypeDat(
                                                  e,
                                                  capitalTitle[i],
                                                  capitalTitle[i] ===
                                                    "Senior Debt"
                                                    ? "iop_senior"
                                                    : "iop_junior",
                                                  i
                                                )
                                              }
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                          <Box className="form-group label-field">
                                            <TextField
                                              disabled={
                                                !forSuggestProjectDetail
                                              }
                                              id="outlined-number"
                                              type="text"
                                              defaultValue={
                                                capitalTitle[i] ===
                                                  "Senior Debt"
                                                  ? capitalTypeDetails[i]
                                                    .senior_duration_to
                                                  : capitalTypeDetails[i]
                                                    .junior_duration_to
                                              }
                                              label={
                                                "Duration of Debt (in years)"
                                              }
                                              className="loan-text"
                                              InputProps={{
                                                inputComponent:
                                                  NumericFormatCustom,
                                                endAdornment: (
                                                  <InputAdornment position="start">
                                                    <Typography
                                                      color={"#94A3B8"}
                                                    >
                                                      years
                                                    </Typography>
                                                  </InputAdornment>
                                                ),
                                              }}
                                              InputLabelProps={{
                                                shrink: true,
                                                style: { color: "#94A3B8" },
                                              }}
                                              inputProps={{
                                                style: {
                                                  color: "white",
                                                },
                                              }}
                                              onKeyDown={(event) => {
                                                if (event?.key === ".") {
                                                  event.preventDefault();
                                                }
                                              }}
                                              value={
                                                capitalTitle[i] ===
                                                  "Senior Debt"
                                                  ? capitalTypeDetails[i]
                                                    .senior_duration_to
                                                  : capitalTypeDetails[i]
                                                    .junior_duration_to
                                              }
                                              onChange={(e) =>
                                                handleChangeCapitalTypeDat(
                                                  e,
                                                  capitalTitle[i],
                                                  capitalTitle[i] ===
                                                    "Senior Debt"
                                                    ? "senior_duration_to"
                                                    : "junior_duration_to",
                                                  i
                                                )
                                              }
                                            />
                                          </Box>
                                        </Grid>
                                      </>
                                    )}
                                  </Grid>
                                </Grid>
                              );
                            }
                            return deals;
                          })()}
                      </Grid>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      color: "red",
                      fontSize: "11px",
                      textAlign: "center",
                      marginTop: -2,
                      marginBottom: 1,
                    }}
                  >
                    {errorCommon === true
                      ? "All fields are required."
                      : errorTotalRaise === true
                        ? "Total raise value must be equal to sum of all the capital."
                        : errorMinimumInvestment === true
                          ? "Minimum investment can not be greater than total raise."
                          : errorRate
                            ? "Interest Rate or equity cannot be greater than 100%"
                            : debtDurationErr === true
                              ? "Debt duration can not be greater than project duration"
                              : iopDurationErr === true
                                ? "Interest only period duration can not be greater than or equal to debt duration"
                                : ""}
                  </Typography>
                </>
              )}
            </Box>

            {(!fromVoting && !justShowSuggestions) && (
              <Box className="loanDone-btn-box">
                {forSuggestProjectDetail ? (
                  <Button
                    className="wallet-button prev"
                    onClick={() => handleBack()}
                  >
                    <ArrowBack />
                    Back
                  </Button>
                ) : forReviewProjectDetails ? (
                  <Button
                    className="wallet-button"
                    sx={{ background: "green !important" }}
                    onClick={() => handleConfirmProject("accept")}
                  >
                    Accept
                  </Button>
                ) : (
                  <>
                    <Button
                      className="wallet-button"
                      onClick={() => handleConfirmProject(formData)}
                      disabled={
                        (assetData?.investment_type?.investment_name === INVESTMENT_TYPE.SALE && (parseFloat(formData?.loan_amount_to) > parseFloat(formData?.loan_amount_from)
                          // || parseFloat(formData?.investment_duration) === 0 // only from v2
                        ))
                          || (assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? false : errorTotalRaise) ||
                          errorMinimumInvestment ||
                          errorFromTo.status1 ||
                          errorFromTo.status2 ||
                          errorFromTo.status3 ||
                          errorProjectSize ||
                          errorRate ||
                          errorLpRate ||
                          errorCommon ||
                          errorEstimatedValue ||
                          errorEstimatedValueWS ||
                          debtDurationErr ||
                          iopDurationErr ||
                          claimPercentError ||
                          spaceXDurationCheck ||
                          // fundTokenLockPeriod ||
                          spaceXMinError ||
                          (assetData?.investment_type?.investment_name ===
                            INVESTMENT_TYPE.FRACTION &&
                            (parseFloat(formData.fractionalize_total_price) === 0 ||
                              parseFloat(formData.fractionalize_duration_of_project) === 0 ||
                              minimumInvestZero))
                          ? true
                          : false
                      }
                    >
                      No Changes Required, Confirm Project
                    </Button>
                  </>
                )}

                {forSuggestProjectDetail ? (
                  <Button
                    disabled={
                      (assetData?.investment_type?.investment_name ===
                        INVESTMENT_TYPE.SALE && (
                          parseFloat(formData?.loan_amount_to) >
                          parseFloat(formData?.loan_amount_from)
                          // || parseFloat(formData?.investment_duration) === 0 // only from v2
                        )) ||
                        (categoryName === CATEGORY_TYPE.ART &&
                          formData.loan_amount_from === 0) ||
                        (assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? false : errorTotalRaise) ||
                        errorMinimumInvestment ||
                        errorFromTo.status1 ||
                        errorFromTo.status2 ||
                        errorFromTo.status3 ||
                        errorProjectSize ||
                        errorRate ||
                        errorLpRate ||
                        errorCommon ||
                        errorEstimatedValue ||
                        errorEstimatedValueWS ||
                        repayDayError ||
                        errorMinimumInvestmentL ||
                        iopError ||
                        debtDurationErr ||
                        iopDurationErr ||
                        claimPercentError ||
                        spaceXDurationCheck ||
                        // fundTokenLockPeriod ||
                        spaceXMinError ||
                        (assetData?.investment_type?.investment_name ===
                          INVESTMENT_TYPE.FRACTION &&
                          (parseFloat(formData.fractionalize_total_price) === 0 ||
                            parseFloat(formData.fractionalize_duration_of_project) === 0 ||
                            minimumInvestZero))
                        ||
                        ((formData.asset_field2 < 18 ||
                          formData.asset_field2 > 24) &&
                          categoryName === CATEGORY_TYPE.GOLD) || formData.asset_field4 === ""
                        ? true
                        : false
                    }
                    className="wallet-button"
                    onClick={handleSubmitDetails}
                  >
                    <DownloadDone />
                    Submit project details
                  </Button>
                ) : forReviewProjectDetails ? (
                  <Button
                    className="wallet-button"
                    sx={{ background: "red !important" }}
                    onClick={() => handleConfirmProject("reject")}
                  >
                    Reject
                  </Button>
                ) : (
                  <Button
                    className="wallet-button"
                    onClick={() => {
                      handleSuggestChanges(formData);
                    }}
                  // disabled={
                  //             CATEGORY_TYPE.ART && listingName === INVESTMENT_TYPE.PARCEL
                  //                 ? true : false}
                  >
                    <DownloadDone />
                    Suggest Changes
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Box >
      </Box >
    </Box >
  );
};

export default AssetReviewDetails;
