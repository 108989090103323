import { Box, Button, Modal, Avatar, Typography } from '@mui/material';
import React, { } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Done } from '@mui/icons-material';
import { NewLogo } from '../../Components/Images/Images';
import "../../Components/Common/ServiceProvider.css"

export default function TransactionStatusModal({ open, setOpen, ...props }) {
    const navigate = useNavigate();

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') {
            return; //do nothing basically do not close modal
        };
        setOpen(false);
    }
    const handleReturn = () => {
        navigate('/user/transactions')
    }

    const style = {
        position: "relative",
        boxShadow: 24,
        borderRadius: "24px",
        width: "1043px",

    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            className="kyc-modal"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <Box className="modal-body" sx={style}>
                <Box className="headContent">
                    <Box className="logo">
                        <Avatar
                            alt="Logo"
                            src={NewLogo}
                            variant="square"
                            sx={{ width: 39, height: 90, objectFit: "contain" }}
                        />
                    </Box>
                </Box>
                <Box className="modalContentDone " >
                    <Box className="main-content" >
                        <Box className="DoneIcon"><Done className="doneIconSvg" /></Box>
                        <Typography component="h1" className="headText">Transaction Successful</Typography >
                        <Typography component="p" className="sub-headText">You have successfully funded this project. The transaction details can be accessed in your <span style={{ textDecoration: 'underline' }}><Link to="/user/transactions" target="_blank" >My Transactions</Link></span> page once you click on your Profile.</Typography >
                        <Button onClick={handleReturn} className="wallet-button">Return to Dashboard</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

