import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "./IndividualJobs.css";
import { ccyFormat, formatDate } from "../../../Utils/utils";
import config from "../../../Config/Config";

const DetailsJob = ({ contractData }) => {

  return (
    <Box className="details-job-container">
      <Box className="details-job-main">
        <Grid container spacing={2}>
          <Grid item xs={6} md={6} sm={6}>
            <Box className="left-section">
              <Typography className="font-16">
                Auditing For {contractData?.title}
              </Typography>
              <Typography className="font-12 text-blueGray-300">
                Service to <span className="usman-text">{contractData?.clientData?.first_name} {contractData?.clientData?.last_name}</span> |
                Delivery date{" "}
                <span className="date-time-text">{contractData?.delivery_date ? formatDate(new Date(contractData?.delivery_date), 'MMM d, yyyy') : ' --'}</span>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={6} sm={6}>
            <Box className="right-section">
              <Typography className="font-14 text-blueGray-300">
                TOTAL PRICE
              </Typography>
              <Typography className="text-golden font-22">
                $ {contractData?.project_fees}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box mt={2}>
          <Typography className="font-16">Order Number</Typography>
          <Typography className="font-12 text-blueGray-300">
          {contractData?.contract_id}
          </Typography>
        </Box>
        <Box className="table-view-container">
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} sm={6}>
              <Box className="your-order-div">
                <Typography className="font-16 text-blueGray-300">
                  Your Order
                </Typography>
                <Typography className="text-blueGray-300 font-12" ml={2}>
                  {contractData?.createdAt ? formatDate(new Date(contractData?.createdAt), 'MMMM dd, yy') : ''}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={6} sm={6}>
              <Typography className="text-golden font-12 text-underline view-invoice-text">
                View Invoice
              </Typography>
            </Grid>
          </Grid>

          <Box mt={1} className="item-table-main">
            <TableContainer component={Paper}>
              <Table sx={{width:'100%' }} aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell>ITEM</TableCell>
                    <TableCell align="right">QTY</TableCell>
                    <TableCell align="right">DURATION</TableCell>
                    <TableCell align="right">PRICE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Auditing For {contractData?.title}</TableCell>
                    <TableCell align="right">1</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {/* project fee (project fee)*/}
                      ${ccyFormat(contractData?.project_fees)}
                    </TableCell>
                  </TableRow>
                  <TableRow className="sub-total-div">
                    <TableCell>SUBTOTAL</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      ${ccyFormat(contractData?.project_fees)}
                    </TableCell>
                  </TableRow>
                  <TableRow className="sub-total-div">
                    <TableCell>SERVICE FEE</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      - ${ccyFormat(contractData?.spv_service_fees ?? 0)}
                    </TableCell>
                  </TableRow>
                  <TableRow className="sub-total-div">
                    <TableCell className="text-white">
                      TOTAL 
                      <Typography className="text-blueGray-300 font-12">(You will get)</Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right" className="text-white">
                      ${ccyFormat(contractData?.project_fees)-ccyFormat(contractData?.spv_service_fees ?? 0)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box>
            <Typography className="text-blueGray-300 footer-text">
              If something appears to be missing or incorrect, please contact
              our <span className="text-underline">Customer Support Specialists</span>.
            </Typography>
          </Box>
        </Box>
        {contractData?.milestoneData?.length > 0 &&
        <Box mt={2} className="table-view-container">
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} sm={6}>
              <Box className="your-order-div">
                <Typography className="text-white font-16">
                  Milestone Details
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box mt={1} className="item-table-main">
            <TableContainer component={Paper}>
              <Table sx={{width:'100%' }} aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell>ITEM</TableCell>
                    <TableCell align="center">Due Date</TableCell>
                    <TableCell align="right">PRICE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contractData?.milestoneData?.length > 0 && contractData?.milestoneData.map((i) =>
                    <TableRow>
                      <TableCell>{i?.description}</TableCell>
                      <TableCell align="center">{i?.dueDate ? formatDate(i?.dueDate, 'MMM d, yyyy') : 'NA'}</TableCell>
                      <TableCell align="right">
                        {/* project fee (project fee)*/}
                        ${ccyFormat(i?.Amount)}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow className="sub-total-div">
                    <TableCell>TOTAL</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      ${ccyFormat(contractData?.project_fees)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>}
      </Box>
      <Box></Box>
    </Box>
  );
};

export default DetailsJob;
