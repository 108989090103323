import DealTerms from "../DealTerms";
import Documents from "../Documents";
import Location from "../Location";
import AssetDetails from "../AssetDetails";
import PriceOverview from "../PriceOverview";
import GallerySlider from "../GallerySlider";
import Description from "../Description";
import OwnerInformation from "../OwnerInformation";
import BaseDetailsAsset from "./BaseDetailsClass";
import CheckReview from "../CheckReview";
import CapitalInfo from "../CapitalInfo";
import Templates from "../Templates";
export default class Estate extends BaseDetailsAsset {

  rightPanel = [];
  leftPanel = [];
  isParcel = false;

  constructor(_data) {
    super(_data);
    if (!_data) {
      throw new Error(
        "Custom error: Real Estate data is required in Art Details class"
      );
    }

    this.offersData = [
      {
        user_name: "John Doe",
        offer_amount: "12850.98",
      },
      {
        user_name: "John Doe",
        offer_amount: "12850.98",
      },
      {
        user_name: "John Doe",
        offer_amount: "12850.98",
      },
    ];

    this.assetDetails = [
      {
        title: "Investment Type",
        value: _data?.investment_type?.investment_name,
      },
      {
        title: "Property Type",
        value: _data.owner_information
          ? _data.asset_sub_category_info?.title
          : "",
      },
      {
        title: "Owned By",
        value: _data.owner_information
          ? _data.owner_information.owner_type
          : "",
      },
    ];

    if (this.isLoan) {
      this.dealData = [
        {
          title: "Loan Duration",
          value: _data?.loan_duration,
        },
        {
          title: "Interest Rate",
          value: _data?.loan_roi + '% APR',
        },
      ];
    } else {
      this.dealData = [
        {
          title: 'Total Raise',
          value: `$${_data?.fractionalize_total_price}`,
        },
        {
          title: 'Total Project Value',
          value: `$${_data?.fractionalize_project_size}`,
        },
        {
          title: 'Duration of Project',
          value: `${_data?.fractionalize_duration_of_project
            } Years`,
        },
        {
          title: "Type of Capital",
          value: _data?.capital_type,
        }
      ];
    }


    this.checkReview = [
      {
        title: "All required documents uploaded",
        value: false,
      },
      {
        title: "Deal terms reviewed",
        value: false,
      },
      {
        title: "Asset Details provided are accurate",
        value: false,
      },
      {
        title: "Project Description and Title reviewed",
        value: false,
      },
      {
        title: "Images uploaded are clear and accurate",
        value: false,
      },
    ];



    this.data = _data;
    this.blockchain_id = _data.blockchain_id
    this.fromClass = "llcDetails";
    this.description_document = _data.description_document
    this.asset_signing_document = _data.asset_signing_document
    this.selected_templates = _data?.template_data ? _data?.template_data[0]?.template_ids : []
    this.docs = _data.asset_document;
    this.location = _data?.asset_location?.split(",");
    this.galleryImages = _data?.asset_gallery;
    this.member_id = _data?.user_details?.member_id

    if (_data.description_document?.length > 0) {
      const documentOnly = _data.description_document.filter(item => item.images && (item.images.type === "mp4"));
      if (documentOnly?.length > 0) {
        this.galleryImages = _data?.asset_gallery.concat(documentOnly);
      }
    }
    this.coverPhoto = _data?.asset_coverphoto;
    this.isParcel = _data?.investment_type?.investment_name === "Parcelling" ? true : false;
    this.descriptionData = {
      title: _data?.asset_title,
      subtitle: _data?.asset_address,
      investmentType: _data?.investment_type?.investment_name,
      assetType: _data?.assets_category?.title,
      assetSubtype: _data?.asset_sub_category_info?.title,
      description: _data?.asset_description,
      image: _data?.asset_gallery[0]?.images?.path,
    };
    this.parcelCardsData = _data?.parcelData;

    this.setLeftPanelComponents();
    this.setRightPanelComponents();
  }

  setLeftPanelComponents = () => {
    // if (this.isParcel === true) {
    //   this.leftPanel.push(
    //     <ParcelDescription
    //       description={this.data.text_description}
    //       parcelImage={this.descriptionData.image}
    //     />
    //   );
    //   this.leftPanel.push(<ParcelCardGrid cardsData={this.parcelCardsData} />);
    // } else {
    this.leftPanel.push(<GallerySlider images={this.galleryImages} coverPhoto={this.coverPhoto} />);
    this.leftPanel.push(
      <Description
        description={this.descriptionData.description}
        investmentType={this.descriptionData.investmentType}
        title={this.descriptionData.title}
        subtitle={this.descriptionData.subtitle}
        assetType={this.descriptionData.assetType}
        assetSubtype={this.descriptionData.assetSubtype}
        parcelCardsData={this.parcelCardsData}
        userId={this.user_id}
        assetId={this.asset_Id}
      />
    );
    // }
  };

  setRightPanelComponents = () => {
    this.rightPanel.push(<OwnerInformation owner={this.ownerInfo} totalProject={this.totalProjects} member_id={this.member_id} />);
    this.rightPanel.push(<PriceOverview prices={this.prices} />);
    this.rightPanel.push(<CapitalInfo capitalInfo={this.capitalInfo} />)
    this.rightPanel.push(<AssetDetails details={this.assetDetails} editable={this.isOwner} />);
    this.rightPanel.push(<Location lat={this.location[0]} long={this.location[1]} editable={this.isOwner} />);
    this.rightPanel.push(<DealTerms dealTerms={this.dealData} fromPage={this.fromClass} editable={this.isOwner} />);
    this.rightPanel.push(<Documents documentsData={this.docs} descriptionData={this.description_document} assetId={this.asset_Id} ownerId={this.ownerInfo} editable={this.isOwner} section_title="Asset Documents" />);
    // this.rightPanel.push(<Documents documentsData={this.asset_signing_document} descriptionData={[]} assetId={this.asset_Id} ownerId={this.ownerInfo} editable={this.isOwner} section_title="Agreement Documents" />);
    this.rightPanel.push(<Templates selected_templates={this.selected_templates} />);
    this.rightPanel.push(<CheckReview data={this.data} blockchain_id={this.blockchain_id} assetId={this.asset_Id} details={this.checkReview} editable={this.isOwner} reviewList={this.reviewList} readOnly={this.readOnly} />);
  };
}
