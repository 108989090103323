import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import "./OnboardingProfile.css"
import OnboardingProfileLeft from './OnboardingProfileComponent/OnboardingProfileLeft'
import OnboardingProfileRight from './OnboardingProfileComponent/OnboardingProfileRight'

const OnboardingProfile = ({onNext, onBack ,handleDataProfile ,previousData ,userProfileImage}) => {

  const [sharedData, setSharedData] = useState({}); // State to hold shared data
  const [profileImage, setProfileImage] = useState(null); // State to hold profile image

  // Function to handle data update from the left component
  const handleDataUpdate = (newData) => {
    setSharedData(newData);
    handleDataProfile(newData)
  };

  // Function to handle profile image update
  const handleProfileImageUpdate = (image) => {
    setProfileImage(image);
    userProfileImage(image) 
  };


  return (
    <Box className="onboarding-profile-main">
      <Box className="onboarding-profile-container">
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} sm={12}>
            <OnboardingProfileLeft onDataUpdate={handleDataUpdate} onImageUpdate={handleProfileImageUpdate} previousData={previousData} onBack={onBack}  onNext={onNext}/>
          </Grid>
          <Grid item xs={12} md={4} sm={12}>
            <Box pl={3}>
              <OnboardingProfileRight personalInfoData={sharedData} profileImage={profileImage} previousData={previousData} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default OnboardingProfile