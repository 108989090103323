import React from 'react';
import { Drawer, Box } from '@mui/material';
import './RightSideDrawer.css';

const RightSideDrawer = ({ isDrawerOpen, setIsDrawerOpen, component, width }) => {
    return (
        <div >
            <Drawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
                <Box
                    role="presentation"
                    sx={{
                        width: width || '500px',
                        padding: 2,
                        backgroundColor: '#05131B',
                        color: 'white',
                        boxShadow: '0 2px 10px #000000',
                    }}
                >
                    {component}
                </Box>
            </Drawer>
        </div>
    );
};

export default RightSideDrawer;