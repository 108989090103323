import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, TextField, FormControl, InputAdornment } from "@mui/material";
import { KeyboardArrowRight, AttachMoney } from "@mui/icons-material";
import countryList from "react-select-country-list";
import { useForm } from "react-hook-form";

// Import custom components for this page
import { NewLogo } from "../../../Components/Images/Images";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import "./Components/Questionnaire.css";

export default function GoldDetails(props) {
    // Destructure props to obtain necessary functions and data
    const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;
    const countryNames = useMemo(() => countryList().getData(), []);

    // Getting current proposal and progress data from parent
    var propData = getProposalData();
    let currentProgress = getProgress();

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'onChange',
        defaultValues: {
            gold_country: propData?.goldInformation?.gold_country ? propData?.goldInformation?.gold_country : "",
            gold_condition: propData?.goldInformation?.gold_condition ? propData?.goldInformation?.gold_condition : "",
        }
    }
    );

    /**
    * Handles the form submission action.
    * @param {Object} data - Form data submitted.
    * @param {Object} event - Form submission event.
    */
    const onSubmit = (data, event) => {
        const buttonText = event.nativeEvent.submitter.innerText;
        // Update 'propData' with the form data
        propData.goldInformation = data
        // setting gold title as asset title
        propData.asset_title = data.gold_title
        // setting gold address as asset address
        propData.asset_address = data.gold_address

        // converting string to numeric
        const stringWithoutCurrency = data?.gold_estimated.replace(/[^0-9.]/g, ''); // Removes non-numeric characters
        const integerValue = parseFloat(stringWithoutCurrency, 10); // Parses the string as an float
        propData.goldInformation.gold_estimated = integerValue;
        // update it to main page
        updateProposalData(propData)

        if (buttonText.includes("Save and Exit")) {
            SaveAndExit()
        } else {
            handleNext();
        }
    }

    /**
     * Function to handle "Save and Exit" button
     */
    const SaveAndExit = () => {
        saveProposal();
    }
    return (
        <Box className="projectProposer questionnaire-page">
            <Box className="questionnaire-section">
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-left">
                            {/* left-title */}
                            <Box className="quests-left-title">
                                <Typography component="h1" className="title">
                                    Next, we need some details of your assets
                                </Typography>
                            </Box>
                            {/* Logo */}
                            <Box className="auth-logo">
                                <Link to="/">
                                    <Box component="img" src={NewLogo} alt="Race logo" />
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-right quests-right-contents-box" component="form"
                            onSubmit={handleSubmit(onSubmit)}>
                            <Box textAlign={"right"}>
                                <Box component="span" className="icon" ml={1}>
                                    <Button id="my-button" className="btn-rounded btn-text-white btn-blue-600 saveAndExit" type="submit">
                                        Save and Exit
                                    </Button>
                                </Box>
                            </Box>
                            <Box className="questionnaire6 qsn-middle-content" sx={{ pt: 4, mb: 5 }} >
                                <Box className="auth-form" mt={4} >
                                    <Box className="form-group label-field">
                                        <TextField
                                            defaultValue={propData?.goldInformation?.gold_title}
                                            autoComplete="title"
                                            type="text"
                                            id="outlined-required"
                                            label='Title'
                                            {...register('gold_title', {
                                                required: 'Title is required.',
                                            })}
                                            error={Boolean(errors.gold_title)}
                                            helperText={errors.gold_title?.message}
                                        />
                                    </Box>
                                    <Box className="form-group label-field">
                                        <TextField
                                            defaultValue={propData?.goldInformation?.gold_quality}
                                            autoComplete="on"
                                            id="outlined-required"
                                            label='Quality (in carat)'
                                            {...register('gold_quality', {
                                                required: 'Quality is required.',
                                                validate: value => {
                                                    const numericValue = parseFloat(value.replace(',', '')); // Convert formatted input to a number
                                                    if (isNaN(numericValue)) {
                                                        return 'Please enter a valid quality.';
                                                    }
                                                    if (numericValue < 18) {
                                                        return 'Minimum 18 carats';
                                                    }
                                                    if (numericValue > 24) {
                                                        return 'Maximum 24 carats'
                                                    }
                                                    return true;
                                                }
                                            })}
                                            onInput={(event) => {
                                                event.target.value = event.target.value
                                                    .replace(/[^\d]/g, "")
                                            }}
                                            error={Boolean(errors.gold_quality)}
                                            helperText={(errors.gold_quality?.message && Boolean(errors.gold_quality)) ? errors.gold_quality?.message : "Minimum 18 carats"}
                                        />
                                    </Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.goldInformation?.gold_weight}
                                                    autoComplete="on"
                                                    type="text"
                                                    id="outlined-required"
                                                    label="Weight in Grams"
                                                    {...register("gold_weight", {
                                                        required: "Weight is required.",
                                                        pattern: {
                                                            value: /^[+]?([0-9]*[.])?[0-9]+$/,  // Allow positive numeric values with one dot
                                                            message: "Please enter a valid weight.",
                                                        },
                                                    })}
                                                    error={Boolean(errors.gold_weight)}
                                                    helperText={errors.gold_weight?.message}
                                                    onInput={(event) => {
                                                        event.target.value = event.target.value
                                                            .replace(/[^\d.]/g, "")
                                                    }}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.goldInformation?.gold_estimated}
                                                    autoComplete="on"
                                                    id="outlined-required"
                                                    label="Estimated value at purchase in USD"
                                                    InputProps={
                                                        {
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <AttachMoney />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    {...register("gold_estimated", {
                                                        required: "Estimated Value is required.",
                                                        pattern: {
                                                            value: /^(?!0(\.0+)?$)([0-9]*[.])?[0-9]+$/,  // Allow positive numeric values with one dot
                                                            message: "Please enter a valid estimated value greater than 0.",
                                                        },
                                                    })}
                                                    error={Boolean(errors.gold_estimated)}
                                                    helperText={errors.gold_estimated?.message}
                                                    onInput={(event) => {
                                                        event.target.value = event.target.value
                                                            .replace(/[^\d.]/g, "")
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box>
                                        <Grid mb={10}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Condition"
                                                    id="outlined-select-country-native"
                                                    select
                                                    SelectProps={{
                                                        native: true
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    name='condition'
                                                    {...register("gold_condition", {
                                                        required: "Condition is required.",
                                                    })}
                                                    error={Boolean(errors.gold_condition)}
                                                    helperText={errors.gold_condition?.message}
                                                    className='stIf-input'
                                                    sx={{ input: { color: '#64748B !important' } }}
                                                >
                                                    <option value={""} >
                                                        Select Condition
                                                    </option>
                                                    <option value={"Mint"} key={1}>
                                                        Mint
                                                    </option>
                                                    <option value={"Pure Gold (24 karats)"} key={1}>
                                                        Pure Gold (24 karats)
                                                    </option>
                                                    <option value={"Hallmarked"} key={1}>
                                                        Hallmarked
                                                    </option>
                                                </TextField>
                                            </FormControl>
                                        </Grid>
                                    </Box>
                                    <Box className="form-group label-field" >
                                        <TextField
                                            autoComplete="address-line2"
                                            InputLabelProps={{ shrink: true }}
                                            defaultValue={propData?.goldInformation?.gold_address}
                                            id="outlined-required"
                                            label="Address (Location of Asset)"
                                            type="text"
                                            SelectProps={{
                                                native: true
                                            }}
                                            {...register("gold_address", {
                                                required: "Address is required.",
                                            })}
                                            error={Boolean(errors.gold_address)}
                                            helperText={errors.gold_address?.message}
                                            multiline
                                        />
                                    </Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.goldInformation?.gold_floor}
                                                    autoComplete="address-line1"
                                                    type="text"
                                                    id="outlined-required"
                                                    label={"Suite/Floor"}
                                                    {...register("gold_floor", {
                                                        required: "Suite/Floor is required.",
                                                    })}
                                                    error={Boolean(errors.gold_floor)}
                                                    helperText={errors.gold_floor?.message}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.goldInformation?.gold_city}
                                                    type="text"
                                                    autoComplete="on"
                                                    id="outlined-required"
                                                    label="City"
                                                    {...register("gold_city", {
                                                        required: "City is required.",
                                                    })}
                                                    error={Boolean(errors.gold_city)}
                                                    helperText={errors.gold_city?.message}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.goldInformation?.gold_state}
                                                    autoComplete="on"
                                                    type="text"
                                                    id="outlined-required"
                                                    label={"State/Province"}
                                                    {...register("gold_state", {
                                                        required: "State/Province is required.",
                                                    })}
                                                    error={Boolean(errors.gold_state)}
                                                    helperText={errors.gold_state?.message}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.goldInformation?.gold_zipcode}
                                                    type="text"
                                                    autoComplete="on"
                                                    id="outlined-required"
                                                    label="Zip/Postal"
                                                    {...register("gold_zipcode", {
                                                        required: "Estimated Value is required.",
                                                    })}
                                                    error={Boolean(errors.gold_zipcode)}
                                                    helperText={errors.gold_zipcode?.message}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box>
                                        <Grid mb={10}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    InputLabelProps={{ shrink: true }}
                                                    label="Country"
                                                    id="outlined-select-country-native"
                                                    select
                                                    SelectProps={{
                                                        native: true
                                                    }}
                                                    name='gold_country'
                                                    {...register("gold_country", {
                                                        required: "Country is required.",
                                                    })}
                                                    error={Boolean(errors.gold_country)}
                                                    helperText={errors.gold_country?.message}
                                                    className='stIf-input'
                                                    sx={{ input: { color: '#64748B !important' } }}
                                                >
                                                    <option value={""}>
                                                        {"-Select a Country-"}
                                                    </option>
                                                    {countryNames.map((option) => (
                                                        <option key={`Topic${option?.label}`}
                                                            value={option?.label}
                                                        >
                                                            {option?.label}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </FormControl>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Box className="questionnaire-progress" >
                                    <LinearProgressCompo value={currentProgress} />
                                </Box>
                                <Box
                                    sx={{ display: "flex", justifyContent: "space-between" }}
                                    mt={3}
                                >
                                    <Box textAlign={"left"} className="quests6-btn-box">
                                        <Button className="btn-rounded back-btn" onClick={handleback}>Back</Button>
                                    </Box>
                                    <Box textAlign={"right"} className="quests-btn-box">
                                        <Button
                                            id="my-button"
                                            endIcon={<KeyboardArrowRight />}
                                            className="btn-rounded btn-text-white btn-blue-600"
                                            type="submit"
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    );
}
