import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { GetApi } from "../../Api/Api";
import ChatContainer from "./ChatContainer";
import Contacts from "./Contacts";
import { decreaseTotalUnreadCount } from "../../features/auth/serviceChatSlice";
import { useSocket } from "../../Contexts/SocketContext";
import { getUserDataFromLocalStorage } from "../../Services/localstorage.service";
import "./Chats.css";

const ChatsPage = () => {
  const sidebarOpen = useOutletContext();
  const socket = useSocket();
  const dispatch = useDispatch();
  const onlineUsers = useSelector((state) => state.serviceChat.onlineUsersIds);

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [contacts, setContacts] = useState([]);
  const [currentChat, setCurrentChat] = useState(undefined);
  const [currentUser, setCurrentUser] = useState(undefined);
  const logedinUserData = getUserDataFromLocalStorage();

  useEffect(() => {
    const serviceProviderUser = localStorage.getItem("service-provider")
    const serviceProposerUser = localStorage.getItem("service-proposer")

    /**
     * Checking whether the user or userData is Available or not
     */
    if (!logedinUserData) {
      navigate("/login");
    } else {
      socket.emit("sp-refresh-unread", logedinUserData?.id);
      setCurrentUser(logedinUserData);
    }
  }, []);

  useEffect(() => {
    const getContacts = async () => {
      let res = await GetApi(`/chat/spGetChat/${currentUser ? currentUser.id : state.user_id}`);
      setContacts(res?.data?.data);
      /**
       * Checking Condition for the case when user comes from Project Detail page
       * will open the chat of the selected asset and receiver
       */
      if (state?.chat_id) {
        let dataForChat = res?.data?.data?.filter((elem) => {
          return (elem.id === state.chat_id)
        })
        if (dataForChat?.length) {
          setCurrentChat(dataForChat[0]);
        }
      }
    };
    if (currentUser || state) {
      getContacts();
    }
  }, [currentUser, state]);

  const handleChatChange = (chat) => {
    // reset unread count to 0 for the selected chat contact
    let countTobeRead = 0;
    contacts.forEach((element, index) => {
      if (element.id === chat.id) {
        countTobeRead = element.unread;
        contacts[index].unread = 0;
      }
    })
    dispatch(decreaseTotalUnreadCount(countTobeRead));
    setContacts(contacts);
    setCurrentChat(chat);
    // emit seen message event
    socket.emit("sp-seen-msg", { chatId: chat.id, userId: currentUser.id });
  };

  return (
    <Grid container className="service-provider-ctPage" spacing={0} marginBottom={'-48px'} marginRight={'-20px'} marginTop={'-80px'}>
      <Grid xs={4} md={sidebarOpen ? 3.5 : 3} sm={4}>
        <Contacts contacts={contacts} changeChat={handleChatChange} onlineUsers={onlineUsers} currentChat={currentChat} logedinUserData={logedinUserData} />
      </Grid>
      <Grid xs={8} md={sidebarOpen ? 8.5 : 9} sm={8} >
        <ChatContainer currentChat={currentChat} socket={socket} logedinUserData={logedinUserData} />
      </Grid>
    </Grid>
  );
};

export default ChatsPage;
