import CategoryHeader from "../CategoryHeader";
import ReachInvestors from "../../Components/Common/ReachInvestors";
import { Fractional, fundsIcon } from "../../Components/Images/Images";
import NewestListings from "../NewestListings";
import TopInvestments from "../TopInvestments";
import TypeSlider from "../TypeSlider";

import ExploreFractionalizationCompo from "../ExploreFractionalization";
import { Box } from "@mui/material";
export default class Funds {
  constructor() { }

  type = "Funds";

  pageComponenets = [];

  headerData = {
    title: "Build Your Own Portfolio of",
    highlight: " Tokenised Funds",
    subtitle:
      "Invest in Fine Funds easily; in a new, secure, and transparent way.",
    image: fundsIcon,
  };

  fractionalizationData = {
    title: "You can now own a small",
    highlightText: "piece",
    title1: "of the pie",
    subTitle:
      "RACE's tokenisation methods allow investorsto invest in the portion of the project, however much you're comfortable with",

    learnMoreLink: "/dummy",
    // extra: "What’s the difference between a parcel and a fraction?",
    extraLink: "/dummy",
    image: Fractional,
    exploreFractionalization: true,
    exploreParceling: false,
  };

  setPageComponents() {
    this.pageComponenets.push(<CategoryHeader categoryId={9} data={this.headerData} />);
    this.pageComponenets.push(<NewestListings categoryId={9} type="Funds" />);
    this.pageComponenets.push(<TopInvestments categoryId={9} type="Funds" />);
    this.pageComponenets.push(
      <TypeSlider
        categoryId={9}
        sliderData={this.typeSliderData}
        sliderTitle={"Fund Types"}
      />
    );

    this.pageComponenets.push(
      <ExploreFractionalizationCompo
        categoryId={9}
        investment_type_id={4}
        data={this.fractionalizationData}
        type="Funds"
      />
    );

    this.pageComponenets.push(<Box className='container'><ReachInvestors /></Box>);
  }
}
