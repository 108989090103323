import React ,{useState} from 'react';
import { Box, Typography } from '@mui/material'
import PostProject from '../PostProjectComponent/PostProject';
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useEffect } from 'react';
import "./ProjectPosting.css"

const ProjectPosting = () => {
    const [stateData,setStateData]=useState("")
    const navigate = useNavigate();
    const location = useLocation();

useEffect(()=>{
    if(location.state){
        setStateData(location.state)
    }
}
,[location.state])

    return (
        <Box>
            <Box>
                <Box className="dashboard-project-posting">
                    <Box>
                        <PostProject data={stateData} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ProjectPosting