import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddGalleryPopup from "./AddGalleryPopup/AddGalleryPopup";

const Gallery = ({ onDataUpdate, onNext, onBack, stepFourPreviousData }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [galleryDetails, setGalleryDetails] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [finishButtonClicked, setFinishButtonClicked] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (stepFourPreviousData?.galleryDetails) {
      setGalleryDetails(stepFourPreviousData?.galleryDetails);
    }
  }, [stepFourPreviousData]);

  useEffect(() => {
    if (finishButtonClicked) {
      validateForm();
    }
  }, [galleryDetails, finishButtonClicked]);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setCurrentId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    if (currentId) {
      const updatedMediaFiles = galleryDetails.filter(file => file.id !== currentId);
      setGalleryDetails(updatedMediaFiles);
      setAnchorEl(null); // Close the menu after deletion
    }
  };

  const handleOpenPopupGallery = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopupGallery = () => {
    setIsPopupOpen(false);
  };

  const handleSaveGalleryDetail = (newDetails) => {
    setGalleryDetails([...galleryDetails, newDetails]);
  };

  const generateUrlFromFile = (file) => {
    return URL.createObjectURL(file);
  };

  const validateForm = () => {
    const errors = {};
    if (galleryDetails.length === 0) {
      errors.galleryDetails = "Gallery Images/Videos are required.";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleNext = async () => {
    setFinishButtonClicked(true);
    if (validateForm()) {
      const dataCollection = {
        galleryDetails,
      };
      onDataUpdate(dataCollection);
      onNext();
    }
  };

  return (
    <Box className="list-service-gallery-container">
      <Box className="top-view">
        <Grid container spacing={1} justifyContent={'space-between'}>
          <Grid item md={8}>
            <Box>
              <Typography className="font-36">Gallery</Typography>
              <Typography>
                Your project will display with an image or a video as its cover.
                You can upload up to 3 images/videos that represent your service.
                For best results with images, consider ones with a 4:3 aspect ratio
                that are less than 10 MB and under 4000 px in width or height.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="upload-img">
              <Button
                variant="outlined"
                endIcon={<AddCircleOutlineIcon />}
                className="btn-rounded btn-large"
                onClick={handleOpenPopupGallery}
              >
                Upload images or videos
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2}>
          {galleryDetails.length > 0 ? (
            galleryDetails.slice(0, 3).map((portfolio) => (
              <Grid item md={6} key={portfolio.id}>
                <Box className='listaservice-gallery-photo-card' sx={{height: '100%'}}>
                  <Box sx={{height: '100%'}}>
                    {portfolio.gallery_image.type === "image/jpeg" || portfolio.gallery_image.type === "image/png" ? (
                      <img
                        className="listaservice-gallery-images"
                        src={generateUrlFromFile(portfolio.gallery_image)}
                        width="100%"
                      />
                    ) : (
                      <Box sx={{height: '100%', backgroundColor: "#010001", borderRadius: '24px', overflow: 'hidden'}}>
                        <video
                          controls
                          poster={generateUrlFromFile(portfolio.gallery_image)}
                          width="100%"
                          height="100%"
                          className='image-gallery-image'
                        >
                          <source
                            className="listaservice-gallery-images"
                            src={generateUrlFromFile(portfolio.gallery_image)}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }} className="gallery-image-option">
                    <Tooltip title="Options">
                      <IconButton
                        onClick={(e) => handleClick(e, portfolio.id)}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        bgcolor="red"
                      >
                        <MoreHorizIcon className="sp-gallery-option-icon" sx={{ borderRadius: '8px', color: 'white', bgcolor: "#06487E" }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
                <Menu
                  className='SlGallery-menu'
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={handleDelete}>Delete</MenuItem>
                </Menu>
              </Grid>
            ))
          ) : (
            <Box>
              {validationErrors.galleryDetails && (
                <Typography variant="body2" ml={3} color="error">
                  {validationErrors.galleryDetails}
                </Typography>
              )}
            </Box>
          )}
        </Grid>
      </Box>
      <Box className="btn-content-div">
        <Stack direction="row" spacing={2} alignItems="center">
          <Box className="go-back-btn">
            <Button startIcon={<ArrowBackIcon />} onClick={onBack}>
              Go Back
            </Button>
          </Box>
          <Box className="continue-btn">
            <Button
              variant="contained"
              startIcon={<ArrowForwardIcon />}
              className="btn-rounded btn-large"
              style={{ background: "var(--golden-gradient)" }}
              onClick={handleNext}
            >
              Continue
            </Button>
          </Box>
        </Stack>
      </Box>
      {isPopupOpen && <AddGalleryPopup open={isPopupOpen} handleClose={handleClosePopupGallery} onSave={handleSaveGalleryDetail} />}
    </Box>
  );
};

export default Gallery;
