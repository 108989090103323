import React from 'react'
import { Chip } from '@mui/material'
import PropTypes from 'prop-types'
import './SkillChip.css'

function SkillChip({ label }) {
    return (
        <Chip className='skill-chip' label={label} />
    )
}

export default SkillChip

//prop types
SkillChip.propTypes = {
    label: PropTypes.string.isRequired
}

//default props
SkillChip.defaultProps = {
    label: 'Skill One'
}