import React, { useState, useEffect, useCallback } from "react";
import { Avatar, Box, Button, Card, CardHeader, Chip, IconButton, Grid, Modal, Stack, Tooltip, Typography, Tabs, Tab } from "@mui/material";
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import StarsIcon from "@mui/icons-material/Stars";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import PropTypes from "prop-types";
import "./ListService.css";
import ViewServiceSlider from "./ViewServiceSlider";
import { GetApiParam } from "../../../Api/Api";
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate } from "../../../Utils/utils";
import config from "../../../Config/Config";

const ReviewServicePost = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [view, setView] = useState(false);
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [value, setValue] = useState(0);
  const [profileData, setProfileData] = useState([]);
  const [serviceData, setServiceData] = useState(null);
  const [language, setLanguage] = useState();
  const userData = getUserDataFromLocalStorage();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleView = () => {
    setView(!view);
  };

  const handleClose = () => setOpenModal(false);

  const viewDoc = async (val) => {
    setOpenModal(true);
    handleView();
    // setFile(fullPath);
    setType(val?.type);
    if (val?.path && val?.title) {
      const fileName = await fetch(`${process.env.REACT_APP_IMAGE_URL}${val.path ? val.path : ""
        }`);

      const templateBuffer = await fileName.arrayBuffer();
      const templateBlob = new Blob([templateBuffer], { type: 'application/pdf' });
      const templateUrl = URL.createObjectURL(templateBlob);
      setFile(templateUrl);
    }else{
      setFile(val);
    }
  };

  const getProfileData = useCallback(async(user_id) => {
    try {
      const res = await GetApiParam("/services/ProfileDetails", { user_id: user_id });
      setProfileData(res?.data?.data ? res?.data?.data[0] : []);
      const profileLanguage = res?.data?.data[0]?.language ? JSON.parse(res?.data?.data[0]?.language) : null;
      const languageSelected = profileLanguage && profileLanguage.map((val) => {
        return `${val?.language}`;
      }).join(" | ");
      setLanguage(languageSelected);
    } catch (error) {
    }
  },[]);

  const getServiceData = useCallback(async() => {
    try {
      const res = await GetApiParam("/services/getServices", { id: id, login_user_id :userData?.id});
      if(res?.data?.data && res?.data?.data.length > 0){
        setServiceData(res?.data?.data[0]);
        getProfileData(res?.data?.data[0].user_id);
      }
    } catch (error) {
    }
  },[]);

  useEffect(() => {
    getServiceData();
  }, [])

  useEffect(() => {
    function scrollTopFunction() {
      document.documentElement.scrollTop = 0;
    }
    scrollTopFunction()
  }, []);

  const handleBack = () => {
    navigate("/service-provider-dashboard/profile")
  };

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3, pl: 0, pr: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <Box className="review-service-post-container" >
      <Box className="top-view">
        <Box>
          <Stack direction="column" spacing={2} >
            <Box className="go-back-btn">
              <Button startIcon={<ArrowBackIcon />} onClick={handleBack}></Button>
            </Box>

          </Stack>
        </Box>
      </Box>

      <Box className="review-content">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            <Box className="left-content">
              <Typography className="font-32">
                {serviceData?.services_title}
              </Typography>
              <Box className="skills-box">
                <Box className="pc-chip-wrap" >
                  <Stack direction="row" flexWrap={'wrap'} display={"flex"} gap={1} >
                    {serviceData?.Skills?.map((item) => (
                      <Chip className="pc-chip" label={item?.skill_name} />
                    ))}
                  </Stack>
                </Box>

                {/* <Box className="">
                  <Stack direction="row" spacing={1.5} alignItems={'center'}>
                    <IconButton aria-label="View" size="large" className={"square-icon-btn"} >
                      <BookmarkBorderIcon />
                    </IconButton>
                  </Stack>
                </Box> */}
              </Box>

              <Box className="mr-3 publish-slider">
                <ViewServiceSlider coverPhoto={serviceData} images={serviceData} />
              </Box>

              <Box>
                <Typography className="font-24" mt={3}>About this service</Typography>
                <Typography className="font-14" mt={2} style={{ whiteSpace: 'pre-line' }}>
                  {serviceData?.describe_services}
                </Typography>
                <Typography className="font-24" mt={3}>Requirement</Typography>
                <Typography className="font-14" mt={2} style={{ whiteSpace: 'pre-line' }}>
                  {serviceData?.requirment_describe}
                </Typography>

              </Box>
              <Box className="about-service-provider">
                <Typography className="font-24">
                  About the Service Provider
                </Typography>
                <Box className="about-service-content">
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Box className="profile-persona-img-wrap">
                        <Avatar src={profileData?.profilePicture && profileData?.profilePicture.length > 0 ? `${process.env.REACT_APP_IMAGE_URL}${profileData?.profilePicture[0].path}` : ''} alt="" className='profile-persona-img user-img-divs' />
                      </Box>
                    </Grid>
                    <Grid item xs={10}>
                      <Box className="right-div">
                        <Typography className="font-22">
                          {profileData?.display_name}
                        </Typography>
                        <Typography className="font-14 text-blueGray-300">
                          {profileData?.title}
                        </Typography>
                        <Box className="profile-view">
                          <Box className="left-view">
                            <StarsIcon />
                            <Typography ml={2} className="font-12">
                              {profileData?.rating ? profileData?.rating : 'NA'}
                            </Typography>
                          </Box>
                          <Box className="top-rates-btn">
                            <Stack spacing={2} direction="row">
                            {profileData?.rating >= config?.TOP_RATAED?.ratingGrater && profileData?.review >= config?.TOP_RATAED?.reviewGrater && <Button variant="contained" className="">
                                                        Top-Rated
                                                    </Button>}
                              <Button variant="contained" className="">
                                {profileData?.successRate ? `${profileData?.successRate}% Success` : 'Not Worked'}
                              </Button>
                            </Stack>
                          </Box>
                        </Box>
                        {/* <Box className="contact-me-btn">
                          <Button variant="outlined" className="">
                            Contact Me
                          </Button>
                        </Box> */}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Box px={2} py={3}>
                    <Grid container spacing={2}>
                      <Grid md={3} sm={3}>
                        <Box>
                          <Typography className="font-22">{profileData?.address}</Typography>
                          <Typography className="font-12 text-blueGray-300">
                            Country
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid md={3} sm={3}>
                        <Box>
                          <Typography className="font-22">{profileData?.avgResponseTime}</Typography>
                          <Typography className="font-12 text-blueGray-300">
                            Avg. response time
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid md={3} sm={3}>
                        <Box>
                          <Typography className="font-22">{profileData?.lastDelivery ? formatDate(profileData?.lastDelivery, 'MMM d, yyyy') : 'NA'}</Typography>
                          <Typography className="font-12 text-blueGray-300">
                            Last delivery
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid md={3} sm={3}>
                        <Box>
                          <Typography className="font-22">{language ? language : null}</Typography>
                          <Typography className="font-12 text-blueGray-300">
                            Languages
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Typography className="font-18">
                      {profileData?.title}
                    </Typography>
                    <Typography className="font-14 text-blueGray-300">
                      {profileData?.description}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box className="left-content" mb={2}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab style={{ textTransform: "capitalize" }} label="Basic" {...a11yProps(0)} />
                    <Tab style={{ textTransform: "capitalize" }} label="Standard" {...a11yProps(1)} />
                    <Tab style={{ textTransform: "capitalize" }} label="Premium" {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <Box className="basic-step-first-container">
                    <Box className="top-view">
                      <Box>
                        <Typography className="font-16">
                          {serviceData?.starter_price_scope?.title}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className="font-22" sx={{ textWrap: 'nowrap' }}>$ {serviceData?.starter_service_price}</Typography>
                      </Box>
                    </Box>
                    <Box mt={2}>
                      <Typography className="font-14">
                        {serviceData?.starter_price_scope?.description}
                      </Typography>
                    </Box>

                    <Box mt={2} className="day-delivery">
                      <Typography className="font-12">
                        {serviceData?.starter_price_scope?.deliveryDays} Days Delivery
                      </Typography>
                      <Typography className="font-12" ml={2}>
                        {serviceData?.starter_price_scope?.revisions} Revisions
                      </Typography>
                    </Box>

                    <Box class="basic-check-points">
                      <CheckIcon />
                      <Typography className="check-text font-14">
                        {serviceData?.starter_price_scope?.includedAdditionalService}
                      </Typography>
                    </Box>

                    {/* <Stack direction="column" spacing={1} mt={2}>
                      <Button variant="contained" style={{ color: "#fff", borderRadius: "8px", textTransform: "capitalize" }}>$ Continue</Button>
                      <Button variant="contained" style={{ color: "#fff", backgroundColor: "#06487E", borderRadius: "8px", textTransform: "capitalize" }}><ChatIcon />  Contact Me</Button>
                    </Stack> */}
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  {serviceData?.standard_service_price !== "0.00" && serviceData?.standard_service_price !== null && serviceData?.standard_service_price !== 0 ?
                    <Box className="basic-step-first-container">
                      <Box className="top-view">
                        <Box>
                          <Typography className="font-16">
                            {serviceData?.standard_price_scope?.title}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className="font-22" sx={{ textWrap: 'nowrap' }}>$ {serviceData?.standard_service_price}</Typography>
                        </Box>
                      </Box>
                      <Box mt={2}>
                        <Typography className="font-14">
                          {serviceData?.standard_price_scope?.description}
                        </Typography>
                      </Box>

                      <Box mt={2} className="day-delivery">
                        <Typography className="font-12">
                          {serviceData?.standard_price_scope?.deliveryDays} Days Delivery
                        </Typography>
                        <Typography className="font-12" ml={2}>
                          {serviceData?.standard_price_scope?.revisions} Revisions
                        </Typography>
                      </Box>

                      <Box class="basic-check-points">
                        <CheckIcon />
                        <Typography className="check-text font-14">
                          {serviceData?.standard_price_scope?.includedAdditionalService}
                        </Typography>
                      </Box>
                      {/* <Stack direction="column" spacing={1} mt={2}>
                        <Button variant="contained" style={{ color: "#fff", borderRadius: "8px", textTransform: "capitalize" }}>$ Continue</Button>
                        <Button variant="contained" style={{ color: "#fff", backgroundColor: "#06487E", borderRadius: "8px", textTransform: "capitalize" }}><ChatIcon />  Contact Me</Button>
                      </Stack> */}
                    </Box> :
                    <Typography textAlign={'center'}>No Plan</Typography>}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  {serviceData?.standard_service_price !== "0.00" && serviceData?.standard_service_price !== null && serviceData?.standard_service_price !== 0 ?
                    <Box className="basic-step-first-container">
                      <Box className="top-view">
                        <Box>
                          <Typography className="font-16">
                            {serviceData?.advacend_price_scope?.title}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className="font-22" sx={{ textWrap: 'nowrap' }}>$ {serviceData?.advacend_service_price}</Typography>
                        </Box>
                      </Box>
                      <Box mt={2}>
                        <Typography className="font-14">
                          {serviceData?.advacend_price_scope?.description}
                        </Typography>
                      </Box>

                      <Box mt={2} className="day-delivery">
                        <Typography className="font-12">
                          {serviceData?.advacend_price_scope?.deliveryDays} Days Delivery
                        </Typography>
                        <Typography className="font-12" ml={2}>
                          {serviceData?.advacend_price_scope?.revisions} Revisions
                        </Typography>
                      </Box>

                      <Box class="basic-check-points">
                        <CheckIcon />
                        <Typography className="check-text font-14">
                          {serviceData?.advacend_price_scope?.includedAdditionalService}
                        </Typography>
                      </Box>
                      {/* <Stack direction="column" spacing={1} mt={2}>
                        <Button variant="contained" style={{ color: "#fff", borderRadius: "8px", textTransform: "capitalize" }}>$ Continue</Button>
                        <Button variant="contained" style={{ color: "#fff", backgroundColor: "#06487E", borderRadius: "8px", textTransform: "capitalize" }}><ChatIcon />  Contact Me</Button>
                      </Stack> */}
                    </Box>
                    :
                    <Typography textAlign={'center'}>No Plan</Typography>}
                </CustomTabPanel>
              </Box>
            </Box>
            {serviceData?.document && serviceData?.document.length > 0 ? (<>
              {
                serviceData?.document?.map((val, ind) => {
                  return (
                    <Card className="pd-action-card" key={ind} >
                      <CardHeader
                        avatar={
                          <Avatar aria-label="recipe" className="bg-blue-900" variant="square" sx={{ width: '48px', height: '48px', borderRadius: '12px' }}>
                            <AssignmentIcon />
                          </Avatar>
                        }
                        action={
                          <>
                            <Box className="btn-wrap">
                              <Stack direction="row" spacing={1.5} alignItems={'center'}>
                                <IconButton aria-label="View" size="large" className='square-icon-btn' onClick={() => viewDoc(val)} >
                                  <RemoveRedEyeIcon />
                                </IconButton>
                              </Stack>
                            </Box>
                          </>
                        }
                        onClick={() => viewDoc(val)}
                        title={val.original_name}
                        subheader={
                          <Tooltip title={val?.original_name}>
                            <span>{val?.original_name}</span>
                          </Tooltip>
                        }
                      />
                    </Card>
                  );
                })
              }
            </>
            ) : (
              <Typography textAlign={'center'}>No documents availiable to view</Typography>
            )}
            {view &&
              <Modal
                className="projectDocumentsModal"
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                fullWidth={true}
              >
                <Box sx={styleModal}>
                  <OverlayScrollbarsComponent defer
                    style={{ width: '100%', height: '100%' }}
                  >
                    {file && type ? (
                      // <FileViewer fileType={type} filePath={file} onError={e => console.error("FileViewer Error:", e)} />
                      <object
                        data={file}
                        type="application/pdf"
                        style={{ width: '100%', height: '100%' }}
                      >
                        <p style={{ textAlign: 'center' }}>
                          Your browser does not support PDFs. <a href={file} target="_blank" style={{ color: 'lightgreen' }}>Download the PDF</a>.
                        </p>
                      </object>
                    ) : (
                      <Typography>Error: File not available.</Typography>
                    )}
                  </OverlayScrollbarsComponent>
                </Box>
              </Modal>
            }
          </Grid>

        </Grid>
      </Box>
    </Box>
  );
};


export default ReviewServicePost;
