import React from 'react'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import './ServiceCard.css'
import { PortfolioPic1 } from '../../../Components/Images/Images';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VideoThumbnail from 'react-video-thumbnail';

const ServiceCard = ({ color, image, profileImage, title, description, link, userId }) => {
    const navigate = useNavigate();
    const isImage = /\.(jpeg|jpg|png)$/i.test(image);
    const isVideo = /\.(mp4)$/i.test(image);
    const proposer =true;

    let profileAvatar = profileImage
        ? (profileImage instanceof File
            ? URL.createObjectURL(profileImage)
            : `${process.env.REACT_APP_IMAGE_URL}${profileImage}`
        )
        : "";
        
    const handleClick = () => {
        navigate(link, {
            state: { image: profileAvatar, title, description, proposer, user_id: userId },
        },
    );
    };

    return (
        <Box className="sp-service-card" onClick={handleClick}>
            <Box className="sp-img-wrap" >
            {isImage && (
                <Box className="sp-img" sx={{ backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}${image})` }}></Box>
            )}
            {isVideo && (
                <Box className="sp-img">
                    <div className="video-thumbnail">
                        <VideoThumbnail
                            videoUrl={`${process.env.REACT_APP_IMAGE_URL}${image}`} // Ensure the correct URL is used for videos
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                        <div className="play-icon-overlay">
                            <PlayCircleOutlineIcon fontSize="large" />
                        </div>
                    </div>
                </Box>
            )}
           </Box>
            <Box className="sp-title" px={1.8} pt={{ xs: 0.5, sm: 1 }}>
                <Typography className="hc-title" sx={{ color: color }} >{title}</Typography>
            </Box>
        </Box>
    )
}

export default ServiceCard;

