import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { login as userLogin } from "./features/auth/authSlice";

function AppGlobalState() {

    const login = useSelector((state) => state.auth.login);
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    useEffect(() => {
        const _login = localStorage.getItem("login");
        // checks if localstorage and redux store are in sync, where localstorage is given priority
        if (_login && !user) {
            dispatch(userLogin(JSON.parse(localStorage.getItem("user_data"))));
        }

        //eslint-disable-next-line
    }, []);

    return null
}

export default AppGlobalState