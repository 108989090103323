import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Avatar, Typography, Stack, Paper, IconButton, Popover } from "@mui/material";
import { Info, RemoveRedEye } from '@mui/icons-material';
import { GetApi, postApi } from "../../Api/Api";
import ChatInput from "./ChatInput";
import { User } from "../../Components/Images/Images";

function ChatContainer({ currentChat, socket, logedinUserData }) {
  const navigate = useNavigate()
  const [chatName, setChatName] = useState(null);
  const [chatAvtar, setChatAvtar] = useState(null);
  const [messages, setMessages] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [isTyping, setIsTyping] = useState({
    status: false,
    username: "",
  });
  const [membersAnchorEl, setMembersAnchorEl] = useState(null);
  const showMembers = Boolean(membersAnchorEl);

  useEffect(() => {
    if (currentChat?.contract_data) {
      setChatName(currentChat?.contract_data?.title);
      setChatAvtar(null);
    } else {
      const oppUser = currentChat?.user_details && currentChat?.user_details.length > 0 ?
        currentChat?.user_details.filter((row) => row.id != logedinUserData?.id) : []
      if (oppUser && oppUser.length > 0) {
        setChatName(`${oppUser[0]?.first_name} ${oppUser[0]?.last_name}`);
        setChatAvtar(null);
      }
    }
  }, [currentChat]);

  //  Getting current chat messages
  useEffect(() => {
    const getCurrentChatMessages = async () => {
      const response = await GetApi("/chat/spMessage/" + currentChat.id);
      setMessages(response?.data?.data ?? []);
    };
    if (currentChat) {
      socket.emit("sp-join-chat", currentChat?.id);
      getCurrentChatMessages();
    }
  }, [currentChat]);

  //  Getting receive message from server
  useEffect(() => {
    const handleReceiveMessage = (data) => {
      if (
        currentChat?.id === data?.chat.id
      ) {
        setArrivalMessage({
          fromSelf: false,
          ...data,
        });
        // message is marked as seen when the chat is open
        socket.emit('sp-seen-msg', { chatId: data?.chat.id, userId: logedinUserData?.id });
        // scrollToBottom()
      }
    };

    const handleTyping = (data) => {
      setIsTyping({
        status: true,
        username: data.username,
      });
    };

    const handleStopTyping = (data) => {
      setIsTyping({
        status: false,
        username: "",
      });
    };


    if (socket) {
      socket.on('sp-message-recieved', handleReceiveMessage);
      socket.on("sp-typing", handleTyping);
      socket.on("sp-stop-typing", handleStopTyping);
    }

    return () => {
      if (socket) {
        socket.off('sp-message-recieved', handleReceiveMessage);
        socket.off("sp-typing", handleTyping);
        socket.off("sp-stop-typing", handleStopTyping);
      }
    };

  }, [currentChat]);

  useEffect(() => {
    groupMessagesByDate();
    scrollToBottom(); // scroll to bottom when new message arrives
  }, [messages]);


  useEffect(() => {
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);


  /**
   * Group messages by date
   * @returns {object} messages grouped by date
   */
  const groupMessagesByDate = () => {
    const groupedMessages = {};

    messages?.length > 0 && messages.forEach((message) => {
      const messageDate = new Date(message.createdAt).toISOString().split("T")[0];
      if (!groupedMessages[messageDate]) {
        groupedMessages[messageDate] = [];
      }
      groupedMessages[messageDate].push(message);
    });
    return groupedMessages;
  };

  const groupedMessages = groupMessagesByDate();

  /**
   *  sends message to the server, also send message to the socket
   * @param {string} msg  message to be sent
   */
  const handleSendMsg = async (msg) => {
    if (msg === "") {
      // if message is empty extra check
      return;
    }

    //add other users ids to the views array  meaning they have not seen messages
    const views = currentChat?.users?.filter(user_id => user_id !== logedinUserData?.id);

    const response = await postApi("/chat/spMessage", {
      sender: logedinUserData?.id,
      chat: currentChat?.id,
      content: msg,
      views: views,
    });

    socket.emit("sp-new-message", response.data.data);
    socket.emit("sp-refresh-unread", logedinUserData?.id);
    const msgs = [...messages];
    msgs.push({ fromSelf: true, ...response.data.data });
    setMessages(msgs);
  };

  /**
   *  converts date format to human readable format
   * @param {string} inputDate  date to be formatted in yyyy-mm-dd format
   * @returns {string} formatted date eg: 12th October 2021
   */
  const convertDateFormat = (inputDate) => {
    // Split the input date into day, month, and year
    const formattedDate = new Date(`${inputDate}`);
    // Get the current date and create a date for yesterday
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Check if the input date is the same as today's date
    if (formattedDate.toDateString() === today.toDateString()) {
      return "Today";
    }
    // Check if the input date is the same as yesterday's date
    else if (formattedDate.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    }
    // If it's not today or yesterday, format and return the full date
    else {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return formattedDate.toLocaleDateString(undefined, options);
    }
  };

  /**
   * scrolls to the bottom of the chat
   */
  const scrollToBottom = () => {
    const chat = document.querySelector(".ctMsgArea");
    // chat.scrollTop = chat.scrollHeight;
    chat?.scrollTo({
      top: chat.scrollHeight,
      // behavior: "smooth",
    });
  };

  /**
   * if use is DIC open the porject details in DIC view else open in Investor view
   */
  const viewProject = () => {

  };

  const showMembersPopover = (event) => {
    setMembersAnchorEl(event.currentTarget);
  }
  const closeMembersPopover = () => {
    setMembersAnchorEl(null);
  }

  const linkToProject = <Typography onClick={viewProject} component={'span'} style={{ fontSize: '14px', fontWeight: 500, textDecoration: 'underline', cursor: 'pointer' }}>here.</Typography>;
  return (
    <>
      {/* ctUNav */}
      {currentChat === undefined ? (
        <Typography variant="h6" my={5} textAlign={"center"}>
          Select a chat to start messaging
        </Typography>
      ) : (
        <>
          <Paper elevation={2} className="ctUNav">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box className="ctNav-box">
                <Box className="">
                  <Avatar
                    src={`${process.env.REACT_APP_IMAGE_URL}${chatAvtar}`}
                    alt={chatName}
                  />
                </Box>
                <Box className="ctUIts">
                  <Typography className="ctUImage-title">
                    {chatName}
                  </Typography>
                  <Box>
                    <Typography className="ctUImage-subtitle">
                      {currentChat?.contract_data &&
                        <>Members: {currentChat?.user_details?.length}
                          <IconButton sx={{ width: '16px', height: '16px', marginLeft: '6px' }} onClick={showMembersPopover}><Info sx={{ width: '14px', height: '14px', color: 'white' }} /></IconButton></>
                      }
                      {isTyping.status && isTyping.username &&
                        <>
                          {currentChat?.contract_data && <span className="username">&nbsp;{isTyping.username}</span>} is typing
                          <svg height="8" width="24" className="typing-indicator">
                            <circle className="dot" cx="5" cy="4" r="2" style={{ fill: 'grey' }} />
                            <circle className="dot" cx="12" cy="4" r="2" style={{ fill: 'grey' }} />
                            <circle className="dot" cx="20" cy="4" r="2" style={{ fill: 'grey' }} />
                          </svg>
                        </>
                      }
                    </Typography>
                    <Popover open={showMembers} anchorEl={membersAnchorEl}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                      onClose={closeMembersPopover}
                      sx={{}}
                    >
                      <Box sx={{ p: 2, backgroundColor: '#C5E4FF' }}>
                        {
                          currentChat?.user_details?.map((user, index) => {
                            return (
                              <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Avatar sx={{ width: '24px', height: '24px', mr: 1 }} ></Avatar>
                                  <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>{user?.member_id}</Typography>
                                </Box>
                                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>{user?.role}</Typography>
                              </Box>
                            )
                          })
                        }
                      </Box>
                    </Popover>
                  </Box>
                </Box>
              </Box>
              <Stack
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                spacing={2}
                className=""
              >
                <Box className="ctEyes">
                  <IconButton aria-label="view project" onClick={viewProject}>
                    <RemoveRedEye sx={{ color: "#ffffff" }} />
                  </IconButton>
                </Box>
              </Stack>
            </Stack>
          </Paper>

          <Box className="ctMsgArea chatBoxContainer" id="chat-scroller-area">
            {Object.keys(groupedMessages).map((date) => (
              <Box key={date} className="ctMsgDate">
                <Box className="ctDateChip">
                  <Typography>
                    {convertDateFormat(date)}
                  </Typography>
                </Box>

                {groupedMessages[date].map((message, index) => {

                  if (message?.sender == logedinUserData?.id) {
                    message.fromSelf = true;
                  }

                  return (
                    // Document updated message
                    message.type === "document_updated" ? <Box className="ctAlertMsgBox">
                      {
                        message.fromSelf ?
                          <>
                            <Avatar src={User} ></Avatar>
                            <Typography className="ctMsgText" > You updated documents. View documents {linkToProject} </Typography>
                          </>
                          :
                          <>
                            <Avatar src={message?.user_details?.persona_picture ?? User} ></Avatar>
                            <Typography className="ctMsgText" > Proposer has updated documents. View documents {linkToProject} </Typography>
                          </>
                      }
                    </Box>

                      :
                      // New Member joined or New chat created
                      message.type === "new_chat" || message.type === "new_user" ?
                        <Box className="ctAlertMsgBox">
                          <Typography className="ctMsgText" > {message.content}</Typography>
                        </Box>

                        :

                        <Box
                          key={index}
                          className={`ctMsgBox ${message.fromSelf ? "selfBox" : ""}`}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Avatar className="profile">{message?.user_details?.first_name[0] + message?.user_details?.last_name[0]}</Avatar>
                          <Box className={`ctMsg ${message.fromSelf ? "self" : ""}`}>
                            <Typography
                              className="ctMsgText"
                              // updated code to handle big string or message, break to next if message length more than 32
                              // preserves the spaces and line breaks by whiteSpace: 'pre-line'
                              style={{ whiteSpace: 'pre-line', overflowWrap: 'anywhere', textAlign: 'left', width: message.content.length > 32 ? '276px' : '' }}
                            >
                              {message.content}
                            </Typography>
                          </Box>
                          <Typography className="ctMsgTime">
                            {!message.fromSelf && message?.user_details?.first_name + " " + message?.user_details?.last_name + ' - '}
                            {new Date(message.createdAt).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </Typography>
                        </Box>

                  )
                })}

              </Box>
            ))}
          </Box >
          <ChatInput handleSendMsg={handleSendMsg} currentChat={currentChat} socket={socket} logedinUserData={logedinUserData} />
        </>
      )
      }
    </>
  );
}

export default ChatContainer;
