import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography, Modal } from '@mui/material';
import { ArrowForward, Close } from '@mui/icons-material';
import { InvestorIcon, wallet } from '../../Components/Images/Images';
import './Launch.css';
import WaitlistPopup from '../../WaitListPopUps/WaitlistPopup';

const StyledArrowForwardIcon = styled(ArrowForward)(({ theme }) => ({
  fontSize: '1.2rem',
  verticalAlign: 'middle',
  marginLeft: '5px',
}));

const Launch = ({ handleCancel, openModal }) => {
  const [open, setOpen] = useState(false);
  const [selectedBox, setSelectedBox] = useState('Investor');
  const [openWaitListModal, setOpenWaitListModal] = useState(false);

  const navigate = useNavigate();

  const handleBoxClick = (box) => {
    if (selectedBox === box) {
      if (selectedBox === 'Investor') {
        navigate('/onboarding')
      } else {
        // window.open('https://raceecosystem.com/', '_blank'); // Opens the external site in a new tab
        setOpenWaitListModal(true);
      }
    } else {
      setSelectedBox(box);
    }
  };

  const handleClose = () => {
    setOpen(false);
    handleCancel();
    window.scrollTo(0, 0);
  };

  const handleCloseWaitList = () => {
    setOpenWaitListModal(false);
  }

  useEffect(() => {
    if (openModal) {
      setOpen(true);
      document.documentElement.scrollTop = 0;
    }
  }, [openModal]);


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    borderRadius: '24px',
    maxWidth: '740px',
    width: '100%',
    padding: ' 32px 50px 50px 50px',
    background: 'var(--blueGray-900)',

  };

  return (
    <>
      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="investor-popup-main"
        >
          <Box sx={style}>
            <Box className="investor-close-icon">
              <Close onClick={handleClose} />
            </Box>
            <Typography id="modal-modal-title" variant="h6" component="h2" className="heading font-36">
              Select your role
            </Typography>
            <Box className="investor-box">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sm={6}>
                  <Box className={`box-first ${selectedBox === 'Investor' ? 'selected' : ''}`} onClick={() => handleBoxClick('Investor')}>
                    <Box className="top-view">
                      <Box className="left-icon">
                        <img src={wallet} alt="" />
                      </Box>
                    </Box>
                    <Typography className="popup-disc font-28">I’m an Investor</Typography>
                    <Typography className="popup-disc font-18">
                      {/* <Link to="/onboarding"> */}
                      Sign up as investor
                      <StyledArrowForwardIcon />
                      {/* </Link> */}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <Box className={`box-second ${selectedBox === 'NotInvestor' ? 'selected' : ''}`} onClick={() => handleBoxClick('NotInvestor')}>
                    <Box className="top-view">
                      <Box className="left-icon">
                        <img src={InvestorIcon} alt="" />
                      </Box>
                    </Box>
                    <Typography className="popup-disc font-28">I’m not an Investor</Typography>
                    <Box >
                      <Typography className="popup-disc font-18">
                        {/* <Link to="https://raceecosystem.com/" target="_blank"> */}
                        Join waitlist
                        <StyledArrowForwardIcon />
                        {/* </Link> */}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
      </Box>

      {openWaitListModal ?
        <WaitlistPopup open={openWaitListModal} onClose={handleCloseWaitList} handleCloseRoleModal={handleClose} />
        : null
      }
    </>
  );
};

export default Launch;
