// config.js

const config = {
    DOCUMENT: {
        /**
         * Maximum total number of PDF documents allowed
         */
        MAX_TOTAL_NUMBER: 10,
        /**
         * Maximum size of a single PDF file in bytes (2MB)
         */
        MAX_FILE_SIZE: 2 * 1024 * 1024,
        /**
         * Maximum total size of all PDF files combined in bytes (10MB)
         */
        MAX_TOTAL_SIZE: 10 * 1024 * 1024
    },
    IMAGE: {
        /**
         * Maximum total number of images allowed
         */
        MAX_TOTAL_NUMBER: 20,
        /**
         * Maximum size of a single image file in bytes (12MB)
         */
        MAX_FILE_SIZE: 12 * 1024 * 1024,
        /**
         * Maximum total size of all images combined in bytes (50MB)
         */
        MAX_TOTAL_SIZE: 50 * 1024 * 1024
    },
    VIDEO: {
        /**
         * Maximum total number of videos allowed
         */
        MAX_TOTAL_NUMBER: 5,
        /**
         * Maximum size of a single video file in bytes (50MB)
         */
        MAX_FILE_SIZE: 50 * 1024 * 1024,
        /**
         * Maximum total size of all videos combined in bytes (50MB)
         */
        MAX_TOTAL_SIZE: 50 * 1024 * 1024
    },
    CONTRACT_STATUS: {
        1: 'Pending',
        2: 'Accepted',
        3: 'In Progress',
        4: 'Missing Details',
        5: 'Delivered',
        6: 'Completed',
        7: 'Cancelled',
        CONTRACT_STATUS_COLOR : {
            'Pending': '#1eb2e3',
            'Accepted': '#035da9',
            'In Progress': '#34D399',
            'Missing Details': '#f5ee22',
            'Delivered': '#423c3c',
            'Completed': '#309c22',
            'Cancelled': '#e30b1d',
        }
    },
    OFFER_STATUS: {
        1: 'Pending',
        2: 'Confirmed',
        3: 'Cancelled',
        CONTRACT_STATUS_COLOR : {
            1: '#1eb2e3',
            2: 'green',
            3: 'red',
        }
    },
    SERVICE_FEE: {
        CLIENT: 10,
        SERVICE_PROVIDER: 10,
        SERVICE_PROVIDER_PROFILE: 10,
    },
    TOP_RATAED: {
        ratingGrater: 3.5,
        reviewGrater: 20
    }
};

export default config;
