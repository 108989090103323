import React, { useState, useEffect } from "react";
import {
  Box,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import "./MyOrders.css";
import JobList from "./JobList";
import { GetApi } from "../../../Api/Api";
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";

const ManageOrders = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');
  const [value, setValue] = React.useState(tab ? Number(tab) : 0);
  const [jobList, setJobList] = useState([]);
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ itemsPerPage, setItemsPerPage ] = useState(5);
  const [ paginationData, setPaginationData ] = useState({
    "page": 1,
    "pageSize": 0,
    "totalCount": 0,
    "totalPages": 0
  });
  const userData = getUserDataFromLocalStorage();

  const handleChange = (event, newValue) => {
    setCurrentPage(1);
    setValue(newValue);
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 , pl: 0}}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const fetchData = async () => {
    let status = '1,2,3';
    if (value === 0) status = '1,2,3';
    if (value === 1) status = 4;
    if (value === 2) status = 5;
    if (value === 3) status = 6;
    if (value === 4) status = 7;
    let url = `/services/getContract?client_id=${userData.id}&status=${status}&page=${currentPage}&rowperpage=${itemsPerPage}`;
    if (value === 5) {
      url = `/services/getContract?client_id=${userData.id}&page=${currentPage}&rowperpage=${itemsPerPage}`;
    }
    GetApi(url).then((res) => {
      if (res?.status === 200) {
        if (res.data && res.data.data && res.data.data.length > 0) {
          setJobList(res.data.data);
          setPaginationData(res.data.pagination)
        } else {
          setJobList([]);
          setPaginationData({
            "page": 1,
            "pageSize": 0,
            "totalCount": 0,
            "totalPages": 0
          })
        }
      }
    })
  };

  useEffect(() => {
    fetchData();
  }, [value, currentPage]);

  return (
    <>
      <Box className="my-job-container">
        <Typography className="font-28">Manage Orders</Typography>
        <Box className="">
          <Box sx={{ width: "100%" }}>
            <Box className="my-job-tab-container gradient-tab-text" sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Active"
                  className={value === 0 ? "st-active" : "st-inactive"}
                  {...a11yProps(0)}
                />
                <Tab label="Missing Details" className={value === 1 ? "st-active" : "st-inactive"} {...a11yProps(1)} />
                <Tab label="Delivered" className={value === 2 ? "st-active" : "st-inactive"} {...a11yProps(2)} />
                <Tab label="Completed" className={value === 3 ? "st-active" : "st-inactive"} {...a11yProps(3)} />
                <Tab label="Cancelled" className={value === 4 ? "st-active" : "st-inactive"} {...a11yProps(4)} />
                <Tab label="All" className={value === 5 ? "st-active" : "st-inactive"} {...a11yProps(5)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <JobList Title="Active Orders" fetchData={fetchData} jobList={jobList} paginationData={paginationData} currentPage={currentPage} setCurrentPage={setCurrentPage} setItemsPerPage={setItemsPerPage}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <JobList Title="Pending Orders" fetchData={fetchData} jobList={jobList} paginationData={paginationData} currentPage={currentPage} setCurrentPage={setCurrentPage} setItemsPerPage={setItemsPerPage} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <JobList Title="Missing Detail Orders" fetchData={fetchData} jobList={jobList} paginationData={paginationData} currentPage={currentPage} setCurrentPage={setCurrentPage} setItemsPerPage={setItemsPerPage} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <JobList Title="Delivered Orders" fetchData={fetchData} jobList={jobList} paginationData={paginationData} currentPage={currentPage} setCurrentPage={setCurrentPage} setItemsPerPage={setItemsPerPage} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <JobList Title="Completed Orders" fetchData={fetchData} jobList={jobList} paginationData={paginationData} currentPage={currentPage} setCurrentPage={setCurrentPage} setItemsPerPage={setItemsPerPage} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
              <JobList Title="Cancelled Orders" fetchData={fetchData} jobList={jobList} paginationData={paginationData} currentPage={currentPage} setCurrentPage={setCurrentPage} setItemsPerPage={setItemsPerPage} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={6}>
              <JobList Title="All Orders" fetchData={fetchData} jobList={jobList} paginationData={paginationData} currentPage={currentPage} setCurrentPage={setCurrentPage} setItemsPerPage={setItemsPerPage} />
            </CustomTabPanel>
          </Box>
        </Box>
      </Box>

    </>
  );
};

export default ManageOrders;
