import React, { useEffect, useState } from "react";
import { Avatar, Box, Card, CardHeader, Grid, Modal, Typography, Stack, IconButton, CircularProgress, Backdrop } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AssignmentIcon from "@mui/icons-material/Assignment";
// import FileViewer from "react-file-viewer";
// import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { ArrowDropDown, Edit } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetApiParam, updateApi } from "../Api/Api";
import DropdownBox from "../InvestorOnly/Component/DropdownBox";
import { updateStep } from "../features/auth/tooltipSlice";
import { CATEGORY_TYPE_ID } from "../constants";

export default function Templates({ documentsData, ownerId, assetId, editable, projectStatus, investment_type_id, selected_templates, isLoan, isParcel, isSale, isFraction, isFundFraction }) {
    const [showMore, setShowMore] = useState(false);
    const [tolPosition, setTolPosition] = useState(0)
    // const isXs = useMediaQuery('(max-width:600px)');
    const step = useSelector((state) => {
        if (isLoan) {
            return state.tooltipSlice.loan_step_number
        } else if (isParcel) {
            return state.tooltipSlice.parcel_step_number
        } else if (isSale) {
            return state.tooltipSlice.sale_step_number
        } else if (isFraction) {
            return state.tooltipSlice.re_step_number
        } else if (isFundFraction) {
            return state.tooltipSlice.funds_step_number
        }
    }
    );
    const isActive = useSelector((state) => state.tooltipSlice.active_how_it_work);

    const [loadingTooltip, setLoadingToolTip] = useState(false);
    const [loadingApi, setLoadingApi] = useState(false);

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // const localData 
    const localData = localStorage.getItem("user_data")
        ? JSON.parse(localStorage.getItem("user_data"))
        : ""; //Getting  user data from local storage


    const [view, setView] = useState(false);
    const [file, setFile] = useState("");
    const [type, setType] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState([]);
    const [viewedTemplates, setViewedTemplates] = useState([]);
    const [loading, setLoading] = useState(false)
    const [current, setCurrent] = useState('');

    /**
   * open modal to display doc content
   * @param {Array} documentdata 
   * @returns {void}
   */
    const viewDoc = async (documentdata, mode, index) => {
        setCurrent(documentdata)
        setLoading(true);

        try {
            // Check if the PDF has been viewed before
            const isViewed = viewedTemplates?.filter((item) => item?.templateId === documentdata?.templateId);

            if (isViewed.length) {
                setFile(isViewed[0]?.templateUrl);
                setType('pdf');
                // PDF has been viewed before, no need to fetch again
                setLoading(false);
                setOpenModal(true);
                setView(true);
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/docusign/viewTemplate?templateId=${documentdata?.templateId}`);

            const templateBuffer = await response.arrayBuffer();
            const templateBlob = new Blob([templateBuffer], { type: 'application/pdf' });
            const templateUrl = URL.createObjectURL(templateBlob);
            setFile(templateUrl);
            setType('pdf');

            // Mark the template as viewed and store in local state
            setViewedTemplates(prevViewedTemplates => [
                ...prevViewedTemplates,
                { templateId: documentdata.templateId, templateUrl }
            ]);

            setOpenModal(true);
            setView(true);
            setLoading(false);
            setCurrent('')
        } catch (error) {
            setLoading(false);
            console.log('error', error);
        }


    };

    useEffect(() => {
        setData(selected_templates);
    }, [selected_templates]);

    /**
     * Onclose function for Modal
     * @returns void
     */
    const handleClose = () => {
        setOpenModal(false);
        setView(false);
    };

    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    };

    // Helper function to truncate a string and add an ellipsis if it's too long
    const truncateString = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.substring(0, maxLength) + "...";
        }
        return str;
    };

    // handle show more button
    const handleShowMore = () => {
        setShowMore(true);
    }

    const handleDocumentUpload = () => {
        const data = {
            "investment_type_id": investment_type_id,
            "asset_signing_document": documentsData,
            "selected_templates": selected_templates,
            "assetId": assetId
            // Add more data as needed
        };
        if (isLoan) {
            data.category_id = CATEGORY_TYPE_ID.WATCH
        } else if (isParcel) {
            data.category_id = CATEGORY_TYPE_ID.ART
        } else if (isSale) {
            data.category_id = CATEGORY_TYPE_ID.ART
        } else if (isFraction) {
            data.category_id = CATEGORY_TYPE_ID.REAL_ESTATE
        } else if (isFundFraction) {
            data.category_id = CATEGORY_TYPE_ID.FUNDS
        }

        navigate('/upload-agreement-documents', { state: data, replace: true })
    }


    useEffect(() => {
        if ((tolPosition > 16 || tolPosition <= 15) && isLoan) {
            setLoadingToolTip(false)
        } else if ((tolPosition > 11 || tolPosition <= 10) && isParcel) {
            setLoadingToolTip(false)
        } else if ((tolPosition > 10 || tolPosition <= 9) && isSale) {
            setLoadingToolTip(false)
        } else if ((tolPosition > 13 || tolPosition <= 12) && isFraction) {
            setLoadingToolTip(false)
        } else if ((tolPosition > 11 || tolPosition <= 10) && isFundFraction) {
            setLoadingToolTip(false)
        }
    }, [tolPosition])

    useEffect(() => {
        async function getStep() {
            try {
                setLoadingApi(true)
                const data = await GetApiParam("/user/currentTooltipPosition", { "user_id": localData.id });
                if (data?.data?.data && isActive) {
                    if (data.data?.data?.loan_step_number == 16 && isLoan) {
                        setTolPosition(data.data?.data?.loan_step_number == null ? 0 : data.data.data.loan_step_number)
                        setLoadingToolTip(true)
                        scrollToPosition("scroll-to-template");
                    } else if ((data.data?.data?.parcel_step_number == 11) && isParcel) {
                        setTolPosition(data.data?.data?.parcel_step_number == null ? 0 : data.data.data.parcel_step_number)
                        setLoadingToolTip(true)
                        scrollToPosition("scroll-to-template");
                    }
                    else if ((data.data?.data?.sale_step_number == 10) && isSale) {
                        setTolPosition(data.data?.data?.sale_step_number == null ? 0 : data.data.data.sale_step_number)
                        setLoadingToolTip(true)
                        scrollToPosition("scroll-to-template");
                    }
                    else if ((data.data?.data?.re_step_number == 13) && isFraction) {
                        setTolPosition(data.data?.data?.re_step_number == null ? 0 : data.data.data.re_step_number)
                        setLoadingToolTip(true)
                        scrollToPosition("scroll-to-template");
                    } else if ((data.data?.data?.funds_step_number == 11) && isFundFraction) {
                        setTolPosition(data.data?.data?.funds_step_number == null ? 0 : data.data.data.funds_step_number)
                        setLoadingToolTip(true)
                        scrollToPosition("scroll-to-template");
                    }
                }
                setLoadingApi(false)
            } catch (error) {
                console.log('error', error)
                setLoadingApi(false)
            }
        }
        // Call getStep initially
        const timer = setTimeout(() => {
            if (localData && localData?.id) {
                getStep();
            }
        }, 100);
        return () => clearTimeout(timer)
    }, [step, isActive])

    async function setStep(tolPositionData) {
        try {
            let body = { "loan_step_number": tolPositionData };
            if (isParcel) {
                body = { "parcel_step_number": tolPositionData };
            } else if (isSale) {
                body = { "sale_step_number": tolPositionData };
            } else if (isFraction) {
                body = { "re_step_number": tolPositionData };
            } else if (isFundFraction) {
                body = { "funds_step_number": tolPositionData };
            }
            const data = await updateApi("/user/updateUser/" + localData?.id, body);
            if (data?.data?.data) {
                setTolPosition(0)
                setLoadingToolTip(false)
                // if (data.data?.data?.loan_step_number == 16 && isLoan) {
                //     setTolPosition(data.data?.data?.loan_step_number == null ? 0 : data.data.data.loan_step_number)
                //     setLoadingToolTip(true)
                // } else if ((data.data?.data?.parcel_step_number == 11) && isParcel) {
                //     setTolPosition(data.data?.data?.parcel_step_number == null ? 0 : data.data.data.parcel_step_number)
                //     setLoadingToolTip(true)
                // }
                // else if ((data.data?.data?.sale_step_number == 10) && isSale) {
                //     setTolPosition(data.data?.data?.sale_step_number == null ? 0 : data.data.data.sale_step_number)
                //     setLoadingToolTip(true)
                // }
                // else if ((data.data?.data?.re_step_number == 13) && isFraction) {
                //     setTolPosition(data.data?.data?.re_step_number == null ? 0 : data.data.data.re_step_number)
                //     setLoadingToolTip(true)
                // } else if ((data.data?.data?.funds_step_number == 11) && isFundFraction) {
                //     setTolPosition(data.data?.data?.funds_step_number == null ? 0 : data.data.data.funds_step_number)
                //     setLoadingToolTip(true)
                // }
            }
        } catch (error) {
            console.log('error', error)
        }
    }
    const scrollToPosition = (findId) => {
        setTimeout(() => {
            const element = document.getElementById(`${findId}`);
            if (element) {
                const offset = 0;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            }
        }, 200);
    }
    const handleTolPosition = async (data) => {
        // setTolPosition(pre => pre + data)
        await setStep(tolPosition + data)
        if (isLoan)
            dispatch(updateStep({ key: 'loan_step_number', value: tolPosition + data }));
        if (isSale)
            dispatch(updateStep({ key: 'sale_step_number', value: tolPosition + data }));
        if (isParcel)
            dispatch(updateStep({ key: 'parcel_step_number', value: tolPosition + data }));
        if (isFraction)
            dispatch(updateStep({ key: 're_step_number', value: tolPosition + data }));
        if (isFundFraction)
            dispatch(updateStep({ key: 'funds_step_number', value: tolPosition + data }));

        if (data < 0) {
            scrollToPosition("scroll-to-document");
        } else {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    }

    return (
        <>
            {loadingTooltip ?
                <Box className="pd-sidebar-documents" id="scroll-to-template" mb={3} sx={{
                    zIndex: 97, position: 'relative', backgroundColor: "#06487E",
                    color: "white", borderRadius: "8px",
                    padding: '4px'
                }}>
                    <DropdownBox render={
                        <Box >
                            <Stack
                                className="pd-sidebar-heading"
                                direction="row"
                                spacing={1}
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography className="title" component={"h4"}>
                                    Agreement Default Templates
                                </Typography>

                                {/* Conditionally rendering upload functionality for proposer only*/}
                                {(editable && localData?.id && !projectStatus) && (
                                    <Box>
                                        <Box className="action">
                                            <IconButton aria-label="Uplaod" size="large">
                                                <Edit className="text-white" onClick={handleDocumentUpload} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                )}
                            </Stack>

                            {/* Dynamically rendering documents with viewer  */}

                            {data && data.length > 0 ? (<>
                                {
                                    data?.map((val, ind) => {
                                        if (ind < 5 || showMore) {
                                            const subheaderText = val?.images?.original_name
                                                ? val.images.original_name
                                                : val.name;
                                            const truncatedSubheader = truncateString(subheaderText, 18); // You can change 30 to your desired length
                                            return (
                                                <Card className="pd-action-card" key={ind}>
                                                    <CardHeader
                                                        avatar={
                                                            <Avatar
                                                                aria-label="recipe"
                                                                className="bg-blue-900"
                                                                variant="square"
                                                                sx={{
                                                                    width: "48px",
                                                                    height: "48px",
                                                                    borderRadius: "12px",
                                                                }}
                                                            >
                                                                <AssignmentIcon />
                                                            </Avatar>
                                                        }
                                                        action={
                                                            <>
                                                                <Box className="btn-wrap">
                                                                    <Stack
                                                                        direction="row"
                                                                        spacing={1.5}
                                                                        alignItems={"center"}
                                                                    >
                                                                        {current === val && loading ?
                                                                            <Box textAlign="center">
                                                                                <CircularProgress />
                                                                            </Box>
                                                                            :
                                                                            <IconButton
                                                                                aria-label="View"
                                                                                size="large"
                                                                                className="square-icon-btn"
                                                                                onClick={() => viewDoc(val)}
                                                                            >
                                                                                <RemoveRedEyeIcon />
                                                                            </IconButton>
                                                                        }
                                                                    </Stack>
                                                                </Box>
                                                            </>
                                                        }
                                                        title={truncatedSubheader}
                                                        subheader={'Template'}
                                                    />
                                                </Card>
                                            );
                                        } else {
                                            return null
                                        }
                                    })
                                }
                                {(showMore === false && data?.length > 5) &&
                                    <Stack flexDirection={'row'} justifyContent={'space-between'} mt={2}>
                                        <Stack flexDirection={'row'} justifyContent={'space-around'}>
                                            <Typography>More</Typography>
                                        </Stack>
                                        <Box sx={{ cursor: 'pointer' }} onClick={handleShowMore}>
                                            <ArrowDropDown />
                                        </Box>
                                    </Stack>
                                }
                            </>
                            ) : (
                                <Typography textAlign={"center"}>
                                    No templates available to view
                                </Typography>
                            )}

                        </Box>
                    }
                        setState={handleTolPosition} description={"The agreements for the deal are available for preview, RACE strongly advises to read all agreements before making an offer"} position={"left"}
                    />

                </Box>
                :
                <Box className="pd-sidebar-documents" id="scroll-to-template" mb={3}>
                    <Stack
                        className="pd-sidebar-heading"
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography className="title" component={"h4"}>
                            Agreement Default Templates
                        </Typography>

                        {/* Conditionally rendering upload functionality for proposer only*/}
                        {(editable && localData?.id && !projectStatus) && (
                            <Box>
                                <Box className="action">
                                    <IconButton aria-label="Uplaod" size="large">
                                        <Edit className="text-white" onClick={handleDocumentUpload} />
                                    </IconButton>
                                </Box>
                            </Box>
                        )}
                    </Stack>

                    {/* Dynamically rendering documents with viewer  */}

                    {data && data.length > 0 ? (<>
                        {
                            data?.map((val, ind) => {
                                if (ind < 5 || showMore) {
                                    const subheaderText = val?.images?.original_name
                                        ? val.images.original_name
                                        : val.name;
                                    const truncatedSubheader = truncateString(subheaderText, 18); // You can change 30 to your desired length
                                    return (
                                        <Card className="pd-action-card" key={ind}>
                                            <CardHeader
                                                avatar={
                                                    <Avatar
                                                        aria-label="recipe"
                                                        className="bg-blue-900"
                                                        variant="square"
                                                        sx={{
                                                            width: "48px",
                                                            height: "48px",
                                                            borderRadius: "12px",
                                                        }}
                                                    >
                                                        <AssignmentIcon />
                                                    </Avatar>
                                                }
                                                action={
                                                    <>
                                                        <Box className="btn-wrap">
                                                            <Stack
                                                                direction="row"
                                                                spacing={1.5}
                                                                alignItems={"center"}
                                                            >
                                                                {current === val && loading ?
                                                                    <Box textAlign="center">
                                                                        <CircularProgress />
                                                                    </Box>
                                                                    :
                                                                    <IconButton
                                                                        aria-label="View"
                                                                        size="large"
                                                                        className="square-icon-btn"
                                                                        onClick={() => viewDoc(val)}
                                                                    >
                                                                        <RemoveRedEyeIcon />
                                                                    </IconButton>
                                                                }
                                                            </Stack>
                                                        </Box>
                                                    </>
                                                }
                                                title={truncatedSubheader}
                                                subheader={'Template'}
                                            />
                                        </Card>
                                    );
                                } else {
                                    return null
                                }
                            })
                        }
                        {(showMore === false && data?.length > 5) &&
                            <Stack flexDirection={'row'} justifyContent={'space-between'} mt={2}>
                                <Stack flexDirection={'row'} justifyContent={'space-around'}>
                                    <Typography>More</Typography>
                                </Stack>
                                <Box sx={{ cursor: 'pointer' }} onClick={handleShowMore}>
                                    <ArrowDropDown />
                                </Box>
                            </Stack>
                        }
                    </>
                    ) : (
                        <Typography textAlign={"center"}>
                            No templates available to view
                        </Typography>
                    )}
                </Box>
            }

            <Grid>
                {/* {view && !loading && (
                    <Modal
                        className="projectDocumentsModal"
                        open={openModal}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        fullWidth={true}
                    >
                        <Box sx={styleModal}>
                            <OverlayScrollbarsComponent
                                defer
                                style={{ width: "100%", height: "100%" }}
                            >
                                <Box className="raj">
                                    {<FileViewer fileType={type} filePath={file} />}
                                </Box>
                            </OverlayScrollbarsComponent>
                        </Box>
                    </Modal>
                )} */}
                {(view && !loading && type && file) && (
                    <Modal
                        className="projectDocumentsModal"
                        open={openModal}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        fullWidth={true}
                    >
                        <Box sx={styleModal}>
                            {/* <iframe
                title="PDF Viewer"
                src={file}
                style={{ width: "100%", height: "100%" }}
                className="pdf-viewer-modal"
              ></iframe> */}
                            <object
                                data={file}
                                type="application/pdf"
                                style={{ width: '100%', height: '100%', borderRadius: '12px' }}
                            >
                                <p style={{ textAlign: 'center' }}>
                                    Your browser does not support PDFs. <a href={file} target="_blank" style={{ color: 'lightgreen' }}>Download the PDF</a>.
                                </p>
                            </object>
                        </Box>
                    </Modal>
                )}
            </Grid>
            {loadingTooltip &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => 96 }}
                    open={loadingTooltip}
                >
                </Backdrop>
            }
            {loadingApi &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => 96 }}
                    open={loadingApi}
                >
                    <CircularProgress />
                </Backdrop>
            }
        </>
    );
}
