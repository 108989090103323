//  nftBalanceOf.js
import { readContract } from "@wagmi/core";

/**
 * Fetch user wallet allowance for given spender
 * @param {String} address user wallet address
 * @returns user wallet 
 */
async function nftBalanceOf(address, contract_address, contract_abi) {
    try {
        // Now check for
        const balanceOf = await readContract({
            address: contract_address,
            abi: contract_abi,
            account: address,
            functionName: "balanceOf",
            chainId: 6805,
            args: [
                address
            ]
        })
        return Number(balanceOf);
    } catch (error) {
        console.error('Error fetching allowance:', error);
        throw error; // propagate the error
    }
}

export { nftBalanceOf };