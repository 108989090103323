import React, { useState, useCallback, useEffect } from "react";
import "./WorkHistorySection.css";
import { Box, Pagination, Tab, Tabs, styled, Typography } from "@mui/material";
import PropTypes from "prop-types";
import WorkComponent from "../WorkComponent/WorkComponent";
import { GetApi } from "../../../Api/Api";
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box pt={3}>{children}</Box>}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: "#398CD1",
    borderRadius: "3px 3px 0 0",
    height: "3px",
  },
  "& .MuiTab-root": {
    textTransform: "none",
    color: "#000000",
    fontWeight: 500,
    fontFamily: "Rubik",
    fontSize: "14px",
    lineHeight: "20px",
    minWidth: 0,
    minHeight: 0,
    "&.Mui-selected": {
      color: "#398CD1",
    },
    "&:hover": {
      color: "#398CD1",
    },
  },
}));

function WorkHistorySection({ userId }) {
  const [value, setValue] = React.useState(0);
  const [workHistory, setWorkHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [completeCount, setCompleteCount] = useState(0);
  const [inprogressCount, setInprogressCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    pageSize: 5,
    totalCount: 0,
    totalPages: 0,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const fetchData = useCallback(async () => {
    let status = 6;
    if (value === 0) status = 6;
    if (value === 1) status = 3;
    let url = `/services/getContract?spv_id=${userId}&status=${status}&page=${currentPage}&rowperpage=${itemsPerPage}`;

    GetApi(url).then((res) => {
      if (res?.status === 200) {
        if (res.data && res.data.data && res.data.data.length > 0) {
          setWorkHistory(res.data.data);
          setPaginationData(res.data.pagination);
        } else {
          setWorkHistory([]);
          setPaginationData({
            page: 1,
            pageSize: 5,
            totalCount: 0,
            totalPages: 0,
          });
        }
      }
    });

    GetApi(
      `/services/getContract?spv_id=${userId}&status=6&page=${currentPage}&rowperpage=${itemsPerPage}`
    ).then((res) => {
      if (res?.status === 200) {
        if (res.data && res.data?.pagination) {
          setCompleteCount(res.data?.pagination?.totalCount);
        } else {
          setCompleteCount(0);
        }
      }
    });

    GetApi(
      `/services/getContract?spv_id=${userId}&status=3&page=${currentPage}&rowperpage=${itemsPerPage}`
    ).then((res) => {
      if (res?.status === 200) {
        if (res.data && res.data?.pagination) {
          setInprogressCount(res.data?.pagination?.totalCount);
        } else {
          setInprogressCount(0);
        }
      }
    });
  });

  useEffect(() => {
    fetchData();
  }, [userId, value, currentPage]);

  return (
    <Box sx={{ width: "100%" }}>
      <CustomTabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        className="complete-tab"
        TabIndicatorProps={{
          style: { background: "var(--golden-gradient)" }, // Add your selected color here
        }}
      >
        <Tab
          label={`Completed Projects ${
            completeCount ? `(${completeCount})` : ""
          }`}
          {...a11yProps(0)}
          className={value == 0 ? "text-golden" : "custom-white"}
        />
        <Tab
          label={`In Progress ${inprogressCount ? `(${inprogressCount})` : ""}`}
          {...a11yProps(1)}
          className={value == 1 ? "text-golden" : "custom-white"}
        />
      </CustomTabs>
      <CustomTabPanel value={value} index={0}>
        <CompletedProjects
          fetchData={fetchData}
          workHistory={workHistory}
          paginationData={paginationData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setItemsPerPage={setItemsPerPage}
        />
        <Box className="pagination-container">
          {workHistory && workHistory.length > 0 && (
            <Pagination
              count={
                paginationData.pageSize === "all"
                  ? 1
                  : Math.ceil(
                      paginationData?.totalCount /
                        Number(paginationData.pageSize)
                    )
              }
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
              showFirstButton
              showLastButton
            />
          )}
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <CompletedProjects
          fetchData={fetchData}
          workHistory={workHistory}
          paginationData={paginationData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setItemsPerPage={setItemsPerPage}
        />
        <Box className="pagination-container">
          {workHistory && workHistory.length > 0 && (
            <Pagination
              count={
                paginationData.pageSize === "all"
                  ? 1
                  : Math.ceil(
                      paginationData?.totalCount /
                        Number(paginationData.pageSize)
                    )
              }
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
              showFirstButton
              showLastButton
            />
          )}
        </Box>
      </CustomTabPanel>
    </Box>
  );
}

export default WorkHistorySection;

function CompletedProjects({
  fetchData,
  workHistory,
  paginationData,
  currentPage,
  setCurrentPage,
  setItemsPerPage,
}) {
  return (
    <Box sx={{ width: "100%" }}>
      {workHistory && workHistory.length > 0 ? (
        workHistory.map((row) => (
          <>
            <Box mb={2} className="work-box">
              <WorkComponent
                fetchData={fetchData}
                workHistory={row}
                paginationData={paginationData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setItemsPerPage={setItemsPerPage}
                workEndDate={new Date()}
              />
            </Box>
          </>
        ))
      ) : (
        <Typography>Work history not available</Typography>
      )}
    </Box>
  );
}

function InProgressProjects() {
  return (
    <Box sx={{ width: "100%" }}>
      {/* {
                [1, 2].map((item, index) => {
                    return ( */}
      <Box mb={2} className="work-box">
        {/* <WorkComponent /> */}
      </Box>
      {/* )
                })
            } */}
    </Box>
  );
}
