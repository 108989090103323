import { useEffect, useState, useCallback } from 'react';
import Stack from '@mui/material/Stack';
import { Typography, Box, Avatar, Button, Chip, Pagination, Tooltip } from '@mui/material';
import { avatarOne } from '../../Components/Images/Images';
import { Add, Bookmark } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import RatingCompo from '../Components/RatingCompo/RatingCompo';
import ServicesSearch from '../Components/ServicesSearch/ServicesSearch';
import ServiceProviderFilters from '../ClientDashboard/ServiceProviderFilters/ServiceProviderFilter';
import { postApi, GetApiParam } from '../../Api/Api';
import "./SearchServiceProviderResults.css"
import { Link, useNavigate, useLocation, NavLink } from 'react-router-dom';
import ConfirmModal from "../../Components/Modal/Confirm/Confirm";
import { getUserDataFromLocalStorage } from '../../Services/localstorage.service';
import config from '../../Config/Config';

const SearchServiceProviderResults = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [openModal, setOpenModal] = useState(false);
    const [categoryData, setCategoryData] = useState(null);
    const [serviceProvider, setServiceProvider] = useState([]);
    const [selectedServiceProvider, setSelectedServiceProvider] = useState(null);
    const [selectedCategoryData, setSelectedCategoryData] = useState(location.state && location.state?.category ? location.state.category : { title: 'All' });
    const loginUserData = getUserDataFromLocalStorage();
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ itemsPerPage, setItemsPerPage ] = useState(4);
    const [ paginationData, setPaginationData ] = useState({
      "page": 1,
      "pageSize": 5,
      "totalCount": 0,
      "totalPages": 0
    });
    const [ searchString, setSearchString ] = useState(location.state?.search ? location.state?.search : '');
    const [filter, setFilter] = useState({
        "priceRangeStart": null,
        "priceRangeEnd": null,
        "location": null,
        "rating": null,
        "sort": null,
        "savedRequest": null,
    });

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 200)
    })

    useEffect(() => {
        // Api to get categoryData
        async function getCategoryData() {
            try {
                const res = await GetApiParam("/services/spCategory", { user_id: loginUserData?.id });
                setCategoryData(res?.data?.data);
            } catch (error) {
                console.log(error);
            }
        }
        getCategoryData();
    }, [])

    useEffect(() => {
        getServiceProviders();
    }, [currentPage])
    
    useEffect(() => {
        setCurrentPage(1);
        getServiceProviders();
    }, [selectedCategoryData, filter, searchString]);

    // Api to get Service Provider
    const getServiceProviders = useCallback(async () => {
        try {
            let payload = { login_user_id: loginUserData?.id };
            if(selectedCategoryData && selectedCategoryData?.id){
                payload = {
                    ...payload,
                    sp_category_id: selectedCategoryData?.id
                }
            }
            if(filter?.savedRequest === "savedRequest"){
                payload = {
                  ...payload,
                  savedRequest: true
                }
            }
            payload = {
                ...payload,
                searchString: searchString
            }
            if(filter?.sort && filter?.sort !== 'all'){
                payload = {
                    ...payload,
                    sort: filter?.sort
                }
            }
            if(filter?.location && filter?.location !== 'all'){
                payload = {
                    ...payload,
                    location: filter?.location
                } 
            }
            if(filter?.rating && filter?.rating !== 'all'){
                payload = {
                    ...payload,
                    rating: filter?.rating
                }
            }
            if(filter?.rating === 0){
                payload = {
                    ...payload,
                    notRated: true
                }
            }
            if((filter?.priceRangeStart || filter?.priceRangeStart === 0) && (filter?.priceRangeEnd || filter?.priceRangeEnd === 0)){
                payload = {
                  ...payload,
                  priceRangeStart: filter?.priceRangeStart,
                  priceRangeEnd: filter?.priceRangeEnd
                }
            }
            const res = await GetApiParam(`/services/getProfiles?page=${currentPage}&rowperpage=${itemsPerPage}`, payload);
            setServiceProvider(res?.data?.data?.length ? res.data.data : []);
            setPaginationData(res?.data?.pagination);
        } catch (error) {
            console.log(error);
        }
    });

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    /**
     * Search Function for the providers
     * @param {*} searchInput 
     */
    const handleSearchResults = (searchInput) => {
        setCurrentPage(1);
        setSearchString(searchInput);
    }

    const handleAction = useCallback((action) => {
        if (action === 'confirm') {
            navigate(`/service-proposer-dashboard/postNewContract/project/${selectedServiceProvider?.user_id}/offer`);
        }
    });

    const fetchServicesAccordingToCategory = useCallback((data) => {
        setSelectedCategoryData(data);
    })

    const handleClickBookmark = async (item) => {
        const payloadData = {
            spv_user_id: item.user_id,
          user_id: loginUserData.id,
          is_bookmark: item.is_bookmark ? 0 : 1,
        };
    
        if (loginUserData.id) {
          const bookmarkResponce = await postApi(
            "/services/isBookmarkServiceProvider",
            payloadData
          );
          if (bookmarkResponce?.data?.code) {
            getServiceProviders(selectedCategoryData?.id);
          }
        }
    };

    return (
        <>
            
                <Box className="container">
                    <Box >
                        <Box mt={0} >
                            <ServicesSearch page={'serviceProviderList'} categoryData={categoryData} handleSearchResults={handleSearchResults} fetchServicesAccordingToCategory={fetchServicesAccordingToCategory} searchString={searchString}/>
                        </Box>
                    </Box>

                    {/* Filters */}
                    <Box mb={2} pt={4}>
                        <ServiceProviderFilters 
                            title={selectedCategoryData?.title === 'Technology Developers & Software Services' ? 'Development & IT' : selectedCategoryData?.title} 
                            filter={filter}
                            setFilter={setFilter}
                        />
                        <Box className="container">
                            { paginationData && paginationData?.totalCount ? <Typography >{paginationData?.totalCount} Service Providers</Typography> : <Typography >Data Not Found</Typography>}
                        </Box>
                    </Box>
                    <Box className="container">
                        {serviceProvider && serviceProvider.length > 0 && serviceProvider?.map((val, ind) => {
                            return (
                                <>
                                    <Box style={{ backgroundColor: "var(--blue-900)", padding: '16px', borderRadius: '24px' }} mb={2}>
                                        <Box display="flex" alignItems="center" justifyContent={'space-between'}>
                                            <RatingCompo rating={val?.rating} review={val?.review ? val?.review : ''} />
                                            <Box sx={{ backgroundColor: '#398CD1', borderRadius: "12px", color: 'white', padding: '10px', display: 'flex', alignItems: 'center' }}>
                                                {val && val?.is_bookmark ? 
                                                    <Bookmark onClick={() => handleClickBookmark(val)} className="bookmark-icon" />
                                                : <BookmarkBorderIcon onClick={() => handleClickBookmark(val)} className="bookmark-icon"/>}
                                            </Box>
                                        </Box>
                                        <NavLink
                                            to={`/service-proposer/profile/individual/${val?.user_id}`}
                                            state={{ user_id: val?.user_id }}
                                        >
                                        <Box display={"flex"} >
                                            <Box className="">
                                                <Avatar
                                                    src={val?.profilePicture.path ? `${process.env.REACT_APP_IMAGE_URL}${val?.profilePicture?.path}` : avatarOne}
                                                    alt={`${val.first_name[0]}`}
                                                />
                                            </Box>
                                            <Box ml={2}>
                                                <Typography variant='h6' sx={{ fontWeight: 500, color: '#FFFFFF' }} className="ctUImage-title">
                                                    {`${val.first_name} ${val.last_name}`}
                                                </Typography>
                                                <Typography className="ctUImage-subtitle" sx={{ fontWeight: 400, color: '#FFFFFF' }} mt={1}>
                                               { val?.skillData?.map(skill => skill?.skill_name).join(' | ') }
                                                </Typography>
                                            </Box>
                                        </Box>
                                        </NavLink>
                                        <Box >
                                            <Typography mt={2} variant='h6' sx={{ fontWeight: 400 }}>{`$${val?.hourly_rate ? val?.hourly_rate : 0}/hr`}</Typography>
                                            <Typography sx={{
                                                mt: '12px', color: '#FFFFFF', overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "-webkit-box",
                                                WebkitLineClamp: "2",
                                                WebkitBoxOrient: "vertical",
                                            }}>{val?.description}
                                            </Typography>
                                            <Link to={`/service-proposer/profile/individual/${val?.user_id}`} state={{user_id : val.user_id}}>
                                                <Typography variant="h6" sx={{
                                                    color: '#398CD1', fontSize: '14px', fontWeight: 700, textDecoration: "underline",
                                                    textDecorationColor: "#398CD1"
                                                }} mt={1}>
                                                    View More
                                                </Typography>
                                            </Link>
                                            <Stack spacing={2} mt={1} direction="row" flexWrap={'wrap'} >
                                                <Box className="pc-chip-wrap" pb={3}  >
                                                    <Stack direction="row" flexWrap={'wrap'} display={"flex"} justifyContent={'center'}>
                                                        <Chip className=" service-provider-result" label={val?.rating >= config?.TOP_RATAED?.ratingGrater && val?.review >= config?.TOP_RATAED?.reviewGrater ? "Top Rated Service Provider" : val?.rating ? 'Average Rated' : val?.rating === 0 ? 'Not Rated' : ''} />
                                                    </Stack>
                                                </Box>
                                                {val?.earned && 
                                                <Box className="pc-chip-wrap" pb={3}  >
                                                    <Stack direction="row" flexWrap={'wrap'} display={"flex"} justifyContent={'center'}>
                                                        <Chip className=" service-provider-result" label={`$${val?.earned} Earned`} />
                                                    </Stack>
                                                </Box>}

                                                <Box className="pc-chip-wrap" pb={3}  >
                                                    <Stack direction="row" flexWrap={'wrap'} display={"flex"} justifyContent={'center'}>
                                                        <Chip className=" service-provider-result" label={val?.successRate ? `${val?.successRate}% Job Success`: 'Not Worked'} />
                                                    </Stack>
                                                </Box>
                                            </Stack>
                                            {/* <Button onClick={() => {setSelectedServiceProvider(val); setOpenModal(true);}} className="btn-rounded" style={{ background: "var(--golden-gradient)" }} sx={{ marginBottom: '10px', borderRadius: "40px", fontSize: "14px", fontWeight: 500 }} variant="contained" startIcon={<Add />}>
                                                Hire
                                            </Button> */}
                                            <Tooltip title={val?.availability === 'notAvailable' ? 'Service Provider Not Available' : 'Hire'} placement='bottom' arrow>
                                                <Button 
                                                    onClick={() => {
                                                        if(val?.availability !== 'notAvailable'){
                                                            setSelectedServiceProvider(val);
                                                            setOpenModal(true);
                                                        }
                                                    }} 
                                                    startIcon={<AddIcon />} variant="contained" 
                                                    className="btn-rounded"
                                                    style={{ background: "var(--golden-gradient)" }} 
                                                    sx={{ marginBottom: '10px', borderRadius: "40px", fontSize: "14px", fontWeight: 500 }}
                                                >Hire</Button>
                                            </Tooltip>
                                            <ConfirmModal title={'Hire'} description={`Are You Sure Want To Hire ${val.first_name} ${val.last_name}`} isOpen={openModal} setIsOpenModal={setOpenModal} handleAction={handleAction} />
                                        </Box>
                                    </Box>
                                </>
                            )
                        })}
                    </Box>
                    <Box className="pagination-container">
                        <Pagination
                            count={paginationData.pageSize === 'all' ? 1 : Math.ceil(paginationData?.totalCount/Number(paginationData.pageSize))}
                            page={currentPage}
                            onChange={handlePageChange}
                            variant="outlined"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                        />
                    </Box>
                </Box>
            
        </>
    )
}

export default SearchServiceProviderResults