import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { Button, Box, Select, FormControl, MenuItem, Typography, ListItemText, ImageListItem, Menu, InputBase, InputLabel, Grid } from '@mui/material';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import BarChartIcon from '@mui/icons-material/BarChart';
import SearchIcon from '@mui/icons-material/Search';
import { GetApi, GetApiParam } from '../../Api/Api';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CardSkeleton from '../../Components/Cards/CardSkeleton';
import Cards from '../../Components/Cards/Cards';
import './SavedDeals.css'

const sortByOptions = ["Default", "Name (A-Z)", "Name (Z-A)", "Asset Type"];

const SavedDeals = () => {
  // Hook to determine whether the sidebar is open
  const sidebarOpen = useOutletContext();

  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve user data from local storage
  const userData = JSON.parse(localStorage.getItem("user_data"));

  // State to hold range of project amount
  const [investVal, setInvestVal] = useState([{ label: '$500 and under' }, { label: '$500-$1000' }, { label: '$1000-$5000' }, { label: '$5000+' }]); //min investment

  // State variables for asset data and filtered data
  const [assetData, setAssetData] = useState([]); //used to save original data from api
  const [filteredData, setFilteredData] = useState([]); //this is the data which is displayed and altered by filters

  // Ref for the search bar
  const searchBarRef = useRef(null); //search bar 

  // State variables for filter values, search string, sort by, category data, investment type data, server time, server date, server clock time, and loading state
  const [filterValues, setFilterValues] = useState({
    category_id: 0,
    user_id: userData?.id,
    investment_type_id: 0,
    investment_val: 'All',
    max_amount: null,
    min_amount: null,
  });
  const [searchString, setSearchString] = useState("");
  const [sortBy, setSortBy] = useState("Default");
  const [categoryData, setCategoryData] = useState()
  const [investmentTypeData, setInvestmentTypeData] = useState([])
  const [serverTime, setServerTime] = useState()
  const [serverDate, setServerDate] = useState()
  const [serverClockTime, setServerClock] = useState()
  const [anchorElSort, setAnchorElSort] = React.useState(null); //sort
  const open = Boolean(anchorElSort); //sort
  const [loading, setLoading] = useState(false);

  // useEffect to fetch saved deal data, category data, and investment type data when the sidebarOpen state changes
  useEffect(() => {
    async function getSaveDealData() {
      try {
        setLoading(true)
        const saveDealPayload = {
          "user_id": userData.id,
        }
        const res = await GetApiParam("/proposer/getSaveDeals/", saveDealPayload);
        setAssetData(res.data?.data)
        setFilteredData(res.data?.data);
        setServerTime(res.data?.server_time)
        setServerDate(res.data?.server_date)
        setTimer(res)
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    async function getCategoryData() {
      try {
        const res = await GetApi("/proposer/getCategory/");
        setCategoryData(res?.data?.data)
      } catch (error) {
        console.log(error)
      }
    }
    async function getInvestMentTypeData() {
      try {
        const res = await GetApi("/proposer/investmentType/");
        setInvestmentTypeData(res?.data?.data)
      } catch (error) {
        console.log(error)
      }
    }
    if (userData?.id) {
      getSaveDealData();
      getCategoryData();
      getInvestMentTypeData();
    }
  }, [sidebarOpen])

  // Function to set the server clock time using the received data
  function setTimer(res) {
    // initialize server clock
    const serverClockTime = new Date(res.data.server_date);
    var timestamp = res.data.server_time.split(':');
    serverClockTime.setHours(timestamp[0])
    serverClockTime.setMinutes(timestamp[1])
    serverClockTime.setSeconds(timestamp[2])
    setServerClock(serverClockTime);
  }

  // start clock interval
  setInterval(() => {
    if (serverClockTime) {
      serverClockTime.setSeconds(serverClockTime.getSeconds() + 1);
      setServerClock(serverClockTime);
      const event = new CustomEvent('clocktime', { detail: serverClockTime, bubbles: true });
      document.dispatchEvent(event);
    }
  }, 1000);


  /**
   * searches the String in [title] and sets the filtered data
   * @param {string} value  search string
   * @returns {void}
   */
  const suggestionSearch = async (value) => {
    setSearchString(value);

    //minimum 2 character to search resets the data to original if search string is less than 2 
    if (value.length < 2) {
      const filterData = assetData.filter((item) => {
        if (filterValues.category_id > 0 && filterValues.investment_type_id > 0) {
          return (filterValues.category_id === item?.asset_data?.assets_category.id && filterValues.investment_type_id === item?.asset_data?.investment_type.id);
        } else if (filterValues.category_id > 0) {
          return (filterValues.category_id === item?.asset_data?.assets_category.id);
        }
        else if (filterValues.investment_type_id > 0) {
          return (filterValues.investment_type_id === item?.asset_data?.investment_type.id);
        } else {
          return true
        }
      });
      setFilteredData(filterData);
      return;
    }; //minimum 2 character to search

    const dataFromSearch = assetData.filter((item) => {
      if (filterValues.category_id > 0 && filterValues.investment_type_id > 0) {
        return (
          item?.asset_data?.asset_title.toLowerCase().includes(value.toLowerCase()) &&
          filterValues.category_id === item?.asset_data?.assets_category.id && filterValues.investment_type_id === item?.asset_data?.investment_type.id
        );
      } else if (filterValues.category_id > 0) {
        return (
          item?.asset_data?.asset_title.toLowerCase().includes(value.toLowerCase()) &&
          filterValues.category_id === item?.asset_data?.assets_category.id
        );
      }
      else if (filterValues.investment_type_id > 0) {
        return (
          item?.asset_data?.asset_title.toLowerCase().includes(value.toLowerCase()) &&
          filterValues.investment_type_id === item?.asset_data?.investment_type.id
        );
      } else {
        return item?.asset_data?.asset_title.toLowerCase().includes(value.toLowerCase());
      }
    });
    setFilteredData(dataFromSearch);
  }

  /**
   * Handle change in category in filter
   * @param {Event} event 
   */
  const handleChangeCategory = (event) => {
    setFilterValues({
      ...filterValues,
      category_id: event.target.value,
    });
  }

  /**
   * Handle change in investment
   * @param {Event} event 
   */
  const handleChangeInvestmentType = (event) => {
    setFilterValues({
      ...filterValues,
      investment_type_id: event.target.value,
    });
  }

  // Filter the data on changing on filter value
  useEffect(() => {
    if (filterValues.category_id === 0 && filterValues.investment_type_id === 0) {
      setFilteredData(assetData);
      return;
    }

    const dataFromFilter = assetData.filter((item) => {
      return (
        (filterValues.category_id === 0 || item?.asset_data?.assets_category?.id === filterValues.category_id) &&
        (filterValues.investment_type_id === 0 || item?.asset_data?.investment_type?.id === filterValues.investment_type_id)
      );
    })

    setFilteredData(dataFromFilter);

  }, [filterValues])

  const handleClick = (event) => {
    setAnchorElSort(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElSort(null);
  };

  /**
  * Function to handle range change
  * @param {event} event 
  */
  const handleRangeFilter = (event) => {
    setFilterValues({ ...filterValues, investment_val: event.target.value });
  };

  /**
   * Handle sort by value
   * @param {String} sortBy 
   */
  const handleSortChange = (sortBy) => {
    setSortBy(sortBy);
    if (assetData || assetData.length !== 0) {
      if (sortBy === 'Name (A-Z)') {
        let filtered = [...assetData]?.sort((a, b) => a?.asset_data?.asset_title?.localeCompare(b?.asset_data?.asset_title));
        setFilteredData(filtered);
      }
      else if (sortBy === 'Name (Z-A)') {
        let filtered = [...assetData]?.sort((a, b) => b?.asset_data?.asset_title?.localeCompare(a?.asset_data?.asset_title));
        setFilteredData(filtered);
      }
      else if (sortBy === 'Asset Type') {
        let filtered = [...assetData]?.sort((a, b) => a?.asset_data?.assets_category?.title?.localeCompare(b?.asset_data?.assets_category?.title));
        setFilteredData(filtered);
      }
      else if (sortBy === 'Default') {
        setFilteredData(assetData)
      }
    }
  }
  const firstRenderRef = useRef(true);

  // Setting filter according to input
  useEffect(() => {

    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }

    // setting payload for filter
    let filterDataPayload = { ...filterValues };
    if (filterDataPayload.category_id === 0) delete filterDataPayload.category_id;
    if (filterDataPayload.investment_type_id === 0) delete filterDataPayload.investment_type_id;
    if (filterDataPayload.investment_val === null) delete filterDataPayload.investment_val;
    if (filterDataPayload.min_amount === null) delete filterDataPayload.min_amount;
    if (filterDataPayload.max_amount === null) delete filterDataPayload.max_amount;
    // setting for range
    if (filterDataPayload.investment_val) {
      switch (filterDataPayload.investment_val) {
        case '$500 and under':
          filterDataPayload.max_amount = 500;
          delete filterDataPayload.min_amount;
          break;
        case '$500-$1000':
          filterDataPayload.min_amount = 500;
          filterDataPayload.max_amount = 1000;
          break;
        case '$1000-$5000':
          filterDataPayload.min_amount = 1000;
          filterDataPayload.max_amount = 5000;
          break;
        case '$5000+':
          filterDataPayload.min_amount = 5000;
          delete filterDataPayload.max_amount;
          break;
        // Add a default case if needed
        default:
          // Handle the case when filterDataPayload.investment_val doesn't match any of the specified cases.
          break;
      }
    }
    // if page is search then filter the data
    if (location.pathname === "/user/saved-deals" || location.pathname === "/user/projects") {
      getFilterData(filterDataPayload);
    } else { // else navigate to search page with state of payload
      navigate(`/search`, { state: { defaultFilter: filterDataPayload } })
    }

    setSortBy(sortByOptions[0]); // setting sort by to default

  }, [filterValues]);

  /**
   * get the filter data
   * @param {Object} filterPayload 
   */
  const getFilterData = async (filterPayload) => {
    try {
      const res = await GetApiParam(
        "/proposer/getSaveDeals/",
        filterPayload
      );

      if (res?.data?.data) {
        setAssetData(res.data?.data)
        setFilteredData(res.data?.data);
        setServerTime(res.data?.server_time)
        setServerDate(res.data?.server_date)
        setTimer(res)
        setLoading(false)
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <Box className="filters-group-wrap" pt={3.5} mb={4} sx={{ justifyContent: 'flex-start' }}>
        {/* Search bar filter */}
        <Box className="filters-group-wrap">
          <Box className="filterSearch-form">
            <Box component={"span"} className="search-icon">
              <SearchIcon />
            </Box>
            <InputBase
              ref={searchBarRef}
              className="filterSearch"
              placeholder="Search Projects"
              value={searchString}
              onChange={(e) => suggestionSearch(e.target.value)}
              inputProps={{ "aria-label": "search transactions" }}
            />
          </Box>
        </Box>

        {/* Category filter */}
        <Box className="select-group" ml={1.5}>
          <FormControl className="select-rounded" sx={{ minWidth: '150px' }} size="small">
            <InputLabel id="demo-controlled-open-select-label">
              Category
              <ViewComfyIcon />
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              value={filterValues.category_id}
              onChange={handleChangeCategory}
              label="Category"
              variant='outlined'
            >
              <MenuItem value={0}>All</MenuItem>
              {categoryData?.map((value, ind) => {
                return (
                  <MenuItem key={ind} value={value.id}>
                    <ListItemText>{value.title === "Asset" ? "Luxury Cars" : value.title}</ListItemText>
                    <Typography className="menu-icon">
                      <ImageListItem>
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                          srcSet={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                          alt={value.category_icon?.original_name}
                          loading="lazy"
                        />
                      </ImageListItem>
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        {/* Investment Type Filter */}
        <Box className="select-group" ml={1.5}>
          <FormControl
            className="select-rounded"
            sx={{ minWidth: 160 }}
            size="small"
          >
            <InputLabel id="demo-controlled-open-select-label">
              Investment Type
              <BarChartIcon />
            </InputLabel>

            <Select
              labelId="demo-controlled-open-select-label"
              value={filterValues.investment_type_id}
              label="Investment Type"
              onChange={handleChangeInvestmentType}
            >
              <MenuItem value={0}>All</MenuItem>
              {investmentTypeData?.map((value, ind) => {
                return (
                  <MenuItem
                    key={ind}
                    value={value.id}
                  >
                    {value.investment_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        {/* Min Investment */}
        <Box className="select-group" ml={1.5}>
          <FormControl
            className="select-rounded"
            sx={{ minWidth: 190 }}
            size="small"
          >
            <InputLabel id="demo-controlled-open-select-label">
              Min. Investment
            </InputLabel>

            <Select
              labelId="demo-controlled-open-select-label"
              value={filterValues.investment_val}
              label="Investment Type"
              onChange={handleRangeFilter}
            >
              <MenuItem value={'All'}>All</MenuItem>
              {investVal?.map((items, ind) => {
                return (
                  <MenuItem
                    key={ind}
                    value={items.label}
                  >
                    {items.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        {/* Sorting  */}
        <Box className="dropdown-menu-wrap" ml={1.5}>
          <Button
            endIcon={<SwapVertIcon />}
            // onClick={() => sortByName()}
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            className="btn-rounded btn-outline"
          >
            Sort By: {sortBy}
          </Button>
          <Menu
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorElSort}
            open={open}
            onClose={handleClose}
            className="dropdown-menu filterCategory-menu"
          >

            {
              sortByOptions.map((value, ind) => {
                return (
                  <MenuItem key={ind}
                    selected={sortBy === value ? true : false}
                    onClick={() => {
                      handleSortChange(value);
                      setAnchorElSort(null); // done seperately to close the menu after click
                    }}
                  >
                    {value}
                  </MenuItem>
                );
              })
            }

          </Menu>
        </Box>

      </Box >

      <Box className='drafts-box' mb={3}>Saved Deals ☑️</Box>


      <Grid container spacing={{ xs: 1, sm: 1, md: 2 }}>
        {
          !loading && filteredData && filteredData?.length > 0 ?
            filteredData.map((asset, ind) => {
              const galleryimg = [];
              // Adding Cover Photo into slider
              if (asset?.asset_coverphoto) {
                galleryimg.push(`${process.env.REACT_APP_IMAGE_URL}${asset.asset_coverphoto?.path}`)
              }

              // Inserting gellery Images Into Slider
              asset?.asset_data?.asset_gallery.map((val, ind) => {
                if (val.title === 'gellary') {
                  galleryimg.push(`${process.env.REACT_APP_IMAGE_URL}${val.images.path}`)
                }
              })
              return (

                <Grid key={ind} item xs={12} sm={6} md={4} lg={4}>
                  <Cards
                    id={asset?.asset_data?.id}
                    title={asset?.asset_data?.asset_title}
                    subTitle={asset?.asset_data?.asset_address}
                    investType={asset?.asset_data?.investment_type?.investment_name}
                    isLoan={asset?.asset_data?.assets_category?.title}
                    isDraft={false} isClock={false}
                    isPending={asset?.asset_data?.status === "Pending" || asset?.asset_data?.status ===
                      "inreview" ? true : false}
                    isDelete={asset?.asset_data?.status === "Rejected" ? true : false}
                    isSaved={true}
                    imgSrc={galleryimg}
                    isSaveDeal={true}
                    link={`/project-details/${asset?.asset_data?.id}`}
                    investment_expiry_time={asset?.asset_data?.investment_expiry_time}
                    server_date={serverDate}
                    server_time={serverTime}
                    projectProgress={asset?.asset_data?.project_process}
                    ownerName={asset?.asset_data?.user_details?.first_name}
                    ownerImage={asset?.asset_data?.user_details?.persona_picture}
                  />
                </Grid>
              )
            }) :
            loading ? Array(3).fill().map((item, ind) =>
              <Grid key={ind} item xs={12} sm={6} md={4} lg={4}>
                <CardSkeleton />
              </Grid>
            )
              :
              assetData?.length === 0 &&
              <Box>   <Typography fontSize={'16px'} height={'24px'} ml={1} textAlign={'center'}>No Saved Deals Yet</Typography></Box>
        }

      </Grid>
      { }

    </>
  );
};
export default SavedDeals;
