import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        login: false,
    },
    reducers: {
        login: (state, action) => {
            state.user = action.payload;
            state.login = true;
            localStorage.setItem('user_data', JSON.stringify(action.payload));
            localStorage.setItem('token', action.payload?.token);
            localStorage.setItem('login', true);
        },
        logout: (state) => {
            state.user = null;
            state.login = false;
            localStorage.removeItem('user_data');
            localStorage.removeItem('token');
            localStorage.removeItem('login');
            localStorage.removeItem("chatbot-thread-id")
            localStorage.removeItem("refreshToken")
            localStorage.removeItem("rememberMeToken")
            localStorage.removeItem("Diclogin")
            localStorage.removeItem("user_data_dic")
            localStorage.removeItem("kyc_modal_closed")
            localStorage.removeItem("service-provider")
            localStorage.removeItem("service-proposer")
        },
        update: (state, action) => {
            state.user = { ...state.user, ...action.payload };
            localStorage.setItem('user_data', JSON.stringify(state.user));
        }
    }
});

export const { login, logout, update } = authSlice.actions;
export default authSlice.reducer;