import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { GetApiParam } from '../../Api/Api';
import purify from "dompurify";

function PrivacyPolicy() {
  const [privacy, setPrivacy] = useState("")

  useEffect(() => {
    async function getPrivacyPolicy() {
      try {
        const termsPayload = {
          type: 2,
        };
        const response = await GetApiParam("/admin/cms", termsPayload);
        setPrivacy(response?.data?.data?.[0]?.content)
      } catch (error) {
        console.log(error);
      }
    }
    getPrivacyPolicy();

  }, []);

  return (
    <>
      <Box className="privacyPage-section" pt={{ xs: 4, sm: 7, md: 8, lg: 9 }} pb={{ xs: 4, sm: 5, md: 10 }}>
        <Box className="container">
          <Box px={{ xs: 0, md: 6, lg: 8 }} className='privacy-section'>
            <Box dangerouslySetInnerHTML={{ __html: purify.sanitize(privacy) }} />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default PrivacyPolicy