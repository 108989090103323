import React from 'react';
import { Typography, Card, Box, CardMedia, CardContent } from "@mui/material";
import "./SliderCard.css";

const SliderCardProser = (props) => {
    return (
        <>
            <Card
                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <CardMedia component="div" sx={{
                    pt: `${props.height}`
                }}
                    image={props.image}
                />
                <CardContent sx={{ flexGrow: 1, textAlign: 'left' }}>
                    <Typography gutterBottom variant="h5" component="h2" sx={{ color: props.color ? "black" : "white" }}>
                        {props.title}
                    </Typography>
                </CardContent>
            </Card>
        </>
    )
}

export default SliderCardProser