import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Box, Typography } from '@mui/material'
import './PortfolioCard.css'

function PortfolioCard({ image, title}) {
    const getImageSrc = (image) => {
        if (image instanceof File || image instanceof Blob) {
          return URL.createObjectURL(image);
        } else if (typeof image === 'string') {
          return `${process.env.REACT_APP_IMAGE_URL}${image}`;
        } else {
          return ''; // or a default image URL
        }
      };

    return (
        <Box className="pf-card-wrap">
            <Box className="pf-image-wrap" >
                <Avatar className="pf-image" src={getImageSrc(image)} alt={title} />
            </Box>
            <Box className="pf-title-wrap">
                <Typography variant="subtitle1" className="pf-title font-18" mb={1.5}>{title ? title : 'Virtual Assistant To Help Pull Contact Information'}</Typography>
            </Box>
        </Box>
    )
}

export default PortfolioCard




//default props
// PortfolioCard.defaultProps = {
//     image: PortfolioPic1,
//     title: 'Virtual Assistant To Help Pull Contact Information',
//     description: 'Portfolio Description',
//     link: 'https://via.placeholder.com/150'
// }

