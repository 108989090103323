import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  Modal,
  TextField,
  IconButton,
  Rating
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./RatingModal.css";
import { useSnackbar } from "../../../../../Contexts/SnackbarContext";
import { getUserDataFromLocalStorage } from "../../../../../Services/localstorage.service";
import { postApi } from "../../../../../Api/Api";

const RatingModal = ({ onOpen, handleClose, contractId, spvUserId }) => {
  const localData = getUserDataFromLocalStorage();
  const [formData, setFormData] = useState({
    rating: 1,
    review: "",
  });
  const [errors, setErrors] = useState({});
  const { showSnackbar } = useSnackbar();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if(!Object.keys(errors).every((key) => errors[key] === "")){
      validate();
    }
  }, [formData]);

  const validate = () => {
    const errors = {};
    // Validate Title field
    if (!formData.rating) {
      errors.rating = "Rating is required";
    }
    setErrors(errors);
    // If there are no errors, proceed with onSave and handleClose
    return Object.keys(errors).every((key) => errors[key] === "");
  };

  const submitRating = async (event) => {
    event.preventDefault();
    const isValid = validate();
    if (isValid) {
      try {
        const payload = {
          client_id: localData?.id,
          spv_user_id: spvUserId,
          contract_id: contractId,
          rating: formData?.rating,
          review: formData?.review
        }
        const res = await postApi(`/services/addRating`, payload);
        if (res.data && res.data.code === 200) {
          showSnackbar(res?.data?.message, 'success');
          handleClose();
          setFormData({
            rating: 0.5,
            review: "",
          });
        } else {
          showSnackbar(res?.data?.message, 'warning');
        }
      } catch (error) {
        console.log("Something went wrong", error);
      }
    }
  };

  return (
    <Box className="add-education-popup-main">
      <Modal
        open={onOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-main"
      >
        <Box
          id="education-scroller-wrapper"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            borderRadius: "24px",
            width: "900px",
            padding: "52px",

            background: "var(--blueGray-900)",
            boxShadow:
              "0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.15)",
            // Media query for small devices
            "@media (max-width: 600px)": {
              width: "350px", // Set a different height for small screens
            },
            // Additional media queries for other screen sizes if needed
            "@media (min-width: 601px) and (max-width: 900px)": {
              // Custom styles for devices between 601px and 900px width
              width: "580px",
            },
            "@media (min-width: 901px) and (max-width: 1200px)": {
              // Custom styles for devices between 901px and 1200px width
              width: "750px",
              maxHeight: "300px",
              overflow: "auto",
            },
            // Specify the maximum width for large screens
            "@media (min-width: 1201px)": {
              maxWidth: "1043px",
              maxHeight: "525px",
              overflow: "auto",
            },
          }}
        >
          <Box className="close-icon-main">
            <IconButton className='closeButton'>
                <CloseIcon onClick={handleClose}/>
            </IconButton>
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            mt={2}
            className="heading font-36"
            sx={{ textAlign: "center" }}
          >
            Rating
          </Typography>
          <form onSubmit={submitRating}>
            <Box mt={3} backgroundColor="var(--yellow-60)" borderRadius={20} padding={1} >
              <Rating 
                name="size-large"
                value={formData.rating}
                onChange={(event, newValue) => {
                  if(newValue >= 1){
                    setFormData((prevState) => ({
                      ...prevState,
                      'rating': newValue,
                    }));
                  }
                }}
                size="large"
              />
            </Box>

            <Box mt={2}>
              <TextField
                id="description"
                name="description"
                label="Review"
                variant="outlined"
                placeholder="Write your review."
                onChange={(e) => {
                  if(e.target.value && e.target.value.length < 500){
                    setFormData((prevState) => ({
                      ...prevState,
                      review: e.target.value,
                    }));
                  }
                }}
                value={formData.review}
                fullWidth
                multiline
                rows={4}
                style={{ minHeight: "80px" }}
                error={!!errors.review}
                helperText={errors.review ? errors.review : ""}
              />
            </Box>
            <Box mt={3} className="btn-container">
              <Stack spacing={2} direction="row" justifyContent="center">
                {/* <Button variant="outlined" className='cancel-btn' onClick={handleClose}>Cancel</Button> */}
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    background: "var(--golden-gradient)",
                    color: "var(--blueGray-900)",
                  }}
                >
                  Submit
                </Button>
              </Stack>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};


export default RatingModal;
