import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Typography } from '@mui/material'
import './ServiceAccordian.css'
import { KeyboardArrowDownSharp } from '@mui/icons-material';
import PropTypes from 'prop-types';

const faqs = [
    {
        id: 1,
        question: 'Web & Mobile Design',
        answer: 'WordPress Web Design using Divi, WP Bakery and other major page builders. Our designs allow the end-user, our clients, to easily manage the content of their websites without the frustrating hassle of learning complex back end web design.'
    },
    {
        id: 2,
        question: 'What is your favorite template from BRIX Templates?',
        answer: 'WordPress Web Design using Divi, WP Bakery and other major page builders. Our designs allow the end-user, our clients, to easily manage the content of their websites without the frustrating hassle of learning complex back end web design.'
    },
    {
        id: 3,
        question: 'How do you clone a template from the Showcase?',
        answer: 'WordPress Web Design using Divi, WP Bakery and other major page builders. Our designs allow the end-user, our clients, to easily manage the content of their websites without the frustrating hassle of learning complex back end web design.'
    },
    {
        id: 4,
        question: 'Why is BRIX Templates the best Webflow agency?',
        answer: 'WordPress Web Design using Divi, WP Bakery and other major page builders. Our designs allow the end-user, our clients, to easily manage the content of their websites without the frustrating hassle of learning complex back end web design.'
    },
    {
        id: 5,
        question: 'When was Webflow officially launched?',
        answer: 'WordPress Web Design using Divi, WP Bakery and other major page builders. Our designs allow the end-user, our clients, to easily manage the content of their websites without the frustrating hassle of learning complex back end web design.'
    },
    {
        id: 6,
        question: 'How do you integrate Jetboost with Webflow?',
        answer: 'WordPress Web Design using Divi, WP Bakery and other major page builders. Our designs allow the end-user, our clients, to easily manage the content of their websites without the frustrating hassle of learning complex back end web design.'
    },
]

function ServiceAccordian() {

    const [expanded, setExpanded] = React.useState('faq1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);

    };
    return (
        <>
            <Box p={'24px'}>
            <Typography  sx={{marginBottom:'16px'}} className='font-18'>Services</Typography>
                {
                    faqs.map((item, index) => {
                        return (
                            
                            <Accordion key={index} disableGutters elevation={0} className='accordian-info'
                                expanded={expanded === `faq${item.id}`}
                                onChange={handleChange('faq' + item.id)} >
                                <AccordionSummary className='faq-accordian-summary' expandIcon={<KeyboardArrowDownSharp />}>
                                    <Typography className='title'>{item.question}</Typography>
                                </AccordionSummary>
                                <AccordionDetails className='faq-accordian-details'>
                                    <Typography className='description'>
                                        {item.answer}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }

            </Box>
        </>
    )
}

export default ServiceAccordian

//prop types 
ServiceAccordian.propTypes = {
    faqs: PropTypes.array.isRequired
}
