import React from 'react';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

const CommonRadio = ({ value, label, size, color, ...props }) => {
  const sizeMapping = {
    sm: 'small',
    md: 'medium',
    lg: 'large',
  };

  const radioSize = sizeMapping[size] || 'medium';

  return (
    <FormControlLabel
      value={value}
      control={
        <Radio
          size={radioSize}
          sx={{
            color: '#FFFFFF',
            '&.Mui-checked': {
              color: color ? color : '#FFFFFF',
            },
          }}
          {...props}
        />
      }
      label={label}
    />
  );
};

export default CommonRadio;