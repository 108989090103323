import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Box } from '@mui/material';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import HeaderSignIn from '../Header/HeaderSignIn'
import { login as userLogin } from "../../features/auth/authSlice";
import HeaderInvestorOnly from '../Header/HeaderInvestorOnly';
import HeaderInvestorOnlyLogged from '../Header/HeaderInvestorOnlyLogged';
import FooterInvestorOnly from '../Footer/FooterInvestorOnly';

function LayoutInvestorOnly() {
    // login state is used to show header for logged in user fetch from redux store
    const login = useSelector((state) => state.auth.login);
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    useEffect(() => {
        const _login = localStorage.getItem("login");
        // checks if localStorage and redux store are in sync, where localStorage is given priority
        if (_login && !user) {
            dispatch(userLogin(JSON.parse(localStorage.getItem("user_data"))));
        }
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <Box className='investor-layout'>
                {login ? <HeaderInvestorOnlyLogged /> : <HeaderInvestorOnly />}
                <Box className='main-page investor-only investor-only-main-page'>
                    <Outlet />
                </Box>
                <FooterInvestorOnly />
            </Box>
        </>
    )
}

export default LayoutInvestorOnly