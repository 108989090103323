import React, { useEffect, useState } from "react";
import {
  Box,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import "./MyOffers.css";
import OfferList from "./OfferList";
import { GetApi } from "../../../Api/Api";
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";

const MyOffers = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');
  const [value, setValue] = React.useState(tab ? Number(tab) : 0);
  const [offerList, setOfferList] = React.useState([]);
  const userData = getUserDataFromLocalStorage();
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ itemsPerPage, setItemsPerPage ] = useState(5);
  const [ paginationData, setPaginationData ] = useState({
    "page": 1,
    "pageSize": 0,
    "totalCount": 0,
    "totalPages": 0
  });

  const fetchData = async()=>{
    let url = `/services/spvOffer?spv_id=${userData.id}&page=${currentPage}&rowperpage=${itemsPerPage}`;
    GetApi(url).then((res) => {
        if (res?.status === 200) {
          if (res.data && res.data.data && res.data.data.length > 0) {
            setOfferList(res.data.data);
            setPaginationData(res.data.pagination)
          }else{
            setOfferList([]);
            setPaginationData({
              "page": 1,
              "pageSize": 0,
              "totalCount": 0,
              "totalPages": 0
            })
          }
        }
    })
  };

  useEffect(() => {
    fetchData();
  }, [value, currentPage]);
  
  return (
    <>
      <Box className="my-job-container">
        <Box sx={{ width: "100%" }}>
          <OfferList fetchData={fetchData} offerList={offerList} paginationData={paginationData} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </Box>
      </Box>
     
    </>
  );
};

export default MyOffers;
