import { Box } from "@mui/material";
import ReachInvestors from "../../Components/Common/ReachInvestors";
import { ArtRace } from "../../Components/Images/Images";
import CategoryHeader from "../CategoryHeader";
import NewestListings from "../NewestListings";
import TopInvestments from "../TopInvestments";
import TypeSlider from "../TypeSlider";
import WhyUseRace from "../WhyUseRace";
import { goldBar } from "../../Components/Images/Images";

export default class GoldCategoryClass {
  constructor() { }

  type = "Gold";

  pageComponenets = []; //push all relevant componenets here

  headerData = {
    title: "Invest in gold Through the Power of the",
    highlight: "Blockchain",
    subtitle: "Invest in Gold easily; in a new, secure, and transparent way.",
    image: goldBar,
  };

  whyRaceData = {
    title: "Why use RACE to invest in",
    highlightText: "Gold",
    first: "Access Masterpieces",
    second: "Direct and Lower Fees",
    third: "Curated and Authenticated",
    fourth: "Quick and Seamless",
    image: ArtRace,
  };

  setPageComponents() {
    this.pageComponenets.push(<CategoryHeader categoryId={7} data={this.headerData} />);
    this.pageComponenets.push(<NewestListings categoryId={7} type="Gold" />);
    this.pageComponenets.push(<TopInvestments categoryId={7} type="Gold" />);
    this.pageComponenets.push(
      <TypeSlider
        categoryId={7}
        sliderData={this.typeSliderData}
        sliderTitle={"Gold Types"}
      />
    );
    this.pageComponenets.push(<WhyUseRace data={this.whyRaceData} />);
    this.pageComponenets.push(
      <>
        <Box className='container'>
          <Box className="reachInvestors-section artLanding">
            <ReachInvestors />
          </Box>
        </Box>
      </>
    );
  }
}
