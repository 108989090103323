import React, { useState, useEffect, useCallback, useRef } from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import "../Overview/Overview.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate, useOutletContext, } from "react-router-dom";
import { GetApiParam } from "../../Api/Api";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PendingIcon from "@mui/icons-material/Pending";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import DoneIcon from "@mui/icons-material/Done";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { DicCard } from "../DICCard/DicCard";
import Filter from "./Filter";
import { ArrowBack, PendingActions } from "@mui/icons-material";
import CardSkeleton from "../../Components/Cards/CardSkeleton";
import CommonBackdropLoader from "../../Components/Common/CommonBackdropLoader";
import { ASSIGNED_PROJECT_STATUS } from "../../constants";

const ProjectDic = () => {
  const sidebarOpen = useOutletContext();
  const navigate = useNavigate();
  // const dataFromLoader = useLoaderData();
  const [assetData, setAssetData] = useState("");
  // const [categoryData, setCategoryData] = useState();
  const [serverTime, setServerTime] = useState();
  const [serverDate, setServerDate] = useState();
  const [serverClockTime, setServerClock] = useState();
  const [completedPro, setCompletedPro] = useState(false);
  const [commitieHeaderInfo, setCommitieHeaderInfo] = useState("");
  // const [numReviewers, setNumReviewers] = useState(3); // Total number of committee members
  // const [numReviewsCompleted, setNumReviewsCompleted] = useState(2); // Number of reviews completed
  // State to store the Set
  const [statusArr, setStatusArr] = useState([]);

  // Sates for the CardSkeleton
  const [loading, setLoading] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0); // Current slide index
  const localData = JSON.parse(localStorage.getItem("user_data_dic"));
  let userName = localData?.first_name;
  let memberId = localData?.member_id;

  // Reference to the carousel component
  const carouselRef = useRef(null);
  const carouselRefSub = useRef(null);
  const carouselRefSubAdd = useRef(null);
  const serverTimeInterval = useRef(null);

  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("user_data_dic"));
    if (!localData) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    async function getAssignedProjectData() {
      setLoading(true);
      try {
        const localData = JSON.parse(localStorage.getItem("user_data_dic"));
        if (localData.id) {
          const payloadData = {
            committee_member_id: localData.id,
          };
          // if (!completedPro) {
          payloadData.only_available = 1
          // }
          const res = await GetApiParam(
            "/committee/getAssignedAssets",
            payloadData
          );
          setAssetData(res.data?.data);
          setServerTime(res.data?.server_time);
          setServerDate(res.data?.server_date);
          setTimer(res.data);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }

    // async function getCategoryData() {
    //   try {
    //     const res = await GetApi("/proposer/getCategory/");
    //     setCategoryData(res?.data?.data);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    async function getCommiteeHeaderInfo() {
      try {
        const localData = JSON.parse(localStorage.getItem("user_data_dic"));
        const payloadData = {
          committee_member_id: localData.id ? localData.id : 5,
        };
        const res = await GetApiParam("/committee/headerInfo", payloadData);
        if (res.data.code === 200) {
          setCommitieHeaderInfo(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }

    const timer = setTimeout(() => {
      getCommiteeHeaderInfo();
      // getCategoryData();
      getAssignedProjectData();
    }, 100);
    return () => clearTimeout(timer);
  }, [completedPro, sidebarOpen]);

  // functionality for Timer
  function setTimer(res) {
    // initialize server clock
    if (res?.data?.length > 0) {
      const serverClockTime = new Date(res?.server_date);
      var timestamp = res?.server_time.split(":");
      serverClockTime.setHours(timestamp[0]);
      serverClockTime.setMinutes(timestamp[1]);
      serverClockTime.setSeconds(timestamp[2]);
      setServerClock(serverClockTime);
    }
  }

  useEffect(() => {
    serverTimeInterval.current = setInterval(() => {
      if (serverClockTime) {
        serverClockTime.setSeconds(serverClockTime.getSeconds() + 1);
        setServerClock(serverClockTime);
        const event = new CustomEvent("clocktime", {
          detail: serverClockTime,
          bubbles: true,
        });
        document.dispatchEvent(event);
      }
    }, 1000);
  }, [serverClockTime]);

  // const [isSort, setIsSort] = useState(false);

  // Product slider
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktopXXl: {
      breakpoint: { max: 3000, min: 2000 },
      items: 5,
    },
    desktopXl: {
      breakpoint: { max: 2000, min: 1400 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1400, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1023, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const completeProjects = () => {
    setCompletedPro(true);
  };

  /**
   * Getting status values from Asset Data to apply condition for displaying
   */
  useEffect(() => {
    if (assetData) {
      const localArr = [];
      for (let i = 0; i < assetData?.length; i++) {
        const newStatus = assetData[i]?.dicMemberData?.find(
          (memberItems) =>
            memberItems?.memberDetails?.id === assetData[i].memberDetails?.id
        );
        localArr.push(newStatus.status);
      }
      setStatusArr(Array.from(new Set(localArr)));
    }
  }, [assetData]);

  const NotCompletedProject = () => {
    return (
      <>
        <Box className="mid-text" marginBottom={"24px"}>
          <Box className="name-text">Not Reviewed</Box>
          <Link
            onClick={completeProjects}
            to=""
            className="btn-rounded btn-white-50"
          >
            View Completed Projects
          </Link>
        </Box>
        {/* Draft Project Section */}
        {!loading && assetData?.length > 0 && statusArr?.includes(0) ? (
          <Box className="draftsProducts-wrap">
            <Carousel
              className="productThumb-slider space-24"
              swipeable={false}
              draggable={false}
              responsive={responsive}
              ref={carouselRef}
              afterChange={(previousSlide, { currentSlide }) => {
                setCurrentSlide(currentSlide);
              }}
              // Set the initial slide to the currentSlide state
              initialSlide={currentSlide}
            >
              {(() => {
                const projects = [];
                for (let i = 0; i < assetData?.length; i++) {
                  const newStatus = assetData[i]?.dicMemberData?.find(
                    (memberItems) =>
                      memberItems?.memberDetails?.id ===
                      assetData[i].memberDetails?.id
                  );

                  if (newStatus.status === 0 && !(assetData[i]?.suggest_review || assetData[i]?.is_repayment_set || assetData[i]?.rules)) {
                    const galleryimg = [];
                    // Adding Cover Photo into slider
                    if (assetData[i]?.assetDetails?.asset_coverphoto) {
                      galleryimg.push(
                        `${process.env.REACT_APP_IMAGE_URL}${assetData[i].assetDetails?.asset_coverphoto?.path}`
                      );
                    }

                    // Inserting gellery Images Into Slider
                    assetData[i]?.assetDetails?.asset_gallery.map(
                      (val, ind) => {
                        if (val.title === "gellary") {
                          galleryimg.push(
                            `${process.env.REACT_APP_IMAGE_URL}${val?.images?.path}`
                          );
                        }
                      }
                    );
                    projects.push(
                      <Box className="slide-item">
                        <DicCard
                          id={assetData[i]?.id}
                          category_id={assetData[i]?.assetDetails?.category_id}
                          title={assetData[i]?.assetDetails?.asset_title}
                          subTitle={assetData[i]?.assetDetails?.asset_address}
                          address={
                            assetData[i]?.assetDetails?.owner_information
                              ?.owner_address
                          }
                          investType={
                            assetData[i]?.assetDetails?.investment_type
                              ?.investment_name
                          }
                          isLoan={
                            assetData[i]?.assetDetails?.assets_category?.title
                          }
                          isDraft={false}
                          isClock={false}
                          isPending={
                            assetData[i]?.assetDetails?.status === "Pending" ||
                              assetData[i]?.assetDetails?.status === "inreview"
                              ? true
                              : false
                          }
                          isDelete={
                            assetData[i]?.assetDetails?.status === "Rejected"
                              ? true
                              : false
                          }
                          isSaved={false}
                          imgSrc={galleryimg}
                          isSaveDeal={false}
                          link={`/dic/project-details/${assetData[i]?.asset_id}`}
                          investment_expiry_time={
                            assetData[i]?.assetDetails?.investment_expiry_time
                          }
                          server_date={serverDate}
                          server_time={serverTime}
                          projectProgress={
                            assetData[i]?.assetDetails?.project_process
                          }
                          memberDetails={assetData[i]?.dicMemberData}
                          dicMember={assetData[i]?.dicMember}
                          checklistData={assetData[i].checklist_data}
                          // status ={"notReviewd"}
                          status={assetData[i]?.status}
                          suggest_review={assetData[i]?.suggest_review}
                          confirm_review={assetData[i]?.confirm_review}
                          is_lead={assetData[i]?.is_lead}
                          assetDataDetail={assetData[i]}
                        />
                      </Box>
                    );
                  }
                }
                return projects;
              })()}
            </Carousel>
            {/* </Slider> */}
          </Box>
        ) : loading ? (
          <Box className="draftsProducts-wrap">
            <Carousel
              className="productThumb-slider space-24"
              swipeable={false}
              draggable={false}
              responsive={responsive}
              ref={carouselRef}
              afterChange={(previousSlide, { currentSlide }) => {
                setCurrentSlide(currentSlide);
              }}
              // Set the initial slide to the currentSlide state
              initialSlide={currentSlide}
            >
              {Array(4)
                .fill()
                .map((item, index) => (
                  <Box className="slide-item">
                    <CardSkeleton key={index} />
                  </Box>
                ))}
            </Carousel>
          </Box>
        ) : (
          <Box>
            <Typography className="title" component={"h4"}>
              No Projects Found
            </Typography>
          </Box>
        )}

        {/* Submit project section */}
        <Box className="myPorject-submittedPro-section">
          <Box paddingTop={4}>
            <Box paddingBottom={3} className="mid-text">
              <Box className="name-text">In Progress</Box>
            </Box>

            {!loading && assetData?.length > 0 && statusArr?.includes(5) ? (
              <Box className="draftsProducts-wrap">
                <Carousel
                  className="productThumb-slider space-24"
                  swipeable={false}
                  draggable={false}
                  responsive={responsive}
                  ref={carouselRefSub}
                  afterChange={(previousSlide, { currentSlide }) => {
                    setCurrentSlide(currentSlide);
                  }}
                  // Set the initial slide to the currentSlide state
                  initialSlide={currentSlide}
                >
                  {(() => {
                    const projects = [];
                    for (let i = 0; i < assetData?.length; i++) {
                      const newStatus = assetData[i]?.dicMemberData?.find(
                        (memberItems) =>
                          memberItems?.memberDetails?.id ===
                          assetData[i].memberDetails?.id
                      );

                      if (newStatus.status === 5 || newStatus.status === 0 && (assetData[i]?.suggest_review || assetData[i]?.is_repayment_set || assetData[i]?.rules)) {
                        const galleryimg = [];

                        // Adding Cover Photo into slider
                        if (assetData[i]?.assetDetails?.asset_coverphoto) {
                          galleryimg.push(
                            `${process.env.REACT_APP_IMAGE_URL}${assetData[i]?.assetDetails?.asset_coverphoto?.path}`
                          );
                        }

                        // Inserting gellery Images Into Slider
                        assetData[i]?.assetDetails?.asset_gallery.map(
                          (val, ind) => {
                            if (val.title === "gellary") {
                              galleryimg.push(
                                `${process.env.REACT_APP_IMAGE_URL}${val?.images?.path}`
                              );
                            }
                          }
                        );
                        projects.push(
                          <Box className="slide-item">
                            {/* <Grid container spacing={{ xs: 1, sm: 1, md: 1 }}> */}
                            {/* <Grid item > */}
                            <DicCard
                              id={assetData[i]?.id}
                              category_id={
                                assetData[i]?.assetDetails?.category_id
                              }
                              address={
                                assetData[i]?.assetDetails?.owner_information
                                  ?.owner_address
                              }
                              title={assetData[i]?.assetDetails?.asset_title}
                              subTitle={
                                assetData[i]?.assetDetails?.asset_address
                              }
                              investType={
                                assetData[i]?.assetDetails?.investment_type
                                  ?.investment_name
                              }
                              isLoan={
                                assetData[i]?.assetDetails?.assets_category
                                  ?.title
                              }
                              isDraft={false}
                              isClock={false}
                              isPending={
                                assetData[i]?.assetDetails?.status ===
                                  "Pending" ||
                                  assetData[i]?.assetDetails?.status ===
                                  "inreview"
                                  ? true
                                  : false
                              }
                              isDelete={
                                assetData[i]?.assetDetails?.status ===
                                  "Rejected"
                                  ? true
                                  : false
                              }
                              isSaved={false}
                              imgSrc={galleryimg}
                              isSaveDeal={false}
                              link={`/dic/project-details/${assetData[i]?.asset_id}`}
                              investment_expiry_time={
                                assetData[i]?.assetDetails
                                  ?.investment_expiry_time
                              }
                              server_date={serverDate}
                              server_time={serverTime}
                              projectProgress={
                                assetData[i]?.assetDetails?.project_process
                              }
                              memberDetails={assetData[i]?.dicMemberData}
                              dicMember={assetData[i]?.dicMember}
                              checklistData={assetData[i].checklist_data}
                              repaymentStatus={assetData[i].is_repayment_set}
                              rules={assetData[i].rules}
                              status={assetData[i].status}
                              suggest_review={assetData[i]?.suggest_review}
                              confirm_review={assetData[i]?.confirm_review}
                              is_lead={assetData[i]?.is_lead}
                              assetDataDetail={assetData[i]}
                            />
                            {/* </Grid> */}
                            {/* </Grid> */}
                          </Box>
                        );
                      }
                    }
                    return projects;
                  })()}
                </Carousel>
                {/* </Slider> */}
              </Box>
            ) : loading ? (
              <Box className="draftsProducts-wrap">
                <Carousel
                  className="productThumb-slider space-24"
                  swipeable={false}
                  draggable={false}
                  responsive={responsive}
                  ref={carouselRefSub}
                  afterChange={(previousSlide, { currentSlide }) => {
                    setCurrentSlide(currentSlide);
                  }}
                  // Set the initial slide to the currentSlide state
                  initialSlide={currentSlide}
                >
                  {Array(4)
                    .fill()
                    .map((item, index) => (
                      <Box className="slide-item">
                        <CardSkeleton key={index} />
                      </Box>
                    ))}
                </Carousel>
              </Box>
            ) : (
              <Box>
                <Typography className="title" component={"h4"}>
                  No Projects Found
                </Typography>
              </Box>
            )}
          </Box>

          <Box paddingTop={4}>
            <Box paddingBottom={3} className="mid-text">
              <Box className="name-text">Pending Vote</Box>
            </Box>

            {!loading && assetData?.length > 0 && statusArr?.includes(1) ? (
              <Box className="draftsProducts-wrap">
                <Carousel
                  className="productThumb-slider space-24"
                  swipeable={false}
                  draggable={false}
                  responsive={responsive}
                  ref={carouselRefSubAdd}
                  afterChange={(previousSlide, { currentSlide }) => {
                    setCurrentSlide(currentSlide);
                  }}
                  // Set the initial slide to the currentSlide state
                  initialSlide={currentSlide}
                >
                  {(() => {
                    const projects = [];
                    for (let i = 0; i < assetData?.length; i++) {
                      const newStatus = assetData[i]?.dicMemberData?.find(
                        (memberItems) =>
                          memberItems?.memberDetails?.id ===
                          assetData[i].memberDetails?.id
                      );

                      if (newStatus.status === 1) {
                        const galleryimg = [];
                        // Adding Cover Photo into slider
                        if (assetData[i]?.assetDetails?.asset_coverphoto) {
                          galleryimg.push(
                            `${process.env.REACT_APP_IMAGE_URL}${assetData[i]?.assetDetails?.asset_coverphoto?.path}`
                          );
                        }

                        // Inserting gellery Images Into Slider
                        assetData[i]?.assetDetails?.asset_gallery.map(
                          (val, ind) => {
                            if (val.title === "gellary") {
                              galleryimg.push(
                                `${process.env.REACT_APP_IMAGE_URL}${val?.images?.path}`
                              );
                            }
                          }
                        );
                        projects.push(
                          <Box className="slide-item">
                            <DicCard
                              id={assetData[i]?.id}
                              category_id={
                                assetData[i]?.assetDetails?.category_id
                              }
                              address={
                                assetData[i]?.assetDetails?.owner_information
                                  ?.owner_address
                              }
                              title={assetData[i]?.assetDetails?.asset_title}
                              subTitle={
                                assetData[i]?.assetDetails?.asset_address
                              }
                              investType={
                                assetData[i]?.assetDetails?.investment_type
                                  ?.investment_name
                              }
                              isLoan={
                                assetData[i]?.assetDetails?.assets_category
                                  ?.title
                              }
                              isDraft={false}
                              isClock={false}
                              isPending={
                                assetData[i]?.assetDetails?.status ===
                                  "Pending" ||
                                  assetData[i]?.assetDetails?.status ===
                                  "inreview"
                                  ? true
                                  : false
                              }
                              isDelete={
                                assetData[i]?.assetDetails?.status ===
                                  "Rejected"
                                  ? true
                                  : false
                              }
                              isSaved={false}
                              imgSrc={galleryimg}
                              isSaveDeal={false}
                              link={`/dic/project-details/${assetData[i]?.asset_id}`}
                              investment_expiry_time={
                                assetData[i]?.assetDetails
                                  ?.investment_expiry_time
                              }
                              server_date={serverDate}
                              server_time={serverTime}
                              // projectProgress={assetData[i]?.assetDetails?.project_process}
                              memberDetails={assetData[i]?.dicMemberData}
                              dicMember={assetData[i]?.dicMember}
                              pendingVote={true}
                              asset_id={assetData[i]?.asset_id}
                              checklistData={assetData[i].checklist_data}
                              // status ={"pendingVote"}
                              status={assetData[i].status}
                              suggest_review={assetData[i]?.suggest_review}
                              confirm_review={assetData[i]?.confirm_review}
                              is_lead={assetData[i]?.is_lead}
                              proposer_id={assetData[i]?.assetDetails?.user_id}
                              from={"dicproject"}
                              assetDataDetail={assetData[i]}
                            />
                          </Box>
                        );
                      }
                    }
                    return projects;
                  })()}
                </Carousel>
                {/* </Slider> */}
              </Box>
            ) : loading ? (
              <Box className="draftsProducts-wrap">
                <Carousel
                  className="productThumb-slider space-24"
                  swipeable={false}
                  draggable={false}
                  responsive={responsive}
                  ref={carouselRefSubAdd}
                  afterChange={(previousSlide, { currentSlide }) => {
                    setCurrentSlide(currentSlide);
                  }}
                  // Set the initial slide to the currentSlide state
                  initialSlide={currentSlide}
                >
                  {Array(4)
                    .fill()
                    .map((item, index) => (
                      <Box className="slide-item">
                        <CardSkeleton key={index} />
                      </Box>
                    ))}
                </Carousel>
              </Box>
            ) : (
              <Box>
                <Typography className="title" component={"h4"}>
                  No Projects Found
                </Typography>
              </Box>
            )}
          </Box>

          {/* complete project */}
        </Box>

        <CommonBackdropLoader loading={loading} />
      </>
    );
  };

  // for filter component
  const FilteredData = useCallback(
    (data) => {
      setAssetData(data);
    },
    [assetData]
  );

  const CompletedProject = () => {
    return (
      <>
        <Box>
          <Box>
            <Filter FilteredData={FilteredData} />
          </Box>
          {!loading && assetData?.length > 0 ? (
            <Box className="draftsProducts-wrap" mt={3}>
              <Grid container spacing={2}>
                {(() => {
                  const projects = [];
                  for (let i = 0; i < assetData?.length; i++) {
                    let check_voted = false;

                    if (
                      assetData[i]?.memberDetails?.length >=
                      assetData[i]?.dicMember?.length
                    ) {
                      check_voted = assetData[i]?.memberDetails?.every(
                        (elem) => elem.status === 2 && elem.status === 3
                      );
                    }

                    const newStatus = assetData[i]?.dicMemberData?.find(
                      (memberItems) =>
                        memberItems?.memberDetails?.id ===
                        assetData[i].memberDetails?.id
                    );
                    if (newStatus?.status === 2 || newStatus?.status === 3 || newStatus?.status === ASSIGNED_PROJECT_STATUS.REJECTED_BY_PROPOSER) {
                      const galleryimg = [];

                      // Adding Cover Photo into slider
                      if (assetData[i]?.assetDetails?.asset_coverphoto) {
                        galleryimg.push(
                          `${process.env.REACT_APP_IMAGE_URL}${assetData[i]?.assetDetails?.asset_coverphoto?.path}`
                        );
                      }

                      // Inserting gellery Images Into Slider
                      assetData[i]?.assetDetails?.asset_gallery.map(
                        (val, ind) => {
                          if (val.title === "gellary") {
                            galleryimg.push(
                              `${process.env.REACT_APP_IMAGE_URL}${val?.images?.path}`
                            );
                          }
                        }
                      );
                      projects.push(
                        <Grid item xs={12} sm={6} md={3}>
                          <DicCard
                            id={assetData[i]?.id}
                            category_id={
                              assetData[i]?.assetDetails?.category_id
                            }
                            address={
                              assetData[i]?.assetDetails?.owner_information
                                ?.owner_address
                            }
                            title={assetData[i]?.assetDetails?.asset_title}
                            subTitle={assetData[i]?.assetDetails?.asset_address}
                            investType={
                              assetData[i]?.assetDetails?.investment_type
                                ?.investment_name
                            }
                            ownerName={
                              assetData[i]?.assetDetails?.user_details
                                ?.first_name
                            }
                            isLoan={
                              assetData[i]?.assetDetails?.assets_category?.title
                            }
                            isDraft={false}
                            isClock={false}
                            isPending={
                              assetData[i]?.assetDetails?.status ===
                                "Pending" ||
                                assetData[i]?.assetDetails?.status === "inreview"
                                ? true
                                : false
                            }
                            isDelete={
                              assetData[i]?.assetDetails?.status === "Rejected"
                                ? true
                                : false
                            }
                            isSaved={false}
                            imgSrc={galleryimg}
                            isSaveDeal={true}
                            link={`/dic/project-details/${assetData[i]?.asset_id}`}
                            investment_expiry_time={
                              assetData[i]?.assetDetails?.investment_expiry_time
                            }
                            server_date={serverDate}
                            server_time={serverTime}
                            status={assetData[i]?.assetDetails?.status}
                            completedStatus={true}
                            is_lead={assetData[i]?.is_lead}
                            is_publish={assetData[i]?.assetDetails?.is_publish}
                            check_voted_dic={check_voted}
                            assetDataDetail={assetData[i]}
                            is_proposer_rejected={newStatus?.status === ASSIGNED_PROJECT_STATUS.REJECTED_BY_PROPOSER}
                          />
                        </Grid>
                      );
                    }
                  }
                  return projects;
                })()}
              </Grid>
              {/* </Slider> */}
            </Box>
          ) : loading ? (
            <Box className="draftsProducts-wrap">
              <Grid container spacing={{ md: 3 }}>
                <Grid item xs={2} sm={4} md={3}>
                  {Array(4)
                    .fill()
                    .map((item, index) => (
                      <Box className="slide-item">
                        <CardSkeleton key={index} />
                      </Box>
                    ))}
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box>
              <Typography className="title" component={"h4"} mt={2}>
                No Projects Found
              </Typography>
            </Box>
          )}
        </Box>
        <CommonBackdropLoader loading={loading} />
      </>
    );
  };

  return (
    <>
      <Box className="myPorject-page overview-page" pt={4}>
        <Box className="myPorject-drafts-section" pb={4}>
          <Box>
            {completedPro && (
              <Button
                className="projectPage-backBtn"
                onClick={() => setCompletedPro(false)}
              >
                <ArrowBack />
              </Button>
            )}
            <Box className="memberId-text">
              Member ID: {memberId ? memberId : ""}
            </Box>
            <Box className="name-text">Hi {userName ? userName : ""} 👋🏼</Box>
          </Box>

          <Box className="top-bar">
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <Box className="headIconBox">
                  <Box className="icon-box">
                    <AssignmentIndIcon className="head-icon" />
                  </Box>
                  <Box className="right-box">
                    <Box className="head-text">Assigned</Box>
                    <Box className="head-number">
                      {commitieHeaderInfo?.assigned ?? ""}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <Box className="headIconBox">
                  <Box className="icon-box">
                    <PendingActions className="head-icon" />
                  </Box>
                  <Box className="right-box">
                    <Box className="head-text">Not Reviewed</Box>
                    <Box className="head-number">
                      {commitieHeaderInfo?.notReviewed ?? ""}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <Box className="headIconBox">
                  <Box className="icon-box">
                    <PendingIcon className="head-icon" />
                  </Box>
                  <Box className="right-box">
                    <Box className="head-text">Reviewed</Box>
                    <Box className="head-number">
                      {commitieHeaderInfo?.Reviewed ?? ""}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <Box className="headIconBox">
                  <Box className="icon-box">
                    <HowToVoteIcon className="head-icon" />
                  </Box>
                  <Box className="right-box">
                    <Box className="head-text">Pending Vote</Box>
                    <Box className="head-number">
                      {commitieHeaderInfo?.pendingVote ?? ""}
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6} sm={4} md={3} lg={2}>
                <Box className="headIconBox">
                  <Box className="icon-box">
                    <HowToVoteIcon className="head-icon" />
                  </Box>
                  <Box className="right-box">
                    <Box className="head-text">In Progress</Box>
                    <Box className="head-number">
                      {commitieHeaderInfo?.inprogress ?? ""}
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6} sm={4} md={3} lg={2}>
                <Box className="headIconBox">
                  <Box className="icon-box">
                    <DoneIcon className="head-icon" />
                  </Box>
                  <Box className="right-box">
                    <Box className="head-text">Completed</Box>
                    <Box className="head-number">
                      {commitieHeaderInfo?.Completed ?? ""}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <Box className="headIconBox">
                  <Box className="icon-box">
                    <AttachMoneyIcon className="head-icon" />
                  </Box>
                  <Box className="right-box">
                    <Box className="head-text">Funded</Box>
                    <Box className="head-number">
                      {commitieHeaderInfo?.funded ?? ""}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {completedPro ? CompletedProject() : NotCompletedProject()}
      </Box>
    </>
  );
};
export default ProjectDic;
