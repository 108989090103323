import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

const CompleteReview = ({ submitReview }) => {
    return (
        <Box className="review-modalContent ">
            <Box className="main-content">
                <Typography component="h1" className="headTextReview">
                    By completing this Project Review, I agree to RACE’s Review <br />{" "}
                    <Link
                        className="link-review"
                        to={"/terms-and-conditions"}
                        target="_blank"
                    >
                        Terms and Conditions
                    </Link>{" "}
                    and that the Review is in accordance to <br /> RACE’s{" "}
                    <Link className="link-review">Review Guidelines.</Link>
                </Typography>
                <Button onClick={submitReview} className="wallet-button">
                    Complete Review
                </Button>{" "}
            </Box>
        </Box>
    )
}

export default CompleteReview;