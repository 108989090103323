import React, { useEffect } from 'react'
import { CardContent, CardMedia, Card, Typography } from '@mui/material'
import { darkBg } from '../../../../Components/Images/Images'

export default function DrawGridImage(props) {
    const { propData, title, artImage, drawGrid } = props;



    useEffect(() => {

        const sourceImage = document.getElementById('sourceImage');
        const canvas = document.getElementById('grid-canvas');
        const container = document.getElementById('container');

        if (canvas && sourceImage && artImage) {
            const ctx = canvas.getContext('2d');
            sourceImage.onload = () => {

                const rows = propData?.number_of_rows // Number of rows to split
                const cols = propData?.number_of_columns; // Number of columns to split

                canvas.width = sourceImage.width;
                canvas.height = sourceImage.height;

                const gridColor = 'rgba(33, 56, 79, 1)';
                const lineWidth = 1;
                // Calculate grid cell dimensions
                const cellWidth = canvas.width / cols;
                const cellHeight = canvas.height / rows;
                // Draw vertical grid lines
                for (let i = 1; i < cols; i++) {
                    const x = i * cellWidth;
                    ctx.beginPath();
                    ctx.moveTo(x, 0);
                    ctx.lineTo(x, canvas.height);
                    ctx.strokeStyle = gridColor;
                    ctx.lineWidth = lineWidth;
                    ctx.stroke();
                }

                // Draw horizontal grid lines
                for (let i = 1; i < rows; i++) {
                    const y = i * cellHeight;
                    ctx.beginPath();
                    ctx.moveTo(0, y);
                    ctx.lineTo(canvas.width, y);
                    ctx.strokeStyle = gridColor;
                    ctx.lineWidth = lineWidth;
                    ctx.stroke();
                }
            }
            // Set canvas size to match the container
            canvas.width = container.clientWidth;
            canvas.height = container.clientHeight;

        }
    }, [artImage])
    return (
        <Card className='rc-parcel-edit-card' id="container">
            <CardMedia component="img"
                id="sourceImage"
                image={artImage} />
            {(propData?.selectedCategory === "art" && drawGrid) && <canvas id="grid-canvas"></canvas>}
            {(propData?.artProjectDetails?.art_name && title) && <CardContent>
                <Typography color={'black'} variant="p" fontWeight={"500"}>{propData?.artProjectDetails?.art_name}</Typography>
            </CardContent>
            }

        </Card>
    )
}
