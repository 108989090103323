import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useParams, useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import LeftPersonProfileSection from './LeftPersonProfileSection'
import RightPersonProfileSection from './RightPersonProfileSection'
import ServicesSearch from '../Components/ServicesSearch/ServicesSearch'
import { Box, Grid } from '@mui/material'
import './IndividualProfilePage.css'
import { GetApi, GetApiParam } from '../../Api/Api'
import { getUserDataFromLocalStorage } from '../../Services/localstorage.service';

function IndividualProfilePage() {
  const [categoryData, setCategoryData] = useState(null);
  const [profileData, setProfileData] = useState({});
  const { id } = useParams();
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const timerRef = useRef();
  const loggedInUserData = getUserDataFromLocalStorage();

  useEffect(() => () => {
    clearTimeout(timerRef.current);
  }, []);

  useEffect(() => {
    async function getCategoryData() {
      try {
        const res = await GetApi("/services/spCategory");
        setCategoryData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }


    async function getProfileData() {
      try {
        setIsLoading(true)
        let payload = {
          user_id: userId ? userId : "",
          login_user_id: loggedInUserData?.id
        }
        const res = await GetApiParam("/services/ProfileDetails", payload);
        setProfileData(res?.data?.data && res?.data?.data?.length > 0 ? res?.data?.data[0] : {});
        setIsLoading(false)
      } catch (error) {
        console.log(error);
      }
    }

    getCategoryData()
    if (userId) {
      getProfileData()
    }
  }, [userId])

  useEffect(() => {
    if (id) {
      setUserId(id)
    }
  }, [id])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [])

  const handleBack = () => {
    navigate(-1)
  };
  return (
    <>
      {isLoading ?
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh' 
          }}
        >
          <CircularProgress disableShrink />
        </Box>
        :
        <Box className="container">
          <ServicesSearch showSearchBar={true} pageTitle={'Service Catalog'} categoryData={categoryData} />
          {/* <Box className="top-view">
          <Box>
            <Stack direction="column" spacing={2} >
              <Box className="go-back-btn">
                <Button startIcon={<ArrowBackIcon />} onClick={handleBack}></Button>
              </Box>
            </Stack>
          </Box>
        </Box> */}
          <Grid container spacing={'20px'} mt={2} >
            <Grid item xs={12} md={8.5} >
              <LeftPersonProfileSection profileData={profileData ? profileData : null} offerId={location.state?.offer_id ? location.state?.offer_id : ''} projectId={location.state?.project_id ? location.state?.project_id : ''} />
            </Grid>
            <Grid item xs={12} md={3.5} >
              <RightPersonProfileSection profileData={profileData ? profileData : null} />
            </Grid>
          </Grid>
        </Box>
      }
    </>
  )
}

export default IndividualProfilePage