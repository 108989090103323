import React from 'react'
import { useAccount, useDisconnect } from 'wagmi';
import { Avatar, Box, Button, Fade, Modal, Typography } from '@mui/material'
import { Close, Done } from '@mui/icons-material';
import { NewLogo } from "../../Components/Images/Images";
import { updateApi } from '../../Api/Api';
import { useSnackbar } from '../../Contexts/SnackbarContext';
import PropTypes from 'prop-types';

const style = {
    position: "relative",
    boxShadow: 24,
    borderRadius: "24px",
    width: "976px",
    maxHeight: '90vh'
};

/**
 * This component is used to show confirmation modal when user tries to connect wallet to his account
 * checks if wallet is already associated with another account, if yes then shows error message
 * else updates user wallet address in db also updates redux store with new wallet address if applicable
 * @param {object} props Component props
 * @param {boolean} props.openModal  state to open confirmation modal 
 * @param {function} props.setOpenModal  func to set confirmation modal state
 * @param {function} props.onUpdateSuccess  callback func to be called when user wallet is successfully updated in db
 * @returns {React.JSX.Element}
 */
export default function ConfirmWalletModal({ openModal, setOpenModal, onUpdateSuccess, closeTransactionModal }) {

    const { address } = useAccount();
    const { showSnackbar } = useSnackbar();
    const { disconnect } = useDisconnect();

    /**
    * func updates user wallet address in db also updates redux store with new wallet address if applicable
    * or else removes wallet address from redux store
    * @param {string} acc wallet address
    */
    const updateUserWallet = async (acc) => {
        let user = JSON.parse(localStorage.getItem('user_data'));
        try {
            const res = await updateApi('/user/updateUser/' + user?.id, { wallet_address: acc });
            if (res?.data?.code === 200) {
                showSnackbar('Wallet connected successfully', 'success');
                // dispatch(update({ wallet_address: acc }));
                onUpdateSuccess(acc);
                if (closeTransactionModal) {
                    closeTransactionModal();
                }
            } else if (res?.data?.code === 201) {
                showSnackbar("Wallet is already registered with another account", 'error');
                // dispatch(update({ wallet_address: null }));
                disconnect();
            }
            else {
                showSnackbar(res?.data?.message, 'error');
                // dispatch(update({ wallet_address: null }));
                disconnect();
            }
            handleConfirmationModalClose();

        } catch (error) {
            showSnackbar('Something went wrong', 'error');
            handleConfirmationModalClose();
            disconnect();
        }
    }

    /**
     * Function handles close of confirmation modal
     * @param {*} reason  reason for modal close auto generated by material ui
     */
    const handleConfirmationModalClose = (reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return; //do nothing basically do not close modal
        } else {
            setOpenModal(false);
        }
    };

    /**
     * Function handles confirmation of wallet to be associated with user account
     * @param {*} answer  answer from user yes or no
     */
    const handleConfirmation = (answer) => {
        if (answer === 'yes') {
            updateUserWallet(address);
        } else {
            handleConfirmationModalClose();
            // dispatch(update({ wallet_address: null }));
            disconnect();
        }
    }

    return (
        <Modal
            open={openModal}
            className="connectWallet-alert-modal"
            onClose={handleConfirmationModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEscapeKeyDown={true}
        >
            <Fade in={openModal} timeout={300}>
                <Box className="modal-body" sx={style}>
                    <Box className="headContent">
                        <Box className="logo">
                            <Avatar
                                alt="Logo"
                                src={NewLogo}
                                variant="square"
                                sx={{ width: 39, height: 90 }}
                            />
                        </Box>
                    </Box>
                    <Box className="modalContent " >
                        <Box className="main-content" >
                            <Typography component="h1" className="headText">Confirmation</Typography >
                            <Typography component="p" className="sub-headText">Are you sure you want to associate this wallet : {address} with your account!</Typography >
                            <Box className='vote-btn-box' >
                                <Button className="wallet-button appr-btn" onClick={() => { handleConfirmation('yes') }} > <Box className="btn-icon" ><Done />Yes, Associate</Box></Button>
                                <Button className="wallet-button rjct-btn" onClick={() => { handleConfirmation('no') }}><Box className="btn-icon" ><Close />Cancel</Box></Button>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}


ConfirmWalletModal.propTypes = {
    openModal: PropTypes.bool.isRequired,
    setOpenModal: PropTypes.func.isRequired,
    /**
     * callback func to be called when user wallet is successfully updated in db
     * @returns {string} wallet address
     */
    onUpdateSuccess: PropTypes.func.isRequired
}