import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import "./Popup.css";
import { Box, Grid, Radio, Stack } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { CheckedRadioButton, BusinessCenter, BusinessCenterGray } from '../../Components/Images/Images';
import RadioUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useNavigate } from "react-router-dom";
import { getServiceProviderFromLocalStorage, 
    getServiceProposerFromLocalStorage,
    setServiceProviderInLocalStorage,
    setServiceProposerInLocalStorage,
    removeServiceProviderFromLocalStorage,
    removeServiceProposerFromLocalStorage } from '../../Services/localstorage.service';


const Popup = ({ open, handleCloseModal }) => {
    const [selectedBox, setSelectedBox] = useState('Client');
    const navigate = useNavigate();


    const isLoggedIn = localStorage.getItem("login") ? JSON.parse(localStorage.getItem("login")) : ""


    /**
     * Onclick function for the Boxes
     * @param {*} box 
     */
    const handleBoxClick = (box) => {
        setSelectedBox(box);
        if(box === 'Client'){
            setServiceProposerInLocalStorage();
            removeServiceProviderFromLocalStorage();
        }
        if(box === 'Service Provider'){
            setServiceProviderInLocalStorage();
            removeServiceProposerFromLocalStorage();
        }
    };

    useEffect(() => {
        if(!selectedBox && selectedBox === 'Client'){
            setServiceProposerInLocalStorage();
            removeServiceProviderFromLocalStorage();
        }
    }, []);

    /**
    Function calls on close button click.
     */
    const handleClose = () => {
        handleCloseModal(); // Call handleCancel function passed from the Footer component
        window.scrollTo(0, 0);
    };


    /**
     * Onclick function for the create Account button that will redirect to the Sign Up Flow
     */
    const handleCreateAccount = () => {
        if(selectedBox === 'Client'){
            navigate("/service-proposer-register");
            handleClose();
        }else{
            navigate("/service-provider-register");
            handleClose();
        }
    }

    /**
     * Onclick function to redirect on the next page with the selected box data
     */
    const handleNext = () => {
        if (selectedBox === "Client") {
            navigate("/service-proposer")
            localStorage.setItem("service-proposer" , true)
            handleClose();
        } else {
            navigate("/service-provider" )
            localStorage.setItem("service-provider", true)
            handleClose();
        }
    }

    /**
     * Onclick function for the Login 
     */
    const handleLogin = () => {
        //  navigate("/login", { state: { from: "ServiceModule" , type : selectedBox } });
        if(selectedBox === 'Client'){
            navigate("/service-proposer-login", { state: { from: "ServiceModule" , type : selectedBox } });
        }else{
            navigate("/service-provider-login", { state: { from: "ServiceModule" , type : selectedBox } });
        }
    }

    /**
    * Style for the Modal
     */
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        borderRadius: "24px",
        width: "900px",
        padding: " 32px 66px 66px 66px",
        background: "var(--blueGray-900)",
        // Media query for small devices
        '@media (max-width: 600px)': {
            width: '350px', // Set a different height for small screens
        },
        // Additional media queries for other screen sizes if needed
        '@media (min-width: 601px) and (max-width: 900px)': {
            // Custom styles for devices between 601px and 900px width
            width: '580px'
        },

        '@media (min-width: 901px) and (max-width: 1200px)': {
            // Custom styles for devices between 901px and 1200px width
            width: '750px'
        },

        // Specify the maximum width for large screens
        '@media (min-width: 1201px)': {
            maxWidth: "1043px",
            maxHeight: "687px",
        },
    };

    return (
        <>
            <Box>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className='service-provider-popup-main'
                    sx={{overflow: 'auto'}}
                >
                    <Box sx={style}>
                        <Box className="close-icon">
                            <CloseIcon onClick={handleClose} />
                        </Box>
                        <Typography id="modal-modal-title" variant="h6" component="h2" className='heading font-36'>
                            Join as a Client or Service Provider
                        </Typography>
                        <Box className="service-provider-box">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} sm={12}>
                                    <Box className={`box-first ${selectedBox === 'Client' ? 'selected' : ''}`} onClick={() => handleBoxClick('Client')}>
                                        <Box className="top-view">
                                            <Box className="left-icon">
                                                <img src={selectedBox === 'Client' ? BusinessCenter : BusinessCenterGray} alt='' />
                                            </Box>
                                            <Box className="right-icon">
                                                {selectedBox === 'Client' ? <img src={CheckedRadioButton} /> : <RadioUncheckedIcon />}
                                            </Box>
                                        </Box>
                                        <Typography className='popup-disc font-28'>I’m a Client, hiring for a project</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} sm={12}>
                                    <Box className={`box-second ${selectedBox === 'Service Provider' ? 'selected' : ''}`} onClick={() => handleBoxClick('Service Provider')}>
                                        <Box className="top-view">
                                            <Box className="left-icon">
                                                <img src={selectedBox === 'Service Provider' ? BusinessCenter : BusinessCenterGray} alt='' />
                                            </Box>
                                            <Box className="right-icon">
                                                {selectedBox === 'Service Provider' ? <img src={CheckedRadioButton} /> : <RadioUncheckedIcon />}
                                            </Box>
                                        </Box>
                                        <Typography className='popup-disc font-28'>I’m a Service Provider, providing services</Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Stack direction="row" spacing={2} className='create-acc-btn'>
                                {isLoggedIn ?
                                    <Button variant="contained" startIcon={<ArrowForwardIcon />} className='btn-rounded btn-large'
                                        onClick={() => handleNext()}
                                    >
                                        Next
                                    </Button>
                                    :
                                    <Button variant="contained" startIcon={<ArrowForwardIcon />} className='btn-rounded btn-large'
                                        onClick={() => handleCreateAccount()}
                                    >
                                        Create Account
                                    </Button>
                                }
                            </Stack>
                            {!isLoggedIn ?
                                <Typography className='bottom-text font-18' component="p">Already have an account? <span className='log-in-span' onClick={() => handleLogin()}>Log In</span></Typography>
                                : ""
                            }
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </>
    )
}

export default Popup