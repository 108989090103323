import React, { useState, useEffect } from 'react'
import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import ListCard from '../Components/ListCard/ListCard';
import { CHInformatics, User } from '../../Components/Images/Images';
import { Assignment } from '@mui/icons-material';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import "./IndividualProfilePage.css";

function RightPersonProfileSection({ profileData }) {

  const [userIndividualProfile, setUserIndividualProfile] = useState([])
  const [language, setLanuage] = useState([])
  const [experienceDetails, setExperienceDetail] = useState([])
  const [organizationVal, setOrganizationVal] = useState([])

  /**
   * Setting Profile Data into State Variable
   */
  useEffect(() => {
    if (profileData)
      setUserIndividualProfile(profileData)
  }, [profileData])


  /**
   * Setting Languages for the Left side Bar section
   */
  useEffect(() => {
    if (profileData) {
      const profileLanguage = profileData?.language ? JSON.parse(profileData?.language) : null;

      // Map languages and proficiencies, then join them with "|"
      const languageSelected = profileLanguage && profileLanguage.map((val) => {
        return `${val?.language}:${val?.proficiency}`;
      }).join("<br />");

      setLanuage(languageSelected);

      // Setting associated Organization
      const associatedOrganization = profileData?.associated_organization ? JSON.parse(profileData?.associated_organization) : null;

      // Map languages and proficiencies, then join them with "|"
      const organization = associatedOrganization && associatedOrganization.map((val) => {

        return `${val?.associated_organization}`;
      }).join(" | ");

      setOrganizationVal(associatedOrganization);


      // Filter experienceData to get the object where currentlyWorking is true
      const currentExperience = profileData && profileData?.experienceProfile
        ?.filter(experience => experience.currentlyWorking);
      setExperienceDetail(currentExperience);
    }
  }, [profileData]);


  return (
    <Box p={3} className="right-person-profile-container">
      {/* Working details */}
      <Grid container spacing={1} >
        <Grid p={2} item md={6} xs={12}>
          <Typography className='font-32'>{profileData?.totalJobs ? profileData?.totalJobs : 0}</Typography>
          <Typography className='font-14' color={'#64748B'}>Total Jobs</Typography>
        </Grid>
        <Grid p={2} item md={6} xs={12}>
          <Typography className='font-32'>{profileData?.totalHourse ? profileData?.totalHourse : '0,000'}</Typography>
          <Typography className='font-14' color={'#64748B'}>Total Hours</Typography>
        </Grid>
      </Grid>

      <Divider style={{ paddingTop: '8px', marginBottom: '8px', borderColor: '#94A3B8', height: '2px' }} />
      <Section title={''}>
        <Stack direction="row" justifyContent={'space-between'} mb={2} >
          <Typography variant="subtitle2" className="font-12" >Available</Typography>
          <Typography textAlign={'right'} variant="subtitle1" className="font-12 text-blueGray-400" >{profileData && profileData?.availability === "open" ? "As Needed - Open to Offers" : 'As Needed - Not Available'}</Typography>
        </Stack>
        <Stack direction="row" justifyContent={'space-between'} mb={2} >
          <Typography variant="subtitle2" className="font-12" >Languages</Typography>
          <Typography textAlign={'right'} variant="subtitle1" className="font-12 text-blueGray-400" dangerouslySetInnerHTML={{__html: language}} />
        </Stack>
        <Stack direction="row" justifyContent={'space-between'} mb={2} >
          <Typography variant="subtitle2" className="font-12" >Verifications</Typography>
          <Typography textAlign={'right'} variant="subtitle1" className="font-12 text-blueGray-400" >Email Verified</Typography>
        </Stack>
      </Section>

      <Section title={'Education'}>
        {profileData?.educationProfile?.length ? profileData?.educationProfile?.map((val) => {
          // Create Date objects from start and end dates
          const startDate = new Date(val.startDate);
          const endDate = new Date(val.endDate);

          // Extract years from start and end dates
          const startYear = startDate.getFullYear();
          const endYear = endDate.getFullYear();

          return <Box>
            <Typography variant="subtitle2" className="font-12" mb={2} >{val?.school}</Typography>
            <Typography variant="subtitle1" className="font-12 text-blueGray-400" mb={2}>
              {val?.degree} <br />
              {val?.fieldOfStudy} <br />
              {startYear}-{endYear}</Typography>
          </Box>
        })
          : "No Education details Found"
        }
      </Section>

      <Section title={'Associated with'}>
      {organizationVal?.length ? (
        <>
          <Box ml={-2} mt={1}>
            {organizationVal?.length > 0 ? organizationVal?.map((val, ind) => {
              return <ListCard key={ind} listIcon={<ApartmentOutlinedIcon />} bgColor={'#fff'} iconColor="#398CD1" type={'icon'} title={val?.associated_organization} subtitle1={""} subtitle1Color='#398CD1' eyeIcon={false}  />
            })
              : "No Orgainzation Available"
            }
         </Box>
        </>
      ) : null}
      </Section>

      <Section title={'Documents'}>
        <Box ml={-2}>
          {profileData?.documents?.length > 0 ? profileData?.documents.map((val, ind) => {
            return <ListCard key={ind} listIcon={<Assignment />} bgColor={'#fff'} iconColor="#398CD1" type={'icon'} title={val?.original_name} subtitle1={val?.title} subtitle1Color='#398CD1' fileVal={val}/>
          })
            : "No Documents Available"
          }
        </Box>
      </Section>
    </Box>
  )
}

export default RightPersonProfileSection

function Section({ children, title }) {
  return (
    <Box >
      <Typography variant="subtitle1" fontWeight={'500'} className='font-16' mb={'4px'}>{title}</Typography>
      <Box p={2}>
        {children}
      </Box>
    </Box>
  )
}
