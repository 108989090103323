import React, { useRef, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types'
import { Box, Button, FormControl, ImageListItem, InputBase, InputLabel, ListItemText, Menu, Popper, Select, TextField, Typography } from "@mui/material";
import { GetApiParam } from '../../Api/Api';
import { MenuItem } from '@mui/material';
import { GetApi } from '../../Api/Api';
import SearchIcon from '@mui/icons-material/Search';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import BarChartIcon from '@mui/icons-material/BarChart';
import HomeIcon from '@mui/icons-material/Home';
import RestoreIcon from '@mui/icons-material/Restore';
import { PROJECT_STATUS } from '../../constants';
export default function Filter({ FilteredData, defaultFilter, defaultSearchString, defaultSortBy, onSortChange, sortByOptions }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [suggestions, setSuggestions] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const searchBarRef = useRef(null); //search bar 
    const [filterValues, setFilterValues] = useState({
        category_id: 0,
        investment_type_id: 0,
        investment_val: 'All',
        max_amount: null,
        min_amount: null,
        status: PROJECT_STATUS.SUBMITTED
    });
    const [sortBy, setSortBy] = useState("Default");
    const [categoryData, setCategoryData] = useState(); //category data
    const [investmentTypeData, setInvestmenTypeData] = useState([]); //investment data
    const [investVal, setInvestVal] = useState([{ label: '$500 and under' }, { label: '$500-$1000' }, { label: '$1000-$5000' }, { label: '$5000+' }]); //min investment

    const [anchorElSort, setAnchorElSort] = React.useState(null); //sort
    const open = Boolean(anchorElSort);

    useEffect(() => {
        getCategoryData();
        getInvestMentTypeData();
    }, []);


    // Checking if any default filter is present then filter the data
    useEffect(() => {

        if (defaultSearchString) {
            const inputElement = searchBarRef.current.querySelector('input');
            if (inputElement) {
                inputElement.value = defaultSearchString;
            }
            getFilterDataBySearch(defaultSearchString);
        }
        if (defaultFilter) {
            setFilterValues(defaultFilter);
        }
        if (defaultSortBy) {
            handleSortChange(defaultSortBy);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultFilter, defaultSearchString, defaultSortBy]);


    /**
     * 
     * @param {*} value search string to search
     * @returns setSuggestions list fetched from the api
     */
    const suggestionSearch = async (value) => {
        if (!value) return setSuggestions([]);
        const payloadforsuggestion = {
            asset_title: value,
            status: PROJECT_STATUS.SUBMITTED,
            is_onboard: 1,
            is_publish: 1
        };
        if (filterValues.category_id) {
            payloadforsuggestion.category_id = filterValues.category_id
        }
        if (filterValues.investment_type_id) {
            payloadforsuggestion.investment_type_id = filterValues.investment_type_id
        }
        const res = await GetApiParam("/proposer/SearchByName", payloadforsuggestion);
        setSuggestions(res.data.data);
        if (!anchorEl) setAnchorEl(searchBarRef.current);
    }

    /**
     * if page is search then filter the data 
     *  else navigate to search page with state of search string
     * @param {*} value search suggestion value
     */
    const searchSuggestionValue = (value) => {
        setSuggestions([]);
        if (location.pathname === "/search" || location.pathname === "/user/projects") {
            getFilterDataBySearch(value);
            // Display suggestion in searchBar
            const inputElement = searchBarRef.current.querySelector('input');
            if (inputElement) {
                inputElement.value = value;
            }
        } else {
            navigate(`/search`, { state: { searchString: value } })
        }
    }


    // Category filter ---------------------

    /**
     * Api to get Category Type Data
     */
    async function getCategoryData() {
        try {
            const res = await GetApi("/proposer/getCategory/");
            setCategoryData(res?.data?.data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeCategory = (event) => {
        setFilterValues({ ...filterValues, category_id: event.target.value });
    };

    // Investment Type Filter --------------

    const handleChangeInvestmentType = (event) => {
        setFilterValues({ ...filterValues, investment_type_id: event.target.value });
    };

    /**
     * Api to get investment Type Data
     */
    async function getInvestMentTypeData() {
        try {
            const res = await GetApi("/proposer/investmentType/");
            setInvestmenTypeData(res?.data?.data);
        } catch (error) {
            console.log(error);
        }
    }

    // Sorting -----------------------------

    const handleSortChange = (value) => {
        // if page is search then filter the data
        if (location.pathname === "/search" || location.pathname === "/user/projects") {
            setSortBy(value);
            onSortChange(value);
        } else { // else navigate to search page with state of payload
            navigate(`/search`, { state: { sortBy: value } })
        }

    };

    const handleClick = (event) => {
        setAnchorElSort(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElSort(null);
    };

    const firstRenderRef = useRef(true);

    // Setting filteration acccording to input
    useEffect(() => {

        if (firstRenderRef.current) {
            firstRenderRef.current = false;
            return;
        }

        // setting payload for filteration
        let filterDataPayload = {
            ...filterValues, status: PROJECT_STATUS.SUBMITTED, is_onboard: 1,
            is_publish: 1
        };
        if (filterDataPayload.category_id === 0) delete filterDataPayload.category_id;
        if (filterDataPayload.investment_type_id === 0) delete filterDataPayload.investment_type_id;
        if (filterDataPayload.investment_val === null) delete filterDataPayload.investment_val;
        if (filterDataPayload.min_amount === null) delete filterDataPayload.min_amount;
        if (filterDataPayload.max_amount === null) delete filterDataPayload.max_amount;
        // setting for range
        if (filterDataPayload.investment_val) {
            switch (filterDataPayload.investment_val) {
                case '$500 and under':
                    filterDataPayload.max_amount = 500;
                    delete filterDataPayload.min_amount;
                    break;
                case '$500-$1000':
                    filterDataPayload.min_amount = 500;
                    filterDataPayload.max_amount = 1000;
                    break;
                case '$1000-$5000':
                    filterDataPayload.min_amount = 1000;
                    filterDataPayload.max_amount = 5000;
                    break;
                case '$5000+':
                    filterDataPayload.min_amount = 5000;
                    delete filterDataPayload.max_amount;
                    break;
                // Add a default case if needed
                default:
                    // Handle the case when filterDataPayload.investment_val doesn't match any of the specified cases.
                    break;
            }
        }
        // if page is search then filter the data
        if (location.pathname === "/search" || location.pathname === "/user/projects") {
            getFilterData(filterDataPayload);
        } else { // else navigate to search page with state of payload
            navigate(`/search`, { state: { defaultFilter: filterDataPayload } })
        }

        setSortBy(sortByOptions[0]); // setting sort by to default

    }, [filterValues]);


    /**
     * 
     * @param {*} filterPayload payload for API filteration  empty payload will return all data
     * calls FilteredData to set data in parent component 
     * 
     */
    const getFilterData = async (filterPayload) => {
        try {
            const res = await GetApiParam(
                "/proposer/getAssetsList/",
                filterPayload
            );

            if (res?.data?.data) {
                FilteredData(res?.data?.data);
            }
        } catch (error) {
            console.log(error);
        }
    }


    /**
     * 
     * @param {*} toSearch search string to search
     * calls FilteredData to set data in parent component
     */
    const getFilterDataBySearch = async (toSearch) => {
        const payloadData = {
            "asset_title": toSearch,
            "status": PROJECT_STATUS.SUBMITTED,
            "category_id": FilteredData?.status,
            "is_onboard": 1,
            "is_publish": 1
        }

        try {
            const res = await GetApiParam("/proposer/getAssetsList/", payloadData);
            // setSearchData(res?.data?.data)
            FilteredData(res?.data?.data)
        } catch (error) {
            console.log(error)
        }

    }

    /**
     * Function to handle range change
     * @param {event} event 
     */
    const handleRangeFilter = (event) => {
        setFilterValues({ ...filterValues, investment_val: event.target.value });
    };

    return (
        <>
            {/* Search bar filter */}
            <Box className="filters-group-wrap">
                <Box className="filterSearch-form">
                    <Box component={"span"} className="search-icon">
                        <SearchIcon />
                    </Box>
                    <InputBase
                        ref={searchBarRef}
                        className="filterSearch"
                        defaultValue={defaultSearchString}
                        placeholder="Search Projects"
                        onChange={(e) => suggestionSearch(e.target.value)}
                        inputProps={{ "aria-label": "search transactions" }}
                    />

                    <Popper className='filterSearch-dropdown' open={suggestions.length > 0} anchorEl={anchorEl} >
                        {suggestions && suggestions.map((value, ind) => {
                            return (
                                <MenuItem
                                    key={ind}
                                    value={value.asset_title}
                                    onClick={() => {
                                        searchSuggestionValue(value.asset_title)
                                    }}
                                >
                                    <Box className="suggestionOption" >
                                        <Box className='advanceSearch'><RestoreIcon />
                                            <div className="suggest-asset-title">
                                                {value?.asset_title}
                                            </div>
                                        </Box>
                                        <Box className='advanceSearch icon' ><HomeIcon /></Box>
                                    </Box>
                                </MenuItem>
                            );
                        })}
                    </Popper>
                </Box>
            </Box>

            {/* Category filter */}
            <Box className="select-group" ml={1.5}>
                <FormControl className="select-rounded" sx={{ minWidth: '150px' }} size="small">
                    <InputLabel id="demo-controlled-open-select-label">
                        Category
                        <ViewComfyIcon />
                    </InputLabel>
                    <Select
                        labelId="demo-controlled-open-select-label"
                        value={filterValues.category_id}
                        onChange={handleChangeCategory}
                        label="Category"
                        variant='outlined'
                    >
                        <MenuItem value={0}>All</MenuItem>
                        {categoryData?.map((value, ind) => {
                            return (
                                <MenuItem key={ind} value={value.id}>
                                    <ListItemText>{value.title}</ListItemText>
                                    <Typography className="menu-icon">
                                        <ImageListItem>
                                            <img
                                                src={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                                srcSet={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                                alt={value.category_icon?.original_name}
                                                loading="lazy"
                                            />
                                        </ImageListItem>
                                    </Typography>
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>

            {/* Investment Type Filter */}
            <Box className="select-group" ml={1.5}>
                <FormControl
                    className="select-rounded"
                    sx={{ minWidth: 190 }}
                    size="small"
                >
                    <InputLabel id="demo-controlled-open-select-label">
                        Investment Type
                        <BarChartIcon />
                    </InputLabel>

                    <Select
                        labelId="demo-controlled-open-select-label"
                        value={filterValues.investment_type_id}
                        label="Investment Type"
                        onChange={handleChangeInvestmentType}
                    >
                        <MenuItem value={0}>All</MenuItem>
                        {investmentTypeData?.map((value, ind) => {
                            return (
                                <MenuItem
                                    key={ind}
                                    value={value.id}
                                >
                                    {value.investment_name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>

            {/* Min Investment */}
            <Box className="select-group" ml={1.5}>
                <FormControl
                    className="select-rounded"
                    sx={{ minWidth: 190 }}
                    size="small"
                >
                    <InputLabel id="demo-controlled-open-select-label">
                        Min. Investment
                    </InputLabel>

                    <Select
                        labelId="demo-controlled-open-select-label"
                        value={filterValues.investment_val}
                        label="Investment Type"
                        onChange={handleRangeFilter}
                    >
                        <MenuItem value={'All'}>All</MenuItem>
                        {investVal?.map((items, ind) => {
                            return (
                                <MenuItem
                                    key={ind}
                                    value={items.label}
                                >
                                    {items.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>

            {/* Min Investment */}
            {/* <Box className="select-group" ml={1.5}>
                <TextField
                    className="input-rounded"
                    sx={{ width: "150px" }}
                    size="small"
                    type="text"
                    // onChange={handleChangeMin}
                    id="outlined-basic"
                    label="Min. Investment"
                    variant="outlined"
                />
            </Box> */}

            {/* Sorting  */}
            <Box className="dropdown-menu-wrap" ml={1.5}>
                <Button
                    endIcon={<SwapVertIcon />}
                    // onClick={() => sortByName()}
                    aria-controls={open ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    className="btn-rounded btn-outline"
                >
                    Sort By: {sortBy}
                </Button>
                <Menu
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    anchorEl={anchorElSort}
                    open={open}
                    onClose={handleClose}
                    className="dropdown-menu filterCategory-menu"
                >

                    {
                        sortByOptions.map((value, ind) => {
                            return (
                                <MenuItem key={ind}
                                    selected={sortBy === value ? true : false}
                                    onClick={() => {
                                        handleSortChange(value);
                                        setAnchorElSort(null); // done seperately to close the menu after click
                                    }}
                                >
                                    {value}
                                </MenuItem>
                            );
                        })
                    }

                </Menu>
            </Box>
        </>
    )
}


Filter.propTypes = {
    FilteredData: PropTypes.func,
    defaultFilter: PropTypes.shape({
        category_id: PropTypes.number,
        investment_type_id: PropTypes.number
    }),
    defaultSearchString: PropTypes.string,
    defaultSortBy: PropTypes.string,
    onSortChange: PropTypes.func,
    sortByOptions: PropTypes.arrayOf(PropTypes.string)
}

Filter.defaultProps = {
    sortByOptions: ["Default", "Name", "Asset Type"]
}


