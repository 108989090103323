import React from 'react';
import { Avatar, Box, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './RequestCard.css'

const RequestCard = ({ Icon, Heading, SubHeading, url }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        if (url) {
            const state = { title: Heading };
            navigate(`/service-proposer/client-category/${url}`, { state });
        }
    }
    return (
        <Box className="request-card" onClick={handleClick}>
            <Box className='Icon-border'>
                <Avatar src={Icon} className="Icon" variant='rounded' />
            </Box>
            <Box pt={3}> 
                <Typography className="Heading font-36 ">{Heading}</Typography>
                <Typography className="SubHeading font-18">{SubHeading}</Typography>
            </Box>
        </Box>
    )
}

RequestCard.propTypes = {
    Icon: PropTypes.elementType.isRequired,
    Heading: PropTypes.string.isRequired,
    SubHeading: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default RequestCard;