import { Box, Button, Chip, Paper, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { GetApi } from '../../../Api/Api'
// import KYCForm from '../../KYC/KYCForm';
import KYCShufti from '../../KYC/KYCShufti';
import { Link } from 'react-router-dom';

const privacyLink = <Link to={'/privacy-policy'} target="_blank" rel="noopener noreferrer" style={{ fontWeight: 700, color: '#1771BD' }}>&nbsp;Privacy Policy.</Link>;

export default function IndentityKyc() {
    // State variables to manage KYC status and KYC modal
    const [isKYCDone, setIsKYCDone] = useState(false);
    const [kycModal, setKycModal] = useState(false)

    // useEffect to check KYC status 
    useEffect(() => {
        const timer = setTimeout(() => {
            checkKyc();
        }, 100);
        return () => clearTimeout(timer)
    }, [])

    // Function to check KYC status using an API call
    const checkKyc = async (url) => {
        try {
            let u = localStorage.getItem('user_data')
            u = JSON.parse(u)
            const res = await GetApi(`user/getUser/${u.id}`);
            if (res?.data && res?.data?.data?.kyc_status) {
                setIsKYCDone(true);
            }

        } catch (error) {
            console.log(error)
        }
    }

    // Callback function to handle the closing of the KYC modal
    const handleKycModalClose = useCallback(() => {
        setKycModal(false)
        const timer = setTimeout(() => {
            checkKyc();
        }, 100);
        return () => clearTimeout(timer)
    }, [])

    return (
        <>
            <Paper className="rc-basic-container" id="KYC-Check">
                <Stack direction={{ xs: 'column', md: 'row' }} display={'inline-flex'} spacing={3} alignItems={'center'} mb={1.5} mt={2}>
                    <Typography variant='h6' className='font-16' >Identity Verification (KYC)</Typography>
                    {
                        !isKYCDone ?
                            <Chip sx={{ color: '#032744', fontWeight: 500, bgcolor: '#ede4c5', borderRadius: '8px', width: { xs: '-webkit-fill-available', md: 'auto' }, }} label={'Incomplete KYC'} />
                            :
                            <Chip sx={{ color: '#032744', fontWeight: 500, bgcolor: '#34D399', borderRadius: '8px', width: { xs: '-webkit-fill-available', md: 'auto' }, }} label={'KYC Completed'} />
                    }
                </Stack>

                <Typography className='rc-small-secondary-text'>
                    RACE is required by U.S. financial laws to verify the identity of all investors.<br />
                    Your identity information will be used to verify your identity. It will be stored securely and will not be shared without your permission, except as required by law. For additional information visit our
                    {privacyLink}
                </Typography>
                {!isKYCDone && <Box mt={2} mb={2.5}>
                    <Button onClick={() => setKycModal(true)} className="btn-rounded btn-text-white btn-blue-500">Verify Identity</Button>
                </Box>}
            </Paper>
            {kycModal ? <KYCShufti kycOpen={kycModal} onClose={handleKycModalClose} /> : null}
        </>
    )
}
