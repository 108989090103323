import React, { useCallback, useEffect, useState } from 'react'
import { Avatar, Box, Divider, Grid, Typography } from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import "./PostViewProject.css"
import RaceProjectCard from '../ServiceProviderProfilePage/RaceProjectCard/RaceProjectCard';
import RightSideDrawer from '../../../Components/Drawer/RightSideDrawer';
import OfferUserProfile from './OfferUserProfile';
import { GetApi } from '../../../Api/Api';

function RightPostViewSection({ individualProject }) {
  const [offerData, setOfferData] = useState([]);
  const [selectedOfferDataIndex, setSelectedOfferDataIndex] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const getOfferData = useCallback((projectId) => {
    GetApi(`/services/spvOffer?project_id=${projectId}`).then((res) => {
      if (res?.status === 200) {
        if (res.data && res.data.data && res.data.data.length > 0) {
          setOfferData(res.data.data);
        }
      }
    })
  }, []);

  useEffect(() => {
    getOfferData(individualProject.id)
  }, [individualProject.id]);

  return (
    <>
      <Box p={3} className="race-activity-container view-project-right" pb={'24px !important'}>
        <Grid container spacing={1}>
          <Grid p={2} item md={6} xs={12}>
            <Typography className='font-32'>{offerData.length}</Typography>
            <Typography className='font-14' color={'#64748B'}>Applications</Typography>
          </Grid>
        </Grid>
        {offerData.length > 0 ?
          <><Divider style={{ paddingTop: '8px', marginBottom: '8px', borderColor: '#334155', height: '2px' }} /><Box
            alignItems={"center"}
            className="applicationCard"
          >
            <Grid container spacing={2} alignItems="center">
              {offerData.length > 0 && offerData.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid item md={2} xs={12}>
                    {/* <Avatar src={`${process.env.REACT_APP_IMAGE_URL}services/uploads/frontend-17200087978367667.jpeg`}/> */}
                    <Avatar src={`${process.env.REACT_APP_IMAGE_URL}${item.spvProfileDetails?.profilePicture
                      && item.spvProfileDetails?.profilePicture.length > 0
                      ? item.spvProfileDetails?.profilePicture[0]?.path
                      : ''}`} />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Typography
                      variant="subtitle2"
                      sx={{ wordBreak: "break-word" }}
                    >
                      {item.serviceProviderData.first_name} {item.serviceProviderData.last_name}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className="font-12"
                      sx={{ wordBreak: "break-word" }}
                    >
                      {item?.spvProfileDetails?.title}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className="font-12 text-blueGray-500"
                      sx={{ wordBreak: "break-word" }}
                    >
                      {/* 100% Job success */}
                      {item?.spvProfileDetails?.successRate ? `${item?.spvProfileDetails?.successRate}% Job Success` : 'Not Worked'}
                    </Typography>
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <Box className="icon-gradient">
                      <RemoveRedEyeIcon onClick={() => {
                        setSelectedOfferDataIndex(index);
                        setIsDrawerOpen(true);
                      }
                      } />
                    </Box>
                  </Grid>
                  {offerData.length-1 > index && <Grid md={12} xs={12} marginTop={1} marginLeft={2} borderBottom={1} borderColor={'var(--blueGray-500)'}></Grid>}
                </React.Fragment>
              ))}
            </Grid>
          </Box></>
          : null}
        {individualProject?.raceProject &&
          <><Divider style={{ paddingTop: '8px', marginBottom: '8px', borderColor: '#334155', height: '2px' }} /><Box className="applicationCard">
            <RaceProjectCard project={individualProject?.raceProject ? individualProject?.raceProject : {}} />
          </Box></>}
      </Box>
      <RightSideDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} component={<OfferUserProfile setIsDrawerOpen={setIsDrawerOpen} offerData={offerData && offerData.length > 0 ? offerData?.filter((_, idx) => idx === selectedOfferDataIndex) : []} individualProject={individualProject} getOfferData={getOfferData} />} width={"700px"} />
    </>
  )
}

export default RightPostViewSection

