// payDividendAmount.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import convertToDecimal from "../Common/convertToDecimal";

/**
 * Call repayment method to repay the emi
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function payDividendAmount(address, contract_address, contract_abi, assetData, user_id, asset_id, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        // set active step to 2
        handleActiveStep(2)

        // make offer by investor
        const { request: payDividendAmount } = await prepareWriteContract({
            address: contract_address,
            abi: contract_abi,
            account: address,
            chainId: 6805,
            functionName: "payDividendAmount",
            args: [
                Number(assetData?.listing_id), // listing id / ipfs code id Integer
                convertToDecimal(assetData?.amount), // offer amount / offeredAmount
            ]
        })

        const { hash } = await writeContract(payDividendAmount)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(3)
                setTimeout(() => {
                    handleSuccess(data, assetData, assetData?.amount, asset_id, user_id)
                    handleModalClose();
                    handleActiveStep(-1)

                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error fetching balance:', error);
        throw error; // propagate the error
    }
}

export { payDividendAmount };