// This script demonstrates access to the NFT API via the Alchemy SDK.
import { Network, Alchemy } from "alchemy-sdk";
// import { OwnedNft, Nft } from "alchemy-sdk"; //types

// Create a new Alchemy instance
export const alchemy = new Alchemy({
    apiKey: process.env.REACT_APP_ALCHEMY_KEY,
    network: Network.ETH_SEPOLIA,
});

/**
 * Get All NFTs for owner
 * @param {string} ownerAddress - The wallet address of the owner
 * @returns {Promise<{nfts: Array<OwnedNft>, totalCount: number}>}
 */
export const getNFTsForOwner = async (ownerAddress, metadata = true) => {
    const nftsForOwner = await alchemy.nft.getNftsForOwner(ownerAddress, { omitMetadata: !metadata });
    return { nfts: nftsForOwner.ownedNfts, totalCount: nftsForOwner.totalCount };
};

/**
 *  Get NFT metadata
 * @param {string} contractAddress 
 * @param {string} tokenId 
 * @returns {Promise<Nft>}
 */
export const getNftMetadata = async (contractAddress, tokenId) => {
    const nft = await alchemy.nft.getNftMetadata(contractAddress, tokenId);
    return nft;
};


