import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/base";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import infoIcon from "../../Assets/Images/Investor/Info-icons.png";
import { Box, useMediaQuery } from "@mui/material";
import "./DropdownBox.css";

// Create a styled Tooltip component with custom styles
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#06487E",
    color: "white",
    fontSize: "1rem",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#06487E",
  },
});

const DropdownBox = (props) => {
  const isXs = useMediaQuery("(max-width:600px)");
  const isMd = useMediaQuery("(max-width:900px)");

  return (
    <CustomTooltip
      title={
        <div>
          <img src={infoIcon} alt="infoIcon" />
          <p className="font-14">{props?.description}</p>
          <Box textAlign={"-webkit-right"} mt={1} display={props?.data ? 'block' : 'flex'} justifyContent={'space-between'}>
            {props?.data ? null
              : <Button
                size="small"
                className="next-btn font-14"
                onClick={() => props?.setState(-1)}
              >
                <ArrowBackIcon /> <span>Back</span>
              </Button>
            }
            <Button
              size="small"
              className="next-btn font-14"
              onClick={() => props?.setState(1)}
            >
              <span>{props?.last ? "Okay" : "Next"}</span> <ArrowForwardIcon />
            </Button>
          </Box>
        </div>
      }
      open={true}
      arrow
      placement={
        isXs || isMd
          ? "bottom"
          : props?.position
            ? props?.position
            : props?.tolPosition === 5
              ? "right"
              : "bottom"
      }
      className={`investor-only-tooltip ${props?.from ? '' : 'new-z-index'}`}
    >
      {props?.render}
    </CustomTooltip>
  );
};

export default DropdownBox;
