import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Stack, Pagination, Button, Backdrop, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ProfileDescription from "../Components/ProfileDescription/ProfileDescription";
import PortfolioCard from "../Components/PortfolioCard/PortfolioCard";
import SkillChip from "../Components/Chips/SkillChip/SkillChip";
import OnboardingRightProfile from "./OnboardingRightProfile";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { IndividualProfilePersona } from "../../Components/Images/Images";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { GetApi, postApi } from "../../Api/Api";
import SpTransactionProgressModal from "../SmartContracts/SpTransactionProgressModal";
// import StakeTokenServiceProvider from "../Components/StakeModals/StakeTokenServiceProvider";

const OnboardingReviewPage = ({ onFinish, onBack, ProfileImage, sharedData, sharedDataStepTwo, sharedDataStepThree, previousData, selectedCategory }) => {
  /**
   * Getting userId from the  local storage
   */
  const localData = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : "";
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [reviewPageData, setReviewPageData] = useState({
    first_name: sharedData?.firstName,
    last_name: sharedData?.lastName,
    display_name: `${sharedData?.firstName} ${sharedData?.lastName}`,
    location: sharedData?.location,
    title: sharedDataStepTwo?.title ? sharedDataStepTwo.title : "",
    user_id: localData ? localData.id : "",
    description: sharedData?.description,
    profile_picture: sharedData?.profileImage,
    sp_category_id: sharedDataStepTwo?.selectedCategory,
    hourly_rate: sharedDataStepThree?.hourlyRate
      ? sharedDataStepThree?.hourlyRate
      : "",
    service_fee: sharedDataStepThree?.serviceFee
      ? sharedDataStepThree?.serviceFee
      : "",
    estimated_fee: sharedDataStepThree?.youWillGet
      ? sharedDataStepThree?.youWillGet
      : "",
    main_service: sharedDataStepThree?.mainServices
      ? sharedDataStepThree?.mainServices
      : "",
    availability: sharedDataStepThree?.availability
      ? sharedDataStepThree?.availability
      : "",
    documents: sharedDataStepTwo?.Documents,
    skillData: sharedDataStepTwo?.selectedSkills,
    language: sharedDataStepTwo?.selectedLanguages,
    associatedOrganization: sharedDataStepTwo.associated_organization,
    educationDetails: sharedDataStepTwo?.educationDetails,
    portfolio: sharedDataStepTwo?.details,
  });
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3; // Number of items per page
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  // const [stakeAmount, setStakeAmount] = useState(0)
  // const [openRTs, setOpenRTs] = useState(false)

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  function SingleSection({ children, sectionTitle }) {
    return (
      <Box p={"24px"}>
        <Typography
          variant="subtitle1"
          className="font-18 font-readexpro"
          ml={"16px"}
          mb={3}
        >
          {sectionTitle}
        </Typography>
        {children}
      </Box>
    );
  }

  // useEffect(() => {
  //   async function getServiceFeeConfiguration() {
  //     try {
  //       const response = await GetApi("/admin/serviceProviderConfig");
  //       if (response?.data?.code === 200) {
  //         setStakeAmount(response?.data?.data?.spStakingAmount)
  //       }
  //     } catch (error) {
  //       console.error("There was an error fetching the data!", error);
  //     }
  //   }
  //   getServiceFeeConfiguration();
  // }, [])

  // const handleStakeAmount = (amount) => {
  //   setStakeAmount(amount);
  //   handleContinue();
  //   handleClose();
  // }

  // const handleClose = () => {
  //   setOpenRTs(false)
  // }

  const handleContinue = async () => {
    setLoading(true);
    /**
       * Calling Api's
       */
    let formData = new FormData();
    formData.append("first_name", reviewPageData?.first_name);
    formData.append("last_name", reviewPageData?.last_name);
    formData.append("display_name", reviewPageData?.display_name);
    formData.append("location", reviewPageData?.location);
    formData.append(
      "title",
      reviewPageData?.title ? reviewPageData.title : ""
    );
    formData.append("user_id", localData ? localData.id : "");
    formData.append("description", reviewPageData?.description);
    formData.append("profile_picture", reviewPageData?.profile_picture);
    formData.append("sp_category_id", reviewPageData?.sp_category_id);
    formData.append(
      "hourly_rate",
      reviewPageData?.hourly_rate ? reviewPageData?.hourly_rate : ""
    );
    formData.append(
      "service_fee",
      reviewPageData?.service_fee ? reviewPageData?.service_fee : ""
    );
    formData.append(
      "estimated_fee",
      reviewPageData?.estimated_fee ? reviewPageData?.estimated_fee : ""
    );
    formData.append(
      "main_service",
      reviewPageData?.main_service ? reviewPageData?.main_service : ""
    );
    formData.append(
      "availability",
      reviewPageData?.availability ? reviewPageData?.availability : ""
    );

    // Append documents here
    reviewPageData?.documents &&
      reviewPageData?.documents?.forEach((val) => {
        formData.append("upload_document", val);
      });

    // Append Skill Ids as an array
    formData.append(
      "skills", JSON.stringify(reviewPageData?.skillData)
    );

    // Appeng Language as an array
    const languageData = reviewPageData?.language?.map((language, ind) => {
      return {
        language: language.name,
        proficiency: language.proficiency,
      };
    });

    formData.append("language", JSON.stringify(languageData));

    // Append Associated Organization Here
    const associated = reviewPageData?.associatedOrganization?.map(
      (assosiateddata, ind) => {
        return {
          associated_organization: assosiateddata,
        };
      }
    );

    formData.append("associated_organization", JSON.stringify(associated));
    const requestEducationDetailData = reviewPageData?.educationDetails;
    formData.append("education_details", JSON.stringify(requestEducationDetailData));

    reviewPageData?.portfolio?.forEach((portfolioData, index) => {
      formData.append(
        `portfolio_title${index}`,
        portfolioData.portfolio_title
      );
      formData.append(
        `portfolio_image${index}`,
        portfolioData.portfolio_image
      );
    });
    postApi(`/services/getTokenImageIpfs/spProfile?title=${reviewPageData?.first_name} ${reviewPageData?.last_name}`, formData).then((res) => {
      if (res?.status === 200) {
        console.log(res?.data?.data);
        // showSnackbar('Created Successfully', 'success');
        // navigate("/service-provider-dashboard/profile");
        const tempData = { ...reviewPageData };
        setReviewPageData({ ...tempData, pinata_token: res?.data?.data?.token, pinata_url: res?.data?.data?.url })
        setOpenTransactionModal(true);
        console.log("reviewPageData-----", reviewPageData);
        setLoading(false);
      } else { // something went wrong user or backend side
        setLoading(false);
        // showSnackbar(res?.data?.message, 'error');
      }
    })
  };
  const confirmStake = async (reviewPageData) => {
    if (reviewPageData) {
      /**
       * Calling Api's
       */
      setLoading(true);
      let formData = new FormData();
      formData.append("first_name", reviewPageData?.first_name);
      formData.append("last_name", reviewPageData?.last_name);
      formData.append("display_name", reviewPageData?.display_name);
      formData.append("location", reviewPageData?.location);
      formData.append(
        "title",
        reviewPageData?.title ? reviewPageData.title : ""
      );
      formData.append("user_id", localData ? localData.id : "");
      formData.append("description", reviewPageData?.description);
      formData.append("profile_picture", reviewPageData?.profile_picture);
      formData.append("sp_category_id", reviewPageData?.sp_category_id);
      formData.append(
        "hourly_rate",
        reviewPageData?.hourly_rate ? reviewPageData?.hourly_rate : ""
      );
      formData.append(
        "service_fee",
        reviewPageData?.service_fee ? reviewPageData?.service_fee : ""
      );
      formData.append(
        "estimated_fee",
        reviewPageData?.estimated_fee ? reviewPageData?.estimated_fee : ""
      );
      formData.append(
        "main_service",
        reviewPageData?.main_service ? reviewPageData?.main_service : ""
      );
      formData.append(
        "availability",
        reviewPageData?.availability ? reviewPageData?.availability : ""
      );

      // Append documents here
      reviewPageData?.documents &&
        reviewPageData?.documents?.forEach((val) => {
          formData.append("upload_document", val);
        });

      // Append Skill Ids as an array
      const skillIds = reviewPageData?.skillData?.map((skill) => skill.id);
      formData.append(
        "skill_ids",
        reviewPageData?.skillData?.length > 0
          ? JSON.stringify(skillIds)
          : sharedDataStepTwo?.stepSkills
      );
      formData.append(
        "skills", JSON.stringify(reviewPageData?.skillData)
      );

      // Appeng Language as an array
      const languageData = reviewPageData?.language?.map((language, ind) => {
        return {
          language: language.name,
          proficiency: language.proficiency,
        };
      });

      formData.append("language", JSON.stringify(languageData));

      // Append Associated Organization Here
      const associated = reviewPageData?.associatedOrganization?.map(
        (assosiateddata, ind) => {
          return {
            associated_organization: assosiateddata,
          };
        }
      );

      formData.append("associated_organization", JSON.stringify(associated));

      formData.append("pinata_token", reviewPageData?.pinata_token)

      try {
        // Define the data for your POST requests
        const requestEducationDetailData = reviewPageData?.educationDetails;
        // Create a new FormData object for portfolio
        const portfolioFormData = new FormData();

        // Append portfolio data to the new FormData object
        reviewPageData?.portfolio?.forEach((portfolioData, index) => {
          portfolioFormData.append(
            `title${index}`,
            portfolioData.portfolio_title
          );
          portfolioFormData.append(
            `image${index}`,
            portfolioData.portfolio_image
          );

          // appending userId into zero index only
          if (index === 0) {
            portfolioFormData.append(`user_id`, portfolioData.user_id);
          }
        });

        // Define your API endpoints
        const apiUrlEducation = "/services/education";
        const apiUrlPortfolio = "/services/portfolio";
        const apiUrlCreateProfile = "/services/createProfile";

        // Define your axios POST requests
        const request1 = await postApi(
          apiUrlEducation,
          requestEducationDetailData
        );
        const request2 = await postApi(apiUrlPortfolio, portfolioFormData);
        const request3 = await postApi(apiUrlCreateProfile, formData);

        // Call both requests simultaneously
        const [
          responseEducation,
          responseWorkExperience,
          responceCreateProfile,
        ] = await Promise.all([request1, request2, request3]);

        // Handle responses here
        if (
          responceCreateProfile?.data?.code === 200 &&
          responseEducation?.data?.code === 200 &&
          responseWorkExperience?.data?.code === 200
        ) {
          setLoading(false);
          navigate("/service-provider-dashboard/profile");
          // Call onFinish or any other function to handle the form submission
          onFinish();
        } else if (responceCreateProfile?.data?.code === 201) {
          setLoading(false);
          showSnackbar(responceCreateProfile?.data?.message, "error");
        }
      } catch (error) {
        // Handle errors here
        setLoading(false);
        console.error("Error:", error);
      }
    }
  }
  const handleModalClose = () => {
    setOpenTransactionModal(false);
  }
  return (
    <>
      {/* full page loader */}
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      )}
      <Box className="onboarding-review-page-container">
        {/* <Box className="logo-onboarding-one">
          <Link to="/">
            <img src={ServiceProviderLogo} alt="Logo" />
          </Link>
        </Box> */}
        <Box className="review-profile-top">
          <Grid container spacing={2}>
            <Grid xs={12} md={8} sm={12}>
              <Box>
                <Typography className="font-36">Review Profile</Typography>
                <Typography className="font-14">
                  Check your details and click create profile to publish your
                  profile. You can edit any details in your profile page.
                </Typography>
              </Box>
            </Grid>
            <Grid xs={12} md={4} sm={12}>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box className="go-back-btn">
                    <Button startIcon={<ArrowBackIcon />} onClick={onBack}>
                      Go Back
                    </Button>
                  </Box>
                  <Box className="continue-btn">
                    {/* <Button
                      variant="contained"
                      startIcon={<ArrowForwardIcon />}
                      className="btn-rounded btn-large"
                      style={{ background: "var(--golden-gradient)" }}
                      onClick={() => {
                        handleContinue();
                      }}
                    >
                      Create Profile
                    </Button> */}
                    <LoadingButton
                      onClick={() => {
                        // setOpenRTs(true);
                        handleContinue();
                      }}
                      startIcon={<ArrowForwardIcon />}
                      style={{ background: "var(--golden-gradient)" }}
                      loading={loading}
                      loadingPosition="start"
                      variant="contained"
                      type="submit"
                      className="btn-rounded btn-large"
                    >
                      Create Profile
                    </LoadingButton>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} sm={12}>
              <Box>
                <Box className="my-profile-box">
                  <ProfileDescription
                    name={
                      reviewPageData
                        ? `${reviewPageData?.first_name
                          ? reviewPageData?.first_name
                          : null
                        } ${reviewPageData?.last_name
                          ? reviewPageData?.last_name
                          : null
                        }`
                        : null
                    }
                    address={
                      reviewPageData?.location ? reviewPageData?.location : null
                    }
                    title={reviewPageData && reviewPageData?.title}
                    description={reviewPageData && reviewPageData?.description}
                    ProfileImage={ProfileImage}
                    hourlyrate={reviewPageData && reviewPageData?.hourly_rate}
                    from="reviewProfile"
                  />
                </Box>

                {/* Portfolio section*/}
                <Box className="comapany-profile-skills">
                  {/* <Typography
                    variant="subtitle1"
                    className="font-18 font-readexpro"
                    ml={"16px"}
                    mb={3}
                    pt={2}
                  >
                    Portfolio
                  </Typography> */}
                  <SingleSection sectionTitle="Portfolio">
                    <Box mx={3}>
                      <Grid container spacing={1}>
                        {reviewPageData?.portfolio
                          ?.slice(
                            (currentPage - 1) * itemsPerPage,
                            currentPage * itemsPerPage
                          )
                          .map((item, index) => (
                            <Grid item key={index} md={4} xs={12}>
                              <PortfolioCard
                                image={item.portfolio_image}
                                title={item.portfolio_title}
                                review={false}
                              // description={item.portfolio_description}
                              // link={item.portfolio_link}
                              />
                            </Grid>
                          ))}
                      </Grid>
                    </Box>
                    <Box className="pagination-container">
                      <Pagination
                        count={Math.ceil(
                          reviewPageData?.portfolio?.length / itemsPerPage
                        )}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        showFirstButton
                        showLastButton
                      />
                    </Box>
                  </SingleSection>
                </Box>

                {/* Category */}
                <Box className="comapany-profile-skills">
                  <SingleSection sectionTitle="Category">
                    <Grid container spacing={2} mt={1}>
                      <Grid xs="auto" ml={2}>
                        {" "}
                        <SkillChip key={0} label={selectedCategory?.title} />{" "}
                      </Grid>
                    </Grid>
                  </SingleSection>
                </Box>
                {/* Skills Section Individual */}
                <Box className="comapany-profile-skills">

                  <SingleSection sectionTitle="Skills">
                    <Grid container spacing={2} mt={1}>
                      {reviewPageData?.skillData?.map((item, index) => {
                        return (
                          <Grid xs="auto" ml={2}>
                            {" "}
                            <SkillChip key={index} label={item.skill_name} />{" "}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </SingleSection>
                </Box>
                {/* Main services */}
                <Box className="comapany-profile-skills">
                  <SingleSection sectionTitle="Main Services">
                    <Grid container spacing={2} mt={1}>
                      {reviewPageData?.main_service?.split(', ')?.map((item, index) => {
                        return (
                          <Grid xs="auto" ml={2}>
                            {" "}
                            <SkillChip key={index} label={item} />{" "}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </SingleSection>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sm={12}>
              <Box className="right-section">
                <OnboardingRightProfile reviewPageData={reviewPageData} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        {openTransactionModal ? <SpTransactionProgressModal identity={'sp-registration'} confirmStake={confirmStake} propData={{ ...reviewPageData }} handleModalClose={handleModalClose} openTransactionModal={openTransactionModal} /> : null}
      </Box>

      {/* <StakeTokenServiceProvider openRTs={openRTs} handleClose={handleClose} handleStakeAmount={handleStakeAmount} stakeAmount={stakeAmount} /> */}
    </>
  );
};

export default OnboardingReviewPage;
