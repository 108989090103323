import React from 'react'
import { Grid, Typography } from '@mui/material'
import ParcelCard from '../Pages/Investor/Parceling/ParcelCard'

const ParcelCardGrid = ({ cardsData, is_onboard, parcelTokenAddress }) => {
  return (
    <Grid container spacing={2} >
      {
        cardsData && cardsData.length > 0 ?
          (cardsData.map((card, index) => {
            return (
              <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                <ParcelCard card={card} viewOnly={true} is_onboard={is_onboard} parcelTokenAddress={parcelTokenAddress} />
              </Grid>
            )
          })
          ) :
          (
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h6" sx={{ textAlign: 'center' }}>No Parcels available</Typography>
            </Grid>
          )
      }
    </Grid>
  )
}

export default ParcelCardGrid