import React, { useState } from 'react'
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import Footer from '../Footer/FooterServiceProvider';
import Header from '../Header/HeaderServiceProposer';
import '../../ServiceProvider/ServiceProvider.css'

function LayoutServiceProposer() {
    return (
        <>
            <Box className={`service-layout`} >
                <Header />
                <Box className='main-page' sx={{ marginTop: "90px" }}>
                    <Outlet />
                </Box>
                <Footer />
            </Box>
        </>
    )
}

export default LayoutServiceProposer