import React from 'react'
import { Backdrop, CircularProgress } from "@mui/material";

const CommonBackdropLoader = ({ loading, zIndex }) => {
    return (
        <>
            {loading && (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => zIndex ? zIndex : theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="primary" />
                </Backdrop>
            )}
        </>
    )
}

export default CommonBackdropLoader