import React, { useState } from "react";
import { Avatar, Box, Button, Card, CardHeader, IconButton, Grid, Modal, Stack, Tooltip, Typography, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import CheckIcon from "@mui/icons-material/Check";
import ChatIcon from '@mui/icons-material/Chat';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import FileViewer from "react-file-viewer";

const ReviewServicePostRight = ({ sharedDataStepThree, sharedDataStepTwo }) => {
  const Pricing = sharedDataStepTwo;
  const [value, setValue] = React.useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [view, setView] = useState(false);
  const [file, setFile] = useState();
  const [type, setType] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleView = () => {
    setView(!view);
  };

  const handleClose = () => setOpenModal(false);

  const viewDoc = (requirementDetails) => {
    setOpenModal(true);
    handleView();
    setFile(requirementDetails);
    setType('pdf');
  };

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3, pl: 0, pr: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Grid item xs={12} md={4}>
      <Box className="left-content left-content-tab" mb={2}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab style={{ textTransform: "capitalize" }} label="Basic" {...a11yProps(0)} />
              <Tab style={{ textTransform: "capitalize" }} label="Standard" {...a11yProps(1)} />
              <Tab style={{ textTransform: "capitalize" }} label="Premium" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0} sx={{ paddingLeft: 0, paddingRight: 0 }}>
            <Box className="basic-step-first-container">
              <Box className="top-view">
                <Box>
                  <Typography className="font-16">
                    {Pricing?.starterTitle}
                  </Typography>
                </Box>
                <Box>
                  <Typography className="font-22" sx={{ textWrap: 'nowrap' }}>$ {Pricing?.starterPrice}</Typography>
                </Box>
              </Box>
              <Box mt={2}>
                <Typography className="font-14">
                  {Pricing?.starterDesc}
                </Typography>
              </Box>

              <Box mt={2} className="day-delivery">
                <Typography className="font-12">
                  {Pricing?.starterDeliveryDays} Days Delivery
                </Typography>
                <Typography className="font-12" ml={2}>
                  {Pricing?.starterRevisions} Revisions
                </Typography>
              </Box>

              <Box class="basic-check-points">
                <CheckIcon />
                <Typography className="check-text font-14">
                  {Pricing?.starterIncAddService}
                </Typography>
              </Box>

              {/* <Stack direction="column" spacing={1} mt={2}>
                <Button variant="contained" style={{ color: "#fff", borderRadius: "8px", textTransform: "capitalize" }}>$ Continue</Button>
                <Button variant="contained" style={{ color: "#fff", backgroundColor: "#06487E", borderRadius: "8px", textTransform: "capitalize" }}><ChatIcon />  Contact Me</Button>
              </Stack> */}
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {Pricing?.standardPrice !== "" ?
              <Box className="basic-step-first-container">
                <Box className="top-view">
                  <Box>
                    <Typography className="font-16">
                      {Pricing?.standardTitle}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="font-22" sx={{ textWrap: 'nowrap' }}>$ {Pricing?.standardPrice}</Typography>
                  </Box>
                </Box>
                <Box mt={2}>
                  <Typography className="font-14">
                    {Pricing?.standardDesc}
                  </Typography>
                </Box>

                <Box mt={2} className="day-delivery">
                  <Typography className="font-12">
                    {Pricing?.standardDeliveryDays} Days Delivery
                  </Typography>
                  <Typography className="font-12" ml={2}>
                    {Pricing?.standardRevisions} Revisions
                  </Typography>
                </Box>

                <Box class="basic-check-points">
                  <CheckIcon />
                  <Typography className="check-text font-14">
                    {Pricing?.standardIncAddService}
                  </Typography>
                </Box>
                {/* <Stack direction="column" spacing={1} mt={2}>
                <Button variant="contained" style={{ color: "#fff", borderRadius: "8px", textTransform: "capitalize" }}>$ Continue</Button>
                <Button variant="contained" style={{ color: "#fff", backgroundColor: "#06487E", borderRadius: "8px", textTransform: "capitalize" }}><ChatIcon />  Contact Me</Button>
              </Stack> */}
              </Box> : <Typography textAlign={'center'}>No Plan</Typography>}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            {Pricing?.advancedPrice !== "" ?
              <Box className="basic-step-first-container">
                <Box className="top-view">
                  <Box>
                    <Typography className="font-16">
                      {Pricing?.advancedTitle}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="font-22" sx={{ textWrap: 'nowrap' }}>$ {Pricing?.advancedPrice}</Typography>
                  </Box>
                </Box>
                <Box mt={2}>
                  <Typography className="font-14">
                    {Pricing?.advancedDesc}
                  </Typography>
                </Box>

                <Box mt={2} className="day-delivery">
                  <Typography className="font-12">
                    {Pricing?.advancedDeliveryDays} Days Delivery
                  </Typography>
                  <Typography className="font-12" ml={2}>
                    {Pricing?.advancedRevisions} Revisions
                  </Typography>
                </Box>

                <Box class="basic-check-points">
                  <CheckIcon />
                  <Typography className="check-text font-14">
                    {Pricing?.advancedIncAddService}
                  </Typography>
                </Box>
                {/* <Stack direction="column" spacing={1} mt={2}>
                <Button variant="contained" style={{ color: "#fff", borderRadius: "8px", textTransform: "capitalize" }}>$ Continue</Button>
                <Button variant="contained" style={{ color: "#fff", backgroundColor: "#06487E", borderRadius: "8px", textTransform: "capitalize" }}><ChatIcon />  Contact Me</Button>
              </Stack> */}
              </Box> : <Typography textAlign={'center'}>No Plan</Typography>}
          </CustomTabPanel>
        </Box>
      </Box>
      {sharedDataStepThree.document && sharedDataStepThree.document.length > 0 ? (<>
        {
          sharedDataStepThree?.document?.[0].map((val, ind) => {
            return (
              <Card className="pd-action-card" key={ind} >
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" className="bg-blue-900" variant="square" sx={{ width: '48px', height: '48px', borderRadius: '12px' }}>
                      <AssignmentIcon />
                    </Avatar>
                  }
                  action={
                    <>
                      <Box className="btn-wrap">
                        <Stack direction="row" spacing={1.5} alignItems={'center'}>
                          <IconButton aria-label="View" size="large" className='square-icon-btn' onClick={() => viewDoc(val)} >
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </Stack>
                      </Box>
                    </>
                  }
                  onClick={() => viewDoc(val)}
                  title={val.path}
                  subheader={
                    <Tooltip title={val?.name}>
                      <span>{val?.name}</span>
                    </Tooltip>
                  }
                />
              </Card>
            );
          })
        }
      </>
      ) : (
        <Typography textAlign={'center'}>No documents availiable to view</Typography>
      )}
      {view &&
        <Modal
          className="projectDocumentsModal"
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          fullWidth={true}
        >
          <Box sx={styleModal}>
            <OverlayScrollbarsComponent defer
              style={{ width: '100%', height: '100%' }}
            >
              {/* {<FileViewer fileType={type} filePath={URL.createObjectURL(file)} />} */}
              <object
                  data={URL.createObjectURL(file)}
                  type="application/pdf"
                  style={{ width: '100%', height: '100%' }}
              >
                <p style={{ textAlign: 'center' }}>
                  Your browser does not support PDFs. <a href={file} target="_blank" style={{ color: 'lightgreen' }}>Download the PDF</a>.
                </p>
              </object>
            </OverlayScrollbarsComponent>
          </Box>
        </Modal>
      }
    </Grid>
  );
};

export default ReviewServicePostRight;