import { Box, Typography } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import "./TokenizedAssetsCard.css"


const TokenizedAssetsCard = (props) => {
    let positionFromTop = props?.positionFromTop;
    const wrapperRef = useRef(null);
    const [wrapperHeight, setWrapperHeight] = useState(0);

    useEffect(() => {
    }, [positionFromTop])

    const handleScroll = () => {
        if (wrapperRef.current) {
            setWrapperHeight(wrapperRef.current.offsetHeight);

        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Initial height set
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <Box className={`tokenized-assets-sec ${wrapperHeight <= 170 ? "short-tokenized" : ''}`} style={{ maxHeight: `calc(100% - ${positionFromTop}px + 100px)`, minHeight: 80 }}>
                <Box className="tokenized-assets-wrapper" ref={wrapperRef}>
                    <Box className={`tokenized-assets-card ${props.cardSelected}`}>
                        <Box className="tokenized-assets-card-body">
                            <Box className="tokenized-assets-image">
                                <img src={props.imgSrc} alt="" />
                            </Box>
                            <Box className="title-wraper">
                                <Typography variant='h4' >{props.title}</Typography>
                            </Box>
                            <Box className="description-wrapper" >
                                <Typography variant='p' >{props.description}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default TokenizedAssetsCard