import { createSlice } from '@reduxjs/toolkit'

export const serviceProviderSlice = createSlice({
    name: 'auth',
    initialState: {
        name: null,
        userProfilePicture: null,
        login: false,
    },
    reducers: {
        updateProfileData: (state, action) => {
            if(action.payload?.userProfilePicture)state.userProfilePicture = action.payload?.userProfilePicture;
            if(action.payload?.first_name)state.first_name = action.payload?.first_name;
            if(action.payload?.last_name)state.last_name = action.payload?.last_name;
        }
    }
});

export const { updateProfileData } = serviceProviderSlice.actions;
export default serviceProviderSlice.reducer;