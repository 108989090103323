import React, { useState, useEffect } from "react";
import { Box, Button, MenuItem, FormControl, Select, Stack, Typography } from "@mui/material";
import ListCard from "../Components/ListCard/ListCard";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import "./CompanyProfile.css";
import { Assignment } from "@mui/icons-material";
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import EditLanguagePopup from '../EditProfile/EditLanguagePopup/EditLanguagePopup';
import EditEducationPopup from "../EditProfile/EditEducationPopup/EditEducationPopup";
import EditDocumentsPopup from "../EditProfile/EditDocumentsPopup/EditDocumentsPopup";
import EditAssociatedOrganisationPopup from "../EditProfile/EditAssociatedOrganisationPopup/EditAssociatedOrganisationPopup";
import { getUserDataFromLocalStorage } from "../../Services/localstorage.service";
import { updateApi } from "../../Api/Api";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { useNavigate } from "react-router-dom";
import SpTransactionProgressModal from "../SmartContracts/SpTransactionProgressModal";

function RightProjectProfileSection({ profileData, getProfileData }) {
  const { showSnackbar } = useSnackbar();
  const [language, setLanuage] = useState([]);
  const [profileLanguage, setProfileLanguage] = useState([]);
  const [organizationVal, setOrganizationVal] = useState([]);
  const [experienceDetails, setExperienceDetail] = useState([]);
  const [isPopupOpenEditProfile, setIsPopupOpenEditProfile] = useState(false);
  const [isPopupOpenEditProfileEducation, setIsPopupOpenEditEducation] = useState(false);
  const [isPopupOpenEditProfileDocuments, setIsPopupOpenEditDocuments] = useState(false);
  const [isPopupOpenEditAssociatedOrganisation, setIsPopupOpenEditAssociatedOrganisation] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [availability, setAvailability] = useState(null);
  const [educationDetails, seteducationDetails] = useState({});
  const [openTransactionModal, setOpenTransactionModal] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    // Update the availability state once profileData is available
    if (profileData?.availability !== undefined && profileData?.availability !== null) {
      setAvailability(profileData.availability);
    } else {
      setAvailability('open');
    }
  }, [profileData]); // Dependency array includes profileData to update when it changes

  const handleChange = (event) => {
    setAvailability(event.target.value);
  };

  const handleSaveClick = (event) => {
    event.preventDefault();
    setOpenTransactionModal(true)
    // let updateData = new FormData();
    // updateData.append("availability", availability);
    // updateApi(`/services/updateProfile/${profileData.id}`, updateData).then((res) => {
    //   if (res?.status === 200) {
    //     showSnackbar('Updated Successfully', 'success');
    //     setIsEditing(false);
    //   } else { // something went wrong user or backend side
    //     showSnackbar(res?.data?.message, 'error');
    //   }
    // })
  };

  const confirmStake = async () => {
    console.log('success')
    setIsEditing(false);
  }

  const handleModalClose = () => {
    setOpenTransactionModal(false);
  }

  const handleEditLanguage = (item) => {
    handleOpenPopupEdit()
  }

  /**
    * Function to open Edit Language Modal
    */
  const handleOpenPopupEdit = () => {
    setIsPopupOpenEditProfile(true);
  };

  /**
 * Function to close Edit Language Modal
 */
  const handleClosePopupEdit = () => {
    setIsPopupOpenEditProfile(false);
  };
  /**
 * Function to close Edit Associated Organisation Modal
 */
  const handleCloseAssociatedOrganisationPopup = () => {
    setIsPopupOpenEditAssociatedOrganisation(false);
  }

  // Edit Education 
  const handleEditEducation = (val) => {
    seteducationDetails(val);
    handleOpenPopupEditEducation()
  }

  /**
    * Function to open Edit Language Modal
    */
  const handleOpenPopupEditEducation = () => {
    setIsPopupOpenEditEducation(true);
  };

  /**
 * Function to close Edit Language Modal
 */
  const handleClosePopupEditEducation = () => {
    setIsPopupOpenEditEducation(false);
  };

  // Edit Documents 
  const handleEditDocuments = () => {
    handleOpenPopupEditDocuments()
  }

  /**
    * Function to open Edit Language Modal
    */
  const handleOpenPopupEditDocuments = () => {
    setIsPopupOpenEditDocuments(true);
  };

  /**
 * Function to close Edit Language Modal
 */
  const handleClosePopupEditDocuments = () => {
    setIsPopupOpenEditDocuments(false);
  };


  /**
 * Setting Languages for the Left side Bar section
 */
  useEffect(() => {
    if (profileData) {
      const profileLanguage = profileData?.language ? JSON.parse(profileData?.language) : null;
      setProfileLanguage(profileLanguage)
      // Map languages and proficiencies, then join them with "|"
      const languageSelected = profileLanguage && profileLanguage.map((val) => {
        return `${val?.language}:${val?.proficiency}`;
      })
      setLanuage(languageSelected);
      // Setting associated Organization
      const associatedOrganization = profileData?.associated_organization ? JSON.parse(profileData?.associated_organization) : null;

      // Map languages and proficiencies, then join them with "|"
      const organization = associatedOrganization && associatedOrganization.map((val) => {

        return `${val?.associated_organization}`;
      }).join(" | ");

      setOrganizationVal(associatedOrganization);

      // Filter experienceData to get the object where currentlyWorking is true
      const currentExperience = profileData && profileData?.experienceProfile
        ?.filter(experience => experience.currentlyWorking);
      setExperienceDetail(currentExperience);
    }
  }, [profileData]);

  return (
    <Box p={3} className="right-project-profile">
      <Box className="top-view border-top">
        <Box>
          <Typography className="font-32">{profileData?.totalJobs ? profileData?.totalJobs : 0}</Typography>
          <Typography className="font-14 text-blueGray-400 ">Total Jobs</Typography>
        </Box>
        <Box>
          <Typography className="font-32">{profileData?.totalHourse ? profileData?.totalHourse : '0,000'}</Typography>
          <Typography className="font-14 text-blueGray-400 ">Total Hours</Typography>
        </Box>
      </Box>
      <Box className="top-view" mt={2}>
        <Box>
          <Typography className="font-12 title">Availability</Typography>
          {!isEditing ? (
            <Typography className="font-16">
              {/* {availability === "open" || profileData?.availability ? "open to offer" : "Not Available"} */}
              {availability === "open" ? "Open to Offer" : "Not Available"}
            </Typography>
          ) : (
            <FormControl sx={{ mt: 1, minWidth: 120 }} className="avaolability-dropdown">
              <Select
                value={availability}
                onChange={handleChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="open">Open to Offer</MenuItem>
                <MenuItem value="notAvailable">Not Available</MenuItem>
              </Select>
            </FormControl>
          )}
        </Box>
        <Box className="edit-grey-icon">
          {!isEditing ? (
            <EditIcon onClick={handleEditClick} />
          ) : (
            <>
              <Button className="btn-rounded btn-golden" sx={{ mr: 2, mt: 2 }} onClick={handleSaveClick}>Save</Button>
              <Button className="btn-rounded btn-golden-outline" sx={{ mt: 2 }} onClick={handleCancelClick}><Typography className="btn-inner" variant="span"> Cancel </Typography> </Button>
            </>
          )}
        </Box>
      </Box>
      <Box className="top-view" mt={2}>
        <Box>
          <Typography className="font-12 title">Languages</Typography>
          {profileLanguage && profileLanguage?.length > 0 && profileLanguage?.map((row) =>
            <Typography className="font-16">{row?.language}{row?.proficiency ? `:${row?.proficiency}` : ''}</Typography>
          )}

        </Box>

        <Box className="edit-grey-icon">
          <EditIcon onClick={handleEditLanguage} />
        </Box>
        {isPopupOpenEditProfile && <EditLanguagePopup open={isPopupOpenEditProfile} handleClose={handleClosePopupEdit} handleDataUpdate={profileLanguage} profileData={profileData} getProfileData={getProfileData} />}
      </Box>

      <Box className="top-view" mt={2}>
        <Box>
          <Typography className="font-16">Education</Typography>
        </Box>
      </Box>
      <Box mt={2} ml={2}>

        {profileData?.educationProfile && profileData?.educationProfile?.length > 0 ? (
          profileData?.educationProfile.map((val, index) => {
            const startDate = new Date(val.startDate);
            const endDate = new Date(val.endDate);

            const startYear = startDate.getFullYear();
            const endYear = endDate.getFullYear();

            return (
              <Box className="top-view" key={index}>
                <Box>
                  <Typography variant="subtitle2" className="font-12" mb={2}>
                    {val.school}
                  </Typography>
                  <Typography variant="subtitle1" className="font-12 text-blueGray-500" mb={2}>
                    {val?.degree} <br />
                    {val?.fieldOfStudy} <br />
                    {startYear}-{endYear}
                  </Typography>
                </Box>
                <Box className="edit-grey-icon">
                  <EditIcon onClick={() => handleEditEducation(val)} />
                </Box>

              </Box>
            );
          })
        ) : (
          <Typography variant="subtitle1" className="font-12" textAlign="center">
            No education details available.
          </Typography>
        )}
        {isPopupOpenEditProfileEducation && <EditEducationPopup open={isPopupOpenEditProfileEducation} handleClose={handleClosePopupEditEducation} educationDetails={educationDetails} getProfileData={getProfileData} />}
      </Box>

      {organizationVal?.length ? (
        <>
          <Box className="top-view" mt={2}>
            <Typography className="font-16">Associated with</Typography>
            <Box className="edit-grey-icon">
              <EditIcon onClick={() => setIsPopupOpenEditAssociatedOrganisation(true)} />
            </Box>
            {isPopupOpenEditAssociatedOrganisation && <EditAssociatedOrganisationPopup open={isPopupOpenEditAssociatedOrganisation} handleClose={handleCloseAssociatedOrganisationPopup} profileData={profileData} getProfileData={getProfileData} />}
          </Box>

          <Box ml={-2} mt={1}>
            {organizationVal?.length > 0 ? organizationVal?.map((val, ind) => {
              return <ListCard key={ind} listIcon={<ApartmentOutlinedIcon />} bgColor={'#fff'} iconColor="#398CD1" type={'icon'} title={val?.associated_organization} subtitle1={""} subtitle1Color='#398CD1' eyeIcon={false} />
            })
              : "No Orgainzation Available"
            }
          </Box>
        </>
      ) : null}

      {profileData?.documents?.length ? (
        <>
          <Box className="top-view" mt={2}>
            <Box>
              <Typography className="font-16">Documents</Typography>
            </Box>
            <Box className="edit-grey-icon">
              <EditIcon onClick={() => handleEditDocuments(profileData?.documents)} />
            </Box>
            {isPopupOpenEditProfileDocuments && <EditDocumentsPopup profileData={profileData} onOpen={isPopupOpenEditProfileDocuments} handleClose={handleClosePopupEditDocuments} getProfileData={getProfileData} />}
          </Box>
          <Box ml={-2} mt={1}>
            {profileData?.documents?.length > 0 ? profileData?.documents.map((val, ind) => {
              return <ListCard key={ind} listIcon={<Assignment />} bgColor={'#fff'} iconColor="#398CD1" type={'icon'} title={val.original_name} subtitle1={val.title} subtitle1Color='#398CD1' fileVal={val} />
            })
              : "No Documents Available"
            }
          </Box>

        </>
      ) : null}

      {openTransactionModal ? <SpTransactionProgressModal identity={'update-availability'} confirmStake={confirmStake} propData={{ status: availability == 'open' ? 1 : 0 }} handleModalClose={handleModalClose} openTransactionModal={openTransactionModal} /> : null}
    </Box>
  );
}

export default RightProjectProfileSection;

function Section({ children, title, icon }) {
  return (
    <Box>
      <Stack direction="row" justifyContent={"space-between"}>
        <Typography
          variant="subtitle1"
          fontWeight={"500"}
          className="font-16"
          mb={2}
        >
          {title}
        </Typography>
        {icon ? <InfoIcon className="info-icon" /> : null}
      </Stack>
      <Box p={2}>{children}</Box>
    </Box>
  );
}
