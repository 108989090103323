import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'

// This code can only for Art parcel draw grid for cover photo
const ParcelGridImage = forwardRef(({ artImage, rows, cols, id }, ref) => {

    const [selectedParcelNos, setSelectedParcelNos] = useState([])
    const sourceImage = useRef(null);
    const canvas = useRef(null);
    const imageOriginalWidth = useRef(0);
    const imageOriginalHeight = useRef(0);
    const cellWidth = useRef(0);
    const cellHeight = useRef(0);

    const drawGrid = () => {
        if (canvas && sourceImage) {

            let _canvas = canvas.current;

            // source image
            let _sourceImage = sourceImage.current;

            // image dimension
            imageOriginalWidth.current = _sourceImage.naturalWidth;
            imageOriginalHeight.current = _sourceImage.naturalHeight;

            const ctx = _canvas.getContext('2d');

            _canvas.width = _sourceImage.width;
            _canvas.height = _sourceImage.height;
            cellWidth.current = _canvas.width / cols;
            cellHeight.current = _canvas.height / rows;

            const translucentDarkOverlay = 'rgba(0, 0, 0, 0.5)';
            ctx.fillStyle = translucentDarkOverlay;

            ctx.fillRect(0, 0, _canvas.width, _canvas.height);
        }
    }


    /**
     *  selects the regions which are already selected when initail load of component should be called by parent
     * @param {*} selectedParcels array of selected parcels
     */
    const initialRegionSelection = (selectedParcels) => {
        let sP = [];
        selectedParcels.forEach(parcel => {
            let parcelNo = parcel.title.split('#')[1];
            sP.push(parseInt(parcelNo));
            selectRegion(parcelNo);

        });
        setSelectedParcelNos(sP);
    }

    /**
     * removes the defalt translucent from the area and feels like its selected
     * @param {*} parcelNo 
     */
    const selectRegion = (parcelNo) => {
        let canvas = document.getElementById(id);
        let ctx = canvas.getContext('2d');
        // -1 because initial grid starts from 0,0
        const x = ((parcelNo - 1) % cols) * cellWidth.current;
        const y = Math.floor((parcelNo - 1) / cols) * cellHeight.current;
        ctx.clearRect(x, y, cellWidth.current, cellHeight.current);
        setSelectedParcelNos([...selectedParcelNos, parseInt(parcelNo)]);
    }

    // Expose the child function through the ref
    useImperativeHandle(ref, () => ({
        selectRegion, initialRegionSelection
    }));

    return (
        <>
            <Box sx={{ position: 'relative' }}>
                <Box ref={sourceImage} onLoad={drawGrid} id='imageContainer' component={'img'} src={artImage} />
                <canvas ref={canvas} style={{ borderRadius: '16px', cursor: 'pointer' }} className={'grid-canvas'} id={id}></canvas>
            </Box>
        </>
    )
});

ParcelGridImage.propTypes = {
    artImage: PropTypes.string.isRequired,
    rows: PropTypes.number.isRequired,
    cols: PropTypes.number.isRequired,
}


export default ParcelGridImage