import convertToDecimal from "./convertToDecimal";
import convertToSixDecimal from "./convertToSixDecimal";

/**
 * Function return currency address for provided currency name
 */
export default function getCurrencyTypeDecimal(name, amount) {
    let decimalAmt = 0
    switch (name) {
        case 'RACE':
            decimalAmt = convertToDecimal(amount)
            break;
        case 'USDT':
            decimalAmt = convertToSixDecimal(amount)
            break;
        case 'USDC':
            decimalAmt = convertToSixDecimal(amount)
            break;
        case 'DAI':
            decimalAmt = convertToDecimal(amount)
            break;
        default:
            decimalAmt = convertToSixDecimal(amount)
            break;
    }
    return decimalAmt
}