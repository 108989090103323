import React, { useState } from "react";
import { Link } from "react-router-dom";
import { NewLogo } from "../../../Components/Images/Images";
import { Box, Grid, Typography, Button, TextField, IconButton, } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import NumericFormatCustomDollar from "../../../Components/Common/NumericFormatCustomDollar";
import "./Components/Questionnaire.css";


export default function DepositAmount(props) {
  // Destructuring props to extract specific functions
  const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

  // Get current proposal and progress data from the parent component
  var propData = getProposalData();
  let currentProgress = getProgress();

  // Initialize and manage the state for the deposit amount
  const [sale_deposit_amount, setDepositAmount] = useState(propData?.sale_deposit_amount ? propData?.sale_deposit_amount : 0);

  /**
   * Function to handle changes in the deposit amount based on the specified type.
   * @param {String} type - The type of change, either 'inc' for increment or 'dec' for decrement.
   */
  const handleDepositAmount = (type) => {
    if (type === 'inc') {
      // Increment the deposit amount by 500, with a minimum value of 500
      setDepositAmount(prevCount => prevCount ? (parseFloat(prevCount) + 500) : 500);
    } else {
      // Decrement the deposit amount by 500, with a minimum value of 0
      setDepositAmount(prevCount => parseFloat(prevCount) >= 500 && prevCount ? parseFloat(prevCount) - 500 : prevCount);
    }
  }

  /**
  * Function to proceed to the next step and update the proposal data with the selected deposit amount.
  */
  const handleClick = () => {
    // Update the proposal data with the selected deposit amount
    propData.sale_deposit_amount = sale_deposit_amount;
    updateProposalData(propData);

    // Move to the next step
    handleNext();
  }

  /**
  * Function to save the current proposal and exit the current step/page.
  */
  const SaveAndExit = () => {
    // Update the proposal data with the selected deposit amount
    propData.sale_deposit_amount = sale_deposit_amount;
    updateProposalData(propData);

    // Save the proposal and exit
    saveProposal();
  }

  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left">
              {/* left-title */}
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  Do you want to take a deposit for every offer you receive?
                </Typography>
              </Box>
              {/* Logo */}
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right  quests-right-contents-box">
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  <Button disabled={(sale_deposit_amount !== "" && Boolean(sale_deposit_amount <= parseFloat(propData?.sale_price))) ? false : true} onClick={SaveAndExit} className="btn-rounded btn-text-white btn-blue-600">
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box className="qsn-middle-content" sx={{ pt: 4, mb: 5 }}>
                <Box>
                  <Box textAlign={"center"} my={2}>
                    <Typography fontWeight={"500"} color="black">
                      Deposit Amount
                    </Typography>
                    <Typography fontSize={"12px"} color={"#94A3B8"}>
                      {" "}
                      If you do not want to take a deposit, set the amount as $0
                    </Typography>
                    <Box className="NS-sale-details" my={1}>
                      <Box className='NS-sale-details-icon'>
                        <IconButton onClick={() => { handleDepositAmount('dec') }} className="NS-sale-details-icon-btn">
                          <RemoveIcon />
                        </IconButton>
                      </Box>
                      <Box>
                        <TextField
                          id="outlined-number"
                          type="text"
                          // defaultValue={duration}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            inputComponent: NumericFormatCustomDollar,
                          }}
                          value={sale_deposit_amount}
                          size="small"
                          // error={Boolean(sale_deposit_amount > parseFloat(propData?.sale_price))}
                          helperText={"Deposit Amount."}
                          onChange={(e) => setDepositAmount(parseFloat(e.target.value))}
                          onKeyDown={(event) => {
                            if (event?.key === '-' || event?.key === '+') {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Box>
                      <Box className='NS-sale-details-icon'>
                        <IconButton onClick={() => { handleDepositAmount('inc') }} className="NS-sale-details-icon-btn">
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Typography sx={{ color: 'red', fontSize: '11px', textAlign: 'center' }}>                           {Boolean(sale_deposit_amount > parseFloat(propData?.sale_price)) ? "Deposit amount can not be greater than or equal to sale price." : ""}
                    </Typography>

                  </Box>
                  <Box textAlign={"left"} my={2}>
                    <Typography
                      fontWeight={"500"}
                      color="#94A3B8"
                      sx={{ fontSize: "18px !important", my: 1 }}
                    >
                      Note
                    </Typography>
                    <Typography fontSize={"12px"} color={"#94A3B8"}>
                      {" "}
                      The deposit will be returned to an investor if you reject
                      an offer, or if the offer is cancelled by an investor.
                      Placing a mandatory deposit will affect your offers.
                      <br />
                      <br />
                      <Link to="/dummy" target={"_blank"}>
                        <span className="text-underline text-blueGray-500">
                          Learn more here.
                        </span>
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  mt={3}
                >
                  <Box textAlign={"left"} className="quests6-btn-box">
                    <Button
                      className="btn-rounded back-btn"
                      onClick={handleback}
                    >
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"} className="quests-btn-box">
                    <Button
                      onClick={handleClick}
                      disabled={(sale_deposit_amount !== "" && Boolean(sale_deposit_amount <= parseFloat(propData?.sale_price))) ? false : true}
                      endIcon={<KeyboardArrowRightIcon />}
                      className="btn-rounded btn-text-white btn-blue-600"
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
