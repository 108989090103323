import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import chatReducer from '../features/auth/chatSlice'
import serviceChatReducer from '../features/auth/serviceChatSlice'
import tooltipSliceReducer from '../features/auth/tooltipSlice'
import serviceProviderReducer from '../features/auth/serviceProviderSlice'
import spNotificationReducer from '../features/auth/spNotificationSlice'
import heightSliceReducer from '../features/auth/heightSlice'

export default configureStore({
        reducer: {
                auth: authReducer,
                chat: chatReducer,
                serviceChat: serviceChatReducer,
                tooltipSlice: tooltipSliceReducer,
                serviceProviderProfile: serviceProviderReducer,
                spNotification: spNotificationReducer,
                heightSlice: heightSliceReducer,
        },
})