import Documents from "../Documents";
import AssetDetails from "../AssetDetails";
import GallerySlider from "../GallerySlider";
import Description from "../Description";
import OwnerInformation from "../OwnerInformation";
import BaseDetailsAsset from "./BaseDetailsClass";
import Templates from "../Templates";
import { formatNumber } from "../../Components/Common/USFormat";

export default class Tokenize extends BaseDetailsAsset {

    rightPanel = [];
    leftPanel = [];

    constructor(_data) {
        super(_data);
        if (!_data) {
            throw new Error(
                "Custom error: Funds data is required in Art Details class"
            );
        }
        let watchData = _data?.watchData
        // Determine the string for months (singular or plural)
        const loanDurationMonths =
            _data?.loan_duration_month > 1
                ? `${_data?.loan_duration_month} Months`
                : `${_data?.loan_duration_month} Month`;

        const loanDurationFrom = _data?.suggestReviewData?.length ? formatNumber(parseFloat(_data?.suggestReviewData[0]?.loan_duration_from)) : 1;

        // const loanDurationFromMonts = loanDurationFrom > 1
        //   ? `${loanDurationFrom} Months`
        //   : `${loanDurationFrom} Month`;

        const result = `${this.data?.is_publish ? loanDurationFrom + ' - ' : ''} ${loanDurationMonths}`;

        const loanRoiFrom = _data?.suggestReviewData?.length ? formatNumber(parseFloat(_data?.suggestReviewData[0]?.loan_roi_from)) : '1';

        this.dealData = [
            {
                title: "Loan Duration",
                value: result,
            },
            {
                title: "Interest Rate",
                value: `${this.data?.is_publish ? loanRoiFrom + ' - ' : ''} ${_data?.loan_roi}` + ' % APR',
            }
        ];

        console.log('a', _data);

        this.assetDetails = [
            // {
            //     title: "Investment Type",
            //     value: _data?.investment_type?.investment_name,
            // },
            {
                title: "Asset Type",
                value: _data?.assets_category?.title ?? "",
            },
            {
                title: "Estimated value at purchase",
                value: `$${formatNumber(parseFloat(_data?.watch_estimated))}` ?? "",
            },
            {
                title: "Brand",
                value: watchData?.watch_brand ?? "",
            },
            {
                title: "Manufactured Year",
                value: watchData?.watch_year ?? "",
            },
            // new keys start
            {
                title: "Gender",
                value: watchData?.watch_gender ?? "",
            },
            {
                title: "Type Of Watch",
                value: watchData?.type_of_watch ?? "",
            },
            {
                title: "Water Resistance",
                value: watchData?.watch_resistance ?? "",
            },
            {
                title: "Crystal",
                value: watchData?.watch_crystal ?? "",
            },
            {
                title: "Strap Material",
                value: watchData?.watch_strap_material ?? "",
            },
            {
                title: "Thickness",
                value: watchData?.watch_thickness ?? "",
            },
            {
                title: "Case Material",
                value: watchData?.watch_case_material ?? "",
            },
            {
                title: "Watch Model",
                value: watchData?.watch_model ?? "",
            },
            {
                title: "Watch Condition",
                value: watchData?.watch_condition ?? "",
            },
            // new keys end
            {
                title: "Owned By",
                value: _data.owner_information
                    ? _data.owner_information.owner_type
                    : "",
            },
        ];

        console.log("_data...", _data)

        this.fromClass = "watchDetails";
        this.docs = _data.asset_document;
        this.description_document = _data.description_document
        this.asset_signing_document = _data.asset_signing_document
        this.investment_type_id = _data.investment_type_id
        this.selected_templates = _data?.template_data ? _data?.template_data[0]?.template_ids : []
        this.location = _data?.asset_location?.split(",");
        this.galleryImages = _data?.asset_gallery;
        this.is_onboard = _data?.is_onboard
        this.nft_id = _data?.mint_token_id
        this.assetData = _data
        this.member_id = _data?.user_details?.member_id

        if (_data.description_document?.length > 0) {
            const documentOnly = _data.description_document.filter(item => item.images && (item.images.type === "mp4"));
            if (documentOnly?.length > 0) {
                this.galleryImages = _data?.asset_gallery.concat(documentOnly);
            }
        }
        this.coverPhoto = _data?.asset_coverphoto;
        this.descriptionData = {
            title: _data?.asset_title,
            subtitle: watchData?.watch_brand,
            address: watchData?.watch_brand,
            investmentType: _data?.investment_type?.investment_name,
            assetType: _data?.assets_category?.title,
            assetSubtype: _data?.asset_sub_category_info?.title,
            description: _data?.asset_description,
        };

        this.setLeftPanelComponents();
        this.setRightPanelComponents();
    }

    setLeftPanelComponents = () => {
        this.leftPanel.push(<GallerySlider images={this.galleryImages} coverPhoto={this.coverPhoto} isOnboard={this.is_onboard} nft_id={this.nft_id} assetData={this.assetData} isTokenize={true} />);
        this.leftPanel.push(
            <Description
                description={this.descriptionData.description}
                investmentType={this.descriptionData.investmentType}
                title={this.descriptionData.title}
                address={this.descriptionData.address}
                subtitle={this.descriptionData.subtitle}
                assetType={this.descriptionData.assetType}
                assetSubtype={this.descriptionData.assetSubtype}
                userId={this.user_id}
                assetId={this.asset_Id}
            />
        );
    };

    setRightPanelComponents = () => {
        if (!this.isOwner) { // only show below code block components if user is not owner
            this.rightPanel.push(<OwnerInformation owner={this.ownerInfo} totalProject={this.totalProjects} member_id={this.member_id} />);
        }
        this.rightPanel.push(<AssetDetails details={this.assetDetails} editable={this.isOwner} isLoan={this.isLoan} />);
        this.rightPanel.push(<Documents documentsData={this.docs} descriptionData={this.description_document} assetId={this.asset_Id} ownerId={this.isOwner} editable={this.isOwner} projectStatus={this.projectStatus} section_title="Asset Documents" document_name="asset_document" investment_type_id={this.investment_type_id} isLoan={this.isLoan} />);
        this.rightPanel.push(<Templates documentsData={this.asset_signing_document} descriptionData={[]} ownerId={this.ownerInfo} assetId={this.asset_Id} editable={this.isOwner} projectStatus={this.projectStatus} investment_type_id={this.investment_type_id} selected_templates={this.selected_templates} isLoan={this.isLoan} />);
    };
}
