import React, { useState ,useEffect } from "react";
import "./SavedServices.css";
import { Box, Grid, Typography } from "@mui/material";
import { GetApiParam } from '../../../Api/Api'
import MainCard from "../../Components/MainCard/MainCard";
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";

const SavedServices = () => {
    const [serviceData, setServicesData] = useState([]);
    const loginUserData = getUserDataFromLocalStorage();

    async function getServicesData() {
        try {
            const payload = { login_user_id: loginUserData?.id, savedRequest: true };
            const res = await GetApiParam("/services/getServices", payload);
            setServicesData(res?.data?.data?.length ? res.data.data : []);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getServicesData();
    }, [])
  return (
    <Box className="saved-service-container">
      {serviceData && serviceData.length > 0 ? 
      <Typography className="font-28">Your Saved Services</Typography> : 
      <Typography className="font-28">Saved Services Not Available.</Typography>}

      <Box className="saved-service-card-wrapper">
        <Box margin={'10px 0px 0px 16px'}>
            <Grid container spacing={2}>
                {
                    <Grid container spacing={1}>
                        {serviceData?.map((card, index) => (
                            <Grid key={index} item md={3} sm={6} xs={12}>
                                <MainCard {...card } getServicesData={getServicesData}  bgColor='var(--blue-800)' />
                            </Grid>
                        ))}
                    </Grid>
                }
            </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default SavedServices;
