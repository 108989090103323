import React, { useState } from "react";
import { Box } from "@mui/material";
import FabIcon from "./FabIcon";
import "./ChatBot.css";
import ChatModal from "./ChatModal";

function ChatBotPage() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openChat, setOpenChat] = useState(false);

  return (
    <>
      {/* ChatBot Message Box */}

      <ChatModal
        anchorEl={anchorEl}
        open={openChat}
        setOpenChatModal={setOpenChat}
      />

      {/* ChatBot Icon */}
      <Box className="fab-icon-container">
        <FabIcon
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          open={openChat}
          setOpenChatModal={setOpenChat}
        />
      </Box>
    </>
  );
}

export default ChatBotPage;
