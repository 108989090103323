// this is the card for the parceling page
import { Avatar, Box, Button, Paper, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';

export default function ParcelCard({ card, select, deselect, viewOnly, is_onboard, parcelTokenAddress }) {
  const user_data = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : null;
  const user_id = user_data ? user_data.id : null;

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }} className={`rc-card-container ${card?.is_sold && 'is_sold'}`} >
      {is_onboard && parcelTokenAddress ?
        <Link to={`https://testnet.racescan.io/token/${parcelTokenAddress}//instance/${card?.title?.split('#')[1]}?tab=metadata`} target="_blank" >
          <Avatar variant='square' src={`${process.env.REACT_APP_IMAGE_URL}${card?.imagess?.path}`} alt='Img' sx={{ width: '100%', height: '137px', borderRadius: '16px' }} />
        </Link> :
        <Avatar variant='square' src={`${process.env.REACT_APP_IMAGE_URL}${card?.imagess?.path}`} alt='Img' sx={{ width: '100%', height: '137px', borderRadius: '16px' }} />
      }
      <Box sx={{ mt: 1 }} className='investor-card'>
        <Typography variant='subtitle2' gutterBottom className='font-16'>{card?.title}</Typography>
        {viewOnly && card.is_sold ?
          (<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span className='font-12 text-blueGray-400'>${card?.price}</span>
            <Box sx={{ display: "inline-block" }}> <Typography className='sold-btn-red' sx={{ margin: '3px 0px' }} >{(card?.investor_id && user_id && (card?.investor_id === user_id)) ? "Purchased" : "Sold"}</Typography> </Box>
          </Box>) :
          <Typography variant='body2' gutterBottom className='font-12 text-blueGray-400' my={1}>${card?.price}</Typography>
        }
        {
          !viewOnly &&
          (card.is_sold ? <Box my={'13px'} sx={{ display: "inline-block" }}> <Typography className='sold-btn-red' >{(card?.investor_id && user_id && (card?.investor_id === user_id)) ? "Purchased" : "Sold"}</Typography> </Box> :
            card.selected ? <Button className='btn-rounded btn-yellow-50 btn-text-yellow-900' onClick={() => deselect(card)} >Selected</Button> :
              <Button className='btn-rounded  btn-blue-300 btn-text-white ' onClick={() => select(card)} variant='contained'>Select</Button>)
        }
      </Box>
    </Paper >
  )
}

// ParcelCard.defaultProps = {
//   viewOnly: false
// }

