import React from "react";
import { Box, Typography, Divider, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";

export default function PriceOverview({ prices }) {
    const isXs = useMediaQuery('(max-width:600px)');

    return (
        <Box className="pd-sidebar-priceOverview" pt={0.8} pb={1.5} display={'flex'} flexWrap={'wrap'}>
            <Divider />
            {
                Object.keys(prices).map((key, index) => {
                    return (
                        <Box className="priceOverview" pl={1} minWidth={'50%'}>
                            <Typography className="title font-12 text-blueGray-400 text-capitalize" mt={0.3}>{key}</Typography>
                            <Typography className={isXs ? "price font-18" : "price font-22"}>{prices[key]}</Typography>
                        </Box>
                    )
                })
            }
        </Box>
    )
}

PriceOverview.propTypes = {
    prices: PropTypes.shape({
        any: PropTypes.any
    }),
}
