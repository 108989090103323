import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'
import { Box, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import ProfileDescription from '../../Components/ProfileDescription/ProfileDescription';
import ConfirmModal from '../../../Components/Modal/Confirm/Confirm';
import { updateApi } from '../../../Api/Api';
import "./PostViewProject.css";

const OfferUserProfile = ({setIsDrawerOpen, offerData, individualProject, getOfferData}) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedSpvId, setSelectedSpvId] = useState('');
  const [selectedOfferId, setSelectedOfferId] = useState('');

  useEffect(()=>{
    setData(offerData);
  },[offerData]);

  const openModalAndSetSpvIdAndOfferId = useCallback((spvId, offerId) => {
    setSelectedSpvId(spvId);
    setSelectedOfferId(offerId);
    setOpenModal(true);
  }, []);

  const profile = useMemo(()=>{
    return data.map((element)=>
      <Box className="profile-box">
        <ProfileDescription
          name={`${element.spvProfileDetails.first_name} ${element.serviceProviderData.last_name}`}
          address={element?.spvProfileDetails?.address}
          title={element?.spvProfileDetails?.title}
          description={element?.spvProfileDetails?.description}
          persona={`${element.spvProfileDetails?.profilePicture 
            && element.spvProfileDetails?.profilePicture.length > 0 
            ? element.spvProfileDetails?.profilePicture[0]?.path 
            : ''}`}
          coverPicture={`${element.spvProfileDetails?.coverPicture 
            && element.spvProfileDetails?.coverPicture.length > 0 
            ? element.spvProfileDetails?.coverPicture[0]?.path
            : ''}`}
          hourlyrate={`${element.offer_amount}`}
          from = "serviceProviderProfileOffer"
          setOpenModal={openModalAndSetSpvIdAndOfferId}
          spvId={element.spv_id}
          offerStatus={element.status}
          offerId={element.id}
          projectId={individualProject.id}
          user_id={element?.spvProfileDetails?.user_id}
          availability={element?.spvProfileDetails && element?.spvProfileDetails?.availability}
        />
      </Box>
    )
  },[data]);

  const handleAction = useCallback((action)=>{
    if(action === 'confirm') {
      navigate(`/service-proposer-dashboard/postNewContract/${individualProject.id}/${selectedSpvId}/${selectedOfferId}`);
    }else{
      updateApi(`/services/spvOffer/${selectedOfferId}`, { status: 3 }).then((res) => {
        if (res?.status === 200) {
          if(res.data.code === 200){
            showSnackbar(res?.data?.message, 'success');
          }else{
            showSnackbar(res?.data?.message, 'error');
          }
          getOfferData(individualProject.id);
          setOpenModal(false);
        }
      });
    }
  });
  
  return (
    <div>
        <Stack direction={'row'} justifyContent={'space-between'}>
            {/* <IconButton className='closeIconProposer'>
                <CloseIcon onClick={()=>setIsDrawerOpen(false)}/>
            </IconButton> */}
            <IconButton
              aria-label="delete"
              variant="contained"
              className="btn-golden btn-rounded btn-large closeIconProposer"
              onClick={()=>setIsDrawerOpen(false)}
            >
              <CloseIcon />
            </IconButton>
            {/* <Button className='viewButton' startIcon={<RemoveRedEyeIcon />}>
                 View Full Profile
            </Button> */}
        </Stack>
        <Box sx={{ overflow: 'auto' }}>
          {profile}
        </Box>
        <ConfirmModal title={'Hire'} description={'Are You Sure Want To Confirm It!'} isOpen={openModal} setIsOpenModal={setOpenModal} handleAction={handleAction}/>
    </div>
  );
};

export default OfferUserProfile;