import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Grid, AppBar, Toolbar, Divider, Box, Button, InputAdornment, Typography, TextField, Avatar, IconButton, styled } from '@mui/material';
import { ContentCopyOutlined, Close } from '@mui/icons-material';
import { Barcode } from '../../../Components/Images/Images'
import './Security.css'

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiFilledInput-root': {
        backgroundColor: '#05131B',
        borderRadius: '8px',
    },
    '& .MuiFilledInput-root:hover': {
        backgroundColor: '#05131B',
    },
    '& .MuiFilledInput-root.Mui-focused': {
        backgroundColor: '#05131B',
    },
    '& .MuiInputBase-input.MuiFilledInput-input': {
        color: '#ffffff',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '16px',
    },
    '& .MuiInputBase-input.Mui-focused': {
        backgroundColor: '#05131B'
    },
    '& .MuiFilledInput-underline:after': {
        border: '1px solid #C5E4FF'
    },
    '& .MuiFilledInput-underline:hover:after': {
        border: '1px solid #C5E4FF'
    },
    '& label.MuiInputLabel-root': {
        color: '#64748B',
    }
}));

const Security = () => {
    const [isCopied, setCopied] = useState(false);
    const [authenticationCode, setAuthenticationCode] = useState('');

    const copyToClipboard = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false)
        }, 2000)
    }
    console.log(authenticationCode)
    const linkToGoogleAuthenticator = <Link to={'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'} target="_blank" rel="noopener noreferrer" style={{ fontWeight: 700, color: '#94A3B8', textDecoration: 'underline' }}>here.</Link>;
    return (
        <>
            <AppBar className="stSc-tab" position="static">
                <Toolbar className="stSc-tab" disableGutters>
                    <Typography variant='subtitle2'> Secure your account with 2FA</Typography>
                </Toolbar>
                <Box className='divider' >
                    <Divider variant="h2" />
                </Box>
                <Grid item xs={12} sm={12} md={12} container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} >
                        <Box>
                            <Box className='stSc-step1'>
                                <Typography>
                                    Step 1: Set up your Authenticator App
                                </Typography>
                            </Box>
                            <Box>
                                <Typography>
                                    Set up your authenticator app by scanning the QR code below:
                                </Typography>
                                <Typography className='stDa-text'>
                                    Download Google Authenticator App {linkToGoogleAuthenticator}
                                </Typography>
                                <Box className="">
                                    <Avatar className='stBarcode' src={Barcode}>
                                    </Avatar>
                                </Box>
                            </Box>
                            <Typography>
                                Having trouble scanning? Set up manually with this key
                            </Typography>
                        </Box>
                        <Box>
                            <CustomTextField
                                id="filled-basic"
                                variant="filled"
                                autoComplete='off'
                                className='stIf'
                                value={'R27TNJEZOJYEOCCTJAUOHIJ762OWZOWU6AALAJQ'}
                                disabled
                                // helperText={isCopied ? 'Text copied to clipboard' : null}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton onClick={copyToClipboard}>
                                                <ContentCopyOutlined sx={{ color: '#FFFFFF !important' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ input: { color: "#FFFFFF !important" } }}
                            />
                        </Box>
                        {isCopied ? <Box sx={{ textAlign: 'right', color: 'green' }}><Typography>Text copied to clipboard</Typography> </Box> : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box className='stSc-step1'>
                            <Typography>Step 2: Enter code generated by your app</Typography>
                        </Box>
                        <Box className=''>
                            <Typography>Enter the 6 digit code generated by your authenticator app</Typography>
                        </Box>
                        <Box >
                            <CustomTextField
                                id="filled-basic"
                                variant="filled"
                                autoComplete='off'
                                className='stIf'
                                placeholder='Enter code'
                                // defaultValue={'123456'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <Close onClick={() => setAuthenticationCode("")} sx={{ color: '#FFFFFF !important' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => setAuthenticationCode(e.target.value)}
                                label="Authentication code"
                                sx={{ input: { color: "#FFFFFF !important" } }}
                            />
                        </Box>
                        <Box>
                            <Button sx={{ mt: 1, width: '100%' }} className="btn btn-rounded btn-teal-100">Enable</Button>
                        </Box>
                    </Grid>
                </Grid>
            </AppBar>
        </>
    )
}

export default Security