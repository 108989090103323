import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography, Button, Avatar } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import { reachInvestorsTimerGolden, reachInvestorsViewGolden, reachInvestorsUserGolden, reachInvestorsRightGolden } from "../../Components/Images/Images";
import "./ReachInvestors.css";

const ReachInvestors = () => {
  const navigate = useNavigate();
  const localData = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : "";

  function handleSubmitProject() {
    if (localData?.id) {
      navigate("/questionnaire-get-started")
    }
    else {
      navigate("/login")
    }
  }

  return (
    <Box className="reachInvestors-wrap bg-blue-800" pb={8} pt={3}>
      <Box className="container">
        <Grid container spacing={6} mt={0} alignItems={"center"}>
          <Grid item sm={12} md={7}>
            <Box className="rn-thumbnail-wrap">
              <Grid container spacing={1.5} direction="row">
                <Grid item xs={12} sm={6}>
                  <Box className="lg-icon-thumbnail reachInvestors bg-blue-900">
                    <Box className="lg-icon-wrap">
                      <Avatar alt="Icon" variant="square" src={reachInvestorsViewGolden} ></Avatar>
                    </Box>
                    <Box className="lg-content" mt={3}>
                      <Typography component={"h5"}>
                        Automated process <br /> powered by the <br />{" "}
                        Blockchain
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className="lg-icon-thumbnail reachInvestors bg-blue-900">
                    <Box className="lg-icon-wrap">
                      <Avatar alt="Icon" variant="square" src={reachInvestorsTimerGolden} ></Avatar>
                    </Box>
                    <Box className="lg-content" mt={3}>
                      <Typography component={"h5"}>
                        No middlemen and <br /> unnecessary <br /> paperwork
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className="lg-icon-thumbnail reachInvestors bg-blue-900">
                    <Box className="lg-icon-wrap">
                      <Avatar alt="Icon" variant="square" src={reachInvestorsUserGolden} ></Avatar>
                    </Box>
                    <Box className="lg-content" mt={3}>
                      <Typography component={"h5"}>
                        Get access to over <br /> 1000 qualified <br />{" "}
                        investors
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className="lg-icon-thumbnail reachInvestors bg-blue-900">
                    <Box className="lg-icon-wrap">
                      <Avatar alt="Icon" variant="square" src={reachInvestorsRightGolden} ></Avatar>
                    </Box>
                    <Box className="lg-content" mt={3}>
                      <Typography component={"h5"}>
                        Curated and vetted by <br /> decentralized <br />{" "}
                        investment committee
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item sm={12} md={5}>
            <Box className="reachInvestors-right">
              <Box className="section-heading" mb={3.5}>
                <Typography className="title" variant="h3">
                  Reach investors from all corners of the world
                </Typography>
                <Typography className="subtitle">
                  RACE allows anyone from anywhere to invest in projects that
                  have not been available before. Be a part of the
                  democratization of investing and gain access to investors like
                  never before.
                </Typography>
              </Box>
              <Button className="btn-rounded btn-large btn-golden"
                onClick={handleSubmitProject}
              >

                <Box component={"span"} mr={1}>
                  <CreateIcon />
                </Box>{" "}
                Submit your project now

              </Button>

            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default ReachInvestors;
