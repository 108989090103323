import React, { useState } from "react";
import { LocationOn } from "@mui/icons-material";
import { Avatar, Box, IconButton, Modal, Stack, Typography } from "@mui/material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import FileViewer from "react-file-viewer";
import "./ListCard.css";

const ListCard = ({
  title,
  fileVal,
  titleColor,
  subtitle1,
  subtitle1Color,
  subtitle2,
  listIcon,
  iconColor,
  type,
  bgColor,
  from,
  eyeIcon
}) => {

  const [openModal, setOpenModal] = useState(false);
  const [view, setView] = useState(false);
  const [file, setFile] = useState();
  const [pdfType, setType] = useState("");
  
  const viewDoc = async (documentData) => {
    // setOpenModal(true);
    // handleView();
    // setFile(fileVal);
    setType('pdf');
    setOpenModal(true);
    setView(true);
    if (documentData?.path && documentData?.title) {
      const fileName = await fetch(`${process.env.REACT_APP_IMAGE_URL}${documentData.path ? documentData.path : ""
        }`);

      const templateBuffer = await fileName.arrayBuffer();
      const templateBlob = new Blob([templateBuffer], { type: 'application/pdf' });
      const templateUrl = URL.createObjectURL(templateBlob);
      setFile(templateUrl);
    }else{
      setFile(documentData);
    }
  };

  const handleClose = () => setOpenModal(false);

  const handleView = () => {
    setView(!view);
  };

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  if (from === "RightProjectProfileSection") {
    return (
      <Stack direction="row" alignItems={"center"} className="listCard">
        {listIcon && type === "icon" ? (
          // for mui icons
          <Box
            className="listCard-icon"
            style={{ background: bgColor ? bgColor : "" }}
            color={iconColor ? iconColor : ""}
          >
            {listIcon}
          </Box>
        ) : (
          // for image types
          <Avatar src={listIcon} />
        )}
        <Stack alignItems={"flex-start"} gap={"2px"}>
          <Typography
            variant="subtitle2"
            color={titleColor ? titleColor : ""}
            sx={{ wordBreak: "break-word" }}
          >
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            color={subtitle1Color ? subtitle1Color : ""}
            className="font-12 text-blueGray-500"
            sx={{ wordBreak: "break-word" }}
          >
            {subtitle1}
          </Typography>
          <Typography
            variant="subtitle1"
            className="font-12 text-blueGray-500"
            sx={{ wordBreak: "break-word" }}
          >
            {subtitle2}
          </Typography>
        </Stack>
      </Stack>
    );
  } else {
    return (
      <Stack
        direction="row"
        spacing={2}
        ml={1}
        mb={"4px"}
        alignItems={"center"}
        className="listCard"
        justifyContent={'space-between'}
      >
        <Stack direction="row" alignItems={"center"} spacing={2}>
          {listIcon && type === "icon" ? (
            // for mui icons
            <Box
              className="listCard-icon"
              style={{ background: bgColor ? bgColor : "" }}
              color={iconColor ? iconColor : ""}
            >
              {listIcon}
            </Box>
          ) : (
            // for image types
            <Avatar src={listIcon} />
          )}
          <Stack alignItems={"flex-start"} gap={"2px"}>
            <Typography
              variant="subtitle2"
              color={titleColor ? titleColor : ""}
              sx={{ wordBreak: "break-word" }}
            >
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              color={subtitle1Color ? subtitle1Color : ""}
              className="font-12 text-blueGray-500"
              sx={{ wordBreak: "break-word" }}
            >
              {subtitle1}
            </Typography>
            <Typography
              variant="subtitle1"
              className="font-12 text-blueGray-500"
              sx={{ wordBreak: "break-word" }}
            >
              {subtitle2}
            </Typography>
          </Stack>
        </Stack>
        {eyeIcon === false ? "":
        <IconButton aria-label="View" size="large" className='square-icon-btn' onClick={() => viewDoc(fileVal)} >
          <RemoveRedEyeIcon />
        </IconButton>}
        {view &&
          <Modal
            className="projectDocumentsModal"
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            fullWidth={true}
          >
            <Box sx={styleModal}>
              <OverlayScrollbarsComponent defer
                style={{ width: '100%', height: '100%' }}
              >
                {/* {fileVal?.title ?
                   <FileViewer fileType={pdfType} filePath={`${process.env.REACT_APP_IMAGE_URL}${file?.path}`} />:
                   <FileViewer fileType={pdfType} filePath={URL.createObjectURL(file)} /> 
                } */}
                  <object
                    data={fileVal?.title ? file : URL.createObjectURL(file)}
                    type="application/pdf"
                    style={{ width: '100%', height: '100%' }}
                  >
                    <p style={{ textAlign: 'center' }}>
                      Your browser does not support PDFs. <a href={file} target="_blank" style={{ color: 'lightgreen' }}>Download the PDF</a>.
                    </p>
                  </object>
              </OverlayScrollbarsComponent>
            </Box>
          </Modal>
        }
      </Stack>

    );
  }
};


export default ListCard;
