import React, { useState } from "react";
import { NewLogo } from "../../Components/Images/Images";
import { Box, Modal, Avatar, Typography, Button, FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { Close } from "@mui/icons-material";

const style = {
    position: "relative",
    boxShadow: 24,
    borderRadius: "24px",
    // width: "1043px",
    width: "800px",
};

export default function SelectCurrencyType({ open, onClose, handleFeeTokenType }) {

    // state to hold payment currency type
    const [selectedOption, setSelectedOption] = useState('USDT');

    /**
    * Handle change in currency type
    * @param {Event} event 
    */
    const handleOptionChange = (event) => { setSelectedOption(event.target.value); };

    /**
    * Call parent function with data
    * @param {Event} event 
    */
    const handleNext = () => {
        handleFeeTokenType(selectedOption);
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            className="kyc-modal"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <Box className="modal-body" sx={style}>
                <Box className="headContent">
                    <Box className="logo">
                        <Avatar
                            alt="Logo"
                            src={NewLogo}
                            variant="square"
                            sx={{ width: 39, height: 90, objectFit: "contain" }}
                        />
                    </Box>
                    <Close className="close-btn" onClick={onClose} />
                </Box>
                <Box className="modalContentDone " >
                    <Box className="main-content loan " >
                        <Box className="currency-pop-up">
                            <Typography component="h1" my={2} px={4} className="headText" textAlign={"center"}>Select Currency</Typography >

                            <Box className='investor-select-currency-type' px={4}>
                                <Typography className='pay-using font-14'>
                                    Pay using
                                </Typography>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="payment-method"
                                        name="payment-method"
                                        defaultValue="USDT"
                                        row
                                        className='currency-options'
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                    >
                                        {/* <FormControlLabel
                                            value="RACE"
                                            control={<Radio />}
                                            label="RACE Token"
                                        /> */}
                                        <FormControlLabel
                                            value="USDT"
                                            control={<Radio />}
                                            label="USDT"
                                        />
                                        <FormControlLabel
                                            value="USDC"
                                            control={<Radio />}
                                            label="USDC"
                                        />
                                        <FormControlLabel
                                            value="DAI"
                                            control={<Radio />}
                                            label="DAI"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>

                            <Box className='vote-btn-box' mt={2}>
                                {/* <Button className={"wallet-button appr-btn"}>Back</Button> */}
                                <Button className={"wallet-button appr-btn"} sx={{ maxWidth: '50%' }} onClick={handleNext}>Next</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal >
    );
}
