import React, { useEffect, useState } from "react";
import {
  Box,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import "./MyJobs.css";
import JobList from "./JobList";
import { GetApi } from "../../../Api/Api";
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";

const MyJobs = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');
  const [value, setValue] = React.useState(tab ? Number(tab) : 0);
  const [jobList, setJobList] = React.useState([]);
  const userData = getUserDataFromLocalStorage();
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ itemsPerPage, setItemsPerPage ] = useState(5);
  const [ paginationData, setPaginationData ] = useState({
    "page": 1,
    "pageSize": 0,
    "totalCount": 0,
    "totalPages": 0
  });

  const handleChange = (event, newValue) => {
    setCurrentPage(1);
    setValue(newValue);
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const fetchData = async()=>{
    let status = 2;
    if(value === 0)status = 3;
    if(value === 1)status = '1,2';
    if(value === 2)status = 4;
    if(value === 3)status = 5;
    if(value === 4)status = 6;
    if(value === 5)status = 7;
    let url = `/services/getContract?spv_id=${userData.id}&status=${status}&page=${currentPage}&rowperpage=${itemsPerPage}`;
    if(value === 6){
        url = `/services/getContract?spv_id=${userData.id}&page=${currentPage}&rowperpage=${itemsPerPage}`;
    }
    GetApi(url).then((res) => {
        if (res?.status === 200) {
          if (res.data && res.data.data && res.data.data.length > 0) {
            setJobList(res.data.data);
            setPaginationData(res.data.pagination)
          }else{
            setJobList([]);
            setPaginationData({
              "page": 1,
              "pageSize": 0,
              "totalCount": 0,
              "totalPages": 0
            })
          }
        }
    })
  };

  useEffect(() => {
    fetchData();
  }, [value, currentPage]);
  
  return (
    <>
      <Box className="my-job-container">
        <Typography className="font-28">Manage Jobs</Typography>
        <Box >
          <Box sx={{ width: "100%" }}>
            <Box className="my-job-tab-container" sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Active"
                  className={value === 0 ? "st-active" : "st-inactive"}
                  {...a11yProps(0)}
                />
                <Tab
                  label="Pending"
                  className={value === 1 ? "st-active" : "st-inactive"}
                  {...a11yProps(1)}
                />
                <Tab label="Missing Details" className={value === 2 ? "st-active" : "st-inactive"} {...a11yProps(2)} />
                <Tab label="Delivered" className={value === 3 ? "st-active" : "st-inactive"} {...a11yProps(3)} />
                <Tab label="Completed" className={value === 4 ? "st-active" : "st-inactive"} {...a11yProps(4)} />
                <Tab label="Cancelled" className={value === 5 ? "st-active" : "st-inactive"} {...a11yProps(5)} />
                <Tab label="All" className={value === 6 ? "st-active" : "st-inactive"} {...a11yProps(6)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <JobList Title="Active Jobs" fetchData={fetchData} jobList={jobList} paginationData={paginationData} currentPage={currentPage} setCurrentPage={setCurrentPage} setItemsPerPage={setItemsPerPage}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <JobList Title="Pending Jobs" fetchData={fetchData} jobList={jobList} paginationData={paginationData} currentPage={currentPage} setCurrentPage={setCurrentPage} setItemsPerPage={setItemsPerPage}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <JobList Title="Missing Detail Jobs" fetchData={fetchData} jobList={jobList} paginationData={paginationData} currentPage={currentPage} setCurrentPage={setCurrentPage} setItemsPerPage={setItemsPerPage}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <JobList Title="Delivered Jobs" fetchData={fetchData} jobList={jobList} paginationData={paginationData} currentPage={currentPage} setCurrentPage={setCurrentPage} setItemsPerPage={setItemsPerPage}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
                <JobList Title="Completed Jobs" fetchData={fetchData} jobList={jobList} paginationData={paginationData} currentPage={currentPage} setCurrentPage={setCurrentPage} setItemsPerPage={setItemsPerPage}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
                <JobList Title="Cancelled Jobs" fetchData={fetchData} jobList={jobList} paginationData={paginationData} currentPage={currentPage} setCurrentPage={setCurrentPage} setItemsPerPage={setItemsPerPage}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={6}>
                <JobList Title="All Jobs" fetchData={fetchData} jobList={jobList} paginationData={paginationData} currentPage={currentPage} setCurrentPage={setCurrentPage} setItemsPerPage={setItemsPerPage}/>
            </CustomTabPanel>
          </Box>
        </Box>
      </Box>
     
    </>
  );
};

export default MyJobs;
