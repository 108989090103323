import React, { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Button, Box, Avatar } from "@mui/material";
// import Header from '../Header/Header'
import HeaderInvestorOnlyLogged from "../Header/HeaderInvestorOnlyLogged";
import Sidebar from "../Sidebar/Sidebar";
import { squareQuestionsMark } from "../../Components/Images/Images";

function Layout() {
   // Get the navigate and useLocation functions from the respective custom hooks
   const navigate = useNavigate();
   const location = useLocation();

   // State variable for the sidebar open state
   const [sidebarOpen, setSidebarOpen] = useState(false);

   // Function to handle the change in the sidebar state
   const handleSidebarChange = (newState) => {
      // Handle the state change in the sidebar
      setSidebarOpen(newState);
   };

   // Event handler to navigate to "/dummy" when clicked
   const handleClick = () => {
      navigate("/dummy");
   };

   return (
      <>
         <Box className='main-layout'>
            <Box className="dashborad-header">
               <HeaderInvestorOnlyLogged />
            </Box>
            <Box className='main-page investor-main-page' display={'flex'}  >
               <Box >
                  <Sidebar handleSidebarChange={handleSidebarChange} />
               </Box>
               <Box className={`main-contant ${location.pathname === '/user/chats' ? "chat-section" : ""}`}>
                  <Outlet context={sidebarOpen} />
                  {/* {
                     // if location is of chat page then don't show help button
                     location.pathname !== '/user/chats' &&
                     <Button className="squareHelp-btn btn-rounded btn-yellow-100 btn-large" onClick={handleClick}>
                        <Avatar alt='Icon' src={squareQuestionsMark} sx={{ width: '36px', height: '36px' }}></Avatar>
                     </Button>
                  } */}
               </Box>
            </Box>
         </Box>
      </>
   );
}

export default Layout;
