import React from 'react'
import { Avatar, Box, Button, Grid, Stack, Typography } from '@mui/material'
import { RealEstate, OfflineBolt, PersonPin, ListPlus } from '../../Components/Images/Images'
import Header from '../../Layouts/Header/HeaderServiceProposer'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "./OnboardingOne.css"
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ServiceProviderLogo } from '../../Components/Images/Images';

const OnboardingOne = () => {

    const location = useLocation();
    const navigate = useNavigate();

    /**
     * Onclick function to navigate on the next page
     */
    const handleContinue = () => {
        navigate("/service-provider/onbording2")
    }

    return (
        <Box className="onboarding-one-main ">
            <Box className="logo-onboarding-one">
                <Link to="/">
                    <img src={ServiceProviderLogo} alt="Logo" />
                </Link>
            </Box>
            <Box className="onboarding-container">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={7}>
                        <Typography component="h4" className="title">Ready to start working on <br />projects on RACE?</Typography>
                        <Box className="onboarding-list">
                            <Box sx={{ paddingLeft: '26px' }}>
                                <Box className='onboarding-list-main'>
                                    <Box className='left-div'>
                                        <img src={OfflineBolt} alt="" />

                                    </Box>
                                    <Box className='right-div'>
                                        <Typography component="h4" className='title'>
                                            Learn what makes a effective profile
                                        </Typography>
                                        <Typography component="p" className='sub-title'>Discover the do’s and don’ts to ensure you have your best self forward and <br /> showcased to potential clients.</Typography>
                                    </Box>
                                </Box>
                                <Box className='onboarding-list-main'>
                                    <Box className='left-div'>
                                        <img src={PersonPin} alt="" />
                                    </Box>
                                    <Box className='right-div'>
                                        <Typography component="h4" className='title'>
                                            Create your Service Provider profile
                                        </Typography>
                                        <Typography component="p" className='sub-title'>Add your profile picture, past projects and professional information to <br /> increase your credibility.</Typography>
                                    </Box>
                                </Box>
                                <Box className='onboarding-list-main'>
                                    <Box className='left-div'>

                                        <img src={ListPlus} alt="" width="24" />

                                    </Box>
                                    <Box className='right-div'>
                                        <Typography component="h4" className='title'>
                                            Publish your services
                                        </Typography>
                                        <Typography component="p" className='sub-title'>Create a service post of the services you’re offering and start selling <br /> instantly.</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            {/* <Box className="continue-btn">
                                <Stack direction="row" spacing={2}>
                                    <Button variant="contained" startIcon={<ArrowForwardIcon />}  className='btn-onboarding-one' onClick={() => handleContinue()}>
                                        Continue
                                    </Button>
                                </Stack>
                            </Box> */}
                            <Stack direction="row" spacing={2} alignItems="center" mt={1}>
                                <Box className='go-back-btn'>
                                        <Button startIcon={<ArrowBackIcon />} onClick={() => navigate('/service-provider')} >
                                            Go Back
                                        </Button>
                                    </Box>
                                <Box className="continue-btn">
                                    <Button variant="contained" startIcon={<ArrowForwardIcon />} className='btn-rounded btn-large' style={{ background: "var(--golden-gradient)" }} onClick={() => handleContinue()}>
                                        Continue
                                    </Button>
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Box className="left-img" display={{ xs: 'none', md: 'block' }}>
                            <Avatar variant="square" alt="Image" src={RealEstate} sx={{ width: 'auto', height: 'auto' }}></Avatar>
                        </Box>
                    </Grid>

                </Grid>

            </Box>
        </Box>
    )
}

export default OnboardingOne