import React, { useState } from 'react'
import { Box, Button, InputAdornment, TextField, Typography, styled } from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { NewLogo } from '../Components/Images/Images';

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiFilledInput-root': {
        backgroundColor: '#05131B',
        borderRadius: '8px',
    },
    '& .MuiInputBase-input.MuiFilledInput-input': {
        color: '#ffffff',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '16px',
    },
    '& .MuiFilledInput-underline:before': {
        display: 'none',
    },
    '& .MuiFilledInput-underline:after': {
        display: 'none',
    },
    '& .MuiFilledInput-underline:hover:before': {
        display: 'none',
    },
    '& .MuiFilledInput-underline:hover:after': {
        display: 'none',
    },
}));

const FractionView = ({ totalPrice, minimumInvestment, imagePath }) => {
    const [investAmount, setInvestAmount] = useState(0)
    const [fractionPercentage, setFractionPercentage] = useState(0)
    const perFractionValue = (totalPrice ?? 1) / 100
    const [investAmountError, setInvestAmountError] = useState({ error: false, message: '' })

    const calculateFraction = (amount) => {
        let fraction = Math.round((amount / perFractionValue) * 100) / 100
        if (fraction <= 100) {
            setFractionPercentage(fraction)
        }

    }

    const calculateInvestAmount = (fraction) => {
        let amount = Math.round((fraction * perFractionValue) * 100) / 100
        validationCheck(amount)
        setInvestAmount(amount)
    }

    const validationCheck = (amount) => {
        let value = parseFloat(amount)
        if (value > totalPrice) {
            setInvestAmountError({ error: true, message: `Amount should be less than $${totalPrice}` })
            return false
        } else if (value < minimumInvestment) {
            setInvestAmountError({ error: true, message: `Minimum investment amount is $${minimumInvestment}` })
            return false
        }
        else { //no error
            setInvestAmountError({ error: false, message: '' })
            return true
        }
    }

    return (
        <>
            <Typography variant='subtitle2' sx={{ mt: 2, mb: 2 }} gutterBottom >You Pay</Typography>
            <Box className="payment-enter-investor">
                <CustomTextField onChange={(event) => { setInvestAmount(event.target.value); calculateFraction(event.target.value); }} value={investAmount} hiddenLabel placeholder="amount" variant='filled' FormHelperTextProps={{ sx: { color: 'white' } }}
                    fullWidth InputProps={{
                        startAdornment: <InputAdornment position="start"><AttachMoneyIcon sx={{ color: '#EDE4C5' }} /></InputAdornment>,
                        endAdornment: <InputAdornment position="end"><Button onClick={() => setInvestAmount(totalPrice)} className="rc-tertiary-btn btn-golden" style={{ padding: '0px' }} variant="filled"> max</Button> </InputAdornment>,
                    }}
                    error={investAmountError.error}
                    helperText={investAmountError.message}
                    onInput={(event) => {
                        event.target.value = event.target.value
                            .replace(/\D/g, "")
                    }} />
            </Box>
            <Typography variant='subtitle2' sx={{ mt: 2, mb: 2 }} gutterBottom>You Receive</Typography>
            <Box className="payment-enter-investor">
                <CustomTextField onChange={(event) => setFractionPercentage(event.target.value)} value={fractionPercentage} hiddenLabel placeholder="amount" variant='filled'
                    fullWidth InputProps={{
                        startAdornment: <InputAdornment position="start"><Box height={'32px'} width={'32px'} component={'img'} src={NewLogo} /></InputAdornment>,
                        endAdornment: <InputAdornment position="end"><Button onClick={() => setFractionPercentage(100)} className="rc-tertiary-btn btn-golden" style={{ padding: '0px' }} variant="filled" > max</Button> </InputAdornment>,
                    }}
                    onInput={(event) => {
                        event.target.value = event.target.value
                            .replace(/\D/g, "")
                    }} />
            </Box>
        </>
    )
}

export default FractionView