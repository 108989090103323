// releasedAsset.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";

/**
 * Call the withdrawalAsset method to transfer the asset to the investor and money to the proposer
 * @param {String} address user wallet address
 * @param {Object} assetData asset's details
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function releasedAsset(address, contract_address, contract_abi, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {

        const { request: withdrawalAsset } = await prepareWriteContract({
            address: contract_address,
            abi: contract_abi,
            account: address,
            chainId: 6805,
            functionName: "releasedAsset",
            args: [
                assetData?.listing_id, // listing id 
            ]
        });

        const { hash } = await writeContract(withdrawalAsset);
        if (hash) {
            const data = await waitForTransaction({ hash });
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success');
                handleActiveStep(2);
                setTimeout(() => {
                    handleSuccess(assetData);
                    handleModalClose();
                    handleActiveStep(-1);
                    return { data };
                }, 1000);
            } else {
                handleModalClose();
                handleActiveStep(-1);
                showSnackbar("Transaction Failed", 'error');
                return;
            }
        }
    } catch (error) {
        console.error('Error in calling withdraw amount sale:', error);
        throw error; // propagate the error
    }
}

export { releasedAsset };
