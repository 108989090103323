import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Cards from "../Components/Cards/Cards";
import { GetApiParam } from "../Api/Api";
import { PROJECT_STATUS } from "../constants";

export default function NewestListings({ categoryId, type }) {
  const [listingData, setlistingData] = useState([]);
  const [bookmark, setBookmark] = useState() // for Bookmark
  const [serverTime, setServerTime] = useState(null);
  const [serverDate, setServerDate] = useState(null);
  const [serverClockTime, setServerClock] = useState(null);
  const serverTimeInterval = useRef(null)
  // Update the bookmark state with the fetched data or an empty array
  const localData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : ""

  useEffect(() => {
    async function getTopInvestmentsData() {
      try {
        // Create a payload for the API request
        let payload = {
          category_id: categoryId,
          new_listing: true,
          status: PROJECT_STATUS.SUBMITTED,
          is_onboard: 1,
          is_publish:1
        };
        const res = await GetApiParam("/proposer/getAssetsList/", payload);
        // setlistingData(res?.data?.data.slice(0, 4));
        setlistingData(res?.data?.data);
        setTimer(res?.data)
        setServerTime(res.data?.server_time ? res?.data?.server_time : null);
        setServerDate(res.data?.server_date ? res?.data?.server_date : null);
      } catch (error) {
        console.log(error);
      }
    }
    getTopInvestmentsData();
  }, [categoryId]);


  // functionality for Timer
  function setTimer(res) {
    // initialize server clock
    if (res?.data?.length > 0) {
      const serverClockTime = new Date(res?.server_date);
      var timestamp = res?.server_time.split(":");
      serverClockTime.setHours(timestamp[0]);
      serverClockTime.setMinutes(timestamp[1]);
      serverClockTime.setSeconds(timestamp[2]);
      setServerClock(serverClockTime);
    }
  }

  useEffect(() => {
    serverTimeInterval.current = setInterval(() => {
      if (serverClockTime) {
        serverClockTime.setSeconds(serverClockTime.getSeconds() + 1);
        setServerClock(serverClockTime);
        const event = new CustomEvent("clocktime", {
          detail: serverClockTime,
          bubbles: true,
        });
        document.dispatchEvent(event);
      }
    }, 1000);

  }, [serverClockTime])


  useEffect(() => {
    if (localData?.id) {
      // Api to get InvestmentTypeData 
      async function getBookmarkData() {
        try {
          const payloadData = {
            "user_id": localData.id
          }
          const res = await GetApiParam("/proposer/getBookmark/", payloadData);
          // Update the bookmark state with the fetched data or an empty array if data is not available
          setBookmark(res?.data?.data ? res?.data?.data : [])
          // setInvestmenTypeData(res?.data?.data ? res?.data?.data : []);

        } catch (error) {
          console.log(error);
        }
      }
      const timer = setTimeout(() => {
        getBookmarkData()
    }, 100);
    return () => clearTimeout(timer);
    }
  }, [])

  // functionality for Bookmark
  const bookmarkVal = (asset_id) => {
    return bookmark?.some((item) => item.asset_id === asset_id && item.status === 1);
  }

  // Function to convert string into capital later
//   function capitalizeFirstLetterOfEachWord(string) {
//     return string.split(' ').map(word => {
//         return word.charAt(0).toUpperCase() + word.slice(1);
//     }).join(' ');
// }

  return (
    <Box className="HomeTopInvestments-section" pb={6}>
      <Box className="container">
        <Box className="filter-panel">
          <Box className="fp-left">
            <Box className="heading-left">
              <Typography>Newly Listed</Typography>
              <Typography component={"h6"}>
                Newest {type} Projects{" "}
                <Box component={"span"} className="title-icon">
                  ✨
                </Box>{" "}
              </Typography>
            </Box>
          </Box>
          {listingData && listingData?.length > 4 ?
            <Box className="fp-right">
              <Link
                className="seeAll"
                to="/search"
                state={{ defaultFilter: { category_id: categoryId } }}
              >
                See All
              </Link>
            </Box>
            : null}
        </Box>
        <Box className="HeaderPanelWrapper" mt={4}>
          {/* Newest Real Estate Projects */}
          <Grid spacing={{ xs: 1, sm: 1, md: 2 }} container>
            {
              //   const deals = [];
              listingData &&
              listingData.slice(0, 4).map((el, index) => {
                let galleryimg = [];
                // Adding Cover Photo into slider 
                // if (el?.asset_coverphoto) {
                //   galleryimg.unshift(
                //     `${process.env.REACT_APP_IMAGE_URL}${el.asset_coverphoto?.path}`
                //   );
                // }

                 // Adding Cover Photo into slider 
                el?.asset_gallery.forEach((val, ind) => {
                  if (val.title === "gellary" && val.image_order === 1) {
                    galleryimg.push(
                      `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`
                    );
                  }
                });

                // Inserting gellery Images Into Sliders 
                el?.asset_gallery.map((val, ind) => {
                  if (val.title === "gellary" &&  ind>0 ) {
                    galleryimg.push(
                      `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`
                    );
                  }
                });

                return (
                  <Grid key={index} item xs={12} sm={6} md={3} lg={3}>
                    <Cards
                      title={el?.asset_title}
                      subTitle={el?.asset_address}
                      investType={el?.investment_type?.investment_name}
                      isLoan={el?.assets_category?.title}
                      address = {el?.owner_information.owner_address}
                      isDraft={el?.isDraft}
                      isClock={true}
                      investment_expiry_time={el?.investment_expiry_time}
                      server_date={serverDate}
                      server_time={serverTime}
                      isPending={
                        el?.status === "Pending" || el?.status === "inreview"
                          ? true
                          : false
                      }
                      isDelete={el?.status === "Rejected" ? true : false}
                      isSaved={false}
                      imgSrc={galleryimg}
                      isSaveDeal={true}
                      link={`/project-details/${el?.id}`}
                      ownerName={el?.user_details?.first_name}
                      ownerImage={el?.user_details?.persona_picture}
                      bookMarkStatus={bookmarkVal(listingData[index]?.id)}
                    />
                  </Grid>
                );
              })
            }
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

NewestListings.prototype = {
  categoryId: PropTypes.number.isRequired,
  type: PropTypes.string,
};
