import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";  // Ensure you have react-router-dom installed

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: '#0e0e0e',
                textAlign: 'center',
                p: 3,
                color: '#333',
            }}
        >
            <Typography variant="h1" sx={{ mb: 2, fontSize: '6rem', fontWeight: 'bold' }}>
                404
            </Typography>
            <Typography variant="h5" sx={{ mb: 3 }}>
                Oops! The page you’re looking for doesn’t exist.
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/')}
                sx={{
                    px: 3,
                    py: 1.5,
                    fontSize: '1rem',
                    borderRadius: '8px',
                }}
            >
                Go to Homepage
            </Button>
            {/* <Box sx={{ mt: 3 }}>
                <Typography variant="body2">
                    If you think this is a mistake, <a href="mailto:support@example.com" style={{ color: '#1976d2' }}>contact support</a>.
                </Typography>
            </Box> */}
        </Box>
    );
};

export default NotFound;
