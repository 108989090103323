import { Box, CardContent, CardMedia, IconButton, TextField, Card, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import { darkBg } from '../../../../Components/Images/Images'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import NumericFormatCustomDollar from '../../../../Components/Common/NumericFormatCustomDollar';

export default function ParcelEditCard(props) {
  const { propData, title, artImage, setParcelData, setParcelImage, setParcelImageView, handleParcelDataError } = props;
  let currentImage = propData?.parcelsDetails[props?.parcelId - 1] ? propData?.parcelsDetails[props?.parcelId - 1] : null;
  const [selectedFiles, setSelectedFiles] = useState(currentImage?.image_view ? [currentImage?.image_view] : [])
  const [price, setPrice] = useState(currentImage?.data ? currentImage?.data : propData?.minimun_investment_per_parcel);

  // price increases, decreases by $5
  const handleChangePrice = (type) => {
    if (type === 'inc') {
      setPrice(prevCount => prevCount ? decimalFormatter(parseFloat(prevCount) + 5) : 5);
    } else {
      setPrice(prevCount => (parseFloat(prevCount) >= 5) ? decimalFormatter(parseFloat(prevCount) - 5) : prevCount);
    }
  }

  const onDrop = useCallback(acceptedFiles => {
    setSelectedFiles(acceptedFiles.map(file => URL.createObjectURL(file)))
  }, [setSelectedFiles])
  const { acceptedFiles, getRootProps, open, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/jpeg": [".jpeg", ".jpg", ".png"],
    },
    uploadMultiple: false,
    onDrop
  });

  useEffect(() => {
    if (props?.edit && props?.editPrice && propData?.selectedCategory !== "art") {
      setParcelData(price, props?.parcelId);
      setParcelImage(acceptedFiles[0] ? acceptedFiles[0] : currentImage ? currentImage?.image : "", props?.parcelId);
      setParcelImageView(selectedFiles[0] ? selectedFiles[0] : currentImage ? currentImage?.image_view : "", props?.parcelId);
    }
    else if (props?.edit && props?.editPrice && propData?.selectedCategory !== "art") {
      setParcelData(price, props?.parcelId);
      // setParcelImage(artImage ? artImage : "", props?.parcelId);
    }
    else if (props?.editPrice) {
      if (price && artImage) {
        setParcelData(price, props?.parcelId);
        // setParcelImageView(artImage ? artImage : "", props?.parcelId);
        // setParcelImage(artImage ? artImage : "", props?.parcelId);
      }
      else if (price == '' || price == 0) {
        handleParcelDataError({ message: 'Price is required for each parcel and each parcel price must be greater than 0.', status: true })
      }

    } else if (props?.edit) {
      setParcelImage(artImage ? artImage : "", props?.parcelId);
    }
  }, [price, acceptedFiles[0]])

  const decimalFormatter = (value) => {
    // Check if the value has more than two decimal places
    if (Number.isInteger(value)) {
      // If the value is an integer or has at most two decimal places, return it as is
      return value;
    } else {
      // If the value has more than two decimal places, apply toFixed(2)
      return value.toFixed(2);
    }
  }
  return (
    <Card className='rc-parcel-edit-card'>
      {props.edit && <Box {...getRootProps({ className: "dropzone", })}>
        <input {...getInputProps()} />
        <IconButton className='rc-asset-card-save-button' onClick={open}>
          <EditIcon />
        </IconButton>
      </Box>}
      <CardMedia component="img" id="parcelImage"
        src={(propData?.selectedCategory === "real-estate" || propData?.selectedCategory === "energy" || propData?.selectedCategory === "collections") ? (selectedFiles[0] ? selectedFiles[0] : darkBg) : (artImage ? artImage : darkBg)}
      />
      {propData?.artProjectDetails?.art_name && title ? <CardContent>
        <Typography color={'black'} variant="p" fontWeight={"500"}>{propData?.artProjectDetails?.art_name}</Typography>
      </CardContent>
        :
        <CardContent>
          <Typography color={'black'} variant="p" fontWeight={"500"}>{`Parcel #${props?.parcelId}`}</Typography>
        </CardContent>
      }

      {props.editPrice && <>
        <Box className='NS-sale-details' sx={{ padding: '0px 2px !important' }} >
          <Box className='NS-sale-details'>
            <Box className='NS-sale-details-icon'>
              <IconButton onClick={() => { handleChangePrice('dec') }} className="NS-sale-details-icon-btn  parcel-button">
                <RemoveIcon />
              </IconButton>
            </Box>
            <Box className='textfield'>
              <TextField
                id="outlined-number"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                value={(price ? price : 0)}
                size="small"
                onChange={(e) => setPrice(e.target.value)}
                // error={Boolean(parseFloat(price) < parseFloat(propData?.minimun_investment_per_parcel))}
                InputProps={{
                  inputComponent: NumericFormatCustomDollar,
                }}
                onKeyDown={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault();
                  }
                }}
              />
            </Box>
            <Box className='NS-sale-details-icon'>
              <IconButton onClick={() => { handleChangePrice('inc') }} className="NS-sale-details-icon-btn  parcel-button">
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </>
      }
    </Card>
  )
}
