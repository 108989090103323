import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Avatar, Modal, Stepper, Step, StepLabel } from "@mui/material";
import { Close, HowToVote, MonetizationOn } from "@mui/icons-material";
import { GetApiParam, postApi, updateApi } from "../../Api/Api";
import { NewLogo } from "../../Components/Images/Images";
import CompleteReview from "./Components/CompleteReview";
import CompletedReview from "./Components/CompletedReview";
import Voting from "./Components/Voting";
import AssetReviewDetails from "./Components/AssetReviewDetails";
import TransactionProgressModal from "../../Components/SmartContract/TransactionProgressModal";
import "./Modal.css"; // Import custom css

export default function ModalContent({
  setOpenReviewModal,
  setOpenVotingModal,
  setReviewSuggestionModal,
  asset_id,
  openReviewModal,
  openVotingModal,
  checkboxPayload,
  openReviewSuggestionModal,
  sendDataToCheckReview,
  forSuggestProjectDetails,
  forReviewProjectDetails,
  updateReviewData,
  isStateFromChanged,
  justShowSuggestions,
  setJustShowSuggestions
}) {
  // getting assetId from useParams
  const { assetId } = useParams();

  const navigate = useNavigate();
  const MaxLength = 500;

  // Initializing state
  const [assetData, setAssetData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  // const [activeModal, setActiveModal] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [voteSubmit, setVoteSubmit] = useState(false);
  const [voteStatus, setVoteStatus] = useState(false);
  const [showStep, setShowStep] = useState(false);
  const [assignId, setAssignId] = useState("");

  // state to handle add review progress modal open close
  const [openTransactionModalReview, setOpenTransactionModalReview] = useState(false);
  // state to handle vote progress modal open close
  const [openTransactionModalVote, setOpenTransactionModalVote] = useState(false);
  // dic vote type
  const [dicVoteType, setDicVoteType] = useState(true);

  // state to handle close button
  const [closeNavigate, setCloseNavigate] = useState(false);

  const [forSuggestProjectDetail, setForSuggestProjectDetail] = useState(
    forSuggestProjectDetails
  );
  const [modelContent, setModelContent] = useState(<></>);

  // Function to handle form submission
  const handleSaveClick = (formData) => {
    // handleShow(1);
    // Some data to send to the parent
    sendDataToCheckReview({
      Button: "Submit project details",
      data: formData,
    });
    setOpenModal(false);
    setShowStep(false);
    // setActiveModal(null);
    setOpenReviewModal(false);
    setOpenVotingModal(false);
    setReviewSuggestionModal(false);
    setForSuggestProjectDetail(false);
  };

  /**
   * handle model close
   */
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return; //do nothing basically do not close modal
    } else {
      setOpenModal(false);
      setShowStep(false);
      // setActiveModal(null);
      setOpenReviewModal(false);
      setOpenVotingModal(false);
      setReviewSuggestionModal(false);
      if (setJustShowSuggestions) {
        setJustShowSuggestions(false)
      }
      if (closeNavigate) {
        navigate("/dic/overview")
      }
    }
  };

  var localData = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : JSON.parse(localStorage.getItem("user_data_dic"));

  /**
   * getting asset details
   */
  useEffect(() => {
    async function getDraftData() {
      //  member id exit then call get assign asset api with asset_id and committee_member_id
      if (
        forReviewProjectDetails &&
        localData.is_community_member === false
      ) {
        const payloadData = {
          asset_id: assetId ? assetId : asset_id,
        };
        try {
          const res = await postApi("/proposer/assetsDetails", payloadData);
          if (res.data.data) {
            setAssetData(res.data?.data[0] ? res.data?.data[0] : []);
          }
        } catch (error) {
          console.log(error);
        }
      } else if (
        localData?.id &&
        forReviewProjectDetails !== true &&
        localData.is_community_member === true
      ) {
        const payloadData = {
          committee_member_id: localData?.id,
          asset_id: assetId ? assetId : asset_id,
        };
        try {
          const res = await GetApiParam(
            "/committee/getAssignedAssets",
            payloadData
          );
          if (res.data?.data?.length && res.data.data[0]) {
            res.data.data[0].assetDetails.dicMemberData =
              res.data.data[0].dicMemberData;
            setAssetData(
              res.data?.data[0]?.assetDetails
                ? res.data?.data[0]?.assetDetails
                : []
            );
            setAssignId(res?.data?.data[0]?.id);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    getDraftData();
  }, [forReviewProjectDetails]);

  /**
   *
   * @param {number} modalIndex
   */
  const handleShow = (modalIndex) => {
    setOpenModal(true);
    // setActiveModal(modalIndex);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (value.length <= MaxLength) {
      setInputValue(value);
    }
  };

  const setInputChange = () => {
    setInputValue("");
  };

  /**
   * handle vote submit by member
   */
  const voteApproveSubmit = () => {
    setOpenTransactionModalVote(true);
    setDicVoteType(true);
    return
  };

  const voteSubmitChange = async () => {
    if (!inputValue) {
      setVoteStatus(true);
    } else {
      setOpenTransactionModalVote(true)
      setDicVoteType(false);
      return
    }
  };

  const handleActiveStepChange = (newActiveStep) => {
    setActiveStep(newActiveStep);
  };

  /**
   * handle submit review
   */
  const submitReview = async () => {
    setOpenTransactionModalReview(true);
    return
  };
  // let votedData;

  if (assetData?.dicMemberData) {
    // const flatData = assetData?.dicMemberData?.flat();
    // const totalLength = flatData?.length;

    // const statusOneCount = flatData.filter((item) => item.status === 2).length;
    // votedData = `${statusOneCount}/${totalLength}`;
  }

  useEffect(() => {
    if (assetData) {
      if (openReviewSuggestionModal || forReviewProjectDetails) {
        setOpenModal(true);
        setModelContent(
          <AssetReviewDetails
            assetData={assetData}
            handleSaveClick={handleSaveClick}
            handleActiveStepChange={handleActiveStepChange}
            handleSuggestChanges={handleSuggestChanges}
            handleConfirmProject={handleConfirmProject}
            setForSuggestProjectDetail={setForSuggestProjectDetail}
            forSuggestProjectDetail={forSuggestProjectDetail}
            forReviewProjectDetails={forReviewProjectDetails}
            updateNewReviewData={updateReviewData}
            justShowSuggestions={justShowSuggestions}
          />
        );
      } else if (openReviewModal) {
        setOpenModal(true);
        setModelContent(<CompleteReview submitReview={submitReview} />);
      }
    }
  }, [
    openReviewModal,
    openReviewSuggestionModal,
    forSuggestProjectDetail,
    forReviewProjectDetails,
    assetData,
    updateReviewData,
    isStateFromChanged
  ]);

  useEffect(() => {
    if (openVotingModal) {
      setModelContent(
        <Voting
          assetData={assetData}
          voteApproveSubmit={voteApproveSubmit}
          voteSubmitChange={voteSubmitChange}
          voteStatus={voteStatus}
          handleInputChange={handleInputChange}
          setInputChange={setInputChange}
          inputValue={inputValue}
          voteSubmit={voteSubmit}
          updateReviewData={updateReviewData}
        />
      );
      setOpenModal(true);
    }
  }, [openVotingModal, voteSubmit, voteStatus, inputValue]);

  /**
   * Callback function to send data to the parent
   */

  // setting style for model
  const style = {
    position: "relative",
    boxShadow: 24,
    borderRadius: "24px",
    width: "100%",
    height: "600px",
    maxWidth: "1043px"
  };

  // Function to handle moving to the next step
  const handleNext = () => {
    // Check if the active step is 0 (first step)
    if (activeStep === 0) {
      // Call the onActiveStepChange function to move to the next step (step 1)
      handleActiveStepChange(1);
    }
  };

  // Function to handle moving back to the previous step
  const handleBackModal = () => {
    // Check if the active step is 1 (second step)
    if (activeStep === 1) {
      // Call the onActiveStepChange function to move back to the first step (step 0)
      handleActiveStepChange(0);
    }
  };

  // Custom function to render a voting icon
  function VoteIcon() {
    return (
      // Render a box containing the "HowToVote" icon with a specific class
      <Box className="voteIconBox">
        <HowToVote className="voteIcon" />
      </Box>
    );
  }

  // Custom function to render a dollar icon
  function DollarIcon() {
    return (
      // Render a box containing the "MonetizationOn" icon with a specific class
      <Box className={activeStep === 0 ? "voteIconBoxgray" : "voteIconBox"}>
        <MonetizationOn className="voteIcon" />
      </Box>
    );
  }

  /**
   * Onclick function for the Suggestion changes button
   */
  const handleSuggestChanges = (formData) => {
    sendDataToCheckReview({
      Button: "Suggest Changes",
      data: formData,
    });
    setForSuggestProjectDetail(true);
  };

  /**
   * Onclick function for the Confirm changes button
   */
  const handleConfirmProject = (formData) => {
    sendDataToCheckReview({
      Button: "Confirm Project",
      data: formData,
    });
    setOpenModal(false);
    setShowStep(false);
    // setActiveModal(null);
    setOpenReviewModal(false);
    setOpenVotingModal(false);
    setReviewSuggestionModal(false);
    setForSuggestProjectDetail(false);
  };

  /**
  * Function call when transaction successful for add review
  */
  const confirmStakeReview = async () => {
    try {
      checkboxPayload.status = 1;
      const res = await updateApi(
        `/committee/updateChecklist/${assignId}`,
        checkboxPayload
      );
      if (res?.data.code === 200) {
        handleShow(2);
        setCloseNavigate(true);
        setModelContent(<CompletedReview />);
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
* Function call when transaction successful for add review
*/
  const confirmStakeVote = async (voteStatus) => {
    if (voteStatus) {
      // approve 
      setVoteSubmit(true);
      setCloseNavigate(true);
    } else {
      // reject
      try {
        const payloadData = {
          status: 3,
          reject_reason: inputValue,
        };
        const res = await updateApi(
          `/committee/submitVote/${assignId}`,
          payloadData
        );
        if (res.data.code === 200) {
          setVoteSubmit(!voteSubmit);
          setCloseNavigate(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
    // if (voteStatus) {
    //   // approve 
    //   try {
    //     const payloadData = {
    //       status: 2,
    //     };
    //     const res = updateApi(`/committee/submitVote/${assignId}`, payloadData);
    //     if (res) {
    //       setVoteSubmit(true);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // } else {
    //   // reject
    //   try {
    //     const payloadData = {
    //       status: 3,
    //       reject_reason: inputValue,
    //     };
    //     const res = await updateApi(
    //       `/committee/submitVote/${assignId}`,
    //       payloadData
    //     );
    //     if (res.data.code === 200) {
    //       setVoteSubmit(!voteSubmit);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

  }

  /**
   * Function to handle contract progress modal status
   */
  const handleModalCloseReview = () => {
    setOpenTransactionModalReview(false)
  }
  /**
   * Function to handle contract progress modal status
   */
  const handleModalCloseVote = () => {
    setOpenTransactionModalVote(false)
  }

  return (
    <Box>
      <Modal
        open={openModal}
        onClose={handleClose}
        className="kyc-modal"
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box className="modal-body" sx={style}>
          <Box className="headContent">
            <Box className="logo">
              <Avatar
                alt="Logo"
                src={NewLogo}
                variant="square"
                sx={{ width: 39, height: 90 }}
              />
            </Box>
            {showStep ? (
              <Stepper
                className={
                  assetData?.investment_type_id === 2
                    ? "stepper"
                    : "stepper not-loan"
                }
              >
                <Step key={"CAST YOUR VOTE"} onClick={handleBackModal}>
                  <StepLabel StepIconComponent={VoteIcon}>
                    <Typography component="h2" className="stepLabelActive">
                      {" "}
                      {"CAST YOUR VOTE"}
                    </Typography>
                  </StepLabel>
                </Step>
                {assetData?.investment_type_id === 2 ? (
                  <Step key={"FUND THE PROJECT"} onClick={handleNext}>
                    <StepLabel StepIconComponent={DollarIcon}>
                      <Typography
                        component="h2"
                        className={
                          activeStep === 1 ? "stepLabelActive" : "stepLabel"
                        }
                      >
                        {"FUND THE PROJECT"}
                      </Typography>
                    </StepLabel>
                  </Step>
                ) : null}
              </Stepper>
            ) : null}
            <Close className="close-btn" onClick={handleClose} />
          </Box>
          {modelContent}
        </Box>
      </Modal>

      {/* stake race token progress modal add review*/}
      {openTransactionModalReview ? <TransactionProgressModal identity={'add-review'} confirmStake={confirmStakeReview} propData={assetData} handleModalClose={handleModalCloseReview} openTransactionModal={openTransactionModalReview} /> : null}
      {/* stake race token progress modal vote On Proposal*/}
      {openTransactionModalVote ? <TransactionProgressModal identity={'vote-on-proposal'} confirmStake={confirmStakeVote} propData={assetData} handleModalClose={handleModalCloseVote} openTransactionModal={openTransactionModalVote} dicVoteType={dicVoteType} /> : null}
    </Box>
  );
}
