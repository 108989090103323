import { createSlice } from '@reduxjs/toolkit'

export const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        totalUnreadCount: null,
        contactWiseUnreadCount: [],
        onlineUsersIds: [],
    },
    reducers: {
        updateMsgUnreadCount: (state, action) => {
            state.totalUnreadCount = action.payload?.total_unread;
            state.contactWiseUnreadCount = action.payload?.contacts_unread;
        },
        setOnlineUsersIds: (state, action) => {
            state.onlineUsersIds = action.payload;
        },
        decreaseTotalUnreadCount: (state, action) => {
            if (state.totalUnreadCount >= action.payload) state.totalUnreadCount = state.totalUnreadCount - action.payload;
        }

    }
});

export const { updateMsgUnreadCount, setOnlineUsersIds, decreaseTotalUnreadCount } = chatSlice.actions;


export default chatSlice.reducer;