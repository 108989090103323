import React from 'react';
import { Box, Stack, Typography, Grid, Avatar } from '@mui/material';
import './WhyRace.css'

const WhyRace = (props) => {
    return (
        <Box className="whyRace-wrap">
            <Grid container spacing={{ xs: 4, md: 6 }} alignItems={'center'}>
                <Grid item md={6} xs={12}>
                    <Box className='left'>
                        <Box className="section-heading" mb={3}>
                            <Typography className="title" variant="h3">{props?.title}{" "} <br /><Box className='text-golden' component={"span"}>{props?.highlightText}</Box>{"?"}</Typography>
                        </Box>
                        <Box className="whyRace-list">
                            <Stack className='item' direction="row" alignItems="center" spacing={1.5} mb={3}>
                                <Avatar variant='square' sx={{ width: '48px', height: '48px' }}>{props.firstIcon}</Avatar>
                                <Typography className='title'>
                                    {props.first}
                                </Typography>
                            </Stack>
                            <Stack className='item' direction="row" alignItems="center" spacing={1.5} mb={3} >
                                <Avatar variant='square' sx={{ width: '48px', height: '48px' }}>
                                    {props.secondIcon}
                                </Avatar>
                                <Typography className='title'>
                                    {props.second}
                                </Typography>
                            </Stack>
                            <Stack className='item' direction="row" alignItems="center" spacing={1.5} mb={3} >
                                <Avatar variant='square' sx={{ width: '48px', height: '48px' }}>
                                    {props.thirdIcon}
                                </Avatar>
                                <Typography className='title'>
                                    {props.third}
                                </Typography>
                            </Stack>
                            <Stack className='item' direction="row" alignItems="center" spacing={1.5} >
                                <Avatar variant='square' sx={{ width: '48px', height: '48px' }}>
                                    {props.fourthIcon}
                                </Avatar>
                                <Typography className='title'>
                                    {props.fourth}
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box className='right'>
                        <Avatar variant='square' alt="Image" src={props.image} sx={{ width: 'auto', height: 'auto', borderRadius: '16px' }}></Avatar>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
export default WhyRace;