import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Typography, Stack, Avatar, Grid } from "@mui/material";
import { NewLogo, Logo, ftrDiscord, ftrFacebook, ftrLinkedin, ftrTwitter, } from "../../Components/Images/Images";
import "./Footer.css";
import Popup from "../../ServiceProvider/Popup/Popup";
import { postApi } from "../../Api/Api";
import EmailForm from "./EmailForm";


const Footer = () => {
  const [serviceClick, setServiceClick] = useState(false)
  /**
     * For Email Functionality
     */

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [contactUsVal, setContactUsVal] = useState(false)

  const handleShow = () => setShowEmailModal(true);
  const handleCloseEmail = () => {
    setShowEmailModal(false);
    setContactUsVal(false)
  }

  const handleCancel = () => {
    setServiceClick(false);
    window.scrollTo(0, 0);
    // topFunction()
  };

  useEffect(() => {
    if (serviceClick === false || contactUsVal === false)
      document.documentElement.scrollTop = 0;
  }, [serviceClick, contactUsVal])


  // useEffect to scroll to the top of the page 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Function to scroll to the top of the page
  function topFunction() {
    document.documentElement.scrollTop = 0;
  }
  // Function to scroll to the top of the page
  function serviceProviderFunction() {
    setServiceClick(true)
  }

  const contactUs = () => {
    document.documentElement.scrollTop = 0;
    setContactUsVal(true)
    handleShow()
  }

  /**
 * Function for calling the email Api
 * @param {*} emailData 
 */
  const handleSend = async (emailData) => {

    try {
      const payload = {
        subject: emailData.subject,
        message: emailData.message,
        email: emailData.to,
        first_name: emailData.firstName,
        last_name: emailData.lastName
      };

      const res = await postApi("/user/contactFrom", payload)
      if (res?.data?.code === 200) {
        handleCloseEmail();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box className="main-footer" pt={{ xs: 4, sm: 8 }}>
      <Box className="container">
        <Box className="ftr-menu-section">
          <Grid container>
            <Grid xs={12} sm={4} item>
              <Box className="footer-colum">
                <Typography className="title">Invest & Lend</Typography>
                <Box className="ftr-menus">
                  <Box className="menu-item text-blueGray-500">
                    <Link onClick={topFunction} to="/asset-category/real-estate">Real Estate</Link>
                  </Box>
                  <Box className="menu-item text-blueGray-500">
                    <Link onClick={topFunction} to="/asset-category/art">Art</Link>
                  </Box>
                  <Box className="menu-item text-blueGray-500">
                    <Link onClick={topFunction} to="/asset-category/watch">Watch</Link>
                  </Box>
                  <Box className="menu-item text-blueGray-500">
                    <Link onClick={topFunction} to="/asset-category/gold">Gold</Link>
                  </Box>
                  <Box className="menu-item text-blueGray-500">
                    <Link onClick={topFunction} to="/asset-category/funds">Funds</Link>
                  </Box>
                  <Box className="menu-item text-blueGray-500">
                    <Link onClick={topFunction} to="/asset-category/car">Asset</Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} sm={4} item>
              <Box className="footer-colum">
                <Typography className="title">Learn</Typography>
                <Box className="ftr-menus">
                  <Box className="menu-item">
                    <Link onClick={topFunction} to="/faq">
                      FAQ
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link onClick={topFunction} to="/guides">
                      Guides
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link onClick={topFunction} to="/about-race">
                      About RACE
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link onClick={topFunction} to="/team">
                      Team
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} sm={4} item>
              <Box className="footer-colum">
                <Typography className="title">Other Features</Typography>
                <Box className="ftr-menus">
                  <Box className="menu-item">
                    <Link onClick={topFunction} to="/project-proposer">
                      Propose A Project
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link onClick={serviceProviderFunction}>
                      Provide A Service
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link
                      onClick={topFunction}
                      to="/investment-committee-dashboard"
                    >
                      Investment Committee Dashboard
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="ftr-termsPrivacy-section" mb={3}>
          <Grid container alignItems="center">
            <Grid xs={12} md={6} sm={8} item>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={{ xs: "center", sm: "flex-start" }}
                mt={1.5}
              >
                <Box className="ftr-logo" mr={2}>
                  <Link to="/" onClick={topFunction}>
                    <img src={NewLogo} alt="Logo" width={26} />
                  </Link>
                </Box>
                <Stack
                  className="ftr-termsPrivacy-menus"
                  direction="row"
                  alignItems="center"
                  spacing={4}
                  flexWrap="wrap"
                >
                  <Box className="menu-item">
                    <Link onClick={topFunction} to="/terms-and-conditions" target="_blank">
                      Terms
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link onClick={topFunction} to="/privacy-policy" target="_blank">
                      Privacy
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link onClick={topFunction} to="/feedback">
                      Feedback
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link onClick={() => contactUs()} >
                      Contact Us
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link onClick={topFunction} to="/cookies">
                      Cookies
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link to="/">
                      Version 1.1
                    </Link>
                  </Box>
                </Stack>
              </Stack>
            </Grid>
            <Grid xs={12} md={6} sm={4} item>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={{ xs: "center", sm: "flex-end" }}
                spacing={{ xs: 2, sm: 3 }}
                flexWrap="wrap"
                mt={1.5}
              >
                <Box className="footer-nav-logo">
                  <Link to="https://www.facebook.com/login/" target="_blank">
                    <Avatar
                      variant="square"
                      alt="Discord"
                      src={ftrFacebook}
                      sx={{ width: 24, height: 24 }}
                    />
                  </Link>
                </Box>
                <Box className="footer-nav-logo">
                  <Link to="https://twitter.com/i/flow/login" target="_blank">
                    <Avatar
                      variant="square"
                      alt="Discord"
                      src={ftrTwitter}
                      sx={{ width: 24, height: 24 }}
                    />
                  </Link>
                </Box>
                <Box className="footer-nav-logo">
                  <Link to="https://www.linkedin.com/login" target="_blank">
                    <Avatar
                      variant="square"
                      alt="Discord"
                      src={ftrLinkedin}
                      sx={{ width: 24, height: 24 }}
                    />
                  </Link>
                </Box>
                <Box className="footer-nav-logo">
                  <Link to="https://discord.com/login" target="_blank">
                    <Avatar
                      variant="square"
                      alt="Discord"
                      src={ftrDiscord}
                      sx={{ width: 24, height: 24 }}
                    />
                  </Link>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box>
        {serviceClick ? <Popup handleCancel={handleCancel} openModal={true} /> : null}
      </Box>
      <Box>
        {contactUsVal ?
          <EmailForm showModal={showEmailModal} handleClose={handleCloseEmail} onSend={handleSend} />
          : null
        }
      </Box>
    </Box>
  );
};

export default Footer;
