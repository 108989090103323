import React from 'react'
import ProfileDescription from '../Components/ProfileDescription/ProfileDescription'
import ServiceAccordian from '../Components/ServicesAccordian/ServiceAccordian'
import { Box, Pagination, Stack, Typography } from '@mui/material'
import PortfolioCard from '../Components/PortfolioCard/PortfolioCard'
import SkillChip from '../Components/Chips/SkillChip/SkillChip'


const skills = [
    {
        name: 'Email Marketing'
    },
    {
        name: 'Data Entry'
    },
    {
        name: 'Lead Generation'
    },
    {
        name: 'Google Suite'
    },
    {
        name: 'Development'
    },

]

function LeftCompanyProfileSection() {
    return (
        <>
            {/* Description paper */}
            <Box className="comapany-profile-skills main-div">
                <ProfileDescription />
            </Box>

            {/* Services tab company */}
            <Box className="comapany-profile-skills">
                <ServiceAccordian />
            </Box>

            {/* Skills Section Company */}
            <Box className="comapany-profile-skills">
                <SingleSection sectionTitle="Skills">
                    <Box>
                        <Box className="skill-section">
                            <Stack direction="row" spacing={2}>
                                {
                                    skills.map((item, index) => {
                                        return (
                                            <SkillChip key={index} label={item.name} />
                                        )
                                    })
                                }
                            </Stack>
                        </Box>
                    </Box>
                </SingleSection>
            </Box>
            {/* Portfolio section*/}
            <Box className="comapany-profile-skills">
                <SingleSection sectionTitle="Portfolio">
                    <Box >
                        <Stack direction="row" spacing={2}>
                            {
                                [1, 2, 3].map((item, index) => {
                                    return (
                                        <PortfolioCard key={index} />
                                    )
                                })
                            }
                        </Stack>
                    </Box>
                </SingleSection>
            </Box>
        </>
    )
}

export default LeftCompanyProfileSection

function SingleSection({ children, sectionTitle }) {
    return (
        <Box p={'24px'}>
            <Typography variant="subtitle1" className="font-18 font-readexpro" mb={3}>{sectionTitle}</Typography>
            {children}
        </Box>
    )
}