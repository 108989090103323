import { Collection_wines, Watches, CarAssetImages } from "../../Components/Images/Images";
import CategoryHeader from "../CategoryHeader";
import NewestListings from "../NewestListings";
import TypeSlider from "../TypeSlider";
import TopInvestments from "../TopInvestments";
import WhyUseRace from "../WhyUseRace";
import { Box } from "@mui/material";
import ReachInvestors from "../../Components/Common/ReachInvestors";
export default class AssetCategoryClass {

  categoryId = null;
  constructor(_categoryId) {
    this.categoryId = _categoryId
  } // Initialize categoryId to null}
  type = "Asset";

  pageComponenets = []; // push all relevant components here

  headerData = {
    title: "Build Your Own Portfolio of",
    highlight: " Tokenised Collectibles",
    subtitle: "Invest in Asset easily; in a new, secure, and transparent way.",
    image: CarAssetImages,
  };

  whyRaceData = {
    title: "Why use RACE to invest in",
    highlightText: "Asset",
    first: "Access Masterpieces",
    second: "Direct and Lower Fees",
    third: "Curated and Authenticated",
    fourth: "Quick and Seamless",
    image: CarAssetImages,
  };

  setPageComponents() {
    this.pageComponenets.push(<CategoryHeader categoryId={47} data={this.headerData} />);
    this.pageComponenets.push(<NewestListings categoryId={47} type="Car" />);
    this.pageComponenets.push(<TopInvestments categoryId={47} type="Car" />);
    this.pageComponenets.push(
      <TypeSlider
        sliderData={this.typeSliderData}
        sliderTitle={"Asset Types"}
        categoryId={47}
      />
    );
    this.pageComponenets.push(<WhyUseRace data={this.whyRaceData} />);
    this.pageComponenets.push(
      <>
        <Box className='container'>
          <Box className="reachInvestors-section artLanding">
            <ReachInvestors />
          </Box>
        </Box>
      </>
    );
  }
}

