import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NewLogo } from "../../../Components/Images/Images";
import { Close, Remove } from "@mui/icons-material";
import { Box, TextField, Button, Typography, IconButton, Avatar, Modal, Backdrop, RadioGroup, FormControlLabel, Radio, FormControl } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NumericFormatCustomDollar from "../../../Components/Common/NumericFormatCustomDollar";
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const style = {
    position: "relative",
    boxShadow: 24,
    borderRadius: "24px",
    width: "900px",
    height: '550px',
    // Media query for small devices
    '@media (max-width: 600px)': {
        width: '350px', // Set a different height for small screens
    },
    // Additional media queries for other screen sizes if needed
    '@media (min-width: 601px) and (max-width: 900px)': {
        // Custom styles for devices between 601px and 900px width
        width: '580px'
    },

    '@media (min-width: 901px) and (max-width: 1200px)': {
        // Custom styles for devices between 901px and 1200px width
        width: '750px'
    },

    // Specify the maximum width for large screens
    '@media (min-width: 1201px)': {
        maxWidth: "1043px",
        maxHeight: "687px",
    },

};

export default function StakeTokenServiceProvider(props) {

    // Initialize state for the total price of the asset
    const [totalRaceToken, setTotalRaceToken] = useState(0);
    const stakeAmt = parseFloat(props?.stakeAmount ? props?.stakeAmount : 0);
    const [errorMsg, setErrorMsg] = useState('');

    // state to hold payment currency type
    const [selectedOption, setSelectedOption] = useState('USDT');

    useEffect(() => {
        if (stakeAmt) {
            setTotalRaceToken(stakeAmt)
        }
    }, [stakeAmt]);
    /**
   * Handle change in payment type
   * @param {Event} event 
   */
    const handleOptionChange = (event) => { setSelectedOption(event.target.value); };

    // Function to change the 'RtTotalPrice' based on the provided 'type' (either 'inc' or 'dec')
    const handleChangeRaceToken = (type) => {
        if (type === "inc") {
            // Increase 'RtTotalPrice' by 10
            setTotalRaceToken((prevCount) => prevCount ? parseInt(prevCount) + 10 : 10);
        } else {
            // Decrease 'RtTotalPrice' by 10, but ensure it doesn't go below 0
            setTotalRaceToken((prevCount) =>
                parseInt(prevCount) >= 10 ? parseInt(prevCount) - 10 : prevCount
            );
        }
    };

    /**
     * Function to handle the confirmation of the total price.
     * Checks if 'totalRaceToken' is greater than or equal to 10, then calls 'userResponse' from props and clears any error message.
     */
    const handleConfirm = async () => {
        if (totalRaceToken == "" && typeof totalRaceToken == 'string') {
            setErrorMsg("Please enter tokens.");
            return
        } else if (stakeAmt > totalRaceToken) {
            setErrorMsg(`Please enter minimum ${stakeAmt} Tokens`, 'error');
            return
        } else {
            props?.handleStakeAmount(totalRaceToken)
        }
    };

    // useEffect to clear the error message when 'totalRaceToken' is greater than or equal to 10
    useEffect(() => {
        if (totalRaceToken >= 0 && errorMsg === 'Please enter tokens.') {
            setErrorMsg('');
        } else if (totalRaceToken >= stakeAmt && errorMsg?.includes('Please enter minimum')) {
            setErrorMsg('');
        }
    }, [totalRaceToken]);

    return (
        <>
            {props.openRTs ?
                <Modal
                    open={props.openRTs}
                    onClose={props.handleClose}
                    className="kyc-modal"
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    BackdropComponent={Backdrop}
                    BackdropProps={{ open: false }}
                >
                    <Box className="set-stack-rts" sx={style}>
                        <Box className="p-headContent">
                            {/* <Link to="/"> */}
                            <Box className="logo">
                                <Avatar
                                    alt="Logo"
                                    src={NewLogo}
                                    variant="square"
                                    sx={{ width: 39, height: 90 }}
                                />
                            </Box>
                            {/* </Link> */}
                            <Close className="close-btn" onClick={props.handleClose} />
                        </Box>
                        <Box className="p-headContent" mt={4}>
                            <Box className="modalContentDone " py={{ md: '2px', sm: '0px' }} px={{ md: 18, sm: 2 }}>
                                <Typography component="h1" className="headText">Stake RACE Tokens</Typography>
                                <Box>
                                    <Box className="overview-text-box">
                                        {/* <Typography component="p" className="sub-headText overview">Enter Amount Below</Typography > */}
                                        <Typography component="p" className="sub-headText overviewText">You need to stake a minimum of {stakeAmt} RACE tokens to participate to onboarding profile. Please ensure your wallet has sufficient RACE Tokens for Staking.<br /> Read more about staking <Link to='/dummy' target="_blank" className="link-review overview">here.</Link></Typography >
                                    </Box>
                                </Box>
                                <Box className="dic-NS-sale-details overview" mb={1} mt={-1}>
                                    <Box className="p-inc-dec-icon">
                                        <IconButton
                                            onClick={() => {
                                                handleChangeRaceToken("dec");
                                            }}
                                            className="p-inc-dec-icon-btn"
                                        >
                                            <Remove />
                                        </IconButton>
                                    </Box>
                                    <Box>
                                        <TextField
                                            id="outlined-number"
                                            type="text"
                                            className="loan-text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputComponent: NumericFormatCustomDollar,
                                            }}
                                            FormHelperTextProps={{ style: { color: 'black' } }}
                                            value={totalRaceToken}
                                            size="small"
                                            helperText={totalRaceToken < 2 ? "RACE Token" : "RACE Tokens"}
                                            onChange={(e) =>
                                                setTotalRaceToken(e.target.value)
                                            }
                                        />
                                    </Box>
                                    <Box className="p-inc-dec-icon">
                                        <IconButton
                                            onClick={() => {
                                                handleChangeRaceToken("inc");
                                            }}
                                            className="p-inc-dec-icon-btn"
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                                {errorMsg && <Typography sx={{ color: '#FF6865', fontSize: '11px', textAlign: 'center', mb: 2 }}>{errorMsg}</Typography>}

                                {/*  <Box className='investor-select-currency-type'>
                                    <Typography className='pay-using font-14'>
                                        Pay using
                                    </Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="payment-method"
                                            name="payment-method"
                                            defaultValue="USDT"
                                            row
                                            className='currency-options'
                                            value={selectedOption}
                                            onChange={handleOptionChange}
                                        >
                                          
                                            <FormControlLabel
                                                value="USDT"
                                                control={<Radio />}
                                                label="USDT"
                                            />
                                            <FormControlLabel
                                                value="USDC"
                                                control={<Radio />}
                                                label="USDC"
                                            />
                                            <FormControlLabel
                                                value="DAI"
                                                control={<Radio />}
                                                label="DAI"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Box> */}

                                <Box className='overview-btn-box' mt={1}>
                                    <Button className="wallet-button" sx={{ background: "#E2E8F0 !important" }}> <Box className="btn-icon" sx={{ color: "#053863 !important" }}><AttachMoneyIcon sx={{ color: "#000 !important" }} />Buy RACE Tokens</Box></Button>
                                    <Button className="wallet-button" sx={{ background: "#1771BD !important" }} onClick={handleConfirm}><Box className="btn-icon" ><DownloadDoneIcon />Confirm Stake</Box></Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal >
                : null
            }
        </>
    );
}
