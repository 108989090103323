import React, { useState, useEffect, useCallback, useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useLocation } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Cards from "../../Components/Cards/Cards";
import ExploreProjectInvestor from "../../Components/Common/ExploreProjectInvestor";
import AssetsSlider from "../../Components/Common/AssetsSlider";
import Filter from "../Home/Filter";
import { GetApiParam } from "../../Api/Api";
import { PROJECT_STATUS } from "../../constants";
import "../Home/Home.css";

const ListingPage = () => {
  // Importing necessary hooks and functions
  const location = useLocation();
  const [assetData, setAssetData] = useState([]);
  const [serverTime, setServerTime] = useState();
  const [serverDate, setServerDate] = useState();
  const [serverClockTime, setServerClock] = useState(null);
  const serverTimeInterval = useRef(null)
  // useEffect to fetch asset data and server time 
  useEffect(() => {
    // Api to get assetsData
    async function getAssetData() {
      try {
        const data = {
          status: PROJECT_STATUS.SUBMITTED,
        };
        const res = await GetApiParam("/proposer/getAssetsList", data);
        setAssetData(res.data?.data);
        setTimer(res?.data)
        setServerTime(res.data?.server_time ? res?.data?.server_time : null);
        setServerDate(res.data?.server_date ? res?.data?.server_date : null);
      } catch (error) {
        console.log(error);
      }
    }

    getAssetData();
  }, []);

  // useEffect to scroll to the top of the document
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  /**
   * set the data from the filter component
   */
  const FilteredData = useCallback(
    (data) => {
      setAssetData(data);
    },
    [assetData]
  );



  // functionality for Timer
  function setTimer(res) {
    // initialize server clock
    if (res?.data?.length > 0) {
      const serverClockTime = new Date(res?.server_date);
      var timestamp = res?.server_time.split(":");
      serverClockTime.setHours(timestamp[0]);
      serverClockTime.setMinutes(timestamp[1]);
      serverClockTime.setSeconds(timestamp[2]);
      setServerClock(serverClockTime);
    }
  }

  useEffect(() => {
    serverTimeInterval.current = setInterval(() => {
      if (serverClockTime) {
        serverClockTime.setSeconds(serverClockTime.getSeconds() + 1);
        setServerClock(serverClockTime);
        const event = new CustomEvent("clocktime", {
          detail: serverClockTime,
          bubbles: true,
        });
        document.dispatchEvent(event);
      }
    }, 1000);

  }, [serverClockTime])


  return (
    <>
      {/* <CollapseAlertBox /> */}
      {/* Explore Projects Sections */}
      <Box className="ep-sec-home" pt={8}>
        <Box className="container">
          <ExploreProjectInvestor
            title={
              location.pathname === "/investor-listing-invest"
                ? "Invest Now"
                : "Lend Now"
            }
            subTitle={
              location.pathname === "/investor-listing-invest"
                ? "Browse current investment opportunities on RACE."
                : "Browse current lending opportunities on RACE."
            }
            subTitle1={
              "All projects have been vetted by our Decentralized Investment Committee."
            }
          />
        </Box>
      </Box>

      {/* Category start */}
      <Box className="homeCategory-section" sx={{ pt: 8, mb: 10 }}>
        <Box className="container">
          <Box className="CategoryBox">
            <AssetsSlider />
          </Box>
        </Box>
      </Box>

      {/*All projects Explore start */}
      <Box className="HomeExplore-section" sx={{ mb: 6 }}>
        <Box className="container">
          <Box className="filter-panel">
            <Box className="fp-left">
              <Box className="heading-left">
                <Typography>All Projects</Typography>
                <Typography component={"h6"}>
                  Explore{" "}
                  <Box component={"span"} className="title-icon">
                    🔎
                  </Box>{" "}
                </Typography>
              </Box>
            </Box>

            <Box className="fp-right">
              <Box className="filters-group-wrap">
                <Filter FilteredData={FilteredData} />
              </Box>
            </Box>
          </Box>
          <Box className="HomeExplore-wrap">
            <Grid spacing={{ xs: 1, sm: 1, md: 2 }} container>
              {(() => {
                const topAssets = [];
                for (
                  let i = 0;
                  i < (assetData?.length >= 4 ? 16 : assetData.length);
                  i++
                ) {
                  const galleryimg = [];
                  // Adding Cover Photo into slider
                  if (assetData[i]?.asset_coverphoto) {
                    galleryimg.push(
                      `${process.env.REACT_APP_IMAGE_URL}${assetData[i].asset_coverphoto?.path}`
                    );
                  }

                  // Inserting gellery Images Into Slider
                  assetData[i]?.asset_gallery.map((val, ind) => {
                    if (val.title === "gellary") {
                      galleryimg.push(
                        `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`
                      );
                    }
                  });
                  topAssets.push(
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Cards
                        id={assetData[i]?.asset_data?.id}
                        title={assetData[i]?.asset_title}
                        subTitle={assetData[i]?.asset_address}
                        address={assetData[i]?.owner_information?.owner_address}
                        investType={
                          assetData[i]?.investment_type?.investment_name
                        }
                        isLoan={assetData[i]?.assets_category?.title}
                        isDraft={false}
                        isClock={true}
                        investment_expiry_time={assetData[i]?.investment_expiry_time}
                        isPending={
                          assetData[i]?.status === "Pending" ||
                            assetData[i]?.status === "inreview"
                            ? true
                            : false
                        }
                        isDelete={
                          assetData[i]?.status === "Rejected" ? true : false
                        }
                        isSaved={false}
                        imgSrc={galleryimg}
                        isSaveDeal={true}
                        server_date={serverDate}
                        server_time={serverTime}
                        link={`/project-details/${assetData[i]?.id}`}
                        ownerName={assetData[i]?.user_details?.first_name}
                        ownerImage={assetData[i]?.user_details?.persona_picture}
                      />
                    </Grid>
                  );
                }
                return topAssets;
              })()}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ListingPage;