import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  Modal,
  TextField,
  Grid,
  IconButton,
  Badge,
  Select,
  MenuItem,
  InputAdornment,
  Tooltip,
  InputLabel,
  FormControl,
  OutlinedInput
} from "@mui/material";
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from "@mui/icons-material/Save";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import "./EditSkillPopup.css";
import { GetApi, updateApi } from "../../../Api/Api";
import CommonBackdropLoader from "../../../Components/Common/CommonBackdropLoader";

const EditSkillPopup = ({ open, handleClose, profileData, getProfileData }) => {
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedServicesData, setSelectedServicesData] = useState([]);
  const [selectedCategorySkillData, setSelectedCategorySkillData] = useState([]);

  // input value for skill
  const [stepSkills, setStepSkills] = useState(null);
  // input value for main service
  const [mainService, setMainService] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);

  useEffect(() => {
    // Api to get categoryData
    async function getCategoryData() {
        try {
            const res = await GetApi("/services/spCategory");
            setCategories(res?.data?.data);
        } catch (error) {
            console.log(error);
        }
    }
    getCategoryData();
  }, [])

  const handleCategoryChange = async (event) => {
    const categoryId = event.target.value;
    setSelectedCategory(categoryId);
    setStepSkills([])
    setSelectedSkills([])
    const selectedCategoryObject = categories?.find(category => category.id === categoryId);
    setSelectedServicesData(selectedCategoryObject.serviceData)
};

  /**
       * FUnction to select Multiple Skills 
       * @param {*} skill 
       */
  const handleSkillSelection = (skill) => {
    const skillId = skill.id;
    const skillName = skill.skill_name;

    // Check if the skill is already selected
    if (!selectedSkills.some((selectedSkill) => selectedSkill.id === skillId)) {
        setSelectedSkills([...selectedSkills, { id: skillId, skill_name: skillName }]);
    }else{
        // If skill is already selected, remove it from the array
        setSelectedSkills(
            selectedSkills.filter((selectedSkill) => selectedSkill.id !== skillId)
        );
    }
    setStepSkills(null);
  };

  useEffect(() => {
    // Api to get categoryData
    async function getSkillData() {
        try {
          const res = await GetApi(`/services/spSkills?sp_category_id=${selectedCategory}&searchString=${stepSkills}`);
          if(res?.data && res?.data?.data?.length > 0){
            setSelectedCategorySkillData(res?.data?.data);
          }else{
            setSelectedCategorySkillData([]);
          }
        } catch (error) {
            console.log(error);
        }
    }
    getSkillData();
  }, [selectedCategory, stepSkills])

  useEffect(() => {
    if(profileData && profileData?.skillData && profileData?.skillData.length > 0){
      setSelectedSkills(profileData?.skills ? profileData?.skills : profileData?.skillData);
    }else{
      setSelectedSkills([]);
    }
    if(profileData && profileData?.main_service){
      setSelectedServices(profileData?.main_service?.split(',').map(i => ({id:0, service_name: i})));
    }else{
      setSelectedServices([]);
    }
    if(profileData && profileData?.sp_category_id){
      setSelectedCategory(profileData?.sp_category_id);
    }else{
      setSelectedCategory(null);
    }
  }, [profileData])

  const handleSubmit = (event) => {
    event.preventDefault();
    if(!selectedCategory){
      return;
    }
    if(selectedSkills && selectedSkills.length === 0){
      return;
    }
    if(selectedServices && selectedServices.length === 0){
      return;
    }
    setLoading(true);
    let updateData = new FormData();
    // Append Skill Ids as an array
    const skillIds = selectedSkills?.map((skill) => skill.id);
    updateData.append("sp_category_id",selectedCategory);
    updateData.append("skill_ids",JSON.stringify(skillIds));
    updateData.append("skills",JSON.stringify(selectedSkills));
    updateData.append("main_service",selectedServices.map(i => i?.service_name));
    updateApi(`/services/updateProfile/${profileData.id}`, updateData).then((res) => {
      if (res?.status === 200) {
        showSnackbar('Updated Successfully', 'success');
        getProfileData()
        handleClose()
      } else { // something went wrong user or backend side
        showSnackbar(res?.data?.message, 'error');
        handleClose()
      }
      setLoading(false);
    })
  };

  /**
     * Onchange function for the Service selection
     * @param {*} service 
     */

  const handleServiceSelection = (service) => {
    const serviceId = service.id;
    const serviceName = service.service_name;

    // Check if the skill is already selected
    if (!selectedServices.some((selectedService) => selectedService.service_name === serviceName)) {
        const tempArr = [...selectedServices, { id: serviceId, service_name: serviceName }];
        setSelectedServices(tempArr);
    } else {
        // If skill is already selected, remove it from the array
        const tempArr = selectedServices.filter((selectedService) => selectedService.id !== serviceId);
        setSelectedServices(tempArr);
    }
    setMainService('');
  };

  return (
    <Box className="add-education-popup-main">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-main"
      >
        <Box
          id="education-scroller-wrapper"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            borderRadius: "24px",
            width: "900px",
            padding: "52px",

            background: "var(--blueGray-900)",
            boxShadow:
              "0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.15)",
            // Media query for small devices
            "@media (max-width: 600px)": {
              width: "350px", // Set a different height for small screens
            },
            // Additional media queries for other screen sizes if needed
            "@media (min-width: 601px) and (max-width: 900px)": {
              // Custom styles for devices between 601px and 900px width
              width: "580px",
            },
            "@media (min-width: 901px) and (max-width: 1200px)": {
              // Custom styles for devices between 901px and 1200px width
              width: "750px",
              maxHeight: "300px",
              overflow: "auto",
            },
            // Specify the maximum width for large screens
            "@media (min-width: 1201px)": {
              maxWidth: "1043px",
              maxHeight: "525px",
              overflow: "auto",
            },
          }}
        >
          <CommonBackdropLoader loading={loading} />
          <Box className="close-icon-main">
            <IconButton className='close-btn' onClick={handleClose}>
                <CloseIcon className="cross-icon-modal"/>
            </IconButton>
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            mt={2}
            className="heading font-36"
            sx={{ textAlign: "center" }}
          >
            Edit Data
          </Typography>
            <Box className="proff-info-box " mt={3}>
              <Grid container spacing={2}>
                  <Grid item  xs={12} md={3}>
                      <Typography component="p" className='font-22'>Category</Typography>
                      <Typography component="p" className='font-16'>Current role category.</Typography>
                      <Typography component="p" className='font-12'>Your skill set will reflect according to the category selected.</Typography>
                  </Grid>
                  <Grid item  xs={12} md={9}>
                      <Box className="select-input-box">
                          <TextField
                              id="outlined-select-basic"
                              value={selectedCategory}
                              onChange={handleCategoryChange}
                              displayEmpty
                              select
                              label="Select Category"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              className="category-select"
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 200, // Limit height of the dropdown
                                      overflowY: 'auto',  // Enable scroll for overflowed content
                                    },
                                  },
                                }
                              }}
                          >
                              {categories?.map(category => (
                                  <MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>
                              ))}
                          </TextField>
                          {!selectedCategory  && <Typography variant="body2" color="error" mt={1}>Category is required.</Typography>}
                      </Box>
                  </Grid>
              </Grid>
          </Box>
          <Box className="proff-info-box search-lang" mt={3}>
              <Grid container spacing={2}>
                  <Grid item  xs={12} md={3}>
                      <Typography className='font-22 title'>
                          Skills
                      </Typography>
                      <Typography className='font-12 sub-title'>Your skills show clients what you can offer, and help us choose which jobs to recommend to you</Typography>
                  </Grid>
                  <Grid item  xs={12} md={9}>
                      <Box className="input-box">
                          <TextField
                              id="outlined-basic"
                              label="Select skills"
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              placeholder='E.g. Auditor needed for real estate project'
                              value={stepSkills}
                              // onChange={handleInputChange}
                              onChange={(e) => setStepSkills(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter' && e.target.value.length > 3) {
                                  handleSkillSelection({id: 0, skill_name: e.target.value});
                                }
                              }}
                              disabled={profileData?.sp_category_id ? false : true}
                          />
                      </Box>
                      {selectedSkills.length === 0  && <Typography variant="body2" color="error" mt={1}>Skills are required.</Typography>}
                      <Box className="button-div-bg-none skill-btn-badge" mt={2}>
                      {selectedSkills && selectedSkills.length > 0 &&
                        selectedSkills.map((skill, index) => (
                          <Badge 
                            badgeContent={<CloseIcon className="font-12"/>} 
                            color="error"
                            overlap="rectangular"
                            onClick={()=> {
                              let tempArray = [...selectedSkills];
                              tempArray = tempArray.filter((_, idx) => index !== idx);
                              setSelectedSkills(tempArray);
                            }}
                          >
                            <Button
                              key={skill.id}
                              className={"skill-button"}
                              variant="outlined"
                              startIcon={<AddIcon />}
                              onClick={() => handleSkillSelection(skill)}
                            >
                              {skill.skill_name}
                            </Button>
                          </Badge>
                      ))}
                      {selectedSkills && selectedSkills.length > 0 && <br />}
                      {selectedCategorySkillData &&
                        selectedCategorySkillData.filter(item => !new Set(selectedSkills.map(itm => itm.skill_name)).has(item.skill_name)).map((skill) => (
                          <Button
                            key={skill.id}
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={() => handleSkillSelection(skill)}
                          >
                            {skill.skill_name}
                          </Button>
                      ))}
                      </Box>
                  </Grid>
              </Grid>
          </Box>

          <Box className="proff-info-box" mt={3}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3} sm={12}>
                    <Typography className='font-22 title'>
                        Main Services
                    </Typography>
                    <Typography className='font-12 sub-title'>You can choose up to 10 work categories—your core services. We suggest picking the strongest, most specialized, and/or most in-demand. While the categories you select will impact how clients find your profile, you can submit proposals to job postings in any category.</Typography>
                </Grid>
                <Grid item xs={12} md={9} sm={12}>
                    {/* Input field for main services */}
                    <Box className="input-box">
                      <TextField
                          id="outlined-basic"
                          name="mainServices"
                          label="Select Service"
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          placeholder='E.g. Auditor needed for real estate project'
                          value={mainService}
                          onChange={(e) => setMainService(e.target.value)}
                          onKeyDown={(e) => {
                              if (e.key === 'Enter' && e.target.value.length > 3) {
                                  handleServiceSelection({ id: 0, service_name: e.target.value });
                              }
                          }}
                          InputProps={{
                              endAdornment: (
                                  <InputAdornment position="end">
                                      <Tooltip title={'After typing main service, please press enter'} arrow>
                                          <InfoOutlinedIcon style={{color: "#fff"}}/>
                                      </Tooltip>
                                  </InputAdornment>
                              ),
                          }}
                      />
                    </Box>
                    {selectedServices.length === 0  && <Typography variant="body2" color="error" mt={1}>Main service is required.</Typography>}
                    <Box className="button-div-bg-none skill-btn-badge" mt={2}>
                        {selectedServices && selectedServices.length > 0 &&
                            selectedServices.map((service, index) => (
                                <Badge
                                    badgeContent={<CloseIcon className="font-12" />}
                                    color="error"
                                    overlap="rectangular"
                                    onClick={() => {
                                        let tempArray = [...selectedServices];
                                        tempArray = tempArray.filter((_, idx) => index !== idx);
                                        setSelectedServices(tempArray);
                                    }}
                                >
                                    <Button
                                        key={service.id}
                                        className={"skill-button"}
                                        variant="outlined"
                                        startIcon={<AddIcon />}
                                        onClick={() => handleServiceSelection(service)}
                                    >
                                        {service.service_name}
                                    </Button>
                                </Badge>
                            ))}
                    </Box>
                    <Box className="button-div-bg-none">
                        {selectedServices && selectedServices.length > 0 && <br />}
                        {selectedServicesData &&
                            selectedServicesData?.filter(item => !new Set(selectedServices.map(itm => itm.service_name)).has(item.service_name)).map((service) => (
                                <Button
                                    key={service.id}
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    onClick={() => handleServiceSelection(service)}
                                >
                                    {service.service_name}
                                </Button>
                            ))}
                    </Box>
                </Grid>
            </Grid>
          </Box>

          <Box mt={3} className="btn-container">
            <Stack spacing={2} direction="row" justifyContent="center">
              <Button
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
                sx={{
                    background: "var(--golden-gradient)",
                    color: "var(--blueGray-900)",
                }}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default EditSkillPopup;
