import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { SwapVert, ViewComfy, Search } from '@mui/icons-material';
import { Box, Paper, InputBase, TableRow, TableCell, TableHead, TableContainer, Table, TableBody, Typography, MenuItem, ListItemText, ImageListItem, FormControl, InputLabel, Select } from '@mui/material';
import { GetApi, postApi } from '../../Api/Api';
import { formatNumber } from '../../Components/Common/USFormat';
import './Transactions.css'

const sortByOptionsOffers = ['Project Name', 'Amount'];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#05131B',
        color: '#94A3B8',
        borderBottom: '0px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: '0px'
    },
}));

const StyledTableRow = styled(TableRow)(({ }) => ({
}));

/**
 * due status for the due date i.e 
 * ```
 * if the due date is in future then the due status will be future
 * if the due date is near then the due status will be near
 * if the due date is passed then the due status will be past
 * if the payment is done then the due status will be paid
 * ```
    @enum {string}
 */
// const DUE_STATUS = {
//     FUTURE: 'FUTURE',
//     NEAR: 'NEAR',
//     PAST: 'PAST',
//     PAID: 'PAID'
// }

const LoanFunded = ({ setLoading }) => {
    const [loansData, setLoansData] = useState([]);
    const [calculateEmiAmount, setCalculateEmiAmount] = useState('');
    const [loanTableData, setLoanTableData] = useState([])
    const [searchString, setSearchString] = useState('');
    const [sortBy, setSortBy] = useState('Default');
    const [categoryData, setCategoryData] = useState(); //category data
    const [filterCategoryId, setFilterCategoryId] = useState(0);

    let u = localStorage.getItem('user_data');
    let User = JSON.parse(u);

    useEffect(() => {
        /**
        * Api to get Category Type Data
        */
        const getCategoryData = async () => {
            try {
                const res = await GetApi("/proposer/getCategory/");
                setCategoryData(res?.data?.data);
            } catch (error) {
                console.log(error);
            }
        }
        getCategoryData();

        /**
        * Api to get current EMI data
        */
        const getCalculateEmiLoanTable = async () => {
            try {
                setLoading(true)
                const res = await postApi("/proposer/loanFundedData", { investor_id: User?.id });
                if (res?.data?.data) {
                    setCalculateEmiAmount(res?.data?.data);
                }
                setLoading(false)
            } catch (error) {
                console.log(error);
                setLoading(false)
            }
        }
        if (User?.id) {
            getCalculateEmiLoanTable();
        }
    }, [])

    // set the table when emi data is there
    useEffect(() => {
        if (calculateEmiAmount && calculateEmiAmount.fundedData?.length > 0) {
            let table = [];

            calculateEmiAmount.fundedData.forEach((loan, index) => {
                // Create a single table row for each loan

                let tableRow = {
                    project_name: loan?.assetData?.asset_title || 'N/A',
                    asset_id: loan?.assetData?.asset_id || 0,
                    category_name: loan?.assetData?.category_title || 'N/A',
                    offer_amount: loan?.offer_amount || 0,
                    loan_roi: loan?.loan_roi || 0,
                    loan_duration: loan?.loan_duration || "",
                    full_payment: loan?.full_payment ? 'Yes' : 'No',
                    offer_accepted_time: loan?.offer_accepted_time ? (loan?.offer_accepted_time) : 'N/A',
                    investor_id: loan?.investor_id,
                    createdAt: loan?.createdAt ? new Date(loan?.updatedAt).toLocaleString() : "",
                    received_amount: calculateEmiAmount.receivedData?.[index]?.totalReceivedAmount,
                    interest_Received: calculateEmiAmount.receivedData?.[index]?.totalReceivedInterest
                };
                table.push(tableRow);
            });

            setLoanTableData(table); // Updates your state with the table data
            setLoansData(table);     // If you need to store it elsewhere as well
        } else {
            setLoanTableData([]);     // Empty table if no data
        }
    }, [calculateEmiAmount]);

    /**
     * searches in the data [title]
     * @param {string} toSearch 
     * @returns {void}
     */
    const searchInData = (toSearch) => {
        setSearchString(toSearch)
        // console.log(loansData[0]);
        if (toSearch.length > 2) {
            if (filterCategoryId > 0) {
                setLoanTableData(loansData.filter(o => o.project_name.toLowerCase().includes(toSearch.toLowerCase()) && o.category_id === filterCategoryId))
            } else {
                setLoanTableData(loansData.filter(o => o.project_name.toLowerCase().includes(toSearch.toLowerCase())))
            }
        } else if (toSearch.length <= 2) {
            if (filterCategoryId > 0) {
                // getLoans({ user_id: User.id, category_id: filterCategoryId });
                setLoanTableData(loansData.filter(o => o.category_id === filterCategoryId))
            } else {
                // getLoans({ user_id: User.id });
                setLoanTableData(loansData)
            }
        }
    }

    // Getting filter data on category id change
    useEffect(() => {
        if (filterCategoryId > 0) {
            // getLoans({ user_id: User.id, category_id: filterCategoryId });
            setLoanTableData(loansData.filter(o => o.category_id === filterCategoryId))
        } else {
            // getLoans({ user_id: User.id });
            setLoanTableData(loansData)
        }
    }, [filterCategoryId])

    /**
     * sorting data by amount and project name
     * @param {event} event 
     */
    const handleSortByChange = (event) => {
        setSortBy(event.target.value);
        let value = event.target.value;
        if (value === 'Project Name') {
            setLoanTableData([...loanTableData].sort((a, b) => a.project_name.localeCompare(b.project_name)))
        }
        else if (value === 'Amount') {
            setLoanTableData([...loanTableData].sort((a, b) => parseFloat(a.total_amount) - parseFloat(b.total_amount)))
        }
        else {
            setLoanTableData(loanTableData)
        }
    }

    /**
    * Handle change in category filter
    * @param {Event} event 
    */
    const handleChangeCategory = (event) => {
        setFilterCategoryId(event.target.value)
    };


    /**
     * @param {string} date  Date String
     * @returns  {string} Formatted Date String in DD/MM/YYYY format
     */
    const formatDate = (date) => {
        let d = new Date(date);
        let formattedDate = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
        return formattedDate;
    }

    return (
        <>
            <Box className="filter-panel" mt={4}>
                <Box className="fp-left">
                    <Box className="heading-left">
                        <Typography component={"h6"}>
                            Loan Funded
                        </Typography>
                    </Box>
                </Box>

                <Box className="fp-right">
                    <Box className="filters-group-wrap">
                        <Box className="filters-group-wrap">
                            {/* Search bar filter */}
                            <Box className="filterSearch-form">
                                <Box component={"span"} className="search-icon">
                                    <Search />
                                </Box>
                                <InputBase
                                    // ref={searchBarRef}
                                    className="filterSearch"
                                    placeholder={"Search in loans"}
                                    value={searchString}
                                    onChange={(e) => searchInData(e.target.value)}
                                    inputProps={{ "aria-label": "search in transactions" }}
                                />
                            </Box>
                        </Box>

                        {/* Category filter */}
                        <Box className="select-group" ml={1.5}>
                            <FormControl className="select-rounded" sx={{ minWidth: '150px' }} size="small">
                                <InputLabel id="demo-controlled-open-select-label">
                                    Category
                                    <ViewComfy />
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    onChange={handleChangeCategory}
                                    value={filterCategoryId}
                                    label="Category"
                                    variant='outlined'
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    {
                                        categoryData?.length && categoryData?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value.id}>
                                                    <ListItemText>{value.title === "Asset" ? "Luxury Cars" : value.title}</ListItemText>
                                                    <Typography className="menu-icon">
                                                        <ImageListItem>
                                                            <img
                                                                src={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                                                srcSet={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                                                alt={value.category_icon?.original_name}
                                                                loading="lazy"
                                                            />
                                                        </ImageListItem>
                                                    </Typography>
                                                </MenuItem>
                                            );
                                        })

                                    }
                                </Select>
                            </FormControl>
                        </Box>


                        {/* Sorting  */}
                        <Box className="select-group" ml={1.5}>
                            <FormControl className="select-rounded" sx={{ minWidth: '150px' }} size="small">
                                <InputLabel id="demo-controlled-open-select-label">
                                    Sort By
                                    <SwapVert />
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    value={sortBy}
                                    onChange={handleSortByChange}
                                    label="Sort By"
                                    variant='outlined'
                                >
                                    <MenuItem value={'Default'}>Default</MenuItem>
                                    {
                                        sortByOptionsOffers?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value}>
                                                    <ListItemText>{value}</ListItemText>
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>

                        </Box>
                    </Box>
                </Box>
            </Box>

            <TableContainer component={Paper} className='TableContainer'>
                <Table aria-label="customized table">
                    <TableHead className='TableHeader'>
                        <TableRow>
                            <StyledTableCell width={'22%'}>Project Name</StyledTableCell>
                            <StyledTableCell align="left">Offer Amount</StyledTableCell>
                            <StyledTableCell align="left">Loan Duration</StyledTableCell>
                            <StyledTableCell align="center"> Interest Rate</StyledTableCell>
                            <StyledTableCell align="center">Funding Date</StyledTableCell>
                            <StyledTableCell align="left"> Amount Received</StyledTableCell>
                            <StyledTableCell align="left"> Interest Received</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loanTableData?.map((row, i) => (
                            <StyledTableRow className='TableRow' key={`${row.name}${i}`}>
                                <StyledTableCell component="th" scope="row"><Link to={`/project-details/${row.asset_id}`} >{row?.project_name}</Link></StyledTableCell>
                                <StyledTableCell align="left" className='payment-type-color' >${formatNumber(parseFloat(row?.offer_amount))}</StyledTableCell>
                                <StyledTableCell align="left" className='payment-type-color' >{row?.loan_duration < 1 ? `${row?.loan_duration} Month` : `${row?.loan_duration} Months`}</StyledTableCell>
                                <StyledTableCell align="center" className='payment-type-color' >{`${row?.loan_roi}%`}</StyledTableCell>
                                <StyledTableCell align="center">{row?.offer_accepted_time !== 'N/A' ? formatDate(row?.offer_accepted_time) : row?.offer_accepted_time}</StyledTableCell>
                                <StyledTableCell align="left" className='payment-type-color' >${row?.received_amount ? formatNumber(parseFloat(row?.received_amount)) : `${0}`}</StyledTableCell>
                                <StyledTableCell align="left" className='payment-type-color' >${row?.interest_Received ? formatNumber(parseFloat(row?.interest_Received)) : `${0}`}</StyledTableCell>

                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default LoanFunded;
