import React, { useState } from 'react'
import '../ServicesSearch/ServicesSearch.css'
import { Box, IconButton, Stack, TextField, Typography, styled } from '@mui/material'
import { Search } from '@mui/icons-material';
import { useNavigate } from 'react-router';

const SearchTextField = styled(TextField)({
    width: '100%',
    height: '48px',
    '& .MuiOutlinedInput-root': {
        '& input': {
            padding: '12px 24px',
            color: 'white'
        },
        '& fieldset': {
            borderRadius: '48px',
        },
  
    },
});

const SearchButton = styled(IconButton)({
    "& .MuiIconButton-root": {
      padding: "10px",
      backgroundColor: "#398CD1",
      borderRadius: "48px",
      "& .MuiSvgIcon-root": {
        color: "#ffffff",
        height: "12px",
        width: "12px",
      },
    },

    // disable hover
    "&:hover": {
      backgroundColor: "#398CD1",
      "& .MuiSvgIcon-root": {
        color: "#ffffff",
      },
    },
});

const SearchCompo = ({ url , pageTitle, handleSearchResults }) => {
    const navigate = useNavigate();
    const [ inputText, setInputText ] = useState('');
    
    return (
        <Box className= {pageTitle === "Service Catalog" ? "service-search-bar Service Catalog " : "service-search-bar find-service-provider" } width={'100%'}>
            {/* <SearchTextField variant='outlined' placeholder="Search for services"
                onChange={(e) => setInputText(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <SearchButton sx={{ p: '10px' }} className='bg-golden' aria-label="search" onClick={() => handleSearchResults(inputText)} >
                            <Search sx={{ color: '#000', height: '12px', width: '12px' }} />
                        </SearchButton>
                    ),
                }}
            /> */}
            <SearchTextField variant='outlined' placeholder="Search Services" 
                onChange={(e) => {
                    setInputText(e.target.value);
                }}
                onKeyDown={(e) => {
                    if(e.key === 'Enter'){
                        navigate(`/service-proposer/client-category-results`, { state: { search: inputText }})
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <SearchButton className='search-button' sx={{ p: '10px', bgcolor: '#cfa119' }} aria-label="search" onClick={() => navigate(`/service-proposer/client-category-results`, { state: { search: inputText }})} >
                            <Search style={{ color: '#05131B' }} sx={{ color: '#05131B', height: '12px', width: '12px' }} />
                        </SearchButton>
                    ),
                }}
            />
        </Box>
    )
}

export default SearchCompo