import React from 'react'
import { Box, FormControl, Grid, InputLabel, ListItemText, Select, Typography } from "@mui/material";
import { MenuItem } from '@mui/material';
import Stack from '@mui/material/Stack';
import './ProjectFilter.css'
import { Assessment, Paid, SwapVert, ViewComfy  } from '@mui/icons-material';
const ProjectFilter = ({ title , totalServices, filter, setFilter}) => {
    const priceRange = [{
        title: '0-1000',
        id: '0-1000'
    },{
        title: '1000-10000',
        id: '1000-10000'
    },{
        title: '10000-100000',
        id: '10000-100000'
    },{
        title: 'Above 100000',
        id: '100000-0'
    }];

    const savedRequest = [{
        title: 'Saved Request',
        id: 'savedRequest'
    }];

    const sort = [{
        title: 'A to Z',
        id: 'ASC'
    },{
        title: 'Z to A',
        id: 'DESC'
    }];

    const handleChange = (value, field) => {
        let temp = { ...filter };
        if(field === 'priceRange'){
            //priceRangeStart, priceRangeEnd
            const priceRangeStart = value.split('-')[0];
            const priceRangeEnd = value.split('-')[1];
            setFilter({...temp, priceRangeStart, priceRangeEnd})
        }else{
            setFilter({...temp, [field]: value});
        }
    }
    return (
        <>
            <Box className='container'>
                <Grid container spacing={2}>
                    <Grid item md={2} xs={12}>
                        <Typography variant='h4' className='text-white'>{title}</Typography>
                        {totalServices !== undefined && <Typography  className='text-white font-14'>{totalServices ? `${totalServices} Services ` : "No Data Found"} </Typography>}
                    </Grid>
                    <Grid item md={10} xs={12}>
                        <Stack direction="row" flexWrap={'wrap'} gap={0.8} justifyContent={'end'}>
                            <Box className="select-group">
                                <FormControl className="filter-select-rounded" sx={{ minWidth: '130px' }} size="small">
                                    <InputLabel id="demo-controlled-open-select-label" style={{ color: '#fff' }}>
                                        Price Range
                                        <ViewComfy className='text-white' />
                                    </InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        label="Price Range"
                                        variant='outlined'
                                        onChange={(e) => handleChange(e.target.value, 'priceRange')}
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {priceRange?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value.id}>
                                                    <ListItemText>{value.title}</ListItemText>
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box className="select-group">
                                <FormControl className="filter-select-rounded" sx={{ minWidth: '120px' }} size="small">
                                    <InputLabel id="demo-controlled-open-select-label" style={{ color: '#fff' }}>
                                        Locations
                                        <Assessment className='text-white' />
                                    </InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        label="Locations"
                                        variant='outlined'
                                    >
                                        <MenuItem value={0}>All</MenuItem>
                                        {[]?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value.id}>
                                                    <ListItemText>{value.title}</ListItemText>
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box className="select-group">
                                <FormControl className="filter-select-rounded" sx={{ minWidth: '100px' }} size="small">
                                    <InputLabel id="demo-controlled-open-select-label" style={{ color: '#fff' }}>
                                        Ratings
                                        <Paid className='text-white' />
                                    </InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        label="Ratings"
                                        variant='outlined'
                                    >
                                        <MenuItem value={0}>All</MenuItem>
                                        {[]?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value.id}>
                                                    <ListItemText>{value.title}</ListItemText>
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box className="select-group">
                                <FormControl className="filter-select-rounded" sx={{ minWidth: '150px' }} size="small">
                                    <InputLabel id="demo-controlled-open-select-label" style={{ color: '#fff' }}>
                                    Sort By: Name
                                        <SwapVert className='text-white' />
                                    </InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        label="Sort By: Name"
                                        variant='outlined'
                                        onChange={(e) => handleChange(e.target.value, 'sort')}
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {sort?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value.id}>
                                                    <ListItemText>{value.title}</ListItemText>
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box className="select-group ">
                                <FormControl className="filter-select-rounded " sx={{ minWidth: '165px' }} size="small">
                                    <InputLabel id="demo-controlled-open-select-label" style={{ color: '#fff' }}>
                                        Saved Request
                                        <Assessment className='text-white' />
                                    </InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        label="Saved Request"
                                        variant='outlined'
                                        onChange={(e) => handleChange(e.target.value, 'savedRequest')}
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {savedRequest?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value.id}>
                                                    <ListItemText>{value.title}</ListItemText>
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}


export default ProjectFilter;
