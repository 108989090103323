// calculatePrepaymentAmount.js

import { readContract } from "@wagmi/core";

/**
 * Fetch user wallet balance
 * @param {Object} payEMIData asset details 
 * @returns emi amount for current date
 */
async function calculatePrepaymentAmount(contract_address, contract_abi, payEMIData) {
    try {
        if (contract_address) {
            const getPrepayment = await readContract({
                address: contract_address,
                abi: contract_abi,
                functionName: "calculatePrepaymentAmount",
                chainId: 6805,
                watch: true,
                args: [
                    payEMIData.listing_id
                ]
            });
            return getPrepayment
        }
    } catch (error) {
        console.error('Error fetching prepayment amount:', error);
        throw error; // propagate the error
    }
}

export { calculatePrepaymentAmount };
