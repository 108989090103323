import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getLoggedInUserType } from '../Services/localstorage.service';

const ProtectedRouteDIC = ({ children }) => {
  const location = useLocation();
  const path = location.pathname;

  const user = localStorage.getItem("user_data");
  const dic_user = localStorage.getItem("user_data_dic");
  const login = localStorage.getItem("login");
  const Diclogin = localStorage.getItem("Diclogin");

  // redirect is service provider/proposer login when provider/proposer logged in
  const loginUserType = getLoggedInUserType();
  // if(loginUserType === 'proposer')return <Navigate to="/service-proposer" replace />;
  // if(loginUserType === 'spv')return <Navigate to="/service-provider" replace />;

  if (path?.startsWith('/dic') && (!Diclogin)) {
    return <Navigate to="/" replace />;
  }
  else if (!path.startsWith('/dic')) {
    if (login && Diclogin && dic_user?.id == user?.id && dic_user?.first_name == user?.first_name) {
      return <Navigate to="/dic/overview" replace />;
    }
  }

  return children;
};

export default ProtectedRouteDIC;
