import React, { useEffect, useState } from "react";
import RoomServiceRoundedIcon from "@mui/icons-material/RoomServiceRounded";
import "./SideBar.css";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as Workspace } from "../../../Assets/Images/service-provider/work.svg";
import { ReactComponent as WorkspaceGradient } from "../../../Assets/Images/service-provider/work-hover.svg";
import { ReactComponent as MyProfile } from "../../../Assets/Images/service-provider/my-profile-icon.svg";
import { ReactComponent as MyProfileGradient } from "../../../Assets/Images/service-provider/my-profile-gradient.svg";
import { ReactComponent as MyJob } from "../../../Assets/Images/service-provider/my-job.svg";
import { ReactComponent as MyJobGradient } from "../../../Assets/Images/service-provider/my-job-gradient.svg";
import { ReactComponent as SavedRequest } from "../../../Assets/Images/service-provider/bookmarks.svg";
import { ReactComponent as SavedRequestGradient } from "../../../Assets/Images/service-provider/bookmarks-hover.svg";
import { ReactComponent as Wallet } from "../../../Assets/Images/service-provider/attach_money.svg";
import { ReactComponent as WalletGradient } from "../../../Assets/Images/service-provider/attach_money-hover.svg";
import { ReactComponent as MessageIcon } from "../../../Assets/Images/service-provider/message.svg";
import { ReactComponent as MessageGradient } from "../../../Assets/Images/service-provider/message-hover.svg";
import { ReactComponent as DocumentIcon } from "../../../Assets/Images/service-provider/assignment.svg";
import { ReactComponent as DocumentGradient } from "../../../Assets/Images/service-provider/assignment-hover.svg";
import { ReactComponent as SettingIcon } from "../../../Assets/Images/service-provider/settings.svg";
import { ReactComponent as SettingGradient } from "../../../Assets/Images/service-provider/settings-hover.svg";
import {
  Button,
  Box,
  styled,
  Drawer,
  IconButton,
  List,
  Badge,
  Divider,
  Tooltip,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import { removeServiceProposerFromLocalStorage, setServiceProviderInLocalStorage } from "../../../Services/localstorage.service";
const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  padding: "12px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  padding: "12px",
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
  [theme.breakpoints.up("xs")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiPaper-root": {
    marginTop: "64px",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      backgroundColor: "#032744",
      borderRadius: "0px 16px 16px 0px",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      backgroundColor: "#032744",
      borderRadius: "0px 16px 16px 0px",
    },
  }),
}));

const SideBar = ({ handleSidebarChange }) => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [open, setOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (/view-job/.test(location?.pathname)) {
      setActiveMenuItem("My Orders");
    } else {
      setActiveMenuItem(null);
    }
  }, [location]);

  const navigate = useNavigate();
  const lastSegment = window.location.href.split("/").pop();

  const { totalUnreadCount } = useSelector((state) => state.serviceChat);

  const handleDrawerOpen = () => {
    setOpen(true);
    handleSidebarChange(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    handleSidebarChange(false);
  };

  const handleProvideAService = () => {
    // removeServiceProposerFromLocalStorage();
    // setServiceProviderInLocalStorage();  
    // navigate("/service-provider");
  };

  return (
    <CustomDrawer className="left-sidebar" variant="permanent" open={open}>
      {open ? (
        <Box className="leftSidebar-toggle-wrap">
          <IconButton
            className="leftSidebar-toggle"
            onClick={handleDrawerClose}
          >
            <ChevronLeft />
          </IconButton>
        </Box>
      ) : (
        <Box className="leftSidebar-toggle-wrap">
          <IconButton className="leftSidebar-toggle" onClick={handleDrawerOpen}>
            <ChevronRight />
          </IconButton>
        </Box>
      )}
      <Box className="service-provider-sidebar-head ">
        {open ? (
          <>
            <Box className="provide-service-btn" mb={2}>
              <Button
                variant="contained"
                className="btn btn-rounded btn-golden btn-large"
                onClick={handleProvideAService}
              >
                <RoomServiceRoundedIcon />
                <span className="btn-text ">Provide a service</span>
              </Button>
            </Box>
            <Divider className="divider" />
          </>
        ) : (
          <>
            <Divider className="divider-closed" />
          </>
        )}
      </Box>
      <List className={open ? "" : "menu-close"}>
        <Box className="service-provider-sidebar-menu">
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink
              to="/service-proposer-dashboard/overview"
              exact
              className={`nav-link`}
            >
              <Tooltip
                title={open === false ? "Workspace" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <Workspace className="workspace-icon" />
                  <WorkspaceGradient className="workspace-gradient" />
                </Box>{" "}
              </Tooltip>
              {open && "Workspace"}
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink
              to="/service-proposer-dashboard/projectposting"
              exact
              className={`nav-link`}
            >
              <Tooltip
                title={open === false ? "My Project Postings" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <MyProfile className="workspace-icon" />
                  <MyProfileGradient className="workspace-gradient" />
                </Box>{" "}
              </Tooltip>
              {open && "My Project Postings"}
            </NavLink>
          </Box>

          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink
              to="/service-proposer-dashboard/myorder"
              exact
              className={`nav-link ${
                activeMenuItem === "My Orders" ? "active" : ""
              }`}
            >
              <Tooltip
                title={open === false ? "My Orders" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <MyJob className="workspace-icon" />
                  <MyJobGradient className="workspace-gradient" />
                </Box>
              </Tooltip>
              {open && "My Orders"}
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink
              to="/service-proposer-dashboard/saved-services"
              exact
              className={`nav-link`}
            >
              <Tooltip
                title={open === false ? "Saved Services" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <SavedRequest className="workspace-icon" />
                  <SavedRequestGradient className="workspace-gradient" />
                </Box>{" "}
              </Tooltip>
              {open && "Saved Services"}
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink
              to="/service-proposer-dashboard/wallet"
              exact
              className={`nav-link`}
            >
              <Tooltip
                title={open === false ? "Wallet" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <Wallet className="workspace-icon" />
                  <WalletGradient className="workspace-gradient" />
                </Box>{" "}
              </Tooltip>
              {open && "Wallet"}
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink
              to="/service-proposer-dashboard/chats"
              exact
              className={`nav-link`}
              style={{}}
            >
              <Tooltip
                title={open === false ? "Messages" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon" style={{}}>
                  {/* // forcefully 0 unread messages make badge  invisible when sidebar is expanded */}
                  <Badge
                    badgeContent={open ? 0 : totalUnreadCount}
                    color="error"
                  >
                    <MessageIcon className="workspace-icon" />
                    <MessageGradient className="workspace-gradient" />
                  </Badge>
                </Box>{" "}
              </Tooltip>
              {open && "Messages"}
              <Badge
                badgeContent={open ? totalUnreadCount : 0}
                color="error"
                sx={{
                  transition: "opacity 0.5s ease-in",
                  opacity: open ? 1 : 0,
                  float: "right",
                  mt: "3px",
                  right: "-84px",
                }}
              />
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink
              to="/service-proposer-dashboard/documents"
              exact
              className={`nav-link`}
            >
              <Tooltip
                title={open === false ? "Documents" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <DocumentIcon className="workspace-icon" />
                  <DocumentGradient className="workspace-gradient" />
                </Box>{" "}
              </Tooltip>
              {open && "Documents"}
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink
              to="/service-proposer-dashboard/settings"
              exact
              className={`nav-link`}
            >
              <Tooltip
                title={open === false ? "Settings" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <SettingIcon
                    className="workspace-icon"
                    style={{ marginRight: open ? "10px" : "0px" }}
                  />
                  <SettingGradient
                    className="workspace-gradient"
                    style={{ marginRight: open ? "10px" : "0px" }}
                  />
                </Box>{" "}
              </Tooltip>
              {open && "Settings"}
            </NavLink>
          </Box>
        </Box>
      </List>
    </CustomDrawer>
  );
};

export default SideBar;
