import React, { useEffect, useState, useCallback } from 'react'
import ProfileDescription from '../Components/ProfileDescription/ProfileDescription'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { useParams, useNavigate } from "react-router-dom";
import PortfolioCard from '../Components/PortfolioCard/PortfolioCard'
import SkillChip from '../Components/Chips/SkillChip/SkillChip'
import { IndividualProfilePersona } from '../../Components/Images/Images'
import WorkHistorySection from '../Components/WorkHistorySection/WorkHistorySection'
import { GetApi, GetApiParam } from '../../Api/Api'
import MainCard from '../Components/MainCard/MainCard'
import Pagination from '@mui/material/Pagination';
import ConfirmModal from "../../Components/Modal/Confirm/Confirm";
import { getUserDataFromLocalStorage } from '../../Services/localstorage.service';

function LeftPersonProfileSection({ profileData, offerId, projectId }) {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [userIndividualProfile, setUserIndividualProfile] = useState([])
    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 3; // Number of items per page

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        if (profileData)
            setUserIndividualProfile(profileData)
    }, [profileData])

    const handleAction = useCallback((action) => {
        if (action === 'confirm') {
            navigate(`/service-proposer-dashboard/postNewContract/${projectId ? projectId : 'project'}/${userIndividualProfile?.user_id}/${offerId ? offerId : 'offer'}`);
        }
    });
    return (
        <>
            <Box >
                {/* Description paper */}
                <Box className="comapany-profile-skills main-div">
                    <ProfileDescription
                        name={userIndividualProfile ? `${userIndividualProfile?.first_name ? userIndividualProfile?.first_name : null} ${userIndividualProfile?.last_name ? userIndividualProfile?.last_name : null}` : null}
                        address={userIndividualProfile?.address ? userIndividualProfile?.address : null}
                        title={userIndividualProfile && userIndividualProfile?.title}
                        description={userIndividualProfile && userIndividualProfile?.description}
                        persona={userIndividualProfile && userIndividualProfile?.profilePicture
                            ?.length > 0 ? userIndividualProfile?.profilePicture[0]?.path : IndividualProfilePersona}
                        coverPicture={userIndividualProfile && userIndividualProfile?.coverPicture?.length ? userIndividualProfile?.coverPicture[0]?.path : null}
                        hourlyrate={userIndividualProfile && userIndividualProfile?.hourly_rate}
                        user_id={userIndividualProfile && userIndividualProfile?.user_id}
                        from="serviceProviderProfileFromClient"
                        setOpenModal={setOpenModal}
                        availability={userIndividualProfile && userIndividualProfile?.availability}
                    />
                    <ConfirmModal title={'Hire'} description={'Are You Sure Want To Confirm It!'}  isOpen={openModal} setIsOpenModal={setOpenModal} handleAction={handleAction} />
                </Box>

                <Box className="comapany-profile-skills">
                    {/* Services tab company */}
                    <SingleSection sectionTitle="Services">
                        <ServicesSection userIndividualProfile={userIndividualProfile.user_id} />
                    </SingleSection>
                </Box>

                {/* Work History */}
                <Box className="comapany-profile-skills">
                    <SingleSection sectionTitle="Work History">
                        <WorkHistorySection userId={userIndividualProfile?.user_id}/>
                    </SingleSection>
                </Box>

                {/* Portfolio section*/}
                <Box className="comapany-profile-skills">
                    <Typography variant="subtitle1" className="font-18 font-readexpro" ml={"16px"} mb={3} pt={2}>
                        Portfolio
                    </Typography>
                    <Box mx={3}>
                        <Grid container spacing={1}>
                            {profileData?.portfolioData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((item, index) => (
                                <Grid item key={index} md={4} xs={12} >
                                    <PortfolioCard
                                        image={item.portfolioImages.path}
                                        title={item.portfolio_title}
                                        description={item.portfolio_description}
                                        link={item.portfolio_link}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <Box className="pagination-container">
                        <Pagination
                            count={Math.ceil(profileData?.portfolioData?.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            variant="outlined"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                        />
                    </Box>
                </Box>
                {/* Category Individual */}
                <Box className="comapany-profile-skills">
                <SingleSection sectionTitle="Category">
                        <Box >
                          <Stack direction="row" spacing={2} className="skill-list-chips">
                            <SkillChip key={0} label={profileData && profileData?.serviceCategory && profileData?.serviceCategory?.title} />
                          </Stack>
                        </Box>
                    </SingleSection>
                </Box>
                {/* Skills Section Individual */}
                <Box className="comapany-profile-skills">
                    <SingleSection sectionTitle="Skills">
                        <Box >
                            <Stack direction="row" spacing={2}>
                                {
                                    userIndividualProfile?.skills && userIndividualProfile?.skills.length ?
                                        userIndividualProfile?.skills?.map((item, index) => {
                                            return (
                                                <SkillChip key={index} label={item.skill_name} />
                                            )
                                        }):
                                    userIndividualProfile?.skillData?.length && userIndividualProfile?.skillData?.map((item, index) => {
                                        return (
                                            <SkillChip key={index} label={item.skill_name} />
                                        )
                                    })
                                }
                            </Stack>
                        </Box>

                    </SingleSection>
                </Box>
                {/* Skills Section Individual */}
                <Box className="comapany-profile-skills">
                    <SingleSection sectionTitle="Main services">
                        <Box >
                            <Stack direction="row" spacing={2}>
                                {userIndividualProfile && userIndividualProfile?.main_service && 
                                userIndividualProfile?.main_service?.split(',')?.map((item, index) => {
                                return <SkillChip key={index} label={item} />;
                                })}
                            </Stack>
                        </Box>

                    </SingleSection>
                </Box>
            </Box>
        </>
    )
}

export default LeftPersonProfileSection

function SingleSection({ children, sectionTitle }) {
    return (
        <Box p={'24px'}>
            <Typography variant="subtitle1" className="font-18 font-readexpro" ml={'16px'} mb={3}>{sectionTitle}</Typography>
            {children}
        </Box>
    )
}

function ServicesSection({ userIndividualProfile }) {
    const [serviceData, setServicesData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const userData = getUserDataFromLocalStorage();
    const itemsPerPage = 3; // Number of items per page

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        // Api to get categoryData
        async function getServicesData() {
            try {
                const res = await GetApiParam("/services/getServices", {
                    user_id: userIndividualProfile,
                    login_user_id: userData?.id
                });
                setServicesData(res?.data?.data?.length ? res.data.data : []);
            } catch (error) {
                console.log(error);
            }
        }
        getServicesData();
    }, [userIndividualProfile])

    return (
        <Box margin={'10px 0px 0px 16px'}>
            <Grid container spacing={2}>
                {
                    <Grid container spacing={1}>
                        {serviceData && serviceData.length > 0 ?
                            <>
                                {serviceData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((card, index) => (
                                    <Grid key={index} item md={4} sm={6} xs={12}>
                                        <MainCard {...card} bgColor='var(--blue-800)' />
                                    </Grid>
                                ))}
                            </> :
                            <Grid key={0} item md={12} sm={12} xs={12}>
                                <Typography className="font-14 font-readexpro" color={'#9CA3AF'}>Services Not Available</Typography>
                            </Grid>
                        }
                    </Grid>
                }
            </Grid>
            <Box className="pagination-container">
                <Pagination
                    count={Math.ceil(serviceData?.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    showFirstButton
                    showLastButton
                />
            </Box>
        </Box>
    )
}
