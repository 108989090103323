import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Avatar, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
// import {
//   fndFooterLogo,
// } from "./../Components/Images/Images";
const FooterInvestorOnly = () => {
  const navigate = useNavigate();


  function topFunction() {
    document.documentElement.scrollTop = 0;
  }
  return (
    <Box className="fnd-footer">
      <Box className="container">
        <Box className="fnd-footer-copyright">
          {/* <Box className="ftra-copyright-logo">
            <Avatar
              variant="square"
              src={fndFooterLogo}
              alt="Icon"
              sx={{ width: "100%", maxWidth: "230px", height: "auto" }}
            ></Avatar>
          </Box> */}
          <Box className="tac" my={1}>
            <Box component={'span'} className="menu-item">
              <Link
                onClick={topFunction}
                to="/terms-and-conditions"
              >
                Terms and Conditions
              </Link>
            </Box>
            <Box component={'span'} px={3}></Box>
            <Box component={'span'} className="menu-item">
              <Link
                onClick={topFunction}
                to="/privacy-policy"
              >
                Privacy Policy
              </Link>
            </Box>
            {/* <Box component={'span'} px={2}></Box>
            <Box component={'span'} className="menu-item">
              <Link
                onClick={topFunction}
                to="/blog"
              >
                News
              </Link>
            </Box> */}
          </Box>
          <Typography className="des">© 2024 RACE. All rights reserved.</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default FooterInvestorOnly;
