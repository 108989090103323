import React from 'react'
import { Box, Grid, Typography, Button, Avatar } from '@mui/material';
import './ReachInvestors.css';
import PhotoSizeSelectLargeOutlinedIcon from '@mui/icons-material/PhotoSizeSelectLargeOutlined';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import { Link } from 'react-router-dom';
import { PROJECT_STATUS } from '../../constants';

const ExploreFractionalization = (props) => {
    return (
        <Box className="explore-fractionalization-wrap">
            <Grid container spacing={6} alignItems={'center'}>
                <Grid item sm={12} md={6}>
                    <Box className="left-img">
                        <Avatar alt="'Explore fractionalization" variant='square' src={props.image} sx={{ width: 'auto', height: 'auto', borderRadius: '16px' }}></Avatar>
                    </Box>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Box className="right">
                        <Box className="section-heading" mb={4}>
                            <Typography className="title" variant="h3">{props.title}{" "}<Box component={'span'} className='text-golden'>{props?.highlightText}</Box>{" "}{props?.title1}</Typography>
                            <Typography className="subtitle">{props.subTitle}
                                {props.learnMoreLink ?
                                    <Link className='fw-500 text-underline' to={props.learnMoreLink}>Learn more</Link>
                                    : ''}
                            </Typography>
                        </Box>
                        <Box className='btn-wrap'>
                            {props?.exploreFractionalization &&
                                <Link to='/search' state={{ defaultFilter: { category_id: props?.categoryId, investment_type_id: props?.investment_type_id } }}>
                                    <Button
                                        className='btn-rounded btn-large btn-golden'
                                        startIcon={<WindowOutlinedIcon />}
                                        aria-haspopup="true" sx={{ mr: 1.5 }}>
                                        Explore Fractionalization
                                    </Button>
                                </Link>
                            }
                            {props?.exploreParceling &&
                                <Link to='/search' state={{ defaultFilter: { category_id: props?.categoryId, investment_type_id: props?.investment_type_id, status: PROJECT_STATUS.SUBMITTED } }}>
                                    <Button
                                        className='btn-rounded btn-large btn-blue-600 btn-text-white'
                                        startIcon={<PhotoSizeSelectLargeOutlinedIcon />}
                                        aria-haspopup="true">
                                        Explore Parceling
                                    </Button>
                                </Link>
                            }
                        </Box>
                        {props.extra ?
                            <Box mt={3}><Link to={props.extraLink} className='exploreExtra-link'>{props.extra}</Link></Box>
                            : ''}
                    </Box>
                </Grid>
            </Grid>
        </Box >
    )
}
export default ExploreFractionalization
