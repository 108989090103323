import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { NewLogo } from "../../../Components/Images/Images";
import { CheckBox, CheckBoxOutlineBlank, Close } from "@mui/icons-material";
import { Box, TextField, Button, Typography, Avatar, Modal, Backdrop, Autocomplete, Tooltip, Checkbox, Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import TransactionProgressModal from "../../../Components/SmartContract/TransactionProgressModal";
import { CONTRACT_NAME, SET_OF_RULES } from "../../../constants";
import CountryCodes from "../../../Data/countries.json";
import { getRulesData } from "../../../Components/SmartContract/getRulesData";
import NumericFormatCustom from "../../../Components/Common/NumericFormatCustom";
import "./SetStackRts.css";
import { GetContractAddress } from "../../../Components/Common/GetContractAddress";
import { postApi } from "../../../Api/Api";
import { useSnackbar } from "../../../Contexts/SnackbarContext";

const WhiteAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-clearIndicator": {
    color: "white",
  },
  "& .MuiAutocomplete-popupIndicator": {
    color: "white",
  },
  "& .MuiInputBase-input": {
    color: "white", // Change the input text color to white
  },
});
// Icon for the unchecked and checked checkboxes
const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

//country code file
const country_codes = CountryCodes;

const style = {
  position: "relative",
  boxShadow: 24,
  borderRadius: "24px",
  width: "900px",
  // minHeight: "550px",
  backgroundColor: "#053863",
  // Media query for small devices
  "@media (max-width: 600px)": {
    width: "350px", // Set a different height for small screens
  },
  // Additional media queries for other screen sizes if needed
  "@media (min-width: 601px) and (max-width: 900px)": {
    // Custom styles for devices between 601px and 900px width
    width: "580px",
  },

  "@media (min-width: 901px) and (max-width: 1200px)": {
    // Custom styles for devices between 901px and 1200px width
    width: "750px",
  },

  // Specify the maximum width for large screens
  "@media (min-width: 1201px)": {
    maxWidth: "1043px",
    maxHeight: "687px",
  },
};

export default function SetRulesModal(props) {

  const [restrictCountries, setRestrictCountries] = useState([]);
  const [unRestrictCountries, setUnRestrictCountries] = useState([]);
  const [filteredRestrictCountry, setFilteredRestrictCountry] = useState([]);
  const [transferFees, setTransferFees] = useState(0);
  const [transferAddress, setTransferAddress] = useState("")
  const [tokenLockPeriod, setTokenLockPeriod] = useState(0)
  const [enableKycOption, setEnabledKycOption] = useState("disabled")
  const [enableTransferApproval, setEnableTransferApproval] = useState("disabled")

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const { showSnackbar } = useSnackbar(); // Hook for displaying snackbar or notifications


  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      setRestrictCountries(filteredRestrictCountry); // Select all countries
    } else {
      setRestrictCountries([]); // Deselect all countries
    }
    setSelectAllChecked(isChecked); // Update select all checkbox state
  };

  const isAllSelected = restrictCountries.length === filteredRestrictCountry.length;

  const [checked, setChecked] = useState({
    restrictCountry: false,
    transferFees: false,
    supplyLimit: false,
    tokenLockPeriod: false,
    enableKycOption: false,
    enableTransferApproval: false
  });

  const [storeData, setStoreData] = useState(
    props?.storeData ? props?.storeData : ""
  );
  const [openTransactionModal, setOpenTransactionModal] = useState(false);

  /**
   * Function to send the rules Data to the backend before chain
   * @param {*} data 
   * @param {*} kycOption 
   */
  const handleSendRulesData = async (data, kycOption) => {
    try {
      const countryNames = data.length ? data?.map(item => item.country) : [];
      const payload = {
        restrict_country: countryNames.length ? countryNames : [],
        asset_id: props?.assetId,
        kyc_required: kycOption == "enabled" ? true : false
      }
      const res = await postApi("/proposer/isRestrictCountry", payload);
      if (res?.data?.code === 200 && res?.data?.message == "Create successfully") {
        setOpenTransactionModal(true);
      } else {
        showSnackbar("Error in fetching user data", "error");
      }
    } catch (error) {
      showSnackbar(error, "error");
    }
  }

  const handleSubmit = async () => {
    let contractAddress = null;
    let contractAbi = null;
    let transferFeesAddress = null;
    let restrictCountryAddress = null;

    const response = await GetContractAddress({}, props?.assetData?.assetDetails
      ?.contract_version);
    const contracts = response.reduce((acc, item) => {
      if (item.contract_name === CONTRACT_NAME.HELPER_MODULE) {
        acc.createProposal = item;
      } else if (item.contract_name === CONTRACT_NAME.SET_TRANSFER_FEES) {
        acc.transfer_fees = item;
      } else if (item.contract_name === CONTRACT_NAME.RESTRICT_COUNTRY) {
        acc.restrict = item;
      }
      return acc;
    }, {});
    if (contracts.createProposal) {
      contractAddress = contracts.createProposal.address;
      contractAbi = contracts.createProposal.abi;
    }
    if (contracts.transfer_fees) {
      transferFeesAddress = contracts.transfer_fees.address;
    }
    if (contracts.restrict) {
      restrictCountryAddress = contracts.restrict.address;
    }

    let data = await getRulesData(
      contractAddress,
      contractAbi,
      restrictCountries,
      transferAddress,
      transferFees,
    );

    if (data?.length) {
      let updateData = data?.map((item, index) => {
        let ans = item;
        switch (index) {
          case 0:
            ans = { ...item, compilance: restrictCountryAddress };
            break;
          case 1:
            ans = { ...item, compilance: transferFeesAddress };
            break;
          default:
            ans = { ...item, compilance: restrictCountryAddress };
            break
        }
        return ans;
      });
      let filterData = [];

      if (checked.restrictCountry) {
        filterData.push(updateData[0]);
      }
      if (checked.transferFees) {
        filterData.push(updateData[1]);
      }

      setStoreData({
        asset_id: props?.assetId,
        data: filterData,
        proposalId: props?.proposalId,
        assetData: props?.assetData?.assetDetails,
        tokenLockPeriodData: (tokenLockPeriod ? tokenLockPeriod * 86400 : 0),
        enableKycOptionData: enableKycOption === "enabled" ? true : false,
        enableTransferApprovalData: enableTransferApproval === "enabled" ? true : false
      });
      handleSendRulesData(restrictCountries, enableKycOption)
    }
  };

  const confirmStake = () => {
    props?.setIsSetRules();
    props?.handleCloseCountry();
  };

  const handleModalClose = () => {
    setOpenTransactionModal(false);
  };


  useEffect(() => {
    // const filteredData = country_codes.filter(
    //   (item) => restrictCountries.includes(item) === false
    // );
    // setFilteredUnRestrictCountry(filteredData);
  }, [restrictCountries]);

  useEffect(() => {
    const filteredData = country_codes.filter(
      (item) => unRestrictCountries.includes(item) === false
    );
    setFilteredRestrictCountry(filteredData);
  }, [unRestrictCountries]);

  return (
    <>
      {props.openAllowCountry ? (
        <Modal
          open={props.openAllowCountry}
          onClose={props.handleCloseCountry}
          className="kyc-modal"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          BackdropComponent={Backdrop}
          BackdropProps={{ open: false }}
        >
          <Box className="set-stack-rts" sx={style}>
            <Box className="p-headContent">
              <Box className="logo">
                <Avatar
                  alt="Logo"
                  src={NewLogo}
                  variant="square"
                  sx={{ width: 39, height: 90 }}
                />
              </Box>
              <Close className="close-btn" onClick={props.handleCloseCountry} />
            </Box>
            <Box className="allow-country-modal" mt={1} id="scroller-wrapper">
              <Box
                className="modalContentDone"
                py={{ md: "2px", sm: "0px" }}
                px={{ md: 10, sm: 2 }}
              >
                <Grid container spacing={2}>
                  <Grid item md="6">
                    <Box >
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Checkbox
                          style={{ marginTop: "14px", color: "#fff" }}
                          checked={checked.restrictCountry}
                          onChange={(event) =>
                            setChecked({
                              ...checked,
                              restrictCountry: event.target.checked,
                            })
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                        <Typography className="font-18" mt={2}>
                          Select Restrict Country
                        </Typography>
                      </Box>
                      {checked.restrictCountry ? (
                        <WhiteAutocomplete
                          multiple
                          id="country-input"
                          options={["Select All", ...filteredRestrictCountry]}
                          getOptionLabel={(option) => (option === "Select All" ? option : option.country)}
                          value={restrictCountries}
                          onChange={(e, newValue) => {
                            if (newValue.includes("Select All")) {
                              handleSelectAll(!isAllSelected);
                            } else {
                              setRestrictCountries(newValue);
                            }
                          }}
                          disableCloseOnSelect
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select countries"
                              variant="outlined"
                              inputProps={{
                                ...params.inputProps,
                                style: { color: "white" },
                              }}
                            />
                          )}
                          style={{ width: "100%", marginTop: "16px" }}
                          renderOption={(props, option, { selected }) => {
                            if (option === "Select All") {
                              return (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={isAllSelected || selectAllChecked}
                                    onClick={(e) => {
                                      handleSelectAll(!isAllSelected);
                                      e.stopPropagation();
                                    }}
                                  />
                                  Select All
                                </li>
                              );
                            }
                            return (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.country}
                              </li>
                            );
                          }}
                          renderTags={(value, getTagProps) => (
                            <div>
                              {value.length > 2 ? (
                                <Tooltip
                                  title={
                                    <Box>
                                      {restrictCountries.length > 0 && (
                                        <span>{restrictCountries.map((country) => country.country).join(", ")}</span>
                                      )}
                                    </Box>
                                  }
                                  arrow
                                  placement="bottom-start"
                                >
                                  <Button className="selected-country-btn">
                                    {`${value.length} Countries Selected`}
                                  </Button>
                                </Tooltip>
                              ) : (
                                value.map((option, index) => (
                                  <span key={index} {...getTagProps({ index })} style={{ color: "white" }}>
                                    {option.country}
                                    {index < value.length - 1 && ", "}
                                  </span>
                                ))
                              )}
                            </div>
                          )}
                        />
                      ) : null}
                    </Box>
                  </Grid>
                  {/* <Grid item md="6">
                    <Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Checkbox
                          style={{ marginTop: "14px", color: "#fff" }}
                          checked={checked.supplyLimit}
                          onChange={(event) =>
                            setChecked({
                              ...checked,
                              supplyLimit: event.target.checked,
                            })
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                        <Typography className="font-18" mt={2}>
                          Set Supply Limit
                        </Typography>
                      </Box>
                      {checked.supplyLimit ? (
                        <>
                          <TextField
                            id="country-input"
                            className="max-balance"
                            placeholder="Enter Supply Limit"
                            variant="outlined"
                            value={supplyLimit}
                            onChange={(e) => setSupplyLimit(e.target.value)}
                            style={{ width: "100%", marginTop: "16px" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                              style: { color: "white" },
                            }}
                            onKeyDown={(event) => {
                              if (
                                event?.key === "-" ||
                                event?.key === "+" ||
                                event?.key === "."
                              ) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <Typography
                            className="font-14"
                            mt={1}
                            style={{ color: "red" }}
                          >
                            {supplyLimit === ""
                              ? "Supply Limit is required"
                              : supplyLimit === 0
                                ? "Supply Limit can not be 0"
                                : ""}
                          </Typography>
                        </>
                      ) : null}
                    </Box>
                  </Grid> */}
                  <Grid item md="12">
                    <Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Checkbox
                          style={{ marginTop: "14px", color: "#fff" }}
                          checked={checked.transferFees}
                          onChange={(event) =>
                            setChecked({
                              ...checked,
                              transferFees: event.target.checked,
                            })
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                        <Typography className="font-18" mt={2}>
                          Set Transfer Fees(%)
                        </Typography>
                      </Box>
                      {checked.transferFees ? (
                        <>
                          <Grid container spacing={2}>
                            <Grid item md={6}>
                              <TextField
                                id="country-input"
                                className="max-balance"
                                placeholder="Enter Transfer Fee"
                                variant="outlined"
                                value={transferFees}
                                onChange={(e) => setTransferFees(e.target.value)}
                                style={{ width: "100%", marginTop: "16px" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                  style: { color: "white" },
                                }}
                                onKeyDown={(event) => {
                                  if (
                                    event?.key === "-" ||
                                    event?.key === "+" ||
                                    event?.key === "."
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              <Typography
                                className="font-14"
                                mt={1}
                                style={{ color: "red" }}
                              >
                                {transferFees === ""
                                  ? "Transfer fees is required"
                                  : parseFloat(transferFees) === 0
                                    ? "Transfer fees can not be 0"
                                    : ""}
                              </Typography>
                            </Grid>
                            <Grid item md={6}>
                              <TextField
                                id="country-input"
                                className="max-balance"
                                placeholder="Enter Collector address"
                                variant="outlined"
                                value={transferAddress}
                                onChange={(e) => setTransferAddress(e.target.value)}
                                style={{ width: "100%", marginTop: "16px" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  // inputComponent: NumericFormatCustom,
                                  style: { color: "white" },
                                }}
                                onKeyDown={(event) => {
                                  if (
                                    event?.key === "-" ||
                                    event?.key === "+" ||
                                    event?.key === "."
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              <Typography
                                className="font-14"
                                mt={1}
                                style={{ color: "red" }}
                              >
                                {transferAddress === ""
                                  ? "Collector Address is required"
                                  : parseFloat(transferAddress) === 0
                                    ? "Collector Address can not be 0" : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      ) : null}
                    </Box>
                  </Grid>

                  <Grid item md="12">
                    <Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Checkbox
                          style={{ marginTop: "14px", color: "#fff" }}
                          checked={checked.tokenLockPeriod}
                          onChange={(event) =>
                            setChecked({
                              ...checked,
                              tokenLockPeriod: event.target.checked,
                            })
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                        <Typography className="font-18" mt={2}>
                          Token Locked Period (Days)
                        </Typography>
                      </Box>
                      {checked.tokenLockPeriod ? (
                        <>
                          <Grid container spacing={2}>
                            <Grid item md={6}>
                              <TextField
                                id="country-input"
                                className="max-balance"
                                placeholder="Enter Token lock period"
                                variant="outlined"
                                value={tokenLockPeriod}
                                onChange={(e) => setTokenLockPeriod(e.target.value)}
                                style={{ width: "100%", marginTop: "16px" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  // inputComponent: NumericFormatCustom,
                                  style: { color: "white" },
                                }}
                                onKeyDown={(event) => {
                                  if (
                                    event?.key === "-" ||
                                    event?.key === "+" ||
                                    event?.key === "."
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              <Typography
                                className="font-14"
                                mt={1}
                                style={{ color: "red" }}
                              >
                                {tokenLockPeriod === ""
                                  ? "Token lock period is required"
                                  : tokenLockPeriod === 0
                                    ? "Token lock period can not be 0"
                                    : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      ) : null}
                    </Box>
                  </Grid>

                  <Grid item md={6}>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Checkbox
                        style={{ marginTop: "14px", color: "#fff" }}
                        checked={checked.enableKycOption}
                        onChange={(event) =>
                          setChecked({
                            ...checked,
                            enableKycOption: event.target.checked,
                          })
                        }
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      <Typography className="font-18" mt={2}>
                        Enable KYC
                      </Typography>
                    </Box>
                    {checked.enableKycOption ? (
                      <>
                        <FormControl variant="outlined" style={{ width: "100%", marginTop: "16px" }}>
                          <InputLabel id="kyc-dropdown-label" style={{ color: "white" }} shrink>
                            KYC
                          </InputLabel>
                          <Select
                            labelId="kyc-dropdown-label"
                            value={enableKycOption}
                            onChange={(e) => setEnabledKycOption(e.target.value)}
                            label="KYC"
                            style={{ color: "white" }}
                          >
                            <MenuItem value="enabled">Enabled</MenuItem>
                            <MenuItem value="disabled">Disabled</MenuItem>
                          </Select>
                        </FormControl>
                      </>
                    ) : null}
                  </Grid>

                  <Grid item md={6}>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Checkbox
                        style={{ marginTop: "14px", color: "#fff" }}
                        checked={checked.enableTransferApproval}
                        onChange={(event) =>
                          setChecked({
                            ...checked,
                            enableTransferApproval: event.target.checked,
                          })
                        }
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      <Typography className="font-18" mt={2}>
                        Enable Transfer Approval
                      </Typography>
                    </Box>
                    {checked.enableTransferApproval ? (
                      <>
                        <FormControl variant="outlined" style={{ width: "100%", marginTop: "16px" }}>
                          <InputLabel id="transfer-dropdown-label" style={{ color: "white" }} shrink>
                            Transfer Approval
                          </InputLabel>
                          <Select
                            labelId="transfer-dropdown-label"
                            value={enableTransferApproval}
                            onChange={(e) => setEnableTransferApproval(e.target.value)}
                            label="Transfer Approval"
                            style={{ color: "white" }}
                          >
                            <MenuItem value="enabled">Enabled</MenuItem>
                            <MenuItem value="disabled">Disabled</MenuItem>
                          </Select>
                        </FormControl>
                      </>
                    ) : null}
                  </Grid>

                </Grid >


                <Box style={{ width: "200px" }} mt={2}>
                  <Button
                    disabled={
                      ((checked.restrictCountry && (restrictCountries.length > 0)) ||
                        (checked.transferFees && transferFees > 0) ||
                        (checked.enableKycOption && enableKycOption) ||
                        (checked.tokenLockPeriod && tokenLockPeriod.length > 0))
                        ? false
                        : true
                    }
                    className="wallet-button"
                    sx={{ background: "#1771BD !important" }}
                    onClick={handleSubmit}
                  >
                    <Box className="btn-icon">Submit</Box>
                  </Button>
                </Box>
              </Box >
            </Box >
          </Box >
        </Modal >
      ) : null}

      {/* Transaction progress modal for dic suggestions(lead) */}
      {
        openTransactionModal ? (
          <TransactionProgressModal
            identity={"store-call-data"}
            confirmStake={confirmStake}
            propData={storeData}
            handleModalClose={handleModalClose}
            openTransactionModal={openTransactionModal}
          />
        ) : null
      }
    </>
  );
}
