import { Collection_wines, Watches } from "../../Components/Images/Images";
import CategoryHeader from "../CategoryHeader";
import NewestListings from "../NewestListings";
import TypeSlider from "../TypeSlider";
import TopInvestments from "../TopInvestments";
import WhyUseRace from "../WhyUseRace";
import { Box } from "@mui/material";
import ReachInvestors from "../../Components/Common/ReachInvestors";

export default class Watch {
  constructor() { }

  type = "Watch";

  pageComponenets = []; //push all relevant componenets here

  headerData = {
    title: "Build Your Own Portfolio of",
    highlight: " Tokenised Collectibles",
    subtitle: "Invest in Watch easily; in a new, secure, and transparent way.",
    image: Watches,
  };

  whyRaceData = {
    title: "Why use RACE to invest in",
    highlightText: "Watch",
    first: "Access Masterpieces",
    second: "Direct and Lower Fees",
    third: "Curated and Authenticated",
    fourth: "Quick and Seamless",
    image: Collection_wines,
  };

  setPageComponents() {
    this.pageComponenets.push(<CategoryHeader categoryId={8} data={this.headerData} />);
    this.pageComponenets.push(<NewestListings categoryId={8} type="Watch" />);
    this.pageComponenets.push(<TopInvestments categoryId={8} type="Watch" />);
    this.pageComponenets.push(
      <TypeSlider
        sliderData={this.typeSliderData}
        sliderTitle={"Watch Types"}
        categoryId={8}
      />
    );
    this.pageComponenets.push(<WhyUseRace data={this.whyRaceData} />);
    this.pageComponenets.push(
      <>
        <Box className='container'>
          <Box className="reachInvestors-section artLanding">
            <ReachInvestors />
          </Box>
        </Box>
      </>
    );
  }
}
