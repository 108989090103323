import { ethers } from "ethers";

/**
 * Function to convert a number to number * 10^18 using ethers.js
 * @param {Number} number 
 * @returns {String} Converted number in Wei (10^18)
 */
export default function convertFromDecimal(number) {
    // Convert the number from wei to ether (divide by 1e18)
    let etherValue = Number(number) / 1e18;

    // Check if there is a decimal part and format accordingly
    if (etherValue % 1 !== 0) {
        // If there's a decimal part, fix it to 2 decimal places
        etherValue = etherValue.toFixed(2);
    }

    return etherValue.toString();
}