import React, { useState, useEffect, useCallback } from "react";
import {
    Box,
    Typography,
    Button,
    IconButton,
    Modal,
    TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { updateApi } from "../../../Api/Api";
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import "./EditDescriptionPopup.css"

const EditDescriptionPopup = ({ onOpen, handleClose, profileData, getProfileData }) => {
     
    const { showSnackbar } = useSnackbar();
    const [formData, setFormData] = useState({
        description: profileData?.description || "",
    });
    const [textCount, setTextCount] = useState(["5000"]);
    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;

        let updatedValue = value;
        if (name === "description") {
            // Check if description length is within the limit
            if (value.length > 5000) {
                updatedValue = value.substring(0, 5000); // Trim the description to 5000 characters
            }
            setTextCount(5000 - updatedValue.length); // Update character count
        }
        setFormData({
            ...formData,
            [name]: updatedValue,
        });

        // Remove error when user starts typing
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    useEffect(() => {
        setErrors("");
    }, [handleClose]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = {};

        // For example, if description is required:
        if (formData.description !== undefined) {
            if (!formData.description.trim()) {
                errors.description = "Description is required";
            }
            if (formData?.description?.length < 500) {
                errors.description =
                    "Please enter a description of at least 500 characters.";
            }
        }
        // Set errors state with validation results
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            // Add user_id to formData

            let updateData = new FormData();
            updateData.append("description", formData?.description);
            updateApi(`/services/updateProfile/${profileData?.id}`, updateData).then((res) => {
                if (res?.status === 200) {
                    showSnackbar("Updated Successfully", 'success');
                    getProfileData();
                    handleClose();
                } else { // something went wrong user or backend side
                    showSnackbar(res?.data?.message, 'error');
                }
            })
            handleClose(); // Close the modal
        }
    };

    return (
        <Modal
            open={onOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-main"
        >
            <Box className="popup-main">
                <form onSubmit={handleSubmit}>
                    <Box>
                        <Box className="close-icon-main">
                            {/* <CloseIcon className="close-icon" onClick={handleClose} /> */}
                            <IconButton className='close-btn' onClick={handleClose}>
                                <CloseIcon className="cross-icon-modal"/>
                            </IconButton>
                        </Box>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            mt={2}
                            className="heading font-28"
                            sx={{ textAlign: "center" }}
                        >
                            Edit Description
                        </Typography>

                        <Typography className="font-18" mt={2}>
                            Description
                        </Typography>
                        <Box mt={2}>
                            <TextField
                                id="description"
                                name="description"
                                label="Description"
                                variant="outlined"
                                placeholder="Describe your studies, awards, etc."
                                fullWidth
                                multiline
                                rows={4}
                                value={formData?.description}
                                onChange={handleChange}
                                style={{ minHeight: "80px" }}
                                error={!!errors.description}
                                helperText={errors.description ? errors.description : ""}
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            {!errors.description ? (
                                <Typography
                                    paddingLeft={"32px"}
                                    paddingTop={"3px"}
                                    className="font-12"
                                >
                                    {" "}
                                    Enter at least 500 characters
                                </Typography>
                            ) : (
                                <Typography
                                    paddingLeft={"32px"}
                                    paddingTop={"3px"}
                                    className="font-12"
                                ></Typography>
                            )}
                            <Typography
                                paddingRight={"24px"}
                                paddingTop={"3px"}
                                className="font-12"
                            >
                                {" "}
                                {textCount} characters left
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: "center" }} mt={3}>
                            <Button
                                type="submit"
                                variant="contained"
                                startIcon={<SaveIcon />}
                                sx={{
                                    background: "var(--golden-gradient)",
                                    color: "var(--blueGray-900)",
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </form>

            </Box>
        </Modal>
    );
};

export default EditDescriptionPopup;
