import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'

export default function ParcelSelectedCard({ card, deselect }) {
    return (
        <Grid className='rc-selected-parcel-right' container alignItems={'center'} spacing={0} >
            <Grid item xs={6} sm={6} md={6}  >
                <Box component={'img'} sx={{ width: '100%', maxHeight: '112px', height: '112px' }} src={`${process.env.REACT_APP_IMAGE_URL}${card?.imagess.path}`} />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
                <Box sx={{ paddingLeft: "16px" }} >
                    <Typography variant='subtitle2' mt={'-8px'}>{card.title}</Typography>
                    <Typography mt={'6px'} mb={'8px'} variant='body2' gutterBottom className='parcel-price' >${card.price}</Typography>
                    <Button className='btn-rounded remove-btn' onClick={() => { deselect(card) }} variant='contained' >Remove</Button>
                </Box>
            </Grid>
        </Grid>
    )
}

ParcelSelectedCard.defaultProps = {
    card: {
        id: 1,
        name: 'Parcel 1',
        image: require('../../../Assets/Images/nft.png'),
        price: '0.1 ETH',
        selected: true,
        sold: false
    }
}
