import { Box } from "@mui/material";
import React from "react";
import ExploreFractionalization from "../Components/Common/ExploreFractionalization";

function ExploreFractionalizationCompo({ data, categoryId, investment_type_id }) {
  return (
    <Box className="container" py={8}>
      <ExploreFractionalization
        title={data.title}
        highlightText={data.highlightText}
        title1={data.title1}
        subTitle={data.subTitle}
        learnMoreLink={data.learnMoreLink}
        // extra={data.extra}
        extraLink={data.extraLink}
        image={data.image}
        exploreFractionalization={data.exploreFractionalization}
        exploreParceling={data.exploreParceling}
        categoryId={categoryId}
        investment_type_id={investment_type_id}
      />
    </Box>
  );
}

export default ExploreFractionalizationCompo;
