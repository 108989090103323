import React, { useState } from 'react'
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import Footer from '../Footer/FooterServiceProvider';
import Header from '../Header/HeaderServiceProvider';
import '../../ServiceProvider/ServiceProvider.css'

function LayoutServiceProvider() {
    return (
        <>
            <Box className={`service-layout`} >
                <Header />
                <Box className='main-page'>
                    <Outlet />
                </Box>
                <Footer />
            </Box>
        </>
    )
}

export default LayoutServiceProvider