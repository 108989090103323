import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import './ProfilePortfolioCard.css';
import { EditIconGradient } from '../../../Components/Images/Images';

const ProfilePortfolioCard = ({ image, title, profileClick }) => {

    const portfolioImages = image.portfolioImages || {};
    const { path } = portfolioImages;
    const avatarSrc = path ? `${process.env.REACT_APP_IMAGE_URL}${path}` : '';

    return (
        <Box className="pf-card-wrap">
            <Box className="pf-image-wrap">
                <Box className="edit-grey-icon" mr={2}>
                    <img src={EditIconGradient} alt={""} onClick={profileClick} />
                </Box>
                <Avatar className="pf-image" src={avatarSrc} alt={title} />
            </Box>
            <Box className="pf-title-wrap">
                <Typography variant="subtitle1" className="pf-title font-18" mb={1.5}>
                    {title ? title : 'Virtual Assistant To Help Pull Contact Information'}
                </Typography>
            </Box>
        </Box>
    );
};

export default ProfilePortfolioCard;


