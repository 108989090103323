import React, { useEffect, useState } from "react";
import { Avatar, Box, Modal, Button, Fade, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { NewLogo } from "../../Components/Images/Images";
import { GetApi } from "../../Api/Api";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { useAccount } from "wagmi";
import ConnectWalletInvestor from "../../Web3/ConnectWalletInvestor";
import { update } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import "./Individual.css";

const style = {
  position: "relative",
  boxShadow: 24,
  borderRadius: "24px",
  width: "1043px",
  height: "auto",
};

const step2style = {
  position: "relative",
  boxShadow: 24,
  borderRadius: "24px",
  width: "786px",
  height: "auto",
};

const eventsToshowCloseBtn = [
  "verification.accepted",
  "verification.declined",
  "verification.cancelled",
  "request.invalid",
  "request.timeout",
];

function KYCShufti({ kycOpen, onClose, handleKycComplete }) {
  // const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showCloseBtn, setShowCloseBtn] = useState(false);

  const { showSnackbar } = useSnackbar();

  // getting user wallet address
  const { address } = useAccount();
  const dispatch = useDispatch();

  // const handleOpen = () => {
  //     setOpen(true);
  // };

  const handleClose = (event, reason) => {
    if (reason === "clickaway" || reason === "backdropClick") {
      return;
    }
    onClose();
  };

  useEffect(() => {
    const handleEvent = (event) => {
      // if verification is accepted or declined then show the close button
      if (
        event.data &&
        eventsToshowCloseBtn.includes(event.data.verification_status)
      ) {
        if (
          event.data.verification_status === "verification.accepted" &&
          handleKycComplete
        ) {
          handleKycComplete(event.data);
        }
        setShowCloseBtn(true);
      } else {
        console.log("verification event", event.data);
      }
    };

    if (step === 2) {
      window.addEventListener("message", handleEvent);
    }

    return () => {
      window.removeEventListener("message", handleEvent);
    };
  }, [step]);

  const initKYC = async () => {
    if (loading) return; // if already loading then return
    setLoading(true); // set loading to true

    let u = localStorage.getItem("user_data");
    let User = JSON.parse(u);

    try {
      let response = await GetApi("/shuftipro/user_kyc/" + User.id);

      if (response.data.code === 200) {
        createIframe(response.data.data.verification_url);
        setStep(2);
      } else if (response.data.code === 201) {
        showSnackbar(response.data.message, "warning");
      } else {
        // console.log("error", response.data);
      }
      setLoading(false); // set loading to false
    } catch (error) {
      setLoading(false); // set loading to false
    }
  };

  /**
   *  Create iframe for kyc verification
   * @param {string} src  iframe src url the verification url from shuftipro
   * @returns
   */
  const createIframe = (src) => {
    const iframe = document.createElement("iframe");
    iframe.src = src;
    iframe.width = "730px";
    iframe.height = "526px";
    iframe.allowFullscreen = true;
    iframe.setAttribute("frameBorder", "0");
    let el = document.getElementById("kyc-iframe");
    console.log(el);
    el.appendChild(iframe);
  };

  //Wallet connection code start
  const [walletDialogOpen, setWalletDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setWalletDialogOpen(true);
  };

  const handleCloseDialog = (value) => {
    setWalletDialogOpen(false);
  };

  /**
   * gets called when a wallet is connect successfully
   * @param {*} acc
   */
  const walletLogin = async (acc) => {
    if (acc) {
      dispatch(update({ wallet_address: acc?.toLowerCase() }));
      initKYC();
    }
  };

  //Wallet connection code end

  return (
    <>
      <Modal
        open={kycOpen}
        onClose={handleClose}
        className="kyc-modal"
        disableEscapeKeyDown
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Fade in={kycOpen} timeout={300}>
          <Box
            className="modal-body kyc-modal"
            sx={step !== 2 ? style : step2style}
          >
            {step !== 2 && (
              <Box className="headContent">
                {/* <Link to="/"> */}
                <Box className="logo">
                  <Avatar
                    alt="Logo"
                    src={NewLogo}
                    variant="square"
                    sx={{
                      width: { xs: "39px", sm: "39px", md: "39px" },
                      height: { xs: "39px", sm: "90px", md: "90px" },
                      objectFit: 'contain'
                    }}
                  />
                </Box>
                {/* </Link> */}
                {step === 1 && (
                  <Close className="close-btn" onClick={handleClose} />
                )}
              </Box>
            )}

            {showCloseBtn && step === 2 && (
              <Box className="headContent">
                <Close
                  className="close-btn"
                  sx={{ marginLeft: "auto" }}
                  onClick={handleClose}
                />
              </Box>
            )}

            <Box className="modalContentDone" mt={step === 2 && 3}>
              <Box className="main-content" id="kyc-iframe">
                {step === 1 ? (
                  <>
                    <Typography component="h1" className="headText">
                      KYC{" "}
                    </Typography>
                    <Typography component="p" className="sub-headText">
                      To access the entire application, please complete your KYC
                      (Know Your Customer) process. Thank you!
                    </Typography>
                    <Button
                      onClick={address ? initKYC : handleClickOpen}
                      className="wallet-button"
                    >
                      Continue to KYC
                    </Button>
                  </>
                ) : null}
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <ConnectWalletInvestor
        open={walletDialogOpen}
        handleCloseDialog={handleCloseDialog}
        handleConnectedSuccess={walletLogin}
      />
    </>
  );
}

export default KYCShufti;

function KYCModal({ email, kycOpen, onClose }) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway" || reason === "backdropClick") {
      return;
    }
    onClose();
  };

  return (
    <Modal
      open={kycOpen}
      onClose={handleClose}
      className="kyc-modal"
      disableEscapeKeyDown
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box className="modal-body" sx={style}>
        <Box className="modalContentDone" mt={2}>
          <Box className="main-content" id="kyc-iframe">
            {/* //iframe will be here */}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
