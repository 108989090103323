import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const GridImageView = forwardRef(
  (
    {
      artImage,
      rows,
      cols,
      selectParcelFromCanvas,
      deselectParcelFromCanvas,
      soldParcels,
    },
    ref
  ) => {
    const [selectedParcelNos, setSelectedParcelNos] = useState([]);
    const sourceImage = useRef(null);
    const canvas = useRef(null);
    const imageOriginalWidth = useRef(0);
    const imageOriginalHeight = useRef(0);
    const cellWidth = useRef(0);
    const cellHeight = useRef(0);
    const containerSize = 500;
    const drawGrid = () => {
      if (canvas && sourceImage) {
        let _canvas = canvas.current;

        // source image
        let _sourceImage = sourceImage.current;

        // image dimension
        imageOriginalWidth.current = _sourceImage.naturalWidth;
        imageOriginalHeight.current = _sourceImage.naturalHeight;

        const ctx = _canvas.getContext("2d");

        _canvas.width = _sourceImage.width;
        _canvas.height = _sourceImage.height;
        cellWidth.current = _canvas.width / cols;
        cellHeight.current = _canvas.height / rows;

        const gridColor = "rgba(33, 56, 79, 1)";
        const lineWidth = 2;
        const translucentDarkOverlay = "rgba(0, 0, 0, 0.5)";
        ctx.fillStyle = translucentDarkOverlay;

        ctx.fillRect(0, 0, _canvas.width, _canvas.height);

        // Calculate grid cell dimensions

        // Draw vertical grid lines
        for (let i = 1; i < cols; i++) {
          const x = i * cellWidth.current;
          ctx.beginPath();
          ctx.moveTo(x, 0);
          ctx.lineTo(x, _canvas.height);
          ctx.strokeStyle = gridColor;
          ctx.lineWidth = lineWidth;
          ctx.stroke();
        }

        // Draw horizontal grid lines
        for (let i = 1; i < rows; i++) {
          const y = i * cellHeight.current;
          ctx.beginPath();
          ctx.moveTo(0, y);
          ctx.lineTo(_canvas.width, y);
          ctx.strokeStyle = gridColor;
          ctx.lineWidth = lineWidth;
          ctx.stroke();
        }
      }
    };

    /**
     *  selects the regions which are already selected when initail load of component should be called by parent
     * @param {*} selectedParcels array of selected parcels
     */
    const initialRegionSelection = (selectedParcels) => {
      let sP = [];
      selectedParcels.forEach((parcel) => {
        let parcelNo = parcel.title.split("#")[1];
        sP.push(parseInt(parcelNo));
        selectRegion(parcelNo);
      });
      setSelectedParcelNos(sP);
    };

    /**
     * deselects all the regions which are already selected
     * @param {*} selectedParcels array of selected parcels to be deselected
     */
    const deselectAllRegions = (selectedParcels) => {
      selectedParcels.forEach((parcel) => {
        let parcelNo = parcel.title.split("#")[1];
        deselectRegion(parcelNo);
      });
    };

    /**
     * Draw numbered icon with circle around it
     * @param {number} x - X coordinate of the icon
     * @param {number} y - Y coordinate of the icon
     * @param {number} size - Size of the icon
     * @param {number} number - Number to be displayed inside the icon
     * @param {CanvasRenderingContext2D} ctx - The canvas 2D rendering context
     */
    const drawIconWithCircleAndTick = (x, y, size, ctx) => {
      // Calculate top right corner coordinates
      const rightX = x + cellWidth.current - size - 8;
      const topY = y + 6;

      // Draw the circle around the numbered icon
      const circleRadius = size / 2.5; // Radius of the circle
      ctx.strokeStyle = "white"; // Set stroke color
      ctx.lineWidth = 1.8; // Set line width for the circle
      ctx.beginPath();
      ctx.arc(rightX + size / 2, topY + size / 2, circleRadius, 0, Math.PI * 2);
      ctx.stroke();

      // Draw the right tick icon
      ctx.lineWidth = 1.5; // Set line width for the tick icon
      ctx.lineCap = "round"; // Set line cap style to round
      ctx.lineJoin = "round"; // Set line join style to round
      ctx.strokeStyle = "white"; // Set stroke color for the tick icon
      ctx.beginPath();
      ctx.moveTo(rightX + size / 4, topY + size / 2);
      ctx.lineTo(rightX + size / 2, topY + size * 0.7);
      ctx.lineTo(rightX + size * 0.8, topY + size / 3);
      ctx.stroke();
    };

    /**
     * removes the default translucent from the area and feels like its selected
     * @param {*} parcelNo
     */
    const selectRegion = (parcelNo) => {
      let canvas = document.getElementById("grid-canvas");
      let ctx = canvas.getContext("2d");
      // -1 because initial grid starts from 0,0
      const x = ((parcelNo - 1) % cols) * cellWidth.current;
      const y = Math.floor((parcelNo - 1) / cols) * cellHeight.current;
      ctx.clearRect(x, y, cellWidth.current - 1, cellHeight.current - 1);
      setSelectedParcelNos([...selectedParcelNos, parseInt(parcelNo)]);

      // Draw tick icon
      drawIconWithCircleAndTick(x, y, 20, ctx);
    };

    /**
     * deselects the selected region i.e adds the translucent back
     * @param {*} parcelNo
     */
    const deselectRegion = (parcelNo) => {
      let canvas = document.getElementById("grid-canvas");
      let ctx = canvas.getContext("2d");
      // -1 because initial grid starts from 0,0
      const x = ((parcelNo - 1) % cols) * cellWidth.current;
      const y = Math.floor((parcelNo - 1) / cols) * cellHeight.current;

      // Calculate the coordinates of the top left corner of the icon
      const rightX = x + cellWidth.current - 20 - 8;
      const topY = y + 6;

      // Clear the area covering the numbered icon with circle
      ctx.clearRect(rightX - 4, topY - 4, 30, 30); // Adjust the clearing area size and padding as needed

      ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
      ctx.fillRect(x, y, cellWidth.current - 1, cellHeight.current - 1);
      setSelectedParcelNos((prevSelectedParcelNos) => {
        return prevSelectedParcelNos.filter(
          (p) => parseInt(p) !== parseInt(parcelNo)
        );
      });
    };

    /**
     * sold region
     * @param {*} parcelNo
     */
    const soldRegion = (parcelNo) => {
      let canvas = document.getElementById("grid-canvas");
      let ctx = canvas.getContext("2d");
      // -1 because initial grid starts from 0,0
      const x = ((parcelNo - 1) % cols) * cellWidth.current;
      const y = Math.floor((parcelNo - 1) / cols) * cellHeight.current;
      ctx.fillStyle = "rgba(255, 0, 0, 0.5) ";
      ctx.fillRect(x, y, cellWidth.current - 1, cellHeight.current - 1);
      setSelectedParcelNos((prevSelectedParcelNos) => {
        return prevSelectedParcelNos.filter(
          (p) => parseInt(p) !== parseInt(parcelNo)
        );
      });
    };

    /**
     *
     * @param {Array<number>} soldParcelsNos
     */
    const handleSetSoldRegions = (soldParcelsNos) => {
      soldParcelsNos.forEach((parcelNo) => {
        soldRegion(parcelNo);
      });
    };

    // Expose the child function through the ref
    useImperativeHandle(ref, () => ({
      selectRegion,
      deselectRegion,
      initialRegionSelection,
      deselectAllRegions,
      handleSetSoldRegions,
    }));

    const mouseClick = (e) => {
      let canvas = document.getElementById("grid-canvas");
      let rect = canvas.getBoundingClientRect();
      let x = e.clientX - rect.left;
      let y = e.clientY - rect.top;
      let cellX = Math.floor(x / cellWidth.current);
      let cellY = Math.floor(y / cellHeight.current);
      let cellNo = cellY * cols + cellX + 1;
      if (soldParcels.includes(cellNo) === false) {
        if (selectedParcelNos.includes(cellNo)) {
          deselectRegion(cellNo);
          deselectParcelFromCanvas(cellNo);
        } else {
          selectRegion(cellNo);
          selectParcelFromCanvas(cellNo);
        }
      }
    };

    return (
      <>
        <Box sx={{ position: "relative" }}>
          <Box
            ref={sourceImage}
            onLoad={drawGrid}
            id="imageContainer"
            component={"img"}
            src={artImage}
          />
          <canvas
            onClick={mouseClick}
            ref={canvas}
            style={{ borderRadius: "16px", cursor: "pointer" }}
            id="grid-canvas"
          ></canvas>
        </Box>
      </>
    );
  }
);

GridImageView.propTypes = {
  artImage: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired,
  cols: PropTypes.number.isRequired,
};

export default GridImageView;
