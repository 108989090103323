import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, Tooltip } from "@mui/material";
import { AddBusiness, Person, KeyboardArrowRight } from '@mui/icons-material';
// Import custom components for this page
import { GetApi } from "../../../Api/Api";
import { decryptStringData } from "../../../Components/Common/Utils";
import { NewLogo } from '../../../Components/Images/Images'
import STCardCompo from "../../../Components/Common/STCardCompo";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import "./Components/Questionnaire.css"

export default function SelectOwnerType(props) {
  // Destructure props to obtain necessary functions and data
  const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

  // getting current proposal and progress data from parent
  var propdata = getProposalData();
  let currentProgress = getProgress();

  // Data for owner type for selection
  const [assetData, setAssetData] = useState([{ image: <Person />, title: 'Individual', subtitle: 'The asset is owned by a single person' }, { image: <AddBusiness />, title: 'Entity', subtitle: 'The asset is owned by a corporation' }]);

  // Initialize state for the owner type
  const [selected, setSelected] = useState(propdata?.owner_type ? propdata?.owner_type : '');

  /**
   * Handle "Next" and "Save and Exit" button click
   * @param {event} event - click event
   */
  const handleClick = async (event) => {
    // Get the inner text of the clicked button
    const buttonText = event.currentTarget.textContent;
    // update proposal data
    propdata.owner_type = selected;
    // Check the inner text to determine which button was clicked
    if (buttonText.includes("Save and Exit")) {
      // update it to main page
      updateProposalData(propdata)
      // Handle the Save and Exit button click
      saveProposal();
    } else {
      // getting user data from local storage
      const userData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : { id: '' };
      // propdata.project_proposer = userData?.first_name;

      // getting user kyc data
      const res = await GetApi(`/user/getUser/${propdata?.user_id}`);
      let response = res?.data?.data;
      if (res?.data?.data) {
        const kycObj = response?.user_profile[0];
        // setting data for proposer name
        propdata.project_proposer = `${response?.first_name} ${response?.last_name}`;
        // extracting kyc data of user
        let owner_name = propdata?.ownerInformation?.owner_name ? propdata?.ownerInformation?.owner_name : response?.first_name && response?.last_name ? `${response?.first_name} ${response?.last_name}` : '';
        let owner_phone_number = propdata?.ownerInformation?.owner_phone_number ? propdata?.ownerInformation?.owner_phone_number : (response && response?.phone_number) ? response?.phone_number : ''
        // decryptStringData(kycObj?.phone_number) : ''
        let owner_address = propdata?.ownerInformation?.owner_address ? propdata?.ownerInformation?.owner_address : (kycObj && kycObj?.address_1) ? decryptStringData(kycObj?.address_1) : ''
        let organization_name = propdata?.ownerInformation?.organization_name ? propdata?.ownerInformation?.organization_name : (kycObj && kycObj?.entity_name) ? kycObj?.entity_name : response?.company_name ? response?.company_name : ''
        let organization_phone_number = propdata?.ownerInformation?.organization_phone_number ? propdata?.ownerInformation?.organization_phone_number : ''
        let organization_address = propdata?.ownerInformation?.organization_address ? propdata?.ownerInformation?.organization_address : (kycObj && kycObj?.entity_address1) ? kycObj?.entity_address1 : ''
        const newKycData = { owner_name, owner_phone_number, owner_address, organization_name, organization_phone_number, organization_address };

        // update owner information for next page
        propdata.ownerInformation = newKycData
      }
      // update it to main page
      updateProposalData(propdata)
      // Handle the Next button click
      handleNext();
    }
  }

  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left" >
              {/* left-title */}
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  Is the asset owned by an individual or entity?
                </Typography>
              </Box>
              {/* Logo */}
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right quests-right-contents-box">
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  <Button onClick={handleClick} disabled={(selected !== "") ? false : true} className="btn-rounded btn-text-white btn-blue-600">
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box className="questionnaire6 qsn-middle-content" sx={{ pt: 4, mb: 5 }}>
                <Grid container spacing={2} direction='row'>
                  {(() => {
                    const deals = [];
                    for (let i = 0; i < assetData?.length; i++) {
                      deals.push(
                        <Grid item xs={12} sm={12} md={6}
                          key={assetData[i].title}
                          onClick={() => setSelected(assetData[i].title)} className={(selected === assetData[i].title) ? "selected" : ""}
                        >
                          <STCardCompo image={assetData[i].image} title={assetData[i].title} subtitle={assetData[i].subtitle} />
                        </Grid>
                      );
                    }
                    return deals;
                  })()}
                </Grid>
              </Box>
              <Box>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={3}>
                  <Box textAlign={"left"} className='quests6-btn-box'>
                    <Button className='btn-rounded back-btn' onClick={handleback}>
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"} className='quests-btn-box'>
                    <Tooltip title={selected !== "" ? "" : "Select the owner type of the asset to continue."} className="p-flow-next-btn-tooltip">
                      <span>
                        <Button onClick={handleClick} disabled={(selected !== "") ? false : true} endIcon={<KeyboardArrowRight />} className='btn-rounded btn-text-white btn-blue-600'>
                          Next
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
