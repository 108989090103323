//  tokenUri.js
import { readContract } from "@wagmi/core";

/**
 * Fetch user wallet allowance for given spender
 * @param {String} contract_address asset data
 * @returns user wallet 
 */
async function tokenUri(id, contract_address, contract_abi) {
    try {
        // Now check for
        const uri = await readContract({
            address: contract_address,
            abi: contract_abi,
            functionName: "tokenURI",
            chainId: 6805,
            args: [
                id
            ]
        })
        return uri;
    } catch (error) {
        console.error('Error fetching tokens by index:', error);
        throw error; // propagate the error
    }
}

export { tokenUri };