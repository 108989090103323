import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  Modal,
  TextField,
  Grid,
  IconButton,
  Tooltip
} from "@mui/material";
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SaveIcon from "@mui/icons-material/Save";
import "./EditAssociatedOrganisationPopup.css";
import { updateApi } from "../../../Api/Api";
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";

const EditAssociatedOrganisationPopup = ({ open, handleClose, profileData, getProfileData }) => {
  const { showSnackbar } = useSnackbar();
  const [organizationValue, setOrganizationValue] = useState(''); // State to store input value
  const [items, setItems] = useState([]); // State to store list itemsbutton click

  // Function to handle adding item to the list
  const addItem = () => {
    if (organizationValue?.trim() !== '') {
        setItems([...items, organizationValue]); // Add item to the list
        setOrganizationValue(''); // Clear input value
    }
  };

  // Function to handle deleting an item from the list
  const deleteItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1); // Remove item at the given index
    setItems(updatedItems); // Update the list
  };

  const handleChange = (event) => {
    setOrganizationValue(event.target.value); // Update input value state
  };

  // Function to handle key press event (Enter key)
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
        addItem(); // Call addItem function when Enter key is pressed
    }
  };

  useEffect(() => {
    if(profileData && profileData?.associated_organization){
      let org = JSON.parse(profileData?.associated_organization);
      if(org && org.length > 0){
        org = org.map((i)=> i?.associated_organization);
      }
      setItems(org);
    }
  }, [profileData])

  const handleSubmit = (event) => {
    event.preventDefault();

    let updateData = new FormData();
    const associated = items?.map(
      (assosiateddata) => {
        return {
          associated_organization: assosiateddata,
        };
      }
    );
    updateData.append("associated_organization", JSON.stringify(associated));
    updateApi(`/services/updateProfile/${profileData.id}`, updateData).then((res) => {
      if (res?.status === 200) {
        showSnackbar('Updated Successfully', 'success');
        getProfileData()
        handleClose()
      } else { // something went wrong user or backend side
        showSnackbar(res?.data?.message, 'error');
        handleClose()
      }
    })
  };

  return (
    <Box className="add-education-popup-main">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-main"
      >
        <Box
          id="education-scroller-wrapper"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            borderRadius: "24px",
            width: "900px",
            padding: "52px",

            background: "var(--blueGray-900)",
            boxShadow:
              "0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.15)",
            // Media query for small devices
            "@media (max-width: 600px)": {
              width: "350px", // Set a different height for small screens
            },
            // Additional media queries for other screen sizes if needed
            "@media (min-width: 601px) and (max-width: 900px)": {
              // Custom styles for devices between 601px and 900px width
              width: "580px",
            },
            "@media (min-width: 901px) and (max-width: 1200px)": {
              // Custom styles for devices between 901px and 1200px width
              width: "750px",
              maxHeight: "300px",
              overflow: "auto",
            },
            // Specify the maximum width for large screens
            "@media (min-width: 1201px)": {
              maxWidth: "1043px",
              maxHeight: "525px",
              overflow: "auto",
            },
          }}
        >
          <Box className="close-icon-main">
            <IconButton className='close-btn' onClick={handleClose}>
                <CloseIcon className="cross-icon-modal"/>
            </IconButton>
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            mt={2}
            className="heading font-36"
            sx={{ textAlign: "center" }}
          >
            Edit Associated Organization
          </Typography>
          {/* <form onSubmit={handleSubmit}> */}
            {/* Associated Organization  Section */}
            <Box className="proff-info-box search-lang" mt={3}>
                <Grid container spacing={2}>
                    <Grid item  xs={12} md={3}>
                        <Typography className='font-22 title'>
                            Associated Organization
                        </Typography>
                        <Typography className='font-12 sub-title'>(Optional) If you are a part of an organization that is on RACE, you can link your profile to the organization’s profile.</Typography>
                    </Grid>
                    <Grid item  xs={12} md={9}>
                        <Tooltip title='After typing organization name.Please enter' placement='left' arrow>
                            <InfoOutlinedIcon style={{ float: "right" }} />
                        </Tooltip>
                        <Box className="input-box">
                            <TextField
                                id="outlined-basic"
                                label="Enter Organization Account "
                                placeholder='E.g. ABC Organization'
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                value={organizationValue}
                                onChange={handleChange}
                                onKeyDown={handleKeyPress}
                            />
                        </Box>
                        {items && items?.map((item, index) => (<Box className="data assoc-data">
                            <Box className="list-data list-wrap">

                                <Typography className='lang-name'>{item}</Typography>
                                <Box className="icons">
                                    <Box sx={{ width: "100%", display: 'flex' }}>
                                        <DeleteIcon className='data-icon' onClick={() => deleteItem(index)} />
                                    </Box>

                                </Box>
                            </Box>

                        </Box>
                        ))}
                    </Grid>
                </Grid>
            </Box>

            <Box mt={3} className="btn-container">
              <Stack spacing={2} direction="row" justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  sx={{
                      background: "var(--golden-gradient)",
                      color: "var(--blueGray-900)",
                  }}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Stack>
            </Box>
          {/* </form> */}
        </Box>
      </Modal>
    </Box>
  );
};

export default EditAssociatedOrganisationPopup;
