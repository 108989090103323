import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Stack, Typography } from '@mui/material'
import Map from '../Components/Map/Map';

export default function Location({ lat, long, editable }) {

    const [mapEdit, setMapEdit] = useState(false)
    const handleMap = () => {
        setMapEdit(true);
    };

    return (
        <>
            <Box className="pd-sidebar-editMap" mb={1.5}>
                <Stack className="pd-sidebar-heading" direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <Typography className="title" component={'h4'}>Location</Typography>
                </Stack>
                <Box className="edit-map-wrap">
                    {lat && long &&
                        <Box className="edit-map">
                            <Map mapEdit={mapEdit} addMarker={[parseFloat(lat), parseFloat(long)]} searchBoxHide={true} />
                        </Box>
                    }
                </Box>
            </Box>
        </>
    )
}

Location.propTypes = {
    lat: PropTypes.string,
    long: PropTypes.string,
    editable: PropTypes.bool
}
