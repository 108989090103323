import "react-responsive-carousel/lib/styles/carousel.min.css";
import React, { useEffect, useState } from "react";
import { Avatar, Box, Chip, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Search } from "@mui/icons-material";
import { IndividualProfilePersona, howItWorks, mobileImgSp, LeftArrow, RightArrow } from "../../Components/Images/Images";
import CategoryCard from "../Components/CategoryCard/CategoryCard";
import ServiceCard from "../Components/ServiceCard/ServiceCard";
import ServicesSearch from "../Components/ServicesSearch/ServicesSearch";
import SearchCompo from "../Components/SearchCompo/SearchCompo";
import "./ServiceCatalog.css";
import { GetApiParam } from "../../Api/Api";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getUserDataFromLocalStorage } from "../../Services/localstorage.service";

const ServiceCatalog = () => {
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState(null);
  const [servicesData, setServicesData] = useState([])
  const [profileData, setProfileData] = useState([])
  const [serviceProvider, setServiceProvider] = useState([]);
  const localData = getUserDataFromLocalStorage();

  const NextArrow = ({ onClick }) => {
    return <img src={RightArrow} alt="RightArrow" className="arrow-right" onClick={onClick} style={{ cursor: 'pointer' }} />;
  };

  const PrevArrow = ({ onClick }) => {
    return <img src={LeftArrow} alt="LeftArrow" className="arrow-left" onClick={onClick} style={{ cursor: 'pointer' }} />;
  };

  const settings = {
    slidesToShow: 1,
    rows: 2,
    slidesPerRow: 4,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: "center",
    centerPadding: "0px",
    centerMode: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2,
          slidesPerRow: 4,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2,
          slidesPerRow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2,
          slidesPerRow: 1
        }
      }
    ]
  };

  useEffect(() => {
    // Api to get categoryData
    async function getCategoryData() {
      try {
        const payload = { user_id: localData?.id };
        const res = await GetApiParam("/services/spCategory", payload);
        setCategoryData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
    // Api to get categoryData
    async function getServicesData() {
      try {
        const payload = { login_user_id: localData?.id };
        const res = await GetApiParam("/services/getServices", payload);
        setServicesData(res?.data?.data?.length ? res.data.data : []);
      } catch (error) {
        console.log(error);
      }
    }
     // Api to get categoryData
     async function getServiceProviders() {
      const payload = {
        login_user_id: localData.id,
      };
      try {
        const res = await GetApiParam("/services/getProfiles", payload);
        setServiceProvider(res?.data?.data?.length ? res.data.data : []);
      } catch (error) {
        console.log(error);
      }
    }
    getServicesData();
    getCategoryData();
    getServiceProviders();
  }, [])

  useEffect(() => {
    async function getProfileData() {
      try {
        const res = await GetApiParam("/services/ProfileDetails/", { user_id: localData?.id ? localData?.id : "" });
        setProfileData(res?.data?.data ? res?.data?.data[0] : []);
      } catch (error) {
      }
    }
    getProfileData()
  }, [])

  const chipData = [
    { label: 'Real Estate Insurance' },
    { label: 'Auditing' },
    { label: 'Digital Services' },
    { label: 'Custodian' },
    // Add more items as needed
  ];
  useEffect(() => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  })
  const handleSearchResults = (inputText) => {
  }
  return (
    <Box>
      <Box className="home-exploreBanner-section ">
        <Box className="container">
          <Box mb={3} px={'10px'}>
            <ServicesSearch pageTitle={'Service Catalog'} categoryData={categoryData} />
          </Box>
        </Box>

        {/* Expert Services */}
        <Box className='service-marketplace'>
          <Box className="container" my={8} >
            <Box className='expert-services-bg'>
              <Grid container spacing={3}>
                <Grid item md={5} sm={12} xs={12}>
                  <Box className="left-img" justifyContent={{ xs: 'center', sm: 'center', md: 'flex-end', lg: 'flex-end' }}>
                    <Avatar variant="square" alt="Image" src={mobileImgSp} sx={{ width: 'auto', maxWidth: { md: '412px', lg: '412px', xs: '280px' }, height: 'auto' }}></Avatar>
                  </Box>
                </Grid>
                <Grid item md={7} sm={12} xs={12}>
                  <Box className='expert-services'>
                    <Typography className="font-18">Service Catalog</Typography>
                    <Typography className="font-45" paddingRight={'20%'}>Clear scope.<br />
                      Secure transactions. Quality vendors.</Typography>
                    <Typography className="font-22" paddingRight={'20%'} minHeight={'112px'} mb={1}>Need services for your project? We got you. Browse and search for service providers in just a few clicks.</Typography>
                    <Box paddingRight={'20%'} width={'100%'}>
                      <SearchCompo pageTitle={"Service Catalog"} handleSearchResults={handleSearchResults}/>
                    </Box>
                    <Box paddingRight={'10%'} mt={1} display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
                      <Typography className="font-14" style={{ fontWeight: '500' }}>Popular:</Typography>
                      {chipData.map((chip, index) => (
                        <Box key={index} className='popular-chip' ml={1} my={'3px'} style={{ cursor: 'pointer' }}>
                          <Chip label={chip.label} icon={<Search />} />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        {/* Browse Services by Category */}
        <Box className='categoryCard' py={8}>
          <Box className="container">
            <Box>
              <Typography className="font-45 text-white" padding={'24px'}>Browse by category</Typography>
            </Box>
            <Box className="cat-slider">
              <Grid >
                <Slider {...settings}>
                  {categoryData?.map((category, index) => (
                    <Grid item key={index} md={3} sm={6} xs={12}>
                      <CategoryCard
                        key={index} // It's good practice to provide a unique key for each element in the array
                        Icon={category?.categoryIcon?.path}
                        Heading={category.title === 'Technology Developers & Software Services' ? 'Development & IT' : category.title}
                        SubHeading={`${category.totalSkill} Skill`}
                        url={category.id}
                      />
                    </Grid>
                  ))}
                </Slider>
              </Grid>
            </Box>
          </Box>
        </Box>

        {/*How it works*/}
        <Box className="rc-easyProcess-section" pt={{ xs: 4, md: 8 }} pb={{ xs: 6, md: 14 }}>
          <Box className="container">
            <Box className="section-heading" textAlign={"center"} pb={7} maxWidth={800} margin={'0 auto'}>
              <Typography className="title text-white" variant="h3">How it works</Typography>
              <Typography className="subtitle  text-white">We created a streamlined, user-friendly process that makes running an investment project easy and affordable.</Typography>
            </Box>
            <Box className="rc-easyProcess-wrap">
              <Avatar variant="square" alt="Image" src={howItWorks} sx={{ width: '100%', height: 'auto', maxWidth: '1000px', margin: '0 auto' }}></Avatar>
            </Box>
          </Box>
        </Box>

        {/* List of service provider */}
        <Box className='service-marketplace' mt={2}>
          <Box className="container">
            <Typography className="font-45 text-white" padding={'24px'}>In-Demand Services</Typography>
            <Grid container spacing={1}>
              {servicesData?.slice(0, 4)?.map((val, ind) => {
                return (
                  <Grid key={ind} item xs={12} sm={6} md={3} lg={3}>
                    <ServiceCard image={val?.gallery[0]?.path} description={val} title={val?.services_title} link={`/service-proposer/provider-service/${val.id}`} profileImage={profileData?.profilePicture?.length ? profileData?.profilePicture[0]?.path : IndividualProfilePersona} />
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Box>

        {/* List of service provider */}
        <Box className='service-marketplace' mt={4}>
          <Box className="container">
            <Typography className="font-45 text-white" padding={'24px'}>Newly Listed Service Providers</Typography>
            <Grid container spacing={1}>
              {serviceProvider?.slice(0, 4)?.map((val, ind) => {
                return (
                  <Grid key={ind} item xs={12} sm={6} md={3} lg={3}>
                    <ServiceCard image={val?.profilePicture?.path} title={`${val?.display_name}`} link={`/service-proposer/profile/individual/${val?.user_id}`} userId={val?.user_id}/>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Box>

        {/* List of service provider */}
        {/* <Box className='service-marketplace' mt={4}>
          <Box className="container">
            <Typography className="font-45 text-white" >Browse all categories</Typography>
            <ServicesSearch pageTitle={'Service Catalog'} categoryData={categoryData} footer={"Catalog Footer"} />
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};
export default ServiceCatalog;
