import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormGroup,
  FormHelperText,
  FormControlLabel
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import "./Contract.css";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { ccyFormat, checkNumberValue, formatDate } from "../../../Utils/utils";

const ReviewNewContract = ({ loading, setReview, formData, handleSubmitContract, step5Description, setStep5Description }) => {
  const [textCount, setTextCount] = useState(["5000"]);
  const [isChecked, setIsChecked] = useState(true); // Initially checked as per defaultChecked
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onBack = () => {
    setReview(false);
    setStep5Description('');
  }

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  /**
   * Validation function for the form Fields
   * @returns
   */

  const handleChangeDiscription = (e) => {
    const textData = e.target.value;
    if (textData.length < 5000) {
      setStep5Description(e.target.value);
    }
    setTextCount(5000 - textData.length);
  };

  const submitContract = () => {
    setIsSubmitted(true);
    if (isChecked) {
      // setIsSubmitted(false);
      handleSubmitContract();
    }
  }

  return (
    <Box className="dashboard-overview-right service-proposer-postproject">
      <Box className="post-project-heading">
        <Typography component="h4" className="title">
          Review Contract
        </Typography>
      </Box>
      <Box className="post-project-container">
        {/* Step 1 */}
        <Box className="card-container">
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography className="text-blueGray-400">Date Created</Typography>
              <Typography sx={{ fontWeight: 500 }}>{formData?.dateCreated}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="text-blueGray-400">Client / From</Typography>
              <Typography sx={{ fontWeight: 500 }}>{formData?.clientName}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="text-blueGray-400">Service Provider / To</Typography>
              <Typography sx={{ fontWeight: 500 }}>{formData?.hireName}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="text-blueGray-400">Delivery Date</Typography>
              <Typography sx={{ fontWeight: 500 }}>{formData?.deliveryDate ? formatDate(formData?.deliveryDate, 'MMM d, yyyy') : ''}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Button className="btn-rounded btn-grey">Contract ID: {formData?.contractId}</Button>
            </Grid>
          </Grid>
        </Box>

        {/* Step 2 */}
        <Box className="card-container" mt={2}>
          <Box className="">
            <Typography component="p" className="font-22">
              {formData?.Title}
            </Typography>
            <Typography component="p" className="font-12 text-blueGray-300" mt={1}>
              {formData?.Description}
            </Typography>
            <Box className="skill-container" mt={2}>
              <Box className="title-box">
                <Typography component="h4" className="title">
                  Add a personal message(Optional)
                </Typography>
              </Box>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { margin: "8px 0px", width: "100%" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-multiline-flexible"
                  placeholder="Say something to your Service Provider"
                  multiline
                  maxRows={10}
                  rows={4}
                  value={step5Description}
                  onChange={(e) => handleChangeDiscription(e)}
                />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography paddingLeft={"32px"} color={'#94A3B8'} className="font-12">
                    Enter at least 500 characters
                  </Typography>
                  <Typography paddingRight={"24px"} color={'#94A3B8'} className="font-12">
                    {textCount} characters left
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Step 4 */}
        <Box className="" mt={2}>
          <Box className="table-view-container">
            <Grid container spacing={2}>
              <Grid item xs={9} md={6} sm={6}>
                <Box className="your-order-div" p={2}>
                  <Typography className="font-16 text-white">
                    Fees
                  </Typography>
                  <Typography className="text-blueGray-300 font-12" ml={2}>
                    {formatDate(new Date(), 'MMM d, yyyy')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3} md={6} sm={6} sx={{ alignSelf: "center", textAlign: 'end' }} pr={2}>
                <Link className="text-golden font-12 text-underline view-invoice-text">
                  Learn more about fees
                </Link>
              </Grid>
            </Grid>

            <Box mt={1} className="item-table-main">
              <TableContainer >
                <Table aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell>TOTAL PRICE OF PROJECT</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">
                        {formData.totalPriceOfProject}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>This includes all milestones, and this is the amount your Service Provider will see</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">
                        {formData.totalPriceOfProject}
                      </TableCell>
                    </TableRow>
                    <TableRow className="sub-total-div">
                      <TableCell>RACE SERVICE FEE</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">
                        {formData.raceServiceFee}
                      </TableCell>
                    </TableRow>
                    <TableRow className="sub-total-div">
                      <TableCell>YOU'LL PAY</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">
                        {ccyFormat(checkNumberValue(formData.raceServiceFee) + checkNumberValue(formData.totalPriceOfProject))}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Your estimated payment, after all fees</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">
                        {ccyFormat(checkNumberValue(formData.raceServiceFee) + checkNumberValue(formData.totalPriceOfProject))}
                      </TableCell>
                    </TableRow>
                  </TableBody>

                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
        <Box className="confirm-title">
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} sx={{ color: '#fff' }} />} label="Yes, I understand and agree to the RACE Terms of Service, including the User Agreement and Privacy Policy." />
          </FormGroup>
          {isSubmitted && !isChecked && (
            <FormHelperText error>Please agree to the terms and conditions to proceed.</FormHelperText>
          )}
        </Box>

        {/* Review Project Button */}
        <Box className="new-project-btn">
          <Typography className="footer-text" sx={{ color: "#398CD1" }} mr={8} mt={1} onClick={onBack}>Back</Typography>
          {/* <Button
            variant="outlined"
            startIcon={<RemoveRedEyeIcon />}
            onClick={submitContract}
          >
            Send to Service Provider
          </Button> */}
          <LoadingButton
            onClick={submitContract}
            startIcon={<RemoveRedEyeIcon />}
            loading={loading}
            loadingPosition="start"
            variant="contained"
            type="submit"
            className="submit-btn"
          >
            Send to Service Provider
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ReviewNewContract;