import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, Button, Stack, Avatar, MenuItem } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Upload } from '@mui/icons-material';
import countryList from "react-select-country-list";

const OnboardingProfileLeft = ({ onDataUpdate, onImageUpdate, onBack, onNext, previousData }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        description: '',
        location: '' // Renamed from displayName to location
    });
    const localData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : "";

    const [profileImage, setProfileImage] = useState(null); // Holds the selected image file
    const [textCount, setTextCount] = useState(5000);
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        description: '',
        profileImage: '', // Add an error state for the profile image
        location: '' // Renamed from displayName to location
    });

    // const initialCountryList = countryList().getData().map(country => ({
    //     label: `${country.label} (${country.value})`,
    //     value: country.value
    // }));

    // const sortedCountryList = initialCountryList.sort((a, b) => a.label.localeCompare(b.label));
    // Getting country name
    const selectedCountries = ['United States', 'United Kingdom', 'Canada', 'India', 'Singapore', 'Brazil'];
    const initialCountryList = countryList().getData();

    // arranging country according to client requirement 
    const customSort = (a, b) => {
        const indexA = selectedCountries.indexOf(a.label);
        const indexB = selectedCountries.indexOf(b.label);

        if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
        } else if (indexA !== -1) {
            return -1;
        } else if (indexB !== -1) {
            return 1;
        }

        return a.label.localeCompare(b.label);
    };

    const sortedCountryList = initialCountryList.sort(customSort);
    // Getting country name- end

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        let updatedValue = value;
        if (name === 'description') {
            // Check if description length is within the limit
            if (value.length > 5000) {
                updatedValue = value.substring(0, 5000); // Trim the description to 5000 characters
            }
            setTextCount(5000 - updatedValue.length); // Update character count
        }   
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: updatedValue
        }));
        
        // Construct the data object to send to the parent component
            const dataToSend = {
            ...formData,
            [name]: updatedValue // Update the specific field with the new value
        };
        // Pass data to parent component
        onDataUpdate(dataToSend);

        // Clear the error message when user starts typing
        setErrors({
            ...errors,
            [name]: ''
        });
    };  

    useEffect(() => {
        
        if (localData) {
            // Update only firstName and lastName fields
            setFormData(prevState => ({
                ...prevState,
                firstName: localData.first_name || '',
                lastName: localData.last_name || ''
            }));
        }
    }, [localData]);


    useEffect(() => {
        if (previousData) {
            setFormData({
                "description": previousData.description,
                "firstName": previousData.firstName,
                "lastName": previousData.lastName,
                "location": previousData.location, // Renamed from displayName to location
            });
            setProfileImage(previousData.profileImage);
        }
    }, [previousData]);

    // Function to handle image upload
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfileImage(file);
            setErrors({
                ...errors,
                profileImage: '' // Clear any previous error message
            });
            onImageUpdate(file); // Pass the selected image to the parent component
        } else {
            setErrors({
                ...errors,
                profileImage: 'Please select an image' // Set error message if no file is selected
            });
        }
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        // Validate first name
        if (!formData.firstName || formData.firstName.trim() === '') {
            newErrors.firstName = 'First name is required';
            valid = false;
        }

        // Validate last name
        if (!formData.lastName || formData.lastName.trim() === '') {
            newErrors.lastName = 'Last name is required';
            valid = false;
        }

        // Validate description
        if (!formData.description || formData.description.trim() === '') {
            newErrors.description = 'Description is required';
            valid = false;
        }

        if (formData?.description?.length < 500) {
            newErrors.description = 'Please enter a description of at least 500 characters.';
            valid = false;
        }

        // Validate profile image
        if (!profileImage) {
            newErrors.profileImage = 'Please select an image';
            valid = false;
        }

        // Validate country of residence
        if (!formData.location || formData.location.trim() === '') {
            newErrors.location = 'Country of Residence is required.';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleFormSubmit = () => {
        if (validateForm()) {
            const dataToSend = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                description: formData.description,
                location: formData.location, // Renamed from displayName to location
                profileImage: profileImage
            };

            // Pass data to parent component
            onDataUpdate(dataToSend);
            onNext();
        }
    };

    return (
        <Box className='onboarding-profile-left-container'>
            <Typography className='font-36 heading' component="h4">
                Personal Information
            </Typography>
            <Typography className='font-18 sub-heading' component="p">
                Tell us a bit about yourself. This information will appear on your public profile, so that potential clients can get to know you better,
            </Typography>
            <Box mt={3}>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6} sm={12}>
                        <TextField
                            id="first-name"
                            name="firstName"
                            label="First Name"
                            variant="outlined"
                            placeholder='Enter your name'
                            className='onboarding-input-text'
                            value={formData.firstName}
                            onChange={handleInputChange}
                            error={Boolean(errors.firstName)}
                            helperText={errors.firstName}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={6} sm={12}>
                        <TextField
                            id="last-name"
                            name="lastName"
                            label="Last Name"
                            variant="outlined"
                            placeholder='Enter your surname'
                            className='onboarding-input-text'
                            value={formData.lastName}
                            onChange={handleInputChange}
                            error={Boolean(errors.lastName)}
                            helperText={errors.lastName}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} mt={3}>
                        <TextField
                            className='roles_select'
                            InputLabelProps={{
                                style: { color: '#fff' }, // Set the label text color to white
                            }}
                            label="Country of Residence"
                            id="outlined-select-country-native"
                            select
                            defaultValue = ""
                            name='location' // Renamed from displayName to location
                            value={formData.location}
                            onChange={handleInputChange}
                            error={Boolean(errors.location)}
                            helperText={errors.location?.message}
                            fullWidth
                            InputProps={{
                                style: { color: '#fff' }, // Set the input text color to white
                            }}
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200, // Set maximum height for the dropdown
                                        },
                                    },
                                },
                            }}
                        >
                            {sortedCountryList?.map((item, index) => (
                                <MenuItem key={index} value={item?.label}>{item?.label}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Box>
            <Box className='discription-container' mt={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} sm={12}>
                        <Box>
                            <Typography className='font-22'>Description</Typography>
                            <Typography className='font-12 disc-sub'>Make sure you include:</Typography>
                            <ul>
                                <li className='font-12'>Clear expectations about your tasks or deliverables</li>
                                <li className='font-12'>Skills required for your work</li>
                                <li className='font-12'>Details about how you or your team like to work</li>
                            </ul>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={8} sm={12}>
                        <Box>
                            <Typography className='font-16'>Describe yourself</Typography>
                        </Box>
                        <Box mt={2}>
                            <TextField
                                id="description"
                                name="description"
                                variant="outlined"
                                placeholder='Already have a description? Paste it here!'
                                value={formData.description}
                                onChange={handleInputChange}
                                className='onboarding-input-text'
                                multiline  // Enable multiline
                                rows={4}   // Set the number of rows to display
                                error={Boolean(errors.description)}
                                helperText={errors.description}
                                style={{ minHeight: '80px' }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }} mt={0.5}>
                            {!errors.description ? <Typography paddingLeft={"16px"} className='font-12'>  Enter at least 500 characters</Typography> : <Typography paddingLeft={"16px"} className='font-12'></Typography>}
                            <Typography paddingRight={"16px"} className='font-12'> {textCount} characters left</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className='profile-container' mt={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Box>
                            <Typography className='font-22'>Profile Picture</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Stack direction="row" spacing={2}>
                            <Avatar alt={formData.firstName} src={profileImage ? URL.createObjectURL(profileImage) : '/static/images/avatar/1.jpg'} className='upload-img' />
                        </Stack>
                        <input
                            accept=".jpg,.jpeg,.png"
                            style={{ display: 'none' }}
                            id="upload-button"
                            type="file"
                            onChange={handleImageUpload}
                        />
                        <label htmlFor="upload-button">
                            <Button
                                className='upload-img-btn'
                                variant="contained"
                                component="span"
                                startIcon={<Upload />}
                            >
                                Upload Image
                            </Button>
                        </label>
                        {errors.profileImage && (
                            <Typography variant="body2" color="error">
                                {errors.profileImage}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Box mt={5}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Box className='go-back-btn'>
                        <Button startIcon={<ArrowBackIcon />} onClick={onBack}>
                            Go Back
                        </Button>
                    </Box>
                    <Box className="continue-btn">
                        <Button
                            variant="contained"
                            startIcon={<ArrowForwardIcon />}
                            className='btn-rounded btn-large'
                            style={{ background: "var(--golden-gradient)" }}
                            onClick={handleFormSubmit}
                        >
                            Continue
                        </Button>
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
};

export default OnboardingProfileLeft;
