import React, { useEffect, useState } from 'react'
import './ServicesSearch.css'
import { Box, IconButton, Stack, TextField, Typography, styled, Badge, Menu, MenuItem, Tab, Tabs } from '@mui/material'
import { KeyboardArrowDown, Search } from '@mui/icons-material';
import PropTypes from 'prop-types'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GetApi } from '../../../Api/Api';

/**
 * Style for the Search text field
 */
const SearchTextField = styled(TextField)({
    width: '100%',
    maxWidth:"662px",
    height: '48px',
    '& .MuiOutlinedInput-root': {
        '& input': {
            padding: '12px 24px',
        },
        '& fieldset': {
            borderRadius: '48px',
        },

    },
});

const SearchButton = styled(IconButton)({
    '& .MuiIconButton-root': {
        padding: '10px',
        backgroundColor: '#398CD1',
        borderRadius: '48px',
        '& .MuiSvgIcon-root': {
            color: '#ffffff',
            height: '12px',
            width: '12px',
        }
    },

    // disable hover
    '&:hover': {
        backgroundColor: '#398CD1',
        '& .MuiSvgIcon-root': {
            color: '#ffffff',
        }
    }


});

function ServicesSearch({ page, showSearchBar, handleSearchResults, pageTitle, categoryData, searchString, fetchServicesAccordingToCategory }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [inputText, setInputText] = useState(searchString ? searchString : '')

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);


    useEffect(() => {
        if (location.pathname) {
            setAnchorEl(null)
        }
    }, [location.pathname])

    const handleClick = (event) => {
        if (Boolean(anchorEl)) {
            setAnchorEl(null)
        }
        else { setAnchorEl(event.currentTarget); }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLinkClick = (data) => {
        navigate(`/service-proposer/client-category/${data.id}`, { state: { title: data.title } })
    }

// for services provider home page in spv flow
    if(page === 'serviceProviderHome'){
        return (
            <Box className="service-search-wrap" >
                <Stack direction="row" flexWrap={'wrap'} gap={1.5}>
                    {categoryData?.slice(0, 4).map((category, index) => (
                        <Box key={index} className="service-search-item">
                            <Box onClick={() => navigate("/service-provider/browser-request", { state: { category } })} sx={{ cursor: 'pointer' }}>
                                <Badge badgeContent={category?.projectsCount} >
                                    <Typography variant="subtitle2" className="tab-title">
                                        {category.title === 'Technology Developers & Software Services' ? 'Development & IT' : category.title}
                                    </Typography>
                                </Badge>
                            </Box>
                        </Box>
                    ))}
                    {categoryData?.length > 4 &&
                        <Box
                            className="service-search-item"
                            id="fade-button"
                            aria-controls={open ? "fade-menu-item" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                        >
                            <Box className="tab-title"  sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <Typography sx={{ cursor: 'pointer' }}>
                                    More
                                </Typography>
                                <KeyboardArrowDown className="icon" sx={{ cursor: 'pointer' }}/>
                                <Menu
                                    id="fade-menu-item"
                                    MenuListProps={{
                                        "aria-labelledby": "fade-button",
                                    }}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    className="dropdown-menu InvesterHeader-dropdown more-category"
                                    
                                >
                                    {categoryData?.slice(4).map((item, index) => (
                                        <Box onClick={() => navigate("/service-provider/browser-request", { state: { category: item } })}>
                                            <MenuItem key={index} onClick={handleClose}>
                                                <Box className='service-search-item'>
                                                    <Badge badgeContent={item?.projectsCount} >
                                                        <Typography className='text-title'> {item.title}</Typography>
                                                    </Badge>
                                                </Box>
                                            </MenuItem>
                                        </Box>
                                    ))}
                                </Menu>
                            </Box>
                        </Box>
                    }
                </Stack>
            </Box>
        )
    }
// for services list in client flow (client-category-results route)
    if(page === 'searchServices'){
        return (
            <Box className="service-search-wrap" >
                <Stack direction="row" flexWrap={'wrap'} gap={1.5}>
                    {categoryData?.slice(0, 4).map((category, index) => (
                        <Box key={index} className="service-search-item">
                            <Box onClick={() => fetchServicesAccordingToCategory(category)} sx={{ cursor: 'pointer' }}>
                                <Badge badgeContent={category?.servicesCount} >
                                    <Typography variant="subtitle2" className="tab-title">
                                        {category.title === 'Technology Developers & Software Services' ? 'Development & IT' : category.title}
                                    </Typography>
                                </Badge>
                            </Box>
                        </Box>
                    ))}
                    {categoryData?.length > 4 &&
                        <Box
                            className="service-search-item"
                            id="fade-button"
                            aria-controls={open ? "fade-menu-item" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                        >
                            <Box className="tab-title"  sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <Typography sx={{ cursor: 'pointer' }}>
                                    More
                                </Typography>
                                <KeyboardArrowDown className="icon" sx={{ cursor: 'pointer' }}/>
                                <Menu
                                    id="fade-menu-item"
                                    MenuListProps={{
                                        "aria-labelledby": "fade-button",
                                    }}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    className="dropdown-menu InvesterHeader-dropdown more-category"
                                    
                                >
                                    {categoryData?.slice(4).map((item, index) => (
                                        <Box onClick={() => fetchServicesAccordingToCategory(item)}>
                                            <MenuItem key={index} onClick={handleClose}>
                                                <Box className='service-search-item'>
                                                    <Badge badgeContent={item?.servicesCount} >
                                                        <Typography className='text-title'> {item.title}</Typography>
                                                    </Badge>
                                                </Box>
                                            </MenuItem>
                                        </Box>
                                    ))}
                                </Menu>
                            </Box>
                        </Box>
                    }
                </Stack>
    
                <Box className={"service-search-item catalog-search"} mt={3}>
                    <SearchTextField variant='outlined' placeholder="Search Services"
                        value={inputText}
                        onChange={(e) => {
                            setInputText(e.target.value);
                            handleSearchResults(e.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <SearchButton className='search-button' sx={{ p: '10px', bgcolor: '#cfa119' }} aria-label="search" onClick={() => handleSearchResults(inputText)} >
                                    <Search style={{ color: '#05131B' }} sx={{ color: '#05131B', height: '12px', width: '12px' }} />
                                </SearchButton>
                            ),
                        }}
                    />
                </Box>
            </Box>
        )
    }
// for service provider list in client flow
    if(page === 'serviceProviderList'){
        return (
            <Box className="service-search-wrap" >
                <Stack direction="row" flexWrap={'wrap'} gap={1.5}>
                    {categoryData?.slice(0, 4).map((category, index) => (
                        <Box key={index} className="service-search-item">
                            <Box onClick={() => fetchServicesAccordingToCategory(category)} sx={{ cursor: 'pointer' }}>
                                <Badge badgeContent={category?.serviceProviderCount} >
                                    <Typography variant="subtitle2" className="tab-title">
                                        {category.title === 'Technology Developers & Software Services' ? 'Development & IT' : category.title}
                                    </Typography>
                                </Badge>
                            </Box>
                        </Box>
                    ))}
                    {categoryData?.length > 4 &&
                        <Box
                            className="service-search-item"
                            id="fade-button"
                            aria-controls={open ? "fade-menu-item" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                        >
                            <Box className="tab-title"  sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <Typography sx={{ cursor: 'pointer' }}>
                                    More
                                </Typography>
                                <KeyboardArrowDown className="icon" sx={{ cursor: 'pointer' }}/>
                                <Menu
                                    id="fade-menu-item"
                                    MenuListProps={{
                                        "aria-labelledby": "fade-button",
                                    }}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    className="dropdown-menu InvesterHeader-dropdown more-category"
                                    
                                >
                                    {categoryData?.slice(4).map((item, index) => (
                                        <Box onClick={() => fetchServicesAccordingToCategory(item)}>
                                            <MenuItem key={index} onClick={handleClose}>
                                                <Box className='service-search-item'>
                                                    <Badge badgeContent={item?.serviceProviderCount} >
                                                        <Typography className='text-title'> {item.title}</Typography>
                                                    </Badge>
                                                </Box>
                                            </MenuItem>
                                        </Box>
                                    ))}
                                </Menu>
                            </Box>
                        </Box>
                    }
                </Stack>
    
                <Box className={"client-category-results"} mt={3}>
                    <SearchTextField variant='outlined' placeholder="Search Service Provider" 
                        value={inputText}
                        onChange={(e) => {
                            setInputText(e.target.value);
                            handleSearchResults(e.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <SearchButton className='search-button' sx={{ p: '10px', bgcolor: '#cfa119' }} aria-label="search" onClick={() => handleSearchResults(inputText)} >
                                    <Search style={{ color: '#05131B' }} sx={{ color: '#05131B', height: '12px', width: '12px' }} />
                                </SearchButton>
                            ),
                        }}
                    />
                </Box>
            </Box>
        )
    }
// for find page of service provider in client flow
    if(page === 'findServiceProvider'){
        return (
            <Box className="service-search-wrap" >
                <Stack direction="row" flexWrap={'wrap'} gap={1.5}>
                    {categoryData?.slice(0, 4).map((category, index) => (
                        <Box key={index} className="service-search-item">
                            <Box onClick={() => fetchServicesAccordingToCategory(category)} sx={{ cursor: 'pointer' }}>
                                <Badge badgeContent={category?.serviceProviderCount} >
                                    <Typography variant="subtitle2" className="tab-title">
                                        {category.title === 'Technology Developers & Software Services' ? 'Development & IT' : category.title}
                                    </Typography>
                                </Badge>
                            </Box>
                        </Box>
                    ))}
                    {categoryData?.length > 4 &&
                        <Box
                            className="service-search-item"
                            id="fade-button"
                            aria-controls={open ? "fade-menu-item" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                        >
                            <Box className="tab-title"  sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <Typography sx={{ cursor: 'pointer' }}>
                                    More
                                </Typography>
                                <KeyboardArrowDown className="icon" sx={{ cursor: 'pointer' }}/>
                                <Menu
                                    id="fade-menu-item"
                                    MenuListProps={{
                                        "aria-labelledby": "fade-button",
                                    }}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    className="dropdown-menu InvesterHeader-dropdown more-category"
                                    
                                >
                                    {categoryData?.slice(4).map((item, index) => (
                                        <Box onClick={() => fetchServicesAccordingToCategory(item)}>
                                            <MenuItem key={index} onClick={handleClose}>
                                                <Box className='service-search-item'>
                                                    <Badge badgeContent={item?.serviceProviderCount} >
                                                        <Typography className='text-title'> {item.title}</Typography>
                                                    </Badge>
                                                </Box>
                                            </MenuItem>
                                        </Box>
                                    ))}
                                </Menu>
                            </Box>
                        </Box>
                    }
                </Stack>
            </Box>
        )
    }
// for common pages with condition in spv flow
    return (
        <Box className="service-search-wrap" >
            <Stack direction="row" flexWrap={'wrap'} gap={1.5}>
                {pageTitle && <Box className="service-search-item service-catlog-items" style={{ borderRight: "3px solid #red" }}>
                    <Typography variant="subtitle2" className='tab-title'>
                        {pageTitle === "Service Catalog" ? <Link to={"/service-proposer/service-catalog"}>
                            {pageTitle}
                        </Link>
                            : { pageTitle }
                        }
                    </Typography>
                </Box>
                }
                {categoryData?.slice(0, 4).map((category, index) => (
                    <Box key={index} className="service-search-item">
                        <Box onClick={() => handleLinkClick(category)} sx={{ cursor: 'pointer' }}>
                            <Badge badgeContent={page === 'serviceProviderList' ? category?.serviceProviderCount : category?.servicesCount} >
                                <Typography variant="subtitle2" className="tab-title">
                                    {category.title === 'Technology Developers & Software Services' ? 'Development & IT' : category.title}
                                </Typography>
                            </Badge>
                        </Box>
                    </Box>
                ))}
                {categoryData?.length > 4 &&
                    <Box
                        className="service-search-item"
                        id="fade-button"
                        aria-controls={open ? "fade-menu-item" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                    >
                        <Box className="tab-title"  sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Typography sx={{ cursor: 'pointer' }}>
                                More
                            </Typography>
                            <KeyboardArrowDown className="icon" sx={{ cursor: 'pointer' }}/>
                            <Menu
                                id="fade-menu-item"
                                MenuListProps={{
                                    "aria-labelledby": "fade-button",
                                }}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                className="dropdown-menu InvesterHeader-dropdown more-category"
                                
                            >
                                {categoryData?.slice(4).map((item, index) => (
                                    <Box onClick={() => handleLinkClick(item)}>
                                        <MenuItem key={index} onClick={handleClose}>
                                            <Box className='service-search-item'>
                                                <Badge badgeContent={page === 'serviceProviderList' ? item?.serviceProviderCount : item?.servicesCount} >
                                                    <Typography className='text-title'> {item.title}</Typography>
                                                </Badge>
                                            </Box>
                                        </MenuItem>
                                    </Box>
                                ))}
                            </Menu>
                        </Box>
                    </Box>
                }
            </Stack>

            {
                showSearchBar &&
                <Box className={(pageTitle === 'Service Catalog') ? "catalog-search" : pageTitle === 'client-category-results' ? "client-category-results" : "service-search-item"} mt={3}>
                    <SearchTextField variant='outlined' placeholder="Search Services"
                        onChange={(e) => {
                            setInputText(e.target.value);
                            handleSearchResults(e.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <SearchButton className='search-button' sx={{ p: '10px', bgcolor: '#cfa119' }} aria-label="search" onClick={() => handleSearchResults(inputText)} >
                                    <Search style={{ color: '#05131B' }} sx={{ color: '#05131B', height: '12px', width: '12px' }} />
                                </SearchButton>
                            ),
                        }}
                    />
                </Box>
            }
        </Box>
    )
}

export default ServicesSearch

//props types default and declaration
ServicesSearch.defaultProps = {
    showSearchBar: false,
}

ServicesSearch.propTypes = {
    showSearchBar: PropTypes.bool,
    pageTitle: PropTypes.any, // Indicates that pageTitle is optional and can be any type
}
