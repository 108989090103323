import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { GetApiParam } from '../../Api/Api'
import purify from "dompurify";

const TermsAndConditions = () => {
  const [terms, setTerms] = useState("")

  useEffect(() => {
    async function getTerms() {
      try {
        const termsPayload = {
          type: 1,
        };
        const response = await GetApiParam("/admin/cms", termsPayload);
        setTerms(response?.data?.data?.[0]?.content)
      } catch (error) {
        console.log(error);
      }
    }
    getTerms();

  }, []);

  return (
    <>
      <Box className="termsConditions-section" pt={{ xs: 4, sm: 7, md: 8, lg: 9 }} pb={{ xs: 4, sm: 5, md: 10 }}>
        <Box className="container">
          <Box px={{ xs: 0, md: 6, lg: 8 }} className='term-section'>
            <Box dangerouslySetInnerHTML={{ __html: purify.sanitize(terms) }} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TermsAndConditions;
