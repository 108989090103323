import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactImageGallery from 'react-image-gallery';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VideoThumbnail from 'react-video-thumbnail';

export default function ReviewServiceSlider({ images, coverPhoto }) {
    const [data, setData] = useState([]);
    const isXs = useMediaQuery('(max-width:300px)');

    // Transform galleryDetails array to match the structure of asset_gallery array
    useEffect(() => {
        // Combine images and cover photo
        let combinedData = [];
        images.slice(0, 3).map((val) => {
            const isVideo = val?.gallery_image?.type === 'video/mp4';
           let thumbnailUrl = isVideo ? (val.gallery_image) : val.gallery_image;
            combinedData.push({
                original: val.gallery_image,
                thumbnail: thumbnailUrl,
                isVideo: isVideo,
            });
        });
        if (coverPhoto) {
            combinedData.unshift({
                original: `${process.env.REACT_APP_IMAGE_URL}${coverPhoto.path}`,
                thumbnail: `${process.env.REACT_APP_IMAGE_URL}${coverPhoto.path}`,
                isVideo: false,
            });
        }
        setData(combinedData);
    }, []);

    // Function to generate a URL from a File object
    const generateUrlFromFile = (file) => {
        return URL.createObjectURL(file);
      };

    const renderThumbnail = (item) => {
        if (item.isVideo) {
            return (
                <div className="video-thumbnail">
                    <VideoThumbnail
                            videoUrl={generateUrlFromFile(item.original)}
                            style={{ width: '100%', height: 'auto' }}
                        />
                    <div className="play-icon-overlay">
                        <PlayCircleOutlineIcon fontSize="large" />
                    </div>
                </div>
            );
        } else {
        return <img src={URL.createObjectURL(item.thumbnail)} alt="" height='auto' width="100%" />;
        }
    };

    const renderItem = (item) => {
        if (item.isVideo) {
            return (
                <div className="video-wrapper">
                    <video controls poster={generateUrlFromFile(item.thumbnail)} width="100%" height="auto" style={{ marginBottom: '40px' }} className='image-gallery-image'>
                        <source src={generateUrlFromFile(item.original)} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            );
        }
        return (
            <img src={URL.createObjectURL(item.original)} alt="" className='image-gallery-image' />
        );
    };

    return (
        <Box className="details-thumbnails-slider">
            {data && data.length > 0 ? (
                <ReactImageGallery
                    items={data}
                    autoPlay={false}
                    showThumbnails={true}
                    thumbnailPosition={isXs ? 'bottom' : 'left'} // Set the position based on screen size
                    infinite={false}
                    showPlayButton={false}
                    lazyLoad={true}
                    useBrowserFullscreen={true}
                    originalHeight="426px"
                    originalWidth="200px"
                    showIndex={true}
                    disableThumbnailScroll={false}
                    showNav={false}
                    showBullets={true}
                     showFullscreenButton={true}
                    slideOnThumbnailOver={false}
                    disableThumbnailSwipe={false}
                    renderThumbInner={renderThumbnail} // Use custom render for thumbnails
                    renderItem={renderItem}
                />
            ) : (
                <Box>
                    <Typography variant="h6" sx={{ textAlign: 'center' }}>
                        No Images available
                    </Typography>
                </Box>
            )}
        </Box>
    );
}
