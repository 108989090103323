import React from "react";
import { useForm } from "react-hook-form";
import { Box, Typography, TextField, Button, Container } from "@mui/material";
import logo from "./../../Assets/Images/logo.png";

export default function ResetPassword() {

  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = (data) => console.log(data);

  return (
    <Box className="resetPassword-page">
      <Box className="auth-logo">
        <Box component="img" src={logo} alt="Race logo" />
      </Box>
      <Container height={"100%"}>
        <Box className="resetPassword-section">
          <Box className="resetPassword-box" width={"100%"}>
            <Box className="heading-wrap">
              <Typography component="h1" className="title">
                Reset Password
              </Typography>
              <Typography component="p" className="sub-title">
                You can reset your password here
              </Typography>
            </Box>
            <Box
              className="rp-form dark-form"
              component="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box className="form-group label-field">
                <TextField
                  id="outlined-required"
                  label="Company Name"
                  type="text"
                  name="compName"
                  {...register("compName", {
                    required: "Company Name is required.",
                    pattern: {
                      value: /^[A-Za-z]+$/i,
                      message: "Please enter Valid name",
                    },
                    minLength: {
                      value: 3,
                      message: "Please enter valid name",
                    },
                  })}
                  error={Boolean(errors.compName)}
                  helperText={errors.compName?.message}
                />
              </Box>
              <Box className="form-group label-field">
                <TextField
                  id="outlined-required"
                  label="Company Email Address"
                  type="email"
                  name="email"
                  {...register("email", {
                    required: "E-mail address is required.",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "Please enter valid email address.",
                    },
                  })}
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                />
              </Box>
              <Box className="btn-wrap">
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  className="btn-rounded"
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
