import React, { useEffect, useState, useRef } from 'react';
import './ProfileDescription.css';
import { Avatar, Box, Button, Stack, Typography, Tooltip } from '@mui/material';
import { CompanyBannerImg, MessageButtonGradient, PersonaPicBg, ShareLinkGradient, EditButtonGradient } from '../../../Components/Images/Images';
import PropTypes from 'prop-types';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { IndividualProfilePersona, EditIconGradient } from "../../../Components/Images/Images";
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import EditRatePopup from '../../EditProfile/EditRatePopup/EditRatePopup';
import EditDescriptionPopup from '../../EditProfile/EditDescriptionPopup/EditDescriptionPopup';
import EditNamePopup from '../../EditProfile/EditNamePopup/EditNamePopup';
import { getUserDataFromLocalStorage } from '../../../Services/localstorage.service';
import { useSnackbar } from '../../../Contexts/SnackbarContext';
import { updateApi, postApi } from '../../../Api/Api';

function ProfileDescription(props) {
    const navigate = useNavigate()
    const { showSnackbar } = useSnackbar();
    const localData = getUserDataFromLocalStorage();
    const [readMoreStatus, setReadMoreStatus] = useState(false);
    const [isPopupOpenEditProfile, setIsPopupOpenEditProfile] = useState(false);
    const [isPopupOpenEditDescription, setIsPopupOpenEditDescription] = useState(false);
    const [isPopupOpenEditName, setIsPopupOpenEditName] = useState(false);
    const [coverImage, setCoverImage] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const inputFile = useRef(null);
    const inputAvatarFile = useRef(null);

    // Check if props.persona exists and is a file object
    let avatarSrc = props.persona ? props.persona : IndividualProfilePersona;
    if(props.ProfileImage){
        avatarSrc = props.ProfileImage ? URL.createObjectURL(props.ProfileImage) :  IndividualProfilePersona
    }
    const coverSrc = props.coverPicture ? props.coverPicture : props.banner;

    const handleCoverFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setCoverImage(file);
            let updateData = new FormData();
            updateData.append("cover_picture", file);
            try {
                const res = await updateApi(`/services/updateProfile/${props?.id}`, updateData);
                if (res.status === 200) {
                    props?.getProfileData();
                } else {
                    // something went wrong on user or backend side
                    showSnackbar(res.data?.message, 'error');
                }
            } catch (error) {
                showSnackbar("An error occurred while updating the profile.", 'error');
            }
        }
    }

    const handleAvatarFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfileImage(file);
            let updateData = new FormData();
            updateData.append("profile_picture", file);
            try {
                const res = await updateApi(`/services/updateProfile/${props?.id}`, updateData);
                if (res.status === 200) {
                    props?.getProfileData();
                } else {
                    // something went wrong on user or backend side
                    showSnackbar(res.data?.message, 'error');
                }
            } catch (error) {
                // handle error appropriately
                console.error("Error updating profile", error);
                showSnackbar("An error occurred while updating the profile.", 'error');
            }
        }
    }


    const onButtonClick = () => {
        inputFile.current.click();
    };

    const onButtonClickCam = () => {
        inputAvatarFile.current.click();
    };

    /**
     * HandleCLick Function for the Read More Button
     */
    const handleClickReadMore = () => {
        setReadMoreStatus(true)
    }

    /**
     * Onclick function for the Read Less Function
     */
    const handleClickReadLess = () => {
        setReadMoreStatus(false)
    }

    const handleChat = async () => {
        try {
            const payload = {
                userID: localData?.id,
                toUserID: props?.user_id,
            }
            const res = await postApi(`/chat/spAddChat`, payload);
            if (res?.data && res?.data?.data) {
                navigate("/service-proposer-dashboard/chats", {
                    state: {
                        user_id: props?.user_id,
                        chat_id: res?.data?.data?.id
                    },
                });
            }
        } catch (error) {
            console.log("dddd", error);
        }

    };

    const handleEditProfile = () => {
        handleOpenPopupEdit()
    }

    /**
     * Function to open Profile Modal
     */
    const handleOpenPopupEdit = () => {
        setIsPopupOpenEditProfile(true);
    };

    /**
    * Function to close Profile Modal
    */
    const handleClosePopupEdit = () => {
        setIsPopupOpenEditProfile(false);
    };

    const handleEditDescription = () => {
        handleOpenPopupEditDescription()
    }

    /**
     * Function to open Description Modal
     */
    const handleOpenPopupEditDescription = () => {
        setIsPopupOpenEditDescription(true);
    };

    /**
     * Function to close Description Modal
     */
    const handleClosePopupEditDescription = () => {
        setIsPopupOpenEditDescription(false);
    };

    const handleClosePopupEditName = () => {
        setIsPopupOpenEditName(false);
    }

    if (props.from === "serviceProviderProfileOffer") {
        return (
            <Box className="profile-description-wrap">
                <Box className="profile-banner-img-wrap" >
                    <Avatar className='profile-banner-img' src={coverSrc} />
                </Box>
                <Box className="profile-persona-img-wrap" >
                    <Avatar className='profile-persona-img user-img-divs' src={avatarSrc} />
                </Box>
                <Box>
                    <Stack direction="row" justifyContent={'space-between'} mt={-5} ml={17} >
                        <Box>
                            <Typography variant="h5" className="profile-name">{props.name}</Typography>
                            <Typography variant="body1" className="profile-description text-blueGray-400">{props.address}</Typography>
                        </Box>
                        {props.offerStatus === 1 &&
                        <Tooltip title={props?.availability === 'notAvailable' ? 'Service Provider Not Available' : 'Hire'} placement='bottom' arrow>
                            <Button 
                                onClick={() => {if(props?.availability !== 'notAvailable')props?.setOpenModal(props.spvId, props.offerId)}} 
                                startIcon={<AddIcon />} variant="contained" 
                                className="btn-rounded" 
                                style={{ background: "var(--golden-gradient)" }}>Hire</Button>
                        </Tooltip>
                            // <Button disabled={props?.availability === 'notAvailable'} onClick={() => { props?.setOpenModal(props.spvId, props.offerId); }} startIcon={<AddIcon />} variant="contained" className="btn-rounded" style={{ background: "var(--golden-gradient)" }}>Hire</Button>
                        }
                        {props.offerStatus === 2 &&
                            <Button startIcon={<CheckIcon />} variant="contained" className="btn-rounded" style={{ background: "green", color: "white" }}>Confirmed</Button>
                        }
                        {props.offerStatus === 3 &&
                            <Button startIcon={<CloseIcon />} variant="contained" className="btn-rounded" style={{ background: "red", color: "white" }}>Cancelled</Button>
                        }
                    </Stack>
                </Box>
                <Stack mt={2} direction={'row'} spacing={6} justifyContent={'space-between'} >
                    <Box pt={props.description === "No discription found." ? 4 : 0}>
                        <Typography variant="h6" sx={{ fontSize: '16px' }} className="profile-description">{props.title}</Typography>
                        {/* <Typography variant="body1" className="profile-description text-blueGray-400">{props.description}</Typography> */}
                        {
                            readMoreStatus ?
                                <Typography variant="body1" className="profile-description text-blueGray-400">{props.description}</Typography>
                                :
                                <Typography sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical",
                                }} variant="body1" className="profile-description text-blueGray-400">{props.description}</Typography>
                        }
                    </Box>
                    <Box alignItems={'center'}>
                        <Typography className='text-blueGray-400'>TOTAL PRICE</Typography>
                        <Typography variant="h6" sx={{ fontSize: '14px' }} className="profile-description text-white">{props.hourlyrate ? `$${props.hourlyrate}` : '$0'}</Typography>
                    </Box>
                </Stack>
                <Box mt={3}>
                    {readMoreStatus ?
                        <Button variant="contained" className="btn-rounded read-more-btn" endIcon={<KeyboardArrowUpIcon />} onClick={() => handleClickReadLess()}><Typography>Read Less</Typography></Button>
                        :
                        <Button variant="contained" className="btn-rounded read-more-btn" endIcon={<KeyboardArrowDownIcon />} onClick={() => handleClickReadMore()}><Typography>Read More</Typography></Button>
                    }
                </Box>
                <Box mt={3} display={'flex'}>
                    <img onClick={() => handleChat()} src={MessageButtonGradient} alt={''} />
                    <Button onClick={() => navigate(`/service-proposer/profile/individual/${props.spvId}`, {
                        state: {
                            user_id: props?.spvId,
                            offer_id: props?.offerId,
                            project_id: props?.projectId
                        },
                    })} variant="contained" className="btn-rounded" style={{ background: "var(--golden-gradient)", marginLeft: "10px" }}>View Full Profile</Button>
                </Box>
            </Box>
        )
    }
    return (
        <Box className="profile-description-wrap">
            <Box className="profile-banner-img-wrap" >
                <Avatar className='profile-banner-img' src={coverImage ? URL.createObjectURL(coverImage) : coverSrc} />
                <Box className="edit-cover-image">
                    <Button >
                        {props.from === "serviceProviderProfile" ? <EditIcon onClick={onButtonClick} /> : null}
                    </Button>
                </Box>
                <input
                    style={{ display: "none" }}
                    accept=".jpg,.jpeg,.png"
                    ref={inputFile}
                    onChange={handleCoverFileUpload}
                    type="file"
                />
            </Box>
            <Box className="profile-persona-img-wrap" >
                <Avatar className='profile-persona-img user-img-divs' src={profileImage ?  URL.createObjectURL(profileImage) : avatarSrc} />
                <Box className="profile-persona-camera-icon">
                    <Button >
                        {props.from === "serviceProviderProfile" ? <CameraAltIcon onClick={onButtonClickCam} /> : null}
                    </Button>
                </Box>
                <input
                    style={{ display: "none" }}
                    accept=".jpg,.jpeg,.png"
                    ref={inputAvatarFile}
                    onChange={handleAvatarFileUpload}
                    type="file"
                />
            </Box>

            <Box>
                <Stack direction="row" justifyContent={'space-between'} mt={-5} ml={{ xs: '0', sm: '0', md: '17px' }}
 >
                    <Box className="profile-container-div">
                        <Box>
                        <Typography variant="h5" className="profile-name">{props.name}</Typography>
                        <Typography variant="body1" className="profile-description text-blueGray-400">{props.address}</Typography>
                       
                        </Box>
                         {props.from === "serviceProviderProfile" && <img src={EditIconGradient} alt={""} style={{ margin: '0px 22px 4px 12px' }} onClick={()=> setIsPopupOpenEditName(true)} />}
                    </Box>

                    {(props.from === "serviceProviderProfileFromClient") &&
                        // <Button onClick={() => props.setOpenModal(true)} startIcon={<AddIcon />} variant="contained" className="btn-rounded" style={{ background: "var(--golden-gradient)" }}>Hire</Button>
                        <Tooltip title={props?.availability === 'notAvailable' ? 'Service Provider Not Available' : 'Hire'} placement='bottom' arrow>
                            <Button 
                                onClick={() => {if(props?.availability !== 'notAvailable')props?.setOpenModal(true)}} 
                                startIcon={<AddIcon />} variant="contained" 
                                className="btn-rounded" 
                                style={{ background: "var(--golden-gradient)" }}>Hire</Button>
                        </Tooltip>
                    }
                </Stack>
            </Box>
            <Stack mt={2} direction={'row'} spacing={6} justifyContent={'space-between'} >
                <Box pt={props.description === "No discription found." ? 4 : 0}>
                    <Typography variant="h6" sx={{ fontSize: '16px' }} className="profile-description">{props.title}</Typography>
                    {
                        readMoreStatus ?
                            <Typography variant="body1" className="profile-description text-blueGray-400">{props.description}</Typography>
                            :
                            <Typography sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                            }} variant="body1" className="profile-description text-blueGray-400">{props.description}</Typography>
                    }
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                    <Typography variant="h6" sx={{ fontSize: '14px' }} className="profile-description text-white">{props?.hourlyrate ? `$${props?.hourlyrate}/hr` : '$0/hr'}</Typography>
                    {props.from === "serviceProviderProfile" ?
                        <img src={EditIconGradient} alt={""} style={{ margin: '0px 22px 4px 12px' }} onClick={handleEditProfile} /> :
                        <img src={ShareLinkGradient} alt={""} style={{ margin: '0px 22px 4px 12px' }} />}
                </Box>
                {isPopupOpenEditProfile && <EditRatePopup open={isPopupOpenEditProfile} handleClose={handleClosePopupEdit} profileData={props} getProfileData={props?.getProfileData}/>}
            </Stack>
            <Box mt={3}>
                {readMoreStatus ?
                    <Button variant="contained" className="btn-rounded read-more-btn" endIcon={<KeyboardArrowUpIcon />} onClick={() => handleClickReadLess()}><Typography>Read Less</Typography></Button>
                    :
                    <Button variant="contained" className="btn-rounded read-more-btn" endIcon={<KeyboardArrowDownIcon />} onClick={() => handleClickReadMore()}><Typography>Read More</Typography></Button>
                }
            </Box>
            <Box mt={3}>
                {props.from === "serviceProviderProfile" && <img src={EditButtonGradient} alt={''} onClick={() => handleOpenPopupEditDescription()} /> }
                {props?.from === "serviceProviderProfileFromClient" && <img onClick={() => handleChat()} src={MessageButtonGradient} alt={''} />}
            </Box>
            {isPopupOpenEditDescription && <EditDescriptionPopup onOpen={isPopupOpenEditDescription} handleClose={handleClosePopupEditDescription} profileData={props} getProfileData={props?.getProfileData} />}
            {isPopupOpenEditName && <EditNamePopup onOpen={isPopupOpenEditName} handleClose={handleClosePopupEditName} userDetails={props} getProfileData={props?.getProfileData} />}
        </Box>
    )
}

export default ProfileDescription

//Prop Types

ProfileDescription.propTypes = {
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    persona: PropTypes.string,
    banner: PropTypes.string,
    from: PropTypes.string
}

//Default Props

ProfileDescription.defaultProps = {
    name: "Name is not available",
    address: 'No address found.',
    title: 'No title found.',
    description: "No discription found.",
    persona: PersonaPicBg,
    banner: CompanyBannerImg,
    from: "any page"
}
