import React from 'react'
import { Typography, Box, Avatar } from '@mui/material';
import './ReachInvestors.css';

const MultipleFundraising = (props) => {
    return (
        <Box className='lg-icon-thumbnail multi-fund bg-blue-900'>
            <Box className="lg-icon-wrap">
                <Avatar alt="Icon" variant='square'>
                    {props.image}
                </Avatar>
            </Box>
            <Box className='lg-content' pt={3.8}>
                <Typography component={'h5'}>
                    {props.title}
                </Typography>
                <Typography mt={1.5}>
                    {props.subtitle}
                </Typography>
            </Box>
        </Box>
    )
}

export default MultipleFundraising