import { Box, Typography, Avatar, Grid } from '@mui/material'
import { dollarBagIcon, dollarNoteIcon, percentageWhiteIcon, diversificationIcon } from "./../Images/Images";
import './WhyInvest.css'

const WhyInvest = () => {
    return (
        <Box className="whyInvest-wrap">
            <Box className="section-heading" mb={4} textAlign={'center'}>
                <Typography className="title" variant="h3">Why Invest In Real Estate?</Typography>
            </Box>
            <Box className="whyInvest-iconBox-wrap">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} mb={1}>
                        <Box className='lg-icon-thumbnail whyInvest bg-blue-900'>
                            <Box className="lg-icon-wrap">
                                <Avatar className='bg-blue-800' alt="Icon" variant='square' src={dollarBagIcon}></Avatar>
                            </Box>
                            <Box className='lg-content' mt={3}>
                                <Typography component={'h5'}>
                                    Stable Cash Flow
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} mb={1}>
                        <Box className='lg-icon-thumbnail whyInvest bg-blue-900'>
                            <Box className="lg-icon-wrap">
                                <Avatar className='bg-blue-800' alt="Icon" variant='square' src={diversificationIcon}></Avatar>
                            </Box>
                            <Box className='lg-content' mt={1}>
                                <Typography component={'h5'}>
                                    Diversification
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} mb={1}>
                        <Box className='lg-icon-thumbnail whyInvest bg-blue-900'>
                            <Box className="lg-icon-wrap">
                                <Avatar className='bg-blue-800' alt="Icon" variant='square' src={percentageWhiteIcon}></Avatar>
                            </Box>
                            <Box className='lg-content' mt={3}>
                                <Typography component={'h5'}>
                                    Capital Appreciation
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} mb={1}>
                        <Box className='lg-icon-thumbnail whyInvest bg-blue-900'>
                            <Box className="lg-icon-wrap">
                                <Avatar className='bg-blue-800' alt="Icon" variant='square' src={dollarNoteIcon}></Avatar>
                            </Box>
                            <Box className='lg-content' mt={3}>
                                <Typography component={'h5'}>
                                    Tax Advantages
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}

export default WhyInvest