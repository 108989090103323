import CategoryHeader from "../CategoryHeader";
import ReachInvestors from "../../Components/Common/ReachInvestors";
import { Box } from "@mui/material";
import {
  ArtFeature,
  ArtDashboard,
  ArtRace,
} from "../../Components/Images/Images";
import NewestListings from "../NewestListings";
import TopInvestments from "../TopInvestments";
import TypeSlider from "../TypeSlider";
import ExploreFractionalizationCompo from "../ExploreFractionalization";
import WhyUseRace from "../WhyUseRace";

export default class Art {
  constructor() { }

  type = "Art";

  pageComponenets = []; //push all relevant componenets here

  headerData = {
    title: "Build Your Own Portfolio of",
    highlight: " Tokenised Masterpieces",
    subtitle:
      "Invest in Fine Art easily; in a new, secure, and transparent way.",
    image: ArtDashboard,
  };

  whyRaceData = {
    title: "Why use RACE to invest in",
    highlightText: "Art",
    first: "Access Masterpieces",
    second: "Direct and Lower Fees",
    third: "Curated and Authenticated",
    fourth: "Quick and Seamless",
    image: ArtRace,
  };

  fractionalizationData = {
    title: "Fine Art has historically",
    highlightText: "outperformed",
    title1: " other asset classes” - Citibank",
    subTitle:
      "Since 1985, contemporary art has been the best bet for investors of the asset class, returning an average of 7.5% per year.",

    learnMoreLink: "/dummy",
    // extra: "What’s the difference between a parcel and a fraction?",
    extraLink: "/dummy",
    image: ArtFeature,
    exploreFractionalization: false,
    exploreParceling: true,
  };

  setPageComponents() {
    this.pageComponenets.push(<CategoryHeader categoryId={2} data={this.headerData} />);
    this.pageComponenets.push(<NewestListings categoryId={2} type="Art" />);
    this.pageComponenets.push(<TopInvestments categoryId={2} type="Art" />);
    this.pageComponenets.push(
      <TypeSlider
        categoryId={2}
        sliderData={this.typeSliderData}
        sliderTitle={"Art Types"}
      />
    );
    this.pageComponenets.push(<WhyUseRace data={this.whyRaceData} />);

    this.pageComponenets.push(
      <ExploreFractionalizationCompo
        categoryId={2}
        investment_type_id={3}
        data={this.fractionalizationData}
        type="Art"
      />
    );
    this.pageComponenets.push(
      <>
        <Box className='container'>
          <Box className="reachInvestors-section artLanding">
            <ReachInvestors />
          </Box>
        </Box>
      </>
    );
  }
}
