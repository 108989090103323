const topCountries = ['US', 'UK', 'CA']; // Example ISO codes for top countries

// Function to sort countries with US, UK, CA in a specific order, then alphabetically
export const SortCountries = (countries) => {
    return countries.sort((a, b) => {
        const aIndex = topCountries.indexOf(a.iso);
        const bIndex = topCountries.indexOf(b.iso);

        // Prioritize US > UK > CA
        if (aIndex !== -1 && bIndex !== -1) {
            return aIndex - bIndex;
        }
        if (aIndex !== -1) return -1;
        if (bIndex !== -1) return 1;

        // Alphabetically sort the rest
        return a.iso?.localeCompare(b.iso);
    });
};