import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Grid, Box, Button, ListItemIcon, Badge, Avatar, Menu, MenuItem, Typography, IconButton, Tabs, Tab, Divider, } from "@mui/material";
import { User, NewLogo, Logo } from "../../Components/Images/Images";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Logout from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useSocket } from "../../Contexts/SocketContext";
import "./Header.css";
import { GetApi, updateApi } from "../../Api/Api";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useDisconnect } from "wagmi";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


const HeaderDic = () => {

  // Get the navigate function from the useNavigate custom hook
  const navigate = useNavigate();

  const location = useLocation();
  // Parse user data from local storage
  const userData = JSON.parse(localStorage.getItem("user_data"));

  // Get the socket instance using the custom hook
  const socket = useSocket();
  const { showSnackbar } = useSnackbar();
  const { disconnect } = useDisconnect();


  // State variables for anchor element and its open state
  const [notifications, setNotifications] = useState([])
  const [unreadNotifications, setUnreadNotifications] = useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [value, setValue] = useState(-1);

  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Event handler to open the anchor element
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
    setValue(0);
  };

  // Event handler to close the anchor element
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
    setValue(-1)
  };

  async function getNotification() {
    try {
      if (userData?.id) {
        const res = await GetApi(`/user/getNotification?id=${userData.id}`);
        if (res?.data?.data && res?.data?.data?.data) {
          setNotifications(res.data.data.data);
          setUnreadNotifications(res?.data?.data?.unreadCount);
        }
      } else {
        showSnackbar()
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * function to clear Local storage
   */
  const ClearLocalStorageData = () => {
    localStorage.removeItem("chatbot-thread-id")
    localStorage.removeItem("refreshToken")
    localStorage.removeItem("rememberMeToken")
    localStorage.removeItem("Diclogin")
    localStorage.removeItem("user_data_dic")
    localStorage.removeItem("user_data")
    localStorage.removeItem("token")
    localStorage.removeItem("login")
    localStorage.removeItem("kyc_modal_closed")
  }

  // Function to calculate relative time
  const formatRelativeTime = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
    return formattedDate
  }
  useEffect(() => {
    // Api to get getNotification
    const timer = setTimeout(() => {
      getNotification();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [])

  // new-notification received then call
  socket.on("new-notification", (data) => {
    getNotification();
  });

  /**
  * Function to update notification is_read status
  * @param {Object} value 
  */
  const handleNotificationsUpdate = (value) => {
    async function updateNotification() {
      try {
        const res = await updateApi(`/user/updateNotification/${value.id}`);
        if (res?.data?.code === 200) {
          // getting updated data
          getNotification();
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (!value.is_read) {
      updateNotification();
    }
  }

  const logOutDic = async () => {
    showSnackbar('You are getting log out...', 'warning')
    setTimeout(() => {
      disconnect();
      socket.emit("remove-user", userData?.id);
      ClearLocalStorageData()
      navigate("/login", { replace: true });
      window.location.reload(true);
    }, 1000)

    // topFunction();
  }
  // Function to scroll to the top of the page
  function topFunction() {
    document.documentElement.scrollTop = 0;
  }

  // code for restricting Routes for the Categories
  const notificationPath = (val) => {
    console.log(val)
    switch (val.type) {
      case 'chat':
        return `/dic/chats`;
      case "Voting":
        return `/dic/project-details/${val.asset_id}`;
      case "Project Updates":
        return `/dic/project-details/${val.asset_id}`;
      case "Open Project list":
        return `/dic/overview`;
      case "Terms":
        return `/dic/project`;
      case "Terms ":
        return `/dic/project`;
      default:
        return "";
    }
  }

  const handleNotificationView = (val) => {
    navigate(notificationPath(val), {
      state: { assetId: val?.asset_id, dicId: val?.receiver_id, project_name: val?.project_name },
    })
    setAnchorEl1(null);
  }

  // Function to get the first letter of a string
  const getFirstLetter = (str) => {
    return str.charAt(0);
  };

  return (
    <>
      <Box className="main-header-wrap">
        <Box ml={2} mr={2} className={location?.pathname?.split('/')[1] === 'dic' ? '' : 'container'}>
          <Grid container spacing={2} className="main-header">
            <Grid item xs={2} className="left">
              <Box display={"flex"} alignItems={"center"}>
                <Box className="logo logo-dic">
                  <img src={NewLogo} alt="Logo" />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={10} className="right">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <Button
                  onClick={logOutDic}
                  className="btn-rounded btn-golden"
                >
                  Log In As Investor{" "}
                </Button>

                {/* <Box className="notification" sx={{ ml: { xs: 2, md: 6 } }}>
                  <Badge
                    // badgeContent={3}
                    color="primary">
                    <NotificationsIcon />
                  </Badge>
                </Box> */}

                {/* notifications code */}
                <Box className="menu-item">
                  <IconButton
                    className="notification"
                    sx={{ ml: { xs: 1, md: 3 } }}
                    id="fade-button"
                    aria-controls={open1 ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? "true" : undefined}
                    onClick={handleClick1}
                  // onMouseOver={handleClick1}
                  >
                    {unreadNotifications ?
                      <Badge
                        badgeContent={unreadNotifications}
                        color="primary"
                      >
                        <NotificationsIcon />
                      </Badge>
                      : <NotificationsIcon />
                    }
                  </IconButton>
                  {notifications?.length ?
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      anchorEl={anchorEl1}
                      open={open1}
                      onClose={handleClose1}
                      // onMouseLeave={handleClose1}
                      className="mega-menu InvesterHeader-dropdown notifications"
                      keepMounted
                    >
                      <OverlayScrollbarsComponent
                        options={{
                          scrollbars: {
                            autoHide: "scroll",
                          },
                        }}
                        style={{ width: 358, maxHeight: 280, marginRight: '5px' }}
                      >
                        <Box
                          className='header-notifications'
                          sx={{ width: "358px" }}
                        >
                          <Tabs
                            value={value}
                            scrollButtons={false}
                            onChange={handleChange}
                            aria-label="scrollable prevent tabs example"
                            className="not-tabs"
                            indicatorClassName="my-indicator"
                          >
                            <Tab label="Notifications" className="tab-title" />
                          </Tabs>
                          <Divider thickness={2} className="divider" />
                          <TabPanel value={value} index={0}>
                            {notifications && notifications?.map((val, ind) => {
                              return (
                                <Box key={ind} className='notification-item' onClick={() => { handleNotificationsUpdate(val); handleNotificationView(val); }}>
                                  <Box>
                                    <Typography variant="body1" className="title">
                                      <span >
                                        {val?.project_name ? `You received the message for the ${val?.project_name} project` : val.message}
                                      </span>
                                    </Typography>
                                    <Typography variant="caption" className="time">
                                      {formatRelativeTime(val.createdAt)}
                                    </Typography>
                                  </Box>
                                  {!val?.is_read ? <Box>
                                    <span className="unread-icon"></span>
                                  </Box>
                                    : null}
                                </Box>
                              );
                            })}
                          </TabPanel>
                        </Box>
                      </OverlayScrollbarsComponent>
                    </Menu>
                    : null}
                </Box>
                {/* <Box className="notification" sx={{ ml: { xs: 1, md: 6 }, display: { xs: 'none', md: 'block' } }}>
                  <Badge color="secondary" overlap="circular" variant="dot" className="timelapse-badge">
                    <TimelapseIcon />
                  </Badge>
                </Box> */}
                {/* <Box className="notification" sx={{ ml: { xs: 1, md: 6 }, display: { xs: 'none', md: 'block' } }}>
                  <Badge
                    // badgeContent={3} 
                    color="primary">
                    <MessageIcon />
                  </Badge>
                </Box> */}
                {/*  icon */}
                {/* <Box className="userStatus" sx={{ ml: { xs: 2, md: 3.5 } }}>
                  <Avatar
                    alt="Token icon"
                    src={
                      userData?.persona_picture
                        ? userData?.persona_picture
                        : User
                    }
                    sx={{ width: 40, height: 40 }}
                  />
                  <Badge className="insideBadge"></Badge>
                </Box> */}

                <Box className="userStatus" sx={{ ml: { xs: 2, md: 3.5 } }}>
                  {userData && userData?.persona_picture ? (
                    <Avatar
                      alt="Token icon"
                      src={
                        userData?.persona_picture ?? userData?.persona_picture
                      }
                      sx={{ width: 40, height: 40 }}
                    />
                  ) : (
                    <Avatar className={"avatar avtar-custom"} style={{ backgroundColor: 'white', color: 'black' }} alt="Remy Sharp">
                      {getFirstLetter(
                        userData?.first_name ? userData?.first_name : ""
                      )}
                      {getFirstLetter(
                        userData?.last_name ? userData?.last_name : ""
                      )}
                    </Avatar>
                  )}
                  <Badge className="insideBadge"></Badge>
                </Box>

                <Box className="profileMenu-wrap" sx={{ ml: { xs: 2, md: 1.5 } }}>
                  <Button
                    id="fade-button"
                    aria-controls={open ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    className="profileMenu-btn"
                  >
                    <ArrowDropDownRoundedIcon />
                  </Button>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    // TransitionComponent={Fade}
                    className="dropdown-menu investor-only"
                  >
                    {/* <MenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <AccountCircleIcon fontSize="small" />
                      </ListItemIcon>
                      Profile
                    </MenuItem> */}
                    <MenuItem
                      onClick={handleClose}
                    >
                      <ListItemIcon>
                        <AccountCircleIcon fontSize="small" />
                      </ListItemIcon>
                      My account
                    </MenuItem>
                    {/* <MenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <Settings fontSize="small" />
                      </ListItemIcon>
                      Settings
                    </MenuItem> */}
                    <MenuItem
                      onClick={() => {
                        // localStorage.removeItem("login");
                        // localStorage.clear();
                        ClearLocalStorageData()
                        handleClose();
                        socket.emit("remove-user", userData?.id);
                        //
                        navigate("/dic/login", { replace: true });
                        window.location.reload(true);
                        disconnect()
                      }}
                    >
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default HeaderDic;


