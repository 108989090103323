import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Import custom components for this page
import { NewLogo, Quest4 } from '../../../Components/Images/Images'
import LinearProgressCompo from "./Components/LinearProgressCompo";
import "./Components/Questionnaire.css"

// Style for left-half background image
const styles = {
  backgroundImage: `url(${Quest4})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  minHeight: '100vh',
  marginRight: '0px',
  backgroundPosition: "center",
  backgroundAttachment: "fixed",
}

export default function LetsGetStarted() {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/questionnaire-flow', {
      replace: true, // This will replace the current entry in the history stack
    })
  }
  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left" style={styles}>
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right quests-right-contents-box">
              <Box textAlign={"right"} className="alreadyAc-des">
                <Box component="span" className="icon" ml={1}>
                  <Link to='/project-proposer'>
                    <CloseIcon />
                  </Link>
                </Box>
              </Box>
              <Box className="quests-right-contents">
                <Box className="heading-wrap">
                  <Typography component="h1" className="title" mb={2.5}>
                    Collateralize or raise capital for your assets in a few easy steps.
                  </Typography>
                  <Typography component="p" className="sub-title">
                    We’ll help you every step of the way
                  </Typography>
                </Box>
              </Box>
              <Box mt={2}>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={0} />
                </Box>
                <Box textAlign={"right"} className='quests-btn-box' mt={4.7}>
                  <Link to=''>
                    <Button endIcon={<KeyboardArrowRightIcon />} onClick={handleNavigate} className='btn-rounded btn-text-white btn-blue-600'>
                      Let’s get started
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
