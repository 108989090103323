import { Box, Button, MenuItem, FormControl, Grid, TextField, Typography, InputAdornment } from "@mui/material";
import React, { useState, useEffect } from "react";
import { GetApi } from "../../Api/Api";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { Controller, useForm } from "react-hook-form";
import CountryCodes from "../../Data/countries.json";
import { LoadingButton } from "@mui/lab";
import { ArrowBack } from '@mui/icons-material';
import countryList from "react-select-country-list";
import { isValidPhoneNumber } from "libphonenumber-js";
import "./StaticModal.css";

const countryCodeVal = CountryCodes;

export default function ServiceProviderModal({ handleSubmitParent, goBack }) {
  // const [categoryData, setCategoryData] = useState();
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([])

  // Getting country name
  const selectedCountries = ['United States', 'United Kingdom', 'Canada', 'India', 'Singapore', 'Brazil'];
  const initialCountryList = countryList().getData();

  // arranging country according to client requirement 
  const customSort = (a, b) => {
    const indexA = selectedCountries.indexOf(a.label);
    const indexB = selectedCountries.indexOf(b.label);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    } else if (indexA !== -1) {
      return -1;
    } else if (indexB !== -1) {
      return 1;
    }

    return a.label.localeCompare(b.label);
  };

  const sortedCountryList = initialCountryList.sort(customSort);
  // Getting country name- end



  useEffect(() => {
    window.scrollTo(0, 0);
    // Api to get categoryData
    // async function getCategoryData() {
    //   try {
    //     const res = await GetApi("/proposer/getCategory/");
    //     setCategoryData(res?.data?.data);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    async function getServices() {
      try {
        const res = await GetApi("/user/getServices");
        setServices(res?.data?.data);
      } catch (error) {
        console.log(error);
      }

    }

    // getCategoryData();
    getServices();
  }, []);


  const {
    register,
    control,
    // reset,
    watch,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const countryCodeNum = watch("country_code");



  const onSubmit = (data) => {
    setLoading(true);
    let userData = "";
    const country_data = data.country_code.split('-')
    userData = {
      title: "service-provider",
      service: data.service,
      // first_name: data.first_name,
      // last_name: data.last_name,
      // email: data.email,
      country_code: country_data[0],
      phone_number: data.phoneNumber,
      country_of_residence: data.Country,
      floor: data.suiteFloor,
      city: data.City,
      state: data.stateProvince,
      zipcode: data.ZIPPostal,
    };

    if (userData) {
      handleSubmitParent(userData);
    }
  };

  const [countryIso, setCountryIso] = useState('');
  /**
  *  validate phone number with libphonenumber-js library
  * @param {string} value  phone number
  * @param {string} country_iso  country code
  * @returns 
  */
  const validatePhoneNumber = (value) => {
    // const res = isSupportedCountry(countryIso);
    const countryCodeNumUpdate = countryCodeNum.split('-')[0];
    return isValidPhoneNumber(`+${countryCodeNumUpdate}${value}`, countryIso);
  }

  return (
    <Box className="investor-modal-container" sx={{ ml: { md: '50px', sm: '40px', xs: '5px' }, maxHeight: '70vh', marginTop: { md: '-70px' }, overflowY: 'auto' }}  >
      <Box className="staticHomepages provider-details-form" sx={{
        maxWidth
          : '730px', mx: 'auto', mt: 2,
      }}>
        <Box
          p={{ xs: 2, sm: 2 }}
          // bgcolor={"#000"}
          // borderRadius={"16px"}
          sx={{ maxWidth: "730px" }}
          mt={{ xs: 2, sm: 3.5 }}
        >
          <Typography
            className="title text-blue-900 font-36"
            color={"#fff"}
            component={"h2"}
          >
            Join us as a service provider
          </Typography>

          {/*Form starts from here*/}
          <Box mt={3.5} component="form" autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
            <Typography
              className="font-18 text-blue-900"
              color={"#fff"}
              mb={2}
            >
              Please submit the information below so we can optimize your
              experience as a service provider on the RACE platform.
            </Typography>
            <Box className="form-group label-field" mt={3.5}>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">Asset Type</InputLabel> */}
                <TextField
                  fullWidth
                  label="Service"
                  select
                  className='roles_select'
                  name="service"
                  {...register("service", {
                    required: "Service type is required.",
                  })}
                  error={Boolean(errors.service)}
                  helperText={errors.service?.message}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200, // Adjust the maximum height according to your needs
                          maxWidth: '50%',
                        },
                      },
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" }, // Set the label text color to white
                  }}
                  InputProps={{
                    style: { color: "#fff" }, // Set the input text color to white
                  }}
                  autoComplete="on"
                  onChange={() => clearErrors("service")}
                // sx={{
                //   width: '100%',
                //   '@media (max-width: 600px)': {
                //     width: '100%', // Adjust the width for screens less than 600px wide
                //   },
                // }}
                >
                  {services?.map((item, index) => (
                    <MenuItem key={item.index} value={item?.title}>
                      {item?.title}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Box>

            <Typography
              className="font-18 text-blue-900"
              color={"#fff"}
              mb={2}
            >
              Please fill in the details below
            </Typography>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={3} md={4} lg={3}>
                <Box className="form-group label-field staticProposerModel">
                  <Controller
                    name="country_code"
                    control={control}
                    defaultValue={`${countryCodeVal[0].code}-${countryCodeVal[0].iso}`}
                    rules={{
                      required: "Country Code is required",
                    }}
                    render={({ field }) => (
                      <TextField
                        select
                        name="country_code"
                        {...field}
                        error={Boolean(errors.country_code)}
                        helperText={errors.country_code?.message}
                        label="Country Code"
                        InputLabelProps={{
                          style: { color: "#fff" }, // Set the label text color to white
                        }}
                        InputProps={{
                          style: { color: "#fff" }, // Set the input text color to white
                        }}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: 200, // Adjust the maximum height according to your needs
                              },
                            },
                          },
                        }}
                      >
                        {countryCodeVal.map((option, index) => (
                          <MenuItem

                            key={index}
                            value={`${option.code}-${option.iso}`}
                            onClick={() => {
                              setCountryIso(option.iso)
                            }}
                          >
                            {`+${option.code} ${option.country}  `}
                          </MenuItem>
                        ))}

                      </TextField>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={9} md={8} lg={9}>
                <Box className="form-group label-field staticProposerModel">
                  <TextField
                    name="phoneNumber"
                    label="Phone Number"
                    type="text"
                    pattern="[0-9]*"
                    placeholder="Enter phone number"
                    {...register("phoneNumber", {
                      required: "Phone number is required..",
                      validate: (value) => validatePhoneNumber(value, countryCodeNum) || "Please enter valid phone number",
                    })}
                    autoComplete="tel" // this line for auto-fill
                    error={Boolean(errors.phoneNumber)}
                    helperText={(errors?.phoneNumber?.message)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIphoneIcon className="text-blue-600" />
                        </InputAdornment>
                      ),
                    }}
                    onInput={(event) => {
                      event.target.value = event.target.value
                        .replace(/\D/g, "")
                        .slice(0, 15);
                    }}
                    InputLabelProps={{
                      style: { color: "#fff" }, // Set the label text color to white
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            {/* <Box  mb={3}>
              <FormControl  >
                <TextField
                  className='roles_select'
                  InputLabelProps={{
                    // shrink: true,
                    style: { color: '#fff' }, // Set the label text color to white
                  }}
                  label="Country"
                  id="outlined-select-country-native"
                  select
                  name='Country'
                  {...register("Country", {
                    required: "Country is required.",
                  })}
                  error={Boolean(errors.Country)}
                  helperText={errors.Country?.message}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200, // Adjust the maximum height according to your needs
                          maxWidth: '50%',
                        },
                      },
                    },
                  }}
                  InputProps={{
                    style: { color: '#fff' }, // Set the input text color to white
                  }}
                  fullWidth
                >
                  {
                    sortedCountryList?.map((item, index) => (
                      <MenuItem key={index} value={item?.label}>{item?.label}</MenuItem>
                    ))
                  }
                </TextField>
              </FormControl>
            </Box> */}

            <Box mb={3} >
              <FormControl fullWidth >
                <TextField
                  className='roles_select'
                  InputLabelProps={{
                    // shrink: true,
                    style: { color: '#fff' }, // Set the label text color to white
                  }}
                  label="Country of Residence"
                  id="outlined-select-country-native"
                  select
                  name='Country'
                  {...register("Country", {
                    required: "Country is required.",
                  })}
                  error={Boolean(errors.Country)}
                  helperText={errors.Country?.message}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200, // Adjust the maximum height accosrding to your needs
                          maxWidth: '50%',
                        },
                      },
                    },
                  }}
                  InputProps={{
                    style: { color: '#fff' }, // Set the input text color to white
                  }}
                  // sx={{
                  //   width: '100%',
                  //   '@media (max-width: 600px)': {
                  //     width: '100%', // Adjust the width for screens less than 600px wide
                  //   },

                  //   '@media screen and (-webkit-min-device-pixel-ratio: 2)': {
                  //     width: '100%', // Adjust for high-density displays (Retina)
                  //   },
                  // }}
                  fullWidth
                  onChange={() => clearErrors("Country")}
                >
                  {
                    sortedCountryList?.map((item, index) => (
                      <MenuItem key={index} value={item?.label}>{item?.label}</MenuItem>
                    ))
                  }
                </TextField>
              </FormControl>
            </Box>
            {/* <Grid container spacing={{ xs: 0, sm: 1 }}>
              <Grid item xs={12} sm={6}>
                <Box className="form-group label-field staticProposerModel">
                  <TextField
                    name="suiteFloor"
                    label="Suite/Floor"
                    placeholder="Enter Suite/Floor"
                    {...register("suiteFloor", {
                      required: "suite floor is required.",
                      // pattern: {
                      //     value: /^[A-Za-z]+$/i,
                      //     message: "Please enter valid suiteFloor",
                      // },
                      minLength: {
                        value: 1,
                        message: "Please enter valid suite floor",
                      },
                    })}
                    autoComplete="on"
                    error={Boolean(errors.suiteFloor)}
                    helperText={errors.suiteFloor?.message}
                    InputLabelProps={{
                      style: { color: "#fff" }, // Set the label text color to white
                    }}
                    InputProps={{
                      style: { color: "#fff" }, // Set the input text color to white
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className="form-group label-field staticProposerModel">
                  <TextField
                    name="City"
                    label="City"
                    placeholder="Enter your city"
                    {...register("City", {
                      required: "City is required.",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Please enter valid city",
                      },
                      minLength: {
                        value: 3,
                        message: "Please enter valid city",
                      },
                    })}
                    autoComplete="on"
                    error={Boolean(errors.City)}
                    helperText={errors.City?.message}
                    InputLabelProps={{
                      style: { color: "#fff" }, // Set the label text color to white
                    }}
                    InputProps={{
                      style: { color: "#fff" }, // Set the input text color to white
                    }}
                  />
                </Box>
              </Grid>
            </Grid> */}
            {/* <Grid container spacing={{ xs: 0, sm: 1 }}>
              <Grid item xs={12} sm={6}>
                <Box className="form-group label-field staticProposerModel">
                  <TextField
                    name="stateProvince"
                    label="State/Province"
                    placeholder="Enter your state province"
                    {...register("stateProvince", {
                      required: "state province is required.",
                      // pattern: {
                      //     value: /^[A-Za-z]+$/i,
                      //     message: "Please enter valid state province",
                      // },
                      minLength: {
                        value: 2,
                        message: "Please enter valid state province",
                      },
                    })}
                    autoComplete="on"
                    error={Boolean(errors.stateProvince)}
                    helperText={errors.stateProvince?.message}
                    InputLabelProps={{
                      style: { color: "#fff" }, // Set the label text color to white
                    }}
                    InputProps={{
                      style: { color: "#fff" }, // Set the input text color to white
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className="form-group label-field staticProposerModel">
                  <TextField
                    name="ZIPPostal"
                    label="ZIP/Postal"
                    placeholder="Enter zip postal"
                    inputProps={{
                      maxLength: 8,
                    }}
                    {...register("ZIPPostal", {
                      required: "Please enter your ZIP postal.",
                      pattern: {
                        value: /^\d{5,7}$/,
                        message: "Please enter valid ZIP/Postal.",
                      },
                    })}
                    error={Boolean(errors.ZIPPostal)}
                    helperText={errors.ZIPPostal?.message}
                    onInput={(event) => {
                      event.target.value = event.target.value
                        .replace(/\D/g, "")
                        .slice(0, 7);
                    }}
                    InputLabelProps={{
                      style: { color: "#fff" }, // Set the label text color to white
                    }}
                    InputProps={{
                      style: { color: "#fff" }, // Set the input text color to white
                    }}
                  />
                </Box>
              </Grid>
            </Grid> */}
            <Box className="btn-wrap" mb={{ xs: 2, sm: 4, md: 6 }}>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                fullWidth
                className="btn-rounded btn-large btn-blue-600 btn-text-white"
                type="submit"
              >
                Submit and join waitlist
              </LoadingButton>
            </Box>
            <Button sx={{
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              marginRight: 'auto',
              marginLeft: 'auto',
              marginTop: '-25px',
              '@media screen and (max-width: 600px)': {
                // Styles for screens smaller than 600px (adjust the value as needed)
                width: '100%', // Make the button full width on smaller screens
                marginTop: '0', // Adjust margin top for smaller screens
              },
            }} onClick={goBack}><ArrowBack style={{ marginRight: '13px' }} /> <span>Go Back</span></Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
