export const SplitImage = async (CoverImage, row, col) => {

    const rows = row; // Number of rows to split
    const cols = col; // Number of columns to split
    var pieces_arr = []

    let sourceImage = document.getElementById('sourceImage');

    if (CoverImage) {
        // const canvasContainer = document.getElementById('canvasContainer');

        // Load the image and get its dimensions

        var image = new Image();
        image.src = sourceImage.src;
        //image.src = CoverImage.path;


        // image.onload = () => {
        const { naturalWidth: width, naturalHeight: height } = image;

        // Calculate the width and height of each sub-image
        const subImageWidth = width / cols;
        const subImageHeight = height / rows;

        // Calculate the dynamic column width based on the number of columns and sub-image width
        const columnWidth = `minmax(${subImageWidth}px, 1fr)`;

        // Set the grid-template-columns dynamically
        // canvasContainer.style.gridTemplateColumns = `repeat(${cols}, ${columnWidth})`;

        // Split the image into sub-images and create separate canvases for each sub-image
        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < cols; col++) {
                const x = col * subImageWidth;
                const y = row * subImageHeight;
                const heightRatio = subImageWidth / subImageHeight

                // Create a new div for each canvas
                const canvasDiv = document.createElement('div');
                /*canvasDiv.classList.add('canvasDiv');*/
                canvasDiv.style.gridColumn = col + 1;
                canvasDiv.style.gridRow = row + 1;
                canvasDiv.style.border = '1px solid black';
                canvasDiv.style.width = subImageWidth + 'px'
                //canvasContainer.appendChild(canvasDiv);

                // Create a new canvas for each sub-image
                const canvas = document.createElement('canvas');

                canvas.width = subImageWidth
                canvas.height = subImageHeight;
                canvasDiv.appendChild(canvas);

                // Draw the sub-image on the canvas
                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, x, y, subImageWidth, subImageHeight, 0, 0, subImageWidth, subImageHeight);

                // const blobUrl = await canvas.toBlob(function (blob) {
                //     // var blobUrl = URL.createObjectURL(blob);
                //     // var img = document.getElementById('#parcelImage')
                //     // img.src = blobUrl
                //     // console.log(img)
                //     pieces_arr.push(URL.createObjectURL(blob));
                // });
                // console.log('got image parcel', canvas, sourceImage)
                // canvas.toBlob((blob) => {
                //     console.log(blob)
                //     pieces_arr.push(blob);
                // });
                // let res = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg', 0.8))
                // var blobUrl = URL.createObjectURL(res);
                // pieces_arr.push(res);

                // const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                // const byteArray = Array.from(imageData.data);
                // pieces_arr.push(blob);

                var base64Data = canvas.toDataURL();
                pieces_arr.push(base64Data);
                /*const img = document.createElement('img');
                img.src = base64Data;   
                canvasDiv.appendChild(img);*/

            }
        }
    }
    return pieces_arr;

    // };

    //     useEffect(() => {
    //     // cutImageUp();
    // }
    // )
    // return pieces_arr

}