import React from 'react'
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

const NumericFormatCustomDollar = React.forwardRef(function NumericFormatCustom(props, ref,) {
    const { onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        // name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
            prefix="$"
        />
    );
});

NumericFormatCustomDollar.propTypes = {
    // name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default NumericFormatCustomDollar;