import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, CardHeader, Button, Chip, Modal, Avatar } from '@mui/material';
import "./FiatCryptoCompo.css"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Check, Checks } from '@mui/icons-material';
import ConnectWalletInvestor from '../../Web3/ConnectWalletInvestor';
import PropTypes from 'prop-types'
import { update } from '../../features/auth/authSlice'
import { useDispatch, useSelector } from 'react-redux';


const FiatCryptoCompo = (props) => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.auth.user);

    //Wallet connection code start
    const [walletDialogOpen, setWalletDialogOpen] = useState(false);

    const handleClickOpen = () => {
        setWalletDialogOpen(true);
    };

    const handleCloseDialog = (value) => {
        setWalletDialogOpen(false);
    };

    /**
     * gets called when a wallet is connect successfully 
     * @param {*} acc 
     */
    const walletLogin = async (acc) => {
        dispatch(update({ wallet_address: acc?.toLowerCase() }));
    }

    //Wallet connection code end


    return (
        <>
            <Box className='FiatPayment'>
                <Card className='FiatCryptoPayment'>
                    <CardHeader
                        avatar={
                            <img aria-label="recipe" src={props.image} className='home-avatar-color' />
                        }
                    />
                    <CardContent className='home-content-box' >
                        <Typography className='multi-fund-card-content' >
                            {props.title}
                        </Typography>
                        <Typography className='multi-fund-text' >
                            {props.subtitle}
                        </Typography>
                        {props.title === "Fiat Payment"
                            ?
                            <Button className='btn-rounded btn-blue-100 btn-text-white' endIcon={<KeyboardArrowRightIcon />}>Connect via Stripe</Button>
                            :
                            <>
                                {
                                    userData?.wallet_address?.length > 10 ?
                                        <Chip icon={<Check />} label={`Wallet : ${userData?.wallet_address}`} color="success" />
                                        :
                                        <Button onClick={handleClickOpen} className='btn-rounded btn-blue-100 btn-text-white' endIcon={<KeyboardArrowRightIcon />}>Connect Wallet</Button>
                                }
                            </>
                        }
                    </CardContent>
                </Card>
            </Box>
            <ConnectWalletInvestor open={walletDialogOpen} handleCloseDialog={handleCloseDialog} handleConnectedSuccess={walletLogin} askConfirmation={true} />


        </>
    )
}

FiatCryptoCompo.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    image: PropTypes.string
}

export default FiatCryptoCompo;