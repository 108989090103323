import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ListCard from '../Components/ListCard/ListCard';
import { User } from '../../Components/Images/Images';
import InfoIcon from '@mui/icons-material/Info';
import "./CompanyProfile.css"
function RightCompanyProfileSection() {
  return (
    <Box p={3} className="race-activity-container">
      <Section title={'Race Activity'} icon={true}>
        <Stack direction="row" justifyContent={'space-between'} mb={2} >
          <Typography variant="subtitle2" className="font-12" >Hourly Rate</Typography>
          <Typography variant="subtitle1" className="font-12 text-blueGray-500" >$45.00 - $75.00</Typography>
        </Stack>
        <Stack direction="row" justifyContent={'space-between'} mb={2} >
          <Typography variant="subtitle2" className="font-12" >Minimum project size</Typography>
          <Typography variant="subtitle1" className="font-12 text-blueGray-500" >$1K+</Typography>
        </Stack>
        <Stack direction="row" justifyContent={'space-between'} mb={2} >
          <Typography variant="subtitle2" className="font-12" >Total jobs</Typography>
          <Typography variant="subtitle1" className="font-12 text-blueGray-500" >10</Typography>
        </Stack>
        <Stack direction="row" justifyContent={'space-between'} mb={2} >
          <Typography variant="subtitle2" className="font-12" >Member since</Typography>
          <Typography variant="subtitle1" className="font-12 text-blueGray-500" >May 23, 2019</Typography>
        </Stack>
      </Section>
      <Section title={'Office locations'}>
        <Box ml={-2}>
          <ListCard listIcon={<LocationOnIcon />} type={'icon'} title={'Edinburgh, United Kingdom'} subtitle1={'6:44 am CDT'} subtitle2={'Primary location'} />
        </Box>
      </Section>
      <Section title={'Information'}>
        <Stack direction="row" justifyContent={'space-between'} mb={2} >
          <Typography variant="subtitle2" className="font-12" >Agency size</Typography>
          <Typography variant="subtitle1" className="font-12 text-blueGray-500" >2-10 workers</Typography>
        </Stack>
        <Stack direction="row" justifyContent={'space-between'} mb={2} >
          <Typography variant="subtitle2" className="font-12" >Year founded</Typography>
          <Typography variant="subtitle1" className="font-12 text-blueGray-500" >2016</Typography>
        </Stack>
        <Stack direction="row" justifyContent={'space-between'} mb={2} >
          <Typography variant="subtitle2" className="font-12" >Languages</Typography>
        </Stack>
        <Stack direction="row" spacing={0} mb={2} justifyContent={'space-between'}>
          <Typography variant="subtitle1" className="font-12" >English&nbsp;</Typography>
          <Typography variant="subtitle1" className="font-12 text-blueGray-500" >Native or Bilingual</Typography>
        </Stack>
        <Stack direction="row" spacing={0} mb={2} justifyContent={'space-between'}>
          <Typography variant="subtitle1" className="font-12" >Spanish&nbsp;</Typography>
          <Typography variant="subtitle1" className="font-12 text-blueGray-500" >Conversational</Typography>
        </Stack>
      </Section>
      <Section title={'Service PR'}>
        <Box ml={-2}>
          <ListCard listIcon={User} type={'img'} title={'Arturo JR A.'} subtitle1={'Web Developer'} subtitle2={'100% Job Success'} />
          <ListCard listIcon={User} type={'img'} title={'Arturo JR A.'} subtitle1={'Web Developer'} subtitle2={'100% Job Success'} />
        </Box>
      </Section>
    </Box>
  )
}

export default RightCompanyProfileSection

function Section({ children, title, icon }) {
  return (
    <Box>
      <Stack direction="row" justifyContent={'space-between'}>
        <Typography variant="subtitle1" fontWeight={'500'} className='font-16' mb={2}>{title}</Typography>
        {icon ? <InfoIcon className='info-icon' /> : null}
      </Stack>
      <Box p={2}>
        {children}
      </Box>
    </Box>
  )
}
