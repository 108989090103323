import axios from 'axios';

// base url is passed inside create
const axiosInstance = axios.create(
  {
    baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    }
  }
);

// Export the axiosInstance
export default axiosInstance;