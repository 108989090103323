import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Done } from '@mui/icons-material';
import { Box, Modal, Avatar, Typography, Button, } from "@mui/material";
import { NewLogo } from "../../Components/Images/Images";

const style = {
    position: "relative",
    boxShadow: 24,
    borderRadius: "24px",
    width: "1043px",
};

export default function InvestorRelatedModal({ open, setOpen, isFunds, isSale, paymentMethod }) {
    // Importing necessary hook
    const navigate = useNavigate();

    // Function to handle closing of the modal
    const handleClose = (event, reason) => {
        // Check if the modal is closed due to a backdrop click
        if (reason && reason === 'backdropClick') {
            return; // Do nothing, basically do not close the modal
        };
        // Close the modal
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            className="kyc-modal"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <Box className="modal-body" sx={style}>
                <Box className="headContent">
                    <Box className="logo">
                        <Avatar
                            alt="Logo"
                            src={NewLogo}
                            variant="square"
                            sx={{ width: 39, height: 90 }}
                        />
                    </Box>
                </Box>
                <Box className="modalContentDone" >
                    <Box className="main-content" >
                        <Box className="DoneIcon"><Done className="doneIconSvg" /></Box>
                        <Typography component="h1" className="headText">{isFunds ? "Investment" : "Offer"} Submitted</Typography >
                        <Typography component="p" className="sub-headText">Your {isFunds ? "investment" : "offer"} has been successfully submitted and can now be viewed on your user dashboard's <span style={{ textDecoration: 'underline' }} onClick={() => {
                            navigate('/user/transactions', {
                                state: { tabId: (isSale ? 1 : paymentMethod && isFunds ? 2 : 0) }
                            })
                        }}
                        >My Transactions</span> page.</Typography >
                        <Button onClick={() => {
                            navigate('/user/transactions', {
                                state: { tabId: (isSale ? 1 : paymentMethod && isFunds ? 2 : 0) }
                            })
                        }} className="wallet-button">Return to Dashboard</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}
