import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, } from "chart.js";
import { Line } from "react-chartjs-2";

// Register necessary chart elements and plugins
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

// Chart options configuration
const options = {
  responsive: true,
  maintainAspectRatio: false, // Disable maintaining aspect ratio
  plugins: {
    title: {
      display: true,
    },
  },
  legend: {
    display: true,
  },
  scales: {
    x: {
      display: true,
    },
    y: {
      display: true,
    },
  },
};

// Data configuration for the Line chart
const labels = ["12:00", "13:00", "14:00", "15:00", "16:00", "17:00"];
export const data = {
  labels,
  datasets: [
    {
      label: "",
      fill: true,
      data: ["17", "16", "16", "15", "15", "14"],
      borderColor: "#6A91A9",
      backgroundColor: "#6A91A9",
      tension: 0.5,
    },
  ],
};

// Functional component for rendering the Line chart
const Charts = () => {
  return (
    <div  style={{ width: "100%", height: "330px" }}>
      <Line
        data={data}
        options={options}
        plugins={[
          {
            beforeDraw: (chart) => {
              const ctx = chart.ctx;
              const label = "...";
              const fontSize = 20;
              const x = chart.width - ctx.measureText(label).width - 20; // Adjust the x-position as needed
              const y = 30; // Adjust the y-position as needed

              ctx.font = `${fontSize}px Arial`;
              ctx.fillStyle = "white";
              ctx.fillText(label, x, y);
            },
          },
          {
            beforeDraw: (chart) => {
              const ctx = chart.ctx;
              ctx.font = "16px Arial";
              ctx.fillStyle = "white";
              const label = "Analytics";
              const x = 10; // Adjust the x-position as needed
              const y = 35; // Adjust the y-position as needed
              ctx.fillText(label, x, y);
            },
          },
        ]}
      />
    </div>
  );
};

export default Charts;
