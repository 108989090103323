import { Box, Grid } from "@mui/material";
import "./ExploreProjects.css";

const ExploreProjectInvestor = (props) => {
  return (
    <Grid container spacing={1}>
      <Grid item md={6}>
        <Box className="epCompo" fontSize={"43px"}>
          <span className="invest-in">{props.title}</span>
        </Box>
        <Box className="epCompo-sub">
          {props.subTitle}
          <br />
          {props.subTitle1}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ExploreProjectInvestor;
