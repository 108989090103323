import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { Box, Typography, Button, } from "@mui/material";

// Import custom components for this page
import { NewLogo } from "../../../Components/Images/Images";
import "./Components/Questionnaire.css";

export default function ListingSubmitted() {
    // Creating a navigate function using the 'useNavigate' hook
    const navigate = useNavigate();

    /**
     * Handles the action to navigate back to the dashboard.
     */
    const handleClick = () => {
        navigate("/user/projects", { replace: true });
    };

    return (
        <Box className="projectProposer questionnaire-page">
            <Box className="questionnaire-section last">
                <Box className="auth-left">
                    {/* left-title */}
                    <Box className="quests-left-title">
                        <Typography component="h1" className="title">
                            And you're done!
                        </Typography>
                        <Typography component="p" className="sub-title">
                            Your listing has been SUbmitted for review. You will be
                            <br />
                            notified via email once it is live.
                        </Typography>
                        <Box className="listLive-btn-box">
                            <Box ml={1}>
                                <Button className="btn-rounded btn-outline" onClick={handleClick}>
                                    Return to Dashboard
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="listLive-detail-box"></Box>
                    {/* Logo */}
                    <Box className="auth-logo">
                        <Link to="/">
                            <Box component="img" src={NewLogo} alt="Race logo" />
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
