import React from 'react'
import "./OnboardingTwo.css"
import { Avatar, Box, Button, Grid, Stack, Typography } from '@mui/material'
import { Onboarding2 } from './../../Components/Images/Images'
import Header from '../../Layouts/Header/HeaderServiceProposer'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import FaceIcon from '@mui/icons-material/Face';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ServiceProviderLogo } from './../../Components/Images/Images';

const OnboardingTwo = () => {
    const navigate = useNavigate()

    const handleContinue = () => {
        navigate("/service-provider/onboarding")
    }

    const handleGoBack = () => {
        navigate("/service-provider/onbording1")
    }

    return (
        <Box className="onboarding-two-main ">
            <Box className="logo-onboarding-one">
                <Link to="/">
                    <img src={ServiceProviderLogo} alt="Logo" />
                </Link>
            </Box>
            <Box className="onboarding-two-container">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={9} sm={12}>
                        <Box className="onboarding-left">
                            <Typography component="h4" className='title'>What makes a successful RACE profile</Typography>
                            <Typography component="p" className='sub-title'>Follow these tips to create a profile that will stand out from the crowd on RACE.</Typography>
                        </Box>
                        <Box className="onboading-cards">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4} sm={12} >
                                    <Box pr={3}>
                                        <Box className="icon-div">
                                            <InsertLinkIcon />
                                        </Box>
                                        <Typography component="h4" className='title'>
                                            Add Credibility
                                        </Typography>
                                        <Typography component="p" className='sub-title'>
                                            Increase credibility by linking out to your relevant professional accounts
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4} sm={12} className="mid-box">
                                    <Box className="card-box">
                                        <Box className="icon-div">
                                            <FormatListNumberedRtlIcon />
                                        </Box>
                                        <Typography component="h4" className='title'>
                                            Describe accurately
                                        </Typography>
                                        <Typography component="p" className='sub-title'>
                                            Describe in detail, and accurately, your professional skills
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4} sm={12}>
                                    <Box className="card-box">
                                        <Box className="icon-div">
                                            <FaceIcon />
                                        </Box>
                                        <Typography component="h4" className='title'>
                                            Add your face!
                                        </Typography>
                                        <Typography component="p" className='sub-title'>
                                            Upload a profile picture that clearly shows your face
                                        </Typography>

                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className="onboading-cards onboading-card">
                            <Box className="onboarding-left">
                                <Typography component="h4" className='titles'>What to avoid</Typography>
                                <Typography component="p" className='sub-titles mb-2' >Steer clear of the following to keep in line with our community standards and ensure a safe and positive experience for yourself anf other users.</Typography>
                            </Box>
                            <Box mt={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4} sm={12}>
                                        <Box className="" pr={3}>
                                            <Box className="icon-div">
                                                <CloseIcon />
                                            </Box>
                                            <Typography component="h4" className='title'>
                                                Misleading others
                                            </Typography>
                                            <Typography component="p" className='sub-title'>
                                                Do not provide misleading, inaccurate or false information about yourself
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4} sm={12} className="mid-box">
                                        <Box className="card-box" >
                                            <Box className="icon-div">
                                                <CloseIcon />
                                            </Box>
                                            <Typography component="h4" className='title'>
                                                Duplicate accounts
                                            </Typography>
                                            <Typography component="p" className='sub-title'>
                                                Create more services instead of opening duplicate accounts
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4} sm={12}>
                                        <Box className="card-box" >
                                            <Box className="icon-div">
                                                <CloseIcon />
                                            </Box>
                                            <Typography component="h4" className='title'>
                                                Moving out of RACE
                                            </Typography>
                                            <Typography component="p" className='sub-title'>
                                                Attempt to take communications and payments outside of RACE.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Box mt={5}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Box className='go-back-btn'>
                                    <Button startIcon={<ArrowBackIcon />} onClick={() => handleGoBack()} >
                                        Go Back
                                    </Button>
                                </Box>
                                <Box className="continue-btn">
                                    <Button variant="contained" startIcon={<ArrowForwardIcon />} className='btn-rounded btn-large' style={{ background: "var(--golden-gradient)" }} onClick={() => handleContinue()}>
                                        Continue
                                    </Button>
                                </Box>
                            </Stack>

                        </Box>

                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                        <Box className="onboarding-left" >
                            <img src={Onboarding2} alt='' />
                        </Box>
                    </Grid>

                </Grid>

            </Box>
        </Box>
    )
}

export default OnboardingTwo