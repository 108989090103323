import React, { useEffect, useMemo, useRef, useState } from "react";
import "./Parceling.css";
import { Badge, Box, Button, Chip, FormControl, Grid, IconButton, ImageListItem, InputLabel, ListItemText, MenuItem, Paper, Select, Stack, Typography, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";
import ClearIcon from "@mui/icons-material/Clear";
import ParcelCard from "./ParcelCard";
import ParcelSelectedCard from "./ParcelSelectedCard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useNavigate } from "react-router-dom";
import GridImageView from "./GridImageView";
import { Info, SwapVert, ViewComfy } from "@mui/icons-material";
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import purify from "dompurify";
import { viewListing } from "../../../Components/SmartContract/viewListing";

const sortByOptions = [
  {
    value: "Nasc",
    label: "Name: A-Z",
  },
  {
    value: "Ndsc",
    label: "Name: Z-A",
  },
  {
    value: "Pasc",
    label: "Price: Low to High",
  },
  {
    value: "Pdsc",
    label: "Price: High to Low",
  },
];

export default function ParcelEditPage({
  assetData,
  selectedParcelsArr,
  setSelections,
  hideParcelSelectionPage,
  state
}) {
  const description = JSON.parse(assetData?.asset_description);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const CanvasImageRef = useRef(null);
  const [selectedParcels, setSelectedParcels] = useState(selectedParcelsArr);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [parcelTokenAddress, setParcelTokenAddress] = useState('');
  const [soldParcels, setSoldParcels] = useState(
    assetData?.parcelData
      ?.filter((parcel) => parcel.is_sold)
      ?.map((parcel) => parseInt(parcel.title.split("#")[1])) || []
  );

  /**
   * deselects all the selected parcels
   */
  const handleDelete = () => {
    CanvasImageRef.current?.deselectAllRegions(selectedParcels);
    assetData?.parcelData.forEach((element) => {
      element.selected = false;
    });
    setSelectedParcels([]);
  };

  /**
   * Function for the Tooltip Button Starts
   */
  const handleMouseDown = () => {
    setTooltipOpen(true);
  };

  const handleMouseUp = () => {
    setTooltipOpen(false);
  };

  const handleButtonClick = () => {
    setTooltipOpen(false);
    handleClick();
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  /**
     * Function for the Tooltip Button Ends
     */




  /**
   * submits this page
   * @returns
   */
  const handleClick = () => {
    if (selectedParcels?.length === 0) {
      showSnackbar("Please select any parcel to continue.", "error");
      return;
    }
    hideParcelSelectionPage();
    setSelections(selectedParcels);
  };

  const handleBackBtn = () => {
    // navigate(-1);
    navigate(`/project-details/${state?.assetId}`, { replace: true, state: { from: 'payment-page' } })
  };

  /**
   * adds field selected parcels
   */
  useEffect(() => {
    assetData?.parcelData.forEach((element) => {
      element.selected = selectedParcelsArr.some((p) => p.id === element.id);
    });
  }, [assetData]);

  useEffect(() => {
    if (assetData?.is_onboard && assetData?.listing_id) {
      async function getParcelTokenAddress() {
        try {
          let listing = await viewListing('', assetData);
          if (listing) {
            setParcelTokenAddress(listing?.tokenAddress)
          }
        } catch (error) {

        }
      }
      getParcelTokenAddress()
    }
  }, [assetData?.is_onboard, assetData?.listing_id])

  /**
   * handling sold regions
   */
  useEffect(() => {
    setTimeout(() => {
      CanvasImageRef.current?.handleSetSoldRegions(soldParcels);
    }, 300);
  }, [soldParcels]);

  useEffect(() => {
    if (selectedParcelsArr && selectedParcelsArr.length > 0) {
      setTimeout(() => {
        CanvasImageRef.current?.initialRegionSelection(selectedParcelsArr);
      }, 300);
    }
  }, [selectedParcelsArr]);

  /**
   *  selects parcel from canvas which is called from child component
   * @param {number} parcelNo
   */
  const selectParcelFromCanvas = (parcelNo) => {
    const index = assetData?.parcelData.findIndex(
      (p) => parseInt(p.title.split("#")[1]) === parcelNo
    );
    if (assetData.parcelData && assetData.parcelData[index]) {
      assetData.parcelData[index].selected = true;
    }
    let parcel = assetData?.parcelData[index];
    setSelectedParcels([...selectedParcels, parcel]);
  };

  /**
   * deselects parcel from canvas which is called from child component
   * @param {number} parcelNo
   */
  const deselectParcelFromCanvas = (parcelNo) => {
    const index = assetData?.parcelData.findIndex(
      (p) => parseInt(p.title.split("#")[1]) === parcelNo
    );

    if (assetData.parcelData && assetData.parcelData[index]) {
      assetData.parcelData[index].selected = false;
    }
    let filteredParcels = selectedParcels.filter(
      (p) => parseInt(p.title.split("#")[1]) !== parcelNo
    );
    setSelectedParcels(filteredParcels);
  };

  /**
   * Selects Parcel from the list of parcels
   * @param {Object} parcel
   */
  const selectParcel = (parcel) => {
    const index = assetData?.parcelData.findIndex((p) => p.id === parcel.id);
    if (assetData.parcelData && assetData.parcelData[index]) {
      assetData.parcelData[index].selected = true;
    }
    setSelectedParcels([...selectedParcels, parcel]);
    let parcelNo = parcel.title.split("#")[1];

    CanvasImageRef.current?.selectRegion(parcelNo);
  };

  /**
   * Selects Parcel from the list of parcels
   * @param {Object} parcel
   */
  const deselectParcel = (parcel) => {
    const index = assetData?.parcelData.findIndex((p) => p.id === parcel.id);
    if (assetData.parcelData && assetData.parcelData[index]) {
      assetData.parcelData[index].selected = false;
    }
    let filteredParcels = selectedParcels.filter((p) => p.id !== parcel.id);
    setSelectedParcels(filteredParcels);
    let parcelNo = parcel.title.split("#")[1];
    CanvasImageRef.current?.deselectRegion(parcelNo);
  };

  /**
   * calculates total price of selected parcels
   */
  const calculateTotalPrice = useMemo(() => {
    let sum = selectedParcels.reduce((acc, parcel) => {
      return acc + (parcel.price);
    }, 0);

    if (!Number.isInteger(sum)) {
      // if not a number than
      return parseFloat(sum).toFixed(2);
    } else {
      return sum;
    }
  }, [selectedParcels]);

  const [sortBy, setSortBy] = useState("Nasc");

  /**
   *  sorts the parcels based on the selected option
   * @param {'Nasc'|'Ndsc'|'Pasc'|'Pdsc'} sortBy
   * @returns {void}
   * `Nasc` - Name: A-Z,
   * `Ndsc` - Name: Z-A,
   * `Pasc` - Price: Low to High,
   * `Pdsc` - Price: High to Low,
   */
  const handleSortBy = (sortBy) => {
    let sortedParcels = assetData?.parcelData.sort((a, b) => {
      if (sortBy === "Nasc") {
        return (
          parseInt(a.title.split("#")[1]) - parseInt(b.title.split("#")[1])
        );
      } else if (sortBy === "Ndsc") {
        return (
          parseInt(b.title.split("#")[1]) - parseInt(a.title.split("#")[1])
        );
      } else if (sortBy === "Pasc") {
        return a.price - b.price;
      } else {
        //Pdsc
        return b.price - a.price;
      }
    });

    assetData.parcelData = sortedParcels;
    setSortBy(sortBy);
  };

  // code for show more button
  const [showAll, setShowAll] = useState(false);
  const [initialItemsCount, setInitialItemCount] = useState(9);

  // Slice the array to show only the initial items or all items based on the showAll state
  const displayedItems = showAll
    ? assetData?.parcelData
    : assetData?.parcelData?.slice(0, initialItemsCount);
  /**
   * Function to toggle the showAll state
   */
  const toggleShowAll = () => {
    if (showAll) {
      setInitialItemCount(9);
      setShowAll(!showAll);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else {
      setShowAll(!showAll);
    }
    // else if (initialItemsCount + 9 >= assetData?.parcelData?.length) { setShowAll(!showAll); }
    // else { setInitialItemCount(initialItemsCount + 9); }
  };

  return (
    <Box mt={6} className="payment-sale-investor">
      <Box className="container">
        <Grid container spacing={{ xs: 4, sm: 2 }}>
          <Grid item xs={12} sm={12} md={8}>
            {/* Left Panel */}
            <Paper
              elevation={3}
              sx={{ p: 2, mb: 2 }}
              className="rc-basic-container"
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <IconButton onClick={handleBackBtn} sx={{ color: "#ffffff" }}>
                  <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                  Select Parcels
                </Typography>
              </Stack>
              <Box component={"hr"} className="rc-hr" />
              <Grid mt={1} container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  className="rc-project-details-left-img"
                >
                  <GridImageView
                    ref={CanvasImageRef}
                    selectParcelFromCanvas={selectParcelFromCanvas}
                    deselectParcelFromCanvas={deselectParcelFromCanvas}
                    rows={parseInt(assetData?.number_of_rows)}
                    cols={parseInt(assetData?.number_of_columns)}
                    artImage={`${process.env.REACT_APP_IMAGE_URL}${assetData?.asset_gallery[0]?.images.path}`}
                    soldParcels={soldParcels}
                  />
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box className="rc-project-details-left-data">
                    <Typography variant="h5">
                      {assetData?.asset_title}
                    </Typography>
                    <Typography
                      className="rc-small-secondary-text"
                      variant="body2"
                      gutterBottom
                    >
                      {assetData?.asset_address}
                    </Typography>
                    <Typography sx={{ my: 3 }} variant="h6">
                      {description[0]?.title}
                    </Typography>
                    <Box
                      color={"white"}
                      dangerouslySetInnerHTML={{
                        __html: purify.sanitize(description[0]?.description),
                      }}
                    />
                    <Button
                      className="rc-invest-btn"
                      startIcon={<InfoIcon />}
                      variant="contained"
                      sx={{ mt: 2 }}
                    >
                      Hover over the main image to select parcel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Stack
                mt={2}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                spacing={2}
              >
                <Box className="select-group" ml={1.5}>
                  <FormControl
                    className="select-rounded"
                    sx={{ minWidth: "150px" }}
                    size="small"
                  >
                    <InputLabel id="demo-controlled-open-select-label">
                      Sort By
                      <SwapVert />
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      value={sortBy}
                      onChange={(e) => handleSortBy(e.target.value)}
                      label="Sort By"
                      variant="outlined"
                    >
                      {/* <MenuItem value={0}>All</MenuItem> */}
                      {sortByOptions.map((option, ind) => {
                        return (
                          <MenuItem key={ind} value={option.value}>
                            <ListItemText>{option.label}</ListItemText>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>

                {selectedParcels && selectedParcels.length > 0 && (
                  <Box onClick={handleDelete} style={{ cursor: 'pointer' }}>
                    <Chip
                      className="rc-filter-chip"
                      sx={{ alignItems: "center" }}
                      variant="clear"
                      label={"Clear Selection"}
                      deleteIcon={<ClearIcon style={{ fill: "black" }} />}
                      onDelete={handleDelete}
                    />
                  </Box>
                )}
              </Stack>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {displayedItems?.map((parcel, index) => {
                  return (
                    <Grid item xs={6} sm={4} md={4} key={index}>
                      <ParcelCard
                        card={parcel}
                        select={selectParcel}
                        deselect={deselectParcel}
                        is_onboard={assetData?.is_onboard}
                        parcelTokenAddress={parcelTokenAddress}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              {assetData?.parcelData?.length > initialItemsCount && (
                <Button
                  className="btn-rounded showMore-btn"
                  endIcon={
                    !showAll ? (
                      <Badge
                        badgeContent={initialItemsCount / 9}
                        color="warning"
                        sx={{ marginLeft: "10px", backgroundColor: "#C29377" }}
                      ></Badge>
                    ) : (
                      ""
                    )
                  }
                  onClick={toggleShowAll}
                >
                  {showAll ? "Show Less" : "Show More"}
                </Button>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box
              className="rc-project-details-right"
              container
              direction="column"
              alignItems="stretch"
            >
              <Typography
                className="rc-detail-subtitle"
                variant="subtitle2"
                gutterBottom
              >
                Review selection
              </Typography>
              {selectedParcels?.length === 0 ? (
                <Box className="rc-no-data">
                  <Typography
                    variant="body2"
                    className="rc-small-secondary-text"
                    textAlign={"center"}
                  >
                    Currently, No parcels are selected.
                  </Typography>
                </Box>
              ) : (
                // <Stack justifyContent="center" alignItems="center" spacing={1}>
                <Grid container spacing={1} mt={0}>
                  {selectedParcels.map((parcel, index) => {
                    return (
                      <Grid item xs={12} sm={6} md={12}>
                        <ParcelSelectedCard
                          key={index}
                          card={parcel}
                          deselect={deselectParcel}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                // </Stack>
              )}

              <Box component="hr" className="rc-hr" mb={2} />
              <Box component="p" my={1}>
                Total
              </Box>
              <Box sx={{ flexBasis: "50%", pb: "10px" }}>
                <Typography variant="h5">${calculateTotalPrice}</Typography>
                <Typography className="rc-small-secondary-text" variant="body2">
                  {selectedParcels?.length}/{assetData?.parcelData?.length}{" "}
                  Parcels
                </Typography>
              </Box>
              <Box>
                {/* <Button onClick={handleClick} className='rc-invest-btn' variant='contained' color='primary' startIcon={<AttachMoneyIcon />}>Confirm investment  for ${calculateTotalPrice} </Button> */}
                <Tooltip
                  title={
                    <>
                      <Typography className="font-14">
                        <ul>
                          <li>Review Parcel Selection</li>
                          <li>Ensure you understand the terms of the project.</li>
                          <li>Click the "Confirm Investment" button to complete purchase of the parcels.</li>
                          <li>You’ll be required to sign the parcel purchase agreement through an electronic signature tool.</li>
                          <li>Once signed, the sale process will proceed and the NFTs of purchased parcel will be transferred to your wallet.</li>
                        </ul>
                      </Typography>
                    </>
                  }
                  arrow
                  classes={{ tooltip: 'custom-tooltip', arrow: 'custom-arrow' }}
                  open={tooltipOpen}
                  onClose={handleTooltipClose}
                  onOpen={handleTooltipOpen}
                >
                  <Button
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                    onClick={handleButtonClick}
                    onMouseEnter={handleTooltipOpen}
                    className="rc-invest-btn"
                    variant="contained"
                    color="primary"
                    startIcon={<AttachMoneyIcon />}
                  >
                    Confirm investment for ${calculateTotalPrice}{' '}
                    <Info style={{ color: 'white', marginLeft: '8px' }} />
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
