import { Box } from "@mui/system";
import React from "react";
import WhyRace from "../Components/Common/WhyRace";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import FactCheckIcon from "@mui/icons-material/FactCheck";

function WhyUseRace({ data }) {
  return (
    <Box>
      <Box className="whyRace-section art" py={8}>
        <Box className="container">
          <WhyRace
            title={data.title}
            highlightText={data.highlightText}
            first={data.first}
            second={data.second}
            third={data.third}
            fourth={data.fourth}
            firstIcon={
              <ColorLensOutlinedIcon className="wrReasons-icons-color" />
            }
            secondIcon={
              <ArrowCircleDownIcon className="wrReasons-icons-color" />
            }
            thirdIcon={<FactCheckIcon className="wrReasons-icons-color" />}
            fourthIcon={<TimerOutlinedIcon className="wrReasons-icons-color" />}
            image={data.image}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default WhyUseRace;
