import RealEstateCategoryClass from "./js/RealEstateCategoryClass";
import ArtCategoryClass from "./js/ArtCategoryClass";
import FundsCategoryClass from "./js/FundsCategoryClass";
import WatchCategoryClass from "./js/WatchCategoryClass";
import GoldCategoryClass from "./js/GoldCategoryClass";
import AssetCategoryClass from "./js/AssetCategoryClass";

const LandingPagesFactory = (componentName, categoryId) => {
  switch (componentName) {
    case "realestate":
      return new RealEstateCategoryClass(categoryId);
    case "art":
      return new ArtCategoryClass(categoryId);
    case "funds":
      return new FundsCategoryClass(categoryId);
    case "watch":
      return new WatchCategoryClass(categoryId);
    case "gold":
      return new GoldCategoryClass(categoryId);
    case "car":
      return new AssetCategoryClass(categoryId);
      
    default:
      return new RealEstateCategoryClass(categoryId);
  }
};

export default LandingPagesFactory;