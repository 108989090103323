import React from "react";
import { Button, Box, Grid, Typography, Avatar } from "@mui/material";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import EditIcon from '@mui/icons-material/Edit';
import { useLocation, useNavigate } from "react-router-dom";
import { PROJECT_STATUS } from "../../constants";
import "./ExploreProjects.css";

const ExploreProjects = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const localData = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : "";

  const handleClick = (value) => {
    // navigate(`/investor-listing-${value}`);
    navigate(`/questionnaire-${value}`);
  };

  function handleClickGetStarted() {
    if (localData?.id) {
      navigate("/questionnaire-get-started")
    }
    else {
      navigate("/login")
    }
  }

  const handleClickLearnMore = () => {
    navigate("/dummy");
  };

  const handleClickExplore = () => {
    navigate("/search", { state: { defaultFilter: { category_id: props?.categoryId, status: PROJECT_STATUS.SUBMITTED } } });
  };

  return (
    <Box className="home-exploreBanner-wrap" position={"relative"}>
      <Grid spacing={1} container alignItems={"center"}>
        <Grid item sm={6} xs={12} md={6}>
          <Box pr={{ md: 14, sm: 0 }}>
            <Typography component={"h1"} className="ex-title">
              <Box component={"span"}>{props.title}</Box>{" "}
              <Box component={"span"} className="text-golden">
                {props.highlightText}
              </Box>
            </Typography>
            <Typography className="ex-subtitle" mt={3}>
              {props.subTitle}
            </Typography>
            <Typography className="ex-subtitle" mt={3}>
              {props.subTitle2}
            </Typography>
            <Box className="btn-wrap multi-btns" mt={2}>
              {/*As per figma i changed text Explore project to Get started and ShowChartIcon to EditIcon on 14-07-2023*/}
              {location?.pathname === "/project-proposer" ? (
                <Button
                  className="btn-rounded btn-large btn-golden"
                  startIcon={<EditIcon />}
                  onClick={handleClickGetStarted}
                >
                  Get Started
                </Button>
              ) : (
                <Button
                  className="btn-rounded btn-large btn-golden"
                  startIcon={<ShowChartIcon />}
                  onClick={handleClickExplore}
                >
                  Explore Project
                </Button>
              )}

              {location?.pathname === "/project-proposer" ? (
                <Button
                  className="btn-rounded btn-large btn-text-white btn-blue-600"
                  startIcon={<HelpCenterIcon />}
                  onClick={handleClickLearnMore}
                >
                  Learn More
                </Button>

              ) : (
                <Button
                  className="btn-rounded btn-large btn-text-white btn-blue-600"
                  startIcon={<HelpCenterIcon />}
                  onClick={handleClickLearnMore}
                >
                  How it Works
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item sm={6} xs={12} md={6}>
          <Box className="raceCapital-banner-img">
            <Avatar
              variant="square"
              src={props.image}
              alt="Image"
              sx={{ width: "auto", height: "auto", borderRadius: "24px" }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExploreProjects;
