import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { useAccount, useDisconnect } from "wagmi";
import LoadingButton from '@mui/lab/LoadingButton';
import { Close, ArrowForward, AccountBalanceWallet } from '@mui/icons-material';
import { Box, Grid, Typography, TextField, FormControlLabel, Checkbox, Button, Switch, styled, Stack, Avatar, Modal, Fade } from "@mui/material";
import { googleUserInfo, postApi, } from "../../Api/Api";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import ConnectWalletInvestor from "../../Web3/ConnectWalletInvestor";
import { NewLogo, googleColorFullIcon } from "../../Components/Images/Images";
import { login as userLogin } from "../../features/auth/authSlice";
import { encryptData } from "../../Components/Common/Utils";

// Retrieve the access token from environment variables
let getUrl = process.env.REACT_APP_ACCESS_TOKEN;

// Styled component for a customized Switch component
const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

// Object defining password strength levels and their associated styles
const PasswordStrength = {
  weak: {
    color: '#FF0000',
    strength: 'weak',
    value: 25
  },
  fair: {
    color: '#FFA500',
    strength: 'fair',
    value: 50
  },
  good: {
    color: '#FFFF00',
    strength: 'good',
    value: 75
  },
  strong: {
    color: '#00FF00',
    strength: 'strong',
    value: 100
  }
}

// Object defining styles wallet connect modal
const style = {
  position: "relative",
  boxShadow: 24,
  borderRadius: "24px",
  width: "662px",
  maxHeight: '90vh'

};

export default function Register() {

  // Importing necessary hooks and functions
  const dispatch = useDispatch();
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  // State variables
  const [openModal, setOpenModal] = useState(false); // Modal open/close state
  const [userType, setUserType] = useState(false); // User type state
  const [user, setUser] = useState(""); // User state
  const [passwordShown, setPasswordShown] = useState(false); // Password visibility state
  const [passwordStrength, setPasswordStrength] = useState(PasswordStrength.weak); // Password strength state
  const [profile, setProfile] = useState(""); // Profile state
  const [walletAccount, setWalletAccount] = useState(null); // Wallet account state

  // useEffect to set the wallet address when it is present in the location state
  useEffect(() => {
    if (state?.wallet_address) {
      setWalletAccount(state?.wallet_address);
    }
  }, [state])

  // State variable to manage the wallet connection dialog
  const [walletDialogOpen, setWalletDialogOpen] = useState(false);

  // Function to open the wallet connection dialog
  const handleClickOpen = () => {
    setWalletDialogOpen(true);
  };

  // Function to close the wallet connection dialog
  const handleCloseDialog = (value) => {
    setWalletDialogOpen(false);
  };

  /**
   * Login with wallet
   * @param {string} acc  wallet address
   */
  const walletLogin = async (acc) => {
    try {
      const checkRes = await postApi(`/user/checkWallet`, { wallet_address: acc.toLowerCase() });
      if (checkRes?.data?.code === 200) {
        const loginRes = await postApi(`/user/login/`, { wallet_address: acc.toLowerCase() });
        if (loginRes?.data?.code === 200) {
          setLoading({ ...loading, wallet: true }) // show loader on wallet button
          dispatch(userLogin(loginRes?.data?.data));
          localStorage.setItem("token", loginRes.data.data.token);
          showSnackbar("Logged in with wallet : " + acc, "success");
          setTimeout(() => {
            if (loginRes.data?.data?.kycStatus === false) {
              window.history.replaceState({}, "", "/");
              navigate("/", { state: { from: "/login" } });
            } else { //kyc is done already
              window.history.replaceState({}, "", "/");
              navigate("/")
            }
            setLoading({ ...loading, wallet: false }) // hide loader on wallet button
          }, 1500);

        }

      } else if (checkRes?.data?.code === 201) {
        setOpenModal(true);
      }
    } catch (error) {

    }
  }

  const handleModalCloseAfterWallet = () => {
    setOpenModal(false);
    setTimeout(() => {
      disconnect();
    }, 500);
  }

  const handleGoToSignupAfterWallet = () => {
    setOpenModal(false);
    navigate("/register", { state: { from: "/login", wallet_address: address.toLowerCase() } });
  }
  // Wallet connection code end

  // Loading button
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm();

  // google login
  const registerWithGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => setUser(tokenResponse),
  });

  useEffect(
    () => {
      let access_token = user?.access_token;
      if (access_token) {
        let headers = {
          Authorization: `Bearer ${access_token}`,
          Accept: 'application/json'
        }
        googleUserInfo(getUrl, access_token, headers)
          .then((res) => {
            setProfile(res.data);
          })
          .catch((err) => console.log(err));
      }
    }, [user]);

  if (profile && user?.access_token) {
    const body = {
      first_name: profile?.given_name,
      last_name: profile?.family_name,
      email: profile?.email,
      google_id: profile?.id,
      login_type: "google",
      email_verified: profile?.verified_email ? 1 : 0,
      google_access_token: user?.access_token,
    }
    postApi(`/user/`, body).then((res) => {
      localStorage.setItem("login", true);
      localStorage.setItem("user_data", JSON.stringify(res?.data?.data));
      localStorage.setItem("token", res?.data?.data?.token);
      navigate("/", { state: { from: '/login' } });
    }
    )
  }

  /**
   * sign up / registration.
   * handles form submit with the type checking of userType Entity or Individual
   * @param {*} data  user data from form
   */
  const onSubmit = (data) => {
    let userData = "";

    if (userType) {
      userData = {
        first_name: data.firstName,
        last_name: data.lastName,
        company_name: data.compName,
        credentials: createEncryptedCredentials(data.compEmail, data.password),
        type: "entity",
        wallet_address: walletAccount ?? null,
        email_verified: false,
        login_type: "site",
      };
    } else {
      userData = {
        first_name: data.firstName,
        last_name: data.lastName,
        credentials: createEncryptedCredentials(data.email, data.password),
        type: "individual",
        wallet_address: walletAccount ?? null,
        email_verified: false,
        login_type: "site",
      };
    }


    if (data.password === data.confirm_password) {
      if (userData) {
        setLoading(true);
        postApi(`/user/`, userData).then((res) => {
          setLoading(false);
          if (res?.data?.code === 200) {
            navigate("/otp-verification", { state: { ...res.data, signedWith: walletAccount } });
          } else { // something went wrong user or backend side
            showSnackbar(res?.data?.message, "error");
          }
        });
      }
    } else {
      showSnackbar("Password mismatch.", "error");
    }
  };

  const checkStrength = (password) => {
    let strength = 0;
    if (/[a-z]/.test(password)) {
      strength += 1;
    }
    if (/[A-Z]/.test(password)) {
      strength += 1;
    }
    if (/\d/.test(password)) {
      strength += 2;
    }
    if (/[$@$!%*?&#]/.test(password)) {
      strength += 2;
    }
    if (password.length > 8) {
      strength += 1;
    }
    if (strength <= 2) {
      setPasswordStrength(PasswordStrength.weak)
    }
    else if (strength <= 3) {
      setPasswordStrength(PasswordStrength.fair);
    }
    else if (strength < 7 && password.length > 8) {
      setPasswordStrength(PasswordStrength.good)
    }
    else if (strength === 7 && password.length > 8) {
      setPasswordStrength(PasswordStrength.strong)
    }
  }

  /**
   * Encrypts email and password and then concat
   * @param {string} email 
   * @param {string} pass 
   * @returns {string} encrypted + concatenated credentials
   */
  const createEncryptedCredentials = (email, pass) => {
    const emailEncrypted = encryptData(email);
    const passEncrypted = encryptData(pass);
    // concatenated credentials separated by " ; " 
    const concatenated = `${emailEncrypted};${passEncrypted}`
    return concatenated;
  }

  return (
    <>
      <Box className="register-page auth-page">
        <Box className="auth-section">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Box className="auth-left">
                <Box className="content">
                  <Box className="heading-wrap" textAlign={"left"}>
                    <Typography component="h1" className="title">
                      Supercharged Investments Powered By The Blockchain
                    </Typography>
                    <Typography component="p" className="sub-title text-white">
                      Lower fees • Simple process • Crypto & fiat friendly
                    </Typography>
                  </Box>
                </Box>
                {/* Logo */}
                <Box className="auth-logo">
                  <Link to="/">
                    <Box component="img" src={NewLogo} alt="Race logo" />
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box className="auth-right">
                <Box textAlign={"right"} className="alreadyAc-des">
                  Already have an account? Sign in{" "}
                  <Link component="a" to="/login">
                    here
                  </Link>{" "}
                  <Box component="span" className="icon" ml={1}>
                    <ArrowForward />
                  </Box>
                </Box>

                {/* Start Form */}
                <Box
                  className="auth-form-wrap"
                  component="form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Box className="heading-wrap">
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      useFlexGap
                      flexWrap="wrap"
                      justifyContent="space-between"
                    >
                      <Box className="left">
                        <Typography component="h1" className="title">
                          Welcome to RACE
                        </Typography>
                        <Typography component="p" className="sub-title">
                          Register your account
                        </Typography>
                      </Box>
                      <Box className="largeDark-swich" sx={{ mt: 1 }}>
                        <Typography>Individual</Typography>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              onClick={() => setUserType(!userType)}
                              defaultChecked={userType}
                            />
                          }
                        />
                        <Typography>Entity</Typography>
                      </Box>
                    </Stack>
                  </Box>
                  <Box className="auth-form" mt={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box className="form-group label-field">
                          <TextField
                            autoComplete='given-name'
                            type="text"
                            id="outlined-required"
                            label="First Name"
                            placeholder="Enter your name"
                            {...register("firstName", {
                              required: "First name is required.",
                              pattern: {
                                value: /^[A-Za-z]+$/i,
                                message: "Please enter valid first name",
                              },
                              minLength: {
                                value: 1,
                                message: "Please enter valid first name",
                              },
                            })}
                            error={Boolean(errors.firstName)}
                            helperText={errors.firstName?.message}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box className="form-group label-field">
                          <TextField
                            type="text"
                            id="outlined-required"
                            label="Last Name"
                            name="lastName"
                            placeholder="Enter your surname"
                            {...register("lastName", {
                              required: "Last name is required.",
                              pattern: {
                                value: /^[A-Za-z]+$/i,
                                message: "Please enter valid last name",
                              },
                              minLength: {
                                value: 3,
                                message: "Please enter valid last name",
                              },
                            })}
                            autoComplete="family-name"  // this line for auto-fill
                            error={Boolean(errors.lastName)}
                            helperText={errors.lastName?.message}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    {!userType ? (
                      <Box className="form-group label-field">
                        <TextField
                          id="outlined-required"
                          label="Email Address"
                          type="email"
                          name="email"
                          placeholder="Enter your email address"
                          {...register("email", {
                            required: "Email address is required.",
                            pattern: {
                              value:
                                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                              message: "Please enter valid email address.",
                            },
                          })}
                          autoComplete="email"  // this line for auto-fill
                          error={Boolean(errors.email)}
                          helperText={errors.email?.message}
                        />
                      </Box>
                    ) : (
                      <Box>
                        <Box className="form-group label-field">
                          <TextField
                            id="outlined-required"
                            label="Company Name"
                            placeholder="Enter your company name"
                            type="text"
                            name="compName"
                            {...register("compName", {
                              required: "Company name is required.",
                            })}
                            autoComplete="organization"  // this line for auto-fill
                            error={Boolean(errors.compName)}
                            helperText={errors.compName?.message}
                          />
                        </Box>

                        <Box className="form-group label-field">
                          <TextField
                            id="outlined-required"
                            label="Company Email Address"
                            placeholder="Enter your company email address"
                            type="email"
                            name="compEmail"
                            {...register("compEmail", {
                              required: "Company email address is required.",
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message: "Please enter valid email address.",
                              },
                            })}
                            autoComplete="email"  // this line for auto-fill
                            error={Boolean(errors.compEmail)}
                            helperText={errors.compEmail?.message}
                          />
                        </Box>
                      </Box>
                    )}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box className="form-group label-field" mb={2}>
                          <TextField
                            // autoComplete="off"
                            id="outlined-required"
                            label="Set Password"
                            type={passwordShown ? 'text' : 'password'}
                            name="password"
                            onCopy={(e) => e.preventDefault()}
                            onPaste={(e) => e.preventDefault()}
                            placeholder="Enter a password"
                            {...register("password", {
                              required: "Password is required.",
                              pattern: {
                                value:
                                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^+#])[A-Za-z\d@$!%*?&^+#]{8,}$/,
                                message:
                                  "Should contain digit, lowercase letter, uppercase letter, and special character",
                              },
                              minLength: {
                                value: 8,
                                message: "You need to add minimum 8 character",
                              },
                              onChange: (e) => {
                                // Add any additional logic you want to perform on change
                                checkStrength(e.target.value);
                              },
                            })}
                            autoComplete="new-password"  // this line for auto-fill
                            error={Boolean(errors.password)}
                            helperText={errors.password?.message}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box className="form-group label-field" mb={1}>
                          <TextField
                            id="outlined-required"
                            label="Confirm Password"
                            type={passwordShown ? 'text' : 'password'}
                            name="confirm_password"
                            placeholder="Confirm password"
                            onCopy={(e) => e.preventDefault()}
                            onPaste={(e) => e.preventDefault()}
                            {...register("confirm_password", {
                              required: "Confirm password is required.",
                            })}
                            autoComplete="new-password"  // this line for auto-fill
                            error={Boolean(errors.confirm_password)}
                            helperText={errors.confirm_password?.message}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box className="form-group" textAlign={"right"} mb={3}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Show Password"
                        sx={{ mr: 0 }}
                        className="checkbox-dark"
                        onChange={() => setPasswordShown(!passwordShown)}
                      />
                    </Box>
                    <Box className="btn-wrap">
                      <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        type="submit"
                        fullWidth
                        className="btn-rounded btn-large btn-blue-600"
                      >
                        Get Started
                      </LoadingButton>
                    </Box>
                  </Box>

                  {
                    walletAccount && walletAccount?.length > 10 ?
                      <>
                        <Box className="signup-btns" sx={{ my: 5 }}>
                          <Typography className="sub-title text-center">Connected with wallet : {walletAccount}  </Typography>
                        </Box>
                      </> :
                      <>
                        <Box className="or-divider" sx={{ my: 5 }}>
                          or
                        </Box>
                        <Box className="signup-btns">
                          <Button
                            variant="contained"
                            component="a"
                            to="/"
                            fullWidth
                            className="blueLite-btn"
                            sx={{ mr: 1.5 }}
                            startIcon={<Avatar alt="Google Icon" src={googleColorFullIcon} sx={{ width: '24px', height: '24px' }} />}
                            onClick={() => registerWithGoogle()}
                          >
                            Sign In with google
                          </Button>
                          <Button
                            variant="contained"
                            component="a"
                            to="/"
                            fullWidth
                            className="blueLite-btn"
                            onClick={handleClickOpen}
                            startIcon={<AccountBalanceWallet sx={{ width: '24px', height: '24px' }} />}
                          >
                            Sign In with Wallet
                          </Button>
                        </Box>
                      </>
                  }

                  <Box
                    className="authAgree-des"
                    textAlign={"center"}
                    sx={{ mt: 4 }}
                  >
                    By signing up I agree to RACE’s{" "}
                    <Link component="a" to="/terms-and-conditions">
                      Terms of Service
                    </Link>{" "}
                    and{" "}
                    <Link component="a" to="/privacy-policy">
                      Privacy Policy
                    </Link>
                    .
                  </Box>
                </Box>
                {/* End Form */}
              </Box>
            </Grid>
          </Grid >
        </Box >
      </Box >

      <ConnectWalletInvestor open={walletDialogOpen} handleCloseDialog={handleCloseDialog} handleConnectedSuccess={walletLogin} userRegister={true} />


      <Modal
        open={openModal}
        className="connectWallet-alert-modal"
        onClose={handleModalCloseAfterWallet}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={openModal} timeout={300}>
          <Box className="modal-body" sx={style}>
            <Box className="headContent">
              <Box className="logo">
                <Avatar
                  alt="Logo"
                  src={NewLogo}
                  variant="square"
                  sx={{ width: 39, height: 90 }}
                />
              </Box>

              <Close className="close-btn" onClick={handleModalCloseAfterWallet} />
            </Box>
            <Box className="modalContent " >
              <Box className="main-content" >
                <Typography component="h1" className="headText">Sign up Please!</Typography >
                <Typography component="p" className="sub-headText">It seems like account : {address && address.slice(0, 6) + '...' + address.slice(-4)} is not registered with us. Please sign up first.</Typography >
                <Box className="modalContent-btn-box" >
                  <Button className="wallet-button prev" onClick={handleGoToSignupAfterWallet} >Continue to Sign up</Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}