import React, { useState, useEffect, useCallback } from "react";
import { Box, Grid, Pagination } from "@mui/material";
import { useLocation } from "react-router-dom";
import Card from "../Components/MainCard/MainCard";
import Filter from "../Components/Filters/Filter";
import ServicesSearch from "../Components/ServicesSearch/ServicesSearch";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { GetApiParam } from "../../Api/Api";
import { getUserDataFromLocalStorage } from "../../Services/localstorage.service";

const SearchProjectResults = () => {
  const location = useLocation();
  const [selectedCategoryData, setSelectedCategoryData] = useState({ title: 'All' });
  const [servicesData, setServicesData] = useState([])
  const [categoryData, setCategoryData] = useState(null);
  const loginUserData = getUserDataFromLocalStorage();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [paginationData, setPaginationData] = useState({
    "page": 1,
    "pageSize": 5,
    "totalCount": 0,
    "totalPages": 0
  });

  const [searchString, setSearchString] = useState(location.state?.search ? location.state?.search : '');
  const [filter, setFilter] = useState({
    "priceRangeStart": null,
    "priceRangeEnd": null,
    "location": null,
    "rating": null,
    "sort": null,
    "savedRequest": null,
  });

  useEffect(() => {
    // Api to get categoryData
    async function getCategoryData() {
      try {
        const res = await GetApiParam("/services/spCategory", { user_id: loginUserData?.id });
        setCategoryData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
    getCategoryData();
  }, []);

  const getServicesData = useCallback(async () => {
    try {
      let payload = { login_user_id: loginUserData?.id };
      if (selectedCategoryData && selectedCategoryData?.id) {
        payload = {
          ...payload,
          sp_category_id: selectedCategoryData?.id
        }
      }
      if(filter?.savedRequest === "savedRequest"){
        payload = {
          ...payload,
          savedRequest: true
        }
      }
      payload = {
        ...payload,
        searchString: searchString
      }
      if(filter?.sort && filter?.sort !== 'all'){
        payload = {
          ...payload,
          sort: filter?.sort
        }
      }
      if(filter?.location && filter?.location !== 'all'){
        payload = {
            ...payload,
            location: filter?.location
        }
      }
      if(filter?.rating && filter?.rating !== 'all'){
        payload = {
            ...payload,
            rating: filter?.rating
        }
      }
      if(filter?.rating === 0){
        payload = {
          ...payload,
          notRated: true
        }
      }
      if((filter?.priceRangeStart || filter?.priceRangeStart === 0) && (filter?.priceRangeEnd || filter?.priceRangeEnd === 0)){
          payload = {
            ...payload,
            priceRangeStart: filter?.priceRangeStart,
            priceRangeEnd: filter?.priceRangeEnd
          }
      }
      const res = await GetApiParam(`/services/getServices?page=${currentPage}&rowperpage=${itemsPerPage}`, payload);
      setServicesData(res?.data?.data?.length ? res.data.data : []);
      setPaginationData(res?.data?.pagination)
    } catch (error) {
      console.log(error);
    }
  });

  useEffect(() => {
    // Api to get service data
    getServicesData();
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    // Api to get service data
    getServicesData();
  }, [selectedCategoryData, filter, searchString]);

  useEffect(() => {
    setSearchString(location.state?.search ? location.state?.search : '');
  }, [location.state])

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const fetchServicesAccordingToCategory = (selectedCategory) => {
    setSelectedCategoryData(selectedCategory);
  }
  const handleSearchResults = (searchInput) => {
    setSearchString(searchInput);
  }
  return (
    <Box className="container">
      <Box className="home-exploreBanner-section ">

        <Box mb={3} pl={{xs:0,md:4,}} px={{md:0, xs:2}}>
          <ServicesSearch 
            page={'searchServices'}
            showSearchBar={true}
            categoryData={categoryData}
            fetchServicesAccordingToCategory={fetchServicesAccordingToCategory}
            handleSearchResults={handleSearchResults}
            searchString={searchString}
          />
        </Box>

        {/* Filters */}
        <Box mb={2}>
          <Filter 
            title={selectedCategoryData?.title === 'Technology Developers & Software Services' ? 'Development & IT' : selectedCategoryData?.title}
            totalServices={paginationData?.totalCount}
            filter={filter}
            setFilter={setFilter}
          />
        </Box>

        {/* Main-card -Top Rated*/}
        <Box className='' mx={1}>
          <Grid container spacing={1}>
            {servicesData && servicesData.length > 0 && servicesData?.map((card, index) => (
              <Grid key={index} item md={3} sm={6} xs={12}>
                <Card {...card} />
              </Grid>
            ))}
          </Grid>
          <Box className="pagination-container">
            <Pagination
              count={paginationData.pageSize === 'all' ? 1 : Math.ceil(paginationData?.totalCount / Number(paginationData.pageSize))}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default SearchProjectResults;
