import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Select,
  Stack,
  Modal,
  MenuItem,
  TextField,
  Grid,
  IconButton
} from "@mui/material";
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from "@mui/icons-material/Save";
import "./EditLanguagePopup.css";
import { GetApi, updateApi } from "../../../Api/Api";
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";

const EditLanguagePopup = ({ open, handleClose, profileData,getProfileData, handleDataUpdate }) => {
  const { showSnackbar } = useSnackbar();
  const localData = getUserDataFromLocalStorage();
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [languagesData, setLanguagesData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showProficiencyDropdown, setShowProficiencyDropdown] = useState(true); // Initially set to false
  const proficiencyOptions = ['Native / Bilingual', 'Intermediate', 'Proficient'];

  const handleSelectLanguage = (language) => {
    if (!selectedLanguages.some(lang => lang.language === language)) {
        setSelectedLanguages([...selectedLanguages, { language: language, proficiency: '' }]);
        setSearchTerm('');
    }
};

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectProficiency = (index, proficiency) => {
    const newLanguages = [...selectedLanguages];
    newLanguages[index].proficiency = proficiency;
    setSelectedLanguages(newLanguages);
  };

  const handleDeleteLanguage = (index) => {
    const newLanguages = [...selectedLanguages];
    newLanguages.splice(index, 1);
    setSelectedLanguages(newLanguages);
  };

  useEffect(() => {
    setShowProficiencyDropdown(new Array(selectedLanguages?.length).fill(true));
  }, [selectedLanguages]);

  /**
    * Setting Data into the field in case when user comes back from the step three 
    */
  useEffect(() => {

    if (handleDataUpdate) {
      setSelectedLanguages(handleDataUpdate)
    }
  }, [handleDataUpdate])

  /**
     * Calling Api for the Languages
     */
  useEffect(() => {
    // Api to get categoryData
    async function getLanguagesData() {
      try {
        const res = await GetApi("/admin/language");
        setLanguagesData(res?.data?.data ? res.data.data : []);
      } catch (error) {
        console.log(error);
      }
    }
    getLanguagesData();
  }, []);

  /**
    * Function to handle Edit Button functionality
     * @param {*} index 
     */
  // const handleEditProficiency = (index) => {
  //   setShowProficiencyDropdown((prev) => {
  //     const newState = [...prev];
  //     newState[index] = !newState[index]; // Toggle the state
  //     return newState;
  //   });
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    const languagesJson = JSON.stringify(selectedLanguages);
    let updateData = new FormData();
    updateData.append("user_id", localData.id);
    updateData.append("language", languagesJson);
    updateApi(`/services/updateProfile/${profileData.id}`, updateData).then((res) => {
      if (res?.status === 200) {
        showSnackbar('Updated Successfully', 'success');
        getProfileData()
        handleClose()
      } else { // something went wrong user or backend side
        showSnackbar(res?.data?.message, 'error');
        handleClose()
      }
    })
  };

  /**
     * Rendring Language row list
     */
  const LanguageRow = React.memo(({ language, index }) => {
    return (
      <Box className="list-data">
        <Box className="data">
          <Typography className='lang-name'>{language?.language}</Typography>
          <Box className="select-lang">
            {showProficiencyDropdown[index] ? (
              <Select
                value={language?.proficiency}
                onChange={(e) => handleSelectProficiency(index, e.target.value)}
                className="proficiency-select"
                displayEmpty
              >
                <MenuItem value="" >Select Proficiency</MenuItem>
                {proficiencyOptions.map((proficiency, index) => (
                  <MenuItem key={index} value={proficiency}>
                    {proficiency}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Typography className={language?.proficiency ? "edit-lang" : ""}>{language?.proficiency}</Typography>
            )}
          </Box>
          <Box className="icons">
            <Box sx={{ width: "100%", display: 'flex' }}>
              <DeleteIcon className='data-icon' onClick={() => handleDeleteLanguage(index)} />
            </Box>

          </Box>
        </Box>
      </Box>
    );
  }, (prevProps, nextProps) => {
    return prevProps.language === nextProps.language;
  });

  return (
    <Box className="add-education-popup-main">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-main"
      >
        <Box
          id="education-scroller-wrapper"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            borderRadius: "24px",
            width: "900px",
            padding: "52px",

            background: "var(--blueGray-900)",
            boxShadow:
              "0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.15)",
            // Media query for small devices
            "@media (max-width: 600px)": {
              width: "350px", // Set a different height for small screens
            },
            // Additional media queries for other screen sizes if needed
            "@media (min-width: 601px) and (max-width: 900px)": {
              // Custom styles for devices between 601px and 900px width
              width: "580px",
            },
            "@media (min-width: 901px) and (max-width: 1200px)": {
              // Custom styles for devices between 901px and 1200px width
              width: "750px",
              maxHeight: "300px",
              overflow: "auto",
            },
            // Specify the maximum width for large screens
            "@media (min-width: 1201px)": {
              maxWidth: "1043px",
              maxHeight: "525px",
              overflow: "auto",
            },
          }}
        >
          <Box className="close-icon-main">
            <IconButton className='close-btn' onClick={handleClose}>
                <CloseIcon className="cross-icon-modal"/>
            </IconButton>
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            mt={2}
            className="heading font-36"
            sx={{ textAlign: "center" }}
          >
            Edit Language
          </Typography>
          <form onSubmit={handleSubmit}>

            {/* Language Section */}
            <Box className="proff-info-box search-lang" mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography className='font-22 title'>
                    Languages
                  </Typography>
                  <Typography className='font-12 sub-title'>English is a must, but do you speak any other languages?</Typography>
                </Grid>
                <Grid item xs={9}>
                  <TextField id="outlined-basic" label="Search languages" className="search-lang" InputLabelProps={{ shrink: true }} variant="outlined" placeholder='E.g. English' onChange={handleSearch} value={searchTerm} />
                  <Box mb={2}>
                    {searchTerm && (
                      <Box mt={2} className="search-list">
                        {languagesData.length && languagesData
                          .filter((language) => language.language_name
                            .toLowerCase().includes(searchTerm.toLowerCase()))
                          .map((language) => (
                            <MenuItem className='onboarding-menuItem' key={language.id} onClick={() => handleSelectLanguage(language.language_name)}>
                              <Typography>{language.language_name}</Typography>
                            </MenuItem>
                          ))}
                      </Box>
                    )}

                  </Box>
                  <Box mt={2}>
                    <Box className="header">
                      <Box className="header-heading">
                        <Typography className='font-14'>Language</Typography>
                      </Box>
                      <Box className="header-heading">
                        <Typography className='font-14'>Proficiency</Typography>
                      </Box>
                      <Box className="header-heading action">
                        <Typography className='font-14 '>Action</Typography>
                      </Box>
                    </Box>
                    <Box>
                      {selectedLanguages?.map((language, index) => (
                        <LanguageRow
                          key={`${language?.language}-${index}`}
                          language={language}
                          index={index}
                        />
                      ))}
                    </Box>

                  </Box>

                </Grid>
              </Grid>
              {validationErrors.selectedLanguages && <Typography variant="body2" color="error">{validationErrors.selectedLanguages}</Typography>}
            </Box>

            <Box mt={3} className="btn-container">
              <Stack spacing={2} direction="row" justifyContent="center">
                {/* <Button variant="outlined" className='cancel-btn' onClick={handleClose}>Cancel</Button> */}
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  sx={{
                      background: "var(--golden-gradient)",
                      color: "var(--blueGray-900)",
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default EditLanguagePopup;
