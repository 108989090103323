import CategoryHeader from "../CategoryHeader";
import ReachInvestors from "../../Components/Common/ReachInvestors";
import { Box } from "@mui/material";
import { RealEstate as RSImage, Fractional } from "../../Components/Images/Images";
import NewestListings from "../NewestListings";
import TopInvestments from "../TopInvestments";
import TypeSlider from "../TypeSlider";
import WhyInvest from "../../Components/Common/WhyInvest";
import ExploreFractionalizationCompo from "../ExploreFractionalization";
export default class RealEstate {
  constructor() { }

  type = "Real Estate";

  pageComponenets = []; //push all relevant componenets here

  headerData = {
    title: "Invest in Real Estate Through the Power of the",
    highlight: " Blockchain",
    subtitle:
      "Tokenize your producing asset through the power of the Blockchain, get access to investors around the world through RACE ",
    image: RSImage,
  };

  fractionalizationData = {
    title: "You can now own a",
    highlightText: "small piece",
    title1: "of the pie",
    subTitle:
      "RACE's tokenisation methods allow investorsto invest in the portion of the project, however much you're comfortable with",

    learnMoreLink: "/dummy",
    // extra: "What’s the difference between a parcel and a fraction?",
    extraLink: "/dummy",
    image: Fractional,
    exploreFractionalization: true,
    exploreParceling: false,
  };

  setPageComponents() {
    this.pageComponenets.push(<CategoryHeader categoryId={1} data={this.headerData} />);
    this.pageComponenets.push(
      <NewestListings categoryId={1} type="Real Estate" />
    );
    this.pageComponenets.push(
      <TopInvestments categoryId={1} type="Real Estate" />
    );
    this.pageComponenets.push(
      <TypeSlider
        sliderData={this.typeSliderData}
        sliderTitle={"Real Estate Types"}
        categoryId={1}
      />
    );
    this.pageComponenets.push(
      <>
        <Box className="WhyInvest-section" py={8}>
          <Box className="container">
            <WhyInvest />
          </Box>
        </Box>
      </>
    );
    this.pageComponenets.push(
      <ExploreFractionalizationCompo
        categoryId={1}
        investment_type_id={4}
        data={this.fractionalizationData}
        type="Real Estate"
      />
    );

    this.pageComponenets.push(<Box className='container'><ReachInvestors /></Box>);
  }
}
