import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

const WaveButton = styled(Button)({
    position: 'relative',
    width: '50px', // Adjust size as needed
    height: '50px',
    borderRadius: '50%',
    overflow: 'hidden',
    padding: 0,
    backgroundColor: '#2196f3',
    minWidth: '50px',
    color: '#000',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none',
    border: '2px solid #2196f3',
    cursor: 'default',
    '&:hover': {
        backgroundColor: '#1976d2',
    },
    '& .label': {
        position: 'relative',
        zIndex: 2, // Keep label above the wave
    }
});

const WaveEffect = styled('div')(({ waterLevel }) => ({
    position: 'absolute',
    width: '200%',
    height: '200%',
    top: `${100 - waterLevel}%`, // Fill from top based on waterLevel
    left: '-50%',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    borderRadius: '40%',
    animation: 'wave-animation 2s infinite ease-in-out',
    zIndex: 1,
    '@keyframes wave-animation': {
        '0%': { transform: 'translateX(-25%) translateY(0)' },
        '50%': { transform: 'translateX(-30%) translateY(-5%)' },
        '100%': { transform: 'translateX(-25%) translateY(0)' },
    }
}));

export default function CircleWaveButton({ waterLevel }) { // Default level 75%
    return (
        <WaveButton>
            <WaveEffect waterLevel={waterLevel} />
            <div className="label">{waterLevel}%</div>
        </WaveButton>
    );
}
