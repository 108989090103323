import React from 'react';
import { Box, Typography, Button, Grid, Chip, Tooltip } from "@mui/material";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import imgBg from "../../../../Assets/Images/service-provider/img-card.png";
import { fetchAndDownloadFile } from '../../../../Utils/utils';

export default function RequirementsJob({ contractData }) {
  return (
    <>
      <Box className="race-primary-card">
        <Box className="race-primary-card-body">
          <Box>
            <Typography className="font-16">
              {contractData?.title}
            </Typography>
            <Typography className="font-12 text-blueGray-300">
              {contractData?.describe_project}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography className="font-16">Category</Typography>
            <Typography className="font-12 text-blueGray-300">
              {contractData?.projectDetails?.categoryDetails?.title ?? ''}
            </Typography>
          </Box>
          <Box mt={2} className="skill-chip">
            <Typography className="font-16">
             Skills
            </Typography>
            {contractData?.projectDetails?.skills && contractData?.projectDetails?.skills.length > 0 ? contractData?.projectDetails?.skills.map((row)=>(
              <Chip
                icon={<AddIcon style={{color: "#ffffff"}}/>} 
                sx={{
                   marginTop: 2,
                  marginRight: 2,
                  color: "#fff",
                  bgcolor: '#07487F',
                  borderRadius: "8px",
                }}
                label={row.skill_name}
              />
            )) : contractData?.projectDetails?.skillData && contractData?.projectDetails?.skillData.length > 0 && contractData?.projectDetails?.skillData.map((row)=>(
              <Chip
                icon={<AddIcon style={{color: "#ffffff"}}/>} 
                sx={{
                   marginTop: 2,
                  marginRight: 2,
                  color: "#fff",
                  bgcolor: '#07487F',
                  borderRadius: "8px",
                }}
                label={row.skill_name}
              />
            ))}
          </Box>
          <Box mt={2}>
            <Typography className="font-16">Scope</Typography>
            <Typography className="font-12 text-blueGray-300">
              {contractData?.projectDetails?.project_size ?? ''}, {contractData?.projectDetails?.work_time ?? ''}, {contractData?.projectDetails?.level_of_experience ?? ''} level
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography className="font-16">Budget</Typography>
            <Typography className="font-12 text-blueGray-300">
              ${contractData?.projectDetails?.budget_from} - ${contractData?.projectDetails?.budget_to} {contractData?.projectDetails?.budget === 'Hourly Rate' ? ('/hr'):('')}
            </Typography>
          </Box>
          {/* <Box mt={2}>
            <Button variant="contained" startIcon={<FactCheckIcon />} className="race-btn-sky-blue">
              All requirements provided
            </Button>
          </Box>
          <Box mt={2}>
            <Typography className="font-12 text-blueGray-300">
              Only click the button here if all requirements have been provided by the client. To request for more information, contact the client by <Link className="font-12 text-blueGray-300 text-underline">sending a message</Link>.
            </Typography>
          </Box> */}
          <Box mt={2}>
            <Grid container spacing={1} >
              {contractData && contractData?.documents && contractData?.documents.length > 0 && contractData?.documents.map((row)=>(
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Box className="download-img-card">
                    <Box className="download-img-wrapper">
                      <img src={imgBg} alt="" />
                    </Box>
                    <Box className="img-box-bottom">
                      <Box className="left-div">
                        <Typography className="font-16">{row?.original_name}</Typography>
                        {/* <Typography className="font-12 text-blueGray-300">
                          100MB
                        </Typography> */}
                      </Box>
                      <Box className="right-div">
                        {/* <img src={downloadIcon} alt="" /> */}
                        <Tooltip title={'download'} arrow>
                          <DownloadIcon style={{ color: '#10B981', cursor: 'pointer' }} onClick={()=>{
                            fetchAndDownloadFile(row.path, row.original_name);
                          }}/>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
              {/* {contractData && contractData?.projectDetails && contractData?.projectDetails?.sp_media_tables && contractData?.projectDetails?.sp_media_tables?.length > 0 && contractData?.projectDetails?.sp_media_tables?.map((row)=>(
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Box className="download-img-card">
                    <Box className="download-img-wrapper">
                      <img src={imgBg} alt="" />
                    </Box>
                    <Box className="img-box-bottom">
                      <Box className="left-div">
                        <Typography className="font-16">{row?.original_name}</Typography>
                      </Box>
                      <Box className="right-div">
                        <Tooltip title={'download'} arrow>
                          <DownloadIcon style={{ color: '#10B981', cursor: 'pointer' }} onClick={()=>{
                            fetchAndDownloadFile(row.path, row.original_name);
                          }}/>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))} */}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  )
}
