import React from 'react'
import PropTypes from 'prop-types'
import { Box, Stack, Typography } from '@mui/material'

export default function DealTerms({ dealTerms, editable, fromPage }) {
    return (
        <>
            <Box className="pd-sidebar-dealTerms">
                <Stack className="pd-sidebar-heading" direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <Typography className="title" component={'h4'}>Deal Terms</Typography>

                </Stack>
                {fromPage && fromPage === "llcDetails" ?
                    <Box className="pd-sidebar-detailsList" mt={0.5}>
                        {
                            dealTerms?.map((item, index) => {
                                if (item.value) {
                                    let detailsData = []
                                    if (item.title === "Type of Capital") {
                                        detailsData = JSON.parse(item.value) || []
                                    } else {
                                        detailsData.push(item.value)
                                    }
                                    return (
                                        <Stack className="detailsList" direction="row" spacing={1} justifyContent="space-between" alignItems="center" key={index}>
                                            <Typography className='title'>{item.title}</Typography>
                                            <Box sx={{ marginLeft: '32px !important', textAlign: 'right' }}>
                                                {
                                                    detailsData?.map((items, index) => (
                                                        <React.Fragment key={index}>
                                                            <Box component={"span"} className='subtitle'>{items}</Box>
                                                            {index < detailsData.length - 1 && ', '}
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </Box>
                                        </Stack>
                                    )
                                }
                            })
                        }
                    </Box>
                    :

                    fromPage && fromPage === "fundDetails" ?
                        <Box className="pd-sidebar-detailsList" mt={0.5}>
                            {
                                dealTerms?.map((item, index) => {
                                    let detailsData = []
                                    if (item.value) {
                                        if (item.title === "No. of Share Classes") {
                                            detailsData = item?.value?.replace(/"/g, '')
                                            return (
                                                <Stack className="detailsList" direction="row" spacing={1} justifyContent="space-between" alignItems="center" key={index}>
                                                    <Typography className='title'>{item.title}</Typography>
                                                    <Box>
                                                        <Typography className='subtitle' key={index}>{`${item.num_of_class} ${item.num_of_class > 1 ? "classes" : "class"}`}</Typography>
                                                    </Box>
                                                </Stack>
                                            )
                                        }

                                        else {
                                            detailsData.push(item.value)
                                            return (
                                                <Stack className="detailsList" direction="row" spacing={1} justifyContent="space-between" alignItems="center" key={index}>
                                                    <Typography className='title'>{item.title}</Typography>
                                                    <Box>
                                                        {
                                                            detailsData?.map((items, index) => {
                                                                return <Typography className='subtitle' key={index}>{items}</Typography>
                                                            })
                                                        }
                                                    </Box>
                                                </Stack>
                                            )
                                        }
                                    }
                                })
                            }
                        </Box>
                        :

                        <Box className="pd-sidebar-detailsList" mt={0.5}>
                            {
                                dealTerms?.map((item, index) => {
                                    return (
                                        <Stack className="detailsList" direction="row" spacing={1} justifyContent="space-between" alignItems="center" key={index}>
                                            <Typography className='title'>{item?.title}</Typography>
                                            <Typography className='subtitle'>{item?.value}</Typography>
                                        </Stack>
                                    )
                                })
                            }
                        </Box>
                }

            </Box>
        </>
    )
}

DealTerms.propTypes = {
    editable: PropTypes.bool,
    dealTerms: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string || PropTypes.number,
    }))
}
