import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useGoogleLogin } from "@react-oauth/google";
import LoadingButton from '@mui/lab/LoadingButton';
import { Close, ArrowForward, VisibilityOff, Visibility, ArrowBack, AccountBalanceWallet, PhoneIphone } from '@mui/icons-material';
import { Box, Grid, Typography, TextField, InputAdornment, Switch, IconButton, Button, Avatar, Modal, Fade, MenuItem, Autocomplete, FormControlLabel, } from "@mui/material";
import { googleUserInfo, postApi, } from "../../Api/Api";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { NewLogo, LogoInvestor, googleColorFullIcon } from "../../Components/Images/Images";
import { encryptData } from "../../Components/Common/Utils";
import { useDispatch } from "react-redux";
import { useDisconnect, useAccount } from "wagmi";
import ConnectWalletInvestor from "../../Web3/ConnectWalletInvestor";
import { login as userLogin } from "../../features/auth/authSlice";
import useAuth from "../../Contexts/useAuth";
import { useSocket } from "../../Contexts/SocketContext";
import Flag from 'react-world-flags';
import { isSupportedCountry, isValidPhoneNumber } from "libphonenumber-js";

//country code file
import CountryCodes from "../../Data/countries.json"
import styled from "@emotion/styled";
const topCountries = ['US', 'UK', 'CA']; // Example ISO codes for top countries

const country_codes = CountryCodes;
// Function to sort countries with US, UK, CA in a specific order, then alphabetically
const sortCountries = (countries) => {
    return countries.sort((a, b) => {
        const aIndex = topCountries.indexOf(a.iso);
        const bIndex = topCountries.indexOf(b.iso);

        // Prioritize US > UK > CA
        if (aIndex !== -1 && bIndex !== -1) {
            return aIndex - bIndex;
        }
        if (aIndex !== -1) return -1;
        if (bIndex !== -1) return 1;

        // Alphabetically sort the rest
        return a.iso?.localeCompare(b.iso);
    });
};

// Styled component for a customized Switch component
const IOSSwitch = styled((props) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor:
                    theme.palette.mode === "dark" ? "#2ECA45" : "",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

// Retrieve the access token from environment variables
let getUrl = process.env.REACT_APP_ACCESS_TOKEN;

// Object defining styles wallet connect modal
const style = {
    position: "relative",
    boxShadow: 24,
    borderRadius: "24px",
    width: "662px",
    maxHeight: '90vh'
};

export default function Register({ userTypeLocal }) {
    // Importing necessary hooks and functions
    const { login } = useAuth();
    const { state } = useLocation();
    const navigate = useNavigate();
    const socket = useSocket();
    const dispatch = useDispatch();
    const { address } = useAccount();
    const { disconnect } = useDisconnect();
    const { showSnackbar } = useSnackbar();

    let patternNumber = /.*[0-9].*/;
    let patternUpperCase = /.*[A-Z].*/;
    let patternLowerCase = /.*[a-z].*/;
    let patternSpecial = /.*[!@#$%^&*(),.?":{}|<>].*/;

    // State variables
    const [user, setUser] = useState(""); // User state
    const [profile, setProfile] = useState(""); // Profile state
    const [userType, setUserType] = useState(false); // User type state
    const [walletAccount, setWalletAccount] = useState(null); // Wallet account state
    const [fadeIn, setFadeIn] = useState(false); // Fade in animation state
    const [showPassword, setShowPassword] = useState(false); // Display / hide password
    const [sortedCountryCodes, setSortedCountryCodes] = useState([]);
    const [defaultCountry, setDefaultCountry] = useState(null);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (e) => { e.preventDefault(); };


    useEffect(() => {
        const sorted = sortCountries([...CountryCodes]);
        setSortedCountryCodes(sorted);
        if (sorted.length > 0) {
            setDefaultCountry(sorted[0]);
        }
    }, []);


    useEffect(() => {
        setFadeIn(true);
    }, []);

    // useEffect to set the wallet address when it is present in the location state
    useEffect(() => {
        if (state?.wallet_address) {
            setWalletAccount(state?.wallet_address);
        }
    }, [state])

    const [openModal, setOpenModal] = useState(false); // Modal open/close state

    // State variable to manage the wallet connection dialog
    const [walletDialogOpen, setWalletDialogOpen] = useState(false);

    // Function to open the wallet connection dialog
    const handleClickOpen = () => {
        setWalletDialogOpen(true);
    };

    // Function to close the wallet connection dialog
    const handleCloseDialog = (value) => {
        setWalletDialogOpen(false);
    };

    /**
     * Login with wallet
     * @param {string} acc  wallet address
     */
    const walletLogin = async (acc) => {
        try {
            const checkRes = await postApi(`/user/checkWallet`, { wallet_address: acc.toLowerCase() });
            if (checkRes?.data?.code === 200) {
                const loginRes = await postApi(`/user/login/`, { wallet_address: acc.toLowerCase() });
                if (loginRes?.data?.code === 200) {
                    setLoading({ ...loading, wallet: true }) // show loader on wallet button
                    dispatch(userLogin(loginRes?.data?.data));
                    localStorage.setItem("token", loginRes.data.data.token);

                    /**
                    * code to reduce wallet id
                    */
                    if (acc.length >= 10) {
                        const firstPart = acc.slice(0, 6);
                        const lastPart = acc.slice(-4);
                        acc = `${firstPart}...${lastPart}`
                    }

                    showSnackbar(`Logged in with wallet : ${acc}`, "success");
                    setTimeout(() => {
                        if (loginRes.data?.data?.kycStatus === false) {
                            window.history.replaceState({}, "", "/");
                            if (userTypeLocal === 'client') navigate("/service-proposer", { state: { from: "/login" } });
                            else navigate("/service-provider", { state: { from: "/login" } });
                        } else { //kyc is done already
                            if (userTypeLocal === 'client') {
                                window.history.replaceState({}, "", "/service-proposer");
                                navigate("/service-proposer");
                            } else {
                                window.history.replaceState({}, "", "/service-provider");
                                navigate("/service-provider")
                            }
                        }
                        setLoading({ ...loading, wallet: false }) // hide loader on wallet button
                    }, 1500);

                }

            } else if (checkRes?.data?.code === 201) {
                setOpenModal(true);
            }
        } catch (error) {

        }
    }

    /**
     * Function to close the information modal
     */
    const handleModalCloseAfterWallet = () => {
        setOpenModal(false);
        setTimeout(() => {
            disconnect();
        }, 500);
    }

    /**
     * Navigate to register page
     */
    const handleGoToSignUpAfterWallet = () => {
        setOpenModal(false);
        if (userTypeLocal === 'client') navigate("/service-proposer-register", { state: { from: "/login", wallet_address: address.toLowerCase() } });
        else navigate("/service-provider-register", { state: { from: "/login", wallet_address: address.toLowerCase() } });
    }
    //Wallet connection code end

    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, formState: { errors }, getValues,
        watch, control, } = useForm({
            phoneNumber: "",
            countryCode: "",
        });

    const countryCodeNum = watch("countryCode");
    const phoneNumber = watch("phoneNumber");
    const password = watch('password', '');

    // google login
    const registerWithGoogle = useGoogleLogin({
        onSuccess: (tokenResponse) => setUser(tokenResponse),
    });

    useEffect(() => {
        let access_token = user?.access_token;
        if (access_token) {
            let headers = {
                Authorization: `Bearer ${access_token}`,
                Accept: 'application/json'
            }
            googleUserInfo(getUrl, access_token, headers)
                .then((res) => {
                    handleGoogleLogin(res.data, access_token);
                })
                .catch((err) => console.log(err));
        }
    }, [user]);

    /**
    * Login with Google Auth
    */
    const handleGoogleLogin = async (user_profile, google_access_token) => {
        const body = {
            first_name: user_profile?.given_name,
            last_name: user_profile?.family_name,
            email: user_profile?.email,
            google_id: user_profile?.id,
            login_type: "google",
            email_verified: user_profile?.verified_email ? 1 : 0,
            google_access_token: google_access_token,
        };
        postApi(`/user/`, body).then(async (res) => {
            if (res?.data?.data) {
                await login(res?.data?.data);
                socket.emit("refresh-unread", res?.data?.data.id);
                localStorage.setItem("token", res.data.data.token);
                window.history.replaceState({}, "", "/");

                localStorage.setItem("login", true);
                localStorage.setItem("user_data", JSON.stringify(res?.data?.data));
                if (userTypeLocal === 'client') navigate("/service-proposer", { state: { from: '/login' } });
                else navigate("/service-provider", { state: { from: '/login' } });
            } else {
                showSnackbar('Failed to register', 'error')
            }
        });
    }

    /**
     * sign up / registration.
     * handles form submit with the type checking of userType Entity or Individual
     * @param {*} data  user data from form
     */
    const onSubmit = (data) => {
        let userData = {
            first_name: data.firstName,
            last_name: data.lastName ? data.lastName : "",
            credentials: createEncryptedCredentials(data.email, data.password),
            type: "individual",
            wallet_address: walletAccount ?? null,
            email_verified: false,
            login_type: "site",
            // country_code: `+${data.countryCode}`,
            phone_number: data.phoneNumber
        };
        if (data.countryCode) {
            userData.country_code = `+${data.countryCode}`;
        }

        if (userData) {
            setLoading(true);
            postApi(`/user/`, userData).then((res) => {
                setLoading(false);
                if (res?.data?.code === 200) {
                    if (userTypeLocal === 'client') {
                        navigate("/otp-verification-service-proposer", { state: { ...res.data, signedWith: walletAccount } });
                    } else {
                        navigate("/otp-verification-service-provider", { state: { ...res.data, signedWith: walletAccount } });
                    }
                } else { // something went wrong user or backend side
                    showSnackbar(res?.data?.message, "error");
                }
            });
        }
    };

    /**
     * Encrypt email and password
     * @param {string} email 
     * @param {string} pass 
     * @returns {string} encrypted + concatenated credentials
     */
    const createEncryptedCredentials = (email, pass) => {
        const emailEncrypted = encryptData(email);
        const passEncrypted = encryptData(pass);
        // concatenated credentials separated by " ; " 
        const concatenated = `${emailEncrypted};${passEncrypted}`
        return concatenated;
    }

    useEffect(() => {
        function scrollTop() {
            document.documentElement.scrollTop = 0;
        }
        scrollTop()
    }, [])

    const goBack = () => {
        navigate(-1);
    };


    const [countryIso, setCountryIso] = useState("");

    /**
     *  validate phone number with libphonenumber-js library
     * @param {string} value  phone number
     * @param {string} country_iso  country code
     * @returns
     */
    const validatePhoneNumber = (value) => {
        const res = isSupportedCountry(countryIso);
        return isValidPhoneNumber(`+${countryCodeNum}${value}`, countryIso);
    };

    return (
        <>
            <Box className="register-page auth-page">
                <Box className="auth-section-investor">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                            <Box className="auth-left-investor">
                                <Box className="auth-logo-investor">
                                    <Link to="/">
                                        <Box component="img" src={LogoInvestor} alt="Race logo" className="logo-investor" />
                                    </Link>
                                </Box>
                                <Box className='investor'>
                                    <Typography className="title">
                                        Welcome back
                                    </Typography>
                                    <Box className='sign-description' mt={'41px'}>
                                        <Typography className={'description-title font-32'} >
                                            What is RACE?
                                        </Typography>
                                        <Typography className={`description-text font-16 ${fadeIn ? 'fade-in' : ''}`} mt={'24px'}>
                                            RACE (Real Assets Cash Flow Ecosystem) is a blockchain platform that tokenizes real-world assets for secure and transparent management.
                                        </Typography>
                                    </Box>
                                    <Box className='sign-description' mt={'12px'}>
                                        <Typography className={'description-title font-32'} mt={'17px'}>
                                            Who will benefit from using RACE?
                                        </Typography>
                                        <Typography className={`description-text font-16 ${fadeIn ? 'fade-in' : ''}`} mt={'24px'}>
                                            Investors: Gain fractional ownership, increased liquidity, and transparent investments.<br /><br />
                                            Asset Owners: Access a broader investor market and unlock asset liquidity.<br /><br />
                                            Developers and Project Proposers: Raise funds efficiently and engage with diverse stakeholders.<br /><br />
                                            Lawyers and Other Service Providers: Offer specialized services within a secure, transparent ecosystem.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Box className="auth-right-investor">
                                <Box className="signup-option">
                                    <Box className="top-option">
                                        <Box textAlign={"left"} >
                                            <Box onClick={goBack}>
                                                <ArrowBack />
                                            </Box>
                                        </Box>
                                        <Box textAlign={"right"} className="alreadyAc-in">
                                            Have an account? Sign in{" "}
                                            <Link component="a" to={userTypeLocal === "client" ? "/service-proposer-login" : "/service-provider-login"}>
                                                here
                                            </Link>{" "}
                                            <Box component="span" className="icon" ml={1}>
                                                <ArrowForward />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Typography className="sign-in-text">Create your account</Typography>
                                    </Box>
                                    {/* <Box className="largeDark-swich" sx={{ mt: 1 }}>
                                        <Typography>Individual</Typography>
                                        <FormControlLabel
                                            control={
                                                <IOSSwitch
                                                    onClick={() => setUserType(!userType)}
                                                    defaultChecked={userType}
                                                />
                                            }
                                        />
                                        <Typography>Entity</Typography>
                                    </Box> */}

                                    <Box className="auth-form">
                                        <Box
                                            component="form"
                                            onSubmit={handleSubmit(onSubmit)}
                                            mb={'30px'}
                                            className="investor-register-label"
                                        >
                                            <Box className="investor-input-email" mb={'22px'}>
                                                <TextField
                                                    autoComplete='given-name'
                                                    type="text"
                                                    id="outlined-required"
                                                    label="First Name"
                                                    placeholder="Enter your first name"
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    {...register("firstName", {
                                                        required: "First Name is required.",
                                                        pattern: {
                                                            value: /^[A-Za-z]+$/i,
                                                            message: "Please enter a valid first name.",
                                                        },
                                                        minLength: {
                                                            value: 1,
                                                            message: "Please enter a valid first name.",
                                                        },
                                                    })}
                                                    error={Boolean(errors.firstName)}
                                                    helperText={errors.firstName?.message}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            color: '#686873',
                                                        },
                                                    }}
                                                />

                                            </Box>

                                            <Box className="investor-input-email" mb={'22px'}>
                                                <TextField
                                                    type="text"
                                                    id="outlined-required"
                                                    label="Last Name"
                                                    // name="lastName"
                                                    variant="standard"
                                                    placeholder="Enter your last name"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    {...register("lastName", {
                                                        required: "Last name is required.",
                                                        pattern: {
                                                            value: /^[A-Za-z]+$/i,
                                                            message: "Please enter valid last name",
                                                        },
                                                        minLength: {
                                                            value: 3,
                                                            message: "Please enter valid last name",
                                                        },
                                                    })}
                                                    autoComplete="family-name"  // this line for auto-fill
                                                    error={Boolean(errors.lastName)}
                                                    helperText={errors.lastName?.message}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            color: '#686873',
                                                        },
                                                    }}
                                                />
                                            </Box>

                                            {/* <Box className="investor-input-email" mb={'22px'}>
                                                <TextField
                                                    id="outlined-required"
                                                    label="Email Address"
                                                    type="email"
                                                    name="email"
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    placeholder="Enter your email address"
                                                    {...register("email", {
                                                        required: "Email address is required.",
                                                        pattern: {
                                                            value:
                                                                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                            message: "Please enter valid email address.",
                                                        },
                                                    })}
                                                    autoComplete="email"  // this line for auto-fill
                                                    error={Boolean(errors.email)}
                                                    helperText={errors.email?.message}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            color: '#686873',
                                                        },
                                                    }}
                                                />
                                            </Box> */}
                                            {!userType ?
                                                <Box className="investor-input-email" mb={'22px'}>
                                                    <TextField
                                                        id="outlined-required"
                                                        label="Email Address"
                                                        type="email"
                                                        name="email"
                                                        variant="standard"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        placeholder="Enter your email address"
                                                        {...register("email", {
                                                            required: "Email address is required.",
                                                            pattern: {
                                                                value:
                                                                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                                message: "Please enter valid email address.",
                                                            },
                                                        })}
                                                        autoComplete="email"  // this line for auto-fill
                                                        error={Boolean(errors.email)}
                                                        helperText={errors.email?.message}
                                                        sx={{
                                                            '& .MuiInputLabel-root': {
                                                                color: '#686873',
                                                            },
                                                        }}
                                                    />
                                                </Box>
                                                :
                                                <>
                                                    <Box className="investor-input-email" mb={'22px'}>
                                                        <TextField
                                                            id="outlined-required"
                                                            label="Company Name"
                                                            type="text"
                                                            name="compName"
                                                            variant="standard"
                                                            placeholder="Enter your company name"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            {...register("compName", {
                                                                required: "Company name is required.",
                                                            })}
                                                            autoComplete="organization"  // this line for auto-fill
                                                            error={Boolean(errors.compName)}
                                                            helperText={errors.compName?.message}
                                                            sx={{
                                                                '& .MuiInputLabel-root': {
                                                                    color: '#686873',
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box className="investor-input-email" mb={'22px'}>
                                                        <TextField
                                                            id="outlined-required"
                                                            label="Company Email Address"
                                                            placeholder="Enter your company email address"
                                                            type="email"
                                                            name="compEmail"
                                                            variant="standard"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            {...register("compEmail", {
                                                                required: "Company email address is required.",
                                                                pattern: {
                                                                    value:
                                                                        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                                    message: "Please enter valid email address.",
                                                                },
                                                            })}
                                                            autoComplete="email"  // this line for auto-fill
                                                            error={Boolean(errors.compEmail)}
                                                            helperText={errors.compEmail?.message}
                                                            sx={{
                                                                '& .MuiInputLabel-root': {
                                                                    color: '#686873',
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                </>
                                            }

                                            <Box className="investor-input-email" mb={'30px'}>
                                                <TextField
                                                    // autoComplete="off"
                                                    id="outlined-required"
                                                    label="Password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    name="password"
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" className="investor-password">
                                                                <IconButton
                                                                    aria-label="password"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onCopy={(e) => e.preventDefault()}
                                                    onPaste={(e) => e.preventDefault()}
                                                    placeholder="Enter a password"
                                                    {...register("password", {
                                                        required: "Password is required.",
                                                        pattern: {
                                                            value:
                                                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^+#])[A-Za-z\d@$!%*?&^+#]{6,}$/,
                                                            message:
                                                                "Should contain digit, lowercase letter, uppercase letter, and special character",
                                                        },
                                                        minLength: {
                                                            value: 6,
                                                            message: "You need to add minimum 8 character",
                                                        },
                                                    })}
                                                    autoComplete="new-password"  // this line for auto-fill
                                                    error={Boolean(errors.password)}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            color: '#686873',
                                                        },
                                                    }}
                                                    helperText={password?.length == 0 ? errors.password?.message : ""}
                                                />
                                            </Box>
                                            {password?.length ?
                                                <Box mb={'30px'}>
                                                    <Box className={'password-validation'}>
                                                        <Close className={`icon ${password?.length < 6 ? "error" : 'success'}`} />
                                                        <Typography className={`icon ${password?.length < 6 ? "error" : 'success'}`}>6 characters minimum</Typography>
                                                    </Box>
                                                    <Box className={'password-validation'}>
                                                        <Close className={`icon ${patternNumber.test(password) ? "success" : 'error'}`} />
                                                        <Typography className={`icon ${patternNumber.test(password) ? "success" : 'error'}`} >Must contain one number</Typography>
                                                    </Box>
                                                    <Box className={'password-validation'}>
                                                        <Close className={`icon ${patternUpperCase.test(password) ? "success" : 'error'}`} />
                                                        <Typography className={`icon ${patternUpperCase.test(password) ? "success" : 'error'}`}>Must contain one uppercase</Typography>
                                                    </Box>
                                                    <Box className={'password-validation'}>
                                                        <Close className={`icon ${patternLowerCase.test(password) ? "success" : 'error'}`} />
                                                        <Typography className={`icon ${patternLowerCase.test(password) ? "success" : 'error'}`}>Must contain one lowercase</Typography>
                                                    </Box>
                                                    <Box className={'password-validation'}>
                                                        <Close className={`icon ${patternSpecial.test(password) ? "success" : 'error'}`} />
                                                        <Typography className={`icon ${patternSpecial.test(password) ? "success" : 'error'}`}>Must contain one special character</Typography>
                                                    </Box>
                                                </Box> : null
                                            }
                                            {/* <Grid container spacing={2}>
                                                <Grid item xs={12} sm={5} md={4}>
                                                    <Box className="form-group label-field country-label" >
                                                        <Controller
                                                            name="countryCode"
                                                            control={control}
                                                            rules={{
                                                                validate: value =>
                                                                    phoneNumber
                                                                        ? value !== '' || "Please select country code"
                                                                        : true,
                                                            }}
                                                            render={({ field: { onChange, value, ref } }) => (
                                                                <Autocomplete
                                                                    options={sortedCountryCodes}
                                                                    getOptionLabel={(option) =>
                                                                        `(${option.iso}) +${option.code}`

                                                                    }
                                                                    renderOption={(props, option) => (
                                                                        <li {...props}>
                                                                            <Flag
                                                                                code={option.iso_1 ? option.iso_1 : option.iso}
                                                                                style={{ width: '20px', marginRight: '8px' }}
                                                                                alt={`${option.country} flag`}
                                                                            />
                                                                            {` (${option.iso}) +${option.code}`}
                                                                        </li>
                                                                    )}
                                                                    value={
                                                                        sortedCountryCodes.find(
                                                                            (option) => (value == 1 ? (option.code === value && option.iso === countryIso) : option.code === value || option.iso === value)
                                                                        ) || null
                                                                    }
                                                                    onChange={(_, newValue) => {
                                                                        onChange(newValue ? newValue.code : "");
                                                                        setCountryIso(newValue ? newValue.iso : "");
                                                                    }}
                                                                    isOptionEqualToValue={(option, value) =>
                                                                        option.code === value || option.iso === value
                                                                    }
                                                                    filterOptions={(options, state) => {
                                                                        const inputValue = state.inputValue.toLowerCase();

                                                                        const isoMatch = options.find(option =>
                                                                            option.iso?.toLowerCase() === inputValue || option.country?.toLowerCase() === inputValue
                                                                        );

                                                                        if (isoMatch) {
                                                                            return [isoMatch];
                                                                        } else {
                                                                            return options.filter(option =>
                                                                                [option.country, option.iso, option.code].some(field =>
                                                                                    field.toLowerCase().includes(inputValue)
                                                                                )
                                                                            );
                                                                        }
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            inputRef={ref}
                                                                            label="Country Code"
                                                                            placeholder="Select Country Code"
                                                                            error={Boolean(errors.countryCode)}
                                                                            helperText={errors.countryCode?.message}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            InputProps={{
                                                                                ...params.InputProps,
                                                                                startAdornment: value ? (
                                                                                    <Flag
                                                                                        code={value == 44 ? 'GB' : sortedCountryCodes.find(option => option.code === value && option.iso === countryIso)?.iso}
                                                                                        style={{ width: '20px', marginRight: '8px' }}
                                                                                        alt=""
                                                                                    />
                                                                                ) : null,
                                                                            }}
                                                                            inputProps={{
                                                                                ...params.inputProps,
                                                                                inputMode: "text",
                                                                                type: "text",
                                                                            }}
                                                                        />
                                                                    )}
                                                                />

                                                            )}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={8}>
                                                    <Box className="form-group label-field">
                                                        <Controller
                                                            name="phoneNumber"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    label="Phone Number"
                                                                    type="text"
                                                                    pattern="[0-9]*"
                                                                    placeholder="Enter your phone number"
                                                                    autoComplete="off"
                                                                    error={Boolean(errors.phoneNumber)}
                                                                    helperText={errors?.phoneNumber?.message}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <PhoneIphone className="text-blue-600" />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    onInput={(event) => {
                                                                        event.target.value = event.target.value.replace(/\D/g, "").slice(0, 15);
                                                                    }}
                                                                />
                                                            )}
                                                            rules={{
                                                                validate: value =>
                                                                    countryCodeNum
                                                                        ? validatePhoneNumber(value, countryCodeNum) || "Please enter a valid phone number"
                                                                        : true,
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid> */}
                                            <Box className="btn-wrap">
                                                <LoadingButton
                                                    loading={loading}
                                                    loadingPosition="start"
                                                    variant="contained"
                                                    type="submit"
                                                    fullWidth
                                                    className="btn-rounded btn-large btn-blue-600 btn-text-white"
                                                >
                                                    Create Account
                                                </LoadingButton>
                                            </Box>
                                        </Box>
                                        {
                                            walletAccount && walletAccount?.length > 10 ?
                                                <>
                                                    <Box className="signup-btns" sx={{ my: 5 }}>
                                                        <Typography className="sub-title text-center">Connected with wallet : {walletAccount}  </Typography>
                                                    </Box>
                                                </> :
                                                <>
                                                    <Box className="or-divider" sx={{ my: '30px' }}>
                                                        or
                                                    </Box>
                                                    <Box className="signup-btns">
                                                        <Button
                                                            variant="contained"
                                                            component="a"
                                                            to="/"
                                                            fullWidth
                                                            className="blueLite-btn"
                                                            sx={{ mr: 1.5, mb: '30px' }}
                                                            startIcon={<Avatar alt="Google Icon" src={googleColorFullIcon} sx={{ width: '24px', height: '24px' }} />}
                                                            onClick={() => registerWithGoogle()}
                                                        >
                                                            Sign In with google
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            component="a"
                                                            to="/"
                                                            fullWidth
                                                            className="blueLite-btn"
                                                            onClick={handleClickOpen}
                                                            startIcon={<AccountBalanceWallet sx={{ width: '24px', height: '24px' }} />}
                                                        >
                                                            Sign In with Wallet
                                                        </Button>
                                                    </Box>
                                                </>
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <ConnectWalletInvestor open={walletDialogOpen} handleCloseDialog={handleCloseDialog} handleConnectedSuccess={walletLogin} userRegister={true} investorRegister={true} />

            <Modal
                open={openModal}
                className="connectWallet-alert-modal"
                onClose={handleModalCloseAfterWallet}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={openModal} timeout={300}>
                    <Box className="modal-body" sx={style}>
                        <Box className="headContent">
                            <Box className="logo">
                                <Avatar
                                    alt="Logo"
                                    src={NewLogo}
                                    variant="square"
                                    sx={{ width: 39, height: 90 }}
                                />
                            </Box>

                            <Close className="close-btn" onClick={handleModalCloseAfterWallet} />
                        </Box>
                        <Box className="modalContent " >
                            <Box className="main-content" >
                                <Typography component="h1" className="headText">Sign up Please!</Typography >
                                <Typography component="p" className="sub-headText">It seems like account : {address && address.slice(0, 6) + '...' + address.slice(-4)} is not registered with us. Please sign up first.</Typography >
                                <Box className="modalContent-btn-box" >
                                    <Button className="wallet-button prev" onClick={handleGoToSignUpAfterWallet} >Continue to Sign up</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}