import { createSlice } from '@reduxjs/toolkit'

export const heightSlice = createSlice({
    name: 'height',
    initialState: {
        'height': 0
    },
    reducers: {
        updateRightHeight: (state, action) => {
            const { key, value } = action.payload;
            if (key in state) {
                state[key] = value;
            }
        }
    }
});

export const { updateRightHeight } = heightSlice.actions;
export default heightSlice.reducer;