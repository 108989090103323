import React, { useRef, useState } from 'react'
import { Box, IconButton, Input, Stack } from '@mui/material'
import SendIcon from '@mui/icons-material/Send';
import { useSocket } from '../../Contexts/SocketContext';

const hiddenStyle = {
    visibility: 'hidden',
    pointerEvent: 'none',
    opacity: 0,
}


export default function ChatInput({ handleSendMsg, currentChat }) {
    const [typedMessage, setTypedMessage] = useState('');

    /**
     * Handle send button click
     * @param {Event} e 
     * @param {String} msg 
     */
    const sendMsg = (e, msg) => {
        e.preventDefault();
        if (msg === '') return;
        handleSendMsg(msg);
        setTypedMessage('');
    }

    /**
     * Function to handle key down event
     * @param {Event} e 
     */
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (e.shiftKey) {
                // Add a new line in the text area
                setTypedMessage((prevMessage) => prevMessage + '\n');
                //use prevent default because it was opening new window
                e.preventDefault();
            } else {
                sendMsg(e, typedMessage)
            }
        }
    };

    return (
        <Box className='ctSendMsgBox' component={'form'}   >
            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={2} >
                <Input className='ctSendMsgInput' placeholder="Type your message"
                    fullWidth={true}
                    disableUnderline={true}
                    value={typedMessage}
                    onChange={(e) => { setTypedMessage(e.target.value) }}
                    onKeyDown={handleKeyDown}
                    multiline
                    maxRows={3}
                />
                <IconButton
                    className='ctSendMsgIcon'
                    aria-label="send"
                    sx={typedMessage === '' ? hiddenStyle : {}}
                    onClick={(e) => { sendMsg(e, typedMessage) }}>
                    <SendIcon />
                </IconButton>
            </Stack>
        </Box>
    )
}
