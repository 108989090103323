import { Box, Checkbox, FormControlLabel, List, ListItem, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { GetApiParam } from '../../../Api/Api';
import { useSnackbar } from '../../../Contexts/SnackbarContext';


export default function Terms({ termsAccepted, setTermsAccepted, assetData }) {
    // Extract showSnackbar function from the useSnackbar custom hook
    const { showSnackbar } = useSnackbar();

    const [terms, setTerms] = useState([]);

    useEffect(() => {
        // Function to fetch category data from the API
        async function getInvestmentTerms() {
            try {
                if (assetData) {
                    const res = await GetApiParam("/proposer/getTermFAQs/", {
                        investment_type_id: assetData?.investment_type_id, category_id: assetData?.category_id
                    });
                    if (res?.data?.data && res?.data?.data?.length && res?.data?.data[0]?.terms_description
                    ) {
                        const flattenedData = res.data.data.flatMap((item) =>
                            item?.terms_description?.map((faq) => ({
                                main_id: item.id,  // Main id
                                id: faq.id,        // Term id
                                term: faq.term
                            }))
                        );
                        setTerms(flattenedData);
                    }
                    else {
                        setTerms([])
                    }
                }
            } catch (error) {
                showSnackbar(error, 'error')
            }
        }
        getInvestmentTerms();
    }, [assetData]);

    return (
        <Paper elevation={3} sx={{ p: 2, mb: 2 }} className='rc-basic-container'>
            <Typography variant='h6' className='font-16' mb={1.5}>Investment Terms</Typography>
            <List >
                {
                    terms?.map((term) => (
                        <ListItem divider={true}>
                            <Typography className='rc-small-secondary-text' gutterBottom>{term?.term}</Typography>
                        </ListItem>
                    ))
                }
                {/* <ListItem divider={true}>
                    <Typography className='rc-small-secondary-text' gutterBottom>I understand that I am unable to cancel my investment if I make a full investment</Typography>
                </ListItem>
                <ListItem divider={true}>
                    <Typography className='rc-small-secondary-text' gutterBottom>I understand that Republic will receive a cash fee of 6% of the proceeds</Typography>
                </ListItem>
                <ListItem divider={true}>
                    <Typography className='rc-small-secondary-text' gutterBottom>I understand that there is no guarantee of a relationship between RACE and the project post-offering</Typography>
                </ListItem>
                <ListItem divider={true}>
                    <Typography className='rc-small-secondary-text' gutterBottom>I consent to electronic delivery of all documents, notices and agreements as related to my investment</Typography>
                </ListItem>
                <ListItem divider={true}>
                    <Typography className='rc-small-secondary-text' gutterBottom>I have read the educational materials and agree to the Terms of Service, including arbitration provisions</Typography>
                </ListItem>
                <ListItem divider={true}>
                    <Typography className='rc-small-secondary-text' gutterBottom>I understand I am responsible for all fees and charges associated with the use of my payment method</Typography>
                </ListItem> */}

            </List>
            <Box >
                <FormControlLabel checked={termsAccepted} onChange={() => { setTermsAccepted(!termsAccepted) }} className='rc-terms-checkbox' control={<Checkbox />} label="I have read and accept the terms of investment" />
            </Box>
        </Paper>
    )
}


