// makeOffer.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CAPITAL_TYPE_ID, CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, SUB_CATEGORY_TYPE_ID } from "../../constants";
import convertToDecimal from "../Common/convertToDecimal";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";

/**
 * Prepares the arguments for the contract function based on assetData.
 * @param {String} address 
 * @param {Number} offerAmount 
 * @param {Object} assetData 
 * @returns {Object} makeOfferArgs 
 */
async function makeOffer(address, contract_address, contract_abi, token_address, assetData, offerAmount, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        // set active step to 2
        if (assetData?.payment_method == 'fiat') { } else {
            handleActiveStep(2)
        }

        if (!assetData?.listing_id) {
            showSnackbar("Listing ID is required.", 'error')
            handleModalClose();
            handleActiveStep(-1)
            return
        }

        let makeOfferArgs = {};
        if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && (assetData?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT || assetData?.capital_type_id === CAPITAL_TYPE_ID.JUNIOR_DEBT)) {
            makeOfferArgs = {
                address: contract_address,
                abi: contract_abi,
                account: address,
                chainId: 6805,
                functionName: "investOnDebt",
                args: [
                    assetData.listingId,
                    assetData?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT ? 0 : 1,
                    convertToDecimal(offerAmount),
                ]
            };
        } else if (assetData?.capital_type_id === CAPITAL_TYPE_ID.EQUITY) {
            makeOfferArgs = {
                address: contract_address,
                abi: contract_abi,
                account: address,
                chainId: 6805,
                functionName: "investOnEquity",
                args: [
                    assetData.listingId,
                    convertToDecimal(offerAmount),
                ]
            }
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
            if (assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
                if (assetData?.payment_method == 'fiat') {
                    makeOfferArgs = {
                        address: contract_address,
                        abi: contract_abi,
                        account: address,
                        chainId: 6805,
                        functionName: "investUsingFiat",
                        args: [
                            assetData?.listing_id,
                            convertToDecimal(offerAmount)
                        ]
                    }
                } else {
                    makeOfferArgs = {
                        address: contract_address,
                        abi: contract_abi,
                        account: address,
                        chainId: 6805,
                        functionName: "investOnFund",
                        args: [
                            assetData?.listing_id, // listing id / ipfs code id Integer
                            token_address,
                            getCurrencyTypeDecimal(assetData?.feeTokenType, offerAmount)
                        ]
                    };
                }
            } else {
                makeOfferArgs = {
                    address: contract_address,
                    abi: contract_abi,
                    account: address,
                    chainId: 6805,
                    functionName: "investOnFund",
                    args: [
                        assetData?.listing_id, // listing id / ipfs code id Integer
                        [assetData?.class == 1 ? 0 : 1], // debtTypeId
                        token_address,
                        // [convertToDecimal(offerAmount)], // offer amount / offeredAmount
                        [getCurrencyTypeDecimal(assetData?.feeTokenType, offerAmount)]
                    ]
                }

            }
        } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
            makeOfferArgs = {
                address: contract_address,
                abi: contract_abi,
                account: address,
                chainId: 6805,
                functionName: "makeOffer",
                args: [
                    assetData?.listing_id, // listing id / ipfs code id Integer
                    getCurrencyTypeDecimal(assetData?.feeTokenType, offerAmount),
                    Number(Math.round(assetData?.loan_roi * 100)), // offer rate of interest / offeredPercentage
                    Number(assetData?.loan_duration), // offer loan duration / offeredDuration
                    token_address, // fee token
                ]
            }
        } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
            makeOfferArgs = {
                address: contract_address,
                abi: contract_abi,
                account: address,
                chainId: 6805,
                functionName: "makeOffer",
                args: [
                    assetData?.listing_id, // listing id / ipfs code id Integer
                    getCurrencyTypeDecimal(assetData?.feeTokenType, offerAmount), // offer amount / offeredAmount
                    token_address, // fee token
                    getCurrencyTypeDecimal(assetData?.feeTokenType, assetData?.depositAmount), // offer deposit amount / depositAmount
                ]
            }
        }

        console.log('makeOfferArgs', makeOfferArgs);
        // make offer by investor
        const { request: makeOffer } = await prepareWriteContract(makeOfferArgs);
        console.log('makeOffer', makeOffer);
        const { hash } = await writeContract(makeOffer);

        if (hash) {
            const data = await waitForTransaction({ hash });
            if (data.status == "success") {
                showSnackbar("Transaction Successful", 'success');
                handleActiveStep(3);
                setTimeout(() => {
                    handleSuccess(data, assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE ? assetData?.depositAmount : offerAmount, assetData?.asset_id);
                    handleModalClose();
                    handleActiveStep(-1);
                    return { data };
                }, 1000);
            } else {
                handleModalClose();
                handleActiveStep(-1);
                showSnackbar("Transaction Failed", 'error');
                return;
            }
        }
    } catch (error) {
        console.error('Error fetching balance:', error);
        throw error; // propagate the error
    }
}

export { makeOffer };
