import React from 'react';
import { Box, Button, Grid, Typography, Avatar } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { mobileImage } from '../../Components/Images/Images'
import { useNavigate } from 'react-router-dom';
import './GetStarted.css'
const GetStarted = () => {
    const navigate = useNavigate();
    const localData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : ""


    function handleClickGetStarted() {
        if (localData?.id) {
            navigate("/questionnaire-get-started")
        }
        else {
            navigate("/login")
        }
    }

    return (
        <Box className="pj-getStart-section">
            <Box className="pj-getStart-wrap" maxWidth={'1000px'}>
                <Grid container spacing={11} alignItems={'center'}>
                    <Grid item xs={12} sm={12} md={5}>
                        <Box className="left-img">
                            <Avatar variant="square" alt="Image" src={mobileImage} sx={{ width: 'auto', maxWidth: '412px', height: 'auto' }}></Avatar>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7}>
                        <Box>
                            <Box className="section-heading" mb={3.5}>
                                <Typography className="title" variant="h3">“For the first time in history, we’re on the verge of a world where <Box component={'span'} className='text-golden'> anyone can invest in assets</Box> that were once out of reach.” </Typography>
                                <Typography className="subtitle">Dan Simerman, Head of Financial Relations at the IOTA Foundation</Typography>
                            </Box>
                            <Button className='btn-rounded btn-large btn-golden' sx={{ mb: 3 }} onClick={handleClickGetStarted}>
                                <Box component={'span'} mr={1}><EditIcon /></Box> Get Started
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default GetStarted;