import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Avatar, Box, Typography, Button, Modal, Grid, TextField, IconButton, Stack } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import { Cancel, Upload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { CircleCheckIcon } from "../../../../Components/Images/Images";
import "./DeliveryModal.css";
import CommonBackdropLoader from "../../../../Components/Common/CommonBackdropLoader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "var(--blue-900)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
  borderWidth: "0px",
  "@media (max-width: 600px)": {
    width: "350px", // Set a different height for small screens
  },
  // Additional media queries for other screen sizes if needed
  "@media (min-width: 601px) and (max-width: 900px)": {
    // Custom styles for devices between 601px and 900px width
    width: "580px",
  },
  "@media (min-width: 901px) and (max-width: 1200px)": {
    // Custom styles for devices between 901px and 1200px width
    width: "750px",
    maxHeight: "300px",
    overflow: "auto",
  },
  // Specify the maximum width for large screens
  "@media (min-width: 1201px)": {
    maxWidth: "1043px",
    maxHeight: "525px",
    overflow: "auto",
  },
};

const DeliveryModal = ({
  openDeliveryModal,
  setOpenDeliveryModal,
  deliveryMessage,
  setDeliveryMessage,
  deliveryDocuments,
  setDeliveryDocuments,
  submitDelivery,
  projectTerm,
  delLoading,
  compLoading,
  milestoneData
}) => {
  const [errors, setErrors] = useState({});

  const [textCount, setTextCount] = useState(["5000"]);
  // handle uploaded docs
  const onDrop = useCallback(
    (acceptedFiles) => {
      const pdfFilesArray = acceptedFiles.filter(
        (file) => file.type === "application/pdf"
      );
      setDeliveryDocuments([...deliveryDocuments, ...pdfFilesArray]);
      let err = '';
      if (pdfFilesArray?.length === 0) {
        err = 'Document is required';
      }
      if (pdfFilesArray.length > 0) {
        err = '';
      }
      setErrors((prevState) => ({
        ...prevState,  // Copy previous state
        deliveryDocuments: err   // Update specific key
      }));
    },
    []
  );

  const currentMilestone = useMemo(() => {
    let idx = 0;
    if (milestoneData && milestoneData.length > 0) {
      for (let index = 0; index < milestoneData.length; index++) {
        if (milestoneData[index]?.status === 0 || milestoneData[index]?.status === 2) {
          idx = index;
          break;
        }
      }
    }
    return idx;
  }, [milestoneData]);

  const { getRootProps, open, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: { "application/pdf": [".pdf"] },
    onDrop,
  });

  // handle deleted docs
  const handleDeleteImage = async (file) => {
    const newUploadedImages = deliveryDocuments.filter(
      (image) => image.path !== file.path
    );
    setDeliveryDocuments(newUploadedImages);
  };

  // preparing to show uploaded docs
  const files = deliveryDocuments?.map((file) => (
    <Box className="showUploadedDocuments">
      <Box
        key={file.path}
        className="showUploadedDocuments-wrap"
        display={"flex"}
        alignItems={"center"}
      >
        <Box className="fileName" mr={1}>
          {file?.path?.includes("services/uploads/")
            ? file.original_name
            : file.name}
        </Box>
        <Cancel
          onClick={() => handleDeleteImage(file)}
          className="removeIcon"
        />
      </Box>
    </Box>
  ));

  /**
   * Validation function for the form Fields
   * @returns
   */
  const validate = () => {
    let errors = {};

    if (!deliveryMessage || deliveryMessage.trim() === '') {
      errors.deliveryMessage = 'Message is required';
    }
    if (deliveryMessage?.length < 500) {
      errors.deliveryMessage = 'Please enter a message of at least 500 characters.';
    }
    if (deliveryMessage?.length >= 500) {
      errors.deliveryMessage = '';
    }

    if (deliveryDocuments?.length === 0) {
      errors.deliveryDocuments = 'Document is required';
    }
    if (deliveryDocuments.length > 0) {
      errors.deliveryDocuments = '';
    }

    setErrors(errors);
    return Object.keys(errors).every((key) => errors[key] === "");
  };

  const handleSubmit = () => {
    const isValid = validate();
    if (isValid) {
      submitDelivery(currentMilestone);
    }
  }

  const handleSubmitAndComplete = () => {
    const isValid = validate();
    if (isValid) {
      submitDelivery(currentMilestone);
    }
  }

  const handleMessageChange = (e) => {
    if (e.target.value.length < 5000) {
      setDeliveryMessage(e.target.value ?? '');
    }
    setTextCount(5000 - e.target.value.length);
    let err = '';
    if (!e.target.value || e.target.value.trim() === '') {
      err = 'Message is required';
    }
    if (e.target.value?.length < 500) {
      err = 'Please enter a message of at least 500 characters.';
    }
    if (e.target.value?.length >= 500) {
      err = '';
    }
    setErrors((prevState) => ({
      ...prevState,  // Copy previous state
      deliveryMessage: err   // Update specific key
    }));
  }

  // useEffect(() => {
  //   validate();
  // }, [deliveryMessage, deliveryDocuments]);

  return (
    <div>
      <Modal
        open={openDeliveryModal}
        onClose={() => setOpenDeliveryModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="send-custom-offer-container"
      >
        <Box sx={style} style={{ paddingTop: '10px' }}>
          <CommonBackdropLoader loading={delLoading} />
          <Box className="header-close-button">
            <IconButton className='close-btn' onClick={() => setOpenDeliveryModal(false)}>
              <CloseIcon className="cross-icon-modal" />
            </IconButton>
          </Box>
          <Typography className="modal-title">
            Service Delivery {projectTerm === 'milestone' ? `#${currentMilestone + 1}` : ''}
          </Typography>
          <Box className="step1-container">
            <Grid container spacing={2}>
              <Grid item md={4} sm={12}>
                <Box className="left-div">
                  <Typography className="font-18">
                    Send your deliverables
                  </Typography>
                  <Typography className="font-12 text-blueGray-300">
                    Include the deliverables to be sent to the client, and add a message if you’d like. Once all deliverables have been sent, click the “Complete Project” button.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={8} sm={12}>
                <Box className="right-div">
                  <Box mt={1}>
                    <TextField
                      id="outlined-multiline-flexible"
                      placeholder="Enter your message"
                      multiline
                      maxRows={10}
                      rows={4}
                      value={deliveryMessage}
                      onChange={handleMessageChange}
                    />
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    {errors.deliveryMessage ? <Typography variant="body2" color="error">{errors.deliveryMessage}</Typography> : <Typography paddingLeft={"32px"} color={'#94A3B8'} className='font-12'>Enter at least 500 characters</Typography>}
                    <Typography paddingRight={"24px"} color={'#94A3B8'} className="font-12">
                      {textCount} characters left
                    </Typography>
                  </Box>
                </Box>
                <Box className="right-div">
                  <Box
                    {...getRootProps({
                      className: "dropzone",
                    })}
                  >
                    <input {...getInputProps()} />
                    <Stack direction="row" spacing={0} flexWrap={"wrap"}>
                      <Box className="btn-wrap" mb={{ xs: 2, sm: 4, md: 1 }}>
                        <Button
                          className="btn-rounded btn-blue-600 btn-text-white"
                          onClick={open}
                          startIcon={<Upload />}
                          sx={{ color: "white" }}
                        >
                          Attach Files
                        </Button>
                      </Box>
                    </Stack>
                  </Box>
                  {errors.deliveryDocuments && (
                    <Typography variant="body2" style={{ color: "#d32f2f" }}>
                      {errors.deliveryDocuments}
                    </Typography>
                  )}
                  {files !== "" ? (
                    <Box
                      sx={{
                        paddingBottom: "8px",
                        overflow: "hidden",
                      }}
                    >
                      {files?.map((fileName, ind) => {
                        return (
                          <>
                            {" "}
                            <Box
                              alignItems={"center"}
                              mt={1}
                              mb={2}
                              key={ind}
                              sx={{ display: "flex" }}
                            >
                              <Avatar
                                src={CircleCheckIcon}
                                alt="Icon"
                                sx={{ width: 24, height: 24 }}
                              />
                              <Box
                                className="dD-file-name text-black"
                                ml={1}
                                sx={{ color: "#fff" }}
                              >
                                {fileName}
                              </Box>
                            </Box>
                          </>
                        );
                      })}
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="send-delivery">
            {/* {projectTerm !== 'project' && */}
            <>
              <LoadingButton
                loading={delLoading}
                loadingPosition="start"
                className="send"
                variant="contained"
                startIcon={<SpeakerNotesIcon />}
                onClick={handleSubmit}
              >
                Send Deliverables
              </LoadingButton>&nbsp;
            </>
            {/* } */}
            {/* <LoadingButton
              loading={compLoading}
              loadingPosition="start"
              className="complete"
              variant="outlined"
              startIcon={<DonutLargeIcon />}
              onClick={handleSubmitAndComplete}
            >
              Complete Service
            </LoadingButton> */}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DeliveryModal;
