import React from "react";
import { Button, Box, Grid, Typography, Avatar } from "@mui/material";
import { Category } from "@mui/icons-material";
import "./Hero.css";

const Hero = (props) => {

  const handleClick = () => {
    if (props.callback && props.callbackTitle) {
      props.callback(props.callbackTitle)
    }
  }
  return (
    <Box className="home-exploreBanner-wrap" position={"relative"}>
      <Grid spacing={1} container alignItems={"center"}>
        <Grid item sm={6} xs={12} md={6}>
          <Box pr={{ md: 13, sm: 0 }}>
            <Typography component={"h1"} className="ex-title">
              <Box component={"span"} className="text-white">{props.title}</Box>{" "}
              <Box component={"span"} className="text-golden">
                {props.highlightText}
              </Box>
            </Typography>
            <Typography className="ex-subtitle text-white" mt={3}>
              {props.subTitle}
            </Typography>
            <Box className="btn-wrap multi-btns" mt={2}>
              {props?.btnText !== "Sign up and provide services" &&
                <Button
                  className="btn-rounded btn-large btn-golden"
                  style={{ color: '#000' }}
                  startIcon={<Category />}
                  onClick={handleClick}
                >
                  {props?.btnText}
                </Button>
              }
            </Box>
          </Box>
        </Grid>
        <Grid item sm={6} xs={12} md={6}>
          <Box className="raceCapital-banner-img">
            <Avatar
              variant="square"
              src={props.image}
              alt="Image"
              sx={{ width: "auto", height: "auto", borderRadius: "24px" }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;
