import React, { useState, useEffect } from "react";
import { Grid, Box, Button, InputAdornment, Typography, TextField, Menu, MenuItem, IconButton, Stack, styled, Paper, Tooltip } from "@mui/material";
import { CheckCircleOutline, Close, ImageSearch, AddShoppingCart } from "@mui/icons-material";
import { ftrFacebook, ftrInstagram, ftrLinkedin, } from "../../../Components/Images/Images";
import { useForm } from "react-hook-form";
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import { GetApi, updateApi } from "../../../Api/Api";
import { Person } from "@mui/icons-material";
import IndentityKyc from "../../Investor/MicroComponents/IndentityKyc";
import BluePrintNFTSelection from "../../BluePrintNFT/BluePrintNFTSelection";
import "./Personal.css";
import { sanitizeInput } from "../../../Components/Common/NameValidation";
import CommonBackdropLoader from "../../../Components/Common/CommonBackdropLoader";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiFilledInput-root": {
    backgroundColor: "#05131B",
    borderRadius: "8px",
  },
  "& .MuiFilledInput-root:hover": {
    backgroundColor: "#05131B",
  },
  "& .MuiFilledInput-root.Mui-focused": {
    backgroundColor: "#05131B",
  },
  "& .MuiInputBase-input.MuiFilledInput-input": {
    color: "#ffffff",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "16px",
  },
  "& .MuiInputBase-input.Mui-focused": {
    backgroundColor: "#05131B",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "4px solid #475569",
    borderRadius: "8px",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "4px solid #475569",
    borderRadius: "8px",
  },
  "& .MuiFilledInput-underline:hover:after": {
    borderBottom: "4px solid #475569",
    borderRadius: "8px",
  },
  "& label.MuiInputLabel-root": {
    color: "#64748B",
  },
}));

const PersonalInformation = ({ Data }) => {
  const { showSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openBluePrintSelection, setOpenBluePrintSelection] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);

  /**
   * Function to handle blue print open
   */
  const handleBluePrintOpen = () => {
    setOpenBluePrintSelection(true);
    handleClose();
  };

  useEffect(() => {
    setUserData(Data);
  }, [Data]);

  /**
   * Function to handle blue print open
   */
  const handleBluePrintClose = () => {
    setOpenBluePrintSelection(false);
    let u = localStorage.getItem('user_data')
    let user = JSON.parse(u);
    if (user?.id) {
      setLoading(true)
      const getUserData = async () => {
        //todo get user data
        try {
          const res = await GetApi('/user/getContactInformation/' + user.id)
          if (res.data.code === 200) {
            setUserData(res.data.data)
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      }
      getUserData()
    }
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const {
    register: registerSocial,
    handleSubmit: handleSubmitSocial,
    formState: { errors: errorsSocial },
    setValue: setValueSocial,
  } = useForm({
    defaultValues: {
      instagram_url: "",
      facebook_url: "",
      linkedin_url: "",
    },
  });

  const {
    register: registerName,
    handleSubmit: handleSubmitName,
    formState: { errors: errorsName },
    setValue: setValueName,
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
    },
  });

  useEffect(() => {
    let u = localStorage.getItem('user_data')
    let user = JSON.parse(u);
    // Set default values after userData is available
    setValueSocial("instagram_url", userData?.userInformation?.length ? userData?.userInformation[0]?.instagram_url : '');
    setValueSocial("facebook_url", userData?.userInformation?.length ? userData?.userInformation[0]?.facebook_url : '');
    setValueSocial("linkedin_url", userData?.userInformation?.length ? userData?.userInformation[0]?.linkedin_url : '');
    setValueName("first_name", userData?.first_name ? (userData?.first_name) : user?.first_name);
    setValueName("last_name", userData?.last_name ? (userData?.last_name) : user?.last_name);
    setValueName("email", userData?.email ? userData?.email : user?.email);
  }, [userData, setValueName, setValueSocial]); // Ensure useEffect runs when userData changes

  /**
   * updates social links of a user
   * @param {object} values
   */
  const onSubmitSocial = async (values) => {
    // did a ternary operation to check if the value is empty or not made undefined so the value is not set in payload
    // avoiding empty string in payload
    let payload = {
      instagram_url:
        values.instagram_url === "" ? undefined : values.instagram_url,
      facebook_url:
        values.facebook_url === "" ? undefined : values.facebook_url,
      linkedin_url:
        values.linkedin_url === "" ? undefined : values.linkedin_url,
    };
    if (values?.instagram_url || values?.facebook_url || values?.linkedin_url) {
      try {
        let u = localStorage.getItem('user_data')
        let user = JSON.parse(u);
        setLoading(true)
        let id = userData?.id ? userData?.id : user?.id;
        const res = await updateApi(
          "/user/updateContactInformation/" + id,
          payload
        );
        if (res.data.code === 200) {
          showSnackbar(res.data.message, "success");
        } else {
          showSnackbar(res.data.message, "error");
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error);
        showSnackbar("Something went wrong", "error");
      }
    } else {
      return
    }
  };

  /**
   * First and last name update of user
   * @param {object} values
   */
  const onSubmitNameChange = async (values) => {
    let payload = {
      first_name: sanitizeInput(values.first_name),
      last_name: sanitizeInput(values.last_name),
      email: values.email
    };
    try {
      setLoading(true)
      let u = localStorage.getItem('user_data')
      let user = JSON.parse(u);
      let id = userData?.id ? userData?.id : user?.id;
      const res = await updateApi(
        "/user/updateName/" + id,
        payload
      );
      if (res.data.code === 200) {
        showSnackbar(res.data.message, "success");
      } else {
        showSnackbar(res.data.message, "error");
      }
      setLoading(false)
    } catch (error) {
      console.log(error);
      showSnackbar("Something went wrong", "error");
      setLoading(false)
    }
  };

  return (
    <Box className="stPi-tab">
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm={12} md={5}>
          <Stack direction={"column"} spacing={2}>
            {/* Blueprint Persona setting */}
            <Paper className="stPi-card">
              <Typography variant="h6">Your Bluprint Persona</Typography>
              <Box mt={2} className="stPi-upload">
                {userData?.persona_picture ? (
                  <>
                    <img
                      className="stPi-upload-img"
                      src={userData?.persona_picture}
                      id="userImage"
                      alt="Your Profile"
                    />
                    <Stack direction={"column"} spacing={2}>
                      <Button
                        startIcon={<CheckCircleOutline />}
                        className="btn-rounded btn-green-400"
                        sx={{ margin: "20px", width: "fit-content" }}
                        id="basic-button"
                      >
                        Blueprint Authenticated
                      </Button>
                      <Typography variant="body1" sx={{ width: "fit-content" }}>
                        Note: If your Bluprint NFT is moved from your wallet or
                        your wallet gets compromised, the linkage will be
                        broken.
                      </Typography>
                    </Stack>
                  </>
                ) : (
                  // Person Doesnt have a blueprint persona picture
                  <>
                    <Box
                      className="bg-golden"
                      p={2}
                      width={"89px"}
                      height={"89px"}
                      borderRadius={"28px"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Person
                        className="text-white"
                        sx={{ fontSize: "36px" }}
                      />
                    </Box>
                    <Button
                      startIcon={<CheckCircleOutline />}
                      className="btn-rounded btn-teal-100"
                      sx={{
                        margin: { xs: 0, sm: "0px", md: "0px", lg: "20px" },
                      }}
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      Authenticate Now
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      className="pi-img-upload-menu"
                    >
                      <MenuItem onClick={handleBluePrintOpen} value={1}>
                        {" "}
                        <ImageSearch sx={{ marginRight: "15px" }} />
                        <Typography>Select Bluprint NFT</Typography>
                      </MenuItem>
                      <MenuItem onClick={handleClose} value={2}>
                        {" "}
                        <AddShoppingCart
                          sx={{ marginRight: "15px" }}
                        />{" "}
                        <Typography>Buy Bluprint NFT</Typography>
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Box>
            </Paper>

            {/* Social Accounts */}
            <Paper className="stPi-card">
              <Box
                component={"form"}
                onSubmit={handleSubmitSocial(onSubmitSocial)}
              >
                <Typography variant="h6">Social accounts</Typography>
                <Box mt={2}>
                  <CustomTextField
                    label="Instagram username"
                    variant="filled"
                    autoComplete="url"
                    placeholder="your instagram social link"
                    className="stPi-social"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <InstagramIcon sx={{ color: '#406ADC !important' }} /> */}
                          <img src={ftrInstagram} alt="Instagram" />
                        </InputAdornment>
                      ),
                    }}
                    {...registerSocial("instagram_url", {
                      pattern: {
                        value:
                          /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?instagram.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                        message: "Invalid URL.",
                      },
                    })}
                    error={Boolean(errorsSocial.instagram_url)}
                    helperText={errorsSocial.instagram_url?.message}
                    sx={{ input: { color: "#FFFFFF !important" } }}
                  />
                  <CustomTextField
                    variant="filled"
                    autoComplete="url"
                    className="stPi-social"
                    placeholder="your facebook social link"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <FacebookOutlinedIcon sx={{ color: '#18ACFE !important' }} /> */}
                          <img src={ftrFacebook} alt="Facebook" />
                        </InputAdornment>
                      ),
                    }}
                    {...registerSocial("facebook_url", {
                      pattern: {
                        value:
                          /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?facebook.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                        message: "Invalid URL.",
                      },
                    })}
                    error={Boolean(errorsSocial.facebook_url)}
                    helperText={errorsSocial.facebook_url?.message}
                    label="Facebook"
                    sx={{ input: { color: "#FFFFFF !important" } }}
                  />
                  <CustomTextField
                    variant="filled"
                    className="stPi-social"
                    autoComplete="url"
                    placeholder="your linkedin social link"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <LinkedInIcon sx={{ color: '#205A7E !important' }} /> */}
                          <img src={ftrLinkedin} alt="Linkedin" />
                        </InputAdornment>
                      ),
                    }}
                    {...registerSocial("linkedin_url", {
                      pattern: {
                        value:
                          /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                        message: "Invalid URL.",
                      },
                    })}
                    error={Boolean(errorsSocial.linkedin_url)}
                    helperText={errorsSocial.linkedin_url?.message}
                    label="Linkedin"
                    sx={{ input: { color: "#FFFFFF !important" } }}
                  />
                </Box>
                <Box mt={2}>
                  <Button type="submit" className="btn-rounded btn-teal-100">
                    Save Changes
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <Stack direction={"column"} spacing={2}>
            {/* Personal Information First name last name*/}
            <Box
              component={"form"}
              onSubmit={handleSubmitName(onSubmitNameChange)}
            >
              <Paper className="stPi-card">
                <Typography variant={"h6"}>Personal Information</Typography>
                <Grid mt={2} container direction="row" spacing={1}>
                  <Grid item md={6} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        background: "#05131B",
                        borderRadius: "8px",
                      }}
                    >
                      {userData?.persona_picture ? <Box
                        sx={{
                          marginTop: "5px",
                          marginLeft: "8px",
                          borderRadius: "40px",
                          width: "32px",
                        }}
                      >

                        <img
                          src={
                            userData?.persona_picture ?? userData?.persona_picture
                          }
                          alt="Your Profile"
                        />
                      </Box> : null
                      }
                      <CustomTextField
                        variant="filled"
                        disabled={userData?.kyc_status}
                        placeholder="Enter first name"
                        autoComplete="off"
                        // name="name"
                        {...registerName("first_name", {
                          required: {
                            value: true,
                            message: "First name is required",
                          },
                          pattern: {
                            value: /^\s*[A-Za-z]+\s*$/i,
                            message: "Please enter a valid first name.",
                          },
                          minLength: {
                            value: 2,
                            message: "Please enter a valid first name.",
                          },
                        })}
                        InputProps={{
                          //if kyc status is true then make the input field readOnly
                          readOnly: userData?.kyc_status,
                          endAdornment: userData?.kyc_status ? null : (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={(e) => {
                                  setValueName("first_name", "");
                                }}
                              >
                                <Close
                                  sx={{ color: "#FFFFFF !important" }}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(errorsName.first_name)}
                        label="First Name"
                        sx={{ input: { color: "#FFFFFF !important" } }}
                      />
                    </Box>

                    {Boolean(errorsName.first_name) && (
                      <Typography className="CustomFormHelperText">
                        First name is required
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Box sx={{ background: "#05131B", borderRadius: "8px" }}>
                      <CustomTextField
                        variant="filled"
                        disabled={userData?.kyc_status}
                        placeholder="Enter last name"
                        autoComplete="off"
                        // name="name"
                        {...registerName("last_name", {
                          required: {
                            value: true,
                            message: "Last name is required",
                          },
                          pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message: "Please enter valid last name",
                          },
                          minLength: {
                            value: 2,
                            message: "Please enter valid last name",
                          },
                        })}
                        InputProps={{
                          //if kyc status is true then make the input field readOnly
                          readOnly: userData?.kyc_status,
                          endAdornment: userData
                            ?.kyc_status ? null : (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={(e) => setValueName("last_name", "")}
                              >
                                <Close
                                  sx={{ color: "#FFFFFF !important" }}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(errorsName.last_name)}
                        // helperText={errorsName.last_name?.message}
                        label="Last Name"
                        sx={{ input: { color: "#FFFFFF !important" } }}
                      />
                    </Box>
                    {Boolean(errorsName.last_name) && (
                      <Typography className="CustomFormHelperText">
                        Last name is required
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid mt={2} container direction="row" spacing={1}>
                  <Grid item md={6} sm={12}>
                    <Box sx={{ background: "#05131B", borderRadius: "8px" }}>
                      <CustomTextField
                        variant="filled"
                        disabled={userData?.kyc_status}
                        placeholder="Enter email address"
                        autoComplete="off"
                        // name="name"
                        {...registerName("email", {
                          required: {
                            value: true,
                            message: "Email is required",
                          },
                          pattern: {
                            // value: /^[a-zA-Z]+$/,
                            value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                            message: "Please enter valid email address.",
                            // message: "Email is required.",
                          },
                        })}
                        InputProps={{
                          //if kyc status is true then make the input field readOnly
                          readOnly: userData?.kyc_status,
                          endAdornment: userData
                            ?.kyc_status ? null : (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={(e) => setValueName("email", "")}
                              >
                                <Close
                                  sx={{ color: "#FFFFFF !important" }}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(errorsName.email)}
                        // helperText={errorsName.last_name?.message}
                        label="Email Address"
                        sx={{ input: { color: "#FFFFFF !important" } }}
                      />
                    </Box>
                    {Boolean(errorsName.email) && (
                      <Typography className="CustomFormHelperText">
                        Email address is required
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Box sx={{ mt: 2 }}>
                  <Tooltip arrow title={userData?.kyc_status ? "Your KYC is complete so you can not update your profile" : ""}>
                    <span style={{ padding: '12px 0px' }}>
                      <Button disabled={userData?.kyc_status ? true : false} type="submit" className="btn-rounded btn-teal-100">
                        Save Changes
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              </Paper>
            </Box>
            {/* KYC  */}
            <IndentityKyc />
          </Stack>
        </Grid>
      </Grid>
      <BluePrintNFTSelection
        open={openBluePrintSelection}
        setOpen={setOpenBluePrintSelection}
        onClose={handleBluePrintClose}
      />
      <CommonBackdropLoader loading={loading} />

    </Box>
  );
};

export default PersonalInformation;
