import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Grid,
  Modal,
  Typography,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import FileViewer from "react-file-viewer";
import { deleteApi, GetApi } from "../../Api/Api";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { ArrowDropDown } from "@mui/icons-material";

export default function Documents({
  documentsData,
  descriptionData,
  ownerId,
  assetId,
  editable,
  section_title,
}) {
  const [showMore, setShowMore] = useState(false);

  // Merge the two arrays
  let mergedArray = documentsData?.length ? documentsData : [];
  if (descriptionData?.length > 0) {
    const documentOnly = descriptionData.filter(
      (item) =>
        item?.images &&
        item?.type === "document" &&
        item?.images?.type !== "mp4"
    );
    if (documentOnly?.length > 0) {
      mergedArray = mergedArray.concat(documentOnly);
    }
  }

  const [view, setView] = useState(false);
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState(mergedArray);

  /**
   * Function to view the PDF 
   * @param {*} documentdata 
   */
  const viewDoc = async (documentdata) => {
    setOpenModal(true);
    setView(true);
    if (documentdata?.images.path) {
      const fileName = await fetch(
        `${process.env.REACT_APP_IMAGE_URL}${documentdata.images.path ? documentdata.images.path : ""
        }`
      );
      const templateBuffer = await fileName.arrayBuffer();
      const mimeType = 'application/pdf'; // Since viewDoc is only for PDFs

      const templateBlob = new Blob([templateBuffer], { type: mimeType });
      const templateUrl = URL.createObjectURL(templateBlob);
      setFile(templateUrl);
      setType(documentdata.images.type);
      setView(true);
      setOpenModal(true);
    }
  };

  /**
   * Function to download the document
   * @param {*} documentData 
   */
  const downloadDoc = async (documentData) => {
    if (documentData?.images?.path) {
      try {
        // Fetch the file data
        const response = await fetch(documentData.images.path);
  
        if (!response.ok) {
          throw new Error(`Failed to fetch file: ${response.statusText}`);
        }
  
        // Get the file data as an ArrayBuffer
        const fileBuffer = await response.arrayBuffer();
  
        // Determine the MIME type and file extension based on the file type
        let mimeType = '';
        let extension = '';
  
        switch (true) {
          // Document types
          case documentData.images.type.includes('msword'): // Flexible for .doc
            mimeType = 'application/msword';
            extension = '.doc';
            break;
          case documentData.images.type.includes('openxmlformats-officedocument.wordprocessingml.document'):
            mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            extension = '.docx';
            break;
          case documentData.images.type.includes('spreadsheetml.sheet'): // Handles Excel .xlsx files
            mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            extension = '.xlsx';
            break;
          case documentData.images.type.includes('ms-excel'): // Handles Excel .xls files
            mimeType = 'application/vnd.ms-excel';
            extension = '.xls';
            break;
          case documentData.images.type.includes('presentationml.presentation'): // Handles PowerPoint .pptx files
            mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
            extension = '.pptx';
            break;
          case documentData.images.type.includes('ms-powerpoint'): // Handles PowerPoint .ppt files
            mimeType = 'application/vnd.ms-powerpoint';
            extension = '.ppt';
            break;
          case documentData.images.type.includes('rtf'): // Handles .rtf files
            mimeType = 'application/rtf';
            extension = '.rtf';
            break;
          case documentData.images.type.includes('oasis.opendocument.text'): // Handles ODT files
            mimeType = 'application/vnd.oasis.opendocument.text';
            extension = '.odt';
            break;
          case documentData.images.type.includes('oasis.opendocument.spreadsheet'): // Handles ODS files
            mimeType = 'application/vnd.oasis.opendocument.spreadsheet';
            extension = '.ods';
            break;
          case documentData.images.type.includes('oasis.opendocument.presentation'): // Handles ODP files
            mimeType = 'application/vnd.oasis.opendocument.presentation';
            extension = '.odp';
  
          // Archive types
          case documentData.images.type.includes('zip'): // Handles .zip files
            mimeType = 'application/zip';
            extension = '.zip';
            break;
          case documentData.images.type.includes('rar'): // Handles .rar files
            mimeType = 'application/x-rar-compressed';
            extension = '.rar';
            break;
          case documentData.images.type.includes('tar'): // Handles .tar files
            mimeType = 'application/x-tar';
            extension = '.tar';
            break;
          case documentData.images.type.includes('gzip'): // Handles .gz files
            mimeType = 'application/gzip';
            extension = '.gz';
            break;
          case documentData.images.type.includes('7z'): // Handles .7z files
            mimeType = 'application/x-7z-compressed';
            extension = '.7z';
            break;
  
          default:
            console.error('Unsupported MIME type:', documentData.images.type);
            throw new Error('Unsupported file type');
        }
  
        // Create a Blob from the file data
        const fileBlob = new Blob([fileBuffer], { type: mimeType });
        const fileUrl = URL.createObjectURL(fileBlob);
  
        // Create a link element, set its attributes, and simulate a click to download the file
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = documentData.images.original_name || `document${extension}`; // Use original name or fallback
        document.body.appendChild(link);
        link.click();
  
        // Cleanup
        document.body.removeChild(link);
        URL.revokeObjectURL(fileUrl);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    }
  };
  
  

  useEffect(() => {
    setData(mergedArray);
  }, [documentsData, descriptionData]);

  const handleClose = () => setOpenModal(false);

  const handleView = () => {
    setView(!view);
  };

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  // handle show more button
  const handleShowMore = () => {
    setShowMore(true);
  };

  return (
    <>
      <Box className="pd-sidebar-documents" mb={3}>
        <Stack
          className="pd-sidebar-heading"
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className="title" component={"h4"}>
            {section_title}
          </Typography>
        </Stack>

        {/* Dynamically rendring documents with viewer */}

        {data && data.length > 0 ? (
          <>
            {data?.map((val, ind) => {
              if (ind < 5 || showMore) {
                return (
                  // <Card key={ind} className="pd-card">
                  <Card className="pd-action-card" key={ind}>
                    <CardHeader
                      avatar={
                        <Avatar
                          aria-label="recipe"
                          className="bg-blue-900"
                          variant="square"
                          sx={{
                            width: "48px",
                            height: "48px",
                            borderRadius: "12px",
                          }}
                        >
                          <AssignmentIcon />
                        </Avatar>
                      }
                      action={
                        <>
                          <Box className="btn-wrap">
                            <Stack
                              direction="row"
                              spacing={1.5}
                              alignItems={"center"}
                            >
                              {val?.images?.type === "pdf" ? (
                                <IconButton
                                  aria-label="View"
                                  size="large"
                                  className="square-icon-btn"
                                  onClick={() => viewDoc(val)}
                                >
                                  <RemoveRedEyeIcon />
                                </IconButton>
                              ) : (
                                <IconButton
                                  aria-label="Download"
                                  size="large"
                                  className="square-icon-btn"
                                  onClick={() => downloadDoc(val)}
                                >
                                  <DownloadForOfflineIcon />
                                </IconButton>
                              )}
                            </Stack>
                          </Box>
                        </>
                      }
                      onClick={() => { val?.images?.type === "pdf" ? viewDoc(val) : downloadDoc(val) }}
                      subheader={val.type}
                      title={<Tooltip title={val.images.original_name}>
                        <span>{val.images.original_name}</span>
                      </Tooltip>}
                    />
                  </Card>
                );
              } else {
                return null;
              }
            })}

            {showMore === false && data?.length > 5 && (
              <Stack flexDirection={"row"} mt={2} justifyContent={"flex-end"}>
                <Stack flexDirection={"row"} alignItems="center">
                  <Typography>More</Typography>
                </Stack>
                <Box sx={{ cursor: "pointer" }} onClick={handleShowMore}>
                  <ArrowDropDown />
                </Box>
              </Stack>
            )}
          </>
        ) : (
          <Typography textAlign={"center"}>
            No documents availiable to view
          </Typography>
        )}
      </Box>

      <Grid>
        {/* {view &&
          <Modal
            className="projectDocumentsModal"
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            fullWidth={true}
          >
            <Box sx={styleModal}>
              <OverlayScrollbarsComponent defer
                style={{ width: '100%', height: '100%' }}
              >
                <Box className="raj">
                  {<FileViewer fileType={type} filePath={file} />}
                </Box>
              </OverlayScrollbarsComponent>
            </Box>
          </Modal>
        } */}
        {view && type && file && (
          <Modal
            className="projectDocumentsModal"
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            fullWidth={true}
          >
            <Box sx={styleModal}>
              {type?.includes("pdf") ? (
                <object
                  data={file}
                  type="application/pdf"
                  style={{ width: '100%', height: '100%', borderRadius: '12px' }}
                >
                  <p style={{ textAlign: 'center' }}>
                    Your browser does not support viewing PDFs.{' '}
                    <a href={file} target="_blank" style={{ color: 'lightgreen' }}>
                      Download the PDF
                    </a>.
                  </p>
                </object>
              ) : type?.includes("wordprocessingml.document") ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                  <p>Your browser does not support viewing Word documents.</p>
                  <a href={URL.createObjectURL(new Blob([file], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" }))} download="document.docx" style={{ color: 'lightgreen' }}>
                    Download the Word document
                  </a>
                </div>
              ) : type?.includes("spreadsheetml.sheet") ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                  <p>Your browser does not support viewing Excel documents.</p>
                  <a href={URL.createObjectURL(new Blob([file], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))} download="document.xlsx" style={{ color: 'lightgreen' }}>
                    Download the Excel document
                  </a>
                </div>
              ) : (
                <p>Unsupported file type.</p>
              )}
            </Box>
          </Modal>
        )}
      </Grid>
    </>
  );
}
