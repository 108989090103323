import React, { useState, useCallback, useEffect } from "react";
import { Box, Typography, Button, Modal, Stack, Avatar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { Upload, Cancel } from "@mui/icons-material";
import { CircleCheckIcon } from "../../../../src/Components/Images/Images";
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import { updateApi } from "../../../Api/Api";
import "./EditDocumentsPopup.css"
import { useDropzone } from "react-dropzone";
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";

const EditDocumentsPopup = ({ onOpen, handleClose, profileData, getProfileData }) => {
    const { showSnackbar } = useSnackbar();
    const [docFiles, setDocFiles] = useState([]);
    const [uploadedImage, setUploadedImage] = useState([]);
    const [deletedDoc, setDeletedDoc] = useState([]);
    const [existingFiles, setExistingFiles] = useState([]);
  
    useEffect(() => {
        if(profileData?.documents && profileData?.documents.length > 0){
            const tempArr = profileData?.documents.map((item) => ({...item, isDeleted: false}));
            setExistingFiles(tempArr);
        }
    },[profileData?.documents]);
    // handle uploaded docs
    const onDrop = useCallback(
        (acceptedFiles) => {
            const pdfFilesArray = acceptedFiles.filter(
                (file) =>
                    file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                    file.type === "application/pdf"
            );
            let dataDetail = [...uploadedImage, ...pdfFilesArray];
            setUploadedImage(dataDetail);
        },
        [uploadedImage]
    );

    const { getRootProps, open, getInputProps } = useDropzone({
        noClick: true,
        noKeyboard: true,
        accept: {
            'application/pdf': ['.pdf'],
        },
        onDrop,
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        let updateData = new FormData();
        if(existingFiles && existingFiles.length > 0){
            let existingFile = existingFiles.map((item) => {
                return {
                    id: item?.id,
                    mimetype: item.type,
                    location: item.path,
                    originalname: item.original_name,
                    key: item.filename,
                    isDeleted: item?.isDeleted
                }
            })
            updateData.append(`existingFiles`, JSON.stringify(existingFile));
        }
        uploadedImage?.forEach((val) => {
            updateData.append("document", val);
        });
        updateApi(`/services/updateProfile/${profileData.id}`, updateData).then((res) => {
          if (res?.status === 200) {
            getProfileData();
            showSnackbar('Updated Successfully', 'success');
            handleClose();
          } else { // something went wrong user or backend side
            showSnackbar(res?.data?.message, 'error');
            handleClose();
          }
        });
    };

    // handle deleted docs
    const handleDeleteImage = async (file) => {
        if (file) {
            const newDocFile = docFiles.filter((docFile) => {
                setDeletedDoc([...deletedDoc, docFile.id]);
                return docFile.image !== file;
            });
            setDocFiles(newDocFile);
        }
        const newUploadedImages = uploadedImage.filter(
            (image) => image.path !== file.path
        );
        setUploadedImage(newUploadedImages);
    };  

    const handleDeleteExistingFile = async (file) => {
        const newUploadedImages = existingFiles.map(
          (item) => {
            if(item.id == file.id){
              return {
                ...item,
                isDeleted: true
              }    
            }
            return item;
          }
        );
        setExistingFiles(newUploadedImages);
    };

    const files = uploadedImage?.map((file) => (
        <Box className="showUploadedDocuments">
            <Box
                key={file.path}
                className="showUploadedDocuments-wrap"
                display={"flex"}
                alignItems={"center"}
            >
                <Box className="fileName" mr={1}>
                    {file?.path?.includes("services/uploads/")
                        ? file.original_name
                        : file.name}
                </Box>
                <Cancel
                    onClick={() => handleDeleteImage(file)}
                    className="removeIcon"
                />
            </Box>
        </Box>
    ));

    return (
        <Modal
            open={onOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-main"
        >
            <Box className="popup-main">
                <form onSubmit={handleSubmit}>
                    <Box>
                        <Box className="close-icon-main">
                            <IconButton className='close-btn' onClick={handleClose}>
                                <CloseIcon className="cross-icon-modal"/>
                            </IconButton>
                        </Box>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            mt={2}
                            className="heading font-28"
                            sx={{ textAlign: "center" }}
                        >
                            Edit Documents
                        </Typography>
                        <Box mt={3}>
                            <Box className="upload-section">
                                <Box className="attach-file-btn" mt={2}>
                                    <Box
                                        {...getRootProps({
                                            className: "dropzone",
                                        })}
                                    >
                                        <input {...getInputProps()} />
                                        <Stack direction="row" spacing={0} flexWrap={"wrap"}>
                                            <Box className="btn-wrap" mb={{ xs: 2, sm: 4, md: 3 }}>
                                                <Button
                                                    className="btn-rounded btn-blue-600 btn-text-white"
                                                    onClick={open}
                                                    startIcon={<Upload />}
                                                    sx={{ color: "white" }}
                                                >
                                                    Attach Files
                                                </Button>
                                            </Box>
                                        </Stack>
                                    </Box>
                                    {files !== "" ? (
                                        <Box
                                            sx={{
                                                paddingBottom: "8px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            {files?.map((fileName, ind) => {
                                                return (
                                                    <>
                                                        {" "}
                                                        <Box
                                                            alignItems={"center"}
                                                            mt={1}
                                                            mb={2}
                                                            key={ind}
                                                            sx={{ display: "flex" }}
                                                        >
                                                            <Avatar
                                                                src={CircleCheckIcon}
                                                                alt="Icon"
                                                                sx={{ width: 24, height: 24 }}
                                                            />
                                                            <Box
                                                                className="dD-file-name text-black"
                                                                ml={1}
                                                                sx={{ color: "#fff" }}
                                                            >
                                                                {fileName}
                                                            </Box>
                                                        </Box>
                                                    </>
                                                );
                                            })}
                                        </Box>
                                    ) : (
                                        ""
                                    )}
                                    {existingFiles && existingFiles.length > 0 && existingFiles?.map((file, ind) => {
                                        return !file?.isDeleted && (
                                            <>
                                            {" "}
                                            <Box
                                                alignItems={"center"}
                                                mt={1}
                                                mb={2}
                                                key={ind}
                                                sx={{ display: "flex" }}
                                            >
                                                <Avatar
                                                src={CircleCheckIcon}
                                                alt="Icon"
                                                sx={{ width: 24, height: 24 }}
                                                />
                                                <Box
                                                className="dD-file-name text-black"
                                                ml={1}
                                                sx={{ color: "#fff" }}
                                                >
                                                {/* {file?.original_name} */}
                                                <Box className="showUploadedDocuments">
                                                    <Box
                                                    key={file.path}
                                                    className="showUploadedDocuments-wrap"
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    >
                                                    <Box className="fileName" mr={1}>
                                                        {file?.original_name}
                                                    </Box>
                                                    <Cancel
                                                        onClick={() => handleDeleteExistingFile(file)}
                                                        className="removeIcon"
                                                    />
                                                    </Box>
                                                </Box>
                                                </Box>
                                            </Box>
                                            </>
                                        );
                                    })}
                                </Box>
                                <br />
                            </Box>
                        </Box>
                        <Box sx={{ textAlign: "center" }} mt={3}>
                            <Button
                                type="submit"
                                variant="contained"
                                startIcon={<SaveIcon />}
                                sx={{
                                    background: "var(--golden-gradient)",
                                    color: "var(--blueGray-900)",
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </form>

            </Box>
        </Modal>
    );
};

export default EditDocumentsPopup;
