import "react-responsive-carousel/lib/styles/carousel.min.css";
import React, { useEffect, useState } from "react";
import { Box, Grid, Pagination, Typography, Button } from "@mui/material";
import Card from "../Components/MainCard/MainCard";
import Filter from "../Components/Filters/Filter";
import ServicesSearch from "../Components/ServicesSearch/ServicesSearch";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GetApiParam } from "../../Api/Api";
import { getUserDataFromLocalStorage } from "../../Services/localstorage.service";
import "./CategoryPage.css";
const CategoryPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { title } = location.state || {};
  const loginUserData = getUserDataFromLocalStorage();

  // Function to navigate to a specific route
  const [categoryData, setCategoryData] = useState(null);
  const [serviceData, setServicesData] = useState([]);
  const [topRatedServiceData, setTopRatedServiceData] = useState([]);
  
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ itemsPerPage, setItemsPerPage ] = useState(8);
  const [ paginationData, setPaginationData ] = useState({
    "page": 1,
    "pageSize": 5,
    "totalCount": 0,
    "totalPages": 0
  });

  const [searchString, setSearchString] = useState('');
  const [filter, setFilter] = useState({
    "priceRangeStart": null,
    "priceRangeEnd": null,
    "location": null,
    "rating": null,
    "sort": null,
    "savedRequest": null,
  });

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  // Effect hook to fetch category data from an API when the component mounts
  useEffect(() => {
    // Api to get categoryData
    async function getCategoryData() {
      try {
        const payload = { user_id: loginUserData?.id };
        const res = await GetApiParam("/services/spCategory", payload);
        setCategoryData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
    getCategoryData()
  }, []);

  async function getServicesData() {
    try {
      let payload = { login_user_id :loginUserData?.id };
      if (id) {
        payload = { ...payload, sp_category_id: id };
      }
      if(filter?.savedRequest === "savedRequest"){
        payload = {
          ...payload,
          savedRequest: true
        }
      }
      payload = {
        ...payload,
        searchString: searchString
      }
      if(filter?.sort && filter?.sort !== 'all'){
        payload = {
          ...payload,
          sort: filter?.sort
        }
      }
      if(filter?.location && filter?.location !== 'all'){
        payload = {
            ...payload,
            location: filter?.location
        }
      }
      if(filter?.rating && filter?.rating !== 'all'){
        payload = {
            ...payload,
            rating: filter?.rating
        }
      }
      if(filter?.rating === 0){
        payload = {
          ...payload,
          notRated: true
        }
      }
      if((filter?.priceRangeStart || filter?.priceRangeStart === 0) && (filter?.priceRangeEnd || filter?.priceRangeEnd === 0)){
          payload = {
            ...payload,
            priceRangeStart: filter?.priceRangeStart,
            priceRangeEnd: filter?.priceRangeEnd
          }
      }
      const res = await GetApiParam(`/services/getServices?page=${currentPage}&rowperpage=${itemsPerPage}`, payload);
      setServicesData(res?.data?.data?.length ? res.data.data : []);
      setPaginationData(res.data.pagination);
    } catch (error) {
      console.log(error);
    }
  }

  async function getTopRatedServicesData() {
    try {
      let payload = { login_user_id :loginUserData?.id, topRated: true };
      if (id) {
        payload = { ...payload, sp_category_id: id };
      }
      if(filter?.savedRequest === "savedRequest"){
        payload = {
          ...payload,
          savedRequest: true
        }
      }
      payload = {
        ...payload,
        searchString: searchString
      }
      if(filter?.sort && filter?.sort !== 'all'){
        payload = {
          ...payload,
          sort: filter?.sort
        }
      }
      if(filter?.location && filter?.location !== 'all'){
        payload = {
            ...payload,
            location: filter?.location
        }
      }
      if((filter?.priceRangeStart || filter?.priceRangeStart === 0) && (filter?.priceRangeEnd || filter?.priceRangeEnd === 0)){
          payload = {
            ...payload,
            priceRangeStart: filter?.priceRangeStart,
            priceRangeEnd: filter?.priceRangeEnd
          }
      }
      const res = await GetApiParam(`/services/getServices?page=${1}&rowperpage=${4}`, payload);
      setTopRatedServiceData(res?.data?.data?.length ? res.data.data : []);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    // Api to get service data
    getServicesData();
  }, [id, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    // Api to get service data
    getServicesData();
    getTopRatedServicesData();
  }, [filter, searchString]);

  useEffect(() => {
    // Api to get service data
    getTopRatedServicesData();
  }, [id])

  const handleSearchResults = (searchInput) => {
    setSearchString(searchInput);
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);
  return (
    <Box>
      <Box className="home-exploreBanner-section ">
        <Box className="container">
          <Box mb={3} px={{md:'10px'}} >
            <ServicesSearch 
              showSearchBar={true}
              handleSearchResults={handleSearchResults}
              pageTitle={'Service Catalog'}
              categoryData={categoryData}
            />
          </Box>
        </Box>

        {/* Filters */}
        <Box mb={2}>
          <Filter 
            title={title === 'Technology Developers & Software Services' ? 'Development & IT' : title}
            filter={filter}
            setFilter={setFilter}
          />
        </Box>

        {/* Main-card -Top Rated*/}
        <Box >
          <Box className='main-card bg'  >
          <Box className="container">
            <Box className='topRated' mb={2} display={'flex'} alignItems={'center'}>
              <Button
                className="top-rated-button"
                style={{ background: "var(--golden-gradient)" }}
              >
                Top Rated
              </Button>
              <Typography className="font-18 text-white" ml={{ xs: '10px', md: '10px', sm: '10px' }}>Top-rated services you may like</Typography>
            </Box>
            <Box className="top-rated-container">
            <Grid container spacing={1}>
              {topRatedServiceData && topRatedServiceData?.length > 0 && topRatedServiceData.map((card, index) => (
                <Grid key={index} item md={3} sm={6} xs={12}>
                  <Card {...card} />
                </Grid>
              ))}
            </Grid>
            </Box>
            </Box>
          </Box>

          {/* All */}
          <Box className="container">
          <Box className="card-category-container">
            <Grid container spacing={1}>
              {serviceData?.map((card, index) => (
                <Grid key={index} item md={3} sm={6} xs={12}>
                  <Card {...card} />
                </Grid>
              ))}
            </Grid>
            <Box className="pagination-container">
              <Pagination
                count={paginationData.pageSize === 'all' ? 1 : Math.ceil(paginationData?.totalCount/Number(paginationData.pageSize))}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </Box>
          </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default CategoryPage;
