import React, { useEffect, useState } from "react";
import LandingPagesFactory from "./LandingPagesFactory";
import { useParams } from "react-router-dom";
import { GetApi } from "../Api/Api";
import { useSnackbar } from "../Contexts/SnackbarContext";

const AssetCategoryPage = () => {
  // Retrieve the 'type' parameter from the URL using the 'useParams' hook
  const { type } = useParams();
  const [id, setId] = useState(null)

  const { showSnackbar } = useSnackbar();

  // Create a 'Page' object using the 'LandingPagesFactory' function, passing the 'type' as an argument
  const Page = LandingPagesFactory(type, id);

  Page.setPageComponents();

  // Setting scroll to top on page after 0.5 sec
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });// Scrolls to the top of the page
    }, 500);
  }, []);

  useEffect(() => {
    // Api to get categoryData
    async function getCategoryData() {
      try {
        const res = await GetApi("/proposer/getCategory/");
        if (res?.data?.data && res?.data?.data?.length) {
          const categoryData = res?.data?.data
          const categoryId = categoryData.map((val, ind) => {
            return val.id
          })
          setId(categoryId)
        }
        else
          setId(null)
      } catch (error) {
        showSnackbar('Error in fetching category details', 'error')
      }
    }
    getCategoryData();
  }, []);

  return (
    <div>
      {Page.pageComponenets.map((component, index) => {
        return component;
      })}
    </div>
  );
};

export default AssetCategoryPage;
