import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { GetApi } from "../../Api/Api";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import SliderCard from "./SliderCard";

const AssetsSlider = () => {
  // State to store category data
  const [categoryData, setCategoryData] = useState();

  // Extract showSnackbar function from the useSnackbar custom hook
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    // Function to fetch category data from the API
    async function getCategoryData() {
      try {
        const res = await GetApi("/proposer/getCategory/");
        if (res?.data?.data && res?.data?.data?.length)
          setCategoryData(res?.data?.data);
        else
          setCategoryData([])
      } catch (error) {
        showSnackbar(error, 'error')
      }
    }
    getCategoryData();
  }, []);

  return (
    <>
      <Grid container spacing={0.6}>
        {categoryData &&
          categoryData?.map((val, ind) => {
            return (
              <Grid key={ind} item xs={4} sm={3} md={2} lg={2} className="hc-thumbnail-column">
                <Box className="hc-thumbnail-wrap" mb={0.5}>
                  <Link to={`/asset-category/${val.url}`}>
                    <SliderCard
                      image={`${process.env.REACT_APP_IMAGE_URL}${val.category_image.path}`}
                      title={val.title}
                    />
                  </Link>
                </Box>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default AssetsSlider;
