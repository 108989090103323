import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, TextField, IconButton } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

// Import custom components for this page
import { NewLogo } from '../../../Components/Images/Images'
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import NumericFormatCustomDollar from "../../../Components/Common/NumericFormatCustomDollar";
import NumericFormatCustom from "../../../Components/Common/NumericFormatCustom";
import "./Components/Questionnaire.css"
import NumericFormatCustomPercent from "../../../Components/Common/NumericFormatCustomPercent";
import { formatNumber } from "../../../Components/Common/USFormat";
import { SUB_CATEGORY_TYPE_ID } from "../../../constants";

export default function FundsFractionDetails(props) {
  const { showSnackbar } = useSnackbar()

  // Destructure props to obtain necessary functions and data
  const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

  // Get current proposal and progress data from the parent component
  var propData = getProposalData();
  let currentProgress = getProgress();

  const [names, setNames] = useState(propData?.capital_type_list?.length ? propData?.capital_type_list : [])

  // state to hold max limit for project price
  const [isMaxLimitExceeded, setIsMaxLimitExceeded] = useState(false);
  const [inBetweenRange, setInBetweenRange] = useState(false);
  const [maxLimit, setMaxLimit] = useState(0);
  const [nextMaxLimit, setNextMinLimit] = useState(0);
  const [preMinLimit, setPreMaxLimit] = useState(0);

  // Initialize state variables with default values from 'propData' or fallback values
  const [raise, setRaise] = useState(propData?.fractionalize_total_price ? propData?.fractionalize_total_price : 0)
  const [minimum_investment, setMinimumInvestment] = useState(propData?.fractionalize_minimum_investment ? propData?.fractionalize_minimum_investment : 0)
  const [projectDuration, setProjectDuration] = useState(propData?.fractionalize_duration_of_project ? propData?.fractionalize_duration_of_project : 0)
  const [sharedClasses, setSharedClasses] = useState(propData?.number_of_shared ? propData?.number_of_shared : 0)

  const [investmentDuration, setInvestmentDuration] = useState(propData?.investment_duration ? propData?.investment_duration : 0)
  const [managementFees, setManagementFees] = useState(propData?.management_fees ? formatNumber(propData?.management_fees) : 0)
  const [tokenName, setTokenName] = useState(propData?.tokenName ? propData?.tokenName : '')
  const [tokenSymbol, setTokenSymbol] = useState(propData?.tokenSymbol ? propData?.tokenSymbol : '')
  const [managementAddress, setManagementAddress] = useState(propData?.managementAddress ? propData?.managementAddress : '')
  const [carriedInterest, setCarriedInterest] = useState(propData?.carried_interest ? formatNumber(propData?.carried_interest) : 0)
  const [isSubmitted, setIsSubmitted] = useState(false);

  /**
   * Function to increment or decrement values by $500
   * @param {String} type 
   * @param {Function} setFunction 
   */
  const handleChangeAmount = (type, setFunction) => {
    if (type === 'inc') {
      setFunction(prevCount => prevCount ? (parseFloat(prevCount) + 500) : 500);
    } else {
      setFunction(prevCount => (parseFloat(prevCount) >= 500 ? parseFloat(prevCount) - 500 : prevCount));
    }
  }

  /**
   * Function to increment or decrement 'projectDuration' by 1 
   * @param {String} type 
   */
  const handleProjectDuration = (type, setFunction) => {
    if (type === 'inc') {
      setFunction(prevCount => prevCount ? (parseInt(prevCount) + 1) : 1)
    } else {
      setFunction(prevCount => (parseInt(prevCount) >= 1 ? parseInt(prevCount) - 1 : prevCount))
    }
  }

  /**
  * Function to increment or decrement 'sharedClasses' by 1
  * @param {String} type 
  */
  const handleSharedClasses = (type) => {
    if (type === 'inc') {
      setSharedClasses(prevCount => prevCount ? (parseInt(prevCount) + 1) : 1)
    } else {
      setSharedClasses(prevCount => (parseInt(prevCount) >= 1 ? parseInt(prevCount) - 1 : prevCount))
    }
  }

  /**
  * Function to handle change in interest rate loan + 0.25%, minimum interest rate can be 1%
  * @param {String} type 
  */
  const changeInterestRate = (type, setFunction) => {
    if (type === "inc") {
      setFunction((prevCount) => prevCount ? parseFloat(prevCount) + 0.25 : 1);
    } else {
      setFunction((prevCount) =>
        parseFloat(prevCount) >= 1.25 ? parseFloat(prevCount) - 0.25 : prevCount
      );
    }
  };

  /**
   * Handle "Next" button click 
   */
  const handleClick = () => {
    setIsSubmitted(true);

    if (propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS && !(Boolean(parseFloat(minimum_investment) <= parseFloat(raise)) && minimum_investment !== '' && minimum_investment > 0 && Boolean((parseFloat(minimum_investment) + parseFloat(raise * managementFees / 100)) <= parseFloat(raise)) && Boolean(parseFloat(projectDuration * 365) >= parseFloat(investmentDuration)) && raise !== "" && raise > 0 && projectDuration !== "" && projectDuration > 0 && investmentDuration !== "" && investmentDuration > 0 && managementFees !== "" && managementFees > 0 && managementFees <= 100 && carriedInterest !== "" && carriedInterest > 0 && carriedInterest <= 100 && !isMaxLimitExceeded && !inBetweenRange && tokenName !== '' && tokenSymbol !== '' && managementAddress !== '' && (/^0x[a-fA-F0-9]{40}$/.test(managementAddress)))) {
      return false;
    }

    if (propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.OPEN_FUNDS && !(raise !== "" && projectDuration !== "" && sharedClasses !== "" && raise > 0 && projectDuration > 0 && sharedClasses < 3 && sharedClasses > 0 && !isMaxLimitExceeded && !inBetweenRange)) {
      return false;
    }

    if (propData?.stackAmtData?.length === 0) {
      return false
    }

    if (propData?.shared_data?.length > sharedClasses && propData?.shared_data?.length > 0) {
      propData.shared_data = propData?.shared_data?.slice(0, sharedClasses);
    }
    propData.fractionalize_total_price = raise;
    propData.fractionalize_duration_of_project = projectDuration;
    // Update properties in 'propData' based on state variables
    if (propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
      propData.fractionalize_minimum_investment = minimum_investment;
      propData.investment_duration = investmentDuration;
      propData.management_fees = managementFees;
      propData.tokenName = tokenName;
      propData.tokenSymbol = tokenSymbol;
      propData.managementAddress = managementAddress;
      propData.carried_interest = carriedInterest;
    } else {
      propData.number_of_shared = parseInt(sharedClasses);
      propData.capital_type_list = names;
    }

    handleNext()

    // Call 'updateProposalData' function with 'propData'
    updateProposalData(propData);
  }

  /**
   *  Handle "Save and Exit" button click 
   */
  const SaveAndExit = () => {
    setIsSubmitted(true);

    if (propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS && !(Boolean(parseFloat(minimum_investment) <= parseFloat(raise)) && minimum_investment !== '' && minimum_investment > 0 && Boolean((parseFloat(minimum_investment) + parseFloat(raise * managementFees / 100)) <= parseFloat(raise)) && Boolean(parseFloat(projectDuration * 365) >= parseFloat(investmentDuration)) && raise !== "" && raise > 0 && projectDuration !== "" && projectDuration > 0 && investmentDuration !== "" && investmentDuration > 0 && managementFees !== "" && managementFees > 0 && managementFees <= 100 && carriedInterest !== "" && carriedInterest > 0 && carriedInterest <= 100 && !isMaxLimitExceeded && !inBetweenRange && tokenName !== '' && tokenSymbol !== '' && managementAddress !== '' && (/^0x[a-fA-F0-9]{40}$/.test(managementAddress)))) {
      return false;
    }

    if (propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.OPEN_FUNDS && !(raise !== "" && projectDuration !== "" && sharedClasses !== "" && raise > 0 && projectDuration > 0 && sharedClasses < 3 && sharedClasses > 0 && !isMaxLimitExceeded && !inBetweenRange)) {
      return false;
    }

    if (propData?.stackAmtData?.length === 0) {
      return false
    }

    if (propData?.shared_data?.length > sharedClasses && propData?.shared_data?.length > 0) {
      propData.shared_data = propData?.shared_data?.slice(0, sharedClasses);
    }
    propData.fractionalize_total_price = raise;
    propData.fractionalize_duration_of_project = projectDuration;
    // Update properties in 'propData' based on state variables
    if (propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
      propData.fractionalize_minimum_investment = minimum_investment;
      propData.investment_duration = investmentDuration;
      propData.management_fees = managementFees;
      propData.tokenName = tokenName;
      propData.tokenSymbol = tokenSymbol;
      propData.managementAddress = managementAddress;
      propData.carried_interest = carriedInterest;
    } else {
      propData.number_of_shared = parseInt(sharedClasses);
      propData.capital_type_list = names;
    }

    // Call 'updateProposalData' function with 'propData'
    saveProposal();
    updateProposalData(propData);
  }

  // updating capital type list on propData change
  useEffect(() => {
    if (propData?.capital_type_list?.length) {
      setNames(propData?.capital_type_list)
    }
  }, [propData])

  useEffect(() => {
    // console.log("raise called")
    if (raise) {
      // checking for max range
      let checkMaxLimitExceeded = true, checkMaxLimit = 0, checkPreMaxLimit = 0, checkNextMinLimit = 0;
      let stackAmtData = propData?.stackAmtData;
      let projectAmt = parseFloat(raise)
      if (stackAmtData?.length) {
        let newStake = stackAmtData
        if (propData?.asset_sub_category) {
          newStake = stackAmtData?.filter((item) => item?.asset_sub_category === propData?.asset_sub_category)
        }

        newStake?.forEach((dataItem) => {
          checkPreMaxLimit = checkMaxLimit
          checkNextMinLimit = dataItem?.min_amount
          checkMaxLimit = dataItem?.max_amount
          if (parseFloat(dataItem?.min_amount) <= parseFloat(projectAmt) && parseFloat(projectAmt) <= parseFloat(dataItem?.max_amount)) {
            checkMaxLimitExceeded = false
          }
        })
        setIsMaxLimitExceeded(checkMaxLimitExceeded)
        setMaxLimit(checkMaxLimit)
        setNextMinLimit(checkNextMinLimit)
        setPreMaxLimit(checkPreMaxLimit)
      }
      if (!checkMaxLimitExceeded) {
        setInBetweenRange(false)
      }
      // check for max range exceeded
      if (checkMaxLimitExceeded && checkMaxLimit > 0 && (parseFloat(checkPreMaxLimit) < parseFloat(projectAmt) && parseFloat(projectAmt) < parseFloat(checkNextMinLimit))) {
        showSnackbar(`You cannot create a project in range from $${checkPreMaxLimit} to $${checkNextMinLimit}`, 'warning');
        setInBetweenRange(true)
        setIsMaxLimitExceeded(false)
      } else if (checkMaxLimitExceeded && checkMaxLimit > 0) {
        showSnackbar(`You cannot create a project that exceeds the set range configuration $${checkMaxLimit}`, 'error');
      } else if (checkMaxLimitExceeded && parseFloat(checkMaxLimit) === 0) {
        showSnackbar(`Range configuration is not set for the asset type`, 'error');
      }
    }
  }, [raise])

  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left" >
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  Now, set your price
                </Typography>
              </Box>
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right  quests-right-contents-box">
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  <Button onClick={SaveAndExit}
                    // disabled={

                    //   (propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ?
                    //     (Boolean(parseFloat(minimum_investment) <= parseFloat(raise)) && minimum_investment !== '' && minimum_investment > 0 && Boolean((parseFloat(minimum_investment) + parseFloat(raise * managementFees / 100)) <= parseFloat(raise)) && Boolean(parseFloat(projectDuration * 365) >= parseFloat(investmentDuration)) && raise !== "" && raise > 0 && projectDuration !== "" && projectDuration > 0 && investmentDuration !== "" && investmentDuration > 0 &&
                    //       managementFees !== "" && managementFees > 0 && carriedInterest !== "" && carriedInterest > 0 && !isMaxLimitExceeded && !inBetweenRange)
                    //     :
                    //     (raise !== "" && projectDuration !== "" && sharedClasses != "" && raise > 0 && projectDuration > 0 && sharedClasses < 3 && sharedClasses > 0 && !isMaxLimitExceeded && !inBetweenRange)) ? false : true
                    // }
                    className="btn-rounded btn-text-white btn-blue-600">
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box className="qsn-middle-content" sx={{ pt: 4, mb: 5 }}>
                <Box>
                  <Grid container spacing={{ xs: 1, md: 1, sm: 1 }} my={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography className="inputFieldTitle">Total Raise</Typography>
                      <Box className='NS-sale-details'>
                        <Box className='NS-sale-details-icon'>
                          <IconButton onClick={() => { handleChangeAmount('dec', setRaise) }} className="NS-sale-details-icon-btn">
                            <RemoveIcon />
                          </IconButton>
                        </Box>
                        <Box>
                          <TextField
                            id="outlined-number"
                            type="text"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustomDollar,
                            }}
                            size="small"
                            helperText={"Total Raise"}
                            value={raise}
                            onChange={(e) => setRaise(parseFloat(e.target.value))}
                            onKeyDown={(event) => {
                              if (event?.key === '-' || event?.key === '+') {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Box>
                        <Box className='NS-sale-details-icon'>
                          <IconButton onClick={() => { handleChangeAmount('inc', setRaise) }} className="NS-sale-details-icon-btn">
                            <AddIcon />
                          </IconButton>
                        </Box>
                      </Box>
                      {(isSubmitted && !raise) && <Typography className="font-12" sx={{ color: 'red', textAlign: 'center', marginTop: 1 }}>{'Total raise must be greater than 0.'}</Typography>}
                    </Grid>

                    {propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ?
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography className="inputFieldTitle">Minimum Investment</Typography>
                        <Box className='NS-sale-details'>
                          <Box className='NS-sale-details-icon'>
                            <IconButton onClick={() => { handleChangeAmount('dec', setMinimumInvestment) }} className="NS-sale-details-icon-btn">
                              <RemoveIcon />
                            </IconButton>
                          </Box>
                          <Box>
                            <TextField
                              id="outlined-number"
                              type="text"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                inputComponent: NumericFormatCustomDollar,
                              }}
                              value={minimum_investment}
                              size="small"
                              helperText={"Minimum Investment"}
                              onChange={(e) => setMinimumInvestment(parseFloat(e.target.value))}
                              onKeyDown={(event) => {
                                if (event?.key === '-' || event?.key === '+') {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Box>
                          <Box className='NS-sale-details-icon'>
                            <IconButton onClick={() => { handleChangeAmount('inc', setMinimumInvestment) }} className="NS-sale-details-icon-btn">
                              <AddIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        {(isSubmitted && !minimum_investment) && <Typography className="font-12" sx={{ color: 'red', textAlign: 'center', marginTop: 1 }}>{'Minimum Investment must be greater than 0.'}</Typography>}
                      </Grid> : null
                    }

                    <Grid item xs={12} md={propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? 6 : 12} lg={propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? 6 : 12}>
                      <Typography
                        fontWeight={"500"}
                        sx={{ color: "black", textAlign: "center", my: 2.5 }}
                      >
                        {propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ?
                          "Initial Project Term" : "Project Duration"}
                      </Typography>
                      <Box className='NS-sale-details'>
                        <Box className='NS-sale-details-icon'>
                          <IconButton onClick={() => { handleProjectDuration('dec', setProjectDuration) }} className="NS-sale-details-icon-btn">
                            <RemoveIcon />
                          </IconButton>
                        </Box>
                        <Box>
                          <TextField
                            id="outlined-number"
                            type="text"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={projectDuration}
                            size="small"
                            helperText={(projectDuration > 1 ? "Years" : 'Year')}
                            onChange={(e) => setProjectDuration(parseFloat(e.target.value))}
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                            onKeyDown={(event) => {
                              if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Box>
                        <Box className='NS-sale-details-icon'>
                          <IconButton onClick={() => { handleProjectDuration('inc', setProjectDuration) }} className="NS-sale-details-icon-btn">
                            <AddIcon />
                          </IconButton>
                        </Box>
                      </Box>
                      {(isSubmitted && !projectDuration) && <Typography className="font-12" sx={{ color: 'red', textAlign: 'center', marginTop: 1 }}>{'Project duration must be at least 1 year.'}</Typography>}
                    </Grid>

                    {propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ?
                      <>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          {/* <Typography className="inputFieldTitle" sx={{ marginBottom: '8px !important' }}>Fund Raising Period</Typography> */}
                          <Typography
                            fontWeight={"500"}
                            sx={{ color: "black", textAlign: "center", my: 2.5 }}
                          >
                            Fund Raising Period
                          </Typography>
                          <Box className='NS-sale-details'>
                            <Box className='NS-sale-details-icon'>
                              <IconButton onClick={() => { handleProjectDuration('dec', setInvestmentDuration) }} className="NS-sale-details-icon-btn">
                                <RemoveIcon />
                              </IconButton>
                            </Box>
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={investmentDuration}
                                size="small"
                                helperText={investmentDuration > 1 ? "Days" : 'Day'}
                                onChange={(e) => setInvestmentDuration(parseFloat(e.target.value))}
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                }}
                                onKeyDown={(event) => {
                                  if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                            <Box className='NS-sale-details-icon'>
                              <IconButton onClick={() => { handleProjectDuration('inc', setInvestmentDuration) }} className="NS-sale-details-icon-btn">
                                <AddIcon />
                              </IconButton>
                            </Box>
                          </Box>
                          {(isSubmitted && !investmentDuration) && <Typography className="font-12" sx={{ color: 'red', textAlign: 'center', marginTop: 1 }}>{'Fund raising period must be above 0.'}</Typography>}
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                          <Typography
                            fontWeight={"500"}
                            sx={{ color: "black", textAlign: "center", my: 2.5 }}
                          >
                            Management Fees
                          </Typography>
                          <Box className="NS-sale-details">
                            <Box className="NS-sale-details-icon">
                              <IconButton
                                onClick={() => {
                                  changeInterestRate("dec", setManagementFees);
                                }}
                                className="NS-sale-details-icon-btn"
                              >
                                <RemoveIcon />
                              </IconButton>
                            </Box>
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={managementFees}
                                onChange={(e) => setManagementFees(parseFloat(e.target.value))}
                                InputProps={{
                                  inputComponent: NumericFormatCustomPercent,
                                }}
                                onKeyDown={(event) => {
                                  if (event?.key === '-' || event?.key === '+') {
                                    event.preventDefault();
                                  }
                                }}
                                size="small"
                              />
                            </Box>
                            <Box className="NS-sale-details-icon">
                              <IconButton
                                onClick={() => {
                                  changeInterestRate("inc", setManagementFees);
                                }}
                                className="NS-sale-details-icon-btn"
                              >
                                <AddIcon />
                              </IconButton>
                            </Box>
                          </Box>
                          {(isSubmitted && !managementFees) && <Typography className="font-12" sx={{ color: 'red', textAlign: 'center', marginTop: 1 }}>{'Management fees must be above 0.'}</Typography>}
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                          <Typography
                            fontWeight={"500"}
                            sx={{ color: "black", textAlign: "center", my: 2.5 }}
                          >
                            Carried Interest
                          </Typography>
                          <Box className="NS-sale-details">
                            <Box className="NS-sale-details-icon">
                              <IconButton
                                onClick={() => {
                                  changeInterestRate("dec", setCarriedInterest);
                                }}
                                className="NS-sale-details-icon-btn"
                              >
                                <RemoveIcon />
                              </IconButton>
                            </Box>
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={carriedInterest}
                                onChange={(e) => setCarriedInterest(parseFloat(e.target.value))}
                                InputProps={{
                                  inputComponent: NumericFormatCustomPercent,
                                }}
                                onKeyDown={(event) => {
                                  if (event?.key === '-' || event?.key === '+') {
                                    event.preventDefault();
                                  }
                                }}
                                size="small"
                              />
                            </Box>
                            <Box className="NS-sale-details-icon">
                              <IconButton
                                onClick={() => {
                                  changeInterestRate("inc", setCarriedInterest);
                                }}
                                className="NS-sale-details-icon-btn"
                              >
                                <AddIcon />
                              </IconButton>
                            </Box>
                          </Box>
                          {(isSubmitted && !carriedInterest) && <Typography className="font-12" sx={{ color: 'red', textAlign: 'center', marginTop: 1 }}>{'Carried interest must be above 0.'}</Typography>}
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                          <Typography
                            fontWeight={"500"}
                            sx={{ color: "black", textAlign: "center", my: 2.5 }}
                          >
                            Token Name
                          </Typography>
                          <Box className="">
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Please enter token symbol"
                                value={tokenName}
                                onChange={(e) => setTokenName(e.target.value)}
                                size="small"
                              // error={isSubmitted && tokenName === ''}
                              // helperText={isSubmitted && tokenName === '' ? 'Token name is required.' : ''}
                              />
                            </Box>
                            {(isSubmitted && !tokenName) && <Typography className="font-12" sx={{ color: 'red', textAlign: 'left', marginTop: 1 }}>{'Token name is required.'}</Typography>}
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                          <Typography
                            fontWeight={"500"}
                            sx={{ color: "black", textAlign: "center", my: 2.5 }}
                          >
                            Token Symbol
                          </Typography>
                          <Box className="">
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                placeholder="Please enter token symbol"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={tokenSymbol}
                                onChange={(e) => setTokenSymbol(e.target.value)}
                                size="small"
                              // error={isSubmitted && tokenSymbol === ''}
                              // helperText={isSubmitted && tokenSymbol === '' ? 'Token symbol is required.' : ''}
                              />
                            </Box>
                            {(isSubmitted && !tokenSymbol) && <Typography className="font-12" sx={{ color: 'red', textAlign: 'left', marginTop: 1 }}>{'Token symbol is required.'}</Typography>}
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            fontWeight={"500"}
                            sx={{ color: "black", textAlign: "center", my: 2.5 }}
                          >
                            Management Address
                          </Typography>
                          <Box className="">
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                placeholder="Please enter management address"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={managementAddress}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setManagementAddress(value);
                                }}
                                size="small"
                              // error={
                              //   isSubmitted &&
                              //   (!/^0x[a-fA-F0-9]{40}$/.test(managementAddress) || managementAddress === '')
                              // }
                              // helperText={
                              //   isSubmitted &&
                              //     (!/^0x[a-fA-F0-9]{40}$/.test(managementAddress) || managementAddress === '')
                              //     ? 'Please enter a valid wallet address.'
                              //     : ''
                              // }
                              />
                            </Box>
                            {isSubmitted && (!/^0x[a-fA-F0-9]{40}$/.test(managementAddress) || managementAddress === '') && <Typography className="font-12" sx={{ color: 'red', textAlign: 'left', marginTop: 1 }}>{'Please enter a valid wallet address.'}</Typography>}
                          </Box>
                        </Grid>
                      </>
                      :
                      <>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            fontWeight={"500"}
                            sx={{ color: "black", textAlign: "center", my: 2.5 }}
                          >
                            No. of Shared Classes
                          </Typography>
                          {/* <Typography className="inputFieldTitle" sx={{ marginBottom: '8px !important' }}>No. of Shared Classes</Typography> */}
                          <Box className='NS-sale-details'>
                            <Box className='NS-sale-details-icon'>
                              <IconButton disabled={sharedClasses === 0 ? true : false} onClick={() => { handleSharedClasses('dec') }} className="NS-sale-details-icon-btn">
                                <RemoveIcon />
                              </IconButton>
                            </Box>
                            <Box>
                              <TextField
                                id="outlined-number"
                                type="text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={sharedClasses}
                                disabled
                                size="small"
                                // onChange={(e) => setSharedClasses(e.target.value)}
                                // InputProps={{
                                //   inputComponent: NumericFormatCustom,
                                // }}
                                onKeyDown={(event) => {
                                  if (event?.key === '-' || event?.key === '+' || event?.key === ".") {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                            <Box className='NS-sale-details-icon'>
                              <IconButton disabled={sharedClasses > 1 ? true : false} onClick={() => { handleSharedClasses('inc') }} className="NS-sale-details-icon-btn">
                                <AddIcon />
                              </IconButton>
                            </Box>
                          </Box>
                          {(isSubmitted && !sharedClasses) && <Typography className="font-12" sx={{ color: 'red', textAlign: 'center', marginTop: 1 }}>{'No. of shared classes must be greater than 0.'}</Typography>}
                        </Grid>
                      </>
                    }
                  </Grid>
                </Box>
                {propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS
                  ? <Typography sx={{ color: 'red', fontSize: '11px', textAlign: 'center', marginTop: 2.5 }}>
                    {
                      Boolean(parseFloat(minimum_investment) > parseFloat(raise)) ? "Minimum investment can not be greater than total raise." : Boolean((parseFloat(minimum_investment) + parseFloat(raise * managementFees / 100)) > parseFloat(raise)) ? "Minimum investment + Management fees must not exceed total raise." : Boolean(parseFloat(projectDuration * 365) < parseFloat(investmentDuration)) ? "Investment duration can not be greater than project duration." : parseFloat(carriedInterest) > 100 ? 'Carried interest can not be greater than 100.' :
                        parseInt(sharedClasses) > 2 ? "No. of shared classes can not be more than two" : isMaxLimitExceeded ? `You cannot create the project above $${maxLimit}` : inBetweenRange ? `You cannot create a project in range from $${preMinLimit} to $${nextMaxLimit}` : ""
                    }
                  </Typography>
                  : <Typography sx={{ color: 'red', fontSize: '11px', textAlign: 'center', marginTop: 2.5 }}>
                    {
                      // Boolean(parseFloat(minimum_investment) > parseFloat(raise)) ? "Minimum investment can not be greater than total raise." : 
                      parseInt(sharedClasses) > 2 ? "No. of shared classes can not be more than two" : isMaxLimitExceeded ? `You cannot create the project above $${maxLimit}` : inBetweenRange ? `You cannot create a project in range from $${preMinLimit} to $${nextMaxLimit}` : ""
                    }
                  </Typography>
                }
              </Box>
              <Box>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={3}>
                  <Box textAlign={"left"} className='quests6-btn-box'>
                    <Button className='btn-rounded back-btn' onClick={handleback}>
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"} className='quests-btn-box'>
                    <Button
                      // disabled={
                      //   (propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ?
                      //     (Boolean(parseFloat(minimum_investment) <= parseFloat(raise)) && minimum_investment !== '' && minimum_investment > 0 && Boolean((parseFloat(minimum_investment) + parseFloat(raise * managementFees / 100)) <= parseFloat(raise)) && Boolean(parseFloat(projectDuration * 365) >= parseFloat(investmentDuration)) && raise !== "" && raise > 0 && projectDuration !== "" && projectDuration > 0 && investmentDuration !== "" && investmentDuration > 0 &&
                      //       managementFees !== "" && managementFees > 0 && carriedInterest !== "" && carriedInterest > 0 && !isMaxLimitExceeded && !inBetweenRange)
                      //     :
                      //     (raise !== "" && projectDuration !== "" && sharedClasses != "" && raise > 0 && projectDuration > 0 && sharedClasses < 3 && sharedClasses > 0 && !isMaxLimitExceeded && !inBetweenRange)) ? false : true
                      // }
                      onClick={() => handleClick()} endIcon={<KeyboardArrowRightIcon />} className='btn-rounded btn-text-white btn-blue-600'>
                      Next
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box >
  );
}



