import React, { useEffect, useState } from "react";
import { useAccount, useNetwork } from "wagmi";
import { useDispatch } from "react-redux";
import { Close, Done } from "@mui/icons-material";
import { Box, Button, Typography, IconButton, Modal, Backdrop, CircularProgress, Chip, Stepper, Step, StepLabel, StepContent } from "@mui/material";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { GetApi, postApi } from "../../Api/Api";
import { update } from "../../features/auth/authSlice";
import { race } from "../../Web3/race-chain";
import ConnectWalletInvestor from "../../Web3/ConnectWalletInvestor";
import SnackbarAlert from "../../Components/Common/SnackbarAlert";

// smart contract methods
import * as methods from "./Method";  // Import all methods from a single file
import '../../Pages/ProjectProposer/Questionnaire/Components/ProposerStakeRaceTokens.css'
import { SP_CONTRACTS } from "../ServiceProviderWeb3/ServiceProviderContract";

// Actions grouped into a single object
const {
    checkBalance,
    checkAllowance,
    approveToken,
    Registration,
    PostNewProject,
    CreateNewContract,
    AcceptOriginalOffer,
    SubmitProjectRequest,
    RejectCustomOffer,
    AcceptOffer,
    CloseProject,
    SubmitContractDelivery,
    ApproveContractDelivery,
    CreateNewService,
    RejectContractDelivery,
    RejectMilestone,
    SubmitMilestone,
    UpdateAvailability,
    ChangeHourlyRate,
    RejectOriginalOffer,
    DirectHire
} = methods;


// Main
export default function SpTransactionProgressModal(props) {
    const dispatch = useDispatch();
    const { chain } = useNetwork();
    console.log('sp transaction progress modal props...', props)

    const identity = props?.identity; // variable to identify the method type

    // Getting user wallet address
    const { address } = useAccount(); // wagmi (Hook) for accessing user account information

    // Wallet disconnect function
    const { showSnackbar } = useSnackbar(); // Hook for displaying snackbar or notifications

    // State variables initialization
    const [activeStep, setActiveStep] = useState(-1); // State variable for tracking active step in a progress modal
    const [associatedWallet, setAssociatedWallet] = useState(null); // State variable to hold associate wallet address in a progress modal
    const [isAssociated, setIsAssociated] = useState(null); // State variable to hold associate wallet address in a progress modal

    // State to check the token status
    const [TokenStatus, SetTokenStatus] = useState('')
    const [openSnackbar, setOpenSnackbar] = useState(false)

    // Default steps for a process
    const [steps, setSteps] = useState([
        { label: 'Wallet Connected', description: `You need to connect your wallet account.` }, // Step indicating that the wallet is connected
        { label: 'Confirm Transaction', description: `You need to pay a transaction fee.` } // Step waiting for transaction confirmation
    ]);


    // monitor changes in wallet address
    useEffect(() => {
        console.log("wallet address", address); // Logging wallet address when it changes
    }, [address]); // Dependency array to trigger effect on changes to 'address'

    // Extracting necessary data from props
    let assetData = props?.propData; // Amount to stake
    let stakeAmt = props?.propData?.stakeAmt; // Amount to stake

    const localData = JSON.parse(localStorage.getItem('user_data'))

    // get decimals based on selected fees token type
    let decimals = (assetData?.feeTokenType == 'USDT' || assetData?.feeTokenType == 'USDC') ? 1e6 : 1e18;
    console.log(decimals / 1e18);
    /**
     * Function to set the disclaimer message for each step
     * @param {string} val The identity string
     * @returns {string[]} Array of disclaimer messages
     */
    const disclaimer = (val) => {
        switch (val) {
            case "accept-original-offer":
                return [
                    "You need to connect your wallet account.",
                    "You need to approve tokens.",
                    "You need to pay the stake amount."
                ];
            case "accept-offer":
                return [
                    "You need to connect your wallet account.",
                    "You need to approve tokens.",
                    "You need to pay the stake amount."
                ];
            default:
                return ["", "", ""];
        }
    };

    /**
     * Function to set the description text
     */
    useEffect(() => {
        if (identity === 'accept-original-offer' || identity === "accept-offer") {
            // Setting new steps for the process
            const newSteps = [
                { label: "Wallet Connected", description: disclaimer(identity)[0] },
                { label: "Approve Tokens", description: disclaimer(identity)[1] },
                { label: "Confirm Payment", description: disclaimer(identity)[2] }
            ];
            setSteps(newSteps);
        }
    }, [identity]);

    /**
     * Calling Api to check the Token Current Status
     */
    useEffect(() => {
        async function CheckToken() {
            try {
                const checkTokenRes = await GetApi("/user/checkToken")
                if (checkTokenRes?.data?.code === 200 && checkTokenRes?.data?.status) {
                    SetTokenStatus(checkTokenRes?.data)
                }
            } catch (error) {
                console.log(error);
            }
        }
        CheckToken();
    }, []);

    // smart contract -- start
    useEffect(() => {
        if (TokenStatus && chain?.id == race?.id) {
            if (TokenStatus?.status) {
                if (address) {
                    // Function to make transaction to blockchain
                    async function makeTransaction() {
                        try {
                            // Set active step to 1
                            setActiveStep(1);

                            console.log("iden-----",identity);

                            // Fetching balance of connected wallet address
                            const balanceOf = await checkBalance(address, "DAI")

                            // Set the allowance amount and spender contract address
                            let allowanceAmt = null;
                            let spenderAddress = null;
                            if (identity === 'accept-original-offer') {
                                allowanceAmt = stakeAmt
                                spenderAddress = SP_CONTRACTS.SERVICE_MARKETPLACE_PROVIDER
                            } else
                                if (identity === 'accept-offer') {
                                    allowanceAmt = stakeAmt
                                    spenderAddress = SP_CONTRACTS.SERVICE_MARKETPLACE_CLIENT
                                }

                            console.log('allowanceAmt', allowanceAmt);
                            console.log('spenderAddress', spenderAddress);

                            // check if allowance amount and spender address exist
                            if (allowanceAmt && spenderAddress) {

                                // Return and Exit if insufficient RACE/USDT Tokens balance in the wallet
                                if (Number(balanceOf) / decimals < Math.ceil(allowanceAmt * 10) / 10) {
                                    showSnackbar(`Insufficient ${"DAI" ? "DAI" : 'USDT'} Tokens`, 'error');
                                    props?.handleModalClose();
                                    return
                                }

                                // Now check for allowance RACE/USDT Tokens
                                const allowance = await checkAllowance(address, spenderAddress, 'DAI');

                                // If not enough allowance available then, call approve method
                                if (parseFloat((allowance) / decimals).toFixed(2) < Math.ceil(allowanceAmt * 10) / 10) {
                                    const data = await approveToken(
                                        address, // user wallet address
                                        spenderAddress, // spender address
                                        Number(parseFloat(allowanceAmt)), // allowance amount
                                        'DAI', // fee token type
                                    )
                                    if (data.status === "success") {
                                        console.log('data', data)
                                        showSnackbar("Transaction Successful", 'success')
                                    } else {
                                        props?.handleModalClose();
                                        setActiveStep(-1)
                                        showSnackbar("Transaction Failed", 'error')
                                        return
                                    }
                                }

                                // Now check for allowance RACE/USDT Tokens
                                const newAllowance = await checkAllowance(address, spenderAddress, 'DAI');
                                // Return and Exit if insufficient RACE/USDT Tokens allowance to spender contract
                                if (parseFloat(newAllowance / decimals).toFixed(2) < Math.ceil(allowanceAmt * 10) / 10) {
                                    showSnackbar('Insufficient allowance', 'error');
                                    props?.handleModalClose();
                                    return
                                }
                            }

                            // Now call the method as required
                            switch (identity) {
                                case 'sp-registration':
                                    await handleRegistration();
                                    break;
                                case 'post-new-project':
                                    await handlePostNewProject();
                                    break;
                                case 'send-offer':
                                    await handleSendOffer();
                                    break;
                                case 'create-new-contract':
                                    await handleCreateNewContract();
                                    break;
                                case 'reject-custom-offer':
                                    await handleRejectedCustomOffer();
                                    break;
                                case 'accept-offer':
                                    await handleAcceptOffer();
                                    break;
                                case 'accept-original-offer':
                                    await handleAcceptOriginalOffer();
                                    break;
                                case 'reject-original-offer':
                                    await handleRejectOriginalOffer();
                                    break;
                                case 'sp-service-create':
                                    await handleCreateNewService();
                                    break;
                                case 'close-project':
                                    await handleCloseProject();
                                    break;
                                case 'reopen-project':
                                    await handleReOpenProject();
                                    break;
                                case 'submit-contract-delivery':
                                    await handleSubmitContractDelivery();
                                    break;
                                case 'approve-contract-delivery':
                                    await handleApproveContractDelivery();
                                    break;
                                case 'reject-contract-delivery':
                                    await handleRejectContractDelivery();
                                    break;
                                case 'reject-milestone':
                                    await handleRejectMilestone();
                                    break;
                                case 'submit-milestone':
                                    await handleSubmitMilestone();
                                    break;
                                case 'approve-milestone':
                                    await handleApproveMilestone();
                                    break;
                                case 'update-availability':
                                    await handleUpdateAvailability();
                                    break;
                                case 'change-hourly-rate':
                                    await handleChangeHourlyRate();
                                    break;
                                case 'update-bidding-amount':
                                    await handleUpdateBiddingAmount();
                                    break;
                                case 'withdraw-amount-provider':
                                    await handleWithdrawAmountProvider();
                                    break;
                                case 'direct-hire':
                                    await handleDirectHire();
                                    break;
                                case 'delivery-date-ext-req':
                                    await handleDeliveryDateExtReq();
                                    break;
                                case 'approve-delivery-date-ext-req':
                                    await handleApproveDeliveryDateExtReq();
                                    break;
                                case 'modify-contract-offer-request':
                                    await handleModifyContractOfferReq();
                                    break;
                                case 'accept-modify-contract-offer-request':
                                    await handleAcceptModifyContractOfferReq();
                                    break;
                                case 'reject-modify-contract-offer-request':
                                    await handleRejectModifyContractOfferReq();
                                    break;

                                default:
                                    // handle default case if needed
                                    break;
                            }

                            /**
                            * handle proposer stack amount for project propose
                            */
                            async function handleRegistration() {
                                await Registration(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }
                            /**
                            * handle create new service
                            */
                            async function handleCreateNewService() {
                                await CreateNewService(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }
                            /**
                            * handle post new project
                            */
                            async function handlePostNewProject() {
                                await PostNewProject(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                             * handle send an offer on project
                            */
                            async function handleSendOffer() {
                                await SubmitProjectRequest(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                            * Create a contract on the project
                            */
                            async function handleCreateNewContract() {
                                await CreateNewContract(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                             * Create a contract for accepting the offer
                             */
                            async function handleAcceptOriginalOffer() {
                                await AcceptOriginalOffer(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                             * reject offer by service provider
                             */
                            async function handleRejectOriginalOffer() {
                                await RejectOriginalOffer(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                             * Handle Cancel Offer function
                             */
                            async function handleRejectedCustomOffer() {
                                await RejectCustomOffer(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                             * Handle Accept Offer function
                             */
                            async function handleAcceptOffer() {
                                await AcceptOffer(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                             * Handle Accept Offer function
                             */
                            async function handleCloseProject() {
                                await CloseProject(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                             * Handle reopen project
                             */
                            async function handleReOpenProject() {
                                await methods.ReOpenProject(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                             * Handle Accept Offer function
                             */
                            async function handleSubmitContractDelivery() {
                                await SubmitContractDelivery(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                             * Handle Accept Offer function
                             */
                            async function handleApproveContractDelivery() {
                                await ApproveContractDelivery(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                             * Handle Reject Contract Delivery Function
                             */
                            async function handleRejectContractDelivery() {
                                await RejectContractDelivery(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                             * Handle Reject Contract Delivery Function
                             */
                            async function handleRejectMilestone() {
                                await RejectMilestone(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                             * Handle Submit Contract Delivery Function
                             */
                            async function handleSubmitMilestone() {
                                await SubmitMilestone(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                             * Handle Submit Contract Delivery Function
                             */
                            async function handleApproveMilestone() {
                                await methods.ApproveMilestone(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                             * Handle update provider availability
                             */
                            async function handleUpdateAvailability() {
                                await UpdateAvailability(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                             * Handle update/change hourly rate 
                             */
                            async function handleChangeHourlyRate() {
                                await ChangeHourlyRate(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                             * Handle update/change hourly rate 
                             */
                            async function handleUpdateBiddingAmount() {
                                await methods.UpdateBiddingAmount(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                             * Handle update/change hourly rate 
                             */
                            async function handleWithdrawAmountProvider() {
                                await methods.ClaimPaymentForProvider(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                             * Handle direct hire 
                             */
                            async function handleDirectHire() {
                                await methods.DirectHire(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                             * Handle delivery date ext request 
                             */
                            async function handleDeliveryDateExtReq() {
                                await methods.DeliveryDateExtensionReq(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                             /**
                             * Handle delivery date ext request approve
                             */
                             async function handleApproveDeliveryDateExtReq() {
                                await methods.ApproveDeliveryDateExtensionReq(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }
                            /**
                             * Handle modify contract offer request
                             */
                            async function handleModifyContractOfferReq() {
                                await methods.SendContractOfferModifyReq(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData)
                            }
                            /**
                             * Handle accept modify contract offer request
                             */
                            async function handleAcceptModifyContractOfferReq() {
                                await methods.AcceptContractOfferModifyReq(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData)
                            }
                            /**
                             * Handle reject modify contract offer request
                             */
                            async function handleRejectModifyContractOfferReq() {
                                await methods.RejectContractOfferModifyReq(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData)
                            }

                        } catch (error) {
                            console.log('error', error)
                            if (error?.cause?.reason && error?.cause?.reason?.split(":")?.length) {
                                let reason = error.cause.reason;
                                let message = reason?.split(":")
                                showSnackbar(message[1] ? message[1] : message[0], 'error')
                            } else {
                                showSnackbar("Transaction Failed", 'error')
                            }
                            props?.handleModalClose({ warning: identity === 'sp-registration' ? true : false });
                        }
                    }
                    // Function to check associate wallet with the account
                    async function checkAssociatedWallet() {
                        try {
                            // const checkRes = await postApi(`/user/checkWallet`, { wallet_address: address?.toLowerCase(), user_id: localData?.id });
                            const checkRes = await postApi(`/user/checkWallet`, { user_id: localData?.id });

                            if (checkRes?.data?.status && checkRes?.data?.data?.wallet_address?.toLowerCase() == address?.toLowerCase()) {
                                setActiveStep(0);
                                setAssociatedWallet(address);
                                makeTransaction();
                                handleCloseDialog();
                            } else if (checkRes?.data?.data?.wallet_address) {
                                handleCloseDialog();
                                setActiveStep(0);
                                showSnackbar('This wallet is not associated with the account', 'error');
                            } else {
                                handleCloseDialog();
                                setActiveStep(0);
                                setTimeout(() => {
                                    // disconnect();
                                    // props?.handleModalClose();
                                }, 1000)
                                return
                            }
                        } catch (error) {
                            showSnackbar('There was an error', 'error');
                            console.log('There was an error')
                        }
                    }
                    // Call the function checkAssociatedWallet
                    checkAssociatedWallet()
                } else {
                    setActiveStep(0);
                }
            } else {
                setOpenSnackbar(true)
                setTimeout(() => {
                    window.location.href = '/';
                }, 4000);
                handleCloseDialog()
            }
        }

    }, [address, TokenStatus, chain, isAssociated])
    //  smart contract -- end

    /**
     * Set the active step value
     * @param {Number} value 
     */
    const handleActiveStep = (value) => {
        console.log("value-----",value);
        setActiveStep(value)
    }

    //Wallet connection code start
    const [walletDialogOpen, setWalletDialogOpen] = useState(false);

    /**
     * Function to handle wallet modal open
     */
    const handleClickOpen = async () => {
        setWalletDialogOpen(true);
    };

    /**
     * Function to handle wallet modal close
     */
    const handleCloseDialog = () => {
        setWalletDialogOpen(false);
    };

    /**
     * gets called when a wallet is connect successfully
     * @param {*} acc
     */
    const walletLogin = async (acc, isAssociate) => {
        dispatch(update({ wallet_address: acc?.toLowerCase() }));
        setAssociatedWallet(acc?.toLowerCase())
        if (isAssociate)
            setIsAssociated(true)
    };
    //Wallet connection code end

    /**
     * Function to  handle custom icons
     * @returns custom icons for progress steps
     */
    const CustomStepIcon = ({ active, completed, index }) => {
        if (completed) {
            // You can customize the completed state icon here
            return <Chip label={<Done />} color="primary" className="item-completed" size="small" />;
        }

        // Customize the active state icon with CircularProgress loader
        return active ? <CircularProgress color="inherit" size={20} /> :
            <Chip label={index + 1} color="primary" className="item-indexing" size="small" />; // Change the icon based on your preference
    };

    /**
     * Function to handle modal close
     * @param {Event} reason 
     * @returns 
     */
    const handleWalletTransactionModal = (reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown' || reason?.code === "Escape") {
            return; //do nothing basically do not close modal
        } else {
            props?.handleModalClose({ warning: true });
        }
    };

    /**
    * handles snackbar close
    * @param {*} event 
    * @param {*} reason 
    * @returns 
    */
    const handleClose = (event, reason) => {
        setOpenSnackbar(false);
    };

    return (
        <>
            {/* Stepper modal for transaction flow for abi*/}
            <Modal
                open={props?.openTransactionModal}
                onClose={handleWalletTransactionModal}
                className="kyc-modal wallet-transaction-flow"
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                BackdropComponent={Backdrop}
                BackdropProps={{ open: false }}
                sx={{ backdropFilter: 'blur(2px)' }}
            >
                <Box className="set-stack-rts common-modal-design">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                        {/* {activeStep >= steps?.length - 1 ? */}
                        {activeStep > 0 ?
                            <></>
                            :
                            <IconButton >
                                <Close style={{ color: '#fff' }} onClick={handleWalletTransactionModal} />
                            </IconButton>
                        }
                    </Box>
                    <Box sx={{ maxWidth: 400 }}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps?.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel StepIconComponent={(props) => <CustomStepIcon {...props} index={index} />}>
                                        {!associatedWallet && index === 0 ? <Button className="btn-rounded" onClick={handleClickOpen}>Connect Wallet</Button> : index === 0 ? <Button className="btn-rounded btn-green-400">Wallet connected</Button> : step.label}
                                    </StepLabel>
                                    <StepContent>
                                        <Typography className={associatedWallet && index === 0 ? "wallet-address-text" : ''}>{associatedWallet && index === 0 ? associatedWallet : step.description}</Typography>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </Box>
            </Modal >

            <SnackbarAlert open={openSnackbar} message={"Your Token has been expire , please login again"} severity={"info"} onClose={handleClose} />

            {/* wallet connect component */}
            <ConnectWalletInvestor
                open={walletDialogOpen}
                handleCloseDialog={handleCloseDialog}
                handleConnectedSuccess={walletLogin}
                closeTransactionModal={props?.handleModalClose}
            />
        </>
    );
}
