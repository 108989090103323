import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Avatar, Box, Button, Chip, Typography } from "@mui/material";
import { useSnackbar } from "../../../../Contexts/SnackbarContext";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AssistantIcon from '@mui/icons-material/Assistant';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { formatDate } from '../../../../Utils/utils';
import { GetApi, updateApi } from "../../../../Api/Api";
import CommonBackdropLoader from '../../../../Components/Common/CommonBackdropLoader';
import SpTransactionProgressModal from '../../../SmartContracts/SpTransactionProgressModal';

export default function ActiveJobs({ contractData, getContractData }) {
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const [deliveryExtPayloadData, setDeliveryExtPayloadData] = useState(null);
  const [openTransactionModalDelDtExtReq, setOpenTransactionModalDelDtExtReq] = useState(false);

  const fetchData = useCallback(() => {
    setLoading(true);
    let url = `/services/getOrderActivity/${contractData?.id}?user_role=client&client_id=${contractData?.client_id}`;
    GetApi(url).then((res) => {
      if (res?.status === 200) {
        if (res.data && res.data.data && res.data.data.length > 0) {
          setActivityData(res.data.data);
        } else {
          setActivityData([]);
        }
      }
      setLoading(false);
    })
  }, [contractData]);

  useEffect(() => {
    fetchData();
  }, [contractData]);

  const groupedActivities = useMemo(() => {
    const groupedByDate = activityData?.reduce((acc, item) => {
      // Extract just the date part (YYYY-MM-DD)
      const dateKey = formatDate(item.createdAt, 'MMM d, yyyy');

      if (!acc[dateKey]) {
        acc[dateKey] = []; // Initialize array for the group
      }

      acc[dateKey].push(item); // Push the current item into the group
      return acc;
    }, {});
    return groupedByDate;
  }, [activityData]);

  const deliveryDateUpdateOrCancel = (accept, deliveryDate, activityId) => {
    if (accept === 'accept') {
      setDeliveryExtPayloadData({ accept, deliveryDate, activityId });
      setOpenTransactionModalDelDtExtReq(true);
    } else if (accept === 'cancel') {
      setLoading(true);
      let url = `/services/deliveryDateUpdateOrCancel/${contractData?.id}`;
      updateApi(url, { accept: accept, requested_delivery_date: deliveryDate, activity_id: activityId }).then((res) => {
        if (res?.status === 200) {
          if (res.data && res?.data?.code === 200) {
            showSnackbar(res?.data?.message, 'success');
            getContractData();
          } else {
            showSnackbar(res?.data?.message, 'error');
          }
        }
        fetchData();
      });
    }
  }

  const confirmStakeDeliveryExtReq = (data) => {
    setOpenTransactionModalDelDtExtReq(false);
    setLoading(true);
    let url = `/services/deliveryDateUpdateOrCancel/${contractData?.id}`;
    updateApi(url, { accept: deliveryExtPayloadData?.accept, requested_delivery_date: deliveryExtPayloadData?.deliveryDate, activity_id: deliveryExtPayloadData?.activityId }).then((res) => {
      if (res?.status === 200) {
        if (res.data && res?.data?.code === 200) {
          showSnackbar(res?.data?.message, 'success');
          getContractData();
        } else {
          showSnackbar(res?.data?.message, 'error');
        }
      }
      fetchData();
    });
  }
  const handleModalCloseDeliveryExtReq = () => {
    setOpenTransactionModalDelDtExtReq(false);
  }
  return (
    <Box className="activity-container">
      <CommonBackdropLoader loading={loading} />
      {Object.keys(groupedActivities).map((key) => (
        <>
          <Box className="date-btn">
            <Chip label={key} />
          </Box>
          <>
            {groupedActivities[key].map((row, index) => (
              <Box className={index < groupedActivities[key].length - 1 ? 'user-chat' : 'user-chat-last'}>
                <Box className="chat-left">
                  {row.message_type === 'ORDPLACE' && <ReceiptLongIcon style={{ color: '#053863' }} />}
                  {row.message_type === 'ORDSTART' && <AssistantIcon />}
                  {row.message_type === 'ORDUPDATE' && <AssistantIcon />}
                  {row.message_type === 'REQFORUPDATEDLVRDATE' && <AccessTimeIcon />}
                  {row.message_type === 'ORDDLVRDATEUPDATECNCL' && <AccessTimeIcon />}
                  {row.message_type === 'ORDDLVR' && <RuleFolderIcon />}
                  {row.message_type === 'ORDCOMPLETE' && <ChecklistIcon />}
                  {row.message_type === 'ORDCNCL' && <CancelPresentationIcon style={{ color: '#053863' }} />}
                  {row.message_type === 'ORDDLVRCNCL' && <CancelPresentationIcon style={{ color: '#053863' }} />}
                  {row.message_type === 'ORDDLVRACCEPT' && <CancelPresentationIcon style={{ color: '#053863' }} />}
                </Box>
                <Box className="chat-right">
                  <Typography className="font-16">
                    {row.message_client}
                  </Typography>
                  <Typography className="font-12 text-blueGray-300">
                    {formatDate(row.createdAt, 'MMM dd, h:mm a')}
                  </Typography>
                  {row.message_type === 'REQFORUPDATEDLVRDATE' && row.is_delivery_date_request_accepted === null &&
                    <>
                      <Button className='race-btn-req-delivery-date' variant="contained" onClick={() => deliveryDateUpdateOrCancel('accept', row.requested_delivery_date, row.id)}>Accept</Button>&nbsp;
                      <Button className='race-btn-req-delivery-date' variant="contained" onClick={() => deliveryDateUpdateOrCancel('cancel', row.requested_delivery_date, row.id)}>Reject</Button>
                    </>
                  }
                </Box>
              </Box>
            ))}
          </>
        </>
      ))}
      {/* <Box className="user-chat">
        <Box className="chat-left">
          <Avatar
            alt="Remy Sharp"
            src="/static/images/avatar/1.jpg"
          />
        </Box>
        <Box className="chat-right">
          <Typography className="font-16">Me</Typography>
          <Typography className="font-12 text-blueGray-300">
            Apr 8, 9:46PM
          </Typography>
          <Box className="msg-box">
            <Typography className="font-16">Message</Typography>
            <Typography className="font-12">
              This helps your project post stand out to the right
              service providers. It’s the first thing they will see,
              so make it stick!
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className="user-chat">
        <Box className="chat-left">
          <Avatar
            alt="Remy Sharp"
            src="/static/images/avatar/1.jpg"
          />
        </Box>
        <Box className="chat-right">
          <Typography className="font-16">Usman Abbasi</Typography>
          <Typography className="font-12 text-blueGray-300">
            Apr 8, 9:46PM
          </Typography>
          <Box className="msg-box">
            <Typography className="font-16">Message</Typography>
            <Typography className="font-12">
              This helps your project post stand out to the right
              service providers. It’s the first thing they will see,
              so make it stick!
            </Typography>
          </Box>
          <Box className="img-box">
            <Box className="img-box-div">
              <img src={imgBg} alt="" />
            </Box>
            <Box className="img-box-bottom">
              <Box>
                <Typography className="font-16">Title</Typography>
                <Typography className="font-12 text-blueGray-300">
                  100MB
                </Typography>
              </Box>
              <Box>
                <img src={downloadIcon} alt="" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box> */}
      {openTransactionModalDelDtExtReq ? <SpTransactionProgressModal identity={'approve-delivery-date-ext-req'} confirmStake={confirmStakeDeliveryExtReq} propData={{ contract_blockchain_id: contractData?.blockchain_id }} handleModalClose={handleModalCloseDeliveryExtReq} openTransactionModal={openTransactionModalDelDtExtReq} /> : null}
    </Box>
  )
}
