import React, { useState } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Box, Button } from '@mui/material';
import HeaderDic from '../Header/HeaderDic'
import HeaderDicSignin from "../Header/HeaderDicSignin"
import { squareQuestionsMark } from '../../Components/Images/Images';
import SidebarDic from '../Sidebar/SidebarDic';

export const LayoutDic = () => {
    // Get the navigate and useLocation functions from the respective custom hooks
    const navigate = useNavigate();
    const location = useLocation();
    const login = localStorage.getItem("Diclogin");

    const [sidebarOpen, setSidebarOpen] = useState(false);

    // Function to handle the change in the sidebar state
    const handleSidebarChange = (newState) => {
        // Handle the state change in the sidebar
        setSidebarOpen(newState);
    };

    return (
        <>
            <Box className='main-layout'>
                {login ? <HeaderDic /> : <Navigate to="/" replace />}
                <Box className={`main-page ${(location.pathname == '/dic/chats') ? 'chat-wrapper-dic' : ''}`} display={'flex'}>
                    <Box >
                        <SidebarDic handleSidebarChange={handleSidebarChange} />
                    </Box>
                    <Box className='main-contant'>
                        <Outlet context={sidebarOpen} />
                        {/* {(location.pathname !== '/dic/chats') && <Button className="squareHelp-btn btn-rounded btn-yellow-100 btn-large" onClick={handleClick}>
                            <Avatar alt='Icon' src={squareQuestionsMark} sx={{ width: '36px', height: '36px' }}></Avatar>
                        </Button>
                        } */}
                    </Box>
                </Box>
                {/* <Footer /> */}
            </Box>
        </>
    )
}
