import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Box, Typography } from '@mui/material'
import './ServiceProviderCard.css'
import { PortfolioPic1 } from '../../../Components/Images/Images'
import { useNavigate } from 'react-router-dom';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VideoThumbnail from 'react-video-thumbnail';
function ServiceProviderCard({ profileImage, image, title, link, description }) {
    const navigate = useNavigate();
    const isImage = /\.(jpeg|jpg|png)$/i.test(image);
    const isVideo = /\.(mp4)$/i.test(image);

    let profileAvatar = profileImage
        ? (profileImage instanceof File
            ? URL.createObjectURL(profileImage)
            : `${process.env.REACT_APP_IMAGE_URL}${profileImage}`
        )
        : PortfolioPic1;

    const handleClick = () => {
        navigate(link, {
            state: { image: profileAvatar, title, description },
        });
    };

    return (
        <Box className="pf-card-wrap" onClick={handleClick} style={{ cursor: 'pointer' }}>
            <Box className="pf-image-wrap" >
                {isImage && (
                    <Avatar className="pf-image" src={image ? `${process.env.REACT_APP_IMAGE_URL}${image}`: PortfolioPic1} alt={title} /> 
            )}
               {isVideo && (
                <div className="video-thumbnail">
                    <VideoThumbnail
                        videoUrl={`${process.env.REACT_APP_IMAGE_URL}${image}`} // Ensure the correct URL is used for videos
                        style={{ width: '100%', height: 'auto' }}
                    />
                    <div className="play-icon-overlay">
                        <PlayCircleOutlineIcon fontSize="large" />
                    </div>
                </div>
            )}
            </Box>
            <Box className="pf-title-wrap">
                <Typography variant="subtitle1" className="pf-title font-18" mb={1.5}>{title ? title : 'Virtual Assistant To Help Pull Contact Information'}</Typography>
            </Box>
        </Box>
    )
}

export default ServiceProviderCard


//prop types
ServiceProviderCard.propTypes = {
    profileImage: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
}


