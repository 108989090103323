import React from 'react';
import { Box, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './CategoryCard.css'
import { noImageAvailable } from '../../../Components/Images/Images';

const CategoryCard = ({ page, categoryData, Icon, Heading, SubHeading, url }) => {

    const navigate = useNavigate();
    const handleClick = () => {
        if(page === 'serviceProviderHome'){
            const state = { category: categoryData };
            navigate(`/service-provider/browser-request`, { state });
        }else{
            if (url) {
                const state = { title: Heading };
                navigate(`/service-proposer/client-category/${url}`, { state });
            }
        }
    }
    return (
        <Box className="category-card" onClick={handleClick} style={{ marginLeft: "10px" }}>
            <img className="Icon" src={Icon ? `${process.env.REACT_APP_IMAGE_URL}${Icon}` : noImageAvailable} />
            <Box pt={3}>
                <Typography className="Heading font-24">{Heading}</Typography>
                <Typography className="SubHeading  font-18">{SubHeading}</Typography>
            </Box>
        </Box>
    )
}

CategoryCard.propTypes = {
    Icon: PropTypes.elementType.isRequired,
    Heading: PropTypes.string.isRequired,
    SubHeading: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default CategoryCard;