import "react-responsive-carousel/lib/styles/carousel.min.css";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Grid,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import {
  Person,
} from "@mui/icons-material";
import Hero from "../Components/Hero/Hero";
import {
  RealEstate,
  Arrow,
  Globe,
  handShakeIcon,
  atmCardIcon,
  moreGraphIcon,
  moreBookIcon,
  LeftArrow, RightArrow
} from "../../Components/Images/Images";
import CategoryCard from "../Components/CategoryCard/CategoryCard";
import ServicesSearch from "../Components/ServicesSearch/ServicesSearch";
import RequestCard from "../Components/RequestCard/RequestCard";
import { useForm } from "react-hook-form";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { GetApi, GetApiParam } from "../../Api/Api";
import { Link, useNavigate } from "react-router-dom";
import { getUserDataFromLocalStorage, isUserLoggedIn } from "../../Services/localstorage.service";
import "./HomeServiceProvider.css";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const projectRequests = [
  {
    Icon: Arrow,
    Heading: "1. Create a profile",
    SubHeading:
      "Highlight your skills and experience, show your portfolio, and set your pay rate.",
  },
  {
    Icon: Globe,
    Heading: "2. Search requests",
    SubHeading:
      "Browse project requests based on the hourly or fixed-price work.",
  },
  {
    Icon: handShakeIcon,
    Heading: "3. Apply for projects",
    SubHeading: "Set your rate and let clients know why you are the one.",
  },
  {
    Icon: atmCardIcon,
    Heading: "4. Contract & Order",
    SubHeading:
      "Send a contract and order, once confirmed, you can begin working.",
  },
  {
    Icon: moreGraphIcon,
    Heading: "5. Complete & Deliver",
    SubHeading:
      "Check steps off as you finish and work with your client if you have questions.",
  },
  {
    Icon: moreBookIcon,
    Heading: "6. Get paid securely",
    SubHeading:
      "Once the client approves your work, you'll get paid in RACE tokens and they can leave you feedback.",
  },
];

const HomeServiceProvider = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [profilestate, setProfileState] = useState(false);
  const { showSnackbar } = useSnackbar();

  const localData = getUserDataFromLocalStorage();
  const loginData = isUserLoggedIn();
  const navigate = useNavigate();

  const NextArrow = ({ onClick }) => {
    return <img src={RightArrow} alt="RightArrow" className="arrow-right" onClick={onClick} style={{ cursor: 'pointer' }} />;
  };

  const PrevArrow = ({ onClick }) => {
    return <img src={LeftArrow} alt="LeftArrow" className="arrow-left" onClick={onClick} style={{ cursor: 'pointer' }} />;
  };

  const settings = {
    slidesToShow: 1,
    rows: 2,
    slidesPerRow: 4,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: "center",
    centerPadding: "0px",
    centerMode: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2,
          slidesPerRow: 4,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2,
          slidesPerRow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2,
          slidesPerRow: 1
        }
      }
    ]
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const payload = {
          user_id: localData?.id,
        };
        const categoryRes = await GetApiParam("/services/spCategory", payload);
        setCategoryData(categoryRes?.data?.data || []);
      } catch (error) {
        console.log(error);
      }
    }
    async function profileCheck() {
      try {
        const profileCheckResponce = await GetApiParam(
          "/services/checkProfile",
          { user_id: localData?.id ? localData?.id : "" }
        );
        setProfileState(
          profileCheckResponce?.data?.code === 200
            ? profileCheckResponce?.data?.profile
            : ""
        );
      } catch (error) {
        console.log(error);
      }
    }

    profileCheck();
    fetchData();
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    showSnackbar("Thank for submitting", "success", 5000);
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 200);
  }, []);
  /**
   *
   * @param {*} id  id of the section to scroll
   */
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const handleCreateProfile = () => {
    navigate("/service-provider/onbording1");
  };

  return (
    <Box>
      <Box className="home-exploreBanner-section " sx={{ marginTop: "90px" }}>
        {/* Hero of home */}
        <Box className="container">
          <Box mb={3}>
            <ServicesSearch page={'serviceProviderHome'} categoryData={categoryData} />
          </Box>
          <Hero
            title={" Find great clients, "}
            highlightText={" your way. "}
            subTitle={
              "The ultimate destination for all your investment project needs. We connect you with top-tier professionals and experts who offer specialized services to support your investment ventures."
            }
            image={RealEstate}
            btnText={"Sign up and provide services"}
            callback={scrollToSection}
            callbackTitle={"service-provider-signup-form"}
          />
        </Box>

        {/* The different ways you can earn - projectRequests*/}
        <Box className="requestCard">
          <Box className="container">
            <Box mb={1} mt={8} textAlign={"center"}>
              <Typography className="font-45">
                The different ways you can earn
              </Typography>
            </Box>
            {/* tabs */}
            <BasicTabs />
          </Box>
        </Box>

        {/* Browse Services by Category */}
        <Box className="categoryCard" py={8}>
          <Box className="container">
            <Box mb={3}>
              <Typography className="font-45">
                Open service requests waiting for you
              </Typography>
              <Box className="btn-wrap multi-btns" mt={3}>
                {!profilestate && loginData === true ?
                  <Button
                    className="btn-rounded sp btn-large "
                    sx={{ backgroundColor: " #398CD1" }}
                    startIcon={<Person />}
                    onClick={() => handleCreateProfile()}
                  >
                    Create profile and start working
                  </Button>
                  : ""}
              </Box>
            </Box>
            <Box className="cat-slider">
              <Grid >
                <Slider {...settings}>
              {categoryData?.map((category, index) => (
                <Grid item key={index} md={3} sm={6} xs={12}>
                  <CategoryCard
                    page={'serviceProviderHome'}
                    categoryData={category}
                    key={index} // It's good practice to provide a unique key for each element in the array
                    Icon={category?.categoryIcon?.path}
                    Heading={category?.title === 'Technology Developers & Software Services' ? 'Development & IT' : category?.title}
                    SubHeading={`${category?.weeklyPost} requests posted`}
                    url={category?.id}
                  />
                </Grid>
              ))}
              </Slider>
              </Grid>
            </Box>
          </Box>
          {/* {categoryData?.length > 8 ? (
            <Box pt={3} className="browse-more">
              <Link to={'/service-provider/browser-request'}>Browse More</Link>
            </Box>
          ) : null} */}
        </Box>

        {/* <Box py={{ xs: 4, md: 8 }}>
                    <Box className="container">
                        <Box >
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6.5} >
                                    <Box id='service-provider-signup-form' p={{ xs: 2, sm: 6 }} bgcolor={'#F1F9FF'} borderRadius={'16px'} color={'#032744'}>
                                        <Typography className='font-36' >Join as a service provider</Typography>

                                        <Box mt={3.5} component="form" onSubmit={handleSubmit(onSubmit)}>
                                            <Typography className='font-18 text-blue-900' mb={2}>Please select the service you’d like to propose from the list below.</Typography>
                                            <Box className="form-group label-field" mt={3.5}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        className='dropdownIcon'
                                                        fullWidth
                                                        label="Asset Type"
                                                        select
                                                        name="asset_type"
                                                        {...register("asset_type", {
                                                            required: "Asset type is required.",
                                                        }
                                                        )}
                                                        error={Boolean(errors.asset_type)}
                                                        helperText={errors.asset_type?.message}

                                                    >
                                                        {
                                                            categoryData?.map((item, index) => (
                                                                <MenuItem key={index} value={item?.id}>{item?.title}</MenuItem>
                                                            ))
                                                        }
                                                    </TextField>
                                                </FormControl>
                                            </Box>

                                            <Typography className='font-18 text-blue-900' mb={2}>Please fill in the details below</Typography>
                                            <Grid container spacing={{ xs: 0, sm: 1 }}>
                                                <Grid item xs={12} sm={6}>
                                                    <Box className="form-group label-field">
                                                        <TextField
                                                            name="first_name"
                                                            label="First Name"
                                                            autoComplete="first_name"
                                                            placeholder="Title Service"
                                                            {...register("first_name", {
                                                                required: "First name is required.",
                                                                pattern: {
                                                                    value: /^[A-Za-z]+$/i,
                                                                    message: "Please enter valid name",
                                                                },
                                                                minLength: {
                                                                    value: 3,
                                                                    message: "Please enter valid name",
                                                                },
                                                            })}
                                                            error={Boolean(errors.first_name)}
                                                            helperText={errors.first_name?.message}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box className="form-group label-field">
                                                        <TextField
                                                            name="last_name"
                                                            label="Last Name"
                                                            placeholder="Enter your surname"
                                                            {...register("last_name", {
                                                                required: "Last name is required.",
                                                                pattern: {
                                                                    value: /^[A-Za-z]+$/i,
                                                                    message: "Please enter valid surname",
                                                                },
                                                                minLength: {
                                                                    value: 3,
                                                                    message: "Please enter valid surname",
                                                                },
                                                            })}
                                                            error={Boolean(errors.last_name)}
                                                            helperText={errors.last_name?.message}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    name="email"
                                                    label="Email Address"
                                                    placeholder="Enter your email address"
                                                    {...register("email", {
                                                        required: "Email address is required.",
                                                        pattern: {
                                                            value:
                                                                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                            message: "Please enter valid email address.",
                                                        },
                                                    })}
                                                    error={Boolean(errors.email)}
                                                    helperText={errors.email?.message}
                                                />
                                            </Box>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={3} md={4} lg={3}>
                                                    <Box className="form-group label-field">

                                                        <Controller name="country_code" control={control}
                                                            defaultValue={CountryCodes[0].code}
                                                            rules={{
                                                                required: "Country Code is required",
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    select
                                                                    name="country_code"
                                                                    {...field}
                                                                    error={Boolean(errors.country_code)}
                                                                    helperText={errors.country_code?.message}
                                                                    label="Country Code"
                                                                >
                                                                    {CountryCodes.map((option, index) => (
                                                                        <MenuItem

                                                                            key={index}
                                                                            value={option.code}
                                                                        >
                                                                            {`+${option.code}   `}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            )} />

                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={9} md={8} lg={9}>
                                                    <Box className="form-group label-field">
                                                        <TextField
                                                            name="phoneNumber"
                                                            label="Phone Number"
                                                            type="text"
                                                            placeholder="Enter phone number"
                                                            inputProps={{
                                                                maxLength: 10,
                                                            }}
                                                            {...register("phoneNumber", {
                                                                required: "Please enter your phone number.",
                                                                pattern: {
                                                                    value: /^\d{10,15}$/,
                                                                    message: "Please enter valid phone number in digits.",
                                                                },
                                                            })}
                                                            error={Boolean(errors.phoneNumber)}
                                                            helperText={errors.phoneNumber?.message}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <IconButton >
                                                                            <PhoneIphone className='text-blue-600' />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={(event) => {
                                                                event.target.value = event.target.value
                                                                    .replace(/\D/g, "")
                                                                    .slice(0, 10);
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    name="Address"
                                                    label="Address"
                                                    placeholder="Enter your address"
                                                    {...register("Address", {
                                                        required: "Address is required.",
                                                        minLength: {
                                                            value: 3,
                                                            message: "Please enter valid Address",
                                                        },
                                                    })}
                                                    error={Boolean(errors.Address)}
                                                    helperText={errors.Address?.message}
                                                />
                                            </Box>
                                            <Grid container spacing={{ xs: 0, sm: 1 }}>
                                                <Grid item xs={12} sm={6}>
                                                    <Box className="form-group label-field">
                                                        <TextField
                                                            name="suiteFloor"
                                                            label="Suite/Floor"
                                                            placeholder="Enter Suite/Floor"
                                                            {...register("suiteFloor", {
                                                                required: "suite floor is required.",
                                                                minLength: {
                                                                    value: 1,
                                                                    message: "Please enter valid suite floor",
                                                                },
                                                            })}
                                                            error={Boolean(errors.suiteFloor)}
                                                            helperText={errors.suiteFloor?.message}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box className="form-group label-field">
                                                        <TextField
                                                            name="City"
                                                            label="City"
                                                            placeholder="Enter your city"
                                                            {...register("City", {
                                                                required: "City is required.",
                                                                pattern: {
                                                                    value: /^[A-Za-z]+$/i,
                                                                    message: "Please enter valid city",
                                                                },
                                                                minLength: {
                                                                    value: 3,
                                                                    message: "Please enter valid city",
                                                                },
                                                            })}
                                                            error={Boolean(errors.City)}
                                                            helperText={errors.City?.message}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={{ xs: 0, sm: 1 }}>
                                                <Grid item xs={12} sm={6}>
                                                    <Box className="form-group label-field">
                                                        <TextField
                                                            name="stateProvince"
                                                            label="State/Province"
                                                            placeholder="Enter your state province"
                                                            {...register("stateProvince", {
                                                                required: "state province is required.",
                                                                minLength: {
                                                                    value: 2,
                                                                    message: "Please enter valid state province",
                                                                },
                                                            })}
                                                            error={Boolean(errors.stateProvince)}
                                                            helperText={errors.stateProvince?.message}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box className="form-group label-field">
                                                        <TextField
                                                            name="ZIPPostal"
                                                            label="ZIP/Postal"
                                                            placeholder='Enter zip postal'
                                                            inputProps={{
                                                                maxLength: 8,
                                                            }}
                                                            {...register("ZIPPostal", {
                                                                required: "Please enter your ZIP postal.",
                                                                pattern: {
                                                                    value: /^\d{5,7}$/,
                                                                    message: "Please enter valid ZIP/Postal.",
                                                                },
                                                            })}
                                                            error={Boolean(errors.ZIPPostal)}
                                                            helperText={errors.ZIPPostal?.message}
                                                            onInput={(event) => {
                                                                event.target.value = event.target.value
                                                                    .replace(/\D/g, "")
                                                                    .slice(0, 7);
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box className="btn-wrap" mb={{ xs: 2, sm: 4, md: 6 }}>
                                                <LoadingButton
                                                    loading={loading}
                                                    loadingPosition="start"
                                                    fullWidth
                                                    className="btn-rounded btn-large btn-blue-600 btn-text-white"
                                                    type="submit"
                                                >
                                                    Submit
                                                </LoadingButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={5.5} >
                                    <Box p={{ xs: 2, sm: 4 }} bgcolor={'#032744'} borderRadius={'16px'} color={'#fff'}>
                                        <Typography className='font-36'>F.A.Q.</Typography>
                                        <Box px={{ lg: 2 }} py={2}>
                                            <Box>
                                                <HowItWorks />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box> */}
      </Box>
    </Box>
  );
};
export default HomeServiceProvider;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }} className="home-services-tabs">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Search Project Requests" {...a11yProps(0)} />
          <Tab label="List Your Own Services" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Grid container spacing={2}>
          {projectRequests?.map((category, index) => (
            <Grid item key={index} md={4} sm={6} xs={12}>
              <RequestCard
                key={index} // It's good practice to provide a unique key for each element in the array
                Icon={category.Icon}
                Heading={category.Heading}
                SubHeading={category.SubHeading}
                url={category.Url}
              />
            </Grid>
          ))}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Grid container spacing={2}>
            {projectRequests?.map((category, index) => (
              <Grid item key={index} md={4} sm={6} xs={12}>
                <RequestCard
                  key={index} // It's good practice to provide a unique key for each element in the array
                  Icon={category.Icon}
                  Heading={category.Heading}
                  SubHeading={category.SubHeading}
                  url={category.Url}
                />
              </Grid>
            ))}
          </Grid>
      </CustomTabPanel>
    </Box>
  );
}
