import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Avatar, Box, Button, Grid, Modal, TextField, Typography, Tooltip } from '@mui/material'
import { Mail, Profile1, TooltipImage, User1, RaceNewLogo } from "../Components/Images/Images";
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/system';
import RoleDetailModal from './StaticModalForms/RoleDetailModal';
import { postApi, GetApi } from '../Api/Api';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import ReactGA from "react-ga4";
import { useSnackbar } from '../Contexts/SnackbarContext';
import "./waitlistPopup.css"

const style = {
    position: "relative",
    boxShadow: 24,
    borderRadius: "24px",
    width: "90%",
    maxWidth: "1043px",
    backdropFilter: "blur(10px)"

};

const roles = [
    { id: 1, title: 'Investor', tooltip: "Usually accredited individuals or qualified entities seeking exposure to yield bearing or investment grade assets." },
    { id: 2, title: 'Liquidity Provider', tooltip: "Participants contributing funds to liquidity pools, enabling lending on the decentralized ecosystem, while earning transaction fees." },
    { id: 3, title: 'Decentralized Investment Committee Member', tooltip: "Experienced leaders in their respective specialities,  tasked with the thorough assessment and approval of projects within the RACE ecosystem." },
    { id: 4, title: 'Project/Asset Proposer', tooltip: "Individuals or entities proposing new ventures or assets for potential adoption and tokenization within the RACE blockchain Protocol." },
    { id: 5, title: 'Service Provider', tooltip: "Certified specialists offering important services  to the ecosystem projects, to improve the operational and transactional efficiency of the RACE Protocol." },
    { id: 6, title: 'Asset Custody Provider', tooltip: "Organizations responsible for the secure custodianship, authenticity, provenance, and administration of clients' financial and physical assets, off-chain." },
    { id: 7, title: 'Law Firm/Attorney', tooltip: "Legal experts dedicated to ensuring that platform activities and user interactions are compliant with regulatory statutes." },
    { id: 8, title: 'Decentralized Financial Advisor', tooltip: " Licensed Financial Advisors allocated to provide strategic financial guidance and product recommendations to wallet users." },
    { id: 9, title: 'Broker', tooltip: "Intermediaries orchestrating the trade between buyers and sellers, upholding transactional efficiency and integrity." },
    { id: 10, title: 'Tech Developer', tooltip: "Experts who want to provide their expertise in development of the ecosystem through coding, audits, testing." },
    { id: 11, title: 'Other', tooltip: "A broad classification covering varied and specialized roles that are integral to the diverse operational needs of the RACE ecosystem." },
];

function WaitlistPopup({ open, onClose, handleCloseRoleModal }) {

    const { showSnackbar } = useSnackbar();

    const [step, setStep] = useState(1);
    const [openRoleForm, setOpenRoleForm] = useState(false);
    const [ipKey, setIpKey] = useState("")
    const [basicForm, setBasicForm] = useState({
        email: "",
        title: "",
        first_name: "",
        last_name: "",
    });
    const [basicFormErrors, setBasicFormErrors] = useState({
        email: { error: false, message: "" },
        title: { error: false, message: "" },
        first_name: { error: false, message: "" },
        last_name: { error: false, message: "" },
    });
    const [selectedBox, setSelectedBox] = useState(null);

    // for tooltip for onClick
    const [isTooltipOpen, setTooltipOpen] = useState(null);

    const handleAvatarClick = (item) => {
        setTooltipOpen(prev => prev === item ? null : item);
    };

    useEffect(() => {
        if (selectedBox !== isTooltipOpen) {
            setTooltipOpen(null)
        }
    }, [selectedBox, isTooltipOpen])

    useEffect(() => {
        async function getIpData() {
            try {
                const res = await GetApi("/getIP");
                if (res.data.code === 200) {
                    const IPV4 = res.data.ipAddress
                    const ipv4Address = IPV4.split(':').pop(); // Extracting IPv4 address
                    setIpKey(ipv4Address);
                }

            } catch (error) {
                console.log(error);
            }
        }
        getIpData();
    }, []);


    useEffect(() => {
        if (ipKey) {
            setBasicForm(prevState => ({
                ...prevState, // spread previous state to keep existing keys
                ip_address: ipKey // add the new key-value pair
            }));
        }
    }, [ipKey])

    /**
     * Function for Api call.
     */
    const handleBasicFormSubmit = async () => {
        try {

            const response = await postApi('/user/waitingList', basicForm);
            if (response.data.code === 200) {
                // Set user's subscription status in local storage based on their IP address
                const subscriptionStatusKey = ipKey ? ipKey : "";
                localStorage.setItem(subscriptionStatusKey, 'true');
            } else {
                console.error('Failed to submit form data');
                // Handle the error as needed (show a message to the user, retry, etc.)
            }
        } catch (error) {
            console.error('Error submitting form data:', error);
            // Handle the error as needed
        }
    };

    /**
     * Function for calling api on each step handle next button
     */
    const callApiForStep = async () => {
        switch (step) {
            case 1:
                if (basicForm.email) {
                    /**
                    * Intializing Google  Analytics Event
                    */
                    ReactGA.event({
                        category: "form_engagement",
                        action: "waitlist_btn_click",
                        label: "email_submission",
                    });
                    await handleBasicFormSubmit();
                }
                break;
            case 2:
                if (basicForm.title) {
                    /**
                    * Intializing Google  Analytics Event
                    */
                    ReactGA.event({
                        category: "form_engagement",
                        action: "waitlist_btn_click",
                        label: "role_selection",
                    });
                    await handleBasicFormSubmit();
                }
                break;
            case 3:
                if (basicForm.first_name && basicForm.last_name) {
                    /**
                    * Intializing Google  Analytics Event
                    */
                    ReactGA.event({
                        category: "form_engagement",
                        action: "waitlist_btn_click",
                        label: "user_name_submission",
                    });
                    await handleBasicFormSubmit();
                    setOpenRoleForm(true);
                    // onClose()
                }
                break;
            default:
                break;
        }
    };

    /**
     * Function to Go back to the previous step
     */
    const goBack = () => {
        // Go back to the previous step
        if (step > 1) {
            setStep(step - 1);
            setOpenRoleForm(false);

        } else {
            // Handle the case where the step is already at the minimum value
            // You might want to implement specific behavior, e.g., close the modal
            // onClose();
        }
    };


    /**
     * Function to close the open role form
     */
    const handleRoleFormClose = () => {
        setOpenRoleForm(false);
        onClose()
    };

    /**
     * Function for next step button
     * @returns 
     */
    const nextStep = async () => {

        await callApiForStep();

        if (step === 1) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (basicForm.email === "" || !emailRegex.test(basicForm.email)) {
                setBasicFormErrors({ ...basicFormErrors, email: { error: true, message: "Please enter a valid email address" } });
                return;
            }
        } else if (step === 2) {
            if (basicForm.title === "") {
                setBasicFormErrors({ ...basicFormErrors, title: { error: true, message: "Please select a role" } });
                showSnackbar('Please select a role', 'error')
                return;
            }
        } else if (step === 3) {
            if (basicForm.first_name === "") {
                setBasicFormErrors({ ...basicFormErrors, first_name: { error: true, message: "Please enter your first name" } });
                return;
            } else if (basicForm.last_name === "") {
                setBasicFormErrors({ ...basicFormErrors, last_name: { error: true, message: "Please enter your last name" } });
                return;
            }
        }

        // Proceed to the next step
        setStep(step + 1);
    };

    /**
     * Function that prevent backdrop click on modal
     * @param {*} event 
     * @param {*} reason 
     * @returns 
     */
    const handleCloseModal = (event, reason) => {
        if (reason === 'clickaway' || reason === 'backdropClick') {
            return;
        }
        onClose();
    };

    useEffect(() => {
        if (open) {
            setBasicForm(pre => ({
                ip_address: pre.ip_address, // add the new key-value pair
                email: "",
                title: "",
                first_name: "",
                last_name: "",
            }));
            setSelectedBox(null)
            setStep(1);
        }
    }, [open]);

    // const isDisabledRole = (title) => {
    //     const disabledRoles = ["Asset Custody Provider", "Law Firm/Attorney", "Decentralized Advisor", "Broker", "Other"];
    //     return disabledRoles.includes(title);
    // };
    const isHoverSupported = () => {
        return window.matchMedia('(hover: hover)').matches;
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleCloseModal}
                className="kyc-modal blurBackground-modal"
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                disableBackdropClick
            >
                <Box className="modal-body bgBlack" sx={style} >
                    <Box className="modal-body-inner">
                        <Box className="headContent">
                            <Link to="/">
                                <Box className="logo">
                                    <Avatar
                                        alt="Logo"
                                        src={RaceNewLogo}
                                        variant="square"
                                        sx={{ width: 58, height: 90 }}
                                    />
                                </Box>
                            </Link>

                            <CloseIcon className="close-btn" onClick={onClose} />
                        </Box>
                        <Box mb={4} className={step === 2 ? "waitlist-content waitlist-content-screen-2" : "waitlist-content"}>
                            {
                                step === 1 ?
                                    // step 1 - Email

                                    <Stack sx={{ maxWidth: '628px', mx: 'auto', minHeight: { md: '55vh' }, maxHeight: { md: '70vh' } }} direction="column" alignItems={'center'} justifyContent={'center'} spacing={2} >
                                        <Box  >
                                            <Avatar
                                                variant="square"
                                                alt="Image"
                                                src={Mail}
                                                sx={{ width: "100%", maxWidth: "auto", height: "120px" }}
                                            ></Avatar>
                                        </Box>
                                        <Typography variant='h5' textAlign={'center'} style={{ marginBottom: '28px' }}>Welcome to RACE.<br />To get notified when we launch, please enter your email.
                                        </Typography >
                                        {/* <Box display={{md:'flex'}} sx={{ width: '100%' }}> */}
                                        <Stack width={'100%'} direction={{ sm: "row", xs: 'column' }} spacing={2} >
                                            <TextField fullWidth
                                                sx={{ flexBasis: { sm: '90%', xs: 'auto' } }}
                                                // sx={{ width: '100%' }}
                                                autoComplete='on'
                                                name="email"
                                                type='email'
                                                label="Email Address"
                                                placeholder="Enter your email address"
                                                onChange={(e) => setBasicForm({ ...basicForm, email: e.target.value })}
                                                value={basicForm.email}
                                                error={basicFormErrors.email.error}
                                                helperText={basicFormErrors.email.message}
                                                InputLabelProps={{
                                                    style: { color: "#fff" }, // Set the label text color to white
                                                }}
                                                InputProps={{
                                                    style: { color: "#fff" }, // Set the input text color to white
                                                }}
                                                className='waitList-data'
                                            />
                                            <Button sx={{ flexBasis: { sm: '18%', xs: 'auto' } }} className="wallet-button loan waitlistNextBtn" onClick={nextStep}><Typography>Next</Typography> <ArrowForward sx={{ marginLeft: '0.5em' }} /></Button>
                                        </Stack>
                                        {/* </Box> */}


                                    </Stack>
                                    : step === 2 ?
                                        // step 2 -  Select Role
                                        <Box
                                            sx={{
                                                maxHeight: '70vh', // Set the maximum height for the modal
                                                overflowY: 'auto', // Enable vertical scrolling
                                                '&::-webkit-scrollbar': {
                                                    width: '0.5em',
                                                    display: 'none', // Hide scrollbar for Chrome, Safari, and Opera
                                                },
                                                scrollbarWidth: 'thin', // Hide scrollbar for Firefox
                                                '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
                                                '&:hover': {
                                                    '&::-webkit-scrollbar': {
                                                        display: 'block', // Show scrollbar on hover for Chrome, Safari, and Opera
                                                    },
                                                },
                                                marginTop: { md: '-70px' },
                                                paddingRight: { xs: '20px' }
                                            }}
                                        >
                                            <Stack sx={{ maxWidth: '628px', mx: 'auto' }} direction="column" alignItems={'center'} justifyContent={'center'} spacing={2} >
                                                <Box >
                                                    <Avatar
                                                        variant="square"
                                                        alt="Image"
                                                        src={User1}
                                                        sx={{ width: "100%", maxWidth: "auto", height: "120px" }}
                                                    ></Avatar>
                                                </Box>
                                                <Typography variant='h5' style={{ marginTop: '23px' }} textAlign={'center'} >Next, select the role that apply to you.
                                                </Typography >
                                                <Stack spacing={2} mt={'0px'}>
                                                    <Grid container spacing={2} display={'flex'} justifyContent={'center'}>
                                                        {roles?.map((item, index) => (
                                                            <Grid item key={index} xs={6} sm={6} lg={3} >
                                                                <Box
                                                                    sx={{
                                                                        // flexBasis: { sm: '90%', xs: 'auto' },
                                                                        width: { xs: '100%', lg: '100%' },
                                                                        height: '137px',
                                                                        padding: {
                                                                            xs: '2px',  // Adjust for smaller screens
                                                                            sm: '2px',
                                                                            md: '2px',
                                                                            lg: '2px',
                                                                        },

                                                                        flexDirection: 'column',
                                                                        justifyContent: 'space-between',
                                                                        alignItems: 'flex-start',
                                                                        flexShrink: 0,
                                                                        gap: '12px',

                                                                    }}
                                                                    className={`${selectedBox === index ? " wait-list-selected-box" : 'wait-list-box'}`}
                                                                    onClick={() => {
                                                                        setBasicForm({ ...basicForm, title: item?.title });
                                                                        setSelectedBox(index);
                                                                    }}
                                                                >
                                                                    <Box className="wait-box" sx={{
                                                                        padding: {
                                                                            xs: '12px',  // Adjust for smaller screens
                                                                            sm: '16px',
                                                                            md: '20px',
                                                                            lg: '20px',
                                                                        },
                                                                    }}>
                                                                        {isHoverSupported() ?
                                                                            <Tooltip
                                                                                title={item.tooltip}
                                                                                arrow
                                                                                placement="top"
                                                                                arrowStyle={{ color: '#053863' }}
                                                                                className='ctm-tooltip'

                                                                            >
                                                                                <Avatar src={TooltipImage} className='ctntooltip' alt={item.title} sx={{ width: '20px', height: '20px', marginBottom: '20px' }}
                                                                                />
                                                                            </Tooltip>
                                                                            :
                                                                            <Tooltip
                                                                                open={isTooltipOpen === index}
                                                                                title={item.tooltip}
                                                                                arrow
                                                                                placement="top"
                                                                                arrowStyle={{ color: '#053863' }}
                                                                                className='ctm-tooltip'
                                                                            >
                                                                                <Avatar src={TooltipImage} className='ctntooltip' alt={item.title} sx={{ width: '20px', height: '20px', marginBottom: '20px' }}
                                                                                    onClick={() => handleAvatarClick(index)}
                                                                                />
                                                                            </Tooltip>
                                                                        }
                                                                        {item?.title}
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Stack>
                                                <Box className="waitlist-btn-footer" sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Button sx={{ color: '#fff', }} onClick={goBack}>
                                                        <ArrowBack />
                                                        <Typography style={{ width: "80px" }}>GO BACK</Typography>
                                                    </Button>
                                                    {/* <Button className="wallet-button loan" onClick={nextStep}  >
                                                    <ArrowForward />
                                                </Button> */}
                                                    <Button sx={{ maxWidth: '56px', flexBasis: 'content', padding: '5px 15px' }} className="waitlistNextBtn wallet-button loan waitlistNextBtn" onClick={nextStep}><Typography >Next</Typography>  <ArrowForward sx={{ marginLeft: '0.5em' }} /></Button>
                                                </Box>
                                            </Stack>
                                        </Box>
                                        :
                                        // step 3 -  Full Name
                                        <Stack className="full-name-wrapper" sx={{ maxWidth: '628px', mx: 'auto', minHeight: { md: '55vh' }, maxHeight: { md: '70vh' } }} direction="column" alignItems={'center'} justifyContent={'center'} spacing={2} >
                                            <Box >
                                                <Avatar
                                                    variant="square"
                                                    alt="Image"
                                                    src={Profile1}
                                                    sx={{ width: "100%", maxWidth: "auto", height: "120px" }}
                                                ></Avatar>
                                            </Box>
                                            <Typography variant='h5' mb={4} textAlign={'center'} >Please enter your full name.
                                            </Typography >
                                            <Stack width={'100%'} direction={{ sm: "row", xs: 'column' }} spacing={2} className="waitlistpopup">
                                                <TextField
                                                    // sx={{ width: '100%' }}
                                                    sx={{ flexBasis: { sm: '45%', xs: 'auto' } }}
                                                    label="First Name"
                                                    name="first_name"
                                                    autoComplete='given-name'
                                                    onChange={(e) => { setBasicForm({ ...basicForm, first_name: e.target.value }); setBasicFormErrors({ ...basicFormErrors, first_name: { error: e.target.value ? false : true } }) }}
                                                    value={basicForm.first_name}
                                                    placeholder='Enter your first name'
                                                    error={(basicFormErrors.first_name.error)}
                                                    helperText={basicFormErrors.first_name.message}
                                                    InputLabelProps={{
                                                        style: { color: "#fff" }, // Set the label text color to white
                                                    }}
                                                    InputProps={{
                                                        style: { color: "#fff" }, // Set the input text color to white
                                                    }}

                                                />
                                                <TextField
                                                    // sx={{ width: '100%', marginLeft: '10px' }}
                                                    sx={{ flexBasis: { sm: '45%', xs: 'auto' } }}
                                                    label="Last Name"
                                                    name="last_name"
                                                    autoComplete='family-name'
                                                    onChange={(e) => { setBasicForm({ ...basicForm, last_name: e.target.value }); setBasicFormErrors({ ...basicFormErrors, last_name: { error: e.target.value ? false : true } }) }}
                                                    value={basicForm.last_name}
                                                    placeholder='Enter your last name'
                                                    error={basicFormErrors.last_name.error}
                                                    helperText={basicFormErrors.last_name.message}
                                                    InputLabelProps={{
                                                        style: { color: "#fff" }, // Set the label text color to white
                                                    }}
                                                    InputProps={{
                                                        style: { color: "#fff" }, // Set the input text color to white
                                                    }}

                                                />
                                                <Button className="wallet-button loan waitlistNextBtn" sx={{ flexBasis: { sm: '18%', xs: 'auto' } }} onClick={nextStep}><Typography>Next </Typography> <ArrowForward sx={{ marginLeft: '0.5em' }} /></Button>
                                            </Stack>
                                            <Button sx={{ color: '#fff' }} onClick={goBack}><ArrowBack style={{ marginRight: '13px' }} /> Go Back</Button>
                                        </Stack>
                            }
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <RoleDetailModal open={openRoleForm} onClose={handleRoleFormClose} basicFormData={basicForm} goBack={goBack} ipKey={ipKey} handleCloseRoleModal={handleCloseRoleModal} />
        </>
    );
}

export default WaitlistPopup;
