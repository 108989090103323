import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDisconnect, useAccount } from "wagmi";
import { Box, Grid, Typography, Button, Avatar, Modal, Fade } from "@mui/material";
import { ArrowForward, Close, ArrowBack, KeyboardArrowRight } from '@mui/icons-material';
import { postApi } from "../../Api/Api";
import { NewLogo, LogoInvestor, mailInvestor, walletInvestor } from "../../Components/Images/Images";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { useSocket } from "../../Contexts/SocketContext";
import useAuth from "../../Contexts/useAuth";
import ConnectWalletInvestor from "../../Web3/ConnectWalletInvestor";
import "./InvestorOnly.css";

const style = {
    position: "relative",
    boxShadow: 24,
    borderRadius: "24px",
    width: "662px",
    maxHeight: '90vh'
};

export default function OnboardingOption() {

    const { login } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const stateVal = location.state;
    const socket = useSocket();
    const { address } = useAccount();
    const { disconnect } = useDisconnect();
    const [signWithWallet, setSignWithWallet] = useState(false);
    const [openModal, setOpenModal] = useState(false)
    const { showSnackbar } = useSnackbar();
    const [loading, setLoading] = useState({ email: false, google: false, wallet: false }); // loading state for login buttons of google, wallet and normal login

    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        setFadeIn(true);
    }, []);

    // Wallet connection code start
    const [walletDialogOpen, setWalletDialogOpen] = React.useState(false);

    /**
     * Handle modal open 
     */
    const handleClickOpen = () => {
        setWalletDialogOpen(true);
        disconnect();
        !signWithWallet && setSignWithWallet(true);
    };

    /**
     * Handle modal close
     */
    const handleCloseDialog = () => {
        setWalletDialogOpen(false);
    };

    /**
     * Login with wallet
     * @param {string} acc  wallet address
     */
    const walletLogin = async (acc) => {
        try {
            const checkRes = await postApi(`/user/checkWallet`, { wallet_address: acc.toLowerCase() });
            if (checkRes?.data?.code === 200) {
                const loginRes = await postApi(`/user/login/`, { wallet_address: acc.toLowerCase() });
                if (loginRes?.data?.code === 200) {
                    setLoading({ ...loading, wallet: true }) // show loader on wallet button
                    // dispatch(userLogin(loginRes?.data?.data));
                    await login(loginRes?.data?.data);
                    socket.emit("setup", loginRes?.data?.data.id);
                    socket.emit("refresh-unread", loginRes?.data?.data.id);
                    localStorage.setItem("token", loginRes.data.data.token);
                    /**
                     * code to reduce wallet id
                     */
                    if (acc.length >= 10) {
                        const firstPart = acc.slice(0, 6);
                        const lastPart = acc.slice(-4);
                        acc = `${firstPart}...${lastPart}`
                    }

                    showSnackbar("Logged in with wallet: " + acc, "success");
                    setTimeout(() => { //timeout for the snackbar to show for some time
                        setLoading({ ...loading, wallet: false }) // hide loader on wallet button
                        window.history.replaceState({}, "", "/");

                        // conditionally redirecting to service provider and the race website
                        if (stateVal?.from === "ServiceModule") {
                            if (stateVal?.type === "Client") {
                                navigate("/service-proposer")
                            } else {
                                navigate("/service-provider")
                            }
                        } else {
                            navigate("/", { state: { from: "/login" }, replace: true });
                        }
                    }, 1500);

                }

            } else if (checkRes?.data?.code === 201) {
                setOpenModal(true);
            }
        } catch (error) {

        }
    }

    /**
     * Handle close information modal
     */
    const handleModalCloseAfterWallet = () => {
        setOpenModal(false);
        setTimeout(() => {
            disconnect();
        }, 500);
    }

    /**
     * Function to navigate to register page
     */
    const handleGoToSignUpAfterWallet = () => {
        setOpenModal(false);
        navigate("/register", { state: { from: "/login", wallet_address: address.toLowerCase() } });
    }
    // Wallet connection code end

    useEffect(() => {
        function scrollTop() {
            document.documentElement.scrollTop = 0;
        }
        scrollTop()
    }, [])

    const handleSignInWithGmail = () => {
        navigate('/login', { replace: true })
    }

    const handleHomeNavigate = () => {
        navigate('/', { replace: true })
    }

    return (
        <>
            <Box className="login-page auth-page">
                <Box className="auth-section-investor">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                            <Box className="auth-left-investor">
                                <Box className="auth-logo-investor">
                                    {/* <Link to="/"> */}
                                    <Box component="img" src={LogoInvestor} alt="Race logo" className="logo-investor" onClick={handleHomeNavigate} sx={{ cursor: 'pointer' }} />
                                    {/* </Link> */}
                                </Box>
                                <Box className='investor'>
                                    <Typography className="title">
                                        Welcome back
                                    </Typography>
                                    <Typography className={`subtitle ${fadeIn ? 'fade-in' : ''}`} mt={'30px'}>
                                        Global Access to Previously Illiquid Assets
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Box className="auth-right-investor">
                                <Box className="signup-option">
                                    <Box className="top-option">
                                        <Box textAlign={"left"} >
                                            {/* <Link to="/"> */}
                                            <ArrowBack onClick={handleHomeNavigate} sx={{ cursor: 'pointer' }} />
                                            {/* </Link> */}
                                        </Box>
                                        <Box textAlign={"right"} className="alreadyAc-in">
                                            New to RACE? Sign up{" "}
                                            <Link component="a" to="/register">
                                                here
                                            </Link>{" "}
                                            <Box component="span" className="icon" ml={1}>
                                                <ArrowForward />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Typography className="sign-in-text">Sign in to your account</Typography>
                                    </Box>
                                    <Box className="sing-in-gmail"
                                        // component={Link}
                                        // to="/login"  // Replace with your desired route
                                        onClick={handleSignInWithGmail}
                                        sx={{
                                            textDecoration: 'none',  // Remove underline from the link
                                            color: 'inherit',  // Inherit color to maintain styling
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '10px',  // Add padding if needed
                                            borderRadius: '8px',  // Add border-radius if needed
                                            // '&:hover': {
                                            //     backgroundColor: 'rgba(0, 0, 0, 0.08)',  // Add hover effect if needed
                                            // },
                                        }}
                                    >
                                        <Box className='sing-in-icon-text'>
                                            <Box component="img" src={mailInvestor} alt="Mail" />
                                            <Typography>Sign in with Email</Typography>
                                        </Box>
                                        <KeyboardArrowRight />
                                    </Box>
                                    <Box className="sing-in-gmail" onClick={handleClickOpen}>
                                        <Box className='sing-in-icon-text'  >
                                            <Box component="img" src={walletInvestor} alt="Mail" className="walletInvestor" />
                                            <Typography>Sign in with Wallet</Typography>
                                        </Box>
                                        <KeyboardArrowRight />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <ConnectWalletInvestor open={walletDialogOpen} handleCloseDialog={handleCloseDialog} handleConnectedSuccess={walletLogin} userLoginPortal={true} />

            <Modal
                open={openModal}
                className="connectWallet-alert-modal"
                onClose={handleModalCloseAfterWallet}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={openModal} timeout={300}>
                    <Box className="modal-body" sx={style}>
                        <Box className="headContent">
                            <Box className="logo">
                                <Avatar
                                    alt="Logo"
                                    src={NewLogo}
                                    variant="square"
                                    sx={{ width: 39, height: 90 }}
                                />
                            </Box>
                            <Close className="close-btn" onClick={handleModalCloseAfterWallet} />
                        </Box>
                        <Box className="modalContent " >
                            <Box className="main-content" >
                                <Typography component="h1" className="headText">Sign up Please!</Typography >
                                <Typography component="p" className="sub-headText">It seems like account : {address && address.slice(0, 6) + '...' + address.slice(-4)} is not registered with us. Please sign up first.</Typography >
                                <Box className="modalContent-btn-box" >
                                    <Button className="wallet-button prev" onClick={handleGoToSignUpAfterWallet} >Continue to Sign up</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}


