import React from 'react'
import { Typography, Avatar, Box } from '@mui/material';
import './STCardCompo.css';

const STCardCompo = (props) => {
    return (
        <Box className='STcardCompo'>
            <Avatar aria-label="recipe" className='STcardCompo-avatar'>
                {props.image}
            </Avatar>
            <Box className='home-content-box' >
                <Typography className='STcardCompo-content' >
                    {props.title}
                </Typography>
                <Typography className='STcardCompo-text' >
                    {props.subtitle}
                </Typography>
            </Box>
        </Box>
    )
}

export default STCardCompo