import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  Modal,
  TextField,
  IconButton,
  Rating,
  Radio
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./DeliveryConfirmModal.css";
import { useSnackbar } from "../../../../../Contexts/SnackbarContext";
import { getUserDataFromLocalStorage } from "../../../../../Services/localstorage.service";
import { postApi } from "../../../../../Api/Api";

const RatingModal = ({ onOpen, handleClose, rejectReason, setRejectReason, handleAction }) => {
  const [errors, setErrors] = useState(null);
  const [selectedValue, setSelectedValue] = React.useState('confirm');

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Box className="add-education-popup-main">
      <Modal
        open={onOpen}
        onClose={() => handleClose(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-main"
      >
        <Box
          id="education-scroller-wrapper"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            borderRadius: "24px",
            width: "900px",
            padding: "52px",

            background: "var(--blueGray-900)",
            boxShadow:
              "0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.15)",
            // Media query for small devices
            "@media (max-width: 600px)": {
              width: "350px", // Set a different height for small screens
            },
            // Additional media queries for other screen sizes if needed
            "@media (min-width: 601px) and (max-width: 900px)": {
              // Custom styles for devices between 601px and 900px width
              width: "580px",
            },
            "@media (min-width: 901px) and (max-width: 1200px)": {
              // Custom styles for devices between 901px and 1200px width
              width: "750px",
              maxHeight: "300px",
              overflow: "auto",
            },
            // Specify the maximum width for large screens
            "@media (min-width: 1201px)": {
              maxWidth: "1043px",
              maxHeight: "525px",
              overflow: "auto",
            },
          }}
        >
          <Box className="close-icon-main">
            <IconButton className='close-btn' onClick={() => handleClose(false)}>
              <CloseIcon className="cross-icon-modal" />
            </IconButton>
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            mt={2}
            className="heading font-36"
            sx={{ textAlign: "center" }}
          >
            Delivery Confirmation
          </Typography>
          <Box mt={3} borderRadius={20} padding={1} >
            {/* Do you want to confirm it */}
            <Radio
              checked={selectedValue === 'confirm'}
              onChange={handleRadioChange}
              value="confirm"
              name="radio-buttons"
              sx={{
                color: "white",
              }}
            />
            Confirm

            {/* If not please add rejection reason before reject. */}
            <Radio
              checked={selectedValue === 'reject'}
              onChange={handleRadioChange}
              value="reject"
              name="radio-buttons"
              sx={{
                color: "white",
              }}
            />
            Reject
            {selectedValue === 'confirm' ?
              <Typography>Do you want to confirm it.</Typography>
              :
              <Typography>If not please add rejection reason before reject.</Typography>}
          </Box>
          {selectedValue === 'reject' &&
            <Box mt={2}>
              <TextField
                id="description"
                name="description"
                label="Rejection Reason"
                variant="outlined"
                placeholder="Write your Reason.."
                onChange={(e) => {
                  if (e.target.value && e.target.value.length < 501) {
                    setRejectReason(e.target.value);
                    if (e.target.value.length > 49) setErrors(null);
                  } else {
                    setRejectReason(null);
                  }
                }}
                value={rejectReason}
                fullWidth
                multiline
                rows={4}
                style={{ minHeight: "80px" }}
                error={!!errors}
                helperText={errors ? errors : ""}
              />
            </Box>}
          <Box mt={3} className="btn-container">
            <Stack spacing={2} direction="row" justifyContent="center">
              {selectedValue === 'reject' ? <Button
                variant="outlined"
                className='cancel-btn'
                onClick={() => {
                  if (rejectReason && rejectReason.length > 50) {
                    handleAction('cancel');
                  } else {
                    setErrors(`Please add reject reason at least 50 character's`);
                  }
                }}
              >Reject</Button>
                :
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    background: "var(--golden-gradient)",
                    color: "var(--blueGray-900)",
                  }}
                  onClick={() => {
                    setErrors(null);
                    handleAction('confirm')
                  }}
                >
                  Confirm
                </Button>
              }
            </Stack>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};


export default RatingModal;
