import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { GetApiParam } from '../../../Api/Api'
import { formatNumber } from '../../../Components/Common/USFormat'

export default function LoanInformation({ loan_amount, loan_iop, loan_roi, loan_duration, loan_repay_day, minimum_investment, assetId, assetData }) {

    // Constants for the title, prices, and listing type
    const [prices, setPrice] = useState([
        {
            subtitle: 'Total Loan Amount',
            value: loan_amount ? formatNumber(loan_amount) : 0,
        },
        {
            subtitle: 'Total Funded Amount',
            value: 0,
        },
        {
            subtitle: 'Total Remaining Amount',
            value: 0,
        },
        {
            subtitle: 'Minimum Investment',
            value: minimum_investment ? formatNumber(parseFloat(minimum_investment)) : 1,
        }
    ])

    const getExtension = (date) => {
        // Find the item with the specified investment_id
        const val = parseInt(date) % 10;
        let result = ""
        switch (val) {
            case 1:
                result = "st"
                break
            case 2:
                result = "nd"
                break
            case 3:
                result = "rd"
                break
            default:
                result = "th"
                break
        }
        return result
    }

    const loanDurationFrom = assetData?.suggestReviewData?.length ? formatNumber(parseFloat(assetData?.suggestReviewData[0]?.loan_duration_from)) : 1;

    const loanRoiFrom = assetData?.suggestReviewData?.length ? formatNumber(parseFloat(assetData?.suggestReviewData[0]?.loan_roi_from)) : '1';

    useEffect(() => {
        const getFundedAmount = async () => {
            const resp = await GetApiParam('/invest/getAssetFundedAmount', { "asset_id": assetId })
            if (resp?.data?.code === 200) {
                // setFundedAmount()
                setPrice((prevPrices) => [
                    ...prevPrices.slice(0, 1), // Keep the first item unchanged
                    {
                        subtitle: prevPrices[1].subtitle,
                        value: formatNumber(parseFloat(resp?.data?.totalAmount)),
                    },
                    {
                        subtitle: prevPrices[2].subtitle,
                        value: formatNumber(parseFloat(loan_amount) - parseFloat(resp?.data?.totalAmount)),
                    },
                    ...prevPrices.slice(3, 4), // Keep the first item unchanged
                ]);
            }
        }
        if (assetId) {
            getFundedAmount()
        }
    }, [])

    return (
        <>
            <Box sx={{ px: '6px', mt: '8px' }} className="PO-price-set-h5">
                <Typography className='font-22' style={{ margin: '10px 0px', fontWeight: '500' }}>Loan Information</Typography>
                <Grid container direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
                    {prices.map((price, index) => (
                        <Grid key={`${index}${price}`} item sx={{ flexBasis: '50%', pb: '10px' }} xs={12} sm={6}>
                            <Typography className='rc-small-secondary-text' variant='body2' >{price.subtitle}</Typography>
                            <Typography className='font-22' >${price.value}</Typography>
                        </Grid >
                    ))}
                </Grid>
                <Grid container direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
                    <Grid key={3} item sx={{ flexBasis: '50%', pb: '10px' }} xs={12} sm={6}>
                        <Typography className='font-14 rc-small-secondary-text' variant='body2' >Preferred Interest Rate Range</Typography>
                        {assetData?.is_publish ?
                            <Typography className='font-22' >{loanRoiFrom} - {loan_roi} % APR</Typography> :
                            <Typography className='font-22' >{loan_roi} % APR</Typography>
                        }
                    </Grid>
                    <Grid key={3} item sx={{ flexBasis: '50%', pb: '10px' }} xs={12} sm={6}>
                        <Typography className='font-14 rc-small-secondary-text' variant='body2' >Preferred Loan Duration Range</Typography>
                        {assetData?.is_publish ?
                            <Typography className='font-22' >{loanDurationFrom} - {loan_duration && loan_duration > 1 ? `${loan_duration} Months` : `${loan_duration == 1 ? 1 : 0} Month`}</Typography> :
                            <Typography className='font-22' >{loan_duration && loan_duration > 1 ? `${loan_duration} Months` : `${loan_duration == 1 ? 1 : 0} Month`}</Typography>
                        }
                    </Grid>
                    <Grid key={3} item sx={{ flexBasis: '50%', pb: '10px' }} xs={12} sm={6}>
                        <Typography className='font-14 rc-small-secondary-text' variant='body2' >Interest Only Period</Typography>
                        <Typography className='font-22' >{loan_iop && loan_iop > 1 ? `${loan_iop} Months` : `${loan_iop == 1 ? 1 : 0} Month`}</Typography>
                    </Grid>
                    <Grid key={3} item sx={{ flexBasis: '50%', pb: '10px' }} xs={12} sm={6}>
                        <Typography className='font-14 rc-small-secondary-text' variant='body2' >Payment Date</Typography>
                        <Typography className='font-22 emi-date'>
                            <span className='day'>{loan_repay_day ? loan_repay_day : 1}</span>
                            <span className='extension'>{getExtension(loan_repay_day ? loan_repay_day : 1)}</span>{" "}
                            <span className='month'>of Month</span>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}



