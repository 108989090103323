import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, TextField, FormControl, InputAdornment, } from "@mui/material";
import { KeyboardArrowRight, AttachMoney } from "@mui/icons-material";
import { useForm } from "react-hook-form";

// Import custom components for this page
import { NewLogo } from "../../../Components/Images/Images";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import { GetApi } from "../../../Api/Api";
import "./Components/Questionnaire.css";
import CommonBackdropLoader from "../../../Components/Common/CommonBackdropLoader";

// type of watch
const GenderType = [
    { value: "", label: "Select Gender" },
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Unisex", label: "Unisex" }
];

export default function WatchDetails(props) {

    const [watchData, setWatchData] = useState([{ value: '', label: 'Select moment type' }])
    const [loading, setLoading] = useState(false);

    // Destructuring props to get functions and data required for the component
    const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

    // Retrieve current proposal data and progress
    var propData = getProposalData();
    let currentProgress = getProgress();

    // useForm hook to handle form validation and submission
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        mode: 'onChange',
        defaultValues: {
            watch_movement: propData?.watchInformation?.watch_movement ? propData?.watchInformation?.watch_movement : "",
            watch_gender: propData?.watchInformation?.watch_gender ? propData?.watchInformation?.watch_gender : "",
        }
    });

    useEffect(() => {
        async function getWatchInventory() {
            try {
                setLoading(true)
                // getting response from api
                const res = await GetApi(`/user/watchInventoryData`);
                // setting data
                if (res?.data?.data?.length) {
                    const momentTypes = res.data.data[0].moment_type;
                    const formattedOptions = momentTypes.map(option => ({
                        value: option,
                        label: option
                    }));
                    setWatchData([
                        { value: '', label: 'Select moment type' }, // Default option
                        ...formattedOptions // Spread the options from API
                    ]);
                }
                setLoading(false)
            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
        const timer = setTimeout(() => {
            getWatchInventory()
        }, 200);
        return () => clearTimeout(timer)
    }, []);

    useEffect(() => {
        if (watchData?.length && propData?.watchInformation?.watch_movement) {
            setValue('watch_movement', propData?.watchInformation?.watch_movement)
        }
    }, [watchData])


    // get current year
    const currentYear = new Date().getFullYear();

    /**
     * Function to handle form submission, updating 'propData' with the form data, and proceeding to the next step or saving and exiting.
     * @param {Object} data - The form data.
     * @param {Object} event - The form submission event.
     */
    const onSubmit = (data, event) => {
        const buttonText = event.nativeEvent.submitter.innerText;
        // Update 'propData' with the form data
        propData.watchInformation = { ...propData.watchInformation, ...data };

        // Update the main page 
        updateProposalData(propData);

        // Proceed to the next step or save and exit based on the button clicked
        if (buttonText.includes("Save and Exit")) {
            SaveAndExit();
        } else {
            handleNext();
        }
    }

    /**
     * Function to save the current proposal data and exit the current step/page.
     */
    const SaveAndExit = () => {
        saveProposal();
    }


    return (
        <Box className="projectProposer questionnaire-page">
            <Box className="questionnaire-section">
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-left">
                            {/* left-title */}
                            <Box className="quests-left-title">
                                <Typography component="h1" className="title">
                                    Next, we need some details of your assets
                                </Typography>
                            </Box>
                            {/* Logo */}
                            <Box className="auth-logo">
                                <Link to="/">
                                    <Box component="img" src={NewLogo} alt="Race logo" />
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-right quests-right-contents-box" component="form"
                            onSubmit={handleSubmit(onSubmit)}>
                            <Box textAlign={"right"}>
                                <Box component="span" className="icon" ml={1}>
                                    <Button id="my-button" className="btn-rounded btn-text-white btn-blue-600 saveAndExit" type="submit">
                                        Save and Exit
                                    </Button>
                                </Box>
                            </Box>
                            {/*Watch information Form starts from here */}
                            <Box className="questionnaire6 qsn-middle-content" sx={{ pt: 4, mb: 5 }} >
                                <Box className="auth-form" mt={4} >
                                    <Grid container spacing={1.5}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box mb={'30px'}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        label="Watch Gender"
                                                        id="outlined-select-country-native"
                                                        select
                                                        InputLabelProps={{ shrink: true }}
                                                        SelectProps={{
                                                            native: true
                                                        }}
                                                        name='watch_gender'
                                                        {...register("watch_gender", {
                                                            required: "Gender is required.",
                                                        })}
                                                        className='stIf-input'
                                                        error={Boolean(errors.watch_gender)}
                                                        helperText={errors.watch_gender?.message}
                                                        sx={{ input: { color: '#64748B !important' } }}
                                                    >
                                                        {GenderType?.map((option) => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.watchInformation?.watch_year}
                                                    type="text"
                                                    autoComplete="on"
                                                    id="outlined-required"
                                                    label='Year Of Production'
                                                    InputLabelProps={{ shrink: true }}
                                                    placeholder="Enter a year of production"
                                                    {...register("watch_year", {
                                                        required: "Year Of Production is required.",
                                                        pattern: {
                                                            value: /^\d{4}$/,
                                                            message: "Please enter a valid year.",
                                                        },
                                                        validate: (value) => {
                                                            if (/^\d+$/.test(value) && (parseInt(value) <= currentYear)) {
                                                                return true;
                                                            }
                                                            return "Please enter the current year or any previous year.";
                                                        }
                                                    })}
                                                    onInput={(event) => {
                                                        event.target.value = event.target.value
                                                            .replace(/\D/g, "")
                                                            .slice(0, 4);
                                                    }}
                                                    error={Boolean(errors.watch_year)}
                                                    helperText={errors.watch_year?.message}
                                                    onKeyDown={(event) => {
                                                        if (event?.key === '-' || event?.key === '+') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Box mb={'30px'}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        label="Movement"
                                                        id="outlined-select-country-native"
                                                        select
                                                        InputLabelProps={{ shrink: true }}
                                                        SelectProps={{
                                                            native: true
                                                        }}
                                                        name='condition'
                                                        {...register("watch_movement", {
                                                            required: "Movement is required.",
                                                        })}
                                                        error={Boolean(errors.watch_movement)}
                                                        helperText={errors.watch_movement?.message}
                                                        className='stIf-input'
                                                        sx={{ input: { color: '#64748B !important' } }}
                                                    >
                                                        {watchData?.map(option => (
                                                            <option key={option.value} value={option.value}>{option.label}</option>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.watchInformation?.watch_case_diameter}
                                                    autoComplete="on"
                                                    type="text"
                                                    id="outlined-required"
                                                    // InputLabelProps={{ shrink: true }}
                                                    // placeholder="Enter Serial No. Here"
                                                    label='Case Diameter'
                                                    {...register("watch_case_diameter", {
                                                        required: 'Case Diameter is required'
                                                    })}
                                                    error={Boolean(errors.watch_case_diameter)}
                                                    helperText={errors.watch_case_diameter?.message}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.watchInformation?.watch_case_material}
                                                    autoComplete="on"
                                                    type="text"
                                                    id="outlined-required"
                                                    // InputLabelProps={{ shrink: true }}
                                                    // placeholder="Enter Serial No. Here"
                                                    label='Case Material'
                                                    {...register("watch_case_material", {
                                                        required: 'Case Material is required'
                                                    })}
                                                    error={Boolean(errors.watch_case_material)}
                                                    helperText={errors.watch_case_material?.message}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.watchInformation?.watch_bezel_material}
                                                    autoComplete="on"
                                                    type="text"
                                                    id="outlined-required"
                                                    // InputLabelProps={{ shrink: true }}
                                                    // placeholder="Enter Serial No. Here"
                                                    label='Bezel Material'
                                                    {...register("watch_bezel_material", {
                                                        required: 'Bezel Material is required'
                                                    })}
                                                    error={Boolean(errors.watch_bezel_material)}
                                                    helperText={errors.watch_bezel_material?.message}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.watchInformation?.watch_crystal}
                                                    autoComplete="on"
                                                    type="text"
                                                    id="outlined-required"
                                                    // InputLabelProps={{ shrink: true }}
                                                    // placeholder="Enter Serial No. Here"
                                                    label='Crystal'
                                                    {...register("watch_crystal", {
                                                        required: 'Crystal is required'
                                                    })}
                                                    error={Boolean(errors.watch_crystal)}
                                                    helperText={errors.watch_crystal?.message}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.watchInformation?.watch_thickness}
                                                    autoComplete="on"
                                                    type="text"
                                                    id="outlined-required"
                                                    // InputLabelProps={{ shrink: true }}
                                                    // placeholder="Enter Serial No. Here"
                                                    label='Thickness'
                                                    {...register("watch_thickness", {
                                                        required: 'Thickness is required'
                                                    })}
                                                    error={Boolean(errors.watch_thickness)}
                                                    helperText={errors.watch_thickness?.message}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.watchInformation?.watch_resistance}
                                                    autoComplete="on"
                                                    type="text"
                                                    id="outlined-required"
                                                    // InputLabelProps={{ shrink: true }}
                                                    // placeholder="Enter Serial No. Here"
                                                    label='Water Resistance'
                                                    {...register("watch_resistance", {
                                                        required: 'Water Resistance is required'
                                                    })}
                                                    error={Boolean(errors.watch_resistance)}
                                                    helperText={errors.watch_resistance?.message}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.watchInformation?.watch_dial}
                                                    autoComplete="on"
                                                    type="text"
                                                    id="outlined-required"
                                                    // InputLabelProps={{ shrink: true }}
                                                    // placeholder="Enter Serial No. Here"
                                                    label='Dial'
                                                    {...register("watch_dial", {
                                                        required: 'Dial is required'
                                                    })}
                                                    error={Boolean(errors.watch_dial)}
                                                    helperText={errors.watch_dial?.message}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.watchInformation?.watch_dial_numeral}
                                                    autoComplete="on"
                                                    type="text"
                                                    id="outlined-required"
                                                    // InputLabelProps={{ shrink: true }}
                                                    // placeholder="Enter Serial No. Here"
                                                    label='Dial Numeral'
                                                    {...register("watch_dial_numeral", {
                                                        required: 'Dial Numeral is required'
                                                    })}
                                                    error={Boolean(errors.watch_dial_numeral)}
                                                    helperText={errors.watch_dial_numeral?.message}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.watchInformation?.watch_strap_material}
                                                    autoComplete="on"
                                                    type="text"
                                                    id="outlined-required"
                                                    // InputLabelProps={{ shrink: true }}
                                                    // placeholder="Enter Serial No. Here"
                                                    label='Strap Material'
                                                    {...register("watch_strap_material", {
                                                        required: 'Strap Material is required'
                                                    })}
                                                    error={Boolean(errors.watch_strap_material)}
                                                    helperText={errors.watch_strap_material?.message}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.watchInformation?.watch_strap_colour}
                                                    autoComplete="on"
                                                    type="text"
                                                    id="outlined-required"
                                                    // InputLabelProps={{ shrink: true }}
                                                    // placeholder="Enter Serial No. Here"
                                                    label='Strap Colour'
                                                    {...register("watch_strap_colour", {
                                                        required: 'Strap Colour is required'
                                                    })}
                                                    error={Boolean(errors.watch_strap_colour)}
                                                    helperText={errors.watch_strap_colour?.message}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            {/*Watch information Form ends here */}
                            <Box>
                                <Box className="questionnaire-progress" >
                                    <LinearProgressCompo value={currentProgress} />
                                </Box>
                                <Box
                                    sx={{ display: "flex", justifyContent: "space-between" }}
                                    mt={3}
                                >
                                    <Box textAlign={"left"} className="quests6-btn-box">
                                        <Button className='btn-rounded back-btn' onClick={handleback}>Back</Button>
                                    </Box>
                                    <Box textAlign={"right"} className="quests-btn-box">
                                        <Button
                                            id="my-button"
                                            // onClick={handleNext}
                                            endIcon={<KeyboardArrowRight />}
                                            className="btn-rounded btn-text-white btn-blue-600"
                                            type="submit"
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {/* showing loader until watch movement type get from api */}
            <CommonBackdropLoader loading={loading} />
        </Box >
    );
}
