import { Box, LinearProgress } from "@mui/material";
import PropTypes from 'prop-types';

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};
const LinearProgressCompo = (props) => {
    return (
        <LinearProgressWithLabel value={props.value} />
    )
}

export default LinearProgressCompo