import React, { useState } from 'react';
import { Box, Chip, Grid, Step, Stepper, StepLabel, Typography } from '@mui/material';
import ServiceOverview from "./ServiceOverview";
import Pricing from "./Pricing";
import './ListService.css';
import { useNavigate } from 'react-router-dom';
import Requirements from './Requirements';
import Gallery from './Gallery';
import Publish from './Publish';

const ListService = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [allStepsCompleted, setAllStepsCompleted] = useState(false);
    const [sharedData, setSharedData] = useState([]); // State to hold shared data
    const [sharedDataStepTwo, setSharedDataStepTwo] = useState([]); // State to hold shared data
    const [sharedDataStepThree, setSharedDataStepThree] = useState([]); // State to hold shared data
    const [sharedDataStepFour, setSharedDataStepFour] = useState([]); // State to hold shared data
    const [previousData, setPreviousData] = useState([])
    const [stepTwoPreviousData, setStepTwoPreviousData] = useState([])
    const [stepThreePreviousData, setStepThreePreviousData] = useState([])
    const [stepFourPreviousData, setStepFourPreviousData] = useState([])
    const navigate = useNavigate();
    // Default steps for a process
    const steps = [
        { label: 'SERVICE OVERVIEW' }, // Step indicating Personal Information
        { label: 'PRICING' }, // Step indicating Professional Information
        { label: 'REQUIREMENTS' }, // Step indicating Service Information
        { label: 'GALLERY' }, //Step indiacating review profile
        { label: 'PUBLISH' }, //Step indiacating review profile
    ];

    const handleDataUpdate = (newData) => {
        setSharedData(newData);
    };
    
    const handleDataPricing = (newData) => {
        setSharedDataStepTwo(newData);
    };

    const handleDataRequirement = (newData) => {
        setSharedDataStepThree(newData);
    };

    const handleDataGallery = (newData) => {
        setSharedDataStepFour(newData);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    /**
 * Function to hadle back button .
 */
    const handleBack = () => {
        navigate("/service-provider-dashboard/overview");
    };

    const handleBackFirst = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setPreviousData({
            "description": sharedData.description,
            "serviceTitle": sharedData.serviceTitle,
            "selectedCategory": sharedData.selectedCategory,
            "selectedSubCategory":sharedData.selectedSubCategory,
            "selectedSkills": sharedData.selectedSkills,
            "stepSkill": sharedData.stepSkill,
        })
    };

    /**
     * Function handling Back button on step Two.
     * Setting Data into state var when user comes back , setting previous step data for step two.
     */
    const handleBackStepTwo = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setStepTwoPreviousData({
            "starterTitle": sharedDataStepTwo.starterTitle,
            "starterDesc": sharedDataStepTwo.starterDesc,
            "starterDeliveryDays": sharedDataStepTwo.starterDeliveryDays,
            "starterRevisions": sharedDataStepTwo.starterRevisions,
            "starterIncAddService": sharedDataStepTwo.starterIncAddService,
            "starterPrice": sharedDataStepTwo.starterPrice,
            "standardTitle": sharedDataStepTwo.standardTitle,
            "advancedTitle": sharedDataStepTwo.advancedTitle,
            "standardDesc": sharedDataStepTwo.standardDesc,
            "advancedDesc": sharedDataStepTwo.advancedDesc,
            "standardDeliveryDays": sharedDataStepTwo.standardDeliveryDays,
            "advancedDeliveryDays": sharedDataStepTwo.advancedDeliveryDays,
            "standardRevisions": sharedDataStepTwo.standardRevisions,
            "advancedRevisions": sharedDataStepTwo.advancedRevisions,
            "standardIncAddService": sharedDataStepTwo.standardIncAddService,
            "advancedIncAddService": sharedDataStepTwo.advancedIncAddService,
            "standardPrice": sharedDataStepTwo.standardPrice,
            "advancedPrice": sharedDataStepTwo.advancedPrice,
            "switchLabel" :   sharedDataStepTwo.switchLabel,
            "showInputs" : sharedDataStepTwo.showInputs
        })
    }

    /**
     * Function handling Back button on step Three.
     * Setting Data into state var when user comes back , setting previous step data for step three
     */
    const handleBackStepThree = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setStepThreePreviousData({
            "requirementDocument": sharedDataStepThree.requirementDocument,
            "document": sharedDataStepThree.document
        })
    }

    /**
   * Function handling Back button on step Three.
   * Setting Data into state var when user comes back , setting previous step data for step three
   */
    const handleBackStepFour = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);

        setStepFourPreviousData({
            "galleryDetails": sharedDataStepFour.galleryDetails,
        })
    }

    /**
     * Function to handle the step colors 
     * @param {*} param
     * @returns 
     */
    const CustomStepIcon = ({ active, completed, index }) => {
        // const allStepsCompleted = activeStep === steps.length;
        const getColor = (stepIndex) => {
            if (allStepsCompleted) return "green"; // All steps completed color
            if (active && !completed && stepIndex === activeStep) return "active-step"; // Active step color
            if (completed) return "green"; // Completed step color
            if (!active && index < activeStep) return "green"; // Previous steps completed color
            return "rednew"; // Inactive step color
        };

        const color = getColor(index);

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Chip
                    label={index + 1}
                    style={{
                        color: color === "active-step" ? "white" : "white", // Adjust text color based on background color
                    }}
                    className={allStepsCompleted ? "sp-stepper-onboarding-cm" : active || completed ? "sp-stepper-onboarding" : 'sp-stepper-onboarding-nc'}
                    size="small"
                />
                <Typography variant="body1" style={{ marginLeft: 8, color: color === "active-step" ? "#b37a56" : "#94A3B8" }}>
                    {steps[index].label}
                </Typography>
            </div>
        );
    };

    return (
        <>
            <Box className="dashboard-overview-right service-proposer-listservice">
            {activeStep === 4 ? "":
              <>
                <Box className="post-project-heading">
                    <Typography component="h4" className="title">
                        List A Service
                    </Typography>
                </Box>
                </>
                }
                <Box>
                    {activeStep < 4 ? <Grid container spacing={2}>
                        <Grid item xs={12} md={12} sm={12} >
                            <Box className="service-stepper-box">
                                <Stepper activeStep={activeStep} className='las-stepper-main'>
                                    {steps.map((step, index) => (
                                        <Step key={step.label} >
                                            <StepLabel StepIconComponent={(props) => <CustomStepIcon {...props} index={index} />} />
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                        </Grid>
                    </Grid>
                        : null}
                    <Box className="sp-steps">
                        {activeStep === 0 ? (
                            <ServiceOverview
                                onDataUpdate={handleDataUpdate}
                                onNext={handleNext}
                                onBack={handleBack}
                                previousData={previousData}
                            />
                        ) : activeStep === 1 ? (
                            <Pricing
                                onNext={handleNext}
                                onBack={handleBackFirst}
                                onDataUpdate={handleDataPricing}
                                previousData={previousData}
                                stepTwoPreviousData={stepTwoPreviousData}
                                sharedData={sharedData}
                            />
                        ) : activeStep === 2 ? (
                            <Requirements
                                onNext={handleNext}
                                onBack={handleBackStepTwo}
                                onDataUpdate={handleDataRequirement}
                                sharedDataStepThree={sharedDataStepThree}
                                stepTwoPreviousData={stepTwoPreviousData}
                                stepThreePreviousData={stepThreePreviousData}
                            />
                        )
                            : activeStep === 3 ? (
                                <Gallery
                                    onDataUpdate={handleDataGallery}
                                    onNext={handleNext}
                                    onBack={handleBackStepThree}
                                    sharedData={sharedData}
                                    stepThreePreviousData={stepThreePreviousData}
                                    sharedDataStepFour={sharedDataStepFour}
                                    stepFourPreviousData={stepFourPreviousData}
                                    handleBackStepFour={handleBackStepFour}
                                />
                            ) : activeStep === 4 ? (
                                <Publish
                                    onNext={handleNext}
                                    onBack={handleBackStepFour}
                                    previousData={previousData}
                                    stepTwoPreviousData={stepTwoPreviousData}
                                    stepThreePreviousData={stepThreePreviousData}
                                    stepFourPreviousData={stepFourPreviousData}
                                    sharedData={sharedData}
                                    sharedDataStepTwo={sharedDataStepTwo}
                                    sharedDataStepThree={sharedDataStepThree}
                                    sharedDataStepFour={sharedDataStepFour}
                                />
                            ) : null}
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default ListService;