// calculateMinimumPrepaymentAmount.js

import { readContract } from "@wagmi/core";
import convertToDecimal from "../Common/convertToDecimal";

/**
 * Fetch user wallet balance
 * @param {Object} payEMIData asset details 
 * @returns emi amount for current date
 */
async function calculateMinimumPrepaymentAmount(contract_address, contract_abi, payEMIData, amount) {
    try {
        if (contract_address) {
            const getMinimumPrepaymentData = await readContract({
                address: contract_address,
                abi: contract_abi,
                functionName: "calculateMinimumPrepaymentAmount",
                chainId: 6805,
                watch: true,
                args: [
                    payEMIData.listing_id,
                    convertToDecimal(amount)
                ]
            });

            return getMinimumPrepaymentData
        }
    } catch (error) {
        console.error('Error fetching prepayment amount:', error);
        throw error; // propagate the error
    }
}

export { calculateMinimumPrepaymentAmount };
