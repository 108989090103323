import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import RaceProjectCard from '../ClientDashboard/ServiceProviderProfilePage/RaceProjectCard/RaceProjectCard';
import "./ViewSingleRequest.css"

function RightSideRaceSection({ raceProject , raceActivity}) {

  return (
    <Box p={3} className="race-activity-container">
      <Section title={'Race Activity'} icon={true}>
        <Stack direction="row" justifyContent={'space-between'} mb={2} >
          <Typography variant="subtitle2" className="font-12" >Total spent</Typography>
          <Typography variant="subtitle1" className="font-12 text-blueGray-500" >$ {raceActivity?.totalSpent}</Typography>
        </Stack>
        <Stack direction="row" justifyContent={'space-between'} mb={2} >
          <Typography variant="subtitle2" className="font-12" >Jobs Posted</Typography>
          <Typography variant="subtitle1" className="font-12 text-blueGray-500" >{raceActivity?.jobsPosted}</Typography>
        </Stack>
        <Stack direction="row" justifyContent={'space-between'} mb={2} >
          <Typography variant="subtitle2" className="font-12" >Hire Rate</Typography>
          <Typography variant="subtitle1" className="font-12 text-blueGray-500" >{raceActivity?.hireRate}%</Typography>
        </Stack>
        <Stack direction="row" justifyContent={'space-between'} mb={2} >
          <Typography variant="subtitle2" className="font-12" >Member since</Typography>
          <Typography variant="subtitle1" className="font-12 text-blueGray-500" >{raceActivity?.memberSince}</Typography>
        </Stack>
      </Section>
      <Typography variant="subtitle1" fontWeight={'500'} className='font-16' mb={2}>Race Project</Typography>
      <Box pb={2} >
        {raceProject ? <RaceProjectCard project={raceProject} /> :<Typography variant="subtitle1" className="font-12 text-blueGray-500">Not available</Typography>}
      </Box>
    </Box>
  )
}

export default RightSideRaceSection

function Section({ children, title, icon }) {
  return (
    <Box>
      <Stack direction="row" justifyContent={'space-between'}>
        <Typography variant="subtitle1" fontWeight={'500'} className='font-16' mb={2}>{title}</Typography>
        {icon ? <InfoIcon className='info-icon' /> : null}
      </Stack>
      <Box p={2}>
        {children}
      </Box>
    </Box>
  )
}
