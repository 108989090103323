import React from "react";
import { NewLogo } from "../../Components/Images/Images";
import { Box, Modal, Avatar, Typography, Button } from "@mui/material";
import { Close } from "@mui/icons-material";

const style = {
    position: "relative",
    boxShadow: 24,
    borderRadius: "24px",
    // width: "1043px",
    width: "800px",
};

export default function ConfirmReleaseAssetModal({ open, onClose, handleResponse }) {

    return (
        <Modal
            open={open}
            onClose={onClose}
            className="kyc-modal"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <Box className="modal-body" sx={style}>
                <Box className="headContent">
                    <Box className="logo">
                        <Avatar
                            alt="Logo"
                            src={NewLogo}
                            variant="square"
                            sx={{ width: 39, height: 90, objectFit: "contain" }}
                        />
                    </Box>
                    <Close className="close-btn" onClick={onClose} />
                </Box>
                <Box className="modalContentDone " >
                    <Box className="main-content loan " >
                        <Box className="currency-pop-up">
                            <Typography component="h5" my={3} px={4} className="asset-release confirm-warming-message" textAlign={"center"}>Are you sure you want to release the asset from the platform?</Typography >

                            <Box className='vote-btn-box' mt={2}>
                                <Button className={"wallet-button rjct-btn"} onClick={onClose}>No</Button>
                                <Button className={"wallet-button appr-btn"} onClick={handleResponse} >Yes</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal >
    );
}
