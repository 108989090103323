import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, Modal, IconButton } from "@mui/material";
import { Close, Check } from "@mui/icons-material";
import { NewLogo } from "../Images/Images";
import "./VotingPopup.css";

const VotingPopup = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleReturn = () => {
    navigate("/");
  };

  return (
    <>
      <Container>
        {/*Modal code starts from here*/}
        <Box className="modal-page">
          {/*submit button and used to open modal */}
          <Button
            className="submt-btn"
            variant="contained"
            onClick={() => setOpen(true)}
          >
            Submit
          </Button>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="svTy-popup">
              <Box className="svTY-popup-box">
                <Box className="logo">
                  <img src={NewLogo} />
                </Box>
                <Box className="closeIcon">
                  <IconButton onClick={handleClose}>
                    <Close sx={{ color: "#FFFFFF" }} />
                  </IconButton>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box className="checkIcon">
                  <Check />
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Container>
    </>
  );
};
export default VotingPopup;
