import React from 'react'
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { Done } from '@mui/icons-material';

const CompletedReview = () => {
  const navigate = useNavigate();
  const localData = JSON.parse(localStorage.getItem("user_data_dic"));
  return (
    <Box className="modalContentDone ">
      <Box className="main-content">
        <Box className="DoneIcon">
          <Done className="doneIconSvg" />
        </Box>
        <Typography component="h1" className="headText">
          Good Job, {localData?.member_id}!
        </Typography>
        <Typography component="p" className="sub-headText">
          Thanks for completing the review on this project. Once all Assigned
          Committee Members complete their review, you’ll receive a
          notification to submit your vote for the project’s approval. Your
          reward has been credited to your RACE wallet.
        </Typography>
        <Button
          onClick={() => navigate("/dic/project")}
          className="wallet-button"
        >
          Return to Dashboard
        </Button>
      </Box>
    </Box>
  )
}

export default CompletedReview