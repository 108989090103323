import Estate from "./js/EstateDetailsClass";
import Art from "./js/ArtDetailsClass";
import Funds from "./js/FundsDetailsClass"
import Gold from "./js/GoldDetailsClass";
import Watch from "./js/WatchDetailsClass";
import Car  from "./js/AssetCarDetailsClass"

const TYPE = {
    ESTATE: 1,
    ART: 2,
    GOLD: 7,
    WATCH: 8,
    FUNDS: 9,
    CAR:47
}

export default function ProjectDetailFactory(type,data) {
    switch (type) {
        case TYPE.ESTATE:
            return new Estate(data);
        case TYPE.GOLD:
            return new Gold(data);
        case TYPE.ART:
            return new Art(data);
        case TYPE.FUNDS:
            return new Funds(data);
        case TYPE.WATCH:
            return new Watch(data);
        case TYPE.CAR:
            return new Car(data);
        default:
            console.error(`Category id ${type} doesn't match any given id in the factory `);
            return new Art(data); //for now we have default as Estate
        // throw new Error(`Category id ${type} doesn't match any given id in the factory `);
    }

}