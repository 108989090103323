import React, { useState, useEffect } from 'react'
import { Alert, Snackbar } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';

const theme = createTheme({
    components: {
        MuiAlert: {
            styleOverrides: {

                filledInfo: {
                    backgroundColor: "#1E293B",
                },
                filledSuccess: {
                    backgroundColor: "#059669",
                },
                filledWarning: {
                    backgroundColor: "#B45309",
                },
                filledError: {
                    backgroundColor: "#B91C1C",
                },
            }
        }
    }
});


export default function SnackbarAlert({ open, message, severity, duration, onClose }) {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        // Call the hideSnackbar function from your context to close the Snackbar
        onClose();
    };

    const [isOpen, setIsOpen] = useState(open);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        if (isOpen) {
            const timeoutId = setTimeout(() => {
                setIsOpen(false);
                onClose();
            }, duration);
            return () => clearTimeout(timeoutId);
        }
    }, [isOpen, duration, onClose]);

    return (
        <ThemeProvider theme={theme}>
            <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: "right" }}
                autoHideDuration={duration} onClose={handleClose}>
                <Alert variant="filled" onClose={handleClose} severity={severity} sx={{ width: '350px' }}>
                    {message}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
};

// SnackbarAlert.defaultProps = {
//     open: false,
//     duration: 4000,
//     message: "Some Error Occurred",
//     onClose: () => { },
// };

SnackbarAlert.propTypes = {
    open: PropTypes.bool,
    message: PropTypes.string.isRequired,
    severity: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
    duration: PropTypes.number,
    onClose: PropTypes.func,
};