import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button } from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";

// Import custom components for this page
import { NewLogo } from "../../../Components/Images/Images";
import Map from "../../../Components/Map/Map";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import "./Components/Questionnaire.css";

export default function AddAssetLocation(props) {
  // Destructuring props to extract necessary functions and data
  const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

  // State to manage the selected asset location
  const [selected, changeSelection] = useState("");

  // Retrieve current proposal and progress data
  var propData = getProposalData();
  let currentProgress = getProgress();

  /**
   * Function to update the selected asset location in the proposal data.
   * @param {String} address - The address of the asset location.
   * @param {Object} data - Additional data related to the asset location.
   */
  function setSelected(address, data) {
    // Update proposal data with new asset location details
    propData.asset_location = data;
    propData.asset_address = address;

    // Update proposal data in the main page state
    updateProposalData(propData);

    // Update selected location in local state
    changeSelection(data);
  }

  /**
   * Function to update the asset location when called.
   * @param {String} address - The address of the asset location.
   * @param {Object} data - Additional data related to the asset location.
   */
  const updateAssetLocation = (address, data) => {
    setSelected(address, data);
  };

  /**
   * Function to save the current proposal and exit the current step/page.
   */
  const SaveAndExit = () => {
    saveProposal();
  };

  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section quests-map-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left" position={'relative'} zIndex={'2'}>
              {/* left-title */}
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  Is the pin in the right spot?
                </Typography>
              </Box>
              {/* Logo */}
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right">
              <Box className="qsn-top-action" textAlign={"right"} position={'relative'}>
                <Box component="span" className="icon" ml={1}>
                  <Button onClick={SaveAndExit} className="btn-rounded btn-text-white btn-blue-600">
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box className="qsn-map-wrap">
                <Map height={'calc(100vh - 102px)'} mapEdit={true} updateAssetLocation={updateAssetLocation} address={propData?.ownerInformation?.owner_address} addMarker={propData?.asset_location} asset_address={propData?.asset_address ? propData?.asset_address : ""} />
              </Box>
              <Box className="qsn-bottom-action">
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  mt={3}
                >
                  <Box textAlign={"left"} className="quests6-btn-box">
                    <Button
                      className="btn-rounded back-btn"
                      onClick={handleback}
                    >
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"} className="quests-btn-box">
                    {(selected !== "" || propData?.asset_location) ?
                      <Button
                        onClick={handleNext}
                        endIcon={<KeyboardArrowRight />}
                        className="btn-rounded btn-text-white btn-blue-600"
                      >
                        Next
                      </Button>
                      :
                      <Button
                        disabled
                        onClick={handleNext}
                        endIcon={<KeyboardArrowRight />}
                        className="btn-rounded btn-text-white btn-blue-600"
                      >
                        Next
                      </Button>
                    }
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
