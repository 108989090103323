import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactImageGallery from 'react-image-gallery';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VideoThumbnail from 'react-video-thumbnail';

export default function ViewServiceSlider({ images, coverPhoto }) {

    const [data, setData] = useState([]);
    const isXs = useMediaQuery('(max-width:300px)');

    useEffect(() => {
        let combinedData = [];
        images?.gallery?.map((val) => {
            const isVideo = val?.type === 'mp4';

           let thumbnailUrl = val?.type === 'mp4'? `${process.env.REACT_APP_IMAGE_URL}${val.path}`: `${process.env.REACT_APP_IMAGE_URL}${val.path}`;
            combinedData.push({
                original: val.gallery_image,
                thumbnail: thumbnailUrl,
                isVideo: isVideo,
            });
        });
        setData(combinedData);
    }, [images]);

    const renderThumbnail = (item) => {
        if (item.isVideo) {
            return (
                <div className="video-thumbnail">
                 <VideoThumbnail
                            videoUrl={(item.thumbnail)}
                            style={{ width: '100%', height: 'auto' }}
                        />
                    <div className="play-icon-overlay">
                        <PlayCircleOutlineIcon fontSize="large" />
                    </div>
                </div>
            );
        } else {
            return <img src={item.thumbnail} alt="" height="auto" width="100%" />;
        }
    };

    const renderItem = (item) => {
        if (item.isVideo === true) {
            return (
                <div className="video-wrapper">
                    <video controls  width="100%" height="auto" style={{ marginBottom: '40px' }} className='image-gallery-image'>
                        <source src={(item.thumbnail)}  type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            );
        } 
            return( <img src={item.thumbnail} alt="" className='image-gallery-image' />)
        
    };

    return (
        <Box className="details-thumbnails-slider">
            {data && data.length > 0 ? (
                <ReactImageGallery
                    items={data}
                    autoPlay={false}
                    showThumbnails={true}
                    thumbnailPosition={isXs ? 'bottom' : 'left'}
                    infinite={false}
                    showPlayButton={false}
                    lazyLoad={true}
                    useBrowserFullscreen={true}
                    originalHeight="426px"
                    originalWidth="200px"
                    showIndex={true}
                    disableThumbnailScroll={false}
                    showNav={false}
                    showBullets={true}
                    showFullscreenButton={true}
                    slideOnThumbnailOver={false}
                    disableThumbnailSwipe={false}
                    renderThumbInner={renderThumbnail}
                    renderItem={renderItem}
                />
            ) : (
                <Box>
                    <Typography variant="h6" sx={{ textAlign: 'center' }}>
                        No Images available
                    </Typography>
                </Box>
            )}
        </Box>
    );
}
